import { Plugins, CameraResultType, CameraSource, KeyboardInfo, Device, Capacitor } from '@capacitor/core';
const { Camera } = Plugins;
const { Keyboard } = Plugins;
const { App } = Plugins;

let INTERVENTION_LOADING_TIMEOUT=7500;
async function getDeviceInfo() {
    const info = await Device.getInfo();
    let platformText="";
    switch(Capacitor.platform){
        case 'web':
            platformText = " per browser";
            INTERVENTION_LOADING_TIMEOUT=100000; //100 seconds
            break;
        case 'android':
            platformText = " per Android";
            INTERVENTION_LOADING_TIMEOUT=13000; //13 seconds
            break;
        case 'ios':
            platformText = " per iOS";
            INTERVENTION_LOADING_TIMEOUT=13000; //13 seconds
            break;
    }
    setInnerHtml(document.getElementById("version-platform"),platformText);
}
getDeviceInfo();

App.addListener('appUrlOpen', function( data ){
    handleUrl(data);
});

function scrollTo(element, to, duration) { var start = element.scrollTop, change = to - start, currentTime = 0, increment = 20; var animateScroll = function(){ currentTime += increment; var val = Math.easeInOutQuad(currentTime, start, change, duration); element.scrollTop = val; if(currentTime < duration) { setTimeout(animateScroll, increment); } }; animateScroll(); } Math.easeInOutQuad = function (t, b, c, d) { t /= d/2; if (t < 1) return c/2*t*t + b; t--; return -c/2 * (t*(t-2) - 1) + b; };

function getScrollTopOfElementInPopupContent(element) {
    var yPosition = 0;
    while(element) {
        yPosition += (element.offsetTop?element.offsetTop:0);
        if(element.className.includes("popup-content")){
            return yPosition;
        }
        element = element.offsetParent;
    }
    return null;
}
window.getScrollTopOfElementInPopupContent=getScrollTopOfElementInPopupContent;
function getScrollTopOfParentPopupContent(element) {
    while(element) {
        if(element.className.includes("popup-content")){
            return element.scrollTop;
        }
        element = element.offsetParent;
    }
    return null;
}

function setScrollTopOfParentPopupContent(element,value) {
    while(element) {
        if(element.className.includes("popup-content")){
            scrollTo(element, value, 600);
            return;
        }
        element = element.offsetParent;
    }
    return;
}

if(Capacitor.isPluginAvailable("Keyboard")){
    Keyboard.setAccessoryBarVisible({isVisible: false});
    var keyboardPromise = Keyboard.setResizeMode(
        {
            mode : "native"
        }
    );
    keyboardPromise.then(function(success){
            console.log("KEYBOARD SUCCESS:");console.log(success);
        },
        function(failure){
            console.log("KEYBOARD FAILURE:");console.log(failure);
        }
    );
    Keyboard.addListener('keyboardDidShow', (info) => {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        var elementPosition = getScrollTopOfElementInPopupContent(document.activeElement);
        var pageTop = getScrollTopOfParentPopupContent(document.activeElement);
        var pageBottom = getScrollTopOfParentPopupContent(document.activeElement)+(vh/*-info.keyboardHeight*/);
        console.log("Page top:"+pageTop);
        console.log("Element position: "+ elementPosition);
        console.log("Page bottom:"+pageBottom);
        if((elementPosition-120) <= pageTop || elementPosition >= pageBottom){
            console.log("should scroll");
            setScrollTopOfParentPopupContent(document.activeElement,elementPosition-120);
        }
        else{
            console.log("should not scroll");
        }
    });
}


var Taggle = require("taggle");
var cytoscape = require("cytoscape");
var nodeHtmlLabel = require('cytoscape-node-html-label');
nodeHtmlLabel( cytoscape );
var Pikaday = require("pikaday");


/*********** config.js start ***************/

var BASE_URL="https://facility.claster.it";
var DIRECT_DOWNLOAD_URL="https://download.claster.it";

/*********** functions.js start **************/

var documentTags=[];
var loggedInUserInfo=[];
var outcomes=[];
var siteTypes=[];
var costCenter=null;
var tempHtml=null;
var tempHtml2=null;
var editableContractorsList=null;
var catTreeTempHtml=null;
var lastTimeOpenedNotifications=null;
var k=null;
var j=null;
var subcategoryGroupAdditionalClass=null;
var hasTicket=null;
var ticketRowToWriteTo=null;
var anomalies=null;
var safetyControlPage=null;
var popupContent=null;
var tmpDate=null;
var notificationManager=null;
var increaseUpdateCounter=null;

var interventionDate; //Dirty but useful.

var slaLevels=null;

var i,j; //Dirty but useful.

// UTILITIES START
var dom = {
    hasClass: function(element, classname) {
        var str = " " + element.className + " ";
        return str.indexOf(" " + classname + " ") > -1;
    },
    addClass: function(element, classname) {
        if(!dom.hasClass(element, classname)) {
            element.className = element.className.trim() + " " + classname;
        }
    },
    removeClass: function(element, classname) {
        if(element != null) {
            var classes = element.className.split(" ");
            element.className = "";
            classes.forEach(function(arrayElement) {
                if(arrayElement !== classname) {
                    element.className += (" " + arrayElement);
                }
            });
            element.className = element.className.trim();
        }
    },
    removeElement: function(element) {
        element.parentElement.removeChild(element);
    },
    findParentTag: function(child, tagName) {
        if (child.tagName === tagName) {
            return child;
        }
        while (child.parentNode) {
            child = child.parentNode;
            if (child.tagName === tagName) {
                return child;
            }
        }
        return null;
    }
};

var ghostClickController = {
    latestInteraction: (new Date).getTime(),
    isOk: function(){
        if( (new Date).getTime() - ghostClickController.latestInteraction < 130 ) { //less than 130ms from the last input
            return false;
        }
        ghostClickController.latestInteraction = (new Date).getTime();
        return true;
    }
};

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

var qrScanner = {
    turnOnFlash: function() {
        QRScanner.enableLight(function(err, status) {
            if(!err) {
                dom.removeClass(document.getElementById("qr-scanner-turn-off-flash"), "hidden");
                dom.addClass(document.getElementById("qr-scanner-turn-on-flash"), "hidden");
            }
        });
    },
    turnOffFlash: function(callback) {
        QRScanner.disableLight(function(err, status) {
            if(!err) {
                dom.addClass(document.getElementById("qr-scanner-turn-off-flash"), "hidden");
                dom.removeClass(document.getElementById("qr-scanner-turn-on-flash"), "hidden");
            }
            if(typeof callback === "function"){
                callback();
            }
        });
    },
    changeCam: function() {
        QRScanner.getStatus(function(status) {
            if(status.currentCamera === 1) {
                QRScanner.useBackCamera(function(err, status) {
                    err && console.error(err);
                    console.log(status);
                });
            }
            else {
                QRScanner.useFrontCamera(function(err, status) {
                    err && console.error(err);
                    console.log(status);
                });
            }
        });
    },
    close: function(){
        ui.hideQrScanner();
    }
};

class Swipe {
    constructor(element) {
        this.xDown = null;
        this.yDown = null;
        this.t0 = null;
        this.element = typeof(element) === 'string' ? document.querySelector(element) : element;

        var that = this;
        this.element.addEventListener('touchstart', function(evt) {
            console.log("touchstart");
            that.xDown = evt.touches[0].clientX;
            that.yDown = evt.touches[0].clientY;
            that.t0 = new Date();
        }, false);
    }

    onSwipeLeft(callback) {
        this.onSwipeLeft = callback;
        return this;
    }
    onSwipeRight(callback) {
        this.onSwipeRight = callback;
        return this;
    }
    onSwipeUp(callback) {
        this.onSwipeUp = callback;
        return this;
    }
    onSwipeDown(callback) {
        this.onSwipeDown = callback;
        return this;
    }
    onPanLeft(callback) {
        this.onPanLeft = callback;
        return this;
    }
    onPanRight(callback) {
        this.onPanRight = callback;
        return this;
    }
    onPanUp(callback) {
        this.onPanUp = callback;
        return this;
    }
    onPanDown(callback) {
        this.onPanDown = callback;
        return this;
    }

    handleTouchMove(evt,that) {
        console.log("touchmove");
        if ( ! that.xDown || ! that.yDown ) {
            return;
        }
        var xUp = evt.changedTouches[0].clientX;
        var yUp = evt.changedTouches[0].clientY;
        var tDiff = (new Date())-that.t0;
        that.xDiff = that.xDown - xUp;
        that.yDiff = that.yDown - yUp;
        if(tDiff!=0)
            console.log("speed:"+(that.yDiff/tDiff));
        //1.5 is very high speed!
        if((that.yDiff/tDiff)<1.5){
            //slow move, pan
            if ( Math.abs( that.xDiff ) > Math.abs( that.yDiff ) ) { // Most significant.
                if ( that.xDiff > 0 ) {
                    that.onPanLeft();
                } else {
                    that.onPanRight();
                }
            } else {
                if ( that.yDiff > 0 ) {
                    that.onPanUp();
                } else {
                    that.onPanDown();
                }
            }
        }
        else{
            //fast move, swipe
            if ( Math.abs( that.xDiff ) > Math.abs( that.yDiff ) ) { // Most significant.
                if ( that.xDiff > 0 ) {
                    that.onSwipeLeft();
                } else {
                    that.onSwipeRight();
                }
            } else {
                if ( that.yDiff > 0 ) {
                    that.onSwipeUp();
                } else {
                    that.onSwipeDown();
                }
            }
        }

        // Reset values.
        that.t0 = null;
        that.xDown = null;
        that.yDown = null;
    }

    run() {
        var that = this;
        this.element.addEventListener('touchmove', function(evt) {
            that.handleTouchMove(evt,that);
        }, false);
    }
};

var ui = {
    qrScannerOpen: false,
    hideQrScanner: function() {
        var body = document.getElementsByTagName("BODY")[0];
        dom.removeClass(body, "hidden-by-qr-scanner");
        dom.addClass(document.getElementById("qr-scanner-overlay"), "hidden");
        dom.addClass(document.getElementById("qr-scanner-close"), "hidden");
        qrScanner.turnOffFlash(function(){
                QRScanner.destroy(function(status) {
                ui.qrScannerOpen = false;
            });
        });
    },
    unescapeHtml: function(text){
        var regex = /&#39;/gi;
        return text.replace(regex,"'");
    },
    showQrScanner: function(callback) {
        var body = document.getElementsByTagName("BODY")[0];
        dom.addClass(body, "hidden-by-qr-scanner");
        QRScanner.getStatus(function(status) {
            dom.removeClass(document.getElementById("qr-scanner-overlay"), "hidden");
            dom.removeClass(document.getElementById("qr-scanner-close"), "hidden");
            if(status.lightEnabled) {
                dom.removeClass(document.getElementById("qr-scanner-turn-off-flash"), "hidden");
                dom.addClass(document.getElementById("qr-scanner-turn-on-flash"), "hidden");
            }
            else {
                dom.removeClass(document.getElementById("qr-scanner-turn-on-flash"), "hidden");
                dom.addClass(document.getElementById("qr-scanner-turn-off-flash"), "hidden");
            }
            if(status.canChangeCamera) {
                dom.removeClass(document.getElementById("qr-scanner-change-cam"), "hidden");
            }
            else {
                dom.addClass(document.getElementById("qr-scanner-change-cam"), "hidden");
            }
        });
        ui.qrScannerOpen = true;
        QRScanner.scan(callback);
        QRScanner.show();
    },
    pageBack: function() {
        if(ui.qrScannerOpen) {
            ui.hideQrScanner();
        }
        else {
            var previousPage = pageStack.pop();
            if(previousPage) {
                changePage(previousPage, false, false, true);
            }
        }
    },
    isPageHistoryEmpty(){
        if(pageStack.length<1)
            return true;
        return false;
    },
    replaceTokens: function(string) {
        var replacer = function(match, p1, p2, p3, offset, string) {
            var output = "", interventionId;
            switch(p2.split("|")[0]) {
                case "intervention":
                    interventionId = p2.split("|")[1];
                    output = "<button class='replaced-token-btn' onclick='selectIntervention(" + interventionId + ")'>intervento " + interventionId + "</button>";
                    break;
                case "date":
                    output = "" + iso8601.toEuropeanDate(p2.split("|")[1],"") + "";
                    break;
            }
            return output;
        };
        return string.replace(/([\[])(.+?)([\]])/g, replacer);
    },
    scrollableNumberPicker: {
        panCounterIncrease: 0,
        panCounterDecrease: 0,
        initialize: function(picker){
            ui.scrollableNumberPicker.panCounterDecrease = 0;
            ui.scrollableNumberPicker.panCounterIncrease = 0;
            picker.addEventListener("keydown", function(event) {
                switch(event.which || event.keyCode) {
                    case KEYCODE_ARROW_RIGHT:
                    case KEYCODE_ARROW_UP:
                        ui.scrollableNumberPicker.moveUp(picker);
                        preventEvent(event);
                        event.preventDefault();
                        break;
                    case KEYCODE_ARROW_LEFT:
                    case KEYCODE_ARROW_DOWN:
                        if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                            ui.scrollableNumberPicker.moveDown(picker);
                        }
                        preventEvent(event);
                        event.preventDefault();
                        break;
                }
            });
            var swiper = new Swipe(picker);
            swiper.onPanDown(function() { 
                console.log("PAN DOWN");
                if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                    ui.scrollableNumberPicker.moveDown(picker);
                }
                ui.pickerPopupButton.pickerComponentChanged(picker);
            });
            swiper.onPanUp(function() { 
                console.log("PAN UP");
                ui.scrollableNumberPicker.panCounterDecrease = 0;
                ui.scrollableNumberPicker.moveUp(picker);
                ui.pickerPopupButton.pickerComponentChanged(picker);
            });
            swiper.onSwipeUp(function() { 
                console.log("SWIPE UP");
                ui.scrollableNumberPicker.moveUp(picker);
                setTimeout(function() {
                    ui.scrollableNumberPicker.moveUp(picker);
                }, 50);
                setTimeout(function() {
                    ui.scrollableNumberPicker.moveUp(picker);
                }, 70);
                setTimeout(function() {
                    ui.scrollableNumberPicker.moveUp(picker);
                }, 180);
                setTimeout(function() {
                    ui.scrollableNumberPicker.moveUp(picker);
                    ui.pickerPopupButton.pickerComponentChanged(picker);
                }, 280);
            });
            swiper.onSwipeDown(function(){
                console.log("SWIPE DOWN");
                if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                    ui.scrollableNumberPicker.moveDown(picker);
                    setTimeout(function() {
                        if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                            ui.scrollableNumberPicker.moveDown(picker);
                        }
                    }, 50);
                    setTimeout(function() {
                        if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                            ui.scrollableNumberPicker.moveDown(picker);
                        }
                    }, 70);
                    setTimeout(function() {
                        if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                            ui.scrollableNumberPicker.moveDown(picker);
                        }
                    }, 180);
                    setTimeout(function() {
                        if(ui.scrollableNumberPicker.canMoveDown(picker)) {
                            ui.scrollableNumberPicker.moveDown(picker);
                        }
                        ui.pickerPopupButton.pickerComponentChanged(picker);
                    }, 280);
                }
            });
            swiper.run();
        },
        draw: function(picker, dataValue, dataMin, dataMax, dataStep) {
            var value = parseInt(dataValue);
            var min = parseInt(dataMin);
            var step = parseInt(dataStep);
            var max;
            if(dataMax !== null && dataMax !== "") {
                max = parseInt(dataMax);
            }
            else {
                max = null;
            }
            var values = [];
            values[4] = ui.scrollableNumberPicker.getFollowingValue(value, min, max, step);
            values[5] = ui.scrollableNumberPicker.getFollowingValue(values[4], min, max, step);
            values[6] = ui.scrollableNumberPicker.getFollowingValue(values[5], min, max, step);
            values[3] = value;
            values[2] = ui.scrollableNumberPicker.getPreviousValue(value, min, max, step);
            values[1] = ui.scrollableNumberPicker.getPreviousValue(values[2], min, max, step);
            values[0] = ui.scrollableNumberPicker.getPreviousValue(values[1], min, max, step);
            //Do not use setInnerHtml here
            picker.innerHTML="<div class='picker-entry' data-picker-entry-order='0'>" + (values[0] ? values[0] : "&nbsp;") + "</div>" +
                "<div class='picker-entry' data-picker-entry-order='1'>" + (values[1] ? values[1] : "&nbsp;") + "</div>" +
                "<div class='picker-entry' data-picker-entry-order='2'>" + (values[2] ? values[2] : "&nbsp;") + "</div>" +
                "<div class='picker-indicator'><i class='fa fa-caret-right' aria-hidden='true'></i></div>" +
                "<div class='picker-entry' data-picker-entry-order='3'>" + values[3] + "</div>" +
                "<div class='picker-entry' data-picker-entry-order='4'>" + values[4] + "</div>" +
                "<div class='picker-entry' data-picker-entry-order='5'>" + values[5] + "</div>" +
                "<div class='picker-entry' data-picker-entry-order='6'>" + values[6] + "</div>"
            ;
        },
        getPreviousValue: function(value, min, max, step) {
            if(value - step >= min) {
                return value - step;
            }
            if(max !== null) {
                return max;
            }
            return null;
        },
        getFollowingValue: function(value, min, max, step) {
            if(max !== null) {
                if(value + step <= max) {
                    return value + step;
                }
                return min;
            }
            return value + step;
        },
        canMoveDown: function(picker) {
            var dataMax = picker.getAttribute("data-max");
            var value = parseInt(picker.getAttribute("data-value"));
            var min = parseInt(picker.getAttribute("data-min"));
            var max=null;
            var step = parseInt(picker.getAttribute("data-step"));
            if(dataMax === "" || dataMax === null) {
                max = null;
            }
            else {
                max = parseInt(dataMax);
            }
            return !(max === null && value === min);
        },
        moveDown: function(picker) {
            var pickerEntries = picker.querySelectorAll(".picker-entry");
            pickerEntries.forEach(function(entry) {
                if(entry.getAttribute("data-picker-entry-order") === "6") {
                    dom.removeElement(entry);
                }
                entry.setAttribute("data-picker-entry-order", (1 + parseInt(entry.getAttribute("data-picker-entry-order"))));
            });
            var dataMax = picker.getAttribute("data-max");
            var value = parseInt(picker.getAttribute("data-value"));
            var min = parseInt(picker.getAttribute("data-min"));
            var max=null;
            var step = parseInt(picker.getAttribute("data-step"));
            if(dataMax === "" || dataMax === null) {
                max = null;
            }
            else {
                max = parseInt(dataMax);
            }
            var newEntry = document.createElement("div");
            var newValue = ui.scrollableNumberPicker.getPreviousValue(value, min, max, step);
            var newEntryText = ui.scrollableNumberPicker.getPreviousValue(newValue, min, max, step);
            newEntryText = ui.scrollableNumberPicker.getPreviousValue(newEntryText, min, max, step);
            newEntryText = ui.scrollableNumberPicker.getPreviousValue(newEntryText, min, max, step);
            newEntry.innerHTML = newEntryText;
            dom.addClass(newEntry, "picker-entry");
            newEntry.setAttribute("data-picker-entry-order", "0");
            picker.insertBefore(newEntry, picker.querySelectorAll(".picker-entry")[0]);
            picker.setAttribute("data-value", newValue);
        },
        moveUp: function(picker) {
            var pickerEntries = picker.querySelectorAll(".picker-entry");
            pickerEntries.forEach(function(entry) {
                if(entry.getAttribute("data-picker-entry-order") === "0") {
                    dom.removeElement(entry);
                }
                entry.setAttribute("data-picker-entry-order", (parseInt(entry.getAttribute("data-picker-entry-order")) - 1));
            });
            var dataMax = picker.getAttribute("data-max");
            var value = parseInt(picker.getAttribute("data-value"));
            var min = parseInt(picker.getAttribute("data-min"));
            var max=null;
            var step = parseInt(picker.getAttribute("data-step"));
            if(dataMax === "" || dataMax === null) {
                max = null;
            }
            else {
                max = parseInt(dataMax);
            }
            var newEntry = document.createElement("div");
            var newValue = ui.scrollableNumberPicker.getFollowingValue(value, min, max, step);
            var newEntryText = ui.scrollableNumberPicker.getFollowingValue(newValue, min, max, step);
            newEntryText = ui.scrollableNumberPicker.getFollowingValue(newEntryText, min, max, step);
            newEntryText = ui.scrollableNumberPicker.getFollowingValue(newEntryText, min, max, step);
            newEntry.innerHTML = newEntryText;
            dom.addClass(newEntry, "picker-entry");
            newEntry.setAttribute("data-picker-entry-order", "6");
            picker.appendChild(newEntry);
            picker.setAttribute("data-value", newValue);
        },
        getValue: function(pickerId) {
            var picker = document.getElementById(pickerId);
            var dataValue = picker.getAttribute("data-value");
            if(dataValue === null || dataValue === "") {
                return null;
            }
            return parseInt(dataValue);
        },
        setValue: function(picker, value) {
            var dataMax = picker.getAttribute("data-max");
            var dataMin = picker.getAttribute("data-min");
            var dataStep = picker.getAttribute("data-step");
            picker.setAttribute("data-value", value);
            ui.scrollableNumberPicker.draw(picker, value, dataMin, dataMax, dataStep);
            ui.pickerPopupButton.pickerComponentChanged(picker);
        }
    },//scrollableNumberPicker end
    pickerPopupButton: {
        presets:{
            //Unlimited number of hours, only multiple of 15 minutes, default time: 00:00
            durationPickerComponents: [
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 1,
                    min: 0,
                    step: 1
                },
                {
                    type: "separator",
                    value: ":"
                },
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 2,
                    min: 0,
                    step: 15,
                    max: 45
                }
            ],
            //default time: 12:00
            timePickerEnd: [
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 2,
                    min: 0,
                    step: 1,
                    max: 23
                },
                {
                    type: "separator",
                    value: ":"
                },
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 2,
                    min: 0,
                    step: 1,
                    max: 59
                }
            ],
            //Shows only multiple of 5 minutes, default time: 12:00
            timePickerFastEnd: [
                {
                    type: "scrollable-number-picker",
                    value: 12,
                    digits: 2,
                    min: 0,
                    step: 1,
                    max: 23
                },
                {
                    type: "separator",
                    value: ":"
                },
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 2,
                    min: 0,
                    step: 5,
                    max: 55
                }
            ],
            //Shows only multiple of 5 minutes, default time: 9:00
            timePickerFastStart: [
                {
                    type: "scrollable-number-picker",
                    value: 9,
                    digits: 2,
                    min: 0,
                    step: 1,
                    max: 23
                },
                {
                    type: "separator",
                    value: ":"
                },
                {
                    type: "scrollable-number-picker",
                    value: 0,
                    digits: 2,
                    min: 0,
                    step: 5,
                    max: 55
                }
            ]
        },
        initialize: function(configuration) {
            var button = document.getElementById(configuration.id);
            dom.addClass(button, "scrollable-picker-caller");
            var buttonValue = document.createElement("span");
            var pickerPopupContainer = document.createElement("div");
            var pickerPopupConfirmButton = document.createElement("button");
            dom.addClass(pickerPopupConfirmButton, "confirm-picker-popup");
            defineTapListenerOnElement(pickerPopupConfirmButton,ui.pickerPopupButton.confirm);
            pickerPopupConfirmButton.innerHTML = "<i class='fa fa-check' aria-hidden='true'></i>";
            dom.addClass(pickerPopupContainer, "popup");
            dom.addClass(pickerPopupContainer, "picker-popup");
            pickerPopupContainer.id = configuration.id + "-picker-popup-popup";
            var pickerPopup = document.createElement("div");
            dom.addClass(pickerPopup, "popup-content");
            var initialValue = "";
            var tempHtml = "<div>";
            var values = [];
            configuration.components.forEach(function(component) {
                values.push(component.value);
                var i;
                switch(component.type) {
                    case "scrollable-number-picker":
                        tempHtml += "<div class='scrollable-number-picker'" +
                            " data-value='" + component.value + "'" +
                            " data-min='" + ((component.min !== undefined) ? component.min : "0") + "'" +
                            " data-step='" + ((component.step !== undefined) ? component.step : "1") + "'" +
                            " data-digits='" + ((component.digits !== undefined) ? component.digits : "1") + "'" +
                            " data-max='" + ((component.max !== undefined) ? component.max : "") + "'></div>";
                        if(component.value.toString().length < component.digits) {
                            for(i = component.value.toString().length; i < component.digits; i++) {
                                initialValue += "0";
                            }
                        }
                        initialValue += component.value;
                        break;
                    case "separator":
                        tempHtml += "<div class='picker-separator' data-value='" + component.value + "'>" + component.value + "</div>";
                        initialValue += component.value;
                        break;
                }
            });
            pickerPopup.innerHTML += tempHtml;
            buttonValue.innerHTML = initialValue;
            dom.addClass(buttonValue, "picker-caller-value");
            pickerPopupContainer.appendChild(pickerPopup);
            pickerPopup.appendChild(pickerPopupConfirmButton);
            document.body.appendChild(pickerPopupContainer);
            pickerPopupContainer.querySelectorAll(".scrollable-number-picker").forEach(function(picker){
                ui.scrollableNumberPicker.initialize(picker);
            });
            button.appendChild(buttonValue);
            var buttonIcon = document.createElement("span");
            dom.addClass(buttonIcon, "picker-caller-icon");
            buttonIcon.innerHTML = "<i class='fa fa-clock-o' aria-hidden='true'></i>";
            button.appendChild(buttonIcon);
            ui.pickerPopupButton.setValue(
                configuration.id,
                values
            );
        },
        open: function(caller) {
            popup(caller.id + "-picker-popup", false, true, false);
        },
        pickerComponentChanged: function(pickerComponent) {
            ui.pickerPopupButton.pickerPopupChanged(pickerComponent.parentNode.parentNode.parentNode);
        },
        pickerPopupChanged: function(pickerPopup) {
            var parentId = pickerPopup.id.replace("-picker-popup-popup", "");
            var newValue = "";

            pickerPopup.querySelectorAll(".scrollable-number-picker, .picker-separator").forEach(function(pickerComponent) {
                var componentDigits = pickerComponent.getAttribute("data-digits");
                var componentValue = pickerComponent.getAttribute("data-value");
                var i;
                if(componentValue.toString().length < componentDigits) {
                    for(i = componentValue.toString().length; i < componentDigits; i++) {
                        newValue += "0";
                    }
                }
                newValue += componentValue;
            });
            document.querySelector("#" + parentId + " .picker-caller-value").innerHTML=newValue;
            if(pickerPopup.id.includes("end-intervention-work-time-employee")){
                endInterventionWorkTimeChanged();
            }
        },
        confirm: function() {
            closePopup();
        },
        setValue: function(pickerId, componentsValues) {
            var picker = document.getElementById(pickerId + "-picker-popup-popup");
            var componentCounter = 0;
            picker.querySelectorAll(".scrollable-number-picker, .picker-separator").forEach(function(pickerComponent) {
                if(dom.hasClass(pickerComponent, "scrollable-number-picker")) {
                    ui.scrollableNumberPicker.setValue(pickerComponent, componentsValues[componentCounter]);
                }
                if(dom.hasClass(pickerComponent, "separator")) {
                    pickerComponent.setAttribute("data-value", componentsValues[componentCounter]);
                    pickerComponent.innerHTML=componentsValues[componentCounter];
                }
                componentCounter++;
            });
            ui.pickerPopupButton.pickerPopupChanged(picker);
            scroll.refreshPopupById(pickerId + "-picker-popup", false);
        },
        getValue: function(pickerId) {
            var picker = document.getElementById(pickerId);
            if(!picker||!picker.querySelector(".picker-caller-value")) {
                return null;
            }
            var dataValue = picker.querySelector(".picker-caller-value").innerHTML.trim();
            if(dataValue === null || dataValue === "") {
                return null;
            }
            return dataValue;
        }
    }//pickerPopupButton end
};

function endInterventionWorkTimeChanged(){
    var totalHours=0;
    var totalMinutes=0;
    interventionManager.currentInterventionData.executionTeam.forEach(function(employee) {
        var pickerValue=ui.pickerPopupButton.getValue("end-intervention-work-time-employee-"+employee.employeeId);
        if(pickerValue!==null) {
            totalHours += parseInt(pickerValue.split(":")[0]);
            totalMinutes += parseInt(pickerValue.split(":")[1]);
        }
    });
    var hourDiff=parseInt(totalMinutes/60);
    totalHours+=hourDiff;
    totalMinutes-=(hourDiff*60);
    ui.pickerPopupButton.setValue(
        "end-intervention-work-time",
        [
            totalHours,
            ":",
            totalMinutes
        ]
    );
}

function getSubcategoryName(subcategoryId){
    for(var i=0;i<subcategories.length;i++){
        if(subcategories[i].subcategoryId==subcategoryId){
            return subcategories[i].name;
        }
    }
}

var interventionPage = {
    subcategories: [],
    siteId: null,
    viewDetailedInterventionExecutionReport: function(interventionId) {
        var popupContent = document.querySelector("#detailed-intervention-outcome-report-popup .popup-content div");
        popup("detailed-intervention-outcome-report", false, false, false);
        interventionPage.ajaxViewDetailedInterventionExecutionReport(interventionId, popupContent);
    },
    ajaxViewDetailedInterventionExecutionReport: function(interventionId, container) {
        setInnerHtml(
            container,
            "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
            function() {
                scroll.refreshPopupById("detailed-intervention-outcome-report", false);
                scroll.refreshPopupById("validate-intervention", false);
            }
        );
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            /**
             * @type {Object}
             * @property {Array} interventionActivities
             * @property {String} interventionActivities.assetRegistrationNumber
             */
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempHtml = "";
                if(response.interventionActivities.length === 0) {
                    tempHtml = "<div class='message success'>Nessuna nota o anomalia durante l'esecuzione dell'intervento.</div>";
                }
                for(var i = 0; i < response.interventionActivities.length; i++) {
                    var activity = response.interventionActivities[i];
                    var activityOutcomeOk=(activity.activityOutcome===true);
                    tempHtml += "<div class='entry "+(activityOutcomeOk?"activity-outcome-success":"activity-outcome-failure")+"'><table><tr><td style='width:30px;'><div class='category-small b-" + activity.categoryIconBackground + "'>" + activity.categoryIcon + "</div></td><td style='text-align:left;'>";
                    if(activity.subcategoryName) {
                        tempHtml += activity.subcategoryName;
                    }
                    if(activity.internalCode) {
                        tempHtml += " Cod. interno " + activity.internalCode;
                    }
                    if(activity.assetRegistrationNumber) {
                        tempHtml += " Matr. " + activity.assetRegistrationNumber;
                    }
                    if(activity.assetPositionHint) {
                        tempHtml += " Posizione  " + activity.assetPositionHint;
                    }
                    if(activity.internalCode || activity.assetRegistrationNumber || activity.assetPositionHint || activity.subcategoryName) {
                        tempHtml += "<br>";
                    }
                    tempHtml += activity.activityDescription + "<br>";
                    var cleanActivityNotes="";
                    if(activity.activityNotes && activity.activityNotes.trim() !== "") {
                        cleanActivityNotes = activity.activityNotes.replace("\"", "").replace("'", "‘");
                        tempHtml += "<strong>Note: " + activity.activityNotes + "</strong><br>";
                    }
                    if(activity.activityOutcome === false) {
                        tempHtml += "<strong>Esito negativo</strong><br>";
                    }
                    if(activity.activityCompletion === null) {
                        tempHtml += "<strong>Attività non compilata</strong><br>";
                    }
                    if(activity.activityCompletion !== null && cleanActivityNotes !== "") {
                        if(permissions["CREATE_ANOMALY"]!=="HIDDEN"||permissions["CREATE_TICKET"]!=="HIDDEN"){
                            tempHtml+="<br>";
                        }
                        switch(permissions["CREATE_ANOMALY"]){
                            case "ACCESSIBLE":
                                tempHtml += "<button class='open-anomaly-from-intervention-detailed-report' data-description='" + cleanActivityNotes + "' onclick='createAnomalyFromInterventionDetailedReport(" + activity.assetInstanceId + "," + activity.siteId + ",this," + activity.categoryId + "," + activity.subcategoryId + ")'>Crea segnalazione</button> ";
                                break;
                            case "DISABLED":
                                tempHtml+= "<button  class='open-anomaly-from-intervention-detailed-report' disabled='disabled'>Crea segnalazione</button> ";
                                break;
                        }
                        switch(permissions["OPEN_TICKET"]){
                            case "ACCESSIBLE":
                                tempHtml += "<button class='open-anomaly-from-intervention-detailed-report' data-description='" + cleanActivityNotes + "' onclick='createTicketFromInterventionDetailedReport(" + activity.assetInstanceId + "," + activity.siteId + ",this," + activity.categoryId + "," + activity.subcategoryId + ")'>Crea ticket</button>";
                                break;
                            case "DISABLED":
                                tempHtml+= "<button  class='open-anomaly-from-intervention-detailed-report' disabled='disabled'>Crea ticket</button>";
                                break;
                        }
                        if(permissions["CREATE_ANOMALY"]!=="HIDDEN"||permissions["CREATE_TICKET"]!=="HIDDEN"){
                            tempHtml+="<br>";
                        }
                    }
                    tempHtml += "</td></tr></table>";
                    tempHtml += "</div>";
                }
                setInnerHtml(
                    container,
                    tempHtml,
                    function(){
                        scroll.refreshPopupById("detailed-intervention-outcome-report", false);
                        scroll.refreshPopupById("validate-intervention", false);
                    }
                );
            }
            else {
                setInnerHtml(
                    container,
                    "<div class='message error'> Errore: " + response.message + "</div>",
                    function(){
                        scroll.refreshPopupById("detailed-intervention-outcome-report", false);
                        scroll.refreshPopupById("validate-intervention", false);
                    }
                );
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getInterventionDetailedExecutionReport.php?interventionId=" + interventionId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    },
    viewTechnicalInterventionConsumptive: function(interventionId) {
        var popupContent = document.querySelector("#technical-intervention-consumptive-popup .popup-content div");
        popup("technical-intervention-consumptive", false, false, false);
        interventionPage.ajaxViewTechnicalInterventionConsumptive(interventionId, popupContent);
    },
    ajaxViewTechnicalInterventionConsumptive: function(interventionId,container) {
        setInnerHtml(
            container,
            "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
            function(){
                scroll.refreshPopupById("technical-intervention-consumptive", false);
                scroll.refreshPopupById("validate-intervention", false);
            }
        );
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                /**
                 * @type {Object}
                 * @property {Object} technicalConsumptive
                 * @property {String} technicalConsumptive.executorName
                 * @property {?String} technicalConsumptive.transferTime
                 * @property {?String} technicalConsumptive.fixedTransfer
                 * @property {?String} technicalConsumptive.transferDistance
                 * @property {Array} technicalConsumptive.materials
                 */
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    if(response.technicalConsumptive.transferDistance!==null||response.technicalConsumptive.transferTime!==null){
                        var tempHtml = "<div class='hth'>";
                        if(response.technicalConsumptive.executorName !== null) {
                            tempHtml += "<div class='tr'><div>Azienda esecutrice</div><div>" + response.technicalConsumptive.executorName + "</div></div>";
                        }
                        else {
                            tempHtml += "<div class='tr'><div>Azienda esecutrice</div><div> <strong>Non assegnato</strong> </div></div>";
                        }
                        if(response.technicalConsumptive.transferDistance !== null) {
                            tempHtml += "<div class='tr'><div>Distanza trasferta [Km]<br><span class='italic'>totale andata e ritorno</span></div><div>" + response.technicalConsumptive.transferDistance + "</div></div>";
                        }
                        if(response.technicalConsumptive.transferTime !== null) {
                            tempHtml += "<div class='tr'><div>Durata trasferta</div><div>" + response.technicalConsumptive.transferTime + "</div></div>";
                        }
                        tempHtml += "<div class='tr'><div>Durata intervento</div><div>" + response.technicalConsumptive.duration + "</div></div>";
                        tempHtml += "<div class='tr'><div>Diritto di chiamata</div><div>" + (response.technicalConsumptive.isCallRight?"SI":"NO") + "</div></div>";
                        tempHtml += "<div class='tr'><div>Notturno</div><div>" + (response.technicalConsumptive.isNightTime?"SI":"NO") + "</div></div>";
                        tempHtml += "<div class='tr'><div>Urgente</div><div>" + (response.technicalConsumptive.isUrgent?"SI":"NO") + "</div></div>";
                        tempHtml += "</div><br>";
                        if(response.technicalConsumptive.employees.length > 0) {
                            tempHtml += "<h3>Operatori sull'intervento</h3>";
                            tempHtml += "<div class='vth'>";
                            tempHtml += "<div class='tr'><div>Operatore</div><div>Tempo di lavoro</div></div>";
                            response.technicalConsumptive.employees.forEach(function(employee) {
                                tempHtml += "<div class='tr'><div>" + employee.name + " " + employee.surname  + "</div><div>" + employee.workTime + "</div></div>";
                            });
                            tempHtml += "</div>";
                        }
                        if(response.technicalConsumptive.materials.length > 0) {
                            tempHtml += "<h3>Materiali utilizzati</h3>";
                            tempHtml += "<div class='vth'>";
                            tempHtml += "<div class='tr'><div>Quantità</div><div>Descrizione</div></div>";
                            response.technicalConsumptive.materials.forEach(function(material) {
                                tempHtml += "<div class='tr'><div>" + material.quantity + (material.unitOfMeasure?material.unitOfMeasure:"") + "</div><div>" + material.description + "</div></div>";
                            });
                            tempHtml += "</div>";
                        }
                    }
                    else{
                        tempHtml="";
                    }
                    tempHtml+="<br><br><button onclick='downloadInterventionTechnicalConsumptivePDF();'> <i class='fa fa-file-pdf-o'></i> Scarica in formato PDF </button><br><br>";
                    setInnerHtml(container, tempHtml,function(){
                        scroll.refreshPopupById("technical-intervention-consumptive", false);
                        scroll.refreshPopupById("validate-intervention", false);
                    });
                }
                else {
                    setInnerHtml(container, "<div class='message error'> Errore: " + response.message + "</div>",function(){
                        scroll.refreshPopupById("technical-intervention-consumptive", false);
                        scroll.refreshPopupById("validate-intervention", false);
                    });
                }
            }
            catch(e) {
                console.error(e);
                setInnerHtml(container, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionId), xhttp.responseText);
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getInterventionTechnicalConsumptive.php?interventionId=" + interventionId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    }
};

var editAssetTreePopup = {
    open: function() {
        popup("edit-asset-tree", false, false, false);
    }
};

var searchAssetPopup = {
    resultSelectedCallback: function(){
        var selectedAsset = getPseudoRadioValueByClass("selected-asset-for-asset-search");
        searchAssetPopup.userDefinedResultSelectedCallback(selectedAsset);
    },
    userDefinedResultSelectedCallback : function(assetId){
        console.log("no callback specified for searchAssetPopup! (Selected asset "+assetId+")");
    },
    open: function(userDefinedResultSelectedCallback,tip) {
        popup("search-asset", false, false, false);
        document.getElementById("search-asset-popup-tip").innerHTML = tip;
        searchAssetPopup.userDefinedResultSelectedCallback = userDefinedResultSelectedCallback;
    }
};

var searchDocumentPopup = {
    resultSelectedCallback: function() {
        var selectedDocument = getPseudoRadioValueByClass("selected-document-for-document-search");
        searchDocumentPopup.userDefinedResultSelectedCallback(selectedDocument);
        closePopup();
    },
    userDefinedResultSelectedCallback : function(documentId) {
        console.log("no callback specified for searchDocumentPopup! (Selected document "+documentId+")");
    },
    open: function(userDefinedResultSelectedCallback,tip) {
        popup("search-document", false, false, false);
        document.getElementById("search-document-popup-tip").innerHTML = tip;
        searchDocumentPopup.userDefinedResultSelectedCallback = userDefinedResultSelectedCallback;
    }
};

function addAssetRelationToAsset(assetId) {
    document.getElementById("add-asset-relation-source-asset-id").value = lastViewedAssetInstance.assetInstanceId;
    document.getElementById("add-asset-relation-destination-asset-id").value = assetId;
    var tempHtml="";
    assetRelationTypes.forEach(function(assetRelationType) {
        tempHtml+="<option value='"+assetRelationType+"'>"+assetRelationType+"</option>";
    });
    document.getElementById("add-asset-relation-type-select").innerHTML=tempHtml;
    document.getElementById("relation-source-asset").innerHTML = renderAssetEntry(lastViewedAssetInstance);
    document.getElementById("relation-destination-asset").innerHTML = renderAssetEntry(assetInstanceManager.getAssetFromAssetId(assetId));
    closePopup();
    popup("add-asset-relation");
}

function addAssetRelationSwapAssets() {
    var source = document.getElementById("add-asset-relation-source-asset-id").value;
    var destination = document.getElementById("add-asset-relation-destination-asset-id").value;
    var sourceHtml = document.getElementById("relation-source-asset").innerHTML;
    var destinationHtml = document.getElementById("relation-destination-asset").innerHTML;
    document.getElementById("add-asset-relation-source-asset-id").value = destination;
    document.getElementById("add-asset-relation-destination-asset-id").value = source;
    document.getElementById("relation-source-asset").innerHTML = destinationHtml;
    document.getElementById("relation-destination-asset").innerHTML = sourceHtml;
}

function ajaxCreateAssetRelation() {
    var requestData = {};
    requestData.source = parseInt(document.getElementById("add-asset-relation-source-asset-id").value);
    requestData.destination = parseInt(document.getElementById("add-asset-relation-destination-asset-id").value);
    requestData.relationType = document.getElementById("add-asset-relation-type-select").value;
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            closePopup();
            ajaxGetAssetInstance(parseInt(lastViewedAssetInstance.assetInstanceId));
            successToast("Relazione creata con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/assetRelation/create.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}
var interventionEdit;
var addAssetToInterventionPopup = {
    init: function() {
        document.getElementById("asset-to-add-to-intervention-container").innerHTML="";
        scroll.refreshPopupById("add-asset-to-intervention", false);
        var tempHtml = "";
        if(interventionPage.subcategories.length>1) {
            tempHtml = "<option value='' disabled='disabled' selected='selected'>-- Selezionare --</option>";
        }
        if(interventionPage.subcategories.length==1){
            addAssetToInterventionPopup.loadAssets(interventionPage.subcategories[0].subcategoryId);
        }
        interventionPage.subcategories.forEach(function(subcategory) {
            tempHtml += "<option value='" + subcategory.subcategoryId + "'>" + subcategory.subcategoryName + "</option>";
        });
        setInnerHtml(
            document.getElementById("add-asset-to-intervention-subcategory"),
            tempHtml,
            function(){scroll.refreshPopupById("add-asset-to-intervention", false);}
        );
    },
    loadAssets: function(selectedSubcategoryId) {
        var matchedAssets = 0;
        var tempHtml = "";
        for(var i = 0; i < preFetchedAssets.length; i++) {
            if(preFetchedAssets[i].site == interventionPage.siteId) {
                for(var j = 0; j < preFetchedAssets[i].subcategories.length; j++) {
                    if(preFetchedAssets[i].subcategories[j].subcategoryId == selectedSubcategoryId) {
                        matchedAssets++;
                        tempHtml += "<button " +
                            " type='button'" +
                            " class='pseudo-radio-button'" +
                            " onclick='togglePseudoRadioByClass(\"selected-asset-to-be-added-to-intervention\",this);'" +
                            ">" +
                            "<span " +
                            " data-value='" + preFetchedAssets[i].assetInstanceId + "' " +
                            " data-checked='0'" +
                            " class='pseudo-radio selected-asset-to-be-added-to-intervention'" +
                            ">" +
                            "</span>" +
                            "<span>" +
                            ((preFetchedAssets[i].subcategories[j].subcategoryName === null) ? "" : preFetchedAssets[i].subcategories[j].subcategoryName) +
                            " " + ((preFetchedAssets[i].qrCodeId === null) ? "" : (" QR " + preFetchedAssets[i].qrCodeId)) +
                            " " + ((preFetchedAssets[i].internalCode === null) ? "" : (" Cod. int. " + preFetchedAssets[i].internalCode)) +
                            " " + ((preFetchedAssets[i].registrationNumber === null) ? "" : ("Matr. " + preFetchedAssets[i].registrationNumber)) +
                            " " + ((preFetchedAssets[i].positionHint === null) ? "" : ("Posizione: " + preFetchedAssets[i].positionHint)) +
                            "</span>" +
                            "</button>";
                        break;//breaks from subcategories cycle
                    }
                }
            }
        }
        if(matchedAssets === 0) {
            tempHtml = "Nessun risultato.";
        }
        document.getElementById("asset-to-add-to-intervention-container").innerHTML = tempHtml;
        scroll.refreshPopupById("add-asset-to-intervention", false);
    },
    submit: function() {
        interventionEdit = {};
        interventionEdit.assetToAdd = getPseudoRadioValueByClass("selected-asset-to-be-added-to-intervention");
        if(interventionEdit.assetToAdd !== null) {
            interventionEdit.assetToAdd = parseInt(interventionEdit.assetToAdd);
        }
        else {
            errorToast("Nessun asset selezionato!");
            return;
        }
        if(lastOpenedIntervention.interventionId) {
            interventionEdit.interventions = [];
            interventionEdit.interventions.push(parseInt(lastOpenedIntervention.interventionId));
        }
        else {
            errorToast("Impossibile recuperare l'intervento attualmente visualizzato");
            return;
        }
        closePopup();
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    selectIntervention(lastOpenedIntervention.interventionId);
                    closePopup();
                    successToast("Intervento modificato correttamente");
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/addAssetToInterventions.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(interventionEdit));
    }
};

var endInterventionPopup = {
    materialRowsId: 0,
    addMaterialsRow: function() {
        endInterventionPopup.materialRowsId++;
        var materialsContainer = document.getElementById("end-intervention-materials-container");
        var newRow = document.createElement("div");
        dom.addClass(newRow, "end-intervention-materials-row");
        var tempHtml="";
        data.unitsOfMeasure.forEach(function(unit){
            if(unit.interventionExecution){
                if(unit.unitName==="pz.") {
                    tempHtml+="<option value='"+unit.unitName+"' selected='selected'>"+unit.unitName+" - "+unit.description+"</option>";
                }
                else {
                    tempHtml+="<option value='"+unit.unitName+"'>"+unit.unitName+" - "+unit.description+"</option>";
                }
            }
        });
        newRow.id = "end-intervention-material-row-" + endInterventionPopup.materialRowsId;
        newRow.innerHTML = "<div class='inline-form-component'>Quantità<br><input type='number' required='required' class='end-intervention-material-quantity' step='0.01' min='0.01' max='999999.99'></div>";
        newRow.innerHTML += "<div class='inline-form-component'>Unità di misura<br><select class='end-intervention-material-unit-of-measure'>"+tempHtml+"</select></div>";
        newRow.innerHTML += "<div class='inline-form-component'>Materiale<br><input type='text' required='required' class='end-intervention-material-description' minlength='1' maxlength='255'></div>";
        newRow.innerHTML += "<div class='inline-form-component'><button type='button' onclick='endInterventionPopup.deleteMaterialRow(" + endInterventionPopup.materialRowsId + ")'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi</button></div>";
        materialsContainer.appendChild(newRow);
        scroll.refreshPopupById("end-intervention", false);
    },
    deleteMaterialRow: function(materialRowId) {
        dom.removeElement(document.getElementById("end-intervention-material-row-" + materialRowId));
        scroll.refreshPopupById("end-intervention", false);
    },
    showTicketDataForm: function() {
        document.getElementById("close-intervention-containing-ticket").setAttribute("value", "1");
        dom.removeClass(document.getElementById("end-intervention-containing-ticket-container"), "hidden");
        scroll.refreshPopupById("end-intervention", false);
    },
    hideTicketDataForm: function() {
        document.getElementById("close-intervention-containing-ticket").setAttribute("value", "0");
        dom.addClass(document.getElementById("end-intervention-containing-ticket-container"), "hidden");
        scroll.refreshPopupById("end-intervention", false);
    }
};

var yourCompanyPopup = {
    init: function(){
        ajaxGetEditCompany(loggedInUserInfo.company);
        popup("edit-company-information", true, false, false);
    }
};

function ajaxApproveCompanyDocument(companyCertificationId){
    var requestData = {};
    requestData.companyCertificationId = companyCertificationId;
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            safetyControlPage.refresh();
            successToast("Documento approvato con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/approveCompanyCertification.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

var yourCompanyUsersPopup = {
    init: function(){
        yourCompanyUsersPopup.refresh();
        popup("your-company-users", true, false, false);
    },
    refresh: function(){
        var companyId=loggedInUserInfo.company;
        document.getElementById("your-company-users-company-id").value = companyId;
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    yourCompanyUsersPopup.loadEmployees(response.employees);
                }
                else {
                    errorPopup("Errore durante l'elaborazione: " + response.message);
                    popup("outcome", true, false, false);
                }
            }
            catch(e) {
                errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
                popup("outcome", true, false, false);
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getCompany.php?companyId=" + companyId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    },
    loadEmployees: function(employeesObject){
        //Company employees
        if(employeesObject!=null) {
            var companyEmployeesHtml = "";
            employees = employeesObject;
            for(var i = 0; i < employees.length; i++) {
                companyEmployeesHtml += "<h3><i class='fa fa-user'></i> "+employees[i].name + " " + employees[i].surname+"</h3>";
                employees[i].certifications.forEach(function(certification){
                    companyEmployeesHtml += "<div class='entry user-certification-entry'><strong>"+certification.certificationName+"</strong>" +
                    "<br>Scadenza: " + iso8601.toEuropeanDate(certification.expiration)+"<br><br>"+
                    "<button onclick='renderDocument(" + certification.documentId + ",\"pdf\");'><i class='fa fa-download'></i> Scarica</button><br><br></div>";
                });
                if(employees[i].certifications.length>0) {
                    companyEmployeesHtml += "<br><br>";
                }
                companyEmployeesHtml += "<button onclick='yourCompanyUsersPopup.addCertificationToEmployee("+employees[i].employeeId+");'><i class='fa fa-plus'></i> Aggiungi certificazione</button>";
            }
            if(employees.length < 1) {
                companyEmployeesHtml += "<div class='no-results-message'>Nessun dipendente registrato</div>";
            }
            document.getElementById("edit-company-employees-zone").innerHTML = companyEmployeesHtml;
        }
    },
    addCertificationToEmployee: function(employeeId){
        document.getElementById("add-certification-for-user-employee-id").value=employeeId;
        document.getElementById("add-certification-for-user-company-id").value=loggedInUserInfo.company;
        popup("add-certification-for-user", false, false, false);
    }
};

var manageSubcategoryAdditionalFieldsPopup = {
    refresh: function() {
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    manageSubcategoryAdditionalFieldsPopup.draw(response.fields);
                }
                else {
                    errorPopup("Errore durante l'elaborazione: " + response.message);
                    popup("outcome", true, false, false);
                }
            }
            catch(e) {
                errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
                popup("outcome", true, false, false);
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/subcategoryAdditionalField/get.php", true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    },
    draw: function(fields) {
        tempHtml="";
        var countEntries=0;
        fields.forEach(function(field){
            if(field.deletion===null){
                countEntries++;
                tempHtml+="<div class='entry'>" +
                renderSubcategoryWithIcon(field.subcategoryId) +
                    "<h3>"+field.fieldName+"</h3>";
                switch(field.fieldType) {
                    case "integer":
                        tempHtml+="Numero intero";
                        break;
                    case "decimal":
                        tempHtml+="Numero decimale";
                        break;
                    case "bool":
                        tempHtml+="Valore si/no";
                        break;
                    case "text":
                        tempHtml+="Testo";
                        break;
                }

            }
            tempHtml+="<br><strong>Storico: </strong>"+(field.keepHistory?"Registrazione attiva":"No");
            if(field.gePrecedent||field.geZero) {
                tempHtml+="<br><strong>Vincoli: </strong>"+(field.gePrecedent?"Vincolo &ge; del valore precedente":"") +
                    ((field.gePrecedent && field.geZero)?",":"") +
                    ((field.geZero)?"Vincolo &ge; di zero":"");
            }
            tempHtml+="</div>";
        });
        if(countEntries==0){
            tempHtml="<div class='no-results-message'>Nessun campo aggiuntivo registrato</div>";
        }
        setInnerHtml(document.getElementById("subcategory-additional-fields-holder"),tempHtml);
    },
    init: function() {
        popup("manage-subcategory-additional-fields", undefined, undefined, false);
        manageSubcategoryAdditionalFieldsPopup.refresh();
    }
};

var verifyInterventionDataPopup = {
    lastClosedIntervention:null,
    refresh: function(){
        verifyInterventionDataPopup.init(verifyInterventionDataPopup.lastClosedIntervention);
    },
    init: function(closedIntervention){
        verifyInterventionDataPopup.lastClosedIntervention=closedIntervention;
        popup("verify-intervention-data", undefined, undefined, false);
        var tempHtml="";
        if(closedIntervention.activitiesData) {
            tempHtml+="<h3>Attività compilate durante l'intervento</h3>";
            closedIntervention.activitiesData.forEach(function(asset,assetIndex) {
                var categoryBackground="n", categoryIcon="", subcategoryIcon="", subcategoryName="";
                if(asset.activitiesOnAssetInstance.length>0){
                    if(asset.subcategories[0]){
                        categoryBackground=asset.subcategories[0].categoryIconBackground;
                        categoryIcon=asset.subcategories[0].categoryIcon;
                        subcategoryIcon=asset.subcategories[0].subcategoryIcon;
                        subcategoryName=asset.subcategories[0].subcategoryName;
                    }
                    tempHtml += "<div class='entry'>" +
                        "<div class='verify-intervention-data-entry-first-col'><div class='category-small b-" + categoryBackground + "'>" + categoryIcon + "</div><div class='category-small b-" + categoryBackground + "'>" + subcategoryIcon + "</div></div>"+
                        "<div class='verify-intervention-data-entry-second-col'><div class='inline-asset-detail'>"+subcategoryName+"</div>"+
                        ((asset.qrCode == null) ? "" : ("<div class='inline-asset-detail'><i class='fa fa-qrcode qr-icon' aria-hidden='true'></i><strong> QR </strong>" + asset.qrCode + "</div>")) +
                        ((asset.description == null) ? "" : ("<div class='inline-asset-detail'></i><strong> Descrizione </strong>" + asset.description + "</div>")) +
                        ((asset.brand == null) ? "" : ("<div class='inline-asset-detail'><strong> Marca </strong>" + asset.brand + "</div>")) +
                        ((asset.model == null) ? "" : ("<div class='inline-asset-detail'><strong> Modello </strong>" + asset.model + "</div>")) +
                        ((asset.internalCode == null) ? "" : ("<div class='inline-asset-detail'><strong> Cod. Interno </strong>" + asset.internalCode + "</div>")) +
                        ((asset.registrationNumber == null) ? "" : ("<div class='inline-asset-detail'><strong> Matr. </strong>" + asset.registrationNumber + "</div>")) +
                        ((asset.positionHint == null) ? "" : ("<br><div class='asset-location'><strong> <i class='fa fa-map-marker' aria-hidden='true'></i> </strong>" + asset.positionHint + "</div>")) +
                        "</div>"+
                        "<button class='correct-intervention-activity-to-verify' onclick='correctAssetInstanceInIntervention("+assetIndex+");'><i class='fa fa-pencil' aria-hidden='true'></i><br>Correggi</button>";
                    var collapsedPositiveActivitiesCounter=0;
                    var savedLastPositiveActivityDescription="";
                    asset.activitiesOnAssetInstance.forEach(function(activity){
                        var activityDescription="";
                        for(var j = 0; j < lastOpenedIntervention.activities.length; j++) {
                            if(lastOpenedIntervention.activities[j].interventionActivityId == activity.interventionActivityId) {
                                activityDescription = lastOpenedIntervention.activities[j].activityDescription;
                                break;
                            }
                        }
                        if(activity.interventionActivityIsCompleted && activity.outcomeOk && (activity.notes==null || activity.notes=="")){
                            //collapsing positive outcome activities without notes
                            collapsedPositiveActivitiesCounter++;
                            savedLastPositiveActivityDescription=activityDescription;
                        }
                        else {
                            if(activity.interventionActivityIsCompleted){
                                if(activity.outcomeOk) {
                                    tempHtml+="<div class='outcome-to-check outcome-ok'><i class='fa fa-check-circle' aria-hidden='true'></i> ";
                                }
                                else {
                                    tempHtml+="<div class='outcome-to-check outcome-not-ok'><i class='fa fa-times-circle' aria-hidden='true'></i> ";
                                }
                            }
                            else{
                                tempHtml+="<div class='outcome-to-check not-compiled'><i class='fa fa-circle' aria-hidden='true'></i> ";
                            }
                            tempHtml += activityDescription;
                            if(activity.notes!=null && activity.notes!="") {
                                tempHtml += "<br><i class='fa fa-comment' aria-hidden='true'></i> <strong>"+activity.notes+"</strong>";
                            }
                            tempHtml+="</div>";
                        }
                    });
                    if(collapsedPositiveActivitiesCounter>0) {
                        tempHtml+="<div class='outcome-to-check outcome-ok'><i class='fa fa-check-circle' aria-hidden='true'></i> ";
                        if(collapsedPositiveActivitiesCounter>1) {
                            tempHtml += "Altre " + collapsedPositiveActivitiesCounter + " attività positive senza note";
                        }
                        else {
                            tempHtml += savedLastPositiveActivityDescription;
                        }
                        tempHtml+="</div>";
                    }
                    tempHtml += "</div>";
                }
                else{
                    var correctTicketButton = "<button class='correct-intervention-activity-to-verify' onclick='closePopup();closePopup();closePopup();'><i class='fa fa-pencil' aria-hidden='true'></i><br>Correggi</button>";
                    asset.relatedTickets.forEach(function(ticket){
                        if(ticket.interventionActivityIsCompleted){
                            if(ticket.outcomeOk) {
                                tempHtml+="<div class='outcome-to-check outcome-to-check-ticket-without-asset outcome-ok'>"+correctTicketButton+"<i class='fa fa-check-circle' aria-hidden='true'></i> ";
                            }
                            else {
                                tempHtml+="<div class='outcome-to-check outcome-to-check-ticket-without-asset outcome-not-ok'>"+correctTicketButton+"<i class='fa fa-times-circle' aria-hidden='true'></i> ";
                            }
                        }
                        else{
                            tempHtml+="<div class='outcome-to-check outcome-to-check-ticket-without-asset not-compiled'>"+correctTicketButton+"<i class='fa fa-circle' aria-hidden='true'></i> ";
                        }
                        tempHtml += ticket.description;
                        if(ticket.notes!=null && ticket.notes!="") {
                            tempHtml += "<br><i class='fa fa-comment' aria-hidden='true'></i> <strong>" + ticket.notes+"</strong>";
                        }
                        tempHtml+="</div>";
                    });
                }
            });
        }

        tempHtml+="<br><h3>Dati chiusura intervento</h3>";
        tempHtml+="<button class='correct-intervention-activity-to-verify' onclick='closePopup();'><i class='fa fa-pencil' aria-hidden='true'></i><br>Correggi</button>";

        switch(closedIntervention.outcome) {
            case SUCCESS_OUTCOME:
                tempHtml+="<strong>Esito intervento:</strong> Completato correttamente<br><br>";
                break;
            case MORE_WORK_REQUIRED_OUTCOME:
                tempHtml+="<strong>Esito intervento:</strong> Devo completarlo<br><br>";
                break;
            case INTERVENTION_NOT_POSSIBLE:
                tempHtml += "<strong>Esito intervento:</strong> Non completamente eseguibile";
            case THIRD_PARTY_INTERVENTION_REQUIRED_OUTCOME:
                tempHtml+="<strong>Esito intervento:</strong> Serve intervento di terzi<br><br>";
                break;
        }

        tempHtml+="<span id='verify-intervention-gps-state'></span><br><br>";

        if(closedIntervention.notes) {
            tempHtml+="<strong>Note di fine intervento:</strong> <br>"+closedIntervention.notes+"<br>";
        }
        else {
            tempHtml+="<strong>Note di fine intervento:</strong> <span class='italic'>Nessuna nota inserita</span><br><br>";
        }

        if(closedIntervention.containsTicket){
            if(closedIntervention.employees.length>0){
                tempHtml+="<br><br><strong>Ore di lavoro operatori registrati:</strong><br><ul class='fa-ul'>";
                closedIntervention.employees.forEach(function(employee){
                    tempHtml+="<li><i class='fa-li fa fa-user-circle'></i>"+employee.workTime+" "+employee.employeeName+" "+employee.employeeSurname+"</li>";
                });
                tempHtml+="</ul>";
            }
            tempHtml+="<br><strong>Distanza trasferta <span class='italic'>totale andata e ritorno</span>:</strong> "+closedIntervention.transferDistance+"Km";
            tempHtml+="<br><br><strong>Ore totali trasferta:</strong> "+closedIntervention.transferHours;
            if(closedIntervention.materials.length>0) {
                tempHtml+="<br><br><strong>Materiali registrati:</strong><br><ul>";
                closedIntervention.materials.forEach(function(material) {
                    tempHtml+="<li>"+material.quantity+material.unitOfMeasure+" "+material.description+"</li>";
                });
                tempHtml+="</ul>";
            }
        }
        var checkGps = function(){
            ajaxGetInterventionGeolocationState(
                verifyInterventionDataPopup.lastClosedIntervention.interventionId,
                document.getElementById("verify-intervention-gps-state")
            );
        };
        setInnerHtml(
            document.getElementById("intervention-data-to-verify-container"),
            tempHtml,
            function(){
                scroll.refreshPopupById("verify-intervention-data", false);
                checkGps();
            }
        );
    }
};


function parseDate(input) {
    if(input) {
        var parts = input.match(/(\d+)/g);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }
    else {
        return null;
    }
}

function addDays(date, days) {
    var dateFormatTotime = new Date(date);
    var increasedDate = new Date(parseInt(dateFormatTotime.getTime()) +parseInt(days *86400000));
    //TODO: leap years
    if(increasedDate.getDate()>28&&increasedDate.getMonth()===1){
        increasedDate.setMonth(2)-1;
        increasedDate.setDate(1);
    }
    return increasedDate;
}

function addMonths(date, months) {
    var newMonth=parseInt(date.getMonth()) + parseInt(months);
    var newYear=date.getFullYear();
    var oldDate=date.getDate();
    var newDate=oldDate;
    var addYear=0;
    while(newMonth>11){
        newMonth-=12;
        addYear++;
    }
    newYear+=addYear;
    if((newMonth===10||newMonth===3||newMonth===5||newMonth===8)&& oldDate>30){
        newDate = 30;
    }
    if(newMonth===1&&oldDate>28) {
        newDate = 28;
    }
    return new Date(newYear,newMonth,newDate);
}

var iso8601 = {
    now: function(){
        var d = new Date();
        return d.toISOString();
    },
    toUsDate: function(iso8601Date) {
        if(iso8601Date == null) {
            return "-";
        }
        //input format: ISO 8601
        var date = new Date(iso8601Date);
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        return date.getFullYear() + "-" + (mm > 9 ? "" : "0") + mm + "-" + (dd > 9 ? "" : "0") + dd;
    },
    toEuropeanDateTime: function(iso8601DateTime,ifNullValue) {
        if(iso8601DateTime == null) {
            return ifNullValue;
        }
        var date = new Date(iso8601DateTime);
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var hh = date.getHours();
        var ii = date.getMinutes();
        return (dd > 9 ? "" : "0") + dd + "/" +
            (mm > 9 ? "" : "0") + mm + "/" +
            date.getFullYear() +
            " " + (hh > 9 ? "" : "0") + hh + ":" +
            (ii > 9 ? "" : "0") + ii;
    },
    getHours: function(iso8601DateTime) {
        if(iso8601DateTime == null) {
            return "-";
        }
        var date = new Date(iso8601DateTime);
        var hh = date.getHours();
        return (hh > 9 ? "" : "0") + hh;
    },
    getMinutes: function(iso8601DateTime) {
        if(iso8601DateTime == null) {
            return "-";
        }
        var date = new Date(iso8601DateTime);
        var ii = date.getMinutes();
        return (ii > 9 ? "" : "0") + ii;
    },
    toEuropeanDateTimeWithSeconds: function(iso8601DateTime) {
        if(iso8601DateTime == null) {
            return "-";
        }
        var date = new Date(iso8601DateTime);
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        var hh = date.getHours();
        var ii = date.getMinutes();
        var ss = date.getSeconds();
        return (dd > 9 ? "" : "0") + dd + "/" +
            (mm > 9 ? "" : "0") + mm + "/" +
            date.getFullYear() +
            " " + (hh > 9 ? "" : "0") + hh + ":" +
            (ii > 9 ? "" : "0") + ii + ":" +
            (ss > 9 ? "" : "0") + ss;
    },
    toEuropeanDate: function(iso8601Date,ifNullValue) {
        if(iso8601Date == null) {
            return ifNullValue;
        }
        //input format: ISO 8601
        var date = new Date(iso8601Date);
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        return (dd > 9 ? "" : "0") + dd + "/" + (mm > 9 ? "" : "0") + mm + "/" + date.getFullYear();
    },
    toDateObject: function(iso8601DateTime){
        if(iso8601DateTime===null) {
            return null;
        }
        return new Date(iso8601DateTime);
    },
    fromEuropeanDateTime: function(europeanDateTime) {
        if(europeanDateTime=="" || (!europeanDateTime)){
            return null;
        }
        //IMPORTANT: this method assumes the local timezone
        //input format dd/mm/yyyy HH:ii:ss or dd/mm/yyyy HH:ii
        var dateTimeComponents = europeanDateTime.split(" ");
        var dateComponent = dateTimeComponents[0];
        var dateComponents = dateComponent.split("/");
        var timeComponent = dateTimeComponents[1];
        var timeComponents = timeComponent.split(":");
        var date;
        //new Date(year, month, day, hours, minutes, seconds, milliseconds)
        if(timeComponents.length > 2) {
            date = new Date(
                parseInt(dateComponents[2]),
                parseInt(dateComponents[1]),
                parseInt(dateComponents[0]),
                parseInt(timeComponents[0]),
                parseInt(timeComponents[1]),
                parseInt(timeComponents[2])
            );
        }
        else {
            date = new Date(
                parseInt(dateComponents[2]),
                parseInt(dateComponents[1]),
                parseInt(dateComponents[0]),
                parseInt(timeComponents[0]),
                parseInt(timeComponents[1])
            );
        }
        return date.toISOString();
    },
    fromEuropeanDate: function(europeanDate){
        //IMPORTANT: this method assumes the local timezone
        //input format dd/mm/yyyy
        var date;
        if(europeanDate === "" || (!europeanDate)) {
            return null;
        }
        else {
            var dateComponents = europeanDate.split("/");
            //new Date(year, month, day, hours, minutes, seconds, milliseconds)
            date = new Date(parseInt(dateComponents[2]), parseInt(dateComponents[1])-1, parseInt(dateComponents[0]),12);
            
        }
        return date.toISOString();
    },
    fromUsDate: function(usDate) {
        //IMPORTANT: this method assumes the local timezone
        //input format yyyy-mm-dd
        var date;
        if(usDate === "" || (!usDate)) {
            return null;
        }
        else {
            var dateComponents = usDate.split("-");
            //new Date(year, month, day, hours, minutes, seconds, milliseconds)
            date = new Date(parseInt(dateComponents[0]), parseInt(dateComponents[1])-1, parseInt(dateComponents[2]),12);
        }
        return date.toISOString();
    }
};

var validation = {
    emailIsValid: function(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    qrOrAssetIdIsValid: function(qrOrAssetId){
        var re1 = /^#[0-9]+$/;
        var re2 = /^[346789BCDFGHJKMNPQRTVWXY]+$/;
        return (re1.test(qrOrAssetId))||(re2.test(String(qrOrAssetId).toUpperCase()));
    }
};

function isEuropeanFormattedDate(str) {
    var parms = str.split(/[.\-\/]/);
    var year = parseInt(parms[2], 10);
    var month = parseInt(parms[1], 10);
    var day = parseInt(parms[0], 10);
    if(day < 1) {
        return false;
    }
    if(month > 12 || month < 1) {
        return false;
    }
    if(year < 0) {
        return false;
    }
    switch(month) {
        case 11:
        case 4:
        case 6:
        case 9:
            if(day > 30) {
                return false;
            }
            break;
        case 2:
            if(((year % 4 == 0) && (year % 100 != 0)) || ((year % 400 == 0) && (year % 100 == 0))) {
                if(day > 29) {
                    return false;
                }
            }
            else {
                if(day > 28) {
                    return false;
                }
            }
            break;
        default:
            if(day > 31) {
                return false;
            }
    }
    return true;
}

function ddmmyyyy(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    return [(dd > 9 ? "" : "0") + dd + "/",
        (mm > 9 ? "" : "0") + mm + "/",
        date.getFullYear()
    ].join("");
}

function setInnerHtml(element, html, callback) {
    element.innerHTML = html;
    setTimeout(function() {
        element.innerHTML = html;
        if(typeof callback === "function") {
            callback();
        }
    }, 5);
}

function isSmallViewport() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return w < 800;
}

function warning(message) {
    document.getElementById("warning-popup-message").innerHTML = message;
    popup("warning", false, false, false);
}

var successToastTimeout = null;
//changing timing values here requires changing #success-toast values in style.css
function successToast(htmlMessage) {
    window.clearTimeout(successToastTimeout);
    var successToastContainer = document.getElementById("success-toast");
    successToastContainer.innerHTML = htmlMessage;
    dom.addClass(successToastContainer, "fadein");
    successToastTimeout = window.setTimeout(function() {
        dom.removeClass(successToastContainer, "fadein");
        dom.addClass(successToastContainer, "show");
    }, 500);
    defineTapListenerOnElement(successToastContainer,function(e) {
        dom.removeClass(successToastContainer, "show");
        preventEvent(e);
    });
    successToastTimeout = window.setTimeout(function() {
        dom.removeClass(successToastContainer, "show");
        dom.addClass(successToastContainer, "fadeout");
        successToastTimeout = window.setTimeout(function() {
            dom.removeClass(successToastContainer, "fadeout");
        }, 500);
    }, 4500);
}

var errorToastTimeout = null;
//changing timing values here requires changing #error-toast values in style.css
function errorToast(htmlMessage) {
    window.clearTimeout(errorToastTimeout);
    var errorToastContainer = document.getElementById("error-toast");
    errorToastContainer.innerHTML = htmlMessage;
    dom.addClass(errorToastContainer, "fadein");
    errorToastTimeout = window.setTimeout(function() {
        dom.removeClass(errorToastContainer, "fadein");
        dom.addClass(errorToastContainer, "show");
    }, 500);
    defineTapListenerOnElement(errorToastContainer,function(e) {
        dom.removeClass(errorToastContainer, "show");
        preventEvent(e);
    });
    errorToastTimeout = window.setTimeout(function() {
        dom.removeClass(errorToastContainer, "show");
        dom.addClass(errorToastContainer, "fadeout");
        errorToastTimeout = window.setTimeout(function() {
            dom.removeClass(errorToastContainer, "fadeout");
        }, 500);
    }, 7500);
}

function errorPopup(htmlMessage) {
    document.getElementById("error-popup-content").innerHTML = htmlMessage;
    popup("error", false, false, false);
}

function htmlEntities(str) {
    return String(str).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
}

function preventEvent(e) {
    var ev = e || window.event;
    if(ev.stopPropagation) {
        ev.stopPropagation();
    }
    if(ev.gesture) {
        ev.gesture.stopPropagation();
    }
}

function getStyle(x, styleProp) {
    var y;
    if(x.currentStyle) {
        y = x.currentStyle[styleProp];
    }
    else {
        if(window.getComputedStyle) {
            y = document.defaultView.getComputedStyle(x, null).getPropertyValue(styleProp);
        }
    }
    return y;
}

function getWidth(x) {
    var y;
    // IE
    if(x.currentStyle) {
        y = x.clientWidth - parseInt(x.currentStyle.paddingLeft) - parseInt(x.currentStyle.paddingRight);
    }
    else {
        //Gecko
        if(window.getComputedStyle) {
            y = document.defaultView.getComputedStyle(x, null).getPropertyValue("width");
        }
    }
    return y || 0;
}

function objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function disableSelection(target) {
    //For IE This code will work
    if(typeof target.onselectstart !== "undefined") {
        target.onselectstart = function() {
            return false;
        };
    }
    else {
        //For Firefox This code will work
        if(typeof target.style.MozUserSelect !== "undefined") {
            target.style.MozUserSelect = "none";
        }
        //All other  (ie: Opera) This code will work
        else {
            target.onmousedown = function() {
                return false;
            };
        }
    }
    target.style.cursor = "default";
}

//checks if a is exactly divisible by b
//a and b MUST be strings
function isDivisible(a, b) {
    a = a.replace(",", ".");
    b = b.replace(",", ".");
    var aDecPartLen = 0;
    var bDecPartLen = 0;
    if(a.indexOf(".") > 0) {
        aDecPartLen = a.split(".")[1].length;
    }
    if(b.indexOf(".") > 0) {
        bDecPartLen = b.split(".")[1].length;
    }
    var multiplyBy = Math.pow(10, Math.max(aDecPartLen, bDecPartLen));
    return parseInt(a * multiplyBy) % parseInt(b * multiplyBy) === 0;

}

function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function uppercaseFirstLetterLowercaseTheRest(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

//adds thousands separator
function formatCurrency(x) {
    var parts = x.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    return parts.join(".").replace(".", ",");
}

function decodeHTMLEntities(text) {
    if(text === undefined || text === null) {
        return "";
    }
    var entities = [
        ["amp", "&"],
        ["apos", "'"],
        ["#x27", "'"],
        ["#x2F", "/"],
        ["#39", "'"],
        ["#47", "/"],
        ["lt", "<"],
        ["gt", ">"],
        ["nbsp", " "],
        ["quot", "\""]
    ];
    entities.forEach(function(entity) {
        text = text.replace(new RegExp("&" + entity[0] + ";", "g"), entity[1]);
    });
    return text;
}

// returns true if the element or one of its parents has the class classname
function someParentHasClass(element, classname) {
    if(element.className) {
        if(element.className.split(" ").indexOf(classname) >= 0) {
            return element;
        }
    }
    if(element.parentNode) {
        return someParentHasClass(element.parentNode, classname);
    }
    return null;
}

function someParentIsDisabled(element) {
    if(element.hasAttribute) {
        if(element.hasAttribute("disabled")) {
            return element;
        }
    }
    if(element.parentNode) {
        return someParentIsDisabled(element.parentNode);
    }
    return null;
}

function getElementsByAttribute(attribute, context) {
    context = (context || document);
    var nodeList = context.getElementsByTagName("*");
    var nodeArray = [];
    var iterator = 0;
    var node;
    while(node = nodeList[iterator++]) {
        if(node.hasAttribute(attribute)) {
            nodeArray.push(node);
        }
    }
    return nodeArray;
}

// UTILITIES END
function dateToEuropeanTimestamp(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    var hh = date.getHours();
    var ii = date.getMinutes();
    var ss = date.getSeconds();
    return (dd > 9 ? "" : "0") + dd + "/" + (mm > 9 ? "" : "0") + mm + "/" + date.getFullYear() + " " + (hh > 9 ? "" : "0") + hh + ":" + (ii > 9 ? "" : "0") + ii + ":" + (ss > 9 ? "" : "0") + ss;
}

function dateToEnglishTimestamp(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    var hh = date.getHours();
    var ii = date.getMinutes();
    var ss = date.getSeconds();
    return date.getFullYear() + "-" + (mm > 9 ? "" : "0") + mm + "-" + (dd > 9 ? "" : "0") + dd + " " + (hh > 9 ? "" : "0") + hh + ":" + (ii > 9 ? "" : "0") + ii + ":" + (ss > 9 ? "" : "0") + ss;
}

function toggleMobileMenu(e) {
    var mobileMenu = document.getElementById("navbar-left-zone");
    if((!dom.hasClass(mobileMenu, "mobile-visible")) && dom.hasClass(mobileMenu, "showable")) {
        dom.addClass(mobileMenu, "mobile-visible");
        dom.removeClass(mobileMenu, "hidable");
        setTimeout(function() {
            dom.addClass(mobileMenu, "hidable");
        }, 500);
    }
    else {
        hideMobileMenu();
    }
    preventEvent(e);
}

function hideMobileMenu(forceClose) {
    var mobileMenu = document.getElementById("navbar-left-zone");
    if(dom.hasClass(mobileMenu, "mobile-visible")) {
        if(dom.hasClass(mobileMenu, "hidable") || forceClose) {
            dom.removeClass(mobileMenu, "hidable");
            dom.removeClass(mobileMenu, "mobile-visible");
            dom.removeClass(mobileMenu, "showable");
            setTimeout(function() {
                dom.addClass(mobileMenu, "showable");
            }, 500);
        }
    }
}

//Calls ajaxGetDashboardDataByEntity depending on the content of the URL
function preHandleUrlPath(path, callback) {
    var str = path.split("/");
    var strPosition;
    var breakUrlParser;
    var ajaxReload;
    switch(str[0]) {
        case "anomalies":
            if(str[1] && str[1].length > 0) {
                ajaxGetDashboardDataByEntity(callback, "anomaly", parseInt(str[1]));
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "ticketing":
            if(str[1] && str[1].length > 0) {
                ajaxGetDashboardDataByEntity(callback, "ticket", parseInt(str[1]));
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "asset":
            if(str[1] && str[1].length > 0) {
                ajaxGetDashboardDataByEntity(callback, "asset", parseInt(str[1]));
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "intervention":
            if(str[1] && str[1].length > 0) {
                ajaxGetDashboardDataByEntity(callback, "intervention", parseInt(str[1]));
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "execute-intervention":
            if(str[1] && str[1].length > 0) {
                ajaxGetDashboardDataByEntity(callback, "intervention", parseInt(str[1]));
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "qr-code":
            if(str[1] && str[1].length > 0) {
                if(!lastScannedQrCode) {
                    lastScannedQrCode = str[1];
                }
                ajaxGetDashboardDataByEntity(callback, "qr-code", lastScannedQrCode);
            }
            else {
                ajaxGetDashboardData(callback);
            }
            break;
        case "data-room":
            strPosition = 1;
            breakUrlParser = false;
            ajaxReload = true;
            if(str[1] === undefined) {
                ajaxGetDashboardData(callback);
            }
            while((str[strPosition] !== undefined && str[strPosition] !== null) && (str[strPosition + 1] !== undefined && str[strPosition + 1] !== null) && (!breakUrlParser)) {
                switch(str[strPosition]) {
                    case "document":
                        /*str[strPosition+1] contains documentId*/
                        ajaxGetDashboardDataByEntity(callback, "document", parseInt(str[strPosition + 1]));
                        breakUrlParser = true;
                        break;
                    case "public-document":
                        if(str[strPosition + 2] !== undefined && str[strPosition + 2] !== null) {
                            /*parseInt(str[strPosition+1]) contains documentId, but getDashboardData is not needed since the document is public*/
                            ajaxGetDashboardData(callback);
                            breakUrlParser = true;
                        }
                        break;
                    case "company":
                        /*str[strPosition+1] contains companyId*/
                        ajaxGetDashboardDataByEntity(callback, "company", parseInt(str[strPosition + 1]));
                        breakUrlParser = true;
                        break;
                }
                strPosition += 2;
            }
            break;
        default:
            ajaxGetDashboardData(callback);
    }
}

//Page routing
function handleUrlPath(path) {
    initialUrlHandled = true;
    if(!path) {
        path = window.location.hash.replace("#", "");
    }
    var str = path.split("/");
    var strPosition;
    var breakUrlParser;
    var ajaxReload;
    switch(str[0]) {
        case "anomalies":
            if(str[1]) {
                selectAnomaly(parseInt(str[1]),true);
            }
            else {
                changePage("page-anomalies");
            }
            break;
        case "tickets":
        case "ticketing":
            if(str[1]) {
                selectTicket(parseInt(str[1]),true);
            }
            else {
                changePage("page-ticketing");
            }
            break;
        case "asset":
            if(str[1]) {
                ajaxGetAssetInstance(parseInt(str[1]));
            }
            else {
                changePage("page-assets");
            }
            break;
        case "intervention":
            if(str[1]) {
                selectIntervention(parseInt(str[1]));
            }
            else {
                changePage("page-calendar");
            }
            break;
        case "execute-intervention":
            if(str[1]) {
                selectIntervention(parseInt(str[1]), startIntervention);
            }
            else {
                changePage("page-calendar");
            }
            break;
        case "qr-code":
            if(str[1]) {
                changePage("page-" + str[0], false, true);
                lastScannedQrCode = str[1];
                document.getElementById("qr-code-read-value").innerHTML = "\"" + htmlEntities(lastScannedQrCode) + "\"";
                ajaxGetQrCodeData(lastScannedQrCode);
            }
            else {
                changePage("page-home");
            }
            break;
        case "data-room":
            strPosition = 1;
            breakUrlParser = false;
            ajaxReload = true;
            changePage("page-" + str[0], false, true);
            changeHashWithoutChangingPage("data-room");
            while((str[strPosition] !== undefined && str[strPosition] !== null) && (str[strPosition + 1] !== undefined && str[strPosition + 1] !== null) && (!breakUrlParser)) {
                switch(str[strPosition]) {
                    case "document":
                        ajaxReloadDataRoomFilters(parseInt(str[strPosition + 1]));
                        breakUrlParser = true;
                        ajaxReload = false;
                        break;
                    case "public-document":
                        if(str[strPosition + 2] !== undefined && str[strPosition + 2] !== null) {
                            ajaxRenderPublicSharedDocument(parseInt(str[strPosition + 1]), str[strPosition + 2]);
                            breakUrlParser = true;
                            ajaxReload = false;
                        }
                        break;
                    case "document-type":
                        document.getElementById("data-room-filter-document-type").setAttribute("value", str[strPosition + 1]);
                        dataRoomDocumentTypeFilterChanged(false);
                        break;
                    case "document-subtype":
                        document.getElementById("data-room-filter-document-subtype").setAttribute("value", str[strPosition + 1]);
                        break;
                }
                strPosition += 2;
            }
            if(ajaxReload) {
                ajaxReloadDataRoomFilters();
            }
            break;
        case "logout":
            ajaxLogout(false);
            break;
        default:
            if(str[0].trim() !== "") {
                if(document.getElementById("page-" + str[0]) != null) {
                    changePage("page-" + str[0], false, true);
                }
                else {
                    changePage("page-home");
                }
            }
            else {
                changePage("page-home");
            }
    }
}

function changeHashWithoutChangingPage(redirectTo) {
    hashChangedInternally = true;
    window.location.hash = "#" + redirectTo;
}

function urlNeedsLogin() {
    var needsLogin = true;
    var path = window.location.hash.replace("#", "");
    var str = path.split("/");
    var strPosition;
    var breakUrlParser;
    switch(str[0]) {
        case "data-room":
            strPosition = 1;
            breakUrlParser = false;
            while((str[strPosition] !== undefined && str[strPosition] !== null) && (str[strPosition + 1] !== undefined && str[strPosition + 1] !== null) && (!breakUrlParser)) {
                switch(str[strPosition]) {
                    case "public-document":
                        if(str[strPosition + 2] !== undefined && str[strPosition + 2] !== null) {
                            needsLogin = false;
                            breakUrlParser = true;
                        }
                        break;
                }
                strPosition += 2;
            }
            break;
        case "qr-code":
            needsLogin = false;
            breakUrlParser = true;
            break;
    }
    return needsLogin;
}

function newUserPasswordChanged() {
    var strenghtViewer = document.getElementById("create-user-password-strenght");
    var passwordMatchViewer = document.getElementById("create-user-password-matches");
    var newPassword = document.getElementById("new-user-password").value;
    var confirmPassword = document.getElementById("new-user-confirm-password").value;
    var score = getPasswordScore(newPassword);
    if(score < 8) {
        dom.addClass(strenghtViewer, "weak");
        dom.removeClass(strenghtViewer, "good");
        dom.removeClass(strenghtViewer, "strong");
    }
    else {
        dom.removeClass(strenghtViewer, "weak");
        if(score > 11.5) {
            dom.removeClass(strenghtViewer, "good");
            dom.addClass(strenghtViewer, "strong");
        }
        else {
            dom.addClass(strenghtViewer, "good");
            dom.removeClass(strenghtViewer, "strong");
        }
    }
    if(newPassword.length === 0 || confirmPassword.length === 0) {
        passwordMatchViewer.innerHTML = "<strong class='red-state'>-</strong>";
    }
    else {
        if(newPassword === confirmPassword) {
            passwordMatchViewer.innerHTML = "<strong class='green-state'>Si</strong>";
        }
        else {
            passwordMatchViewer.innerHTML = "<strong class='red-state'>No</strong>";
        }
    }
}

function clearChangePasswordForm() {
    document.getElementById("change-password-old-password").removeAttribute("disabled");
    document.getElementById("change-password-old-password").setAttribute("value", "");
    document.getElementById("change-password-new-password").setAttribute("value", "");
    document.getElementById("change-password-confirm-new-password").setAttribute("value", "");
    var strenghtViewer = document.getElementById("change-password-password-strenght");
    dom.removeClass(strenghtViewer, "weak");
    dom.removeClass(strenghtViewer, "good");
    dom.removeClass(strenghtViewer, "strong");
}

function changePasswordPasswordChanged() {
    var strenghtViewer = document.getElementById("change-password-password-strenght");
    var newPassword = document.getElementById("change-password-new-password").value;
    var score = getPasswordScore(newPassword);
    if(score < 8) {
        dom.addClass(strenghtViewer, "weak");
        dom.removeClass(strenghtViewer, "good");
        dom.removeClass(strenghtViewer, "strong");
    }
    else {
        dom.removeClass(strenghtViewer, "weak");
        if(score > 11.5) {
            dom.removeClass(strenghtViewer, "good");
            dom.addClass(strenghtViewer, "strong");
        }
        else {
            dom.addClass(strenghtViewer, "good");
            dom.removeClass(strenghtViewer, "strong");
        }
    }
}

function nextContractFormPage(oldPage) {
    var dateStart;
    var dateEnd;
    var contractor;
    var customer;
    var contractForm = document.getElementById("create-contract-popup");
    switch(oldPage) {
        case "form-page-1":
            dateStart = document.getElementById("contract-date-start").value.split("/").reverse().join("-");
            dateEnd = document.getElementById("contract-date-end").value.split("/").reverse().join("-");
            if(dateStart && dateEnd) {
                dateStart = parseDate(dateStart);
                dateEnd = parseDate(dateEnd);
                if(dateStart.getTime() >= dateEnd.getTime()) {
                    errorPopup("La data di inizio contratto deve essere precedente alla data di fine contratto.");
                }
                else {
                    contractor = document.getElementById("contract-contractor");
                    customer = document.getElementById("contract-customer");
                    loadCustomerSitesForContract(customer.value);
                    dom.addClass(contractForm.querySelector("." + oldPage), "hidden");
                    createSubcategoryGraphicalMultiSelector(
                        "create-contract-categories-container",
                        "contract",
                        false,{},{},{}
                    );
                    dom.removeClass(contractForm.querySelector(".form-page-2"), "hidden");
                }
            }
            else {
                errorPopup("Errore nelle date inserite");
            }
            break;
        case "form-page-2":
            dom.addClass(contractForm.querySelector("." + oldPage), "hidden");
            dom.removeClass(contractForm.querySelector(".form-page-3"), "hidden");
            break;
        case "form-page-3":
            dom.addClass(contractForm.querySelector("." + oldPage), "hidden");
            dom.removeClass(contractForm.querySelector(".form-page-4"), "hidden");
            ajaxLoadContractActivityBySubcategories();
            break;
        case "form-page-4":
            dom.addClass(contractForm.querySelector("." + oldPage), "hidden");
            dom.removeClass(contractForm.querySelector(".form-page-5"), "hidden");
            break;
    }
    scroll.refreshPopupById("create-contract", false);
}

function getSiteNameFromId(siteId){
    for(var i=0;i<sites.length;i++){
        if(sites[i].siteId==siteId){
            return sites[i].name;
        }
    }
}

function checkHolidayForDate(europeanDate){
    var holidayMessage = getHolidayMessageForDate(europeanDate);
    if(holidayMessage !== null){
        successToast(holidayMessage);
    }
}

function getHolidayMessageForDate(europeanDate){
    var checkedDate = new Date(iso8601.fromEuropeanDate(europeanDate));

    if(checkedDate.getDate()===25&&checkedDate.getMonth()===11){
        return "Il giorno selezionato (25 dicembre) è Natale";
    }
    if(checkedDate.getDate()===26&&checkedDate.getMonth()===11){
        return "Il 26 dicembre è una festività nazionale in Italia: Santo Stefano";
    }
    if(checkedDate.getDate()===1&&checkedDate.getMonth()===4){
        return "Il primo maggio è una festività nazionale in Italia: Festa dei Lavoratori";
    }
    if(checkedDate.getDate()===1&&checkedDate.getMonth()===0){
        return "Il primo gennaio è una festività nazionale in Italia: Capodanno";
    }
    if(checkedDate.getDate()===6&&checkedDate.getMonth()===0){
        return "Il 6 gennaio è una festività nazionale in Italia: Epifania";
    }
    if(checkedDate.getDate()===25&&checkedDate.getMonth()===3){
        return "Il 25 aprile è una festività nazionale in Italia: Anniversario della liberazione d'Italia";
    }
    if(checkedDate.getDate()===2&&checkedDate.getMonth()===5){
        return "Il 2 giugno è una festività nazionale in Italia: Festa della Repubblica";
    }
    if(checkedDate.getDate()===15&&checkedDate.getMonth()===7){
        return "Il 15 agosto è una festività nazionale in Italia: Ferragosto";
    }
    if(checkedDate.getDate()===1&&checkedDate.getMonth()===10){
        return "Il primo novembre è una festività nazionale in Italia: Tutti i Santi";
    }
    if(
        (checkedDate.getDate()===1&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2018)
        ||(checkedDate.getDate()===21&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2019)
        ||(checkedDate.getDate()===12&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2020)
        ||(checkedDate.getDate()===4&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2021)
        ||(checkedDate.getDate()===17&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2022)
        ||(checkedDate.getDate()===9&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2023)
        ||(checkedDate.getDate()===31&&checkedDate.getMonth()===2&&checkedDate.getFullYear()===2024)
        ||(checkedDate.getDate()===20&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2025)
    ){
        return "la data selezionata è una festività nazionale in Italia: Pasqua";
    }
    if(
        (checkedDate.getDate()===22&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2019)
        ||(checkedDate.getDate()===2&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2018)
        ||(checkedDate.getDate()===13&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2020)
        ||(checkedDate.getDate()===5&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2021)
        ||(checkedDate.getDate()===18&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2022)
        ||(checkedDate.getDate()===10&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2023)
        ||(checkedDate.getDate()===1&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2024)
        ||(checkedDate.getDate()===21&&checkedDate.getMonth()===3&&checkedDate.getFullYear()===2025)
    ){
        return "la data selezionata è una festività nazionale in Italia: Lunedì dell'Angelo";
    }
    if(checkedDate.getDay() === 0){
        return "Il giorno selezionato è una domenica";
    }
    if(checkedDate.getDay() === 6){
        return "Il giorno selezionato è un sabato";
    }
    return null;
}

var createInterventionPopup = {
    createInterventionStartPikaday: null,
    assetCountForSubcategoriesBySite: [],
    init: function(createOnDate) {
        if(createOnDate!==undefined && createOnDate!==null) {
            createInterventionPopup.createInterventionStartPikaday.setDate(createOnDate);
        }
        popup("create-intervention", false, false, false);
    },
    ajaxGetAssetsCountForSubcategoriesBySite: function(siteId){
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    createInterventionPopup.assetCountForSubcategoriesBySite[siteId]=[];
                    response.subcategoriesCount.forEach(function(subcategoryCount){
                        createInterventionPopup.assetCountForSubcategoriesBySite[siteId][subcategoryCount.subcategoryId]=subcategoryCount.count;
                    });
                    popup("create-intervention-step-2", true, false, false);
                }
                else {
                    setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                }
            }
            catch(e) {
                console.error(e);
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getAssetsCountForSubcategoriesBySite.php?siteId=" + siteId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    }
};

var createContractPopup = {
    executorBgColours:["#2196F3","#FF9800","#8BC34A","#E91E63","#CDDC39","#3F51B5"],
    usedExecutorsBySubcategory:[],
    addSubcategoryRelatedExecutors: function(subcategoryId){
        document.getElementById("select-executor-subcategory-id").value=subcategoryId;
        popup("select-executor", false, false, false);
    },
    addChosenRelatedExecutor: function() {
        closePopup();
        var subcategoryId=parseInt(document.getElementById("select-executor-subcategory-id").value);
        var executorsContainer=document.getElementById("create-contract-related-executors-"+subcategoryId);
        var executorId=parseInt(document.getElementById("select-executor-selector").value);

        companies.forEach(function(executor){
            if(executor.companyId===executorId){
                var background= createContractPopup.getAvailableBgColorForSubcategory(subcategoryId);
                executorsContainer.innerHTML+="<div class='create-contract-inline-related-executor'>"+
                    "<button type='button' onclick='createContractPopup.removeChosenRelatedExecutor(this);' data-executor-id='"+executor.companyId+"' data-background='"+background+"' style='background:"+background+";'><i class='fa fa-wrench' aria-hidden='true'></i></button> "+executor.companyName+"</div>";
            }
        });
        createContractPopup.refreshRelatedExecutors(subcategoryId);
    },
    getAvailableBgColorForSubcategory:function(subcategoryId){
        if(typeof(createContractPopup.usedExecutorsBySubcategory[subcategoryId])==="undefined"){
            createContractPopup.usedExecutorsBySubcategory[subcategoryId]=[];
        }
        for(var i=0;i<createContractPopup.executorBgColours.length;i++){
            if(createContractPopup.usedExecutorsBySubcategory[subcategoryId].indexOf(createContractPopup.executorBgColours[i])<0){
                createContractPopup.usedExecutorsBySubcategory[subcategoryId].push(createContractPopup.executorBgColours[i]);
                return createContractPopup.executorBgColours[i];
            }
        }
        return "rgba(0,0,0,0.2)";
    },
    removeChosenRelatedExecutor:function(button){
        var subcategoryId=button.parentNode.parentNode.getAttribute("data-subcategory-id");
        delete(createContractPopup.usedExecutorsBySubcategory[subcategoryId][createContractPopup.usedExecutorsBySubcategory[subcategoryId].indexOf(button.getAttribute("data-background"))]);
        dom.removeElement(button.parentNode);
        createContractPopup.refreshRelatedExecutors(subcategoryId);
    },
    getSiteIdsInUse: function(){
        var sitesCollection = document.querySelectorAll("#add-contract-customer-sites-container .customer-site-for-contract-checkbox");
        var choosedSites=[];
        sitesCollection.forEach(function(site) {
            if(site.getAttribute("data-checked")==="1") {
                choosedSites.push(parseInt(site.getAttribute("data-site")));
            }
        });
        return choosedSites;
    },
    generateInterventionDates: function(){
        var subcategoryTables=document.querySelectorAll(".create-contract-activities-table");
        createContractPopup.generateInterventionDatesFromTables(subcategoryTables);
    },
    generateInterventionDatesForSubcategory: function(subcategoryId){
        if(!ghostClickController.isOk()){
            return false;
        }
        var subcategoryTables=document.querySelectorAll("#create-contract-subcat-activities-table-"+subcategoryId);
        createContractPopup.generateInterventionDatesFromTables(subcategoryTables);
    },
    generateInterventionDatesFromTables: function(subcategoryTables){
        var subcategoryId;
        var contractStartDate = new Date(iso8601.fromEuropeanDate(document.getElementById("contract-date-start").value));
        var lastInterventionForActivity = null;
        var contractEndDate = new Date(iso8601.fromEuropeanDate(document.getElementById("contract-date-end").value));
        subcategoryTables.forEach(function(subcategoryTable){
            subcategoryId=parseInt(subcategoryTable.getAttribute("data-subcategory-id"));
            var activityRows=subcategoryTable.querySelectorAll(".generate-contract-activity-row");
            var activityId,activityFrequency,activityFrequencyType,activityFrequencyInDays;
            var tempHtml="";
            var sitesInContract = createContractPopup.getSiteIdsInUse();
            var siteOffset=0; //To perform interventions on different sites in different days
            sitesInContract.forEach(function(siteInContract){
                tempHtml+="<div data-site-id='"+siteInContract+"'><div class='contract-site-label'>"+getSiteNameFromId(siteInContract)+"</div>";
                var interventionDates={};
                activityRows.forEach(function(activityRow){
                    
                    var dateKey;
                    activityId=parseInt(activityRow.getAttribute("data-activity-id"));
                    activityFrequency=activityRow.querySelector("#create-contract-frequency-activity-id-"+activityId).value;
                    activityFrequencyType=activityRow.querySelector("#create-contract-frequency-type-activity-id-"+activityId).value;
                    if(activityFrequency!=0){
                        switch(activityFrequencyType){
                            case "anni":
                                activityFrequencyInDays=activityFrequency*365;
                                break;
                            case "settimane":
                                activityFrequencyInDays=activityFrequency*7;
                                break;
                            case "giorni":
                                activityFrequencyInDays=activityFrequency;
                                break;
                        }
                        if(activityFrequencyType==="giorni"||activityFrequencyType==="settimane"||activityFrequencyType==="anni"){
                            if(lastInterventionForActivity==null){
                                interventionDate=addDays(contractStartDate,siteOffset);
                            }
                            else{
                                interventionDate=addDays(lastInterventionForActivity,activityFrequencyInDays+siteOffset);
                                if(interventionDate<contractStartDate){
                                    //The intervention is late
                                    interventionDate=contractStartDate;
                                }
                            }
                            while(interventionDate <= contractEndDate) {
                                dateKey=(parseInt(interventionDate.getTime()/1000/60/60/24)).toString();
                                if(interventionDates[dateKey]==undefined){
                                    interventionDates[dateKey]={};
                                    interventionDates[dateKey].activities=[];
                                    interventionDates[dateKey].date=interventionDate;
                                }
                                interventionDates[dateKey].activities.push(activityId);
                                interventionDate=addDays(interventionDate,activityFrequencyInDays);
                            }
                        }
                        if(activityFrequencyType==="mesi") {
                            if(lastInterventionForActivity==null){
                                interventionDate=addDays(contractStartDate,siteOffset);
                            }
                            else{
                                interventionDate=addDays(addMonths(lastInterventionForActivity,activityFrequency),siteOffset);
                                if(interventionDate<contractStartDate){
                                    //The intervention is late
                                    interventionDate=contractStartDate;
                                }
                            }
                            while(interventionDate <= contractEndDate) {
                                dateKey=(parseInt(interventionDate.getTime()/1000/60/60/24)).toString();
                                if(interventionDates[dateKey]==undefined){
                                    interventionDates[dateKey]={};
                                    interventionDates[dateKey].activities=[];
                                    interventionDates[dateKey].date=interventionDate;
                                }
                                interventionDates[dateKey].activities.push(activityId);
                                interventionDate=addMonths(interventionDate,activityFrequency);
                            }
                        }
                    }
                });
                siteOffset++;
                Object.entries(interventionDates).forEach(function(interventionDate){
                    tempHtml+="<div class='contract-planned-date' data-site-id='"+siteInContract+"' data-activities='"+JSON.stringify(interventionDate[1].activities)+"'><input class='contract-planned-date-value' title='Data intervento' onchange='checkHolidayForDate(iso8601.toEuropeanDate(iso8601.fromUsDate(this.value)))' value='"+iso8601.toUsDate(interventionDate[1].date)+"' type='date' required='required'>"+
                    "<div class='contract-planned-date-executors'><button type='button' data-subcategory-id='"+subcategoryId+"' onclick='createContractPopup.toggleExecutorButton(this)'>?</button></div></div>";
                });
                tempHtml+="</div>";
            });
            document.getElementById("create-intervention-generated-dates-"+(subcategoryId.toString())).innerHTML=tempHtml;

        });
    },
    refreshRelatedExecutors: function(subcategoryId){
        var executorsForSubcategory=document.querySelectorAll("#create-contract-related-executors-"+subcategoryId+" .create-contract-inline-related-executor button");
        var executorDots=document.querySelectorAll("#create-intervention-generated-dates-"+subcategoryId+" .contract-planned-date-executors button");
        if(executorsForSubcategory.length===0){
            executorDots.forEach(function(button){
                setInnerHtml(button,"?");
                button.removeAttribute("data-executor-id");
                button.removeAttribute("style");
            });
        }
        if(executorsForSubcategory.length===1){
            executorDots.forEach(function(button){
                setInnerHtml(button,"<i class='fa fa-wrench' aria-hidden='true'></i>");
                button.setAttribute("data-executor-id",executorsForSubcategory[0].getAttribute("data-executor-id"));
                button.setAttribute("style",executorsForSubcategory[0].getAttribute("style"));
            });
        }
        if(executorsForSubcategory.length>1){
            var availableExecutors=[];
            executorsForSubcategory.forEach(function(executorButton){
                availableExecutors.push(executorButton.getAttribute("data-executor-id"));
            });
            executorDots.forEach(function(button){
                if(availableExecutors.indexOf(button.getAttribute("data-executor-id"))<0) {
                    setInnerHtml(button, "<i class='fa fa-wrench' aria-hidden='true'></i>");
                    button.setAttribute("data-executor-id", executorsForSubcategory[0].getAttribute("data-executor-id"));
                    button.setAttribute("style", executorsForSubcategory[0].getAttribute("style"));
                }
            });
        }
    },
    toggleExecutorButton: function(button){
        var subcategoryId=button.getAttribute("data-subcategory-id");
        var executorsForSubcategory=document.querySelectorAll("#create-contract-related-executors-"+subcategoryId+" .create-contract-inline-related-executor button");
        if(executorsForSubcategory.length>1) {
            var executors=[];
            executorsForSubcategory.forEach(function(executor){
                executors.push({
                    id:executor.getAttribute("data-executor-id"),
                    background:executor.style.background
                });
            });
            for(var i=0;i<executors.length;i++){
                if(executors[i].id==button.getAttribute("data-executor-id")){
                    if(i>0) {
                        button.style.background=executors[i-1].background;
                        button.setAttribute("data-executor-id", executors[i-1].id);
                    }
                    else{
                        button.style.background=executors[executors.length-1].background;
                        button.setAttribute("data-executor-id", executors[executors.length-1].id);
                    }
                    break;
                }
            }
        }
    }
};

function ajaxLoadContractActivityBySubcategories() {
    var subcatCheckboxes = document.querySelectorAll(".contract-contract-subcats");
    var subcategoriesList = "";
    subcatCheckboxes.forEach(function(subcatCheckbox) {
        if(subcatCheckbox.getAttribute("data-checked")==="1") {
            subcategoriesList += subcatCheckbox.id.replace("contract-subcat-selector-", "") + ",";
        }
    });
    subcategoriesList = subcategoriesList.slice(0, -1);//remove last comma
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempHtml = "";
                var frequenciesMarkup = "";
                var i = 0;
                frequencies.forEach(function(frequency) {
                    if(i === 0) {
                        frequenciesMarkup += "<option selected value='" + frequency.frequencyType + "'>" + frequency.frequencyType + "</option>";
                    }
                    else {
                        frequenciesMarkup += "<option value='" + frequency.frequencyType + "'>" + frequency.frequencyType + "</option>";
                    }
                    i++;
                });
                var defaultFrequencyType = frequencies[0].frequencyType;
                var defaultFrequency = 1;
                subcategories.forEach(function(subcategory) {
                    var subcatCheckbox = document.getElementById("contract-subcat-selector-" + subcategory.subcategoryId);
                    if(subcatCheckbox) {
                        if(subcatCheckbox.getAttribute("data-checked")==="1") {
                            var len = 0;
                            var hasStatutory = false;
                            var statutoryMinFrequency;
                            var statutoryFrequencyType;
                            var tempTable = "";
                            var mustNotCollapse = false;
                            if(response.activities[subcategory.subcategoryId]) {
                                response.activities[subcategory.subcategoryId].forEach(function(thisActivity) {
                                    if(thisActivity.disabledFrom===null) {
                                        len++;
                                        var aoati = thisActivity.activityOnAssetTypeId;
                                        tempTable += "<div data-activity-id='" + aoati + "' class='generate-contract-activity-row' id='create-contract-activity-row-" + aoati + "'><div class='frequency-selection-col'>";
                                        if (thisActivity.statutoryRequirementFrequency) {
                                            tempTable += "<i class='fa fa-shield' aria-hidden='true' onclick='popup( \"shield-meaning-help\" ,false,false,false);'></i> <span class='create-contract-statutory-indication'>Almeno ogni " + thisActivity.statutoryRequirementFrequency + " " + thisActivity.statutoryRequirementFrequencyType + "</span><br>" +
                                                "<button type='button' class='frequency-placeholder' onclick='toggleFrequencyPlaceholder(" + aoati + ");'>Ogni " + thisActivity.statutoryRequirementFrequency + " " + thisActivity.statutoryRequirementFrequencyType + "</button>" +
                                                "<div class='frequency-selection hidden'>Ogni<br>" +
                                                "<input onchange='createContractPopup.generateInterventionDatesForSubcategory(" + subcategory.subcategoryId + ")' type='number' class='create-contract-choosed-activity' id='create-contract-frequency-activity-id-" + aoati + "' maxlength='4' min='1' step='1' required value='" + thisActivity.statutoryRequirementFrequency + "' max='" + thisActivity.statutoryRequirementFrequency + "'><br>" +
                                                "<select onchange='createContractPopup.generateInterventionDatesForSubcategory(" + subcategory.subcategoryId + ")' class='frequency-type-selector' id='create-contract-frequency-type-activity-id-" + aoati + "' disabled='disabled'>" +
                                                "<option value='" + thisActivity.statutoryRequirementFrequencyType + "'>" + thisActivity.statutoryRequirementFrequencyType +
                                                "</option></select>";
                                            if (hasStatutory) {
                                                if (thisActivity.statutoryRequirementFrequencyType !== statutoryFrequencyType) {
                                                    mustNotCollapse = true;
                                                }
                                                else {
                                                    if (thisActivity.statutoryRequirementFrequency < statutoryMinFrequency) {
                                                        statutoryMinFrequency = thisActivity.statutoryRequirementFrequency;
                                                    }
                                                }
                                            }
                                            else {
                                                hasStatutory = true;
                                                statutoryMinFrequency = thisActivity.statutoryRequirementFrequency;
                                                statutoryFrequencyType = thisActivity.statutoryRequirementFrequencyType;
                                            }
                                        }
                                        else {
                                            tempTable += "<button type='button' class='frequency-placeholder' onclick='toggleFrequencyPlaceholder(" + aoati + ");'>Ogni " + defaultFrequency + " " + defaultFrequencyType + "</button>" +
                                                "<div class='frequency-selection hidden'>Ogni<br>" +
                                                "<input onchange='createContractPopup.generateInterventionDatesForSubcategory(" + subcategory.subcategoryId + ")' type='number' class='create-contract-choosed-activity' id='create-contract-frequency-activity-id-" + aoati + "' maxlength='4' max='9999' min='0' required value='1'><br>" +
                                                "<select onchange='createContractPopup.generateInterventionDatesForSubcategory(" + subcategory.subcategoryId + ")' class='frequency-type-selector' id='create-contract-frequency-type-activity-id-" + aoati + "' required='required'>" + frequenciesMarkup + "</select>";
                                        }
                                        tempTable += "</div></div><div class='create-contract-activity-description'>" + thisActivity.description + "</div></div>";
                                    }
                                });
                                tempTable += "</div>";
                            }
                            if((!response.activities[subcategory.subcategoryId])||len===0) {
                                tempTable += "<div data-subcategory-id='"+subcategory.subcategoryId+"' id='create-contract-subcat-activities-table-" + subcategory.subcategoryId + "' class='no-results-message hidden'>Nessuna attività disponibile per questa sottocategoria.</div>";
                            }
                            tempHtml += "<div class='create-contract-subcategory-header "+(mustNotCollapse?"opened":"")+"' id='create-contract-subcategory-header-"+subcategory.subcategoryId+"'><div class='category-small b-" + subcategory.background + "'>" + subcategory.icon + "</div>" + subcategory.name + " <span class='generate-contract-activity-counter'>" + len + " attività</span>";
                            if(mustNotCollapse) {
                                tempHtml+="</div>";
                            }
                            else {
                                tempHtml += "<button " +
                                    " type='button' " +
                                    " onclick='addContractEditSubcategoryFrequency(" + subcategory.subcategoryId + ");'>" +
                                    "Modifica singole attività <i class='fa fa-chevron-down' id='arrow-down-contract-activities-subcategory-" + subcategory.subcategoryId + "' aria-hidden='true'></i></button>"+
                                    "<button " +
                                    " type='button' " +
                                    " id='show-edit-global-frequency-btn-subcat-" + subcategory.subcategoryId + "' " +
                                    " class='frequency-placeholder' " +
                                    " onclick='addContractEditSubcategoryGlobalFrequency(" + subcategory.subcategoryId + ");'" +
                                    ">"+
                                    "Ogni " + (hasStatutory?statutoryMinFrequency:defaultFrequency) + " " + (hasStatutory?statutoryFrequencyType:defaultFrequencyType) + "</button>" +
                                    "<div " +
                                    " class='global-frequency-selection hidden' " +
                                    " id='global-frequency-selection-" + subcategory.subcategoryId + "'" +
                                    ">" +
                                    "Ogni " +
                                    "<input " +
                                    " type='number' " +
                                    " onchange='addContractPropagateGlobalFrequencyEditToChildren(" + subcategory.subcategoryId + ");' " +
                                    " onkeyup='addContractPropagateGlobalFrequencyEditToChildren(" + subcategory.subcategoryId + ");' " +
                                    " class='create-contract-choosed-activity-subc' " +
                                    " maxlength='4' "+
                                    " max='" + (hasStatutory?statutoryMinFrequency:9999) + "' " +
                                    " min='"+(hasStatutory?1:0)+"' " +
                                    " value='" + (hasStatutory?statutoryMinFrequency:defaultFrequency) + "'" +
                                    ">" +
                                    "<select " +
                                    " class='frequency-type-global-selector' " +
                                    " id='create-contract-frequency-type-subc-" + subcategory.subcategoryId +"' ";
                                if(hasStatutory) {
                                    tempHtml += "disabled='disabled'><option value='" + statutoryFrequencyType + "'>" + statutoryFrequencyType + "</option></select></div>" +
                                        "<i class='fa fa-shield inline-statutory-sign' aria-hidden='true' onclick='popup( \"shield-meaning-help\" ,false,false,false);'></i></div>";
                                }
                                else {
                                    tempHtml += "onchange='addContractPropagateGlobalFrequencyEditToChildren(" + subcategory.subcategoryId + ");'>" + frequenciesMarkup + "</select></div></div>";
                                }
                            }
                            tempHtml += "<div " +
                                " id='create-contract-subcat-activities-table-" + subcategory.subcategoryId + "' " +
                                " data-subcategory-id='"+subcategory.subcategoryId+"' " +
                                " class='create-contract-activities-table "+(mustNotCollapse?"":"hidden")+"'" +
                                ">";
                            tempHtml += tempTable+"</div>";
                            tempHtml += "<div " +
                                " class='create-contract-subcategory-footer' " +
                                " id='create-contract-subcategory-footer-"+subcategory.subcategoryId+"'" +
                                ">" +
                                "<div class='create-contract-subcategory-footer-title'>Esecutori coinvolti</div><br>"+
                                "<div " +
                                " class='create-contract-related-executors' " +
                                " data-subcategory-id='" + subcategory.subcategoryId + "' " +
                                " id='create-contract-related-executors-" + subcategory.subcategoryId + "'" +
                                ">" +
                                "</div>" +
                                "<button " +
                                " class='create-contract-add-related-company-btn' " +
                                " onclick='createContractPopup.addSubcategoryRelatedExecutors(" + subcategory.subcategoryId + ")' " +
                                " type='button'" +
                                ">+</button>"+
                                "<br><div class='create-contract-subcategory-footer-title'>Date generate</div><div class='create-intervention-generated-dates' id='create-intervention-generated-dates-"+subcategory.subcategoryId+"'></div>"+
                                "</div><br>";

                        }
                    }
                });
                document.getElementById("contract-activities-choosed-subc-container").innerHTML = tempHtml;
                scroll.refreshPopupById("create-contract", false);
                createContractPopup.generateInterventionDates();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
                popup("outcome", false, false, false);
            }
        }
        catch(e) {
            outcomePopup.innerHTML = "<div class='message error'> Si è verificato un Errore durante l'elaborazione.</div>";
            console.error(e);
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getActivitiesOnSubcategories.php?s=" + subcategoriesList, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function addContractPropagateGlobalFrequencyEditToChildren(subcategoryId) {
    var childrenFrequencySelectors = document.getElementById("create-contract-subcat-activities-table-" + subcategoryId).querySelectorAll(".create-contract-choosed-activity");
    var childrenFrequencyTypeSelectors = document.getElementById("create-contract-subcat-activities-table-" + subcategoryId).querySelectorAll(".frequency-type-selector");
    var childrenFrequencyPlaceholder = document.getElementById("create-contract-subcat-activities-table-" + subcategoryId).querySelectorAll(".frequency-placeholder");
    var frequencyToPropagateSelector = document.getElementById("global-frequency-selection-" + subcategoryId).querySelector(".create-contract-choosed-activity-subc");
    var frequencyTypeToPropagateSelector = document.getElementById("global-frequency-selection-" + subcategoryId).querySelector(".frequency-type-global-selector");
    var frequencyTypeToPropagate = frequencyTypeToPropagateSelector.value;
    var maxFrequencyToPropagate = parseInt(frequencyTypeToPropagateSelector.getAttribute("max"));
    var minFrequencyToPropagate = parseInt(frequencyTypeToPropagateSelector.getAttribute("min"));
    var frequencyToPropagate = parseInt(frequencyToPropagateSelector.value);
    if(frequencyToPropagate > maxFrequencyToPropagate) {
        frequencyToPropagate = maxFrequencyToPropagate;
        frequencyToPropagateSelector.value = maxFrequencyToPropagate;
    }
    if(frequencyToPropagate < minFrequencyToPropagate) {
        frequencyToPropagate = minrequencyToPropagate;
    }
    var i = 0;
    var maxFrequency,minFrequency;
    childrenFrequencySelectors.forEach(function(childrenFrequencySelector) {
        maxFrequency = parseInt(childrenFrequencySelector.getAttribute("max"));
        minFrequency = parseInt(childrenFrequencySelector.getAttribute("min"));
        if(frequencyToPropagate <= maxFrequency && frequencyToPropagate >= minFrequency) {
            childrenFrequencySelector.value = frequencyToPropagate;
            childrenFrequencyTypeSelectors[i].value = frequencyTypeToPropagate;
            childrenFrequencyPlaceholder[i].innerHTML = "Ogni " + frequencyToPropagate + " " + frequencyTypeToPropagate;
        }
        i++;
    });
    createContractPopup.generateInterventionDatesForSubcategory(subcategoryId);
}

function addContractEditSubcategoryGlobalFrequency(subcategoryId) {
    dom.addClass(document.getElementById("show-edit-global-frequency-btn-subcat-" + subcategoryId), "hidden");
    dom.removeClass(document.getElementById("global-frequency-selection-" + subcategoryId), "hidden");
}

function addContractEditSubcategoryFrequency(subcategoryId) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var activitiesForSubcategoryTable = document.getElementById("create-contract-subcat-activities-table-" + subcategoryId);
    var arrow = document.getElementById("arrow-down-contract-activities-subcategory-" + subcategoryId);
    var header = document.getElementById("create-contract-subcategory-header-"+subcategoryId);
    if(dom.hasClass(activitiesForSubcategoryTable, "hidden")) {
        dom.addClass(header,"opened");
        dom.removeClass(arrow, "fa-chevron-down");
        dom.addClass(arrow, "fa-chevron-up");
        dom.removeClass(activitiesForSubcategoryTable, "hidden");
    }
    else {
        dom.removeClass(header,"opened");
        dom.addClass(activitiesForSubcategoryTable, "hidden");
        dom.removeClass(arrow, "fa-chevron-up");
        dom.addClass(arrow, "fa-chevron-down");
    }
    scroll.refreshPopupById("create-contract", false);
}

function toggleFrequencyPlaceholder(activityId) {
    var tr = document.getElementById("create-contract-activity-row-" + activityId);
    dom.removeClass(tr.querySelector(".frequency-selection"), "hidden");
    dom.addClass(tr.querySelector(".frequency-placeholder"), "hidden");
    scroll.refreshPopupById("create-contract", false);
}

function calendarYearSiteChanged() {
    document.getElementById("calendar-site-selector").value = document.getElementById("calendar-year-site-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarYearExecutorChanged() {
    document.getElementById("calendar-executor-selector").value = document.getElementById("calendar-year-executor-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarExecutorChanged() {
    document.getElementById("calendar-year-executor-selector").value = document.getElementById("calendar-executor-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarSiteChanged() {
    document.getElementById("calendar-year-site-selector").value = document.getElementById("calendar-site-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarYearCategoryChanged() {
    document.getElementById("calendar-category-selector").value = document.getElementById("calendar-year-category-selector").value;
    calendarCategoryChanged();
}

function calendarCategoryChanged() {
    var selectedCategory = document.getElementById("calendar-category-selector").value;
    document.getElementById("calendar-year-category-selector").value = selectedCategory;
    var availableSubcategoriesOptions = "";
    subcategories.forEach(function(subcategory) {
        if(selectedCategory === "" || subcategory.categoryId == selectedCategory) {
            availableSubcategoriesOptions += "<option value='" + subcategory.subcategoryId + "'>" + subcategory.name + "</option>";
        }
    });
    availableSubcategoriesOptions += "<option value='' selected='selected'>-- Tutte --</option>";
    document.getElementById("calendar-subcategory-selector").innerHTML = availableSubcategoriesOptions;
    document.getElementById("calendar-year-subcategory-selector").innerHTML = availableSubcategoriesOptions;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarSubcategoryChanged() {
    document.getElementById("calendar-year-subcategory-selector").value = document.getElementById("calendar-subcategory-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function calendarYearSubcategoryChanged() {
    document.getElementById("calendar-subcategory-selector").value = document.getElementById("calendar-year-subcategory-selector").value;
    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);
}

function subcategoryGraphicalMultiSelectorChanged(prefix, category) {
    var categoryToggleBtn = document.getElementById(prefix + "-category-toggle-children-selection-cat-" + category);
    var children = document.querySelectorAll("." + prefix + "-subcat-child-of-cat-" + category);
    var allChecked=true,allNotChecked=true;
    children.forEach(function(child) {
        if(child.getAttribute("data-checked")==="1") {
            allNotChecked=false;
        }
        if(child.getAttribute("data-checked")==="0") {
            allChecked=false;
        }
    });
    if(allChecked&&allNotChecked||(!allChecked)&&(!allNotChecked)) {
        categoryToggleBtn.removeAttribute("data-checked");
    }
    else {
        if(allChecked) {
            categoryToggleBtn.setAttribute("data-checked","1");
        }
        else {
            categoryToggleBtn.setAttribute("data-checked","0");
        }
    }
    switch(prefix){
        case "calculate-licenses":
            calculateLicenseCostImport();
            break;
    }
}

function toggleSubcategoryGraphicalMultiSelectorSubcategories(prefix, category) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var children = document.querySelectorAll("." + prefix + "-subcat-child-of-cat-" + category);
    var countChecked = 0;
    children.forEach(function(child) {
        if(child.getAttribute("data-checked")==="1") {
            countChecked++;
        }
    });
    if(countChecked < (children.length / 2)) {
        children.forEach(function(child) {
            child.setAttribute("data-checked","1");
        });
    }
    else {
        children.forEach(function(child) {
            child.setAttribute("data-checked","0");
        });
    }
    subcategoryGraphicalMultiSelectorChanged(prefix, category);
}

function toggleSubcategoryGraphicalMultiSelectorSubcategory(prefix, category, subcategory) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var checkbox=document.getElementById(prefix + "-subcat-selector-" + subcategory);
    if(checkbox.getAttribute("data-checked")==="1") {
        checkbox.setAttribute("data-checked","0");
    }
    else {
        checkbox.setAttribute("data-checked","1");
    }
    subcategoryGraphicalMultiSelectorChanged(prefix, category);
}

function toggleSubcategoryGraphicalMultiSelectorSubcategoriesVisibility(prefix, category) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var cateogryArrow = document.getElementById(prefix + "-toggle-arrow-category-" + category);
    var categoryChildren = document.getElementById(prefix + "-children-of-cat-" + category);
    var categoryRow;
    if(dom.hasClass(categoryChildren, "hidden")) {
        cateogryArrow.innerHTML = "<i class='fa fa-chevron-up' aria-hidden='true'></i>";
        dom.removeClass(categoryChildren, "hidden");
        try{
            categoryRow=cateogryArrow.parentNode.parentNode;
            dom.addClass(categoryRow,"open");
        }catch(e){
            console.error(e);
        }
    }
    else {
        cateogryArrow.innerHTML = "<i class='fa fa-chevron-down' aria-hidden='true'></i>";
        dom.addClass(categoryChildren, "hidden");
        try{
            categoryRow=cateogryArrow.parentNode.parentNode;
            dom.removeClass(categoryRow,"open");
        } catch(e){
            // ...
        }
    }
    switch(prefix){
        case "calculate-licenses":
            scroll.refreshPopupById("calculate-license-cost", false);
            break;
        case "add-intervention":
            scroll.refreshPopupById("create-intervention-step-2", false);
            break;
        case "contract":
            scroll.refreshPopupById("create-contract", false);
            break;
        case "exp-contract":
            scroll.refreshPopupById("export-contract-specifications", false);
            break;
    }
}

function createSubcategoryGraphicalMultiSelector(containerId, prefix, filterActivities, filterCategoryGroup, filterCategory, filterSubcategory) {
    var treeArea = document.getElementById(containerId);
    var tempHtml = "";
    var k = 0;
    categoryGroups.forEach(function() {
        if(k !== 1) {
            tempHtml += "<div class='inline-graphical-subcategories-container'>";
        }
        if ((!filterActivities) || (filterCategoryGroup[categoryGroups[k].categoryGroupId])) {
            categories.forEach(function (category) {
                if (category.categoryGroupId === categoryGroups[k].categoryGroupId) {
                    if ((!filterActivities) || (filterCategory[category.categoryId])) {
                        tempHtml += "<li>" +
                            "<div class='contract-category-label b-" + category.background + "'>" +
                            "<button " +
                            " type='button' " +
                            " id='" + prefix + "-category-toggle-children-selection-cat-" + category.categoryId + "'" +
                            " class='contract-category-toggle-children-selection' " +
                            " onclick='toggleSubcategoryGraphicalMultiSelectorSubcategories(\"" + prefix +
                            "\"," + category.categoryId + ");'" +
                            " data-checked='0'" +
                            ">" +
                            "</button>" +
                            "<button " +
                            " type='button' " +
                            " onclick='toggleSubcategoryGraphicalMultiSelectorSubcategoriesVisibility(\"" + prefix +
                            "\"," + category.categoryId + ");' " +
                            " class='contract-category-toggle-children-visibility b-" + category.background + "'" +
                            ">" +
                            "<span class='inline-category-icon'>" + category.icon + "</span>" +
                            category.name +
                            "<span class='contract-category-toggle-arrow' " +
                            " id='" + prefix + "-toggle-arrow-category-" + category.categoryId + "'" +
                            ">" +
                            "<i class='fa fa-chevron-down' aria-hidden='true'></i>" +
                            "</span>" +
                            "</button>" +
                            "</div>" +
                            "<ul id='" + prefix + "-children-of-cat-" + category.categoryId + "' class='hidden'>";
                        var foundSubcategories = 0;
                        subcategories.forEach(function (subcategory) {
                            if (subcategory.categoryId === category.categoryId) {
                                if (subcategory.subcategoryId !== null && (((prefix=="add-intervention"||prefix=="contract")&&subcategory.deletion==null)||(prefix!="add-intervention"&&prefix!="contract"))) {
                                    if((!filterActivities) || (filterSubcategory[subcategory.subcategoryId])) {
                                        var assetsCounter = null;
                                        if (containerId == "create-intervention-subcat-selector-container") {
                                            var siteId = document.getElementById("create-intervention-site").value;
                                            if (createInterventionPopup.assetCountForSubcategoriesBySite[siteId] !== undefined) {
                                                if (createInterventionPopup.assetCountForSubcategoriesBySite[siteId][subcategory.subcategoryId]) {
                                                    assetsCounter = parseInt(createInterventionPopup.assetCountForSubcategoriesBySite[siteId][subcategory.subcategoryId]);
                                                }
                                                else {
                                                    assetsCounter = 0;
                                                }
                                            }
                                        }
                                        var counterZero = (assetsCounter ? "" : "counter-zero");
                                        var assetsCounterHtml = "";
                                        if (assetsCounter !== null) {
                                            assetsCounterHtml = "<span class='subcat-asset-counter " + counterZero + "'>" + assetsCounter + "</span>";
                                        }
                                        tempHtml += "<li>" +
                                            "<button" +
                                            " class='toggle-subcategory-graphical-multi-selector-subcategory-button'" +
                                            " onclick='toggleSubcategoryGraphicalMultiSelectorSubcategory(\"" + prefix + "\"," + category.categoryId + "," + subcategory.subcategoryId + ");'" +
                                            " type='button'" +
                                            ">" +
                                            "<span " +
                                            " data-checked='0' " +
                                            " class='subcat-checkbox-button " + prefix + "-contract-subcats " + prefix + "-subcat-child-of-cat-" + category.categoryId + "' " +
                                            " data-category='" + category.categoryId + "' " +
                                            " data-assets-counter='" + assetsCounter + "' " +
                                            " id='" + prefix + "-subcat-selector-" + subcategory.subcategoryId + "'" +
                                            ">" +
                                            "</span>" +
                                            "<span>" +
                                            assetsCounterHtml + subcategory.name +
                                            "</span>" +
                                            "</button>" +
                                            "</li>";
                                        foundSubcategories++;
                                    }
                                }
                            }
                        });
                        if (foundSubcategories === 0) {
                            tempHtml += "<li><span class='no-results-span'>Nessuna sottocategoria</span></li>";
                        }
                        tempHtml += "</ul>";
                        tempHtml += "</li>";
                    }
                }
            });
        }
        if(k !== 0) {
            tempHtml += "</div>";
        }
        k++;
    });
    setInnerHtml(
        treeArea,
        tempHtml,
        function() {
            switch(prefix) {
                case "calculate-licenses":
                    scroll.refreshPopupById("calculate-license-cost", false);
                    break;
                case "add-intervention":
                    scroll.refreshPopupById("create-intervention-step-2", false);
                    break;
                case "contract":
                    scroll.refreshPopupById("create-contract", false);
                    break;
                case "exp-contract":
                    scroll.refreshPopupById("export-contract-specifications", false);
                    break;
            }
        }
    );
}

function ajaxCreateActivitySelectorFromSubcategoriesGraphicalMultiSelector(containerId, prefix) {
    var subcatCheckboxes = document.querySelectorAll("." + prefix + "-contract-subcats");
    var subcategoriesList = "";
    var totalAssetsCounter=0;
    var someSubcategoryIsEmpty=false;
    subcatCheckboxes.forEach(function(subcatCheckbox) {
        if(subcatCheckbox.getAttribute("data-checked")==="1") {
            var assetCounter;
            if((assetCounter=subcatCheckbox.getAttribute("data-assets-counter"))!==null){
                assetCounter=parseInt(assetCounter);
                totalAssetsCounter+=assetCounter;
                if(assetCounter===0){
                    someSubcategoryIsEmpty=true;
                }
                else {
                    subcategoriesList += subcatCheckbox.id.replace(prefix + "-subcat-selector-", "") + ",";
                }
            }
            else {
                subcategoriesList += subcatCheckbox.id.replace(prefix + "-subcat-selector-", "") + ",";
            }
        }
    });
    if(totalAssetsCounter===0){
        errorToast("Nessun asset presente nelle categorie selezionate!");
        return;
    }
    else{
        if(someSubcategoryIsEmpty){
            setTimeout(function(){errorToast("Attenzione: Le sottocategorie non contenenti asset sono state omesse");},500);
        }
        closePopup();
        popup("create-intervention-step-3", true, false, false);
    }
    subcategoriesList = subcategoriesList.slice(0, -1);//remove last comma
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempHtml = "";
                subcategories.forEach(function(subcategory) {
                    var subcatCheckbox;
                    if(subcatCheckbox = document.getElementById(prefix + "-subcat-selector-" + subcategory.subcategoryId)) {
                        if(subcatCheckbox.getAttribute("data-checked")==="1") {
                            var assetCounter=subcatCheckbox.getAttribute("data-assets-counter");
                            if(assetCounter===null||assetCounter>0){
                                var len = 0;
                                var tempTable = "";
                                if(response.activities[subcategory.subcategoryId]) {
                                    response.activities[subcategory.subcategoryId].forEach(function(thisActivity) {
                                        if(thisActivity.disabledFrom===null) {
                                            len++;
                                            var aoati = thisActivity.activityOnAssetTypeId;
                                            tempTable += "<div class='select-activity-label'>" +
                                                "<button" +
                                                " type='button'" +
                                                " onclick='toggleActivityForNewIntervention("+aoati+",\"" + prefix + "\")'" +
                                                ">" +
                                                "<span " +
                                                " class='" + prefix + "-activity-checkbox " + prefix + "-activity-child-of-cat-" + subcategory.subcategoryId + "' " +
                                                " id='" + prefix + "-checkbox-for-activity-" + aoati + "'" +
                                                " data-checked='0'" +
                                                ">" +
                                                "</span>" +
                                                "<span>" +
                                                thisActivity.description +
                                                "</span>" +
                                                "</button>" +
                                                "</div>";
                                        }
                                    });
                                }
                                else {
                                    tempTable += "<div " +
                                        "id='" + prefix + "-subcat-activities-table-" + subcategory.subcategoryId + "' " +
                                        "class='no-results-message hidden'" +
                                        ">" +
                                        "Nessuna attività disponibile per questa sottocategoria." +
                                        "</div>" +
                                        "<br>";
                                }
                                tempHtml += "<br>" +
                                    "<div " +
                                    " class='create-contract-subcategory-header activity-selector-subcategory-header'" +
                                    ">" +
                                    "<div " +
                                    " class='category-small b-" + subcategory.background + "'>" + subcategory.icon +
                                    "</div>" + subcategory.name + " (" + len + " attività)" +
                                    "<button " +
                                    " type='button' " +
                                    " onclick='selectAllActivitiesForNewIntervention(" + subcategory.subcategoryId + ",\"" + prefix + "\");'" +
                                    "><i class='fa fa-check' aria-hidden='true'></i> Seleziona tutte" +
                                    "</button>" +
                                    "</div>" +
                                    "</div><br>";
                                tempHtml += tempTable;
                            }
                        }
                    }
                });
                setInnerHtml(
                    document.getElementById(containerId),
                    tempHtml,
                    function(){scroll.refreshPopupById("create-intervention-step-3", false);}
                );
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
                popup("outcome", false, false, false);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'> Si è verificato un errore durante l'elaborazione.</div>");
            console.error(e);
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getActivitiesOnSubcategories.php?s=" + subcategoriesList, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function toggleActivityForNewIntervention(activityId,prefix) {
    var checkbox = document.getElementById(prefix + "-checkbox-for-activity-" + activityId);
    var newState = checkbox.getAttribute("data-checked")!=="1";
    checkbox.setAttribute("data-checked",(newState?"1":"0"));
}

function selectAllActivitiesForNewIntervention(subcategoryId, prefix) {
    var checkboxes = document.querySelectorAll("." + prefix + "-activity-child-of-cat-" + subcategoryId);
    var newState;
    if(checkboxes[0]) {
        newState = checkboxes[0].getAttribute("data-checked")!=="1";
    }
    checkboxes.forEach(function(checkbox) {
        checkbox.setAttribute("data-checked",(newState?"1":"0"));
    });
}

function sendInterventionMailToStefano(interventionId) {
    var interventionToNotify = {};
    interventionToNotify.interventionId = parseInt(interventionId);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            if(xhttp.status === 200) {

            }
        }
        catch(e) {
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(e), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendInterventionMailToStefano.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToNotify));
}

function ajaxCreateIntervention() {
    var interventionDate = document.getElementById("create-intervention-start-date").value;
    if(interventionDate) {
        interventionDate = interventionDate.split("/").reverse().join("-");
    }
    var toCreateIntervention = {};
    toCreateIntervention.startDateTime = interventionDate + " " + ui.pickerPopupButton.getValue("create-intervention-start-time") + ":00";
    toCreateIntervention.endDateTime = interventionDate + " " + ui.pickerPopupButton.getValue("create-intervention-end-time") + ":00";
    toCreateIntervention.site = parseInt(document.getElementById("create-intervention-site").value);
    toCreateIntervention.executor = parseInt(document.getElementById("create-intervention-executor").value);
    toCreateIntervention.activities = [];
    var selectedActivities = document.querySelectorAll(".add-intervention-activity-checkbox");
    selectedActivities.forEach(function(selectedActivity) {
        if(selectedActivity.getAttribute("data-checked")==="1") {
            toCreateIntervention.activities.push(parseInt(selectedActivity.id.replace("add-intervention-checkbox-for-activity-", "")));
        }
    });
    if(toCreateIntervention.activities.length < 1) {
        errorPopup("E' necessario selezionare almeno un'attività per poter generare l'intervento!");
    }
    else {
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                closePopup();
                successToast("Intervento creato con successo");
                renderCalendar(lastMonth, lastYear);
                //Hardcoded username for demo purposes only
                if(loggedInUserInfo.username === "stefano.frattini") {
                    sendInterventionMailToStefano(response.interventionId);
                }
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/createIntervention.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(toCreateIntervention));
    }
}

function populateExportContractSpecCategories() {
    var activitiesWithCategoryGroup = [];
    var activitiesWithCategory = [];
    var activitiesWithSubcategory = [];
    lastOpenedContract.activities.forEach(function(activity) {
        activitiesWithCategoryGroup[activity.categoryGroupId] = true;
        activitiesWithCategory[activity.categoryId] = true;
        activitiesWithSubcategory[activity.subcategoryId] = true;
    });
    popup("export-contract-specifications", false, false, false);
    createSubcategoryGraphicalMultiSelector(
        "export-contract-spec-categories-container",
        "exp-contract",
        true,
        activitiesWithCategoryGroup,
        activitiesWithCategory,
        activitiesWithSubcategory
    );
}

function prevContactFormPage(oldPage) {
    var contractForm = document.getElementById("create-contract-popup");
    dom.addClass(contractForm.querySelector("." + oldPage), "hidden");
    switch(oldPage) {
        case "form-page-2":
            dom.removeClass(contractForm.querySelector(".form-page-1"), "hidden");
            break;
        case "form-page-3":
            dom.removeClass(contractForm.querySelector(".form-page-2"), "hidden");
            break;
        case "form-page-4":
            dom.removeClass(contractForm.querySelector(".form-page-3"), "hidden");
            break;
        case "form-page-5":
            dom.removeClass(contractForm.querySelector(".form-page-4"), "hidden");
            break;
    }
    scroll.refreshPopupById("create-contract", false);
}

function toggleCustomerSiteForContract(siteId) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var checkbox = document.getElementById("customer-site-for-contract-" + siteId);
    var newValue = (checkbox.getAttribute("data-checked")!=="1")?"1":"0";
    checkbox.setAttribute("data-checked",newValue);
}

function loadCustomerSitesForContract(companyId) {
    var contractActivitySiteSelector = document.getElementById("add-contract-customer-sites-container");
    var tempHtml = "Selezionare i siti coinvolti nel contratto:<br><br>";
    var matchedSites = 0;
    sites.forEach(function(site) {
        if(site.ownedBy == companyId) {
            tempHtml += "<button" +
                " type='button'" +
                " class='customer-site-for-contract-button'" +
                " onclick='toggleCustomerSiteForContract(" + site.siteId + ");'" +
                ">" +
                "<span " +
                " data-site='" + site.siteId + "'" +
                " data-checked='0'" +
                " id='customer-site-for-contract-" + site.siteId + "' " +
                " class='customer-site-for-contract-checkbox'" +
                ">" +
                "<span>" + site.name + "</span>" +
                "</button><br>";
            matchedSites++;
        }
    });
    if(matchedSites === 0) {
        document.getElementById("continue-contract-creation-sites-selected-btn").disabled = "disabled";
        tempHtml = "Nessun sito disponibile per questo committente.<br>&Egrave; possibile aggiungere un sito attraverso la scheda <strong>Immobili</strong>.";
    }
    else {
        document.getElementById("continue-contract-creation-sites-selected-btn").removeAttribute("disabled");
    }
    setInnerHtml(contractActivitySiteSelector, tempHtml);
}

function ajaxSendErrorReport(requestURI, requestBody, errorMessage) {
    var errorReport = {};
    errorReport.serverMessage = errorMessage;
    errorReport.requestURI = requestURI;
    errorReport.requestBody = requestBody;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            if(xhttp.status === 200) {
                console.log("Segnalazione di errore inviata automaticamente");
            }
            else {
                console.error("Errore durante l'invio di una segnalazione.");
                console.error(xhttp.responseText);
            }
        }
        catch(e) {
            console.error("Errore durante l'invio di una segnalazione.");
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendErrorReport.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(errorReport));
}

function ajaxCountSiteAssetsBySubcategory() {
    var contractActivitySiteSelector = document.getElementById("contract-activity-sites");
    var newActivitySubcategorySelector = document.getElementById("contract-activity-subcategory-selector");
    var selectedSite = contractActivitySiteSelector.value;
    var selectedSubcategory = newActivitySubcategorySelector.value;
    if(selectedSite === undefined || selectedSubcategory === undefined) {
        return;
    }
    var resultArea = document.getElementById("contract-activity-asset-counter");
    resultArea.innerHTML = "<span class='loading small'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            setInnerHtml(resultArea, parseInt(xhttp.responseText));
        }
        else {
            var response = JSON.parse(xhttp.responseText);
            setInnerHtml(resultArea, "<div class='message error'> Errore nel reperire il numero di asset coinvolti: " + response.message + "</div>");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getCountSiteAssetsBySubcategory.php?siteId=" + selectedSite + "&subcategoryId=" + selectedSubcategory, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function loadTicketMailLog(ticketId) {
    var toWriteArea = document.querySelector("#view-ticket-mail-log-popup .popup-content div");
    setInnerHtml(
        toWriteArea,
        "<span class='loading small'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){scroll.refreshPopupById("view-ticket-mail-log", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table id='ticket-mail-log-table'>";
            tempHtml += "<tr><th>Stato ticket</th><th>Destinatario</th><th>Da inviare</th><th>Data e ora tentativo di invio</th><th>Stato mail</th><th>Oggetto mail</th></tr>";
            response.outgoingMails.forEach(function(outgoingMail) {
                tempHtml += "<tr><td>" + ((outgoingMail.ticketStateFrom == null) ? "Nessuno" : outgoingMail.ticketStateFrom) + " > " + outgoingMail.ticketStateTo + "</td>";
                tempHtml += "<td>" + outgoingMail.recipient.replace(new RegExp(",", "g"), ", ") + "</td><td>" + ((outgoingMail.toSendAfterDateTime == null) ? "Subito" : outgoingMail.toSendAfterDateTime) + "</td><td>" + iso8601.toEuropeanDateTimeWithSeconds(outgoingMail.isoSendingDateTime) + "</td><td>" + outgoingMail.mailState + "</td><td>" + outgoingMail.subject + "</td></tr>";
            });
            tempHtml += "</table>";
            setInnerHtml(
                toWriteArea,
                tempHtml,
                function(){scroll.refreshPopupById("view-ticket-mail-log", false);}
            );
        }
        else {
            setInnerHtml(
                toWriteArea,
                "<div class='message error'> Errore: " + response.message + "</div>",
                function(){scroll.refreshPopupById("view-ticket-mail-log", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getTicketMailLog.php?ticketId=" + ticketId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function resetAddContract() {
    var contractForm = document.getElementById("create-contract-popup");
    newContract = {};
    newContract.activities = [];
    newContractActivityCounter = 0;
    newContractActivityUniqueCounter = 0;
    document.getElementById("contract-date-start").value = null;
    document.getElementById("contract-date-end").value = null;
    document.getElementById("contract-contractor").disabled = false;
    document.getElementById("contract-customer").disabled = false;
    dom.removeClass(contractForm.querySelector(".form-page-1"), "hidden");
    dom.addClass(contractForm.querySelector(".form-page-2"), "hidden");
    dom.addClass(contractForm.querySelector(".form-page-3"), "hidden");
    dom.addClass(contractForm.querySelector(".form-page-4"), "hidden");
    dom.addClass(contractForm.querySelector(".form-page-5"), "hidden");
    scroll.refreshPopupById("create-contract", false);
}

function ajaxCreateContract() {
    newContract = {};
    newContract.dateTo = document.getElementById("contract-date-end").value.split("/").reverse().join("-");
    newContract.dateFrom = document.getElementById("contract-date-start").value.split("/").reverse().join("-");
    newContract.customerId = parseInt(document.getElementById("contract-customer").value);
    newContract.contractorId = parseInt(document.getElementById("contract-contractor").value);
    newContract.orderNumber = document.getElementById("contract-order-number").value;
    newContract.dates = [];
    newContract.activities = [];

    var dates = document.querySelectorAll(".contract-planned-date");
    dates.forEach(function(date) {
        var contractDate={};
        contractDate.siteId = parseInt(date.getAttribute("data-site-id"));
        contractDate.executorId = parseInt(date.getElementsByTagName("button")[0].getAttribute("data-executor-id"));
        contractDate.activities=[];
        date.getAttribute("data-activities").replace("[","").replace("]","").split(",").forEach(function(activityId){
            contractDate.activities.push(parseInt(activityId));
        });
        contractDate.plannedStartDate=iso8601.fromUsDate(date.querySelector(".contract-planned-date-value").value);
        newContract.dates.push(contractDate);
    });

    var activities = document.querySelectorAll(".frequency-selection-col");
    activities.forEach(function(activity){
        var contractActivity={};
        contractActivity.activityForSubcategoryId=parseInt(activity.querySelector(".create-contract-choosed-activity").id.replace("create-contract-frequency-activity-id-",""));
        contractActivity.frequency=parseInt(activity.querySelector(".create-contract-choosed-activity").value);
        contractActivity.frequencyType=activity.querySelector(".frequency-type-selector").value;
        createContractPopup.getSiteIdsInUse().forEach(function(siteId){
            contractActivity.siteId = siteId;
            newContract.activities.push(contractActivity);
        });
    });

    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            ajaxGetContracts();
            successToast("Contratto registrato con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createContract.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newContract));
}

function ajaxDeleteInterventionActivity() {
    var toDeleteActivity = {};
    toDeleteActivity.activityId = selectedInterventionActivity;
    toDeleteActivity.interventionId = selectedIntervention;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Attività eliminata con successo");
                selectIntervention(selectedIntervention);
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toDeleteActivity), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toDeleteActivity), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteInterventionActivity.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(toDeleteActivity));
}

function ajaxCloneContract(toCloneContractId) {
    var toCloneContract = {};
    toCloneContract.contractId = toCloneContractId;
    closePopup();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                ajaxGetContracts();
                successToast("Contratto clonato con successo, il nuovo contratto ha numero identificativo " + response.newContractId + ".");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toCloneContract), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toCloneContract), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/cloneContract.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(toCloneContract));
}

function ajaxAddActivitiesToIntervention() {
    var interventionToEdit = {};
    interventionToEdit.interventionId = selectedIntervention;
    interventionToEdit.subcategoryId = document.getElementById("add-activities-to-intervention-subcategory").value;
    if(interventionToEdit.subcategoryId === "") {
        delete interventionToEdit.subcategoryId;
    }
    else {
        interventionToEdit.subcategoryId = parseInt(interventionToEdit.subcategoryId);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(selectedIntervention);
                closePopup();
                successToast(response.insertedActivities + " attività aggiunte con successo all'intervento.");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToEdit), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToEdit), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/addActivitiesToIntervention.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToEdit));
}

function ajaxDisableIntervention() {
    var interventionToDisable = {};
    if(lastOpenedIntervention.interventionId) {
        interventionToDisable.interventionId = parseInt(lastOpenedIntervention.interventionId);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Intervento disabilitato");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToDisable), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToDisable), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/disableIntervention.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToDisable));
}

function ajaxEnableIntervention() {
    var interventionToEnable = {};
    if(lastOpenedIntervention.interventionId) {
        interventionToEnable.interventionId = parseInt(lastOpenedIntervention.interventionId);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Intervento abilitato");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToEnable), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionToEnable), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/enableIntervention.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToEnable));
}

function editInterventionOrderNumber() {
    var tempHtml="";
    var currentCompanyFound=false;
    var executorFound=false;
    lastOpenedIntervention.orderNumbers.forEach(function(orderNumber){
        tempHtml+="<div class='entry'><input type='hidden' class='order-number-company-id' value='"+orderNumber.orderNumberCompany+"'><div>"+orderNumber.orderNumberCompanyName+"</div>";
        tempHtml+="<div><input title='Numero di commessa'  id='order-number-value-company-"+orderNumber.orderNumberCompany+"' type='text' maxlength='20' minlength='0' value='"+orderNumber.orderNumber+"'></div></div>";
        if(currentCompanyId===orderNumber.orderNumberCompany){
            currentCompanyFound=true;
        }
        if(lastOpenedIntervention.executorId===orderNumber.orderNumberCompany){
            executorFound=true;
        }
    });
    if(!currentCompanyFound){
        tempHtml+="<div class='entry'><input type='hidden' class='order-number-company-id' value='"+currentCompanyId+"'><div>"+userPermissions[0].companyName+"</div><div><input title='Numero di commessa' id='order-number-value-company-"+currentCompanyId+"' type='text' maxlength='20' minlength='0' value=''></div></div>";
    }
    if(!executorFound){
        tempHtml+="<div class='entry'><input type='hidden' class='order-number-company-id' value='"+lastOpenedIntervention.executorId+"'><div>"+lastOpenedIntervention.executor+"</div><div><input title='Numero di commessa' id='order-number-value-company-"+lastOpenedIntervention.executorId+"' type='text' maxlength='20' minlength='0' value=''></div></div>";
    }
    setInnerHtml(document.getElementById("intervention-order-numbers-list-container"),tempHtml);
    popup("set-intervention-order-number", true, false, false);
}

function ajaxEditInterventionOrderNumbers() {
    var requestData = {};
    if(lastOpenedIntervention.interventionId) {
        requestData.interventionId = parseInt(lastOpenedIntervention.interventionId);
    }
    var orderNumberCompanies=document.querySelectorAll(".order-number-company-id");
    requestData.orderNumbers=[];
    orderNumberCompanies.forEach(function(orderNumberCompany){
        var orderNumber={};
        orderNumber.companyId = parseInt(orderNumberCompany.value);
        orderNumber.orderNumber = document.getElementById("order-number-value-company-"+orderNumber.companyId).value;
        requestData.orderNumbers.push(orderNumber);
    });
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Numero di commessa modificato correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editInterventionOrderNumbers.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxRequestInterventionValidation() {
    var interventionEdit = {};
    if(lastOpenedIntervention.interventionId) {
        interventionEdit.interventionId = parseInt(lastOpenedIntervention.interventionId);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Richiesta di validazione inviata");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/requestInterventionValidation.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionEdit));
}

function editInterventionFireLogBookNumber() {
    document.getElementById("edit-intervention-fire-log-book-number").value = ((lastOpenedIntervention.fireLogBookNumber === null) ? "" : lastOpenedIntervention.fireLogBookNumber);
    popup("set-intervention-fire-log-book-number", true, false, false);
}

function ajaxEditInterventionFireLogBookNumber() {
    var interventionEdit = {};
    interventionEdit.fireLogBookNumber = document.getElementById("edit-intervention-fire-log-book-number").value;
    if(lastOpenedIntervention.interventionId) {
        interventionEdit.interventionId = parseInt(lastOpenedIntervention.interventionId);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Numero identificativo intervento per registro antincendio modificato correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionEdit), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editInterventionFireLogBookNumber.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionEdit));
}

function ajaxGetInterventionsAvailableToMerge() {
    if(lastOpenedIntervention !== null) {
        var toMergeInterventionDate = document.getElementById("merge-interventions-date").value;
        if(isEuropeanFormattedDate(toMergeInterventionDate)) {
            toMergeInterventionDate = toMergeInterventionDate.split("/").reverse().join("-");
        }
        var toWriteArea = document.getElementById("to-merge-intervention-selector");
        toWriteArea.innerHTML = "<span class='loading small'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    toWriteArea.innerHTML = "Sono presenti <strong>" + response.interventions.length + "</strong> interventi nel sito <strong>" + lastOpenedIntervention.siteName + "</strong> e con " + (lastOpenedIntervention.executor === null ? "<strong>nessun esecutore assegnato " : ("esecutore <strong>" + lastOpenedIntervention.executor)) + "</strong> per la data indicata<br><br>";
                    var tempHtml = "";
                    response.interventions.forEach(function(intervention) {
                        if(intervention.interventionId == lastOpenedIntervention.interventionId) {
                        tempHtml += "<button " +
                            " type='button' " +
                            " class='pseudo-radio-button'" +
                            ">" +
                            "<span " +
                            " class='pseudo-radio intervention-to-merge' " +
                            " data-value='" + intervention.interventionId + "'" +
                            " data-checked='0'" +
                            ">" +
                            "<span>" +
                            "<strong>Questo intervento</strong> (non può essere unito con se stesso)"+
                            "</span>" +
                            "</button>";
                        }
                        else {
                            //tickets cannot be merged
                            if(intervention.activities[0].category==null){
                                tempHtml += "<button " +
                                    " type='button' " +
                                    " class='pseudo-radio-button'" +
                                    ">" +
                                    "<span " +
                                    " class='pseudo-radio intervention-to-merge' " +
                                    " data-value='" + intervention.interventionId + "'" +
                                    " data-checked='0'" +
                                    ">" +
                                    "<span>" +
                                    "<strong>Intervento " + intervention.interventionId + "</strong> (non può essere unito)"+
                                    "</span>" +
                                    "</button>";
                            }
                            else {
                                tempHtml += "<button " +
                                    " type='button'" +
                                    " class='pseudo-radio-button'" +
                                    " onclick='togglePseudoRadioByClass(\"intervention-to-merge\",this);'" +
                                    ">" +
                                    "<span " +
                                    " class='pseudo-radio intervention-to-merge' "+
                                    " data-value='" + intervention.interventionId + "'" +
                                    " data-checked='0'" +
                                    ">" +
                                    "</span>" +
                                    "<span>" +
                                    "<strong>Intervento " + intervention.interventionId + "</strong>:";
                                intervention.activities.forEach(function(activity) {
                                    tempHtml += " " + activity.subcategory + " (" + activity.activitiesForSubcategoryCount + ")";
                                });
                                tempHtml += "</span></button><br>";
                            }
                        }
                    });
                    toWriteArea.innerHTML += tempHtml;
                    scroll.refreshPopupById("merge-interventions", false);
                }
                else {
                    toWriteArea.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                    scroll.refreshPopupById("merge-interventions", false);
                    ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
                }
            }
            catch(e) {
                toWriteArea.innerHTML = "<div class='message error'>Errore (Codice HTTP " + xhttp.status + ")</div>";
                scroll.refreshPopupById("merge-interventions", false);
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
        };
        if(globalFilterByCompany) {
            xhttp.open("GET", BASE_URL + "/ws/getInterventionsByFilters.php?companyId=" + globalFilterByCompany + "&date=" + toMergeInterventionDate + "&site=" + lastOpenedIntervention.siteId + "&executor=" + (lastOpenedIntervention.executorId === null ? "" : lastOpenedIntervention.executorId), true);
        }
        else {
            xhttp.open("GET", BASE_URL + "/ws/getInterventionsByFilters.php?date=" + toMergeInterventionDate + "&site=" + lastOpenedIntervention.siteId + "&executor=" + (lastOpenedIntervention.executorId === null ? "" : lastOpenedIntervention.executorId), true);
        }
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    }
}

function ajaxMergeInterventions() {
    var interventionsMerge = {};
    interventionsMerge.deletedInterventionId = getPseudoRadioValueByClass("intervention-to-merge");
    if(interventionsMerge.deletedInterventionId !== null) {
        interventionsMerge.deletedInterventionId = parseInt(interventionsMerge.deletedInterventionId);
    }
    else {
        delete interventionsMerge.deletedInterventionId;
    }
    interventionsMerge.mergedInterventionId = lastOpenedIntervention.interventionId;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Interventi uniti correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionsMerge), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionsMerge), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/mergeInterventions.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionsMerge));
}

function ajaxSplitInterventions() {
    var interventionSplit = {};
    interventionSplit.splittedInterventionId = lastOpenedIntervention.interventionId;
    interventionSplit.currentInterventionDate = document.getElementById("split-interventions-date-1").value.split("/").reverse().join("-");
    interventionSplit.currentInterventionStartTime = ui.pickerPopupButton.getValue("split-intervention-1-start-time") + ":00";
    interventionSplit.currentInterventionEndTime = ui.pickerPopupButton.getValue("split-intervention-1-end-time") + ":00";
    interventionSplit.newInterventionDate = document.getElementById("split-interventions-date-2").value.split("/").reverse().join("-");
    interventionSplit.newInterventionStartTime = ui.pickerPopupButton.getValue("split-intervention-2-start-time") + ":00";
    interventionSplit.newInterventionEndTime = ui.pickerPopupButton.getValue("split-intervention-2-end-time") + ":00";
    interventionSplit.newInterventionActivites = [];
    lastOpenedIntervention.activities.forEach(function(activity) {
        if(document.getElementById("split-intervention-assign-activity-" + activity.interventionActivityId).value === "2") {
            interventionSplit.newInterventionActivites.push(activity.interventionActivityId);
        }
    });
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                selectIntervention(lastOpenedIntervention.interventionId);
                closePopup();
                successToast("Intervento diviso correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionSplit), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(interventionSplit), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/splitIntervention.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionSplit));
}

function ajaxCreateActivityForSubcategory() {
    var newActivity = {};
    newActivity.subcategory = document.getElementById("add-activity-for-subc-subcategory").value;
    if(newActivity.subcategory === "") {
        delete newActivity.subcategory;
    }
    else {
        newActivity.subcategory = parseInt(newActivity.subcategory);
    }
    newActivity.description = document.getElementById("add-activity-for-subc-description").value;
    //this means that there's a law defining minimum frequency of this activity
    if(document.getElementById("add-activity-for-subc-is-statutory-requirement").getAttribute("data-checked")==="1") {
        newActivity.isStatutoryRequirement = true;
        newActivity.relatedLaw = document.getElementById("add-activity-for-subc-law").value;
        if(newActivity.relatedLaw === "") {
            delete newActivity.relatedLaw;
        }
        else {
            newActivity.relatedLaw = parseInt(newActivity.relatedLaw);
        }
        newActivity.minFrequency = document.getElementById("add-activity-for-subc-frequency").value;
        if(newActivity.minFrequency === "") {
            delete newActivity.minFrequency;
        }
        else {
            newActivity.minFrequency = parseInt(newActivity.minFrequency);
        }
        newActivity.frequencyType = document.getElementById("add-activity-for-subc-frequency-type").value;
    }
    else {
        newActivity.isStatutoryRequirement = false;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Attività creata con successo");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newActivity), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newActivity), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createActivityForSubcategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newActivity));
}

function ajaxCreateLaw() {
    var newLaw = {};
    newLaw.number = document.getElementById("add-law-number").value;
    newLaw.text = document.getElementById("add-law-text").value;
    if(newLaw.text === "") {
        delete newLaw.text;
    }
    newLaw.entryInForce = document.getElementById("add-law-date-start").value.split("/").reverse().join("-");
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Normativa inserita con successo");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newLaw), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newLaw), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createLaw.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newLaw));
}

function ajaxCreateInvoice() {
    var newInvoice = {};
    newInvoice.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    newInvoice.issuedBy = parseInt(document.getElementById("add-invoice-issued-by-company-id").value);
    newInvoice.import = Math.round(document.getElementById("add-invoice-import").value * 1e4) / 1e4;
    newInvoice.vatRate = parseInt(document.getElementById("add-invoice-vat-rate").value);
    newInvoice.otherTaxes = Math.round(document.getElementById("add-invoice-taxes").value * 1e4) / 1e4;
    newInvoice.expenditureItem = parseInt(document.getElementById("add-invoice-expenditure-item").value);
    newInvoice.registrationNumber = parseInt(document.getElementById("add-invoice-registration-number").value);
    newInvoice.invoiceDate = document.getElementById("add-invoice-date").value.split("/").reverse().join("-");
    newInvoice.causal = document.getElementById("add-invoice-causal").value;
    if(document.getElementById("add-invoice-for-building-group").getAttribute("data-checked")==="1") {
        newInvoice.invoiceTo = "currentBuildingGroup";
    }
    else {
        newInvoice.invoiceTo = "site";
        newInvoice.receivingSite = parseInt(document.getElementById("add-invoice-receiving-site-selector").value);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Fattura registrata con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createInvoice.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newInvoice));
}

function ajaxCreateUser() {
    var newUser = {};
    newUser.username = document.getElementById("new-user-username").value;
    newUser.password = document.getElementById("new-user-password").value;
    if(getPasswordScore(newUser.password) < 8) {
        errorPopup("La password non soddisfa i requisiti minimi di sicurezza.");
        return;
    }
    var confirmPassword = document.getElementById("new-user-confirm-password").value;
    if(confirmPassword !== newUser.password) {
        errorPopup("Le due password non coincidono.");
        return;
    }
    newUser.companyId = parseInt(document.getElementById("new-user-employee-of-company").value);
    if(newUser.companyId === "") {
        newUser.companyId = null;
    }
    newUser.name = document.getElementById("new-user-name").value;
    if(newUser.name === "") {
        newUser.name = null;
    }
    newUser.surname = document.getElementById("new-user-surname").value;
    if(newUser.surname === "") {
        newUser.surname = null;
    }
    newUser.mail = document.getElementById("new-user-mail").value;
    newUser.telephone = document.getElementById("new-user-phone").value;
    if(newUser.telephone === "" && newUser.email === "") {
        errorToast("È necessario compilare almeno uno tra i campi 'E-mail aziendale personale' e 'Telefono cellulare'");
        return;
    }
    if(newUser.telephone === "") {
        newUser.telephone = null;
    }
    if(newUser.mail === "") {
        newUser.mail = null;
    }
    closePopup();
    popup("outcome", true, true, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Utente registrato con successo");
                manageCompanySelectorChanged();
            }
            else {
                errorToast("Errore durante l'elaborazione: " + response.message);
            }
        }
        catch(e) {
            errorToast("Errore durante l'elaborazione: (Codice HTTP " + xhttp.status + ").");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/create.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newUser));
}

function ajaxSelfRegisterUser() {
    var newUser = {};
    newUser.name = document.getElementById("login-register-name").value;
    if(newUser.name === "") {
        newUser.name = null;
    }
    newUser.surname = document.getElementById("login-register-surname").value;
    if(newUser.surname === "") {
        newUser.surname = null;
    }
    newUser.mail = document.getElementById("login-register-mail").value;
    newUser.telephone = document.getElementById("login-register-phone").value;
    if(newUser.telephone === "") {
        newUser.telephone = null;
    }
    if(newUser.mail === "") {
        newUser.mail = null;
    }
    closePopup();
    popup("outcome", true, true, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Se l'indirizzo mail è abilitato riceverà una e-mail per completare la registrazione");
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>");
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/selfRegister.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newUser));
}

function ajaxChangePassword() {
    var passwordChange = {};
    passwordChange.newPassword = document.getElementById("change-password-new-password").value;
    passwordChange.oldPassword = document.getElementById("change-password-old-password").value;
    if(getPasswordScore(passwordChange.newPassword) < 8) {
        errorPopup("La password non soddisfa i requisiti minimi di sicurezza.");
        return;
    }
    var confirmPassword = document.getElementById("change-password-confirm-new-password").value;
    if(confirmPassword !== passwordChange.newPassword) {
        errorPopup("'Nuova password' non coincide con 'Conferma nuova password'");
        return;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Password modificata con successo");
            }
            else {
                errorToast("Errore durante l'elaborazione: " + response.message);
            }
        }
        catch(e) {
            errorToast("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/password/change.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(passwordChange));
}

function ajaxCreateEstimate() {
    var newEstimate = {};
    newEstimate.estimates = [];
    expenditureItems.forEach(function(expenditureItem) {
        var estimate = {};
        estimate.expenditureItem = expenditureItem.expenditureItemId;
        estimate.estimatedValue = document.getElementById("data-expenditure-item-" + expenditureItem.expenditureItemId).value;
        newEstimate.estimates.push(estimate);
    });
    newEstimate.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Preventivo registrato con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createEstimate.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newEstimate));
}

function ajaxCreateExpenditureItem() {
    var newExpenditureItem = {};
    newExpenditureItem.name = document.getElementById("add-expenditure-item-name").value;
    newExpenditureItem.unitOfMeasure = document.getElementById("add-expenditure-item-unit").value;
    if(newExpenditureItem.unitOfMeasure === "") {
        newExpenditureItem.unitOfMeasure = null;
    }
    newExpenditureItem.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Voce di spesa creata con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createExpenditureItem.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newExpenditureItem));
}

function ajaxCreateCommonUnit() {
    var buildingsGroupSelector = document.getElementById("common-unit-buildings-group");
    var buildingSelector = document.getElementById("common-unit-building");
    var newCommonUnit = {};
    newCommonUnit.buildingsGroup = parseInt(buildingsGroupSelector.options[buildingsGroupSelector.selectedIndex].value);
    newCommonUnit.building = parseInt(buildingSelector.options[buildingSelector.selectedIndex].value);
    newCommonUnit.name = document.getElementById("common-unit-name").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Zona comune creata con successo");
            ajaxRefreshBuildings();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createCommonUnit.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCommonUnit));
}

function ajaxCreateCompanyOrPerson() {
    var newCompanyOrPerson = {};
    newCompanyOrPerson.streetName = document.getElementById("add-company-street-name").value;
    if(newCompanyOrPerson.streetName === "") {
        delete newCompanyOrPerson.streetName;
    }
    newCompanyOrPerson.streetNumber = document.getElementById("add-company-street-number").value;
    if(newCompanyOrPerson.streetNumber === "") {
        delete newCompanyOrPerson.streetNumber;
    }
    newCompanyOrPerson.city = document.getElementById("add-company-city").value;
    if(newCompanyOrPerson.city === "") {
        delete newCompanyOrPerson.city;
    }
    newCompanyOrPerson.district = document.getElementById("add-company-district").value;
    if(newCompanyOrPerson.district === "") {
        delete newCompanyOrPerson.district;
    }
    newCompanyOrPerson.zipCode = document.getElementById("add-company-zip-code").value;
    if(newCompanyOrPerson.zipCode === "") {
        delete newCompanyOrPerson.zipCode;
    }
    newCompanyOrPerson.fiscalCode = document.getElementById("add-company-fiscal-code").value;
    if(newCompanyOrPerson.fiscalCode === "") {
        delete newCompanyOrPerson.fiscalCode;
    }
    newCompanyOrPerson.email = document.getElementById("add-company-mail").value;
    if(newCompanyOrPerson.email === "") {
        delete newCompanyOrPerson.email;
    }
    newCompanyOrPerson.telephone = document.getElementById("add-company-phone").value;
    if(newCompanyOrPerson.telephone === "") {
        delete newCompanyOrPerson.telephone;
    }
    newCompanyOrPerson.isContractor = (!!document.getElementById("add-company-supplier").value);
    newCompanyOrPerson.isCustomer = (!!document.getElementById("add-company-customer").value);
    newCompanyOrPerson.isLicense = (!!document.getElementById("add-company-is-license").value);
    var isCompany = document.getElementById("is-company-radio").getAttribute("data-checked")==="1";
    if(isCompany) {
        newCompanyOrPerson.companyName = document.getElementById("add-company-fiscal-name").value;
        newCompanyOrPerson.subjectType = "company";
        newCompanyOrPerson.vatNumber = document.getElementById("add-company-vat-number").value;
        if(newCompanyOrPerson.vatNumber === "") {
            delete newCompanyOrPerson.vatNumber;
        }
    }
    else {
        newCompanyOrPerson.name = document.getElementById("add-company-name").value;
        newCompanyOrPerson.surname = document.getElementById("add-company-surname").value;
        newCompanyOrPerson.subjectType = "person";
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            if(response.companyId != null) {
                //If i'm directly creating a company and the company popup creation was not requested to complete the input of another popup
                if(inputRequestedRealValueField === null && inputRequestedVisualValueField === null) {
                    closePopup();
                    successToast("Azienda creata con successo");
                    ajaxGetDashboardData();
                }
                else {
                    closePopup();
                    successToast("Azienda creata con successo, il campo è stato compilato automaticamente");
                    //if it's a company and not a physical person
                    if(isCompany) {
                        inputRequestedVisualValueField.value = newCompanyOrPerson.companyName;
                    }
                    else {
                        inputRequestedVisualValueField.value = newCompanyOrPerson.name + " " + newCompanyOrPerson.surname;
                    }
                    inputRequestedRealValueField.value = response.companyId;
                    inputRequestedRealValueField = null;
                    inputRequestedVisualValueField = null;
                }
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Impossibile creare azienda, probabilmente esiste già un'azienda con la stessa P.IVA / C.F.</div>";
            }
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createCompany.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCompanyOrPerson));
}

function ajaxCreateBuildingsGroup() {
    var newBuildingsGroup = {};
    newBuildingsGroup.buildingsGroupName = document.getElementById("buildings-group-name").value;
    newBuildingsGroup.streetName = document.getElementById("buildings-group-street-name").value;
    if(newBuildingsGroup.streetName === "") {
        delete newBuildingsGroup.streetName;
    }
    newBuildingsGroup.streetNumber = document.getElementById("buildings-group-street-number").value;
    if(newBuildingsGroup.streetNumber === "") {
        delete newBuildingsGroup.streetNumber;
    }
    newBuildingsGroup.city = document.getElementById("buildings-group-city").value;
    if(newBuildingsGroup.city === "") {
        delete newBuildingsGroup.city;
    }
    newBuildingsGroup.district = document.getElementById("buildings-group-district").value;
    if(newBuildingsGroup.district === "") {
        delete newBuildingsGroup.district;
    }
    newBuildingsGroup.zipCode = document.getElementById("buildings-group-zip-code").value;
    if(newBuildingsGroup.zipCode === "") {
        delete newBuildingsGroup.zipCode;
    }
    newBuildingsGroup.owner = document.getElementById("buildings-group-owner-id").value;
    if(newBuildingsGroup.owner === "") {
        delete newBuildingsGroup.owner;
    }
    else {
        newBuildingsGroup.owner = parseInt(newBuildingsGroup.owner);
    }
    if(document.getElementById("buildings-group-owner-admin-match").getAttribute("data-checked")==="1") {
        if(newBuildingsGroup.owner !== undefined) {
            newBuildingsGroup.administrator = newBuildingsGroup.owner;
        }
    }
    else {
        newBuildingsGroup.administrator = document.getElementById("buildings-group-admin-id").value;
        if(newBuildingsGroup.administrator === "") {
            delete newBuildingsGroup.administrator;
        }
        else {
            newBuildingsGroup.administrator = parseInt(newBuildingsGroup.administrator);
        }
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Gruppo di edifici creato con successo");
                ajaxRefreshBuildings(response.createdBuildingsGroupId);
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            console.error(e);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createBuildingsGroup.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newBuildingsGroup));
}

function ajaxCreatePropertyUnit() {
    var unitTypeSelector = document.getElementById("property-unit-type");
    var buildingsGroupSelector = document.getElementById("property-unit-buildings-group");
    var buildingSelector = document.getElementById("property-unit-building");
    var cadastralCategorySelector = document.getElementById("cadastral-category");
    var newPropertyUnit = {};
    newPropertyUnit.buildingsGroup = parseInt(buildingsGroupSelector.options[buildingsGroupSelector.selectedIndex].value);
    newPropertyUnit.building = parseInt(buildingSelector.options[buildingSelector.selectedIndex].value);
    newPropertyUnit.name = document.getElementById("property-unit-name").value;
    newPropertyUnit.unitType = unitTypeSelector.options[unitTypeSelector.selectedIndex].value;
    newPropertyUnit.cadastralCategory = cadastralCategorySelector.options[cadastralCategorySelector.selectedIndex].value;
    if(newPropertyUnit.cadastralCategory === "") {
        delete newPropertyUnit.cadastralCategory;
    }
    newPropertyUnit.responsibleForPreventionAndSafety = document.getElementById("property-unit-responsible-for-prevention-and-safety").value;
    if(newPropertyUnit.responsibleForPreventionAndSafety === "") {
        delete newPropertyUnit.responsibleForPreventionAndSafety;
    }
    newPropertyUnit.insidersForPreventionAndSafety = document.getElementById("property-unit-insiders-for-prevention-and-safety").value;
    if(newPropertyUnit.insidersForPreventionAndSafety === "") {
        delete newPropertyUnit.insidersForPreventionAndSafety;
    }
    newPropertyUnit.medic = document.getElementById("property-unit-medic").value;
    if(newPropertyUnit.medic === "") {
        delete newPropertyUnit.medic;
    }
    newPropertyUnit.emergencyTeam = document.getElementById("property-unit-emergency-team").value;
    if(newPropertyUnit.emergencyTeam === "") {
        delete newPropertyUnit.emergencyTeam;
    }
    newPropertyUnit.firstAidTeam = document.getElementById("property-unit-first-aid-team").value;
    if(newPropertyUnit.firstAidTeam === "") {
        delete newPropertyUnit.firstAidTeam;
    }
    newPropertyUnit.workersSafetyRepresentatives = document.getElementById("property-unit-workers-safety-representatives").value;
    if(newPropertyUnit.workersSafetyRepresentatives === "") {
        delete newPropertyUnit.workersSafetyRepresentatives;
    }
    newPropertyUnit.fireLogBookResponsible = document.getElementById("property-unit-fire-log-book-responsible").value;
    if(newPropertyUnit.fireLogBookResponsible === "") {
        delete newPropertyUnit.fireLogBookResponsible;
    }
    newPropertyUnit.floor = parseInt(document.getElementById("property-unit-floor").value);
    if(isNaN(newPropertyUnit.floor)) {
        delete newPropertyUnit.floor;
    }
    newPropertyUnit.tenant = document.getElementById("property-unit-owner-id").value;
    if(newPropertyUnit.tenant === "") {
        newPropertyUnit.tenant = null;
    }
    else {
        newPropertyUnit.tenant = parseInt(newPropertyUnit.tenant);
    }
    newPropertyUnit.internalNumber = parseInt(document.getElementById("property-unit-internal-number").value);
    if(isNaN(newPropertyUnit.internalNumber)) {
        delete newPropertyUnit.internalNumber;
    }
    newPropertyUnit.cadastralSheet = parseInt(document.getElementById("property-unit-cadastral-sheet").value);
    if(isNaN(newPropertyUnit.cadastralSheet)) {
        delete newPropertyUnit.cadastralSheet;
    }
    newPropertyUnit.cadastralMap = parseInt(document.getElementById("property-unit-cadastral-map").value);
    if(isNaN(newPropertyUnit.cadastralMap)) {
        delete newPropertyUnit.cadastralMap;
    }
    newPropertyUnit.cadastralSubaltern = parseInt(document.getElementById("property-unit-cadastral-subaltern").value);
    if(isNaN(newPropertyUnit.cadastralSubaltern)) {
        delete newPropertyUnit.cadastralSubaltern;
    }
    newPropertyUnit.cadastralZone = parseInt(document.getElementById("property-unit-cadastral-zone").value);
    if(isNaN(newPropertyUnit.cadastralZone)) {
        delete newPropertyUnit.cadastralZone;
    }
    var consistencyValue = document.getElementById("property-unit-consistency").value;
    if(consistencyValue !== "") {
        newPropertyUnit.consistency = Math.round(consistencyValue * 1e4) / 1e4;
    }
    var revenueValue = document.getElementById("property-unit-revenue").value;
    if(revenueValue !== "") {
        newPropertyUnit.revenue = Math.round(revenueValue * 1e4) / 1e4;
    }
    newPropertyUnit.cadastralClass = parseInt(document.getElementById("property-unit-cadastral-class").value);
    if(isNaN(newPropertyUnit.cadastralClass)) {
        delete newPropertyUnit.cadastralClass;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Unità immobiliare creata con successo");
                ajaxRefreshBuildings();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createPropertyUnit.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newPropertyUnit));
}

function ajaxCreateBuilding() {
    var newBuilding = {};
    var buildingsGroupSelect = document.getElementById("new-building-buildings-group");
    newBuilding.buildingsGroup = parseInt(buildingsGroupSelect.options[buildingsGroupSelect.selectedIndex].value);
    newBuilding.name = document.getElementById("new-building-name").value;
    newBuilding.cadastralParcel = parseInt(document.getElementById("new-building-cadastral-parcel").value);
    if(newBuilding.cadastralParcel === "") {
        delete newBuilding.cadastralParcel;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Edificio creato con successo");
                ajaxRefreshBuildings();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createBuilding.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newBuilding));
}

function ajaxEditBuildingsGroup() {
    var editedBuildingsGroup = {};
    editedBuildingsGroup.buildingsGroupId = parseInt(document.getElementById("edit-buildings-group-buildings-group-id").value);
    editedBuildingsGroup.buildingsGroupName = document.getElementById("edit-buildings-group-name").value;
    editedBuildingsGroup.streetName = document.getElementById("edit-buildings-group-street-name").value;
    if(editedBuildingsGroup.streetName === "") {
        delete editedBuildingsGroup.streetName;
    }
    editedBuildingsGroup.streetNumber = document.getElementById("edit-buildings-group-street-number").value;
    if(editedBuildingsGroup.streetNumber === "") {
        delete editedBuildingsGroup.streetNumber;
    }
    editedBuildingsGroup.city = document.getElementById("edit-buildings-group-city").value;
    if(editedBuildingsGroup.city === "") {
        delete editedBuildingsGroup.city;
    }
    editedBuildingsGroup.district = document.getElementById("edit-buildings-group-district").value;
    if(editedBuildingsGroup.district === "") {
        delete editedBuildingsGroup.district;
    }
    editedBuildingsGroup.zipCode = document.getElementById("edit-buildings-group-zip-code").value;
    if(editedBuildingsGroup.zipCode === "") {
        delete editedBuildingsGroup.zipCode;
    }
    editedBuildingsGroup.owner = document.getElementById("edit-buildings-group-owner-id").value;
    if(editedBuildingsGroup.owner === "") {
        delete editedBuildingsGroup.owner;
    }
    else {
        editedBuildingsGroup.owner = parseInt(editedBuildingsGroup.owner);
    }
    if(document.getElementById("edit-buildings-group-owner-admin-match").getAttribute("data-checked")==="1") {
        if(editedBuildingsGroup.owner !== undefined) {
            editedBuildingsGroup.administrator = editedBuildingsGroup.owner;
        }
    }
    else {
        editedBuildingsGroup.administrator = document.getElementById("edit-buildings-group-admin-id").value;
        if(editedBuildingsGroup.administrator === "") {
            delete editedBuildingsGroup.administrator;
        }
        else {
            editedBuildingsGroup.administrator = parseInt(editedBuildingsGroup.administrator);
        }
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Gruppo di edifici modificato con successo");
                ajaxRefreshBuildings();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editBuildingsGroup.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(editedBuildingsGroup));
}

function ajaxEditBuilding() {
    var editedBuilding = {};
    editedBuilding.buildingId = parseInt(document.getElementById("edit-building-building-id").value);
    editedBuilding.buildingsGroup = parseInt(document.getElementById("edit-building-buildings-group").value);
    editedBuilding.name = document.getElementById("edit-building-name").value;
    editedBuilding.cadastralParcel = parseInt(document.getElementById("edit-building-cadastral-parcel").value);
    if(editedBuilding.cadastralParcel === "") {
        delete editedBuilding.cadastralParcel;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Edificio modificato con successo");
                ajaxRefreshBuildings();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editBuilding.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(editedBuilding));
}

function ajaxEditSite() {
    var editedSite = {};
    editedSite.siteId = parseInt(document.getElementById("edit-site-site-id").value);
    editedSite.buildingsGroup = parseInt(document.getElementById("edit-site-buildings-group").value);
    editedSite.building = parseInt(document.getElementById("edit-site-building").value);
    editedSite.name = document.getElementById("edit-site-name").value;
    editedSite.unitType = document.getElementById("edit-site-type").value;
    editedSite.isCommon = document.getElementById("edit-site-is-common-unit").getAttribute("data-checked")==="1";
    editedSite.siteManagerMailTo = document.getElementById("edit-site-site-manager-mail").value;
    if(editedSite.siteManagerMailTo.trim() === "") {
        delete editedSite.siteManagerMailTo;
    }
    editedSite.siteManagerMailCC = editSiteManagerCcTaggle.getTagValues().join(",");
    if(editedSite.siteManagerMailCC.trim() === "") {
        delete editedSite.siteManagerMailCC;
    }
    editedSite.executorsMailCC = editSiteExecutorsCcTaggle.getTagValues().join(",");
    if(editedSite.executorsMailCC.trim() === "") {
        delete editedSite.executorsMailCC;
    }
    editedSite.cadastralCategory = document.getElementById("edit-site-cadastral-category").value;
    if(editedSite.cadastralCategory === "") {
        delete editedSite.cadastralCategory;
    }
    editedSite.responsibleForPreventionAndSafety = document.getElementById("edit-site-responsible-for-prevention-and-safety").value;
    if(editedSite.responsibleForPreventionAndSafety === "") {
        delete editedSite.responsibleForPreventionAndSafety;
    }
    editedSite.insidersForPreventionAndSafety = document.getElementById("edit-site-insiders-for-prevention-and-safety").value;
    if(editedSite.insidersForPreventionAndSafety === "") {
        delete editedSite.insidersForPreventionAndSafety;
    }
    editedSite.medic = document.getElementById("edit-site-medic").value;
    if(editedSite.medic === "") {
        delete editedSite.medic;
    }
    editedSite.emergencyTeam = document.getElementById("edit-site-emergency-team").value;
    if(editedSite.emergencyTeam === "") {
        delete editedSite.emergencyTeam;
    }
    editedSite.firstAidTeam = document.getElementById("edit-site-first-aid-team").value;
    if(editedSite.firstAidTeam === "") {
        delete editedSite.firstAidTeam;
    }
    editedSite.workersSafetyRepresentatives = document.getElementById("edit-site-workers-safety-representatives").value;
    if(editedSite.workersSafetyRepresentatives === "") {
        delete editedSite.workersSafetyRepresentatives;
    }
    editedSite.fireLogBookResponsible = document.getElementById("edit-site-fire-log-book-responsible").value;
    if(editedSite.fireLogBookResponsible === "") {
        delete editedSite.fireLogBookResponsible;
    }
    editedSite.isCommon = document.getElementById("edit-site-is-common-unit").getAttribute("data-checked")==="1";
    editedSite.isActive = document.getElementById("edit-site-is-active").getAttribute("data-checked")==="1";
    editedSite.atecoCode = document.getElementById("edit-site-ateco-code").value;
    if(editedSite.atecoCode === "") {
        delete editedSite.atecoCode;
    }
    editedSite.floor = parseInt(document.getElementById("edit-site-floor").value);
    if(isNaN(editedSite.floor)) {
        delete editedSite.floor;
    }
    editedSite.tenant = document.getElementById("edit-site-owner-id").value;
    if(editedSite.tenant === "") {
        editedSite.tenant = null;
    }
    else {
        editedSite.tenant = parseInt(editedSite.tenant);
    }
    editedSite.internalNumber = parseInt(document.getElementById("edit-site-internal-number").value);
    if(isNaN(editedSite.internalNumber)) {
        delete editedSite.internalNumber;
    }
    editedSite.cadastralSheet = parseInt(document.getElementById("edit-site-cadastral-sheet").value);
    if(isNaN(editedSite.cadastralSheet)) {
        delete editedSite.cadastralSheet;
    }
    editedSite.cadastralMap = parseInt(document.getElementById("edit-site-cadastral-map").value);
    if(isNaN(editedSite.cadastralMap)) {
        delete editedSite.cadastralMap;
    }
    editedSite.cadastralSubaltern = parseInt(document.getElementById("edit-site-cadastral-subaltern").value);
    if(isNaN(editedSite.cadastralSubaltern)) {
        delete editedSite.cadastralSubaltern;
    }
    editedSite.cadastralZone = parseInt(document.getElementById("edit-site-cadastral-zone").value);
    if(isNaN(editedSite.cadastralZone)) {
        delete editedSite.cadastralZone;
    }
    editedSite.consistency = document.getElementById("edit-site-consistency").value;
    if(editedSite.consistency !== "") {
        editedSite.consistency = Math.round(editedSite.consistency * 1e4) / 1e4;
    }
    else {
        delete editedSite.consistency;
    }
    editedSite.revenue = document.getElementById("edit-site-revenue").value;
    if(editedSite.revenue !== "") {
        editedSite.revenue = Math.round(editedSite.revenue * 1e4) / 1e4;
    }
    else {
        delete editedSite.revenue;
    }
    editedSite.latitude = document.getElementById("edit-site-latitude").value;
    if(editedSite.latitude !== "") {
        editedSite.latitude = Math.round(editedSite.latitude * 1e10) / 1e10;
    }
    editedSite.longitude = document.getElementById("edit-site-longitude").value;
    if(editedSite.longitude !== "") {
        editedSite.longitude = Math.round(editedSite.longitude * 1e10) / 1e10;
    }
    editedSite.cadastralClass = parseInt(document.getElementById("edit-site-cadastral-class").value);
    if(isNaN(editedSite.cadastralClass)) {
        delete editedSite.cadastralClass;
    }

    editedSite.firePreventionCertificateNumber=document.getElementById("edit-site-fire-prevention-certificate-number").value;
    editedSite.firePreventionCertificateDate=iso8601.fromEuropeanDate(document.getElementById("edit-site-fire-prevention-certificate-date").value);
    editedSite.firePreventionCertificateExpiration=iso8601.fromEuropeanDate(document.getElementById("edit-site-fire-prevention-certificate-expiration").value);
    editedSite.firefightersCommand=document.getElementById("edit-site-firefighters-command").value;

    editedSite.headerLine1=document.getElementById("edit-site-header-line-1").value;
    editedSite.headerLine2=document.getElementById("edit-site-header-line-2").value;
    editedSite.headerLine3=document.getElementById("edit-site-header-line-3").value;
    editedSite.headerLine4=document.getElementById("edit-site-header-line-4").value;
    editedSite.headerLine5=document.getElementById("edit-site-header-line-5").value;
    editedSite.headerLine6=document.getElementById("edit-site-header-line-6").value;
    editedSite.headerLine7=document.getElementById("edit-site-header-line-7").value;
    editedSite.headerImage=parseInt(document.getElementById("edit-site-header-logo-id").value);

    editedSite.requiredDocuments = [];
    if(document.getElementById("edit-site-require-durc").getAttribute("data-checked")==="1"){
        editedSite.requiredDocuments.push("DURC");
    }
    if(document.getElementById("edit-site-require-duvri").getAttribute("data-checked")==="1"){
        editedSite.requiredDocuments.push("DUVRI");
    }
    if(document.getElementById("edit-site-require-visura-camerale").getAttribute("data-checked")==="1"){
        editedSite.requiredDocuments.push("Visura camerale");
    }
    if(document.getElementById("edit-site-require-quietanza-premio").getAttribute("data-checked")==="1"){
        editedSite.requiredDocuments.push("Quietanza premio");
    }
    if(document.getElementById("edit-site-require-assicurazione").getAttribute("data-checked")==="1"){
        editedSite.requiredDocuments.push("Assicurazione");
    }


    var toUploadSiteHeaderLogo = document.getElementById("edit-site-header-logo");
    closePopup();
    popup("outcome", true, false, false);

    if(toUploadSiteHeaderLogo.files.length === 0) {
        ajaxSendEditSiteRequest(editedSite);
    }
    else{
        var data = new FormData();
        data.append("newPicture", toUploadSiteHeaderLogo.files[0]);

        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    editedSite.headerImage=response.newFileId;
                    ajaxSendEditSiteRequest(editedSite);
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/uploadPicture.php", true);
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(data);
    }

}

function ajaxSendEditSiteRequest(editedSite) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Sito modificato con successo");
                ajaxRefreshBuildings();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editSite.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(editedSite));
}

function getAllUrlParams(url) {
    var queryString = url ? url.split("?")[1] : window.location.search.slice(1);
    var obj = {};
    if (queryString) {
        queryString = queryString.split("#")[0];
        var arr = queryString.split("&");
        for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split("=");
            var paramName = a[0];
            var paramValue = typeof (a[1]) === "undefined" ? true : a[1];
            if (paramName.match(/\[(\d+)?]$/)) {
                var key = paramName.replace(/\[(\d+)?]/, '');
                if (!obj[key]) obj[key] = [];
                if (paramName.match(/\[\d+]$/)) {
                    var index = /\[(\d+)]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                }
                else {
                    obj[key].push(paramValue);
                }
            } else {
                if (!obj[paramName]) {
                    obj[paramName] = paramValue;
                }
                else if (obj[paramName] && typeof obj[paramName] === "string"){
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                }
                else {
                    obj[paramName].push(paramValue);
                }
            }
        }
    }
    return obj;
}

function changePage(newPage, clear, dontChangeHash, dontPushToPageStack) {
    dom.addClass(document.getElementById("page-loading-error"), "hidden");
    dom.addClass(document.getElementById("page-loading"), "hidden");
    hideMobileMenu(true);
    showCorrectActionButton(newPage);
    //actions before the page changed
    switch(newPage) {
        case "page-execute-intervention":
            /*if(cordova){
                hardwareAvailability.checkGpsPermission();
                hardwareAvailability.checkGpsEnabled();
            }*/
            ajaxStartIntervention();
            //geolocalizeDevice();
            break;
        case "page-asset":
            if(clear) {
                setInnerHtml(document.querySelector("#page-asset .page-main-title"), "");
            }
            break;
        case "page-intervention":
            if(clear) {
                document.getElementById("intervention-container").innerHTML = "";
                scroll.refreshByName("intervention-activities");
                document.querySelector("#page-intervention .page-main-title").innerHTML="";
                document.getElementById("intervention-details-top").innerHTML = "";
                scroll.refreshByName("intervention-details");
            }
            else {
                //reloading latest viewed intervention
                if(interventionManager.currentInterventionData) {
                    if(interventionManager.currentInterventionData.interventionId) {
                        ajaxGetInterventionData(interventionManager.currentInterventionData.interventionId);
                    }
                }
            }
            break;
        case "page-intervention-activity":
            if(clear) {
                setInnerHtml(document.getElementById("intervention-activity-container"), "");
                setInnerHtml(document.querySelector("#page-intervention-activity .page-main-title"), "");
                document.getElementById("intervention-activity-details").innerHTML = "";
                scroll.refreshByName("intervention-activity-details");
            }
            break;
        case "page-assets":
            if(!assetsCategoryTreeGenerated) {
                assetsCategoryTreeGenerated = true;
                generateCategoryTreeForAssetNavigation();
            }
            scroll.refreshByName("assets-list");
            break;
        case "page-maintenance":
            maintenancePage.refresh();
            break;
        case "page-safety-control":
            safetyControlPage.refresh();
            break;
        case "page-calendar":
            renderCalendar(lastMonth, lastYear);
            break;
        case "page-calendar-year":
            renderYearCalendar(lastYear);
            break;
        case "page-company":
            scroll.refreshByName("company-details");
            scroll.refreshByName("company-employees");
            break;
        case "page-buildings":
            scroll.refreshByName("buildings-tree");
            scroll.refreshByName("building-details");
            break;
        case "page-data-room":
            if(textSearchDataRoomDocumentTag !== undefined) {
                textSearchDataRoomDocumentTag.options = dataRoomDocumentTags;
            }
            if(dataRoomShowTrash) {
                closeDataRoomTrash(false);
            }
            scroll.refreshByName("vdr");
            break;
        case "page-ticketing":
            document.getElementById("ticket-details").innerHTML = "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>";
            ajaxGetTickets(lastSelectedTicket);
            break;
        case "page-anomalies":
            document.getElementById("anomaly-details").innerHTML = "<div class='no-results-message'>Seleziona una segnalazione per vederne qui i dettagli.</div>";
            ajaxGetAnomalies(lastSelectedAnomaly, false);
            break;
        case "page-login":
            dom.addClass(document.getElementById("navbar"), "hidden");
            break;
        case "page-contracts":
            ajaxGetContracts();
            break;
        case "page-plan":
            ajaxLoadPlans();
            break;
        case "page-home":
            homePage.refreshCounters();
            break;
    }
    if(newPage !== "page-login" && dom.hasClass(document.getElementById("navbar"), "hidden")) {
        dom.removeClass(document.getElementById("navbar"), "hidden");
    }
    if(currentPage) {
        dom.addClass(document.getElementById(currentPage), "hidden");
        if(!dontPushToPageStack) {
            if(currentPage != newPage) {
                pageStack.push(currentPage);
            }
        }
    }
    dom.removeClass(document.getElementById(newPage), "hidden");
    //actions after the page changed
    switch(newPage) {
        case "page-login":
            //in case of logout the user must not be able to go back in history
            pageStack = [];
            location.replace("#login");
            break;
        case "page-buildings":
            initializeLayerIfNeeded(document.getElementById("building-map"), true);
            break;
    }
    var linksToPage;
    if(linksToPage = getElementsByAttribute("data-tab-link-to-page", document.getElementById("navbar"))) {
        for(var i = 0; i < linksToPage.length; i++) {
            if(linksToPage[i].getAttribute("data-tab-link-to-page") === newPage) {
                dom.addClass(linksToPage[i], "selected");
            }
            else {
                dom.removeClass(linksToPage[i], "selected");
            }
        }
    }
    currentPage = newPage;
    if(!dontChangeHash) {
        var newHash = "#" + currentPage.replace("page-", "");
        if(newHash !== window.location.hash) {
            hashChangedInternally = true;
            window.location.hash = newHash;
        }
    }
}

//prevent back button
window.onhashchange = function(event) {
    if(ui.qrScannerOpen) {
        ui.hideQrScanner();
        var previousUrl = event.oldURL.split("#");
        if(previousUrl && previousUrl[1]) {
            hashChangedInternally = true;
            location.hash = previousUrl[1];
        }
    }
    else {
        if(!hashChangedInternally) {
            handleUrlPath();
        }
        hashChangedInternally = false;
    }
};

//-------AJAX--------//

function ajaxLogout(afterPasswordReset) {
    userLoggedOut = true;
    getNotificationsInitialized = false;
    globalUserPermissions = null;
    globalFilterByCompany = null;
    maintenancePage.forceSiteFilterUnset();
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            if(db.localDb) {
                db.deleteUserSessions();
            }
            currentAuthToken = null;
            currentSessionId = null;
            currentLoggedInUserId = null;
            window.localStorage.removeItem("cfm_at");
            window.localStorage.removeItem("cfm_last_global_filter_by_company");
            window.localStorage.removeItem("cfm_last_global_filter_by_workarea");
            globalFilterByCompany=null;
            globalFilterByCompanyFromLocalStorage=null;
            changePage("page-login");
            if(afterPasswordReset) {
                successToast("Password reimpostata");
            }
            else {
                successToast("Disconnessione effettuata");
            }
        }
        else {
            errorToast(response.message);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/logout.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function drawSimplifiedWorkAreaSelector(){
    if(workAreaDrawn){
        return;
    }
    var i;
    var globalCompanyFilterContainer = document.getElementById("global-filter-companies-container");
    globalCompanyFilterContainer.innerHTML="";
    for(i = 0; i < globalUserPermissions.length; i++) {
        var button = document.createElement("button");
        button.setAttribute("type","button");
        button.innerHTML = "<i class='fa fa-briefcase' aria-hidden='true'></i><br>" + globalUserPermissions[i].companyName;
        button.setAttribute("data-company-id",globalUserPermissions[i].companyId);
        button.addEventListener("click",function(event){
            if(!ghostClickController.isOk()){
                return false;
            }
            var companyId = parseInt(event.currentTarget.getAttribute("data-company-id"));
            setGlobalFilterToCompany(companyId);
        });
        globalCompanyFilterContainer.appendChild(button);
    }
    if(globalUserPermissions == null || globalUserPermissions.length < 2) {
        setInnerHtml(document.getElementById("global-filter-companies-container"), "Non sono disponibili altre aziende per questo account.");
    }
    scroll.refreshPopupById("select-global-company-filter", false);
}

function showWorkArea(callerButton){
    switch(callerButton.getAttribute("data-target")){
        case 'group':
            setGlobalFilterToWorkArea(callerButton.getAttribute("data-id"));
            break;
        case 'company':
            setGlobalFilterToCompany(callerButton.getAttribute("data-id"));
            break;
    }
}
window.showWorkArea=showWorkArea;

function drawWorkAreaButton(workAreaElement,depth){
    var html="";
    var i=0;
    var contained=0;
    //draw me if depth > 0 (not the root) and then the children
    var found=false;
    if(workAreaElement.companyId==null){
        found=true;
    }
    else{
        for(j = 0; j < globalUserPermissions.length;j++) {
            if(workAreaElement.companyId==globalUserPermissions[j].companyId){
                found=true;
                contained++;
            }
        }
    }
    if(found){
        var resultsHtml="",resultsContained=0;
        for(i=0;i<workAreaElement.children.length; i++){
            //generating children html and count visible children
            var result = drawWorkAreaButton(workAreaElement.children[i],depth+1);
            resultsHtml += result.html;
            resultsContained += result.contained;
        }
        if((resultsContained>1 || workAreaElement.companyId != null) && depth>0){
            //Showing a wrapper that represent me with children inside
            if(workAreaElement.children.length>0){
                html += "<div class='work-area-group'>";
                html += "<button type='button' onclick='showWorkArea(this)' ";
                if(workAreaElement.companyId==null){
                    html+="data-target='group' data-id='"+workAreaElement.id+"'";
                }
                else{
                    html+="data-target='company' data-id='"+workAreaElement.companyId+"'";
                }
                html += " class='work-area-button-with-children'><span class='label'>"+ workAreaElement.name +"</span></button>";
            }
            else{
                html += "<button type='button' onclick='showWorkArea(this)' ";
                if(workAreaElement.companyId==null){
                    html+="data-target='group' data-id='"+workAreaElement.id+"'";
                }
                else{
                    html+="data-target='company' data-id='"+workAreaElement.companyId+"'";
                }
                html += " class='work-area-button'><span class='label'>"+ workAreaElement.name +"</span></button>";
            }
            if(workAreaElement.children.length>0){
                html += "<br><div class='work-area-children'>";
            }
            html += resultsHtml;
            if(workAreaElement.children.length>0){
                html += "</div></div>";
            }
        }
        else{
            //omitting myself, showing only children
            html += resultsHtml;
        }
        contained +=resultsContained;
    }
    return {"html":html,"contained":contained};
}

var workAreaDrawn = false;
function drawWorkAreaSelector(workAreas){
    workAreaDrawn=true;
    var globalCompanyFilterContainer = document.getElementById("global-filter-companies-container");
    var i,j;
    for(j = 0; j< workAreas.length; j++){
        workAreas[j].okCounter=0;
    }
    var result = drawWorkAreaButton(workAreas,0);
    globalCompanyFilterContainer.innerHTML=result.html;
    scroll.refreshPopupById("select-global-company-filter", false);
}

function getWorkAreas(){
    drawSimplifiedWorkAreaSelector();
    if(!currentAuthTokenV2 || !currentSessionCode){
        return;
    }
    if(permissions["VIEW_MASTER_HOME"]=="ACCESSIBLE"){
        var xhttp = new XMLHttpRequest();
        xhttp.timeout = 7000; // time in milliseconds
        var timeoutErrorAbort = function(){
            setTimeout(function(){
                getWorkAreas();
            },500);
        };
        xhttp.onerror = timeoutErrorAbort;
        xhttp.onabort = timeoutErrorAbort;
        xhttp.ontimeout = timeoutErrorAbort;
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    drawWorkAreaSelector(response.resources);
                    window.workAreas = response.resources;
                }
                else {
                    console.error("Errore durante lettura work areas: codice HTTP "+xhttp.status+", errore: "+response.error);
                }
            }
            catch(e) {
                console.error(e);
                ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, e.message + "<br><br> On line: " + e.lineNumber + "<br><br>" + e.stack + "<br><br>" + xhttp.responseText.substring(0, 1000));
            }
        };
        xhttp.open("GET", "https://facility.claster.it/api/v2/work-areas/", true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authorization", "Bearer "+currentAuthTokenV2);
        xhttp.setRequestHeader("X-Session-Id", currentSessionCode);
        xhttp.send("");
    }
}

function ajaxLogin() {
    dom.removeClass(document.getElementById("page-loading"), "hidden");
    var username = document.getElementById("usr").value.trim();
    var password = document.getElementById("pwd").value;
    document.getElementById("pwd").value="";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                userLoggedOut = false;
                try {
                    window.localStorage.setItem("cfm_at", response.token);
                }
                catch(e) {
                    errorPopup("Impossibile salvare i dati di autenticazione<br>Assicurati che i cookie siano abilitati sul tuo browser.<br>Se stai usando Safari 10 o precedente assicurati di non avere attivato la navigazione anonima.");
                }
                if(db.localDb) {
                    db.registerUserSession(
                        response.userId,
                        username,
                        response.token
                    );
                }
                currentAuthToken = response.token;
                currentSessionId = response.sessionId;
                currentLoggedInUserId = response.userId;

                if(currentFcmToken != null) {
                    ajaxSendNewFirebaseTokenToServer(currentFcmToken);
                }
                preHandleUrlPath(
                    window.location.hash.replace("#", ""),
                    function() {
                        if(savedPageHash !== "login" && savedPageHash !== "logout" && savedPageHash != null) {
                            window.location.hash = "#" + savedPageHash;
                            handleUrlPath();
                        }
                        else {
                            changePage("page-home");
                        }
                    }
                );
                setInnerHtml(document.getElementById("page-company-view-information"), "",function(){
                    scroll.refreshByName("company-details");
                });
                setInnerHtml(
                    document.getElementById("page-company-employees"),
                    "<span class='tip'>Per utilizzare questa funzione è necessario selezionare un'<strong>azienda</strong>.</span>",
                    function() {
                        scroll.refreshByName("company-employees");
                    }
                );
                setInnerHtml(document.getElementById("buildings-organization-viewer"), "<div class='tip centered-tip'>Seleziona un <strong>gruppo di edifici</strong>.</div>");
                setInnerHtml(document.getElementById("building-details"), "<div class='tip centered-tip'>Seleziona un edificio, un'unità immobiliare o una zona comune nella colonna di sinistra per vederne qui i dettagli.</div>");
                setInnerHtml(document.getElementById("ticket-details"), "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>");
                clearAccountingDashboard();
            }
            else {
                dom.addClass(document.getElementById("page-loading"), "hidden");
                errorToast(response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione, contatta l'assistenza tecnica: <br>" + JSON.stringify(e));
            console.error(e);
            if(xhttp.responseText) {
                ajaxSendErrorReport("Errore durante l'autenticazione, contatta l'assistenza tecnica", JSON.stringify(e), xhttp.responseText);
            }
            else {
                ajaxSendErrorReport("Errore durante l'autenticazione, contatta l'assistenza tecnica", JSON.stringify(e), "");
            }
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/login.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.send("username=" + username + "&password=" + password);
    try {
        var loginResource={};
        loginResource.username=username;
        loginResource.password=password;
        loginResource.app_version="v0";
        var xhttp2 = new XMLHttpRequest();
        xhttp2.onload = function() {
            var response = JSON.parse(xhttp2.responseText);
            if(xhttp2.status === 200) {
                currentSessionCode = response.sessionCode;
                currentAuthTokenV2 = response.token;
                window.localStorage.setItem("cfm_sc", response.sessionCode);
                window.localStorage.setItem("cfm_at_v2", response.token);
            }
            else {
                console.error("errore di autenticazione al nuovo server");
            }
        };
        xhttp2.open("POST", "https://facility.claster.it/api/v2/session/", true);
        xhttp2.setRequestHeader("Content-Type", "application/json");
        xhttp2.send(JSON.stringify(loginResource));
    }
    catch(e) {
        console.error("errore di autenticazione al nuovo server:");
        console.error(e);
    }
    return false;//prevent navigation
}

function loginAskAssistanceFormSubmit() {
    var requestData = {};
    requestData.username = document.getElementById("reset-password-username").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Se lo username è corretto riceverai una mail o un SMS con il link di recupero");
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'invio della richiesta: " + response.message + "<br>Può comunque richiedere assistenza all'indirizzo mail <a href='mailto:facility@claster.it'>facility@claster.it</a></div>");
                console.error(xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'invio della richiesta (Codice HTTP " + xhttp.status + ").<br>Può comunque richiedere assistenza all'indirizzo mail <a href='mailto:facility@claster.it'>facility@claster.it</a></div>");
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/password/requestReset.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

/**
 *
 * @param qrCode string the qrCode for which request relations
 * @param successCallback function a callback that will receive
 *   the response with the qrCode relations (response.intervention, response.ticket, response.asset)
 * @param errorCallback function a callback called in case of error, receives a string with the error description
 */
function ajaxGetQrCodeRelations(qrCode,successCallback,errorCallback) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            switch(xhttp.status) {
                case 200: //OK
                    if(typeof(successCallback)==="function") {
                        successCallback(response);
                    }
                    break;
                default:
                    if(typeof(errorCallback)==="function") {
                        errorCallback("Errore durante l'elaborazione: " + response.message);
                    }
                    break;
                }
            }
            catch(e) {
                if(typeof(errorCallback)==="function") {
                    errorCallback("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
                }
            }
    };
    xhttp.open("GET", BASE_URL + "/ws/qrCode/get.php?qr=" + qrCodeInUrl, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function scanQrForAssetReplacement(){
    scanQrCode(
        function(qrCode){
            ajaxGetQrCodeRelations(
                qrCode,
                function(response){
                    //Success callback, checking that tha QR code is not already in use
                    if(!response.used) {
                        assetReplacementNewAssetPopup.open(qrCode);
                    }
                },
                errorPopup
            );
        }
    );
}

var uploadEstimateForTicketPopup = {
    init: function(chatRelatedToInterventionId,chatRelatedToTicketId,chatViewerIsExecutor,executorId) {
        if(chatRelatedToInterventionId!==null){
            document.getElementById("chat-related-to-intervention-id").value = chatRelatedToInterventionId;
        }
        if(chatRelatedToTicketId!==null){
            document.getElementById("chat-related-to-ticket-id").value = chatRelatedToTicketId;
        }
        if(chatViewerIsExecutor!==null){
            document.getElementById("chat-viewer-is-executor").value = chatViewerIsExecutor;
        }
        if(executorId!==null){
            document.getElementById("chat-executor").value = executorId;
        }
        document.getElementById("upload-estimate-amount").value="";
        document.getElementById("upload-estimate-pdf").value="";
        document.getElementById("upload-estimate-message").value="";
        popup("upload-estimate-for-ticket");
    }
};

var chatPopup = {
    refresh: function(viewerIsExecutor,interventionId,ticketId){
        var messagesHolder=document.getElementById("chat-messages-holder");
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    var resources = response.resources;
                    var tempHtml = "";
                    var icon;
                    resources.forEach(function(resource){
                        if(resource.ticketEstimateImport === null) {
                            var additionalClass = (currentLoggedInUserId==resource.senderUserId)?"own-message":"";
                            tempHtml+="<div class='chat-message "+additionalClass+"'>" +
                                "<strong>"+resource.senderName+" "+resource.senderSurname+"</strong> " +
                                "<span class='timestamp'>"+iso8601.toEuropeanDateTimeWithSeconds(resource.creation) +"</span><br>" +
                                resource.message;
                            if(resource.orderFormDocumentId !== null) {
                                icon = renderDocumentIcon(resource.ticketEstimateDocumentFormat,true);
                                tempHtml+="<br><div class='data-room-file' onclick='renderDocument("+resource.orderFormDocumentId+",\""+resource.ticketEstimateDocumentFormat+"\");'>" +
                                    icon + "<br>" +
                                    "Buono d'ordine "+ resource.orderFormNumber;
                                tempHtml+="</div>";
                            }
                            if(resource.approvedEstimateDocumentId !== null){
                                icon = renderDocumentIcon(resource.ticketEstimateDocumentFormat,true);
                                tempHtml+="<br><div class='data-room-file' onclick='renderDocument("+resource.approvedEstimateDocumentId+",\""+resource.ticketEstimateDocumentFormat+"\");'>" +
                                    icon + "<br>" +
                                    "Preventivo approvato ";
                                tempHtml+="</div>";
                            }
                            tempHtml+="</div>";
                        }
                        else{
                            if(currentLoggedInUserId==resource.senderUserId) {
                                tempHtml+="<div class='chat-message own-message'><strong>"+resource.senderName+" "+resource.senderSurname+"</strong> <span class='timestamp'>"+iso8601.toEuropeanDateTimeWithSeconds(resource.creation) +"</span><br>";
                            }
                            else {
                                tempHtml += "<div class='chat-message'><strong>"+resource.senderName+" "+resource.senderSurname+"</strong> <span class='timestamp'>"+iso8601.toEuropeanDateTimeWithSeconds(resource.creation) +"</span><br>";
                            }
                            tempHtml+=resource.message+"<br>Importo preventivo: "+resource.ticketEstimateImport+"€<br>";
                            icon = renderDocumentIcon(resource.ticketEstimateDocumentFormat,true);
                            tempHtml+="<div class='data-room-file' onclick='renderDocument("+resource.ticketEstimateDocumentId+",\""+resource.ticketEstimateDocumentFormat+"\");'>" +
                                icon + "<br>" +
                                "Preventivo "+ resource.ticketEstimateId;
                            if(resource.ticketEstimateAccepted === true){
                                tempHtml+="<br><span class='data-room-approved-text'>Accettato da "+resource.ticketEstimateReviewerName+" "+resource.ticketEstimateReviewerSurname+"</span>";
                            }
                            if(resource.ticketEstimateAccepted === false){
                                tempHtml+="<br><span class='data-room-refused-text'>Rifiutato da "+resource.ticketEstimateReviewerName+" "+resource.ticketEstimateReviewerSurname+"</span>";
                            }
                            tempHtml+="</div>";
                            if(resource.ticketEstimateAccepted===null && permissions["APPROVE_TICKET_ESTIMATES"]==="ACCESSIBLE") {
                                tempHtml+="<br><br><button type='button' class='bg-green text-white' onclick='chatPopup.approveEstimate("+resource.ticketEstimateId+");'><i class='data-room-file-icon fa fa-check' aria-hidden='true'></i> Approva</button> <button type='button' class='bg-red text-white' onclick='chatPopup.refuseEstimate("+resource.ticketEstimateId+");'><i class='data-room-file-icon fa fa-times' aria-hidden='true'></i>  Rifiuta</button>";
                            }
                            tempHtml+="</div>";
                        }
                    });

                    var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                    if(response.estimateRequired && viewerIsExecutor) {
                        dom.removeClass(document.getElementById("upload-estimate-from-chat-button"),"hidden");
                        dom.addClass(document.getElementById("cannot-upload-estimate-from-chat"),"hidden");
                    }
                    else{
                        dom.addClass(document.getElementById("upload-estimate-from-chat-button"),"hidden");
                        dom.removeClass(document.getElementById("cannot-upload-estimate-from-chat"),"hidden");
                    }
                    setInnerHtml(messagesHolder,tempHtml,function(){
                        scroll.refreshPopupById("chat", true);
                    });
                }
                else {
                    setInnerHtml(messagesHolder, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>",function(){
                        scroll.refreshPopupById("chat", true);
                    });
                }
            }
            catch(e) {
                console.error(e);
                setInnerHtml(messagesHolder, "<div class='message error'> Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>",function(){
                    scroll.refreshPopupById("chat", true);
                });
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/chatMessage/get.php?interventionId=" + interventionId + "&ticketId=" + ticketId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    },
    initialize: function(viewerIsExecutor,interventionId,ticketId,executorId) {
        document.getElementById("chat-related-to-intervention-id").value = interventionId;
        document.getElementById("chat-related-to-ticket-id").value = ticketId;
        document.getElementById("chat-viewer-is-executor").value = viewerIsExecutor?"1":"0";
        document.getElementById("chat-executor").value = executorId;
        popup("chat");
        var messagesHolder=document.getElementById("chat-messages-holder");
        messagesHolder.innerHTML="<i class='fa fa-cog fa-spin fa-2x'></i><span class='loading'>Caricamento del file in corso...</span>";
        chatPopup.refresh(viewerIsExecutor,interventionId,ticketId);
    },
    sendMessage: function() {
        var requestData={};
        requestData.message=document.getElementById("new-chat-message").value;
        document.getElementById("new-chat-message").value="";
        requestData.interventionId=document.getElementById("chat-related-to-intervention-id").value;
        if(requestData.interventionId!="") {
            requestData.interventionId = parseInt(requestData.interventionId);
        }
        if(requestData.message!=="") {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                try {
                    var response = JSON.parse(xhttp.responseText);
                    if(xhttp.status === 200) {
                        var ticketId = parseInt(document.getElementById("chat-related-to-ticket-id").value);
                        var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                        chatPopup.refresh(viewerIsExecutor,requestData.interventionId,ticketId);
                        console.log("messaggio inviato: "+requestData.message);
                    }
                    else {
                        errorPopup("Errore durante l'invio del messaggio: " + response.message);
                    }
                }
                catch(e) {
                    errorPopup("Errore durante l'invio del messaggio: (Codice HTTP " + xhttp.status + ")");
                    console.error(e);
                }
            };
            xhttp.open("POST", BASE_URL + "/ws/chatMessage/create.php", true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(requestData));
        }
    },
    approveEstimate: function(ticketEstimationId) {
        var requestData={};
        requestData.ticketEstimationId=ticketEstimationId;
        requestData.ticketId=parseInt(document.getElementById("chat-related-to-ticket-id").value);
        if(requestData.message!=="") {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                try {
                    var response = JSON.parse(xhttp.responseText);
                    if(xhttp.status === 200) {
                        var interventionId = parseInt(document.getElementById("chat-related-to-intervention-id").value);
                        var chatExecutorId = parseInt(document.getElementById("chat-executor").value);
                        var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                        chatPopup.refresh(viewerIsExecutor,interventionId,requestData.ticketId);
                        successToast("Il preventivo è stato accettato");
                        changeTicketState(requestData.ticketId,'In lavorazione','Aggiungi richiesta di intervento',chatExecutorId);
                        popup("approve-estimate-for-ticket",true,false,false);
                    }
                    else {
                        errorPopup("Errore durante l'approvazione del preventivo: " + response.message);
                    }
                }
                catch(e) {
                    errorPopup("Errore durante l'approvazione del preventivo: (Codice HTTP " + xhttp.status + ")");
                    console.error(e);
                }
            };
            xhttp.open("POST", BASE_URL + "/ws/ticketEstimation/approve.php", true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(requestData));
        }
    },
    refuseEstimate: function(ticketEstimationId) {
        var requestData={};
        requestData.ticketEstimationId=ticketEstimationId;
        if(requestData.message!=="") {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                try {
                    var response = JSON.parse(xhttp.responseText);
                    if(xhttp.status === 200) {
                        var ticketId = parseInt(document.getElementById("chat-related-to-ticket-id").value);
                        var interventionId = parseInt(document.getElementById("chat-related-to-intervention-id").value);
                        var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                        chatPopup.refresh(viewerIsExecutor,interventionId,ticketId);
                        successToast("Il preventivo è stato rifiutato");
                    }
                    else {
                        errorPopup("Errore durante il rifiuto del preventivo: " + response.message);
                    }
                }
                catch(e) {
                    errorPopup("Errore durante il rifiuto del preventivo: (Codice HTTP " + xhttp.status + ")");
                    console.error(e);
                }
            };
            xhttp.open("POST", BASE_URL + "/ws/ticketEstimation/refuse.php", true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(requestData));
        }
    },
    uploadEstimation: function(){
        if(document.getElementById("upload-estimate-pdf").value != "") {
            popup("uploading-file");
            ajaxUploadDocument("upload-estimate-pdf","uploading-file-progress-bar",chatPopup.ajaxSendEstimation);
        }
        else {
            errorToast("Allegare il documento contenente il preventivo");
        }
    },
    ajaxSendEstimation:function(estimationFileId){
        var documentDetails = {};
        documentDetails.documentType = 16;//TODO: company document, not hard coded
        documentDetails.documentSubtype = 37;
        documentDetails.company = globalFilterByCompany;
        documentDetails.fileId = parseInt(estimationFileId);
        documentDetails.tags = ['Preventivo'];
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            closePopup();//Closing finalizing upload spinner popup
            closePopup();//Closing upload document popup
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    successToast("Documento caricato con successo");
                    var requestData = {};
                    requestData.message = document.getElementById("upload-estimate-message").value;
                    requestData.interventionId = document.getElementById("chat-related-to-intervention-id").value;
                    requestData.orderFormDocumentId = null;
                    requestData.approvedEstimateDocumentId = null;
                    requestData.estimationDocumentId = response.newDocumentId;
                    requestData.estimationImport = document.getElementById("upload-estimate-amount").value;
                    var xhttp2 = new XMLHttpRequest();
                    xhttp2.onload = function() {
                        try {
                            var response = JSON.parse(xhttp2.responseText);
                            if(xhttp2.status === 200) {
                                var ticketId = parseInt(document.getElementById("chat-related-to-ticket-id").value);
                                var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                                chatPopup.refresh(viewerIsExecutor,requestData.interventionId,ticketId);
                            }
                            else {
                                errorPopup("Errore durante l'invio del preventivo: " + response.message);
                            }
                        }
                        catch(e) {
                            errorPopup("Errore durante l'invio del preventivo: (Codice HTTP " + xhttp2.status + ")");
                            console.error(e);
                        }
                    };
                    xhttp2.open("POST", BASE_URL + "/ws/chatMessage/create.php", true);
                    xhttp2.setRequestHeader("Content-Type", "application/json");
                    xhttp2.setRequestHeader("Authentication-Token", currentAuthToken);
                    xhttp2.send(JSON.stringify(requestData));
                }
                else {
                    errorPopup(response.message);
                }
            }
            catch(error) {
                errorPopup("Errore durante l'elaborazione");
                console.error(error);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/finalizeDocumentUpload.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(documentDetails));
    },
    uploadApprovedEstimation: function(){
        if(document.getElementById("upload-approved-estimate-pdf").value != "") {
            popup("uploading-file");
            ajaxUploadDocument("upload-approved-estimate-pdf","uploading-file-progress-bar",chatPopup.ajaxSendApprovedEstimation);
        }
        else {
            errorToast("Allegare il documento contenente il preventivo approvato");
        }
    },
    uploadOrderForm: function(){
        if(document.getElementById("upload-order-form-pdf").value != "") {
            popup("uploading-file");
            ajaxUploadDocument("upload-order-form-pdf","uploading-file-progress-bar",chatPopup.ajaxSendOrderForm);
        }
        else {
            errorToast("Allegare il documento contenente il buono d'ordine");
        }
    },
    ajaxSendApprovedEstimation:function(approvedEstimationFileId){
        var documentDetails = {};
        documentDetails.documentType = 16;//TODO: company document, not hard coded
        documentDetails.documentSubtype = 37;
        documentDetails.company = globalFilterByCompany;
        documentDetails.fileId = parseInt(approvedEstimationFileId);
        documentDetails.tags = ['Preventivo approvato'];
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            closePopup();//Closing approve-estimate-for-ticket-popup
            closePopup();//Closing finalizing upload spinner popup
            closePopup();//Closing upload document popup
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    successToast("Documento caricato con successo");
                    selectTicket(lastSelectedTicketId,false);
                    var requestData = {};
                    requestData.message = document.getElementById("upload-approved-estimate-message").value;
                    requestData.interventionId = document.getElementById("chat-related-to-intervention-id").value;
                    requestData.approvedEstimateDocumentId = response.newDocumentId;
                    requestData.approvedEstimateDate = document.getElementById("upload-approved-estimate-date").value.split("/").reverse().join("-");
                    requestData.orderFormDocumentId = null;
                    requestData.orderFormDate = null;
                    requestData.orderFormNumber = null;
                    requestData.estimationDocumentId = null;
                    requestData.estimationImport = null;
                    var xhttp2 = new XMLHttpRequest();
                    xhttp2.onload = function() {
                        try {
                            var response = JSON.parse(xhttp2.responseText);
                            if(xhttp2.status === 200) {
                                var ticketId = parseInt(document.getElementById("chat-related-to-ticket-id").value);
                                var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                                chatPopup.refresh(viewerIsExecutor,requestData.interventionId,ticketId);
                            }
                            else {
                                errorPopup("Errore durante l'invio del preventivo: " + response.message);
                            }
                        }
                        catch(e) {
                            errorPopup("Errore durante l'invio del preventivo: (Codice HTTP " + xhttp2.status + ")");
                            console.error(e);
                        }
                    };
                    xhttp2.open("POST", BASE_URL + "/ws/chatMessage/create.php", true);
                    xhttp2.setRequestHeader("Content-Type", "application/json");
                    xhttp2.setRequestHeader("Authentication-Token", currentAuthToken);
                    xhttp2.send(JSON.stringify(requestData));
                }
                else {
                    errorPopup(response.message);
                }
            }
            catch(error) {
                errorPopup("Errore durante l'elaborazione");
                console.error(error);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/finalizeDocumentUpload.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(documentDetails));
    },
    ajaxSendOrderForm:function(orderFormFileId){
        var documentDetails = {};
        documentDetails.documentType = 16;//TODO: company document, not hard coded
        documentDetails.documentSubtype = 37;
        documentDetails.company = globalFilterByCompany;
        documentDetails.fileId = parseInt(orderFormFileId);
        documentDetails.tags = ["Buono d'ordine"];
        var xhttp = new XMLHttpRequest();

        xhttp.onload = function() {
            closePopup();//Closing approve-estimate-for-ticket-popup
            closePopup();//Closing finalizing upload spinner popup
            closePopup();//Closing upload document popup
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    successToast("Documento caricato con successo");
                    selectTicket(lastSelectedTicketId,false);
                    var requestData = {};
                    requestData.message = document.getElementById("upload-order-form-message").value;
                    requestData.interventionId = document.getElementById("chat-related-to-intervention-id").value;
                    requestData.approvedEstimateDocumentId = null;
                    requestData.approvedEstimateDate = null;
                    requestData.orderFormDocumentId = response.newDocumentId;
                    requestData.orderFormDate = document.getElementById("upload-order-form-date").value.split("/").reverse().join("-");
                    requestData.orderFormNumber = document.getElementById("upload-order-form-number").value;
                    requestData.estimationDocumentId = null;
                    requestData.estimationImport = null;
                    var xhttp2 = new XMLHttpRequest();
                    xhttp2.onload = function() {
                        try {
                            var response = JSON.parse(xhttp2.responseText);
                            if(xhttp2.status === 200) {
                                var ticketId = parseInt(document.getElementById("chat-related-to-ticket-id").value);
                                var viewerIsExecutor = !! document.getElementById("chat-viewer-is-executor").value;
                                chatPopup.refresh(viewerIsExecutor,requestData.interventionId,ticketId);
                            }
                            else {
                                errorPopup("Errore durante l'invio del preventivo: " + response.message);
                            }
                        }
                        catch(e) {
                            errorPopup("Errore durante l'invio del preventivo: (Codice HTTP " + xhttp2.status + ")");
                            console.error(e);
                        }
                    };
                    xhttp2.open("POST", BASE_URL + "/ws/chatMessage/create.php", true);
                    xhttp2.setRequestHeader("Content-Type", "application/json");
                    xhttp2.setRequestHeader("Authentication-Token", currentAuthToken);
                    xhttp2.send(JSON.stringify(requestData));
                }
                else {
                    errorPopup(response.message);
                }
            }
            catch(error) {
                errorPopup("Errore durante l'elaborazione");
                console.error(error);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/finalizeDocumentUpload.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(documentDetails));
    }
};
var assetReplacementNewAssetPopup = {
    initialize: function(newAssetQrCode) {
        if(newAssetQrCode!==null) {
            document.getElementById("asset-replacement-new-asset-qr").value = newAssetQrCode;
        }
        else {
            document.getElementById("asset-replacement-new-asset-qr").value = "";
        }
    },
    open: function(newAssetQrCode) {
        assetReplacementNewAssetPopup.initialize(newAssetQrCode);
        popup("asset-replacement-new-asset",true,false,false);
    }
};

function ajaxReplaceAsset(){
    var requestData = {};
    requestData.qr = document.getElementById("asset-replacement-new-asset-qr").value;
    if(requestData.qr===""){
        requestData.qr = null;
    }
    requestData.replacedAssetId = parseInt(document.getElementById("asset-replacement-new-asset-replaced-asset-id").value);
    requestData.brand = document.getElementById("asset-replacement-new-asset-brand").value;
    if(requestData.brand===""){
        delete requestData.brand;
    }
    requestData.model = document.getElementById("asset-replacement-new-asset-model").value;
    if(requestData.model===""){
        delete requestData.model;
    }
    requestData.notes = document.getElementById("asset-replacement-new-asset-notes").value;
    if(requestData.notes===""){
        delete requestData.notes;
    }
    requestData.registrationNumber = document.getElementById("asset-replacement-new-asset-registration-number").value;
    if(requestData.registrationNumber===""){
        delete requestData.registrationNumber;
    }

    closePopup();//use qr for asset replacement
    closePopup();//new asset data
    var assetInstanceKey = parseInt(document.getElementById("asset-replacement-new-asset-replaced-asset-key").value);
    var activityOnAssetInstanceKey = parseInt(document.getElementById("asset-replacement-activity-on-asset-instance-key").value);
    var isMobileView = isSmallViewport();
    toggleActivityOnAssetInstanceCheckbox(assetInstanceKey, activityOnAssetInstanceKey, "ok", isMobileView, true);
    outboxManager.addOperation(
        requestData,
        "/ws/asset/replace.php",
        "Sostituzione asset",
        function(){
            assetInstanceManager.refreshAssets(globalFilterByCompany);
            successToast("Asset sostituito con successo");
        }
    );
}

function ajaxSetExcludedSubcategory(){
    var requestData = {};
    requestData.companyId = parseInt(document.getElementById("view-companies-to-exclude-subc-category").value);
    requestData.subCatId = parseInt(document.getElementById("view-activities-to-exclude-subc-subcategory").value);

    outboxManager.addOperation(
        requestData,
        "/ws/subcategory/setExcluded.php",
        "Disabilitazione sottocategorie",
        function(){
            assetInstanceManager.refreshAssets(globalFilterByCompany);
            successToast("Categoria disabilitata correttamente.");
        }
    );
    ajaxLoadExcludedSubCategory();
}

function ajaxGetQrCodeData(qrCodeInUrl) {
    lastScannedQrCode = qrCodeInUrl;
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = 3500; // time in milliseconds
    if(qrCodeInUrl==null||qrCodeInUrl==="") {
        qrCodeInUrl=lastScannedQrCode;
    }
    var timeoutErrorAbort = function() {
        var found = false;
        preFetchedAssets.forEach(function(assetInstance) {
            if(assetInstance.qrCodeId == qrCodeInUrl) {
                renderAsset(assetInstance, true);
                closePopup();//closing outcome popup
                found = true;
            }
        });
        if(!found) {
            setInnerHtml(outcomePopup, "<div><button type='button' class='big-button' onclick='getQrCodeDataByScanningQrCode();'><i class='fa fa-qrcode fa-2x' aria-hidden='true'></i><br>Scansiona QR Code</button></div>");
            popup("assign-qr-no-internet", false, false, false);
        }
    };
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            switch(xhttp.status) {
                case 200: //OK
                    var tempHtml = "";
                    if(response.intervention && response.intervention.interventionId) {
                        if(response.authenticated) {
                            selectIntervention(response.intervention.interventionId);
                            closePopup();//closing outcome popup
                        }
                        else {
                            tempHtml = "<div class='details-container'>Il codice scansionato è collegato all'<strong>intervento " + response.intervention.interventionId + "</strong>.</div>";
                        }
                    }
                    if(response.ticket && response.ticket.ticketId) {
                        tempHtml += "<div class='details-container'>Il codice scansionato è collegato al <strong>ticket " + response.ticket.ticketId + "</strong>.</div>";
                    }
                    if(response.asset) {
                        if(response.asset.deleted) {
                            tempHtml += "<div class='details-container'><div class='second-col-header'>Dettagli asset scansionato</div>";
                            tempHtml += "L'asset collegato al QR code scansionato è stato eliminato da Claster o non si hanno i permessi per potervi accedere.";
                            tempHtml += "</div>";
                        }
                        else {
                            if(response.authenticated) {
                                ajaxGetAssetInstance(response.asset.assetInstanceId);
                                closePopup();//closing outcome popup
                            }
                        }
                    }
                    if((!response.ticket) && (!response.intervention) && (!response.asset)) {
                        if(response.authenticated) {
                            tempHtml += "<div class='details-container'><div class='second-col-header'>Codice non utilizzato</div><br>Il QR code scansionato non risulta collegato a nessuna entità all'interno di Claster.";
                            tempHtml += "<br><br><button type='button' onclick='popup( \"link-qr-to-entity\" ,false,undefined,false);'><i class='fa fa-link' aria-hidden='true'></i> Assegna ad un asset</button><br>";
                        }
                        else {
                            document.getElementById("navbar").style.display = "none";
                            tempHtml += "<div class='details-container'><div class='second-col-header'>Codice non utilizzato</div><br>Il QR code scansionato non risulta collegato a nessuna entità all'interno di Claster.";
                            tempHtml += "<br><br>Effettuando il login sarà possibile assegnare questo QR code ad un asset.";
                            tempHtml += "<br><br><button type='button' onclick='document.location.href=\"https://facility.claster.it\"'><i class='fa fa-sign-in' aria-hidden='true'></i> Effettua il login</button></div>";
                        }
                    }

                    setInnerHtml(outcomePopup, tempHtml);
                    break;
                default:
                    setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                    break;
                }
            }
            catch(e) {
                console.error(e);
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
    };
    xhttp.open("GET", BASE_URL + "/ws/qrCode/get.php?qr=" + qrCodeInUrl, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetAssetInstance(assetInstanceId) {
    var readyToDeleteAssetInstances = [];
    readyToDeleteAssetInstances.push(parseInt(assetInstanceId));
    document.getElementById("to-delete-asset-instances-counter").innerHTML = readyToDeleteAssetInstances.length;
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    
    function timeoutErrorAbort(){
        for(var i = 0; i < preFetchedAssets.length; i++) {
            if(preFetchedAssets[i].assetInstanceId == assetInstanceId) {
                lastViewedAssetInstance = preFetchedAssets[i];
            }
        }
        if(lastViewedAssetInstance !== null) {
            renderAsset(lastViewedAssetInstance, changePageAfter);
            ajaxGetAssetInstanceEventLog(lastViewedAssetInstance.assetInstanceId);
        }
    }
    
    xhttp.timeout = 4000;
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var asset = response.assetInstance;
                closePopup();
                renderAsset(asset, true);
                ajaxGetAssetInstanceEventLog(assetInstanceId);
                ajaxGetAssetExpirations(assetInstanceId);
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
            }
        }
        catch(e) {
            console.error(e);
            setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/asset/get.php?assetInstanceId=" + assetInstanceId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function getFloorNameFromFloorId(floorId) {
    if(floorId === null) {
        return "-";
    }
    for(var i = 0; i < floors.length; i++) {
        if(floorId == floors[i].floorId) {
            return floors[i].floorName;
        }
    }
}
function getAreaNameFromAreaId(areaId) {
    if(areaId === null) {
        return "-";
    }
    for(var i = 0; i < areas.length; i++) {
        if(areaId == areas[i].areaId) {
            return areas[i].code + " " + areas[i].name;
        }
    }
}

function renderAssetSubcategoryList(assetToBeRendered){
    var subcategoryList=[],categoryList="";
    var addedCategories = [];
    assetToBeRendered.subcategories.forEach(function(subcategory){
        if(subcategory.deletion===null){
            if(addedCategories.indexOf(subcategory.categoryId)<0){
                addedCategories.push(subcategory.categoryId);
                categoryList += "<div class='category-small b-" + subcategory.categoryIconBackground + "'>" + subcategory.categoryIcon + "</div>";
            }
            subcategoryList.push(subcategory.subcategoryName);
        }
    });
    return categoryList + " " + subcategoryList.join(" / ");
}

function renderSubcategoryLabelForRelationChild(relation,assetId) {
    return "<div class='category-small b-" + relation.categoryGroupBackground + "'>" + relation.categoryIcon + "</div>" + relation.subcategoryName + " " + assetId;
}

function ajaxDeleteAssetRelation(assetRelationId) {
    var requestData = {};
    requestData.relationId = parseInt(assetRelationId);
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Relazione asset eliminata");
                ajaxGetAssetInstance(parseInt(lastViewedAssetInstance.assetInstanceId));
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/assetRelation/delete.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function renderAssetRelations(assetToBeRendered) {
    var assetRelationDiv,assetRelationDeleteButton,assetRelationDivDescription;
    var tempHtml = "<ul class='fa-ul tree-view reverse-tree-view'>";
    var relatedAssetsContainer = document.getElementById("related-assets-container");
    relatedAssetsContainer.innerHTML="";
    if (assetToBeRendered.relations !== undefined) {
        assetToBeRendered.relations.forEach(function(relation) {
            //Arc starting TO the viewed asset
            if(relation.relationDeletion === null) {
                if(relation.destinationAssetInstance == assetToBeRendered.assetInstanceId) {
                    tempHtml += "<li>" +
                        "<button class='asset-relations-asset' onclick='selectAssetInstance("+relation.sourceAssetInstance+");'>" +
                        renderSubcategoryLabelForRelationChild(relation,relation.sourceAssetInstance) + "<span class='relation-descriptor'>" + relation.relationType + "</span>" +
                        "</button>";
                    tempHtml += "</li>";
                    assetRelationDiv = document.createElement("div");
                    dom.addClass(assetRelationDiv,"entry");
                    assetRelationDeleteButton = document.createElement("button");
                    dom.addClass(assetRelationDeleteButton,"delete-button");
                    assetRelationDeleteButton.innerHTML = "<i class='fa fa-trash' aria-hidden='true'></i> Rimuovi relazione";
                    defineTapListenerOnElement(assetRelationDeleteButton,
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var localRelationId=relation.assetRelationId;
                            return function(event) {
                                // Actual callback here
                                ajaxDeleteAssetRelation(localRelationId);
                            };
                        })()
                    );
                    assetRelationDivDescription = document.createElement("div");
                    assetRelationDivDescription.innerHTML = renderSubcategoryLabelForRelationChild(relation,relation.sourceAssetInstance) + " <strong>" + relation.relationType + "</strong>" + " questo asset";
                    assetRelationDiv.appendChild(assetRelationDivDescription);
                    assetRelationDiv.appendChild(assetRelationDeleteButton);
                    relatedAssetsContainer.appendChild(assetRelationDiv);
                }
            }
        });
    }
    tempHtml += "</ul>" +
        "<button class='asset-relations-main-asset'>" +
        renderAssetSubcategoryList(assetToBeRendered) + " " +
        assetToBeRendered.assetInstanceId +
        "</button>" +
        "<ul class='fa-ul tree-view'>";
    if (assetToBeRendered.relations !== undefined) {
        assetToBeRendered.relations.forEach(function(relation) {
            //Arc starting FROM the viewed asset
            if(relation.relationDeletion === null) {
                if(relation.sourceAssetInstance == assetToBeRendered.assetInstanceId) {
                    tempHtml += "<li>" +
                        "<button class='asset-relations-asset' onclick='selectAssetInstance("+relation.destinationAssetInstance+");'>" +
                        "<span class='relation-descriptor'>" + relation.relationType + "</span>" + renderSubcategoryLabelForRelationChild(relation,relation.destinationAssetInstance) +
                        "</button>";
                    tempHtml += "</li>";
                    assetRelationDiv = document.createElement("div");
                    dom.addClass(assetRelationDiv,"entry");
                    assetRelationDeleteButton = document.createElement("button");
                    dom.addClass(assetRelationDeleteButton,"delete-button");
                    assetRelationDeleteButton.innerHTML = "<i class='fa fa-trash' aria-hidden='true'></i> Rimuovi relazione";
                    defineTapListenerOnElement(assetRelationDeleteButton,
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var localRelationId=relation.assetRelationId;
                            return function(event) {
                                // Actual callback here
                                ajaxDeleteAssetRelation(localRelationId);
                            };
                        })()
                    );
                    assetRelationDivDescription = document.createElement("div");
                    assetRelationDivDescription.innerHTML = "Questo asset <strong>" + relation.relationType +  "</strong> " +renderSubcategoryLabelForRelationChild(relation,relation.sourceAssetInstance) + "<span class='relation-descriptor'>"  + "</span>";
                    assetRelationDiv.appendChild(assetRelationDivDescription);
                    assetRelationDiv.appendChild(assetRelationDeleteButton);
                    relatedAssetsContainer.appendChild(assetRelationDiv);
                }
            }
        });
    }
    tempHtml += "</ul>";
    scroll.refreshPopupById("edit-asset-tree", false);
    document.getElementById("asset-relationship-graph").innerHTML = tempHtml;
    scroll.refreshByName("asset-relations");
}

function refreshAssetDetailsScroll() {
    scroll.refreshByName("asset-details");
}

/* Draws the content of the asset page */
var readyToDeleteAssetInstances = [];
function renderAsset(assetToBeRendered, changePageAfter) {
    var render = function(assetToBeRendered, changePageAfter){
        readyToDeleteAssetInstances = [];
        readyToDeleteAssetInstances.push(assetToBeRendered.assetInstanceId);
        document.getElementById("to-delete-asset-instances-counter").innerHTML = readyToDeleteAssetInstances.length;
        lastViewedAssetInstance = assetToBeRendered;
        if(changePageAfter) {
            changePage("page-asset", true, true, false);
        }
        if(window.location.hash != "#asset/" + assetToBeRendered.assetInstanceId){
            hashChangedInternally = true;
            window.location.hash = "#asset/" + assetToBeRendered.assetInstanceId;
        }
        var titleString = "Asset " + assetToBeRendered.assetInstanceId;

        setInnerHtml(document.getElementById("page-asset").querySelector(".page-main-title"), titleString);
        var tempHtml = "";
        tempHtml += "<div class='second-col-header'>Dettagli asset</div>";
        /**
         * @type {Object}
         * @param {String} qrCodeId
         */
        assetToBeRendered.subcategories.forEach(function(subcategory){
            if(subcategory.deletion === null){
                assetToBeRendered.categoryIcon=subcategory.categoryIcon;
                assetToBeRendered.subcategoryIcon=subcategory.subcategoryIcon;
                assetToBeRendered.categoryIconBackground=subcategory.categoryIconBackground;
                assetToBeRendered.categoryName=subcategory.categoryName;
                assetToBeRendered.subcategoryName=subcategory.subcategoryName;
            }
        });
        var i;
        tempHtml += "<div class='site-label'><span class='site'>" + assetToBeRendered.letter + "</span>" + assetToBeRendered.siteName + "</div><br><br>";
        for(i = 0; i < assetToBeRendered.subcategories.length; i++) {
            if(assetToBeRendered.subcategories[i].deletion==null) {
                tempHtml += "<div class='asset-details-subcategory-row'>";
                tempHtml += "<div class='category-label'><div class='category-small b-" + assetToBeRendered.subcategories[i].categoryIconBackground + "'>" + assetToBeRendered.subcategories[i].categoryIcon + "</div>" + assetToBeRendered.subcategories[i].categoryName + "</div>";
                tempHtml += "<div class='subcategory-label'><div class='category-small b-" + assetToBeRendered.subcategories[i].categoryIconBackground + "'>" + assetToBeRendered.subcategories[i].subcategoryIcon + "</div>" + assetToBeRendered.subcategories[i].subcategoryName + "</div>";
                tempHtml += "</div>";
            }
        }
        var conformityDeclarationDocumentId = null;
        var manualDocumentId = null;
        var projectDocumentId = null;
        var manualDocumentFormat = null;
        var projectDocumentFormat = null;
        var conformityDeclarationDocumentFormat = null;
        if(assetToBeRendered.documents!=undefined){
            assetToBeRendered.documents.forEach(function(assetDocument){
               switch(assetDocument.assetDocumentType){
                   case 'manual':
                       manualDocumentId = assetDocument.assetDocumentId;
                       manualDocumentFormat = assetDocument.assetDocumentFormat;
                       break;
                   case 'project':
                       projectDocumentId = assetDocument.assetDocumentId;
                       projectDocumentFormat = assetDocument.assetDocumentFormat;
                       break;
                   case 'conformityDeclaration':
                       conformityDeclarationDocumentId = assetDocument.assetDocumentId;
                       conformityDeclarationDocumentFormat = assetDocument.assetDocumentFormat;
                       break;
               }
            });
        }
        else {
            ajaxSendErrorReport(renderAsset,JSON.stringify(assetToBeRendered),new Error().stack);
        }

        tempHtml += "<br><div class='hth'>";
        tempHtml += "<div class='tr'><div>Descrizione</div><div>" + ((assetToBeRendered.description === null) ? "-" : assetToBeRendered.description) + "</div></div>";
        tempHtml += "<div class='tr'><div><i onclick='popup( \"help-ticket-assets\" ,false,undefined,false);' class='fa fa-qrcode qr-icon' aria-hidden='true'></i> QR asset</i></div><div>" + ((assetToBeRendered.qrCodeId === null) ? "-" : assetToBeRendered.qrCodeId) + "</div></div>";
        tempHtml += "<div class='tr'><div>Codice interno</div><div>" + ((assetToBeRendered.internalCode === null) ? "-" : assetToBeRendered.internalCode) + "</div></div>";
        tempHtml += "<div class='tr'><div>Matricola</div><div>" + ((assetToBeRendered.registrationNumber === null) ? "-" : assetToBeRendered.registrationNumber) + "</div></div>";
        if(assetToBeRendered.brand !== null){
            tempHtml += "<div class='tr'><div>Marca</div><div>" + assetToBeRendered.brand + "</div></div>";
        }
        if(assetToBeRendered.model !== null){
            tempHtml += "<div class='tr'><div>Modello</div><div>" + assetToBeRendered.model + "</div></div>";
        }
        if(assetToBeRendered.type !== null){
            tempHtml += "<div class='tr'><div>Tipo</div><div>" + assetToBeRendered.type + "</div></div>";
        }
        tempHtml += "<div class='tr'><div>Piano</div><div>" + getFloorNameFromFloorId(assetToBeRendered.floor) + "</div></div>";
        tempHtml += "<div class='tr'><div>Immobile</div><div>" + getAreaNameFromAreaId(assetToBeRendered.areaId) + "</div></div>";
        if(assetToBeRendered.positionHint !== null){
            tempHtml += "<div class='tr'><div>Localizzazione</div><div>" + assetToBeRendered.positionHint + "</div></div>";
        }
        if(assetToBeRendered.creationCausal !== null){
            tempHtml += "<div class='tr'><div>Note</div><div>" + assetToBeRendered.creationCausal + "</div></div>";
        }
        if(assetToBeRendered.size !== null){
            tempHtml += "<div class='tr'><div>Dimensioni</div><div>" + assetToBeRendered.size + "</div></div>";
        }
        if(assetToBeRendered.homologation !== null){
            tempHtml += "<div class='tr'><div>Omologazione</div><div>" + assetToBeRendered.homologation + "</div></div>";
        }
        if(assetToBeRendered.certification !== null){
            tempHtml += "<div class='tr'><div>Certificazione</div><div>" + assetToBeRendered.certification + "</div></div>";
        }
        if(assetToBeRendered.constructionYear !== null){
            tempHtml += "<div class='tr'><div>Anno di costruzione</div><div>" + assetToBeRendered.constructionYear + "</div></div>";
        }
        if(assetToBeRendered.expectedLifeInYears !== null){
            tempHtml += "<div class='tr'><div>Vita media</div><div>" + assetToBeRendered.expectedLifeInYears + " anni</div></div>";
        }
        if(assetToBeRendered.assetValueAsNew !== null){
            tempHtml += "<div class='tr'><div>Valore a nuovo</div><div>" + assetToBeRendered.assetValueAsNew + " EUR</div></div>";
        }
        if(assetToBeRendered.instantaneousConsumption !== null){
            tempHtml += "<div class='tr'><div>Assorbimento</div><div>" + assetToBeRendered.instantaneousConsumption + " kW</div></div>";
        }
        if(assetToBeRendered.operationHoursOfDay !== null){
            tempHtml += "<div class='tr'><div>Lavoro giornaliero</div><div>" + assetToBeRendered.operationHoursOfDay + " ore</div></div>";
        }
        if(assetToBeRendered.operationDaysOfYear !== null){
            tempHtml += "<div class='tr'><div>Lavoro annuale</div><div>" + assetToBeRendered.operationDaysOfYear + " giorni</div></div>";
        }

        for(i = 0; i < assetToBeRendered.assetFieldForSubcategoryValues.length; i++) {
            if(assetToBeRendered.assetFieldForSubcategoryValues[i].deletion==null) {
                tempHtml += "<div class='tr'><div>" + assetToBeRendered.assetFieldForSubcategoryValues[i].fieldName + "</div><div>";
                var j;
                for(j = 0; j < assetToBeRendered.assetFieldForSubcategoryValues[i].values.length; j++) {
                    var wrongValue = assetToBeRendered.assetFieldForSubcategoryValues[i].values[j].assetFieldForSubcategoryWrongValue;
                    tempHtml += iso8601.toEuropeanDate(assetToBeRendered.assetFieldForSubcategoryValues[i].values[j].assetFieldForSubcategoryValueRegistrationDatetime)+": "+
                        assetToBeRendered.assetFieldForSubcategoryValues[i].values[j].assetFieldForSubcategoryValue +
                        ((wrongValue&&wrongValue!="")?" <span class='activity-on-asset-instance-custom-field-wrong-holder'> ( "+wrongValue+" )</span>":"")+
                        "<br>";
                }
                tempHtml += "</div></div>";
            }
        }
        tempHtml += "</div>";
        if(conformityDeclarationDocumentId!==null) {
            tempHtml += "<button type='button' class='asset-document' onclick='renderDocument("+conformityDeclarationDocumentId+",\""+conformityDeclarationDocumentFormat+"\");'>"+
                renderDocumentIcon(conformityDeclarationDocumentFormat,false)+" Dichiarazione di conformità</button>";
        }
        else {
            tempHtml += "<button type='button' class='asset-document' disabled='disabled'>"+
            renderDocumentIcon(conformityDeclarationDocumentFormat,false)+" Dichiarazione di conformità</button>";
        }
        if(manualDocumentId!==null) {
            tempHtml += "<button type='button' class='asset-document' onclick='renderDocument("+manualDocumentId+",\""+manualDocumentFormat+"\");'>"+
                renderDocumentIcon(manualDocumentFormat,false)+" Manuale</button>";
        }
        else {
            tempHtml += "<button type='button' class='asset-document' disabled='disabled'>" +
                renderDocumentIcon(manualDocumentFormat, false) + " Manuale</button>";
        }
        if(projectDocumentId!==null) {
            tempHtml += "<button type='button' class='asset-document' onclick='renderDocument("+projectDocumentId+",\""+projectDocumentFormat+"\");'>"+
                renderDocumentIcon(projectDocumentFormat,false)+" Progetto</button>";
        }
        else {
            tempHtml += "<button type='button' class='asset-document' disabled='disabled'>" +
                renderDocumentIcon(projectDocumentFormat, false) + " Progetto</button>";
        }
        tempHtml += "<br>";
        if(assetToBeRendered.relatedPictures.length > 0) {
            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Foto asset</span><br>";
            for(i = 0; i < assetToBeRendered.relatedPictures.length; i++) {
                tempHtml += "<img " +
                    "class='thumbnail-image' " +
                    "onload='refreshAssetDetailsScroll();' " +
                    "onclick='imageTheater(\"" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + assetToBeRendered.relatedPictures[i] + "&authToken=" + currentAuthToken + "\");' " +
                    "src='" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + assetToBeRendered.relatedPictures[i] + "&height=160&authToken=" + currentAuthToken + "'> ";
            }
        }
        if(assetToBeRendered.relatedPicturesAmbient.length > 0) {
            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Foto asset e ambiente</span><br>";
            for(i = 0; i < assetToBeRendered.relatedPicturesAmbient.length; i++) {
                tempHtml += "<img " +
                    "class='thumbnail-image' " +
                    "onload='refreshAssetDetailsScroll();' " +
                    "onclick='imageTheater(\"" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + assetToBeRendered.relatedPicturesAmbient[i] + "&authToken=" + currentAuthToken + "\");' " +
                    "src='" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + assetToBeRendered.relatedPicturesAmbient[i] + "&height=160&authToken=" + currentAuthToken + "'> ";
            }
        }
        document.querySelector("#page-asset .details-container").innerHTML = tempHtml;
        scroll.refreshByName("asset-details");
        renderAssetRelations(assetToBeRendered);
    };

    if(assetToBeRendered.siteOwner!==globalFilterByCompany){
        console.log("Site owner does not match currently selected company, changing company...");
        ajaxGetDashboardDataByEntity(
            function(){render(assetToBeRendered, changePageAfter);},
            "asset",
            assetToBeRendered.assetInstanceId
        );
    }
    else {
        render(assetToBeRendered, changePageAfter);
    }
}

function popupUserPermissions() {
    var tempHtml = "<div class='permissions-list'>";
    userPermissions.forEach(function(userPermission){
        tempHtml += "<h3>" + userPermission.companyName + "</h3>";
        userPermission.permissions.forEach(function(permission){
            if(permission.permissionLevel==="ACCESSIBLE" || permission.permissionLevel==="DISABLED") {
                tempHtml += "<div class='entry'><div>" + permission.permissionName + " <br><span class='optional'>" + permission.permissionCode + "</span></div><div>" + ((permission.permissionLevel === "ACCESSIBLE") ? "Disponibile" : "Non disponibile") + "</div></div>";
            }
        });
    });
    tempHtml += "</div>";
    setInnerHtml(
        document.querySelector("#permissions-popup .popup-content div"),
        tempHtml,
        function(){scroll.refreshPopupById("permissions", false);}
    );
}


var exportAssetFieldForSubcategoryReportPopup = {
    assetFieldForSubcategoryTypes:null,
    init:function() {
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    tempHtml = "";
                    exportAssetFieldForSubcategoryReportPopup.assetFieldForSubcategoryTypes = response.assetFieldForSubcategoryTypes;
                    response.assetFieldForSubcategoryTypes.forEach(function(type){
                        if(type.keepHistory){
                            tempHtml += "<option value='" + type.assetFieldForSubcategoryTypeId + "'>" + type.subcategoryName + " - " + type.fieldName + "</option>";
                        }
                    });
                    setInnerHtml(
                        document.getElementById("export-asset-field-for-subcategory-report-field-type"),
                        tempHtml,
                        exportAssetFieldForSubcategoryReportPopup.refreshDates
                    );
                    closePopup();
                    popup("export-asset-field-for-subcategory-report",false,false,false);
                }
                else {
                    setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                }
            }
            catch(e) {
                console.error(e);
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/assetFieldForSubcategoryType/get.php?companyId="+globalFilterByCompany, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    },
    refreshDates:function() {
        var value = document.getElementById("export-asset-field-for-subcategory-report-field-type").value;
        var addedDatesCounter = 0;
        var tempHtml="",count;

        count=0;
        exportAssetFieldForSubcategoryReportPopup.assetFieldForSubcategoryTypes.forEach(function(type) {
            if(type.assetFieldForSubcategoryTypeId == value) {
                type.availableDates.sort(function(a,b){
                    //ordering dates from most recent to oldest
                    return parseInt(b.split("-").reverse().join(""))-parseInt(a.split("-").reverse().join(""));
                }).forEach(function(availableDate){
                    tempHtml += "<option value='" + availableDate + "'";
                    if(count==1){
                        tempHtml+=" selected='selected' ";
                    }
                    count++;
                    tempHtml += ">" + availableDate + "</option>";
                });
                addedDatesCounter = type.availableDates.length;
            }
        });

        if(addedDatesCounter==0) {
            tempHtml="<option value=''> Nessuna lettura disponibile</option>";
        }
        document.getElementById("export-asset-field-for-subcategory-report-date-from").innerHTML=tempHtml;
        tempHtml="";
        count=0;
        exportAssetFieldForSubcategoryReportPopup.assetFieldForSubcategoryTypes.forEach(function(type) {
            if(type.assetFieldForSubcategoryTypeId == value) {
                type.availableDates.sort(function(a,b){
                    //ordering dates from most recent to oldest
                    return parseInt(b.split("-").reverse().join(""))-parseInt(a.split("-").reverse().join(""));
                }).forEach(function(availableDate){
                    tempHtml += "<option value='" + availableDate + "'";
                    if(count===0){
                        tempHtml+=" selected='selected' ";
                    }
                    count++;
                    tempHtml += ">" + availableDate + "</option>";
                });
                addedDatesCounter = type.availableDates.length;
            }
        });
        if(addedDatesCounter<=1) {
            tempHtml="<option value=''> Nessuna lettura disponibile</option>";
        }
        document.getElementById("export-asset-field-for-subcategory-report-date-to").innerHTML=tempHtml;
    },
    submit: function(assetFieldForSubcategoryTypeId,from,to) {
        var newWindow = null;
        if(Capacitor.platform=='web') {
            newWindow=window.open();
        }
        ajaxGenerateAssetFieldForSubcategoryReportOtt(assetFieldForSubcategoryTypeId,function(ott) {
            ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/assetFieldForSubcategoryType/report.php?assetFieldForSubcategoryTypeId=" + assetFieldForSubcategoryTypeId + "&ott=" + ott + "&companyId=" + globalFilterByCompany + "&from=" + from + "&to=" + to, "report-campo-asset-" + assetFieldForSubcategoryTypeId + ".pdf", false, newWindow, "pdf");
        });
    }
};

function ajaxGenerateAssetFieldForSubcategoryReportOtt(assetFieldForSubcategoryTypeId,callback) {
    var requestData={};
    requestData.companyId=globalFilterByCompany;
    if(assetFieldForSubcategoryTypeId!=""){
        requestData.assetFieldForSubcategoryTypeId=parseInt(assetFieldForSubcategoryTypeId);
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                callback(response.ott);
            }
            else {
                errorPopup("Errore durante l'autenticazione: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione: (Codice HTTP " + xhttp.status + ")");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/ott/createForAssetFieldForSubcategoryType.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxGenerateCustomerSatisfactionPointOtt(customerSatisfactionPointId,callback) {
    var requestData={};
    requestData.customerSatisfactionPointId=customerSatisfactionPointId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                callback(response.ott);
            }
            else {
                errorPopup("Errore durante l'autenticazione: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione: (Codice HTTP " + xhttp.status + ")");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/ott/createForCustomerSatisfactionPoint.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

var outboxManager = {
    outbox: [],
    sending: false,
    randomString: function() {
      var text = "";
      //Be carefull not to add here special characters like parenthesis as they can cause false positivise at sql injection protection firewalls
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789,";
      for (var i = 0; i < 32; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    },
    generatePseudoUniqueIdentifier: function() {
        var date = new Date();
        var year    = ""+date.getFullYear();
        var month   = ""+date.getMonth();
        var day     = ""+date.getDay();
        var hour    = ""+date.getHours();
        var minute  = ""+date.getMinutes();
        var seconds = ""+date.getSeconds();
        var millisecons = ""+date.getMilliseconds();
        var randomString = outboxManager.randomString();
        return year+month+day+hour+minute+seconds+millisecons+randomString;
    },
    showEntryDetails: function(rowid){
        popup("outbox-entry-details", true, false, false);
        outboxManager.outbox.forEach(function(outboxEntry){
            if(outboxEntry.rowid===rowid){
                var popupContainer=document.getElementById("outbox-entry-details-container");
                var tempHtml="";
                tempHtml+="<div><strong>ROWID</strong><div>"+outboxEntry.rowid+"</div></div>";
                tempHtml+="<div><strong>Creazione</strong><div>"+outboxEntry.creation+"</div></div>";
                tempHtml+="<div><strong>Tipologia di operazione</strong><div>"+outboxEntry.operationType+"</div></div>";
                tempHtml+="<div><strong>Web Service</strong><div>"+outboxEntry.ws+"</div></div>";
                tempHtml+="<div><strong>Payload</strong><div>"+outboxEntry.jsonRequest+"</div></div>";
                tempHtml+="<div><strong>Ultimo tentativo di invio</strong><div>"+outboxEntry.lastAttempt+"</div></div>";
                tempHtml+="<div><strong>Ultimo errore</strong><div>"+outboxEntry.lastAttemptError+"</div></div>";
                setInnerHtml(popupContainer,tempHtml,function(){scroll.refreshPopupById("outbox-entry-details", false);});
            }
        });

    },
    drawList: function() {
        var tempHtml = "";
        var operationsContainer = document.getElementById("outbox-container");
        if(db.localDb) {
            db.getEnqueuedOfflineOperationsForUser(currentLoggedInUserId,function(resultSet){
                if(resultSet.rows.length===0){
                    tempHtml="<div class='no-results-message'>Nessuna operazione in coda</div>";
                }
                else{
                    tempHtml = "";
                    outboxManager.outbox=[];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        outboxManager.outbox.push(row);
                        tempHtml += "<div class='entry' onclick='outboxManager.showEntryDetails("+row.rowid+");'>" +
                            "<div>"+row.creation+"</div>"+
                            "<div>"+row.operationType+"</div></div>";
                    }
                }
                setInnerHtml(operationsContainer,tempHtml,function(){scroll.refreshPopupById("outbox", false);});
            });
        }
        else {
            tempHtml="<div class='no-results-message'>DB locale non disponibile su questa piattaforma</div>";
            setInnerHtml(operationsContainer,tempHtml);
        }
    },
    //This function is meant to be used only for POST operations with JSON encoded payload
    addOperation: function(requestObject,ws,operationType,immediateSuccessCallback,previousOperationsRowId,localDbEnqueueCallback){
        if(db.localDb) {
            //First I add the operation to the local DB
            requestObject.pseudoUniqueOperationId = outboxManager.generatePseudoUniqueIdentifier();
            db.enqueueOfflineOperation(JSON.stringify(requestObject), ws, operationType, currentLoggedInUserId, previousOperationsRowId, function(rowId) {
                if(typeof localDbEnqueueCallback === "function"){
                    localDbEnqueueCallback(rowId);
                }
                //Operation logged in the DB, I try to elaborate the operation the first time
                var xhttp = new XMLHttpRequest();
                xhttp.timeout = 6500;
                xhttp.onerror = function() {
                    db.logOfflineOperationAttempt(rowId, "Errore di connessione",function(){
                        outboxManager.refreshCounter();
                    });
                };
                xhttp.onabort = function() {
                    db.logOfflineOperationAttempt(rowId, "Connessione annullata",function(){
                        outboxManager.refreshCounter();
                    });
                };
                xhttp.ontimeout = function() {
                    db.logOfflineOperationAttempt(rowId, "Timeout",function(){
                        outboxManager.refreshCounter();
                    });
                };
                xhttp.onload = function() {
                    var response;
                    //OK
                    if(xhttp.status === 200) {
                        db.removeOfflineOperationFromQueue(rowId);
                        if(typeof immediateSuccessCallback === "function"){
                            immediateSuccessCallback(xhttp.responseText);
                        }
                        if(operationType==="Modifica stato attività intervento"){
                            outboxManager.activityOnAssetInstanceCallback(requestObject);
                        }
                        if(operationType==="Modifica stato ticket durante intervento"){
                            outboxManager.activityOnTicketInInterventionCallback(requestObject);
                        }
                    }
                    else {
                        ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestObject), xhttp.responseText);
                    }
                    //Server's fault
                    if(xhttp.status >= 500 && xhttp.status < 600) {
                        db.logOfflineOperationAttempt(rowId, "Errore del server " + xhttp.status,function(){
                            outboxManager.refreshCounter();
                        });
                        successToast("Server non disponibile, la richiesta verrà inviata automaticamente più tardi");
                    }
                    //Client's fault
                    if(xhttp.status >= 400 && xhttp.status < 500) {
                        db.removeOfflineOperationFromQueue(rowId);
                        try {
                            response = JSON.parse(xhttp.responseText);
                            errorPopup("Errore: " + response.message);
                        }
                        catch(e) {
                            console.error(e);
                            errorPopup("Errore "+xhttp.status+" durante la richiesta");
                        }
                    }
                };
                xhttp.open("POST", BASE_URL + ws, true);
                xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
                xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
                xhttp.send(JSON.stringify(requestObject));
            });
        }
        //Local DB is not available
        else {
            var xhttp = new XMLHttpRequest();
            xhttp.timeout = 20000;
            xhttp.onerror = function() {
                errorPopup("Errore durante la richiesta: problema di connessione");
            };
            xhttp.onabort = function() {
                errorPopup("Errore durante la richiesta: connessione annullata");
            };
            xhttp.ontimeout = function() {
                errorPopup("Errore durante la richiesta: il server ha impiegato troppo tempo per rispondere");
            };
            xhttp.onload = function() {
                var response;
                //OK
                if(xhttp.status === 200) {
                    if(typeof immediateSuccessCallback === "function"){
                        immediateSuccessCallback(xhttp.responseText);
                    }
                    if(operationType==="Modifica stato attività intervento"){
                        outboxManager.activityOnAssetInstanceCallback(requestObject);
                    }
                    if(operationType==="Modifica stato ticket durante intervento"){
                        outboxManager.activityOnTicketInInterventionCallback(requestObject);
                    }
                }
                else {
                    try {
                        response = JSON.parse(xhttp.responseText);
                        errorPopup("Errore: " + response.message);
                    }
                    catch(e) {
                        console.error(e);
                        errorPopup("Errore "+xhttp.status+" durante la richiesta");
                        ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestObject), xhttp.responseText);
                    }
                }
            };
            xhttp.open("POST", BASE_URL + ws, true);
            xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(requestObject));
        }
    },
    send: function(skipSendingCheck) {
        if(db.localDb) {
            //If I'm not already sending operations in the outbox queue
            if((!outboxManager.sending)||skipSendingCheck) {
                outboxManager.sending = true;
                db.getOldestEnqueuedOfflineOperationForUser(currentLoggedInUserId, function(operation) {
                    if(operation !== null) {
                        var xhttp = new XMLHttpRequest();
                        xhttp.timeout = 5000;
                        xhttp.onerror = function() {
                            db.logOfflineOperationAttempt(operation.rowid, "Errore di connessione");
                            outboxManager.sending = false;
                        };
                        xhttp.onabort = function() {
                            db.logOfflineOperationAttempt(operation.rowid, "Connessione annullata");
                            outboxManager.sending = false;
                        };
                        xhttp.ontimeout = function() {
                            db.logOfflineOperationAttempt(operation.rowid, "Timeout");
                            outboxManager.sending = false;
                        };
                        xhttp.onload = function() {
                            var response;
                            //OK
                            if(xhttp.status === 200) {
                                db.removeOfflineOperationFromQueue(operation.rowid, function() {
                                    //Sending the following operation in queue, if any
                                    outboxManager.send(true);
                                    outboxManager.refreshCounter();
                                });
                                if(operation.operationType==="Modifica stato attività intervento"){
                                    try {
                                        outboxManager.activityOnAssetInstanceCallback(JSON.parse(operation.jsonRequest));
                                    }
                                    catch(e) {
                                        console.error(e);
                                        ajaxSendErrorReport(xhttp.responseURL, operation.jsonRequest, "outboxManager.activityOnAssetInstanceCallback "+e.message);
                                    }
                                }
                                if(operation.operationType==="Modifica stato ticket durante intervento"){
                                    try {
                                        outboxManager.activityOnTicketInInterventionCallback(JSON.parse(operation.jsonRequest));
                                    }
                                    catch(e) {
                                        console.error(e);
                                        ajaxSendErrorReport(xhttp.responseURL, operation.jsonRequest, "outboxManager.activityOnTicketInInterventionCallback "+e.message);
                                    }
                                }
                            }
                            //Server's fault
                            if(xhttp.status >= 500 && xhttp.status < 600) {
                                db.logOfflineOperationAttempt(operation.rowid, "Errore del server " + xhttp.status, function() {
                                    //Sending the following operation in queue, if any
                                    outboxManager.send(true);
                                });
                                successToast("Server non disponibile, la richiesta verrà inviata automaticamente più tardi");
                            }
                            //Client's fault
                            if(xhttp.status >= 400 && xhttp.status < 500) {
                                db.removeOfflineOperationFromQueue(operation.rowid, function() {
                                    //Sending the following operation in queue, if any
                                    outboxManager.send(true);
                                    outboxManager.refreshCounter();
                                });
                                try {
                                    response = JSON.parse(xhttp.responseText);
                                    errorPopup("Errore: " + response.message);
                                }
                                catch(e) {
                                    console.error(e);
                                    errorPopup("Errore " + xhttp.status + " durante la richiesta");
                                    ajaxSendErrorReport(xhttp.responseURL, operation.jsonRequest, xhttp.responseText);
                                }
                            }
                        };
                        xhttp.open("POST", BASE_URL + operation.ws, true);
                        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
                        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
                        xhttp.send(operation.jsonRequest);
                    }
                    else {
                        outboxManager.sending = false;
                    }
                });
            }
            else {
                console.log("L'invio è già in corso");
            }
        }
        else
        {
            //Impossibile elaborare coda operazioni offline: DB locale non disponibile
        }
    },
    refreshCounter: function(){
        if(db.localDb) {
            db.getEnqueuedOfflineOperationsCountForUser(currentLoggedInUserId, function(counterValue) {
                var outboxTab = document.getElementById("tab-outbox-container");
                if(counterValue > 0) {
                    setInnerHtml(outboxTab.querySelector(".outbox-counter"), counterValue);
                    dom.removeClass(outboxTab, "hidden");
                }
                else {
                    dom.addClass(outboxTab, "hidden");
                    outboxManager.sending = false;
                }
                if(dom.hasClass(document.getElementById("outbox-popup"),"visible-popup")) {
                    outboxManager.drawList();
                }
            });
        }
    },
    //A success callback for every operation with type "Modifica stato attività intervento"
    activityOnAssetInstanceCallback: function(sentObject){
        //If the app was closed and the same intervention it's not under execution, this array will not be available
        if(assetInstancesInExecution) {
            if(assetInstancesInExecution[sentObject.assetInstanceKey]) {
                if(assetInstancesInExecution[sentObject.assetInstanceKey].activitiesOnAssetInstance[sentObject.activityOnAssetInstanceKey]) {
                    var currentActivity = assetInstancesInExecution[sentObject.assetInstanceKey].activitiesOnAssetInstance[sentObject.activityOnAssetInstanceKey];
                    currentActivity.sentToServer = true;
                    if(currentActivity.activityContainer) {
                        setInnerHtml(currentActivity.activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-check' aria-hidden='true'></i>");
                    }
                    if(currentActivity.activityContainerMobile) {
                        setInnerHtml(currentActivity.activityContainerMobile.querySelector(".sent-to-server-icon"), "<i class='fa fa-check' aria-hidden='true'></i>");
                    }
                }
            }
        }
    },
    activityOnTicketInInterventionCallback: function(sentObject){
        //If the app was closed and the same intervention it's not under execution, this array will not be available
        if(assetInstancesInExecution) {
            if(assetInstancesInExecution[sentObject.assetInstanceKey]) {
                if(assetInstancesInExecution[sentObject.assetInstanceKey].relatedTickets[sentObject.activityOnTicketKey]) {
                    var currentActivity = assetInstancesInExecution[sentObject.assetInstanceKey].relatedTickets[sentObject.activityOnTicketKey];
                    currentActivity.sentToServer = true;
                    if(currentActivity.activityContainer) {
                        setInnerHtml(currentActivity.activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-check' aria-hidden='true'></i>");
                    }
                }
            }
        }
    }
};

var ticketingPage = {
    showingArchive: false,
    manualRefresh: function() {
        dom.addClass(document.getElementById("ticket-manual-refresh-spinner"), "fa-spin");
        if(lastSelectedTicket) {
            ajaxGetTickets(lastSelectedTicket);
        }
        else {
            ajaxGetTickets();
        }
    },
    viewArchive: function(ticketId) {
        document.getElementById("ticket-details").innerHTML = "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>";
        ajaxGetTickets(ticketId, true);
    },
    closeArchive: function() {
        document.getElementById("ticket-details").innerHTML = "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>";
        ajaxGetTickets(null, false);
    }
};

var anomaliesPage = {
    showingArchive: false,
    manualRefresh: function() {
        dom.addClass(document.getElementById("anomaly-manual-refresh-spinner"), "fa-spin");
        if(lastSelectedAnomaly) {
            ajaxGetAnomalies(lastSelectedAnomaly, anomaliesPage.showingArchive);
        }
        else {
            ajaxGetAnomalies(null, anomaliesPage.showingArchive);
        }
    },
    viewArchive: function(anomalyId) {
        document.getElementById("anomaly-details").innerHTML = "<div class='no-results-message'>Seleziona una segnalazione per vederne qui i dettagli.</div>";
        ajaxGetAnomalies(anomalyId, true);
    },
    closeArchive: function() {
        document.getElementById("anomaly-details").innerHTML = "<div class='no-results-message'>Seleziona una segnalazione per vederne qui i dettagli.</div>";
        ajaxGetAnomalies(null, false);
    },
    ignoreAnomaly: function(anomalyId) {
        document.getElementById("anomaly-id-to-ignore").value = anomalyId;
        popup("ignore-anomaly-confirm", false, false, false);
    },
    approveAnomaly: function(anomalyId) {
        document.getElementById("anomaly-id-to-approve").value = anomalyId;
        popup("approve-anomaly-confirm", false, false, false);
    },
    deleteAnomaly: function(anomalyId) {
        document.getElementById("anomaly-id-to-delete").value = anomalyId;
        popup("delete-anomaly-confirm", false, false, false);
    },
    editAnomaly: function(anomalyId) {
        document.getElementById("anomaly-id-to-edit").value = anomalyId;
        document.getElementById("edit-anomaly-site").value = lastViewedAnomaly.siteId;
        document.getElementById("edit-anomaly-category").value = lastViewedAnomaly.categoryId;
        propagateCategoryChangeToSubcategory(lastViewedAnomaly.categoryId,"edit-anomaly-subcategory");
        document.getElementById("edit-anomaly-subcategory").value = lastViewedAnomaly.subcategoryId;
        document.getElementById("edit-anomaly-description").value = htmlDecode(lastViewedAnomaly.description);
        popup("edit-anomaly", false, false, false);
    }
};

var formParser = {
    byId: function(requestData,dataType,canBeNull,dataName,elementId){
        var element = document.getElementById(elementId);
        var value;
        if(element === null) {
            console.warn("Elemento con id " + elementId + " non presente nella pagina");
            if(!canBeNull) {
                return null;
            }
            value = null;
        }
        else {
            var rawValue=element.value;
            if(rawValue === "" || rawValue === null) {
                if(!canBeNull) {
                    return null;
                }
                value = null;
            }
            else {
                switch(dataType) {
                    case "int":
                        value = parseInt(rawValue);
                        break;
                    case "bool":
                        value = !!rawValue;
                        break;
                    default:
                        value = rawValue;
                }
            }
        }
        requestData[dataName] = value;
        return value;
    }
};

function ajaxEditAnomaly() {
    var requestData = {};
    var anomalyId=formParser.byId(requestData,"int",false,"anomalyId","anomaly-id-to-edit");
    formParser.byId(requestData,"int",false,"siteId","edit-anomaly-site");
    formParser.byId(requestData,"int",true,"categoryId","edit-anomaly-category");
    formParser.byId(requestData,"int",true,"subcategoryId","edit-anomaly-subcategory");
    formParser.byId(requestData,"string",false,"description","edit-anomaly-description");
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                closePopup();
                ajaxGetAnomalyDetails(anomalyId);
                successToast("Segnalazione modificata");
                anomaliesPage.manualRefresh();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editAnomaly.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxDeleteAnomaly() {
    var requestData = {};
    var anomalyId = formParser.byId(requestData,"int",false,"anomalyId","anomaly-id-to-delete");
    if(anomalyId !== null) {
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    successToast("Segnalazione eliminata");
                    anomaliesPage.manualRefresh();
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/deleteAnomaly.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestData));
    }
}

function ajaxApproveAnomaly() {
    var requestData = {};
    var anomalyId = formParser.byId(requestData,"int",false,"anomalyId","anomaly-id-to-approve");
    if(anomalyId !== null) {
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    closePopup();
                    ajaxGetAnomalyDetails(anomalyId);
                    successToast("Segnalazione approvata");
                    anomaliesPage.manualRefresh();
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/approveAnomaly.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestData));
    }
}

function ajaxIgnoreAnomaly() {
    var requestData = {};
    var anomalyId = formParser.byId(requestData,"int",false,"anomalyId","anomaly-id-to-ignore");
    if(anomalyId !== null) {
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    closePopup();
                    successToast("Segnalazione ignorata");
                    anomaliesPage.viewArchive(anomalyId);
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/ignoreAnomaly.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestData));
    }
}

//The number must be positive
function doubleZeroPadding(number) {
    if(number < 1) {
        return "000";
    }
    if(number < 10) {
        return "00" + number;
    }
    if(number < 100) {
        return "0" + number;
    }
    return number;
}

window.showOutOfCompetenceAnomalies=false;
window.showOutOfCompetenceTickets=false;
function renderTicketsList(tickets, selectedTicketId, showArchive) {
    var ticketsContainer = document.getElementById("tickets-list");
    var ticketDescriptions = [];
    var tempHtml = "";
    tickets.forEach(function(ticket) {
        var userIsExecutor=false;
        ticket.assignedCompanies.forEach(function(assignedCompany){
            if(assignedCompany.companyId==loggedInUserInfo.company){
                userIsExecutor=true;
            }
        });
        //TODO: permission to skip competence area
        if( window.showOutOfCompetenceTickets 
            ||userIsExecutor
            ||ticket.competentUsers.length==0 
            ||ticket.competentUsers[0]=="" 
            ||ticket.competentUsers.includes(loggedInUserInfo.userId.toString())
        ){
            if(ticket.deletion === null){
                if(showArchive) {
                    if(ticket.state === "Annullato" || ticket.state === "Validato") {
                        tempHtml += renderTicketEntry(ticket, lastSelectedTicket);
                    }
                }
                else {
                    if(ticket.state !== "Annullato" && ticket.state !== "Validato") {
                        tempHtml += renderTicketEntry(ticket, lastSelectedTicket);
                    }
                }
                ticketDescriptions.push(ticket.description);
            }
        }
    });
    if(tempHtml === "") {
        if(showArchive) {
            tempHtml = "<div class='no-results-message'>Nessun ticket è ancora stato archiviato.</div>";
        }
        else {
            tempHtml = "<div class='no-results-message'>Non è presente nessun ticket.</div>";
        }
    }
    ticketsContainer.innerHTML = tempHtml;
    scroll.refreshByName("tickets");

    if(selectedTicketId) {
        selectTicket(selectedTicketId, false);
    }
}

function ajaxGetTickets(selectedTicketId, showArchive) {
    if(showArchive) {
        setInnerHtml(document.querySelector("#page-ticketing .page-main-title"), window.showOutOfCompetenceTickets?"Archivio ticket (tutti)":"Archivio ticket");
        dom.removeClass(document.getElementById("action-button-close-tickets-archive"), "hidden");
        dom.removeClass(document.getElementById("tickets-archive-back-btn"), "hidden");
        dom.addClass(document.getElementById("action-button-view-tickets-archive"), "hidden");
        dom.addClass(document.getElementById("tickets-archive-btn"), "hidden");
        dom.addClass(document.getElementById("create-ticket-btn"), "hidden");
        dom.addClass(document.getElementById("refresh-tickets-btn"), "hidden");
        dom.addClass(document.getElementById("action-button-create-ticket"), "hidden");
        ticketingPage.showingArchive = true;
    }
    else {
        setInnerHtml(document.querySelector("#page-ticketing .page-main-title"), window.showOutOfCompetenceTickets?"Ticket (tutti)":"Ticket");
        dom.addClass(document.getElementById("action-button-close-tickets-archive"), "hidden");
        dom.addClass(document.getElementById("tickets-archive-back-btn"), "hidden");
        dom.removeClass(document.getElementById("action-button-view-tickets-archive"), "hidden");
        dom.removeClass(document.getElementById("tickets-archive-btn"), "hidden");
        dom.removeClass(document.getElementById("create-ticket-btn"), "hidden");
        dom.removeClass(document.getElementById("refresh-tickets-btn"), "hidden");
        dom.removeClass(document.getElementById("action-button-create-ticket"), "hidden");
        ticketingPage.showingArchive = false;
    }
    var ticketsContainer = document.getElementById("tickets-list");
    ticketsContainer.innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    scroll.refreshByName("tickets");
    var xhttp = new XMLHttpRequest();

    xhttp.timeout = 8000; // time in milliseconds
    var timeoutErrorAbort = function() {
        if(db.localDb) {
            db.getTickets(globalFilterByCompany,
                function(resultSet) {
                    //tickets is a global variable
                    tickets={};
                    var addedTicketIds = [];
                    var addedTicketAssignationIds = [];
                    for(var i = 0; i < resultSet.rows.length; i++) {
                        var row = resultSet.rows.item(i);
                        if(row.ticketId) {
                            if(addedTicketIds.indexOf(row.ticketId)<0) {
                                addedTicketIds.push(row.ticketId);
                                var ticket = {};
                                ticket.anomalyLevel = row.anomalyLevel;
                                ticket.assignedCompanies = {};
                                ticket.category = row.categoryId;
                                ticket.customerTicketId = row.customerTicketId;
                                ticket.description = row.description;
                                ticket.executionNotes = row.executionNotes;
                                ticket.letter = row.siteLetter;
                                ticket.reportTimestamp = row.reportTimestamp;
                                ticket.reportedBy = row.reportedByUsername;
                                ticket.reportedById = row.reportedBy;
                                ticket.siteId = row.siteId;
                                ticket.siteName = row.siteName;
                                ticket.slaHours = row.slaHours;
                                if(row.reportTimestamp !== null && row.slaHours !== null) {
                                    var reportTimestampDatetime = new Date(row.reportTimestamp);
                                    reportTimestampDatetime.setHours(reportTimestampDatetime.getHours() + row.slaHours);
                                    ticket.isoSlaExpiration = reportTimestampDatetime.toISOString();
                                }
                                else {
                                    ticket.isoSlaExpiration = null;
                                }
                                ticket.state = row.state;
                                ticket.subcategory = row.subcategoryId;
                                ticket.success = row.success;
                                ticket.ticketId = row.ticketId;
                                ticket.ticketType = row.ticketType;
                                ticket.oldTicketType = null;
                                ticket.deletion = null;
                                tickets[row.ticketId] = ticket;
                            }
                            if(row.ticketAssignationId) {
                                if(addedTicketAssignationIds.indexOf(row.ticketAssignationId)<0) {
                                    addedTicketAssignationIds.push(row.ticketAssignationId);
                                    var ticketAssignation = {};
                                    ticketAssignation.ticketAssignationId = row.ticketAssignationId;
                                    ticketAssignation.companyId = row.assignedCompanyId;
                                    ticketAssignation.companyName = row.companyName;
                                    tickets[row.ticketId].assignedCompanies[row.ticketAssignationId] = ticketAssignation;
                                }
                            }
                        }
                    }
                    var finalTickets = [];
                    for (var ticketId in tickets) {
                        if (tickets.hasOwnProperty(ticketId)) {
                            var finalAssignedCompanies = [];
                            for (var assignationId in tickets[ticketId].assignedCompanies) {
                                if (tickets[ticketId].assignedCompanies.hasOwnProperty(assignationId)) {
                                    finalAssignedCompanies.push(tickets[ticketId].assignedCompanies[assignationId]);
                                }
                            }
                            tickets[ticketId].assignedCompanies = finalAssignedCompanies;
                            finalTickets.push(tickets[ticketId]);
                        }
                    }
                    tickets = finalTickets;
                    console.log(tickets);
                    renderTicketsList(tickets, selectedTicketId, showArchive);
                }
            );
        }
        else {
            setTimeout(function() {
                    ajaxGetTickets(selectedTicketId, showArchive);
                },
                3000
            );
        }
    };
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            tickets = response.tickets;
            renderTicketsList(tickets, selectedTicketId, showArchive);
        }
        else {
            ticketsContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        setTimeout(function() {
                dom.removeClass(document.getElementById("ticket-manual-refresh-spinner"), "fa-spin");
            },
            1000
        );
    };
    xhttp.open("GET", BASE_URL + "/ws/getTickets.php?companyId=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetAnomalies(selectedAnomalyId, showArchive) {
    var anomaliesContainer = document.getElementById("anomalies-list");
    anomaliesContainer.innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    scroll.refreshByName("anomalies");
    if(showArchive) {
        setInnerHtml(document.querySelector("#page-anomalies .page-main-title"), window.showOutOfCompetenceAnomalies?"Archivio segnalazioni (tutte)":"Archivio segnalazioni");
        dom.removeClass(document.getElementById("action-button-close-anomalies-archive"), "hidden");
        dom.removeClass(document.getElementById("anomalies-archive-back-btn"), "hidden");
        dom.addClass(document.getElementById("action-button-view-anomalies-archive"), "hidden");
        dom.addClass(document.getElementById("anomalies-archive-btn"), "hidden");
        dom.addClass(document.getElementById("create-anomaly-btn"), "hidden");
        dom.addClass(document.getElementById("refresh-anomalies-btn"), "hidden");
        dom.addClass(document.getElementById("action-button-create-anomaly"), "hidden");
        anomaliesPage.showingArchive = true;
    }
    else {
        setInnerHtml(document.querySelector("#page-anomalies .page-main-title"), window.showOutOfCompetenceAnomalies?"Segnalazioni (tutte)":"Segnalazioni");
        dom.addClass(document.getElementById("action-button-close-anomalies-archive"), "hidden");
        dom.addClass(document.getElementById("anomalies-archive-back-btn"), "hidden");
        dom.removeClass(document.getElementById("action-button-view-anomalies-archive"), "hidden");
        dom.removeClass(document.getElementById("anomalies-archive-btn"), "hidden");
        dom.removeClass(document.getElementById("create-anomaly-btn"), "hidden");
        dom.removeClass(document.getElementById("refresh-anomalies-btn"), "hidden");
        dom.removeClass(document.getElementById("action-button-create-anomaly"), "hidden");
        anomaliesPage.showingArchive = false;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            anomalies = response.anomalies;
            var tempHtml = "";
            var anomaliesDescriptions = [];
            anomalies.forEach(function(anomaly) {
                if(window.showOutOfCompetenceAnomalies || anomaly.competentUsers.length==0 || anomaly.competentUsers[0]=="" || anomaly.competentUsers.includes(loggedInUserInfo.userId.toString())){
                    if(showArchive) {
                        if(anomaly.state === "Ignorato" || anomaly.state === "Aperto ticket") {
                            tempHtml += renderAnomalyEntry(anomaly, lastSelectedAnomaly);
                        }
                    }
                    else {
                        if(anomaly.state !== "Ignorato" && anomaly.state !== "Aperto ticket") {
                            tempHtml += renderAnomalyEntry(anomaly, lastSelectedAnomaly);
                        }
                    }
                    anomaliesDescriptions.push(anomaly.description);
                }
            });

            if(tempHtml === "") {
                if(showArchive) {
                    tempHtml = "<div class='no-results-message'>Nessuna segnalazione è ancora stata archiviata.</div>";
                }
                else {
                    tempHtml = "<div class='message success'>Non è presente nessuna nuova segnalazione.</div>";
                }
            }
            anomaliesContainer.innerHTML = tempHtml;
            if(selectedAnomalyId) {
                selectAnomaly(selectedAnomalyId,false);
            }
        }
        else {
            anomaliesContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        scroll.refreshByName("anomalies");
        dom.removeClass(document.getElementById("anomaly-manual-refresh-spinner"), "fa-spin");
    };
    xhttp.open("GET", BASE_URL + "/ws/getAnomalies.php?companyId=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function selectLaw(i) {
    setInnerHtml(
        document.querySelector("#view-law-details-popup .popup-content div"),
        "<div class='hth'><div class='tr'><div>Numero normativa</div><div>" + laws[i].lawNumber + "</div></div><div class='tr'><div>Descrizione</div><div>" +
        (laws[i].lawDescription == null ? "-" : laws[i].lawDescription) + "</div></div><div class='tr'><div>Entrata in vigore (nel sistema)</div><div>" +
        (laws[i].entryInForceDate == null ? "-" : laws[i].entryInForceDate) + "</div></div><div class='tr'><div>Abrogazione</div><div>" +
        (laws[i].repealDate == null ? "-" : laws[i].repealDate) + "</div></div></div><br>",
        function(){scroll.refreshPopupById("view-law-details", false);}
    );
    popup("view-law-details", false, false, false);
}

function ajaxGetLaws(selectedLaw) {
    var lawsContainer = document.querySelector("#view-laws-popup .popup-content div");
    lawsContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    scroll.refreshPopupById("view-laws", false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            laws = response.laws;
            var tempOptions = "";
            lawsContainer.innerHTML = "";
            if(response.laws.length > 0) {
                for(var i = 0; i < response.laws.length; i++) {
                    var entry = document.createElement("div");
                    dom.addClass(entry, "entry");
                    dom.addClass(entry, "entry-with-top-row");
                    dom.addClass(entry, "entry-hoverable");
                    entry.setAttribute("data-law-index",i);
                    entry.innerHTML="<div class='entry-top-row'>" +
                        response.laws[i].lawNumber + "</div>" +
                        "<div>" +
                        "<div>In vigore nel sistema dal giorno " + response.laws[i].entryInForceDate + "</div>" +
                        (response.laws[i].repealDate == null ? "" : "<div>Rimossa dal sistema il giorno "+response.laws[i].repealDate+"</div>") +
                        "</div>";
                    entry.addEventListener("click",function(event){
                        var lawIndex = event.currentTarget.getAttribute("data-law-index");
                        if(lawIndex != null){
                            lawIndex = parseInt(lawIndex);
                            selectLaw(lawIndex);
                        }
                    });
                    lawsContainer.appendChild(entry);
                    tempOptions += "<option value='" + response.laws[i].lawId + "'>" + response.laws[i].lawNumber + "</option>";
                }
                lawsContainer.appendChild(document.createElement("br"));
                scroll.refreshPopupById("view-laws", false);
            }
            else {
                setInnerHtml(
                    lawsContainer,
                    "<div class='no-results-message'>Nessun risultato</div>",
                    function(){scroll.refreshPopupById("view-laws", false);}
                );
            }
            setInnerHtml(
                document.getElementById("edit-activity-for-subc-law"),
                tempOptions,
                function(){
                    document.getElementById("edit-activity-for-subc-law").value = selectedLaw;
                }
            );
            setInnerHtml(
                document.getElementById("add-activity-for-subc-law"),
                tempOptions
            );
        }
        else {
            setInnerHtml(
                lawsContainer,
                "<div class='message error'> Errore: " + response.message + "</div>",
                function(){scroll.refreshPopupById("view-laws", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getLaws.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function calculatePagesForEventsLog() {
    var eventsNumber = logEvents.length;
    var eventLogsPerPage = parseInt(document.getElementById("show-event-logs-per-page").value);
    var pagesNumber = Math.ceil(parseFloat(eventsNumber) / parseFloat(eventLogsPerPage));
    var tempHtml = "";
    for(var i = 1; i <= pagesNumber; i++) {
        tempHtml += "<option value='" + i + "' " + ((i === 1) ? "selected='selected'" : "") + ">" + i + "</option>";
    }
    setInnerHtml(document.getElementById("view-event-logs-page"), tempHtml);
    setInnerHtml(document.getElementById("total-event-logs-page"), pagesNumber);
}

function renderEventsLog() {
    var popupContent = document.getElementById("event-logs-container");
    var tempHtml = "";
    if(logEvents.length > 0) {
        var eventLogsPage = parseInt(document.getElementById("view-event-logs-page").value);
        var eventLogsPerPage = parseInt(document.getElementById("show-event-logs-per-page").value);
        var startFrom = (eventLogsPage - 1) * (eventLogsPerPage);
        var endTo = startFrom + eventLogsPerPage;
        if(endTo > logEvents.length) {
            endTo = logEvents.length;
        }
        for(var i = startFrom; i < endTo; i++) {
            tempHtml += "<div " +
                " class='entry"+((logEvents[i].level === "warning") ? " log-level-warning" : "")+"' " +
                " id='event-" + logEvents[i].eventId + "'" +
                ">" +
                "<div class='log-event-timestamp'>" +
                iso8601.toEuropeanDateTimeWithSeconds(logEvents[i].isoTimestamp) +
                "</div>" +
                "<div class='log-event-ip-address'>";
            if(logEvents[i].ipAddress!=null){
                tempHtml += "<a " +
                    " href='http://ip-api.com/#" + logEvents[i].ipAddress + "' " +
                    " target='_blank' " +
                    " rel='noreferrer'>" +
                    logEvents[i].ipAddress +
                    "</a>";
            }
            tempHtml+=
                "</div>" +
                "<div class='log-event-description'>" +
                logEvents[i].description +
                "</div>" +
                "</div>";
        }
    }
    else {
        tempHtml = "<div class='no-results-message'>Nessun risultato</div>";
    }
    setInnerHtml(
        popupContent,
        tempHtml,
        function(){ scroll.refreshPopupById("view-events-log", false); }
    );
}

function ajaxGetEventsLog() {
    var popupContent = document.getElementById("event-logs-container");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            logEvents = response.events;
            var resultsCountText;
            if(response.events.length === 0) {
                resultsCountText = "Nessun risultato";
            }
            else {
                resultsCountText = response.events.length + " risultati";
            }
            setInnerHtml(document.querySelector("#view-events-log-popup .results-count"), resultsCountText);
            calculatePagesForEventsLog();
            renderEventsLog(logEvents);
        }
        else {
            setInnerHtml(popupContent, "<div class='message error'> Errore: " + response.message + "</div>");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getLogEvents.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetInterventionEvents() {
    var popupContent = document.querySelector("#view-intervention-log-popup .popup-content div");
    setInnerHtml(
        popupContent,
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){scroll.refreshPopupById("view-intervention-log", false);}
    );
    popup("view-intervention-log", false, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        var tempHtml="";
        if(xhttp.status === 200) {
            if(response.events.length > 0) {
                tempHtml = "<div class='vth3'><div class='tr'><div>Data e ora</div><div>Descrizione</div><div>Utente</div></div>";
                for(var i = 0; i < response.events.length; i++) {
                    tempHtml += "<div class='tr'><div>" + iso8601.toEuropeanDateTimeWithSeconds(response.events[i].isoTimestamp) + "</div>"+
                        "<div>" + ui.replaceTokens(response.events[i].description) + "</div><div>" + response.events[i].username + "</div></div>";
                }
                tempHtml += "</div>";
            }
            else {
                tempHtml = "<div class='no-results-message'>Nessun evento disponibile</div>";
            }
        }
        else {
            tempHtml = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        setInnerHtml(
            popupContent,
            tempHtml+"<br>",
            function(){scroll.refreshPopupById("view-intervention-log", false);}
        );
    };
    xhttp.open("GET", BASE_URL + "/ws/getInterventionEvents.php?interventionId=" + lastOpenedIntervention.interventionId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxGetInterventionGeolocationState(interventionId,outputElement) {
    /*setInnerHtml(outputElement,"<strong>Stato verifica GPS:</strong> <i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...");*/
    setInnerHtml(outputElement,"");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            //TODO: RE-ENABLE GPS VERIFICATION
            /*
            if(response.geolocationOk){
                setInnerHtml(outputElement,"<strong>Stato verifica GPS:</strong> <span class='green-state'>Verificato</span>");
            }
            else{
                setInnerHtml(outputElement,"<strong>Stato verifica GPS:</strong> <span class='red-state'>Non verificato</span>");
            }*/
            setInnerHtml(outputElement,"");
        }
        else {
            setInnerHtml(outputElement,"<strong>Stato verifica GPS:</strong> <span class='red-state'>Errore - " + response.error + "</span>");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInterventionGeolocationState.php?interventionId=" + interventionId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function toggleAddActivityForSubcLawRequirementsOptions() {
    var checkbox=document.getElementById("add-activity-for-subc-is-statutory-requirement");
    if(checkbox.getAttribute("data-checked")!=="1") {
        checkbox.setAttribute("data-checked","1");
        dom.removeClass(document.getElementById("add-activity-for-subc-law-requirements"), "hidden");
        document.getElementById("add-activity-for-subc-law").required = "required";
        document.getElementById("add-activity-for-subc-frequency").required = "required";
        document.getElementById("add-activity-for-subc-frequency-type").required = "required";
    }
    else {
        checkbox.setAttribute("data-checked","0");
        dom.addClass(document.getElementById("add-activity-for-subc-law-requirements"), "hidden");
        document.getElementById("add-activity-for-subc-law").removeAttribute("required");
        document.getElementById("add-activity-for-subc-frequency").removeAttribute("required");
        document.getElementById("add-activity-for-subc-frequency-type").removeAttribute("required");
    }
    scroll.refreshPopupById("add-activity-for-subcategory", false);
}

function toggleEditActivityForSubcLawRequirementsOptions() {
    var checkbox = document.getElementById("edit-activity-for-subc-is-statutory-requirement");
    if(checkbox.getAttribute("data-checked")!=="1") {
        checkbox.setAttribute("data-checked","1");
        dom.removeClass(document.getElementById("edit-activity-for-subc-law-requirements"), "hidden");
        document.getElementById("edit-activity-for-subc-law").required = "required";
        document.getElementById("edit-activity-for-subc-frequency").required = "required";
        document.getElementById("edit-activity-for-subc-frequency-type").required = "required";
    }
    else {
        checkbox.setAttribute("data-checked","0");
        dom.addClass(document.getElementById("edit-activity-for-subc-law-requirements"), "hidden");
        document.getElementById("edit-activity-for-subc-law").removeAttribute("required");
        document.getElementById("edit-activity-for-subc-frequency").removeAttribute("required");
        document.getElementById("edit-activity-for-subc-frequency-type").removeAttribute("required");
    }
}

var lastSelectedTicketId;
function selectTicket(ticketId,forcePageChange) {
    if(isSmallViewport()) {
        lastSelectedTicket = null;
    }
    else {
        lastSelectedTicket = ticketId;
    }
    lastSelectedTicketId = ticketId; //used to load mail delivery status on mobile devices
    if(currentPage !== "page-ticketing" && forcePageChange) {
        changePage("page-ticketing", false, true);
    }
    if(document.getElementById("tickets-list")) {
        var rows = document.querySelectorAll("#tickets-list .entry");
        for(var i = 0; i < rows.length; i++) {
            if(rows[i].getAttribute("data-ticket-id") == ticketId) {
                dom.addClass(rows[i], "selected");
            }
            else {
                dom.removeClass(rows[i], "selected");
            }
        }
    }
    ajaxGetTicketDetails(ticketId);
}

function selectAnomaly(anomalyId, forcePageChange) {
    if(isSmallViewport()) {
        lastSelectedAnomaly = null;
    }
    else {
        lastSelectedAnomaly = anomalyId;
    }
    if(currentPage !== "page-anomalies" && forcePageChange) {
        changePage("page-anomalies", false, true);
    }
    if(document.getElementById("anomalies-list")) {
        var rows = document.querySelectorAll("#anomalies-list .entry");
        for(var i = 0; i < rows.length; i++) {
            if(rows[i].getAttribute("data-anomaly-id") == anomalyId) {
                dom.addClass(rows[i], "selected");
            }
            else {
                dom.removeClass(rows[i], "selected");
            }
        }
    }
    ajaxGetAnomalyDetails(anomalyId);
}

function refreshTicketDetailsScroll() {
    scroll.refreshPopupById("ticket-info", false);
    scroll.refreshByName("ticket-details");
}

/**
 * @param {Object} ticketObject
 * @property {Object} ticketObject.ticket
 * @property {number} ticketObject.ticket.customerTicketId
 * @property {?number} ticketObject.ticket.relatedInterventionId
 * @property {String} ticketObject.ticket.reportedBy
 * @property {String} ticketObject.ticket.categoryName
 * @property {String} ticketObject.ticket.isoSlaExpiration
 * @property {String} ticketObject.ticket.isoReportTimestamp
 * @property {Array} ticketObject.ticket.relatedPictures
 * @property {String} ticketObject.ticket.relatedInterventionPlannedStartDate
 * @property {Array} ticketObject.ticketLog
 * @property {String} ticketObject.ticketLog.stateFrom
 * @property {String} ticketObject.ticketLog.stateTo
 * @property {String} ticketObject.ticketLog.editedBy
 */
function renderTicketDetails(ticketObject){
    var ticket = ticketObject.ticket;
    var ticketLog = ticketObject.ticketLog;
    if(ticket.ticketId) {
        var i;
        var logContent = document.querySelector("#view-ticket-log-popup .popup-content div");
        if((!ticketLog) || ticketLog.length === 0) {
            setInnerHtml(
                logContent,
                "<div><div class='no-results-message'>Nessun evento da mostrare</div></div>",
                function(){scroll.refreshPopupById("view-ticket-log", false);}
            );
        }
        else {
            var tempHtml ="";
            for(i = 0; i < ticketLog.length; i++) {
                tempHtml += "<div class='entry entry-with-top-row'>" +
                    "<div class='entry-top-row'>"+
                    ((ticketLog[i].stateFrom == null) ? "Nessuno" : ticketLog[i].stateFrom) +
                    " &gt; " +
                    ticketLog[i].stateTo +
                    "</div>" +
                    "<div>"+
                    "Data e ora: " + iso8601.toEuropeanDateTimeWithSeconds(ticketLog[i].isoDateTime) + "<br>" +
                    "Operatore: " + ((ticketLog[i].editedBy === null) ? "-" : ticketLog[i].editedBy) +
                    "</div>" +
                    "</div>";
            }
            setInnerHtml(
                logContent,
                tempHtml,
                function(){scroll.refreshPopupById("view-ticket-log", false);}
            );
        }
        document.getElementById("log-ticket-number").innerHTML = ticket.customerTicketId.toString();
        tempHtml = "<div class='hth'>";
        tempHtml += "<div class='tr'><div>N° ticket</div><div>" + doubleZeroPadding(ticket.customerTicketId) + "</div></div>";
        tempHtml += "<div class='tr'><div>Sito</div><div>" + ticket.siteName + "</div></div>";
        if (ticket.costCenterName !== null) {
            tempHtml += "<div class='tr'><div>Centro di costo</div><div>" + ticket.costCenterName + "</div></div>";
        }
        tempHtml += "<div class='tr'><div>Tipologia intervento</div><div>" +
            ((ticket.oldTicketType === null) ? "" : (ticket.oldTicketType + "<br><i class='fa fa-reply fa-rotate-180' aria-hidden='true'></i>  ")) +
            ((ticket.ticketType === null) ? "<span class='no-value-text'>Nessuna</span>" : ticket.ticketType) +
            "</div></div>";
        tempHtml += "<div class='tr'><div>Categoria</div><div>" + ticket.categoryName + "</div></div>";
        tempHtml += "<div class='tr'><div>Sottocategoria</div><div>" + ((ticket.subcategoryName === null) ? "<span class='no-value-text'>Nessuna</span>" : ticket.subcategoryName) + "</div></div>";
        tempHtml += "<div class='tr'><div>Stato</div><div " + ((ticket.state === "Chiuso") ? "class='green-state'" : "") + ((ticket.state === "Annullato") ? "class='red-state'" : "") + ">" + ticket.state + "</div></div>";
        tempHtml += "<div class='tr'><div>Grado di urgenza - SLA</div><div>" + ticket.anomalyLevel + "</div></div>";
        if(ticket.isoSlaExpiration !== null){
            var expiration = iso8601.toEuropeanDateTimeWithSeconds(ticket.isoSlaExpiration);
            tempHtml += "<div class='tr'><div>Termine SLA</div><div>" + expiration + "</div></div>";
            setInnerHtml(document.getElementById("assign-ticket-sla-container"),("<strong>Termine SLA: </strong>" + expiration));
        }
        else {
            setInnerHtml(document.getElementById("assign-ticket-sla-container"),("<strong>Termine SLA: </strong>Non presente"));
        }
        tempHtml += "<div class='tr'><div>Apertura</div><div>" + iso8601.toEuropeanDateTimeWithSeconds(ticket.isoReportTimestamp) + "</div></div>";
        tempHtml += "<div class='tr'><div>Aperto da</div><div>" + ticket.reportedBy + "</div></div>";
        if(ticket.competenceAreaId!=null){
            tempHtml += "<div class='tr'><div>Area di competenza</div><div>" + ticket.competenceAreaName;
            if(permissions["CHANGE_COMPETENCE_AREA"]==="ACCESSIBLE") {
                tempHtml+="<br><button type='button' onclick=\"changeCompetenceArea('Ticket','"+ticket.competenceAreaName+"',"+ticket.competenceAreaId+","+ticket.siteId+","+ticket.ticketId+");\">";
                tempHtml+="<i class='fa fa-pencil' aria-hidden='true'></i> Riassegna";
                tempHtml+="</button>";
            }
            tempHtml += "</div></div>";
        }
        if(permissions["VIEW_INTERVENTION_EXECUTOR"]==="ACCESSIBLE") {
            tempHtml += "<div class='tr'><div>Assegnato a</div><div>";
            if(ticket.assignedCompanies.length > 1) {
                tempHtml += "<ul>";
            }
            for(i = 0; i < ticket.assignedCompanies.length; i++) {
                if(ticket.assignedCompanies.length > 1) {
                    tempHtml += "<li>" + ticket.assignedCompanies[i].companyName + "</li>";
                }
                else {
                    if(i > 0) {
                        tempHtml += "<br>";
                    }
                    tempHtml += ticket.assignedCompanies[i].companyName;
                }
            }
            if(ticket.assignedCompanies.length > 1) {
                tempHtml += "</ul>";
            }
            else {
                if(i === 0) {
                    tempHtml += "<span class='no-value-text'>Nessuno</span>";
                }
            }
            tempHtml += "</div></div>";
        }
        else {
            if(ticket.assignedCompanies.length < 1) {
                tempHtml += "<div class='tr'><div>Assegnato</div><div>No</div></div>";
            }
            else {
                tempHtml += "<div class='tr'><div>Assegnato</div><div>Si</div></div>";
            }
        }
        tempHtml += "<div class='tr'><div>Descrizione</div><div>" + ticket.description + "</div></div>";
        tempHtml += "<div class='tr'><div>Asset collegati</div><div>";
        if(ticket.relatedAssets.length===0){
            tempHtml += "<span class='no-value-text'>Nessuno</span>";
        }
        else{
            ticket.relatedAssets.forEach(function(relatedAsset){
                tempHtml+="<button type='button' onclick='closePopup();selectAssetInstance("+relatedAsset.assetId+");'>"+(relatedAsset.qrCode?relatedAsset.qrCode:("#"+relatedAsset.assetId))+"</button>";
            });
        }
        tempHtml += "</div></div></div>";

        tempHtml += "<br><button type='button' onclick='popup( \"view-ticket-log\" ,false,undefined,false);'><i class='fa fa-ticket' aria-hidden='true'></i> Visualizza eventi ticket</button>";
        switch(permissions["VIEW_TICKET_MAIL_LOG"]) {
            case "ACCESSIBLE":
                tempHtml += " <button type='button' onclick='popup( \"view-ticket-mail-log\" ,false,undefined,false);'><i class='fa fa-envelope' aria-hidden='true'></i> Stato notifiche mail</button>";
                break;
            case "DISABLED":
                tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-envelope' aria-hidden='true'></i> Stato notifiche mail</button>";
                break;
        }
        tempHtml += "<br><span class='important-label' style='margin-left:0;'>Richieste di intervento</span><br>";
        if(ticket.relatedInterventions.length===0){
            tempHtml += "<span class='no-value-text'>Nessuna</span>";
        }
        ticket.relatedInterventions.forEach(function(relatedIntervention) {
            var interventionDate=iso8601.toEuropeanDate(relatedIntervention.relatedInterventionPlannedStartDate,null);
            var relatedInterventionDisabled = relatedIntervention.isDisabled;
            var relatedInterventionDeleted = (relatedIntervention.interventionDeletion!==null||relatedIntervention.interventionActivityDeletion!==null);
            var relatedInterventionStateHtml="";
            if(!ticket.ticketType.toUpperCase().includes("PREVENTIVO")) {
                if (relatedInterventionDisabled) {
                    relatedInterventionStateHtml = "<span class='related-intervention-state entry-disabled-label'>Disabilitato</span>";
                }
                else {
                    if (relatedIntervention.interventionValidation !== null) {
                        relatedInterventionStateHtml = "<span class='related-intervention-state state-green'>Validato</span>";
                    }
                    else {
                        switch (relatedIntervention.relatedInterventionState) {
                            case 1:
                                relatedInterventionStateHtml = "<span class='related-intervention-state state-green'>Completato</span>";
                                break;
                            case 2:
                                relatedInterventionStateHtml = "<span class='related-intervention-state state-orange'>Devo completarlo</span>";
                                break;
                            case 3:
                                relatedInterventionStateHtml = "<span class='related-intervention-state state-orange'>Serve intervento di terzi</span>";
                                break;
                            default:
                            case 4:
                                if (relatedIntervention.relatedInterventionStartDate === null) {
                                    relatedInterventionStateHtml = "<span class='related-intervention-state state-red'>Non ancora eseguito</span>";
                                }
                                else {
                                    relatedInterventionStateHtml = "<span class='related-intervention-state state-orange'>In lavorazione</span>";
                                }
                                break;
                        }
                    }
                }
            }

            tempHtml += "<div class='ticket-related-intervention"+(relatedInterventionDeleted?" related-intervention-deleted":"")+(relatedInterventionDisabled?" related-intervention-disabled":"")+"'><span class='related-intervention-header'>N° "+ relatedIntervention.interventionId + " - "+ relatedIntervention.interventionExecutorName + " - Previsto in data " + interventionDate +"</span><br>"+
                relatedInterventionStateHtml+
                (relatedIntervention.interventionInstructions?(relatedIntervention.interventionInstructions+"<br>"):"");
            if(!relatedInterventionDeleted) {
                if(!ticket.ticketType.toUpperCase().includes("PREVENTIVO")){
                    tempHtml += "<button type='button' onclick='selectIntervention(" + relatedIntervention.interventionId + ",null," + ticket.ticketId + ");'>Visualizza</button>";
                }
                var INTERVENTION_NOT_YET_EXECUTED = 4;
                var INTERVENTION_PAUSED = 5;

                if(permissions["EXECUTE_INTERVENTION"] === "ACCESSIBLE") {
                    if(ticket.state === "Assegnato" || ticket.state === "In lavorazione") {
                        //TODO: not using hard coded intervention state, ticket with type "preventivo" cannot be directly executed via intervention
                        if((!ticket.ticketType.toUpperCase().includes("PREVENTIVO"))
                            && (
                                relatedIntervention.relatedInterventionState === INTERVENTION_NOT_YET_EXECUTED
                                || relatedIntervention.relatedInterventionState === INTERVENTION_PAUSED
                                || relatedIntervention.relatedInterventionState === null
                            )
                        ) {
                            if(relatedIntervention.certificationInvalid) {
                                tempHtml += "<button type='button' class='bg-green text-white' onclick='errorToast(\""+relatedIntervention.certificationInvalidMessage+"\");'><i class='fa fa-play' aria-hidden='true'></i> Esegui </button>";
                            }
                            else {
                                if(relatedInterventionDisabled) {
                                    tempHtml += "<button type='button' disabled='disabled'><i class='fa fa-play' aria-hidden='true'></i> Esegui </button>";
                                }
                                else{
                                    if(
                                        permissions["VIEW_INTERVENTIONS"] === "ACCESSIBLE"
                                        || (
                                            relatedIntervention.interventionExecutorId == loggedInUserInfo.company
                                            &&
                                            permissions["VIEW_OWN_INTERVENTION"] === "ACCESSIBLE"
                                        )
                                    ) {
                                        tempHtml += "<button type='button' class='bg-green text-white' onclick='selectIntervention(" + relatedIntervention.interventionId + ",startIntervention," + ticket.ticketId + ");'><i class='fa fa-play' aria-hidden='true'></i> Esegui </button>";
                                    }
                                }
                            }
                        }
                    }
                    if(
                        (relatedIntervention.relatedInterventionState === INTERVENTION_NOT_YET_EXECUTED
                            || relatedIntervention.relatedInterventionState === null
                        )
                        &&
                        relatedIntervention.interventionExecutorId == loggedInUserInfo.company
                        &&
                        ticket.state === "Assegnato"
                    ) {
                        if(relatedInterventionDisabled) {
                            tempHtml += "<button type='button' disabled='disabled'><i class='fa fa-trash' aria-hidden='true'></i> Rifiuta </button>";
                        }
                        else {
                            tempHtml += "<button type='button' class='bg-red text-white' onclick='refuseIntervention(" + relatedIntervention.interventionId + "," + ticket.ticketId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Rifiuta </button>";
                        }
                    }
                }
                if(permissions["CHANGE_TICKET_STATE"]==="ACCESSIBLE"
                    &&
                    (ticket.state === "Assegnato" || ticket.state === "In lavorazione")
                    &&
                    (relatedIntervention.relatedInterventionState === INTERVENTION_NOT_YET_EXECUTED
                        || relatedIntervention.relatedInterventionState === null
                    )
                ) {
                    tempHtml += "<button type='button' onclick='cancelInterventionRequest(" + relatedIntervention.interventionId + "," + ticket.ticketId + ");'><i class='fa fa-times' aria-hidden='true'></i> Annulla richiesta </button>";
                }
            }

            if(relatedIntervention.interventionExecutorId == loggedInUserInfo.company || permissions["CHAT_WITH_INTERVENTION_EXECUTORS"]==="ACCESSIBLE"){
                var ok = ticket.ticketType.toUpperCase().includes("PREVENTIVO");
                if(ok){
                    tempHtml += "<button class='bg-green text-white' type='button' onclick='chatPopup.initialize("+(relatedIntervention.interventionExecutorId == loggedInUserInfo.company)+"," + relatedIntervention.interventionId + "," + ticket.ticketId + "," + relatedIntervention.interventionExecutorId + ");'><i class='fa fa-comments' aria-hidden='true'></i> Gestione preventivo </button>";
                }
                if(!ok) {
                    if(ticket.oldTicketType!==null) {
                        ok = ticket.oldTicketType.toUpperCase().includes("PREVENTIVO");
                    }
                    if(ok){
                        tempHtml += "<button type='button' onclick='chatPopup.initialize("+(relatedIntervention.interventionExecutorId == loggedInUserInfo.company)+"," + relatedIntervention.interventionId + "," + ticket.ticketId + "," + relatedIntervention.interventionExecutorId + ");'><i class='fa fa-comments' aria-hidden='true'></i> Gestione preventivo </button>";
                    }
                    else {
                        tempHtml += "<button type='button' onclick='chatPopup.initialize("+(relatedIntervention.interventionExecutorId == loggedInUserInfo.company)+"," + relatedIntervention.interventionId + "," + ticket.ticketId + "," + relatedIntervention.interventionExecutorId + ");'><i class='fa fa-comments' aria-hidden='true'></i> Conversazione </button>";
                    }
                }
            }
            tempHtml+="</div>";
        }); //End of ticket related interventions listing
        //Showing, if available, add intervention request button
        if(ticket.state !== "Validato" && ticket.state !== "Annullato") {
            tempHtml += "<br>";
            if(ticket.actions.length > 1 || (ticket.actions.length === 1 && ticket.actions[0].actionName != null)) {
                switch(permissions["CHANGE_TICKET_STATE"]) {
                    case "ACCESSIBLE":
                        for(i = 0; i < ticket.actions.length; i++) {
                            if (ticket.actions[i].actionName == "Aggiungi richiesta di intervento") {
                                tempHtml += "<button class='add-intervention-request-button' type='button' onclick=\"changeTicketState(" + ticket.ticketId + ",'" + ticket.actions[i].toState + "','" + ticket.actions[i].actionName + "',null);\">" + ticket.actions[i].actionIcon + " " + ticket.actions[i].actionName + "</button>  ";
                            }
                        }
                        break;
                    case "DISABLED":
                        for(i = 0; i < ticket.actions.length; i++) {
                            if(ticket.actions[i].actionName=="Aggiungi richiesta di intervento") {
                                tempHtml += "<button class='add-intervention-request-button' type='button' disabled='disabled'>" + ticket.actions[i].actionIcon + " " + ticket.actions[i].actionName + "</button>  ";
                            }
                        }
                        break;
                }
            }
        }
        if(ticket.relatedPictures.length > 0) {
            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Foto</span><br>";
            for(i = 0; i < ticket.relatedPictures.length; i++) {
                tempHtml += "<img " +
                    "class='thumbnail-image' " +
                    "onload='refreshTicketDetailsScroll();' " +
                    "onclick='imageTheater(\"" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + ticket.relatedPictures[i] + "&authToken=" + currentAuthToken + "\");' " +
                    "src='" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + ticket.relatedPictures[i] + "&height=160&authToken=" + currentAuthToken + "'> ";
            }
        }
        if(ticket.state !== "Validato" && ticket.state !== "Annullato") {
            tempHtml += "<br>";
            if(ticket.actions.length > 1 || (ticket.actions.length === 1 && ticket.actions[0].actionName != null)) {
                switch(permissions["CHANGE_TICKET_STATE"]) {
                    case "ACCESSIBLE":
                        tempHtml += "<br><span class='important-label' style='margin-left:0;'>Modifica ticket</span><br>";
                        for(i = 0; i < ticket.actions.length; i++) {
                            if (ticket.actions[i].actionName != "Aggiungi richiesta di intervento") {
                                tempHtml += "<button type='button' onclick=\"changeTicketState(" + ticket.ticketId + ",'" + ticket.actions[i].toState + "','" + ticket.actions[i].actionName + "',null);\">" + ticket.actions[i].actionIcon + " " + ticket.actions[i].actionName + "</button>  ";
                            }
                        }
                        break;
                    case "DISABLED":
                        tempHtml += "<br><span class='important-label' style='margin-left:0;'>Modifica ticket</span><br>";
                        for(i = 0; i < ticket.actions.length; i++) {
                            if (ticket.actions[i].actionName != "Aggiungi richiesta di intervento") {
                                tempHtml += "<button type='button' disabled='disabled'>" + ticket.actions[i].actionIcon + " " + ticket.actions[i].actionName + "</button>  ";
                            }
                        }
                        break;
                }
            }
        }
        tempHtml += "<div id='ticket-editing-status-container'></div>";
    }
    else {
        tempHtml = "<div class='message error'>Il ticket richiesto non esiste o non si dispongono i permessi per poterlo visualizzare </div>";
    }
    if(isSmallViewport()) {
        setInnerHtml(
            document.getElementById("popup-ticket-info-container"),
            tempHtml,
            function(){scroll.refreshPopupById("ticket-info", false);}
        );
        popup("ticket-info", false, false, false);
    }
    else {
        setInnerHtml(
            document.getElementById("ticket-details"),
            tempHtml,
            function(){
                scroll.refreshByName("ticket-details");
            }
        );
    }
}

function cancelInterventionRequest(interventionId,ticketId) {
    document.getElementById("confirm-cancel-intervention-intervention-id").value=interventionId;
    document.getElementById("confirm-cancel-intervention-ticket-id").value=ticketId;
    document.getElementById("cancel-intervention-request-notes").value="";
    popup("confirm-cancel-intervention", false, false, false);
}

function refuseIntervention(interventionId,ticketId) {
    document.getElementById("confirm-refuse-intervention-intervention-id").value=interventionId;
    document.getElementById("confirm-refuse-intervention-ticket-id").value=ticketId;
    popup("confirm-refuse-intervention", false, false, false);
}

function ajaxRefuseInterventionRequest(interventionId,ticketId) {
    var requestData={};
    requestData.interventionId = interventionId;
    requestData.ticketId = ticketId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Richiesta di intervento rifiutata");
            ajaxGetTicketDetails(lastSelectedTicketId);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/refuseInterventionRequest.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxDisableActivityForSubcategory(activityId,fromDate){
    var requestData={};
    requestData.toDisableActivityId=activityId;
    requestData.disableFromDate=fromDate;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Attività disabilitata");
            ajaxViewActivitiesForSubcategory();
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/disableActivityForSubcategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxCancelInterventionRequest(interventionId,ticketId,notes){
    var requestData={};
    requestData.interventionId = interventionId;
    requestData.ticketId = ticketId;
    requestData.notes = notes;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Richiesta di intervento annullata");
            ajaxGetTicketDetails(lastSelectedTicketId);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/cancelInterventionRequest.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxGetTicketDetails(ticketId) {
    var detailsContainer;
    if(isSmallViewport()) {
        detailsContainer = document.getElementById("popup-ticket-info-container");
    }
    else {
        detailsContainer = document.getElementById("ticket-details");
    }
    detailsContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            renderTicketDetails(response);
        }
        else {
            detailsContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
            scroll.refreshByName("ticket-details");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getTicket.php?ticketId=" + ticketId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function refreshImageTheaterScroll(){
    scroll.refreshPopupById("image-theater", false);
}

function imageTheater(imageUrl) {
    if ("PhotoViewer" in window) {
        var options = {
            share: true, // default is false
            closeButton: true, // default is true
            copyToReference: true, // default is false
            headers: '',  // If this is not provided, an exception will be triggered
            piccasoOptions: { } // If this is not provided, an exception will be triggered
        };
        PhotoViewer.show(imageUrl,'',options);        
    }
    else {        
        setInnerHtml(
            document.querySelector("#image-theater-popup .popup-content div"),
            "<img " +
            " src='" + imageUrl + "'" +
            " onload='refreshImageTheaterScroll();'" +
            ">",
            refreshImageTheaterScroll
        );
        popup("image-theater", false, false, false);
    }
}

function refreshAnomalyDetailsScroll(){
    scroll.refreshPopupById("anomaly-info", false);
    scroll.refreshByName("anomaly-details");
}

function ajaxGetAnomalyDetails(anomalyId) {
    var smallViewport=isSmallViewport();
    if(smallViewport) {
        setInnerHtml(
            document.getElementById("popup-anomaly-info-container"),
            "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
            function(){scroll.refreshPopupById("anomaly-info", false);}
        );
        popup("anomaly-info", false, false, false);
    }
    else {
        setInnerHtml(
            document.getElementById("anomaly-details"),
            "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
            function(){scroll.refreshByName("anomaly-details");}
        );
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        /**
         * @type {Object}
         * @property {Object} anomaly
         * @property {number} anomaly.customerAnomalyId
         * @property {?number} anomaly.relatedTicketId
         * @property {String} anomaly.reportTimestamp
         */
        var response = JSON.parse(xhttp.responseText);
        var tempHtml;
        if(xhttp.status === 200) {
            if(!objectIsEmpty(response.anomaly)) {
                lastViewedAnomaly = response.anomaly;
                tempHtml = "<div class='hth'>";
                tempHtml += "<div class='tr'><div>N° segnalazione</div><div>" + doubleZeroPadding(response.anomaly.customerAnomalyId) + "</div></div>";
                tempHtml += "<div class='tr'><div>Sito</div><div>" + response.anomaly.siteName + "</div></div>";
                tempHtml += "<div class='tr'><div>Categoria</div><div>" + response.anomaly.categoryName + "</div></div>";
                tempHtml += "<div class='tr'><div>Sottocategoria</div><div>" + ((response.anomaly.subcategoryName === null) ? "Nessuna" : response.anomaly.subcategoryName) + "</div></div>";
                tempHtml += "<div class='tr'><div>Segnalazione</div><div>" + iso8601.toEuropeanDateTimeWithSeconds(response.anomaly.reportTimestamp) + "</div></div>";
                tempHtml += "<div class='tr'><div>Segnalato da</div><div>" + response.anomaly.reportedBy + "</div></div>";
                if(response.anomaly.competenceAreaId!=null){
                    tempHtml += "<div class='tr'><div>Area di competenza</div><div>" + response.anomaly.competenceAreaName;
                    if(permissions["CHANGE_COMPETENCE_AREA"]==="ACCESSIBLE") {
                        tempHtml+="<br><button type='button' onclick=\"changeCompetenceArea('Segnalazioni','"+ response.anomaly.competenceAreaName+"',"+ response.anomaly.competenceAreaId+","+response.anomaly.siteId+","+response.anomaly.anomalyId+");\">";
                        tempHtml+="<i class='fa fa-pencil' aria-hidden='true'></i> Riassegna";
                        tempHtml+="</button>";
                    }
                    tempHtml += "</div></div>";
                }
                tempHtml += "<div class='tr'><div>Descrizione</div><div>" + response.anomaly.description + "</div></div>";
                if(response.anomaly.relatedTicketId !== null) {
                    tempHtml += "<div class='tr'><div>Ticket collegato</div><div><button type='button' onclick='closePopup();selectTicket(" + response.anomaly.relatedTicketId + ",true);'>Ticket N°" + response.anomaly.customerTicketId + " - " + response.anomaly.ticketState + "</button></div></div>";
                }
                tempHtml += "<div class='tr'><div>Asset collegati</div><div>";
                if(response.anomaly.relatedAssets.length===0){
                    tempHtml += "<span class='no-value-text'>Nessuno</span>";
                }
                else{
                    response.anomaly.relatedAssets.forEach(function(relatedAsset){
                        tempHtml+="<button type='button' onclick='closePopup();selectAssetInstance("+relatedAsset.assetId+");'>"+(relatedAsset.qrCode?relatedAsset.qrCode:("#"+relatedAsset.assetId))+"</button>";
                    });
                }
                tempHtml += "</div></div>";
                tempHtml += "</div>";
                if(response.anomaly.relatedPictures.length > 0) {
                    tempHtml += "<br><span class='important-label' style='margin-left:0;'>Foto</span><br>";
                    for(var i = 0; i < response.anomaly.relatedPictures.length; i++) {
                        tempHtml += "<img " +
                            "class='anomaly-image' " +
                            "onload='refreshAnomalyDetailsScroll();' " +
                            "onclick='imageTheater(\"" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + response.anomaly.relatedPictures[i] + "&authToken=" + currentAuthToken + "\");' " +
                            "src='" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + response.anomaly.relatedPictures[i] + "&height=160&authToken=" + currentAuthToken + "'" +
                            "> ";
                    }
                }
                if(response.anomaly.state === "Nuovo") {
                    switch(permissions["CHANGE_ANOMALY_STATE"]) {
                        case "ACCESSIBLE":
                            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Gestisci segnalazione</span><br>";
                            switch(permissions["OPEN_TICKET"]) {
                                case "ACCESSIBLE":
                                    tempHtml += " <button type='button' onclick='createTicketFromAnomaly();'><i class='fa fa-ticket' aria-hidden='true'></i> Crea ticket</button>";
                                    break;
                                case "DISABLED":
                                    tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-ticket' aria-hidden='true'></i> Crea ticket</button>";
                                    break;
                            }
                            tempHtml += " <button type='button' onclick='anomaliesPage.ignoreAnomaly(" + response.anomaly.anomalyId + ");'><i class='fa fa-thumbs-down' aria-hidden='true'></i> Ignora segnalazione</button>";
                            break;
                        case "DISABLED":
                            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Gestisci segnalazione</span><br>";
                            tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-ticket' aria-hidden='true'></i> Crea ticket</button>";
                            tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-thumbs-down' aria-hidden='true'></i> Ignora segnalazione</button>";
                            break;
                    }
                }
                if(response.anomaly.state === "Da approvare") {
                    if(permissions["CHANGE_ANOMALY_STATE"]==="ACCESSIBLE"||permissions["EDIT_ANOMALY"]==="ACCESSIBLE"||permissions["CHANGE_ANOMALY_STATE"]==="DISABLED"||permissions["EDIT_ANOMALY"]==="DISABLED"){
                        tempHtml += "<br><span class='important-label' style='margin-left:0;'>Gestisci segnalazione</span><br>";
                    }
                    switch(permissions["CHANGE_ANOMALY_STATE"]) {
                        case "ACCESSIBLE":
                            switch(permissions["APPROVE_ANOMALY"]) {
                                case "ACCESSIBLE":
                                    tempHtml += " <button type='button' onclick='anomaliesPage.approveAnomaly(" + response.anomaly.anomalyId + ");'><i class='fa fa-thumbs-up' aria-hidden='true'></i> Approva segnalazione</button>";
                                    tempHtml += " <button type='button' onclick='anomaliesPage.deleteAnomaly(" + response.anomaly.anomalyId + ");'><i class='fa fa-thumbs-down' aria-hidden='true'></i> Elimina segnalazione</button>";
                                    break;
                                case "DISABLED":
                                    tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-thumbs-up' aria-hidden='true'></i> Approva segnalazione</button>";
                                    tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-thumbs-down' aria-hidden='true'></i> Elimina segnalazione</button>";
                                    break;
                            }
                            break;
                        case "DISABLED":
                            tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-thumbs-up' aria-hidden='true'></i> Approva segnalazione</button>";
                            tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-thumbs-down' aria-hidden='true'></i> Elimina segnalazione</button>";
                            break;
                    }
                    switch(permissions["EDIT_ANOMALY"]) {
                        case "ACCESSIBLE":
                            tempHtml += " <button type='button' onclick='anomaliesPage.editAnomaly(" + response.anomaly.anomalyId + ");'><i class='fa fa-pencil' aria-hidden='true'></i> Modifica segnalazione</button>";
                            break;
                        case "DISABLED":
                            tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-pencil' aria-hidden='true'></i> Modifica segnalazione</button>";
                            break;
                    }
                }
            }
            else {
                tempHtml = "<div class='message error'>La segnalazione richiesta non esiste o non si dispongono i permessi per poterla visualizzare</div>";
            }
            if(smallViewport) {
                setInnerHtml(
                    document.getElementById("popup-anomaly-info-container"),
                    tempHtml,
                    function(){scroll.refreshPopupById("anomaly-info", false);}
                );
                popup("anomaly-info", false, false, false);
            }
            else {
                setInnerHtml(
                    document.getElementById("anomaly-details"),
                    tempHtml,
                    function(){scroll.refreshByName("anomaly-details");}
                );
            }
        }
        else {
            if(smallViewport) {
                setInnerHtml(
                    document.getElementById("popup-anomaly-info-container"),
                    "<div class='message error'> Errore: " + response.message + "</div>",
                    function(){scroll.refreshPopupById("anomaly-info", false);}
                );
                popup("anomaly-info", false, false, false);
            }
            else {
                setInnerHtml(
                    document.getElementById("anomaly-details"),
                    "<div class='message error'> Errore: " + response.message + "</div>",
                    function(){scroll.refreshByName("anomaly-details");}
                );
            }
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAnomaly.php?anomalyId=" + anomalyId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function clearAccountingDashboard() {
    document.getElementById("accounting-status").innerHTML = "";
    document.getElementById("accounting-summary").innerHTML = "Per gestire la contabilità è necessario selezionare un <strong>gruppo di edifici</strong> ed un relativo <strong>anno di esercizio</strong>.";
    document.getElementById("accounting-sites-trend").innerHTML = "";
    document.getElementById("accounting-link-invoices").disabled = "disabled";
    document.getElementById("accounting-link-repartition-tables").disabled = "disabled";
    document.getElementById("accounting-link-installments").disabled = "disabled";
    document.getElementById("accounting-link-transactions").disabled = "disabled";
    document.getElementById("accounting-link-estimates").disabled = "disabled";
    document.getElementById("accounting-link-consumptives").disabled = "disabled";
    document.getElementById("accounting-link-balances").disabled = "disabled";
    document.getElementById("accounting-link-expenditure-items").disabled = "disabled";
}

function loadAccountingDashboard() {
    if(document.getElementById("accounting-year-selector").value === "") {
        clearAccountingDashboard();
    }
    else {
        ajaxGetAccountingDashboardData();
        var selectedBuildingsGroup = document.getElementById("accounting-buildings-group").value;
        var tempHtml = "";
        var i;
        tempHtml += "<option value='' disabled selected>-- Selezionare --</option>";
        for(i = 0; i < sites.length; i++) {
            if(sites[i].buildingsGroupId == selectedBuildingsGroup) {
                tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
            }
        }
        document.getElementById("add-invoice-receiving-site-selector").innerHTML = tempHtml;
        document.getElementById("register-installment-site").innerHTML = tempHtml;
        tempHtml = "";
        for(i = 0; i < sites.length; i++) {
            if(sites[i].buildingsGroupId == selectedBuildingsGroup && sites[i].isCommon) {
                tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
            }
        }
        document.getElementById("add-repartition-table-common-site-selector").innerHTML = tempHtml;
        tempHtml = "";
        for(i = 0; i < buildings.length; i++) {
            if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
                tempHtml += "<option value='" + buildings[i].buildingId + "'>" + buildings[i].name + "</option>";
            }
        }
        document.getElementById("add-repartition-table-building-selector").innerHTML = tempHtml;
        ajaxGetBalances();
    }
}

function ajaxGetAccountingDashboardData() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var i;
            var accountingLinkInvoices = document.getElementById("accounting-link-invoices");
            var accountingLinkInstallments = document.getElementById("accounting-link-installments");
            var accountingLinkTransactions = document.getElementById("accounting-link-transactions");
            var accountingLinkConsumptives = document.getElementById("accounting-link-consumptives");
            document.getElementById("accounting-link-repartition-tables").removeAttribute("disabled");
            document.getElementById("accounting-link-estimates").removeAttribute("disabled");
            document.getElementById("accounting-link-balances").removeAttribute("disabled");
            document.getElementById("accounting-link-expenditure-items").removeAttribute("disabled");
            var accountingSummary = document.getElementById("accounting-summary");
            accountingSummary.innerHTML = "";
            switch(response.yearPeriod) {
                case "newYear":
                    accountingLinkInvoices.disabled = "disabled";
                    accountingLinkInstallments.disabled = "disabled";
                    accountingLinkTransactions.disabled = "disabled";
                    accountingLinkConsumptives.disabled = "disabled";
                    accountingSummary.innerHTML += "Periodo: definizione preventivi";
                    break;
                case "estimationsClosed":
                    accountingLinkInvoices.disabled = "disabled";
                    accountingLinkInstallments.removeAttribute("disabled");
                    accountingLinkTransactions.disabled = "disabled";
                    accountingLinkConsumptives.disabled = "disabled";
                    accountingSummary.innerHTML += "Periodo: definizione tabelle di ripartizione e generazione rate";
                    break;
                case "installmentsClosed":
                    accountingLinkInvoices.removeAttribute("disabled");
                    accountingLinkInstallments.removeAttribute("disabled");
                    accountingLinkTransactions.removeAttribute("disabled");
                    accountingLinkConsumptives.removeAttribute("disabled");
                    accountingSummary.innerHTML += "Periodo: fatturazione, riscossione e consuntivazione";
                    break;
                case "yearClosed":
                    accountingLinkInvoices.removeAttribute("disabled");
                    accountingLinkInstallments.removeAttribute("disabled");
                    accountingLinkTransactions.removeAttribute("disabled");
                    accountingLinkConsumptives.removeAttribute("disabled");
                    accountingSummary.innerHTML += "Periodo: consuntivi finalizzati, anno archiviato";
                    break;
            }
            accountingSummary.innerHTML += "<i class='fa fa-question-circle inline-help' aria-hidden='true' onclick='popup( \"help-year-period\" ,false,undefined,false);'></i><br><br>";
            var missingBuildingGroupRepTable = false;
            var buildingRepTable = response.countBuildingRepartitionTable;
            var commonSiteRepTable = response.countCommonSiteRepartitionTable;
            unpaidSupplierInvoices = response.unpaidSupplierInvoices;
            var budgetedSpending = response.budgetedSpending;
            var actualSpending = response.actualSpending;
            var shortConsumptive = response.shortConsumptive;
            expenditureItems = response.expenditureItems;
            vatRates = response.vatRates;
            var accountingStatusBoard = document.getElementById("accounting-status");
            var accountingSitesTrend = document.getElementById("accounting-sites-trend");
            accountingStatusBoard.innerHTML = "";
            accountingSitesTrend.innerHTML = "<h3>Spese per zona (IVA incl.)</h3><br>";
            if(missingBuildingGroupRepTable) {
                accountingStatusBoard.innerHTML += "<i class='fa fa-exclamation-circle' aria-hidden='true'></i> Tabelle di ripartizione per il gruppo di edifici mancanti";
            }
            else {
                accountingStatusBoard.innerHTML += "<i class='fa fa-check-circle' aria-hidden='true'></i> Tabelle di ripartizione per il gruppo di edifici presenti";
            }
            if(buildingRepTable <= 0) {
                accountingStatusBoard.innerHTML += "<br><i class='fa fa-exclamation-circle' aria-hidden='true'></i> Tabelle di ripartizione per edifici mancanti";
            }
            else {
                accountingStatusBoard.innerHTML += "<br><i class='fa fa-check-circle' aria-hidden='true'></i> " + buildingRepTable + " tabelle di ripartizione per gli edifici presenti";
            }
            if(commonSiteRepTable <= 0) {
                accountingStatusBoard.innerHTML += "<br><i class='fa fa-exclamation-circle' aria-hidden='true'></i> Tabelle di ripartizione per siti comuni mancanti";
            }
            else {
                accountingStatusBoard.innerHTML += "<br><i class='fa fa-check-circle' aria-hidden='true'></i> " + commonSiteRepTable + " tabelle di ripartizione per i siti comuni presenti";
            }
            if(unpaidSupplierInvoices.length > 0) {
                accountingStatusBoard.innerHTML += "<br><span onclick='popup( \"view-invoices\" ,true,false,false);' style='cursor:pointer;'><i class='fa fa-exclamation-circle' aria-hidden='true'></i> " + unpaidSupplierInvoices.length + " fatture risultano non saldate</span>";
            }
            accountingSummary.innerHTML += "<span class='accounting-summary-label'>Spese dell'anno corrente:</span><span class='accounting-summary-spending'>€ " + formatCurrency(actualSpending) + "</span>";
            accountingSummary.innerHTML += "<br><br><span class='accounting-summary-label'>Preventivo anno corrente:</span><span class='accounting-summary-spending'>€ " + formatCurrency(budgetedSpending) + "</span>";
            for(i = 0; i < shortConsumptive.length; i++) {
                accountingSitesTrend.innerHTML += "<span class='accounting-zone'>" + shortConsumptive[i].siteName + "</span>";
                accountingSitesTrend.innerHTML += "<span class='accounting-amount'>€ " + formatCurrency((shortConsumptive[i].untaxedAmount + shortConsumptive[i].otherTaxes + shortConsumptive[i].vat)) + "</span><br>";
            }
            var tempHtml = "";
            for(i = 0; i < expenditureItems.length; i++) {
                tempHtml += "<option value='" + expenditureItems[i].expenditureItemId + "'>" + expenditureItems[i].name + "</option>";
            }
            document.getElementById("add-invoice-expenditure-item").innerHTML = tempHtml;
            document.getElementById("add-repartition-table-expenditure-item").innerHTML = tempHtml;
            tempHtml = "";
            for(i = 0; i < response.contributionTypes.length; i++) {
                tempHtml += "<option value='" + response.contributionTypes[i] + "'>" + response.contributionTypes[i] + "</option>";
            }
            document.getElementById("add-repartition-table-method").innerHTML = tempHtml;
            tempHtml = "";
            for(i = 0; i < vatRates.length; i++) {
                tempHtml += "<option value='" + vatRates[i].vatRateId + "'>" + vatRates[i].name + "</option>";
            }
            document.getElementById("add-invoice-vat-rate").innerHTML = tempHtml;
            tempHtml = "";
            tempHtml += "<option value='' selected>Nessuna unità di misura</option>";
            for(i = 0; i < response.unitsOfMeasure.length; i++) {
                if(response.unitsOfMeasure[i].accountingSection) {
                    tempHtml += "<option value='" + response.unitsOfMeasure[i].unitName + "'>" + response.unitsOfMeasure[i].description + "</option>";
                }
            }
            document.getElementById("add-expenditure-item-unit").innerHTML = tempHtml;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore di connessione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAccountingDashboardData.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxReloadCompanies() {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml2 = "";
            var companies = response.companies;
            document.getElementById("contract-customer").innerHTML = "";
            document.getElementById("contract-contractor").innerHTML = "";
            for(var i = 0; i < companies.length; i++) {
                if(companies[i].isCustomer) {
                    tempHtml2 += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
                }
                if(companies[i].isContractor) {
                    document.getElementById("contract-contractor").innerHTML += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
                    if(companies[i].isEditable) {
                        if(companies[i].privateVersion == document.getElementById("manage-company-selector").value) {
                            editableContractorsList += "<option value='" + companies[i].privateVersion + "' selected='selected'>" + companies[i].companyName + "</option>";
                        }
                        else {
                            editableContractorsList += "<option value='" + companies[i].privateVersion + "'>" + companies[i].companyName + "</option>";
                        }
                    }
                }
            }
            console.log(editableContractorsList);
            document.getElementById("manage-company-selector").innerHTML = editableContractorsList;
            document.getElementById("safety-control-company-selector").innerHTML = editableContractorsList;
            manageCompanySelectorChanged();
            safetyControlPage.refresh();
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php?companyId=" + globalFilterByCompany, true);
    }
    else {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php", true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxRefreshBuildings(buildingsGroupToSelect) {
    var selectedBuildingsGroup;
    if(typeof(buildingsGroupToSelect) !== "undefined") {
        selectedBuildingsGroup = buildingsGroupToSelect;
    }
    else {
        selectedBuildingsGroup = document.getElementById("manage-buildings-buildings-group").value;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            sites = response.sites;
            buildingsGroups = response.buildingsGroups;
            buildings = response.buildings;
            var i;
            maintenancePage.refreshSitesFilters();
            tempHtml = "";
            for(i = 0; i < buildingsGroups.length; i++) {
                tempHtml += "<option value='" + buildingsGroups[i].buildingGroupId + "'>" + buildingsGroups[i].name + "</option>";
            }
            //only one element in the select
            if(i === 1) {
                setInnerHtml(document.getElementById("add-asset-buildings-group"), tempHtml, createAssetLoadBuildings);
                setInnerHtml(document.getElementById("add-asset-for-qr-code-buildings-group"), tempHtml, addAssetForQrCodeBuildingGroupChanged);
            }
            else {
                document.getElementById("add-asset-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
                document.getElementById("add-asset-for-qr-code-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            }
            document.getElementById("new-building-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("common-unit-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("property-unit-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("create-accounting-year-buildings-group").innerHTML = tempHtml;
            if(!popupManager.popupIsOpen("edit-site")){
                document.getElementById("edit-site-buildings-group").innerHTML = tempHtml;
            }
            document.getElementById("manage-buildings-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("edit-building-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("manage-buildings-buildings-group").value = selectedBuildingsGroup;
            document.getElementById("accounting-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
            setInnerHtml(document.getElementById("manage-asset-buildings-group"), (tempHtml + "<option value='' selected>-- Tutti --</option>"));

            var tempHtml = "";
            for(i = 0; i < buildings.length; i++) {
                tempHtml += "<option value='" + buildings[i].buildingId + "'>" + buildings[i].name + "</option>";
            }
            document.getElementById("manage-asset-building").innerHTML = tempHtml + "<option value='' selected>-- Tutti --</option>";
            tempHtml = "";
            for(i = 0; i < sites.length; i++) {
                tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</site>";
            }
            document.getElementById("upload-asset-site").innerHTML = tempHtml + "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>";
            document.getElementById("manage-asset-site").innerHTML = tempHtml + "<option value='' selected>-- Tutti --</option>";
            document.getElementById("manage-asset-floor").innerHTML = tempHtml + "<option value='' selected>-- Tutti --</option>";
            if(map !== null) {
                createSitePushpins();
            }
            if(selectedBuildingsGroup !== "") {
                viewBuildingsOrganization();
            }
        }
        else {
            if(response.responseCode === 403) {
                dom.addClass(document.getElementById("page-loading-error"), "hidden");
                dom.addClass(document.getElementById("page-loading"), "hidden");
                if(urlNeedsLogin()) {
                    changePage("page-login");
                }
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
                popup("outcome", false, false, false);
            }
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php?companyId=" + globalFilterByCompany, true);
    }
    else {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php", true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function setGlobalFilterToCompany(companyId) {
    dom.addClass(document.getElementById("work-area-items"),"hidden");
    dom.removeClass(document.querySelector(".home-items"),"hidden");
    dom.removeClass(document.getElementById("home-btn-search"),"hidden");
    for(var i = 0; i < globalUserPermissions.length; i++) {
        if(globalUserPermissions[i].companyId == companyId) {
            setInnerHtml(document.getElementById("global-company-filter-holder"), globalUserPermissions[i].companyName);
        }
    }
    switch(currentPage) {
        case "page-intervention":
        case "page-execute-intervention":
        case "page-intervention-activity":
            changePage("page-calendar");
            break;
        case "page-asset":
            changePage("page-assets");
            break;
    }
    globalFilterByCompany = companyId;
    lastSelectedTicket = null;
    lastSelectedAnomaly = null;
    ajaxGetDashboardData();
    closePopup();
}
window.setGlobalFilterToCompany=setGlobalFilterToCompany;

function getWorkArea(workAreasTree,workAreaId){
    if(workAreasTree.id==workAreaId){
        return workAreasTree;
    }
    var i;
    for (i=0;i<workAreasTree.children.length;i++) {
        var result = getWorkArea(workAreasTree.children[i],workAreaId);
        if(result!==null){
            return result;
        }
    }
    return null;
}

function openCleanLateInterventions(){
    document.getElementById("all-late-interventions-container").innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento interventi...</span>";
    popup("all-late-interventions", false, false, false);
}
window.openCleanLateInterventions=openCleanLateInterventions;

function openCleanUnresolvedAnomalies(){
    changePage("page-anomalies");
}
window.openCleanUnresolvedAnomalies=openCleanUnresolvedAnomalies;

function openCleanCompanyProblems(){
    document.getElementById("company-problems-list").innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento problemi...</span>";
    popup("company-problems");
}
window.openCleanCompanyProblems=openCleanCompanyProblems;

function openCleanToValidateInterventions(companyId){    
    document.getElementById("interventions-to-validate-container").innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento interventi da validare...</span>";
    setGlobalFilterToCompany(companyId);
    renderInterventionsToValidatePopup();
}
window.openCleanToValidateInterventions=openCleanToValidateInterventions;

function showWorkAreaCounter(type){
    switch(type){
        case "late-interventions":
            document.getElementById("work-area-counters-popup-title").innerHTML="Interventi in ritardo";
            break;
        case "anomalies":
            document.getElementById("work-area-counters-popup-title").innerHTML="Nuove segnalazioni";
            break;            
        case "to-validate-interventions":
            document.getElementById("work-area-counters-popup-title").innerHTML="Interventi da validare";
            break;        
        case "company-problems":
            document.getElementById("work-area-counters-popup-title").innerHTML="Problemi organizzativi";
            break;
    }
    popup("work-area-counters");
    var tempHtml="";
    currentWorkArea.children.forEach(function(child){
        switch(type){
            case "late-interventions":
                tempHtml+="<button type='button' onclick='setGlobalFilterToCompany("+child.companyId+");openCleanLateInterventions();' class='work-area-counter "+(child.lateInterventionsCounter>0?"b-red":"b-green")+"'>";
                tempHtml+="<span class='counter'>"+child.lateInterventionsCounter+"</span><span class='label'>"+child.name+"</span></button>";
                break;
            case "anomalies":
                tempHtml+="<button type='button' onclick='setGlobalFilterToCompany("+child.companyId+");openCleanUnresolvedAnomalies();' class='work-area-counter "+(child.unresolvedAnomaliesCounter>0?"b-yellow":"b-green")+"'>";
                tempHtml+="<span class='counter'>"+child.unresolvedAnomaliesCounter+"</span><span class='label'>"+child.name+"</span></button>";
                break;            
            case "to-validate-interventions":
                tempHtml+="<button type='button' onclick='openCleanToValidateInterventions("+child.companyId+");' class='work-area-counter "+(child.toValidateInterventionsCounter>0?"b-yellow":"b-green")+"'>";
                tempHtml+="<span class='counter'>"+child.toValidateInterventionsCounter+"</span><span class='label'>"+child.name+"</span></button>";
                break;        
            case "company-problems":
                tempHtml+="<button type='button' onclick='setGlobalFilterToCompany("+child.companyId+");openCleanCompanyProblems();' class='work-area-counter "+(child.companyProblemsCounter>0?"b-yellow":"b-green")+"'>";
                tempHtml+="<span class='counter'>"+child.companyProblemsCounter+"</span><span class='label'>"+child.name+"</span></button>";
                break;
        }
    });
    document.querySelector("#work-area-counters-popup .main-content").innerHTML=tempHtml;
}
window.showWorkAreaCounter=showWorkAreaCounter;

var currentWorkArea = null;
function setGlobalFilterToWorkArea(workAreaId){
    currentWorkArea = getWorkArea(window.workAreas,workAreaId);
    window.localStorage.setItem("cfm_last_global_filter_by_workarea",workAreaId);
    if(currentWorkArea!=null){
        document.getElementById("tab-ticketing").parentNode.style.display="none";
        document.getElementById("tab-anomalies").parentNode.style.display="none";
        document.getElementById("tab-maintenance").parentNode.style.display="none";
        document.getElementById("tab-calendar").parentNode.style.display="none";
        document.getElementById("tab-manage-asset").parentNode.style.display="none";
        document.getElementById("tab-data-room").parentNode.style.display="none";
        document.getElementById("tab-buildings").parentNode.style.display="none";
        document.getElementById("tab-company").parentNode.style.display="none";
        document.getElementById("tab-contracts").parentNode.style.display="none";
        document.getElementById("tab-safety-control").parentNode.style.display="none";
        document.getElementById("tab-accounting").parentNode.style.display="none";
        dom.addClass(document.querySelector(".home-items"),"hidden");
        dom.addClass(document.getElementById("home-btn-search"),"hidden");
        dom.removeClass(document.getElementById("work-area-items"),"hidden");
        setInnerHtml(document.getElementById("global-company-filter-holder"),currentWorkArea.name);
        document.querySelector("#wa-late-interventions-counter .counter").innerHTML=parseInt(currentWorkArea.lateInterventionsCounter);
        if(currentWorkArea.lateInterventionsCounter>0){
            dom.addClass(document.getElementById("wa-late-interventions-counter"),"b-red");
            dom.removeClass(document.getElementById("wa-late-interventions-counter"),"b-green");
        }
        else{
            dom.removeClass(document.getElementById("wa-late-interventions-counter"),"b-red");
            dom.addClass(document.getElementById("wa-late-interventions-counter"),"b-green");
        }
        document.querySelector("#wa-anomalies-counter .counter").innerHTML=parseInt(currentWorkArea.unresolvedAnomaliesCounter);
        if(currentWorkArea.unresolvedAnomaliesCounter>0){
            dom.addClass(document.getElementById("wa-anomalies-counter"),"b-new-yellow");
            dom.removeClass(document.getElementById("wa-anomalies-counter"),"b-green");
        }
        else{
            dom.removeClass(document.getElementById("wa-anomalies-counter"),"b-new-yellow");
            dom.addClass(document.getElementById("wa-anomalies-counter"),"b-green");
        }
        document.querySelector("#wa-to-validate-interventions-counter .counter").innerHTML=parseInt(currentWorkArea.toValidateInterventionsCounter);
        if(currentWorkArea.toValidateInterventionsCounter>0){
            dom.addClass(document.getElementById("wa-to-validate-interventions-counter"),"b-new-yellow");
            dom.removeClass(document.getElementById("wa-to-validate-interventions-counter"),"b-green");
        }
        else{
            dom.removeClass(document.getElementById("wa-to-validate-interventions-counter"),"b-new-yellow");
            dom.addClass(document.getElementById("wa-to-validate-interventions-counter"),"b-green");
        }
        document.querySelector("#wa-company-problems-counter .counter").innerHTML=parseInt(currentWorkArea.companyProblemsCounter);
        if(currentWorkArea.companyProblemsCounter>0){
            dom.addClass(document.getElementById("wa-company-problems-counter"),"b-new-yellow");
            dom.removeClass(document.getElementById("wa-company-problems-counter"),"b-green");
        }
        else{
            dom.removeClass(document.getElementById("wa-company-problems-counter"),"b-new-yellow");
            dom.addClass(document.getElementById("wa-company-problems-counter"),"b-green");
        }
        changePage("page-home");
        closePopup();
    }
    
}

function checkPermissionsOnTab(permissionCode, tabId) {
    var tab = document.getElementById(tabId);
    switch(permissions[permissionCode]) {
        case "ACCESSIBLE":
            dom.removeClass(tab.parentNode, "tab-not-available");
            tab.parentNode.style.removeProperty("display");
            tab.removeAttribute("disabled");
            break;
        case "HIDDEN":
            dom.addClass(tab.parentNode, "tab-not-available");
            tab.parentNode.style.display = "none";
            tab.disabled = "disabled";
            break;
        case "DISABLED":
            dom.addClass(tab.parentNode, "tab-not-available");
            tab.parentNode.style.removeProperty("display");
            tab.disabled = "disabled";
            break;
    }
}

function checkPermissionsOnHomeButton(permissionCode, buttonId) {
    var homeButton = document.getElementById(buttonId);
    if(homeButton!==null){
        switch(permissions[permissionCode]) {
            case "ACCESSIBLE":
                homeButton.removeAttribute("disabled");
                homeButton.parentNode.parentNode.style.removeProperty("display");
                break;
            case undefined:
            case null:
            case "HIDDEN":
                homeButton.parentNode.parentNode.style.display = "none";
                break;
            case "DISABLED":
                homeButton.parentNode.parentNode.style.removeProperty("display");
                homeButton.setAttribute("disabled", "disabled");
                break;
        }
    }
    else {
        console.error("Home button " + buttonId + " does not exist");
    }
}

function checkPermissionOnButtons(permissionCode, buttonsIds) {
    for(var i = 0; i < buttonsIds.length; i++) {
        var buttonToCheck = document.getElementById(buttonsIds[i]);
        if(buttonToCheck!==null){
            switch(permissions[permissionCode]) {
                case "ACCESSIBLE":
                    buttonToCheck.removeAttribute("disabled");
                    buttonToCheck.style.removeProperty("display");
                    break;
                case undefined:
                case null:
                case "HIDDEN":
                    buttonToCheck.style.display = "none";
                    break;
                case "DISABLED":
                    buttonToCheck.style.removeProperty("display");
                    buttonToCheck.setAttribute("disabled", "disabled");
                    break;
            }
        }
        else {
            console.error("Button " + buttonsIds[i] + " does not exist");
        }
    }
}

function applyUserPermissions(permissions) {
    //checkPermissionsOnTab enables, disables or hides tabs depending on the permissions that a user has
    checkPermissionsOnTab("SECTION_HOME", "tab-home");
    checkPermissionsOnTab("SECTION_PROPERTY", "tab-buildings");
    checkPermissionsOnTab("SECTION_TICKET", "tab-ticketing");
    checkPermissionsOnTab("SECTION_ACCOUNTING", "tab-accounting");
    checkPermissionsOnTab("SECTION_CONTRACT", "tab-contracts");
    checkPermissionsOnTab("SECTION_CONTRACT", "tab-plan");
    checkPermissionsOnTab("SECTION_MANAGE_ASSET", "tab-manage-asset");
    checkPermissionsOnTab("SECTION_DATA_ROOM", "tab-data-room");
    checkPermissionsOnTab("SECTION_ADVANCED_SETTINGS", "tab-settings");
    checkPermissionsOnTab("SECTION_ADVANCED_SETTINGS", "tab-settings-mobile");
    checkPermissionsOnTab("SECTION_COMPANIES", "tab-company");
    checkPermissionsOnTab("VIEW_NOTIFICATIONS", "tab-notifications");
    checkPermissionsOnTab("SECTION_CALENDAR", "tab-calendar");
    checkPermissionsOnTab("SECTION_MAINTENANCE", "tab-maintenance");
    checkPermissionsOnTab("SECTION_ANOMALIES", "tab-anomalies");
    checkPermissionsOnTab("SECTION_SAFETY_CONTROL","tab-safety-control");

    //checkPermissionsOnHomeButton enables, disables or hides home page buttons depending on the permissions that a user has
    checkPermissionsOnHomeButton("VALIDATE_INTERVENTION", "home-btn-validate-intervention");
    checkPermissionsOnHomeButton("SECTION_MANAGE_ASSET", "home-btn-manage-asset");
    checkPermissionsOnHomeButton("SECTION_CALENDAR", "home-btn-calendar");
    checkPermissionsOnHomeButton("SECTION_MAINTENANCE", "home-btn-maintenance");
    checkPermissionsOnHomeButton("SECTION_TICKET", "home-btn-ticketing");
    checkPermissionsOnHomeButton("SECTION_ANOMALIES", "home-btn-anomalies");

    //Always enable user tab (allows logging out, viewing his own info, etc.)
    var tabUser = document.getElementById("tab-user");
    dom.removeClass(tabUser.parentNode, "tab-not-available");
    tabUser.parentNode.style.removeProperty("display");
    tabUser.removeAttribute("disabled");

    //checkPermissionOnButtons enables, disables or hides buttons depending on the permissions that a user has
    checkPermissionOnButtons("EDIT_COMPANY_INFO", ["manage-company-edit-info-button"]);
    checkPermissionOnButtons("OPEN_TICKET", ["create-ticket-btn", "action-button-create-ticket", "create-ticket-from-asset-btn"]);
    checkPermissionOnButtons("CREATE_ANOMALY", ["create-anomaly-btn", "action-button-create-anomaly", "create-anomaly-from-asset-btn", "comment-activity-on-asset-execution-create-anomaly-button"]);
    checkPermissionOnButtons("CREATE_ASSET", ["create-asset-button", "action-button-create-asset"]);
    checkPermissionOnButtons("EDIT_ASSET", ["page-asset-details-edit-button", "action-button-edit-asset"]);
    checkPermissionOnButtons("EDIT_ASSET_RELATIONS", ["edit-asset-tree-button"]);
    checkPermissionOnButtons("IMPORT_ASSET", ["import-asset-button", "import-asset-button-mobile"]);
    checkPermissionOnButtons("DELETE_ASSET", ["page-asset-details-delete-button","action-button-delete-asset"]);
    checkPermissionOnButtons("DATA_ROOM_VIEW_TRASH", ["enter-data-room-trash-button"]);
    checkPermissionOnButtons("DATA_ROOM_UPLOAD_DOCUMENT", ["upload-document-button"]);
    checkPermissionOnButtons("CREATE_INTERVENTION", ["create-intervention-button", "action-button-create-intervention", "create-intervention-button-year"]);
    checkPermissionOnButtons("EDIT_INTERVENTION", ["manage-intervention-button","action-button-edit-intervention"]);
    checkPermissionOnButtons("VIEW_INTERVENTION_LOG", ["view-intervention-log-button"]);
    checkPermissionOnButtons("VIEW_FIRE_LOG_BOOK", ["export-fire-log-book-button"]);
    checkPermissionOnButtons("VIEW_CUSTOMER_SATISFACTION_REPORT", ["export-customer-satisfaction-report-button"]);
    checkPermissionOnButtons("EXECUTE_INTERVENTION", ["start-intervention-button"]);
    checkPermissionOnButtons("EXPORT_INTERVENTION", ["export-interventions-button"]);
    checkPermissionOnButtons("VIEW_ANNUAL_REPORT", ["annual-report-button", "annual-report-button-2"]);
    checkPermissionOnButtons("SEND_INTERVENTION_NOTIFICATION_VIA_MAIL", ["send-intervention-mail-button"]);
    checkPermissionOnButtons("REMOVE_ACTIVITY_FROM_INTERVENTION", ["remove-activity-from-intervention-button"]);
    checkPermissionOnButtons("VIEW_LATE_INTERVENTIONS_HOME_COUNTER", ["home-late-interventions-counter"]);
    checkPermissionOnButtons("FULL_SEARCH", ["home-btn-search"]);
    checkPermissionOnButtons("VIEW_ANOMALIES", ["home-anomalies-counter"]);
    checkPermissionOnButtons("VALIDATE_INTERVENTION", ["home-to-validate-interventions-counter"]);
    checkPermissionOnButtons("VIEW_COMPANY_PROBLEMS_REPORT", ["home-company-problems-counter"]);
    checkPermissionOnButtons("CREATE_CONTRACT", ["create-contract-button","action-button-create-contract"]);
    checkPermissionOnButtons("CREATE_BUILDINGS_GROUP", ["create-buildings-group-button"]);
    checkPermissionOnButtons("DOWNLOAD_ASSET_FIELD_REPORT", ["export-asset-field-for-subcategory-report-button"]);
    checkPermissionOnButtons("VIEW_OWN_COMPANY_USERS", ["your-company-users-button"]);

    checkPermissionOnButtons("VIEW_DOCUMENTS", [
        "edit-asset-document-section",
        "asset-document-manual-file",
        "clear-asset-document-manual",
        "asset-document-project-file",
        "clear-asset-document-project",
        "asset-document-conformity-declaration-file",
        "clear-asset-document-conformity-declaration"
    ]);
}

function localDbFetchedInterventionContainsActivity(intervention,activityId) {
    var found=false;
    intervention.activities.forEach(function(activity){
        if(activity.interventionActivityId===activityId){
            found=true;
        }
    });
}

function loadInitializationDataFromLocalDB(successCallback,companyIdToLoad) {
    if(companyIdToLoad==null){
        console.error("Nessuna azienda selezionata");
    }
    var response = {};
    response.certificationsNames = [];
    response.currentCompanyId = companyIdToLoad;
    response.categoryTree = {};
    response.meteo = [];
    response.permissionLevels = [];
    response.permissionTypes = [];
    response.notifications = [];//todo
    response.companyLicenses = [];
    var operationStartIndex=-1;
    var executeNextLocalDbOperation = function(){
        if(operationStartIndex+1<localDbOperations.length) {
            operationStartIndex++;
            localDbOperations[operationStartIndex]();
        }
    };
    var localDbOperations = [
        function(){
            db.getLoggedInUserInfo(
                companyIdToLoad,
                function(resultSet) {
                    response.loggedInUserInfo = {};
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        if(row.userId) {
                            response.loggedInUserInfo.birthday = null; //not stored locally
                            response.loggedInUserInfo.company = row.companyId;
                            response.loggedInUserInfo.companyName = row.companyName;
                            response.loggedInUserInfo.eMailAddress = null; //not stored locally
                            response.loggedInUserInfo.employeeId = row.employeeId;
                            response.loggedInUserInfo.name = row.name;
                            response.loggedInUserInfo.surname = row.surname;
                            response.loggedInUserInfo.telephoneNumber = null; //not stored locally
                            response.loggedInUserInfo.userId = row.userId;
                            response.loggedInUserInfo.username = row.username;
                        }
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getCompanies(
                companyIdToLoad,
                function(resultSet){
                    response.companies = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var company={};
                        company.addresses = []; //todo
                        company.companyId = row.companyId;
                        company.companyName = row.companyName;
                        company.eMailAddress = row.eMailAddress;
                        company.fiscalCode = row.fiscalCode;
                        company.isContractor = row.isContractor;
                        company.isCustomer = row.isCustomer;
                        company.isEditable = row.isEditable;
                        company.privateVersion = row.privateVersion;
                        company.publicVersion = row.publicVersion;
                        company.telephoneNumber = row.telephoneNumber;
                        company.vatNumber = row.vatNumber;
                        response.companies.push(company);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getUserPermissions(
                companyIdToLoad,
                function(resultSet){
                    var companyNameToLoad=null;
                    response.companies.forEach(function(company){
                        if(company.companyId==companyIdToLoad){
                            companyNameToLoad=company.companyName;
                        }
                    });
                    response.userPermissions = [];
                    var userPermissionsCompany={};
                    userPermissionsCompany.companyId=companyIdToLoad;
                    userPermissionsCompany.companyName=companyNameToLoad;
                    userPermissionsCompany.permissions=[];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var userPermission={};
                        userPermission.companyId = row.companyId;
                        userPermission.permissionCode = row.permissionCode;
                        userPermission.permissionLevel = row.permissionLevel;
                        userPermissionsCompany.permissions.push(userPermission);
                    }
                    response.userPermissions.push(userPermissionsCompany);
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getAnomalyLevels(
                companyIdToLoad,
                function(resultSet){
                    response.anomalyLevels = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        response.anomalyLevels.push(row.anomalyLevel);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getBuildingGroups(
                companyIdToLoad,
                function(resultSet){
                    response.buildingsGroups = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var buildingsGroup={};
                        buildingsGroup.associatedCompanies = row.associatedCompanies.split(",");
                        buildingsGroup.buildingGroupId = row.buildingGroupId;
                        buildingsGroup.name = row.name;
                        response.buildingsGroups.push(buildingsGroup);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getBuildings(
                companyIdToLoad,
                function(resultSet){
                    response.buildings = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var building={};
                        building.associatedCompanies = row.associatedCompanies.split(",");
                        building.buildingId = row.buildingId;
                        building.buildingsGroup = row.buildingGroupId;
                        building.name = row.name;
                        response.buildings.push(building);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getCadastralCategories(
                companyIdToLoad,
                function(resultSet){
                    response.cadastralCategories = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var cadastralCategory={};
                        cadastralCategory.categoryName = row.categoryName;
                        cadastralCategory.description = row.description;
                        response.cadastralCategories.push(cadastralCategory);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getCategoryGroups(
                companyIdToLoad,
                function(resultSet){
                    response.categoryTree.categoryGroups = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var categoryGroup={};
                        categoryGroup.background = row.background;
                        categoryGroup.categoryGroupId = row.categoryGroupId;
                        categoryGroup.icon = row.icon;
                        response.categoryTree.categoryGroups.push(categoryGroup);
                    }
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            console.log("companyIdToLoad:"+companyIdToLoad);
            db.getCategories(
                companyIdToLoad,
                function(resultSet){
                    response.categoryTree.categories = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var category={};
                        category.background = row.background;
                        category.categoryId = row.categoryId;
                        category.categoryGroupId = row.categoryGroupId;
                        category.icon = row.icon;
                        category.name = row.name;
                        category.deletion = null; //TODO: manage offline category deletion
                        response.categoryTree.categories.push(category);
                    }
                    console.log("categories loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getSubcategories(
                companyIdToLoad,
                function(resultSet){
                    response.categoryTree.subcategories = [];
                    console.log("loading "+resultSet.rows.length+" subcategories");
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var subcategory={};
                        subcategory.subcategoryId = row.subcategoryId;
                        subcategory.categoryId = row.categoryId;
                        subcategory.categoryGroupId = row.categoryGroupId;
                        subcategory.icon = row.icon;
                        subcategory.name = row.name;
                        subcategory.background = row.background;
                        subcategory.deletion = null;
                        response.categoryTree.subcategories.push(subcategory);
                    }
                    console.log("subcategories loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function() {
            db.getSubcategoryGroups(
                companyIdToLoad,
                function (resultSet) {
                    response.categoryTree.subcategoryGroups = [];
                    console.log("loading "+resultSet.rows.length+" subcategory group");
                    for (var i = 0; i < resultSet.rows.length; i++) {
                        var row = resultSet.rows.item(i);
                        var subcategoryGroup = {};
                        subcategoryGroup.subcategoryGroupId = row.subcategoryGroupId;
                        subcategoryGroup.name = row.name;
                        response.categoryTree.subcategoryGroups.push(subcategoryGroup);
                    }
                    console.log("subcategory group loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getDocumentTypes(
                companyIdToLoad,
                function(resultSet){
                    response.documentTypes = [];
                    console.log("loading "+resultSet.rows.length+" document types");
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var documentType={};
                        documentType.backgroundColor = row.backgroundColor;
                        documentType.documentType = row.documentType;
                        documentType.documentTypeId = row.documentTypeId;
                        documentType.textColor = row.textColor;
                        documentType.subtypes = []; //todo
                        response.documentTypes.push(documentType);
                    }
                    console.log("document types loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getDocumentTags(
                companyIdToLoad,
                function(resultSet){
                    response.documentTags = [];
                    console.log("loading "+resultSet.rows.length+" document tags");
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var documentTag={};
                        documentTag.tag = row.tag;
                        response.documentTags.push(documentTag);
                    }
                    console.log("document tags loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getFloors(
                companyIdToLoad,
                function(resultSet){
                    response.floors = [];
                    console.log("loading "+resultSet.rows.length+" floors");
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var floor={};
                        floor.floorName = row.floorName;
                        floor.floorId = row.floorId;
                        floor.siteId = row.siteId;
                        response.floors.push(floor);
                    }
                    console.log("floors loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getFrequencyTypes(
                companyIdToLoad,
                function(resultSet){
                    response.frequencyTypes = [];
                    console.log("loading "+resultSet.rows.length+" frequency types");
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        console.log(row);
                        var frequencyType={};
                        frequencyType.frequencyType = row.frequencyType;
                        response.frequencyTypes.push(frequencyType);
                        console.log(response.frequencyTypes);
                    }
                    console.log("frequency types loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
        try {
            db.getInterventions(
                companyIdToLoad,
                function(resultSet) {
                    response.interventions = [];
                    console.log("loading "+resultSet.rows.length+" interventions");
                    for(var i = 0; i < resultSet.rows.length; i++) {
                        var row = resultSet.rows.item(i);
                        if(!response.interventions[row.interventionId]) {
                            var intervention = {};
                            intervention.activities = [];
                            intervention.executionTeam = [];//todo
                            intervention.relatedPictures = [];//todo
                            intervention.executor = row.executorName;
                            intervention.executorName = row.executorName;
                            intervention.admittableDates = {};
                            intervention.admittableDates.start = null;
                            intervention.admittableDates.end = null;
                            intervention.admittableDates.isoStart = null;
                            intervention.admittableDates.isoEnd = null;
                            intervention.interventionId = row.interventionId;
                            intervention.parentInterventionId = row.parentInterventionId;
                            intervention.followingDayIntervention = row.followingDayIntervention;
                            intervention.previousDayIntervention = row.previousDayIntervention;
                            intervention.completed = (row.completed==1);
                            intervention.contractor = row.contractor;
                            intervention.executorId = row.executorId;
                            intervention.executorNotificationsSent = row.executorNotificationsSent;
                            intervention.fireLogBookNumber = row.fireLogBookNumber;
                            intervention.interventionExecutionNotes = row.interventionExecutionNotes;
                            intervention.isoStartDate = row.startDate;
                            intervention.isoEndDate = row.endDate;
                            intervention.isoPlannedStartDate = row.plannedStartDate;
                            intervention.isoPlannedEndDate = row.plannedEndDate;
                            intervention.siteId = row.siteId;
                            intervention.siteName = row.siteName;
                            intervention.siteStreet = row.siteStreet;
                            intervention.siteStreetNumber = row.siteStreetNumber;
                            intervention.siteCity = row.siteCity;
                            intervention.siteDistrict = row.siteDistrict;
                            intervention.siteName = row.siteName;
                            intervention.isoValidationDatetime = row.validationDatetime;
                            intervention.validationRequested = !!row.validationRequested;
                            intervention.validatorUsername = row.validatorUsername;
                            intervention.weather = row.weather;
                            intervention.weatherDateTime = row.weatherDateTime;
                            intervention.windSpeed = row.windSpeed;
                            intervention.validatorUsername = row.validatorUsername;
                            intervention.letter = row.siteLetter;
                            intervention.outcome = row.outcomeName;
                            intervention.activitiesCompleted = 0; //TODO: fetch correct value from local DB
                            intervention.manualOrderNumber = null;//obsolete
                            response.interventions[row.interventionId] = intervention;
                        }
                        if(!localDbFetchedInterventionContainsActivity(response.interventions[row.interventionId], row.activityId)) {
                            var activity = {};
                            activity.interventionActivityId = row.interventionActivityId;
                            activity.activityOnAssetType = row.activityOnAssetType;
                            activity.relatedTicket = row.relatedTicket;
                            activity.activityIsTicket = !(row.relatedTicket==null);
                            activity.activityCompleted = false; //TODO: fetch correct value from local DB
                            activity.containsTicket = !(row.relatedTicket==null);
                            activity.relatedContractActivity = row.relatedContractActivity;
                            activity.admittableExecutionStart = row.admittableExecutionStart;
                            activity.admittableExecutionEnd = row.admittableExecutionEnd;
                            activity.activityDescription = row.activityDescription;
                            activity.isoRelatedTicketDate = null;//todo
                            activity.subcategoryIcon=row.subcategoryIcon;
                            activity.subcategoryId=row.subcategoryId;
                            activity.subcategory=row.subcategory;
                            activity.category=row.category;
                            activity.categoryIcon=row.categoryIcon;
                            activity.categoryIconBackground=row.categoryIconBackground;
                            activity.categoryGroupId=row.categoryGroupId;
                            activity.categoryId=row.categoryId;
                            intervention.activities.push(activity);
                        }
                    }
                    var finalInterventions = [];
                    for(intervention in response.interventions) {
                        finalInterventions.push(response.interventions[intervention]);
                    }
                    response.interventions = finalInterventions;
                    console.log("interventions loaded");
                    executeNextLocalDbOperation();
                }
            );
        }catch(e){
            console.error(e);
        }
        },
        function(){
            db.getOutcomes(
                companyIdToLoad,
                function(resultSet){
                    response.outcomes = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var outcome={};
                        outcome.outcomeId = row.outcomeId;
                        outcome.name = row.name;
                        response.outcomes.push(outcome);
                    }
                    console.log("outcomes loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getAssetInstances(
                companyIdToLoad,
                function(resultSet){
                    response.assetInstances = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        if(!response.assetInstances[row.assetInstanceId]){
                            var assetInstance={};
                            assetInstance.assetInstanceId = row.assetInstanceId;
                            assetInstance.description = null; //TODO: need to add field to DB
                            assetInstance.brand = row.brand;
                            assetInstance.certification = row.certification;
                            assetInstance.constructionYear = row.constructionYear;
                            assetInstance.creationCausal = row.creationCausal;
                            assetInstance.floor = row.floor;
                            assetInstance.homologation = row.homologation;
                            assetInstance.isoInstallationDate = row.installationDate;
                            assetInstance.internalCode = row.internalCode;
                            assetInstance.model = row.model;
                            assetInstance.positionHint = row.positionHint;
                            assetInstance.productionNumber = row.productionNumber;
                            assetInstance.qrCodeId = row.qrCode;
                            assetInstance.registrationNumber = row.registrationNumber;
                            assetInstance.site = row.siteId;
                            assetInstance.siteName = row.siteName;
                            assetInstance.letter = row.siteLetter;
                            assetInstance.size = row.size;
                            assetInstance.subcategories = [];
                            assetInstance.type = row.type;
                            assetInstance.deletion = null; // We don't store deleted asset in localDB
                            response.assetInstances[row.assetInstanceId] = assetInstance;
                        }
                        var subcategory = {};
                        subcategory.categoryIcon=row.categoryIcon;
                        subcategory.categoryIconBackground=row.background;
                        subcategory.categoryName=row.categoryName;
                        subcategory.subcategoryIcon=row.subcategoryIcon;
                        subcategory.subcategoryId=row.subcategoryId;
                        subcategory.subcategoryName=row.subcategoryName;
                        subcategory.deletion=null; // We don't store deleted asset in localDB
                        response.assetInstances[row.assetInstanceId].subcategories.push(subcategory);
                    }
                    var finalAssetInstances=[];
                    for (assetInstance in response.assetInstances ){
                        finalAssetInstances.push(response.assetInstances[assetInstance]);
                    }
                    response.assetInstances = finalAssetInstances;
                    console.log("asset instances loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getQrCodes(
                companyIdToLoad,
                function(resultSet){
                    response.qrCodes = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var qrCode={};
                        qrCode.qrCode = row.qrCode;
                        qrCode.isoCreation = row.creation;
                        qrCode.relatedAsset = row.relatedAsset;
                        qrCode.relatedIntervention = row.relatedIntervention;
                        qrCode.relatedTicket = row.relatedTicket;
                        response.qrCodes.push(qrCode);
                    }
                    console.log("qr codes loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getSites(
                companyIdToLoad,
                function(resultSet){
                    response.sites = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var site={};
                        site.buildingId = row.buildingId;
                        site.buildingsGroupId = row.buildingGroupId;
                        site.city = row.city;
                        site.createdBy = null;
                        site.district = row.district;
                        site.isActive = row.isActive;
                        site.isCommon = row.isCommon;
                        site.latitude = row.latitude;
                        site.letter = row.letter;
                        site.longitude = row.longitude;
                        site.name = row.siteName;
                        site.ownedBy = row.ownedBy;
                        site.siteId = row.siteId;
                        site.siteType = row.siteType;
                        site.street = row.street;
                        site.streetNumber = row.streetNumber;
                        response.sites.push(site);
                    }
                    console.log("sites loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getSiteTypes(
                companyIdToLoad,
                function(resultSet){
                    response.siteTypes = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        response.siteTypes.push(row.siteType);
                    }
                    console.log("site types loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function(){
            db.getUnitsOfMeasure(
                companyIdToLoad,
                function(resultSet){
                    response.unitsOfMeasure = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        var unitOfMeasure={};
                        unitOfMeasure.unitName = row.unitName;
                        unitOfMeasure.description = row.description;
                        unitOfMeasure.interventionExecution = row.interventionExecution;
                        unitOfMeasure.accountingSection = row.accountingSection;
                        response.unitsOfMeasure.push(unitOfMeasure);
                    }
                    console.log("units of measure loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function() {
            db.getTicketTypes(
                companyIdToLoad,
                function(resultSet){
                    response.ticketTypes = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        response.ticketTypes.push(row.ticketType);
                    }
                    console.log("ticket types loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function() {
            db.getGlobalUserPermissions(
                function(resultSet){
                    response.globalUserPermissions = [];
                    for(var i=0;i<resultSet.rows.length;i++) {
                        var row=resultSet.rows.item(i);
                        if(response.globalUserPermissions[row.companyId]==undefined) {
                            response.globalUserPermissions[row.companyId]={};
                            response.globalUserPermissions[row.companyId].companyId=row.companyId;
                            response.globalUserPermissions[row.companyId].companyName=row.companyName;
                            response.globalUserPermissions[row.companyId].permissions=[];
                        }
                        var userPermission={};
                        userPermission.permissionCode = row.permissionCode;
                        userPermission.permissionName = ""; //not available locally
                        userPermission.permissionLevel = row.permissionLevel;
                        response.globalUserPermissions[row.companyId].permissions.push(userPermission);
                    }
                    var finalGlobalUserPermissions=[];
                    var globalUserPermission;
                    for (globalUserPermission in response.globalUserPermissions ){
                        finalGlobalUserPermissions.push(response.globalUserPermissions[globalUserPermission]);
                    }
                    response.globalUserPermissions = finalGlobalUserPermissions;
                    console.log(response.globalUserPermissions);
                    console.log("global user permissions loaded");
                    executeNextLocalDbOperation();
                }
            );
        },
        function() {
            try {
                response.assetRelationTypes=[];
                loadInitializationData(response, successCallback, false);
            }
            catch(e) {
                console.error(e);
            }
        }
    ];

    var getDataSyncFromLocalDb = function() {
        //Checking if data is available in the local DB for the requested company
        db.getDataSync(
            companyIdToLoad,
            function(resultSet) {
                if(resultSet.rows.length > 0) {
                    if(resultSet.rows.item(0).lastFullSync !== null) {
                        //Starting fetching data from local database, by tables
                        executeNextLocalDbOperation();
                    }
                    else {
                        errorToast("Azienda non disponibile in modalità offline");
                    }
                }
                else {
                    errorToast("Azienda non disponibile in modalità offline");
                }
            }
        );
    };

    var waitForDb = function() {
        console.log("waiting for DB");
        if(db.localDb!==null){
            getDataSyncFromLocalDb();
        }
        else{
            setTimeout(waitForDb,600);
        }
    };

    var waitForDeviceReady = function() {
        console.log("waiting for device ready");
        if(deviceReady) {
            waitForDb();
        }
        else {
            setTimeout(waitForDeviceReady,600);
        }
    };

    if(!window.sqlitePlugin) {
        waitForDeviceReady();
    }
    else {
        waitForDb();
    }
}

var homePage = {
    refreshCounters: function(){
        var xhttp = new XMLHttpRequest();
        xhttp.timeout = 20000; // time in milliseconds
        var timeoutErrorAbort = function(){
            setTimeout(function(){
                homePage.refreshCounters();
            },5000);
        };
        xhttp.onerror = timeoutErrorAbort;
        xhttp.onabort = timeoutErrorAbort;
        xhttp.ontimeout = timeoutErrorAbort;
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    homePage.refreshLateInterventionsCount(response.lateInterventions);
                    homePage.refreshUnresolvedAnomaliesCount(response.unresolvedAnomaliesCount);
                    homePage.refreshInterventionsToValidateCount(response.interventionsToValidate);
                    homePage.refreshCompanyProblemsCount(response.otherProblems);
                    scroll.refreshByName("home-page");
                }
                else {
                    console.error("Errore durante il refresh contatori in home: codice HTTP "+xhttp.status+", errore: "+response.error);
                }
            }
            catch(e) {
                console.error(e);
                console.error("Errore durante il refresh contatori in home: codice HTTP "+xhttp.status);
                ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, e.message + "<br><br> On line: " + e.lineNumber + "<br><br>" + e.stack + "<br><br>" + xhttp.responseText.substring(0, 1000));
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getHomeCounters.php?companyId=" + globalFilterByCompany, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    },
    refreshLateInterventionsCount: function(lateInterventions){
        var lateInterventionsHtml="";
        var lateInterventionsCount=0;
        lateInterventions.forEach(function(lateIntervention){
            lateInterventionsCount++;

            var interventionActivitiesHtml="";
            var addedSubcategories = [];
            for(j=0;j<lateIntervention.interventionActivities.length;j++) {
                if(!addedSubcategories.includes(lateIntervention.interventionActivities[j].subcategoryIcon)) {
                    addedSubcategories.push(lateIntervention.interventionActivities[j].subcategoryIcon);
                    hasTicket=lateIntervention.interventionActivities[j].containsTicket;
                    interventionActivitiesHtml+="<div class='intervention-subcategory-dots "+(hasTicket?"has-ticket":"")+"'>";
                    interventionActivitiesHtml+="<div class='category-label'><div class='category-small b-"+
                        lateIntervention.interventionActivities[j].categoryIconBackground+"'>"+
                        lateIntervention.interventionActivities[j].categoryIcon+"</div>"+
                        lateIntervention.interventionActivities[j].category+"</div>";
                    if(lateIntervention.interventionActivities[j].subcategoryIcon!=null) {
                        interventionActivitiesHtml+="<div class='subcategory-label'><div class='category-small b-"+
                            lateIntervention.interventionActivities[j].categoryIconBackground+"'>"+
                            lateIntervention.interventionActivities[j].subcategoryIcon+"</div>"+
                            lateIntervention.interventionActivities[j].subcategory+"</div>";
                    }
                    else {
                        interventionActivitiesHtml+="<div class='subcategory-label'>&nbsp;</div>";
                    }
                    if(hasTicket) {
                        interventionActivitiesHtml+="<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>";
                    }
                    interventionActivitiesHtml+="</div>";
                }
            }

            lateInterventionsHtml+="<div class='entry late' onclick='closePopup();selectIntervention("+lateIntervention.interventionId+");'>"+
                "<div class='entry-body'>"+
                "<div class='dots entry-header'>"+
                " "+"<div class='entry-title'>Intervento "+lateIntervention.interventionId+
                "<span class='entry-late-label'>In ritardo<span>"+
                "</div>"+
                "<div class='site-label'><span class='site'>"+lateIntervention.letter+"</span>"+lateIntervention.siteName+"</div>"+
                "<div class='site-label'><span class='site'><i class='fa fa-wrench' aria-hidden='true'></i></span>"+((lateIntervention.executorName===null)?"Non assegnato":lateIntervention.executorName)+"</div>"+
                "<div class='time-label'><span class='clock'><i class='fa fa-calendar' aria-hidden='true'></i></span>"+iso8601.toEuropeanDate(lateIntervention.isoPlannedStartDate,"-")+"</div>"+
                "</div><div class='dots'>"+interventionActivitiesHtml+"</div></div></div></div></div>";
        });
        setInnerHtml(document.getElementById("all-late-interventions-container"),lateInterventionsHtml);
        if(lateInterventionsCount !== undefined && lateInterventionsCount !== null) {
            var homeLateInterventionsCounter = document.getElementById("home-late-interventions-counter");
            if(lateInterventionsCount === 0) {
                setInnerHtml(homeLateInterventionsCounter, "<i class='fa fa-check' aria-hidden='true'></i> Nessun intervento in ritardo");
                dom.addClass(homeLateInterventionsCounter, "b-green");
                dom.removeClass(homeLateInterventionsCounter, "b-red");
            }
            else {
                defineTapListenerOnElement(homeLateInterventionsCounter,function(){
                    setTimeout(function(){popup("all-late-interventions", false, false, false);},200);
                });
                if(lateInterventionsCount === 1) {
                    setInnerHtml(homeLateInterventionsCounter, "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Un intervento è in ritardo");
                }
                else {
                    setInnerHtml(homeLateInterventionsCounter, "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> " + lateInterventionsCount + " interventi in ritardo");
                }
                dom.removeClass(homeLateInterventionsCounter, "b-green");
                dom.addClass(homeLateInterventionsCounter, "b-red");
            }
        }
    },
    refreshUnresolvedAnomaliesCount: function(unresolvedAnomaliesCount){
        if(unresolvedAnomaliesCount !== undefined && unresolvedAnomaliesCount !== null) {
            var homeUnresolvedAnomaliesCounter = document.getElementById("home-anomalies-counter");
            if(unresolvedAnomaliesCount === 0) {
                setInnerHtml(homeUnresolvedAnomaliesCounter, "<i class='fa fa-check' aria-hidden='true'></i> Nessuna nuova segnalazione");
                dom.addClass(homeUnresolvedAnomaliesCounter, "b-green");
                dom.removeClass(homeUnresolvedAnomaliesCounter, "b-red");
            }
            else {
                if(unresolvedAnomaliesCount === 1) {
                    setInnerHtml(homeUnresolvedAnomaliesCounter, "<i class='fa fa-flag' aria-hidden='true'></i> Una segnalazione non risolta");
                }
                else {
                    setInnerHtml(homeUnresolvedAnomaliesCounter, "<i class='fa fa-flag' aria-hidden='true'></i> " + unresolvedAnomaliesCount + " segnalazioni non risolte");
                }
                dom.removeClass(homeUnresolvedAnomaliesCounter, "b-green");
                dom.addClass(homeUnresolvedAnomaliesCounter, "b-yellow");
            }
        }
    },
    refreshInterventionsToValidateCount: function(interventionsToValidate){
        if(interventionsToValidate !== undefined && interventionsToValidate !== null) {
            interventionManager.interventionsToValidate = interventionsToValidate;
            initInterventionsToValidatePopup();
            var homeInterventionsToValidateCounter = document.getElementById("home-to-validate-interventions-counter");
            if(interventionsToValidate.length === 0) {
                setInnerHtml(homeInterventionsToValidateCounter, "<i class='fa fa-check' aria-hidden='true'></i> Nessun intervento da validare");
                dom.addClass(homeInterventionsToValidateCounter, "b-green");
                dom.removeClass(homeInterventionsToValidateCounter, "b-red");
            }
            else {
                defineTapListenerOnElement(homeInterventionsToValidateCounter,renderInterventionsToValidatePopup);
                if(interventionsToValidate.length === 1) {
                    setInnerHtml(homeInterventionsToValidateCounter, "<i class='fa fa-flag' aria-hidden='true'></i> Un intervento da validare");
                }
                else {
                    setInnerHtml(homeInterventionsToValidateCounter, "<i class='fa fa-flag' aria-hidden='true'></i> " + interventionsToValidate.length + " interventi da validare");
                }
                dom.removeClass(homeInterventionsToValidateCounter, "b-green");
                dom.addClass(homeInterventionsToValidateCounter, "b-yellow");
            }
        }
    },
    refreshCompanyProblemsCount: function(companyProblems){
        if(companyProblems !== undefined && companyProblems !== null) {
            companyProblemsPopup.companyProblems = companyProblems;
            companyProblemsPopup.init();
            var homeCompanyProblemsCounter = document.getElementById("home-company-problems-counter");
            if(companyProblems.length === 0) {
                setInnerHtml(homeCompanyProblemsCounter, "<i class='fa fa-check' aria-hidden='true'></i> Nessun problema organizzativo");
                dom.addClass(homeCompanyProblemsCounter, "b-green");
                dom.removeClass(homeCompanyProblemsCounter, "b-red");
            }
            else {
                defineTapListenerOnElement(homeCompanyProblemsCounter,companyProblemsPopup.open);
                if(companyProblems.length === 1) {
                    setInnerHtml(homeCompanyProblemsCounter, "<i class='fa fa-flag' aria-hidden='true'></i> Un problema organizzativo rilevato");
                }
                else {
                    setInnerHtml(homeCompanyProblemsCounter, "<i class='fa fa-flag' aria-hidden='true'></i> " + companyProblems.length + " problemi organizzativi rilevati");
                }
                dom.removeClass(homeCompanyProblemsCounter, "b-green");
                dom.addClass(homeCompanyProblemsCounter, "b-yellow");
            }
        }
    }
};

var maintenancePage = {
    refresh: function() {
        document.getElementById("entries-container").innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
        scroll.refreshByName("maintenance");
        var xhttp = new XMLHttpRequest();
        xhttp.timeout = 9000; // time in milliseconds
        var timeoutErrorAbort = function() {
            maintenancePage.draw();
        };
        xhttp.ontimeout = timeoutErrorAbort;
        xhttp.onerror = timeoutErrorAbort;
        xhttp.onabort = timeoutErrorAbort;
        xhttp.onload = function() {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                interventionManager.offlineInterventions = response.interventions;
                maintenancePage.draw();
            }
            else {
                timeoutErrorAbort();
            }
        };
        if(globalFilterByCompany) {
            xhttp.open("GET", BASE_URL + "/ws/getFollowingDaysInterventions.php?companyId=" + globalFilterByCompany, true);
        }
        else {
            xhttp.open("GET", BASE_URL + "/ws/getFollowingDaysInterventions.php", true);
        }
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    },
    draw: function() {
        var tempHtml = "";
        var interventionsCount = 0;
        interventionManager.offlineInterventions.sort(function(a, b) {
            var aDate = iso8601.toDateObject(a.isoPlannedStartDate);
            var bDate = iso8601.toDateObject(b.isoPlannedStartDate);
            var returnValue = ((aDate.getTime()) - (bDate.getTime()));
            if(parseInt(returnValue) < 0) {
                return -1;
            }
            if(parseInt(returnValue) > 0) {
                return 1;
            }
            return 0;
        });
        var lastDate = null;
        for(var i = 0; i < interventionManager.offlineInterventions.length; i++) {
            if(appliedFilters["site"] === null || appliedFilters["site"] == interventionManager.offlineInterventions[i].siteId) {
                var intervActvities = interventionManager.offlineInterventions[i].activities;
                var tempHtml2 = "";
                for(var j = 0; j < intervActvities.length; j++) {
                    if(appliedFilters["category"] === null || appliedFilters["category"] == intervActvities[j].categoryId) {
                        if(appliedFilters["subcategory"] == null || appliedFilters["subcategory"] == intervActvities[j].subcategoryId) {
                            var newDate = iso8601.toUsDate(interventionManager.offlineInterventions[i].isoPlannedStartDate);
                            if(newDate != lastDate) {
                                lastDate = newDate;
                                tmpDate = parseDate(lastDate);
                                tempHtml += "<span class='next-interventions-date'>" + weekday[tmpDate.getDay()] + " " + tmpDate.getDate() + " " + monthNames[tmpDate.getMonth()] + " " + tmpDate.getFullYear() + "</span>";
                            }
                            tempHtml2 += "<div class='entry-body'><div class='dots'>";
                            tempHtml2 += "<div class='category-label'><div class='category-small b-" + intervActvities[j].categoryIconBackground + "'>" + intervActvities[j].categoryIcon + "</div>" +
                                intervActvities[j].category + "</div>";
                            if(intervActvities[j].subcategory != null) {
                                tempHtml2 += "<div class='subcategory-label'><div class='category-small b-" +
                                    intervActvities[j].categoryIconBackground + "'>" + intervActvities[j].subcategoryIcon + "</div>" +
                                    intervActvities[j].subcategory + "</div></div></div>";
                            }
                            else {
                                tempHtml2 += "<div class='subcategory-label'></div></div></div>";
                            }
                            interventionsCount++;
                        }
                    }
                }
                if(tempHtml2 !== "") {
                    tempHtml += renderInterventionEntry(interventionManager.offlineInterventions[i],false);
                }
            }
        }
        if(interventionsCount === 0) {
            tempHtml = "<div class='no-results-message'>Nessun intervento nei prossimi giorni corrisponde ai filtri applicati</div>";
        }
        if(permissions["SECTION_CALENDAR"] === "ACCESSIBLE") {
            tempHtml += "<div class='calendar-tip-link'>Per visualizzare tutti gli interventi usa il <button type='button' onclick='changePage(\"page-calendar\");'><i class='fa fa-calendar' aria-hidden='true'></i> Calendario</button></div>";
        }
        else {
            tempHtml += "<div class='calendar-tip-link'>Tutti gli interventi potrebbero essere visualizzati utilizzando il <strong>Calendario</strong></div>";
        }
        document.getElementById("entries-container").innerHTML = tempHtml;
        scroll.refreshByName("maintenance");
    },
    map: {
        setSite: function(siteId) {
            var mapAddressZone = document.getElementById("map-address");
            if(siteId !== null) {
                focusSiteOnMap(siteId);
                for(var i = 0; i < sites.length; i++)
                    if(sites[i].siteId == siteId) {
                        mapAddressZone.innerHTML = "<div id='map-address-container'>" +
                            "<strong>" + sites[i].name + " </strong><br>" +
                            sites[i].street + ", " + sites[i].streetNumber + "  " +
                            sites[i].city + " (" + sites[i].district + ")" +
                            "</div>" +
                            "<button id='map-popup-button' onclick='popup( \"map-viewer\" ,false,false,false)'><i class='fa fa-map' aria-hidden='true'></i></button>";
                        appliedFilters.site = siteId;
                        break;
                    }
            }
            else {
                mapAddressZone.innerHTML = "<div id='map-address-container' class='not-applied'>Nessun filtro per sito applicato</div>" +
                    "<button id='map-popup-button' onclick='popup( \"map-viewer\" ,false,false,false)'><i class='fa fa-map' aria-hidden='true'></i></button>";

                if(lastPushPinSelected != null) {
                    lastPushPinSelected.setOptions({icon: imgPushpin, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
                }
                try {
                    centerMapGlobally();
                }
                catch(e) {
                    console.warn(e);
                }
                appliedFilters['site'] = null;
            }
        }
    },
    meteo: {
        setSite:function(siteId) {
            if(siteId !== null) {
                var meteoContainer = document.getElementById("right-meteo");
                var tempHtml = "<div id='meteo-header'>";
                var meteoDates = maintenancePage.meteo.getDatesToBeShown();
                for(var j = 0; j < meteoDates.length; j++) {
                    if(j % 2 === 0) {
                        tempHtml += "<div class='meteo-day'><div class='meteo-day-header'>";
                        switch(j) {
                            case 0:
                                tempHtml += "Oggi - "+weekday[today.getDay()].substring(0, 3) + " " + today.getUTCDate();
                                break;
                            case 2:
                                tempHtml += weekday[tomorrow.getDay()].substring(0, 3) + " " + tomorrow.getUTCDate();
                                break;
                            case 4:
                                tempHtml += weekday[afterTomorrow.getDay()].substring(0, 3) + " " + afterTomorrow.getUTCDate();
                                break;
                        }
                        tempHtml += "</div>";
                    }
                    var found = 0;
                    for(var i = 0; i < meteo.length; i++) {
                        if(meteo[i].siteId == siteId) {
                            if(meteo[i].dateTime == meteoDates[j]) {
                                tempHtml+=renderMeteoIcon(meteo[i].weather);
                                found = 1;
                                break;
                            }
                        }
                    }
                    if(!found) {
                        tempHtml += "<img src='"+imgNoData+"'>";
                    }
                    if((j + 1) % 2 === 0) {
                        tempHtml += "</div>";
                    }
                }
                meteoContainer.innerHTML = tempHtml;
                document.getElementById("right-meteo").style.display = "block";
            }
            else {
                document.getElementById("right-meteo").style.display = "none";
            }
        },
        getDatesToBeShown:function() {
            var dates = [];
            dates[0] = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2) + " 09:00:00";
            dates[1] = today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2) + " 15:00:00";
            dates[2] = tomorrow.getFullYear() + "-" + ("0" + (tomorrow.getMonth() + 1)).slice(-2) + "-" + ("0" + tomorrow.getDate()).slice(-2) + " 09:00:00";
            dates[3] = tomorrow.getFullYear() + "-" + ("0" + (tomorrow.getMonth() + 1)).slice(-2) + "-" + ("0" + tomorrow.getDate()).slice(-2) + " 15:00:00";
            dates[4] = afterTomorrow.getFullYear() + "-" + ("0" + (afterTomorrow.getMonth() + 1)).slice(-2) + "-" + ("0" + afterTomorrow.getDate()).slice(-2) + " 09:00:00";
            dates[5] = afterTomorrow.getFullYear() + "-" + ("0" + (afterTomorrow.getMonth() + 1)).slice(-2) + "-" + ("0" + afterTomorrow.getDate()).slice(-2) + " 15:00:00";
            return dates;
        }
    },
    //used only when logging out
    forceSiteFilterUnset:function() {
        maintenancePage.map.setSite(null);
        maintenancePage.meteo.setSite(null);
        maintenancePage.refresh();
    },
    setSiteFilter:function(siteId) {
        if(siteId === "") {
            siteId = null;
        }
        maintenancePage.map.setSite(siteId);
        if(sites.length==1) {
            maintenancePage.meteo.setSite(sites[0].siteId);
        }
        else {
            maintenancePage.meteo.setSite(siteId);
        }
        maintenancePage.refresh();
    },
    refreshSitesFilters: function() {
        var tempHtml = "";
        if(sites.length==1) {
            //Setting an empty value allows to show no filter applied, since there's only one site available
            tempHtml = "<option value=''>" + sites[0].name + "</option>";
            //maintenancePage.map.setSite(sites[0].siteId);
            setTimeout(
                function() {
                    maintenancePage.meteo.setSite(sites[0].siteId);
                },
                2000
            );

        }
        else {
            for(var i = 0; i < sites.length; i++) {
                tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
            }
            tempHtml += "<option value='' selected='selected'>-- Tutti --</option>";
        }
        setInnerHtml(document.getElementById("maintenance-site-selector"),tempHtml);
    }
};
function getCertificationState(certification){
    var certificationState = "";
    if((new Date(certification.expiration))<(new Date())){
        certificationState = "<span style='color:#b13121;'>Scaduto</span>";
    }
    else{
        if(certification.approved == null){
            certificationState = "<span style='color:#b13121;'>Da approvare</span>";
        }
        else{
            certificationState = "<span style='color:#368f39;'>Valido e approvato</span>";
        }
    }
    return certificationState;
}
var safetyControlPage = {
    deleteCompanyCertification: function(companyCertificationId){
        var requestData = {};
        requestData.companyCertificationId = companyCertificationId;
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    safetyControlPage.refresh();
                    successToast("Il documento è stato eliminato");
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/companyCertification/delete.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestData));
    },
    refresh: function() {
        var companyId = document.getElementById("safety-control-company-selector").value;
        if (companyId != "") {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function () {
                try {
                    var toBeValidatedDocuments = [];
                    var atLeastOneValidDocuments = [];
                    var certificationsNames = ['DURC','DUVRI','Visura camerale','Assicurazione','Quietanza premio'];
                    certificationsNames.forEach(function(certificationName){
                        toBeValidatedDocuments[certificationName]=0;
                        atLeastOneValidDocuments[certificationName]=0;
                    });
                    var response = JSON.parse(xhttp.responseText);
                    if (xhttp.status === 200) {
                        tempHtml="";
                        /* DUVRI */
                        tempHtml += "<h2>DUVRI</h2>";
                        var duvriSites=[];
                        response.company.certifications.forEach(function (certification) {
                            if (certification.certificationName == "DUVRI" && certification.deletion==null) {
                                if(!duvriSites.includes(certification.companyCertificationSiteName)){
                                    var duvriSite={
                                        siteId: certification.companyCertificationSiteId,
                                        name: certification.companyCertificationSiteName
                                    };
                                    duvriSites.push(duvriSite);
                                }
                            }
                        });
                        duvriSites.forEach(function(duvriSite){
                            tempHtml += "<h3>"+duvriSite.name+"</h3>";
                            response.company.certifications.forEach(function (certification) {
                                if (certification.certificationName == "DUVRI" && certification.deletion == null && certification.companyCertificationSiteId == duvriSite.siteId) {
                                    tempHtml += "<div class='entry'>" +
                                        "<h3>" + renderDocumentIcon(certification.fileFormat, false) + " DUVRI " + getCertificationState(certification) + "</h3>" +
                                        "<strong>Creazione</strong>: " + iso8601.toEuropeanDate(certification.creation,"-") + "<br>" +
                                        "<strong>Approvazione</strong>: " + iso8601.toEuropeanDate(certification.approved,"-") + "<br>" +
                                        "<strong>Scadenza</strong>: " + iso8601.toEuropeanDate(certification.expiration,"-") + "<br><br>" +
                                        "<button type='button' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='fa fa-download' aria-hidden='true'></i> Scarica</button> ";
                                    toBeValidatedDocuments[certification.certificationName]+=((certification.approved==null)?1:0);
                                    atLeastOneValidDocuments[certification.certificationName]+=((getCertificationState(certification).includes("Valido e approvato"))?1:0);
                                    if (permissions["APPROVE_DUVRI"] && certification.approved == null) {
                                        tempHtml += "<button type='button' class='bg-green' onclick='ajaxApproveCompanyDocument(" + certification.companyCertificationId + ");'><i class='fa fa-check' aria-hidden='true'></i> Approva</button> ";
                                    }
                                    if (loggedInUserInfo.company == companyId) {
                                        tempHtml += "<button type='button' class='bg-red text-white' onclick='safetyControlPage.deleteCompanyCertification(" + certification.companyCertificationId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                                    }
                                    tempHtml += "<br><br></div>";
                                }
                            });
                        });

                        tempHtml += "" +
                            "<div><button type='button' onclick='addCompanyCertification(\"DUVRI\");' class='bg-green'>" +
                            "<i class='fa fa-upload' aria-hidden='true'></i> Carica DUVRI" +
                            "</button></div>" +
                            "<br>";

                        /* DURC */
                        tempHtml += "<h2>DURC</h2>";
                        response.company.certifications.forEach(function (certification) {
                            if (certification.certificationName == "DURC" && certification.deletion==null) {
                                tempHtml += "<div class='entry'>" +
                                    "<h3>" + renderDocumentIcon(certification.fileFormat, false) + " DURC " + getCertificationState(certification) + "</h3>" +
                                    "<strong>Creazione</strong>: " + iso8601.toEuropeanDate(certification.creation,"-") + "<br>" +
                                    "<strong>Approvazione</strong>: " + iso8601.toEuropeanDate(certification.approved,"-") + "<br>" +
                                    "<strong>Scadenza</strong>: " + iso8601.toEuropeanDate(certification.expiration,"-") + "<br><br>" +
                                    "<button type='button' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='fa fa-download' aria-hidden='true'></i> Scarica</button> ";
                                toBeValidatedDocuments[certification.certificationName]+=((certification.approved==null)?1:0);
                                atLeastOneValidDocuments[certification.certificationName]+=((getCertificationState(certification).includes("Valido e approvato"))?1:0);
                                if (permissions["APPROVE_DURC"] && certification.approved == null) {
                                    tempHtml += "<button type='button' class='bg-green' onclick='ajaxApproveCompanyDocument(" + certification.companyCertificationId + ");'><i class='fa fa-check' aria-hidden='true'></i> Approva</button> ";
                                }
                                if (loggedInUserInfo.company == companyId) {
                                    tempHtml += "<button type='button' class='bg-red text-white' onclick='safetyControlPage.deleteCompanyCertification(" + certification.companyCertificationId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                                }
                                tempHtml += "<br><br></div>";
                            }
                        });
                        tempHtml += "" +
                            "<div><button type='button' onclick='addCompanyCertification(\"DURC\");' class='bg-green'>" +
                            "<i class='fa fa-upload' aria-hidden='true'></i> Carica DURC" +
                            "</button></div>" +
                            "<br>";

                        /*VISURA CAMERALE*/
                        tempHtml += "<h2>Visura camerale</h2>";
                        response.company.certifications.forEach(function (certification) {
                            if (certification.certificationName == "Visura camerale" && certification.deletion==null) {
                                tempHtml += "<div class='entry'>" +
                                    "<h3>" + renderDocumentIcon(certification.fileFormat, false) + " Visura camerale " + getCertificationState(certification) + "</h3>" +
                                    "<strong>Creazione</strong>: " + iso8601.toEuropeanDate(certification.creation,"-") + "<br>" +
                                    "<strong>Approvazione</strong>: " + iso8601.toEuropeanDate(certification.approved,"-") + "<br>" +
                                    "<strong>Scadenza</strong>: " + iso8601.toEuropeanDate(certification.expiration,"-") + "<br><br>" +
                                    "<button type='button' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='fa fa-download' aria-hidden='true'></i> Scarica</button> ";
                                toBeValidatedDocuments[certification.certificationName]+=((certification.approved==null)?1:0);
                                atLeastOneValidDocuments[certification.certificationName]+=((getCertificationState(certification).includes("Valido e approvato"))?1:0);
                                if (permissions["APPROVE_VISURA_CAMERALE"] && certification.approved == null) {
                                    tempHtml += "<button type='button' class='bg-green' onclick='ajaxApproveCompanyDocument(" + certification.companyCertificationId + ");'><i class='fa fa-check' aria-hidden='true'></i> Approva</button> ";
                                }
                                if (loggedInUserInfo.company == companyId) {
                                    tempHtml += "<button type='button' class='bg-red text-white' onclick='safetyControlPage.deleteCompanyCertification(" + certification.companyCertificationId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                                }
                                tempHtml += "<br><br></div>";
                            }
                        });
                        tempHtml += "" +
                            "<div><button type='button' onclick='addCompanyCertification(\"Visura camerale\");' class='bg-green'>" +
                            "<i class='fa fa-upload' aria-hidden='true'></i> Carica visura camerale" +
                            "</button></div>" +
                            "<br>";

                        /*ASSICURAZIONE*/
                        tempHtml += "<h2>Assicurazione</h2>";
                        response.company.certifications.forEach(function (certification) {
                            if (certification.certificationName == "Assicurazione" && certification.deletion==null) {
                                tempHtml += "<div class='entry'>" +
                                    "<h3>" + renderDocumentIcon(certification.fileFormat, false) + " Assicurazione " + getCertificationState(certification) + "</h3>" +
                                    "<strong>Creazione</strong>: " + iso8601.toEuropeanDate(certification.creation,"-") + "<br>" +
                                    "<strong>Massimale</strong>: " + ((certification.maxInsurance==null)?"-":certification.maxInsurance) + "<br>" +
                                    "<strong>Approvazione</strong>: " + iso8601.toEuropeanDate(certification.approved,"-") + "<br>" +
                                    "<strong>Scadenza</strong>: " + iso8601.toEuropeanDate(certification.expiration,"-") + "<br><br>" +
                                    "<button type='button' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='fa fa-download' aria-hidden='true'></i> Scarica</button> ";
                                toBeValidatedDocuments[certification.certificationName]+=((certification.approved==null)?1:0);
                                atLeastOneValidDocuments[certification.certificationName]+=((getCertificationState(certification).includes("Valido e approvato"))?1:0);
                                if (permissions["APPROVE_ASSICURAZIONE"] && certification.approved == null) {
                                    tempHtml += "<button type='button' class='bg-green' onclick='ajaxApproveCompanyDocument(" + certification.companyCertificationId + ");'><i class='fa fa-check' aria-hidden='true'></i> Approva</button> ";
                                }
                                if (loggedInUserInfo.company == companyId) {
                                    tempHtml += "<button type='button' class='bg-red text-white' onclick='safetyControlPage.deleteCompanyCertification(" + certification.companyCertificationId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                                }
                                tempHtml += "<br><br></div>";
                            }
                        });
                        tempHtml += "" +
                            "<div><button type='button' onclick='addCompanyCertification(\"Assicurazione\");' class='bg-green'>" +
                            "<i class='fa fa-upload' aria-hidden='true'></i> Carica assicurazione" +
                            "</button></div>" +
                            "<br>";

                        /*QUIETANZA PREMIO*/
                        tempHtml += "<h2>Quietanza premio</h2>";
                        response.company.certifications.forEach(function (certification) {
                            if (certification.certificationName == "Quietanza premio" && certification.deletion==null) {
                                tempHtml += "<div class='entry'>" +
                                    "<h3>" + renderDocumentIcon(certification.fileFormat, false) + " Quietanza premio " + getCertificationState(certification) + "</h3>" +
                                    "<strong>Creazione</strong>: " + iso8601.toEuropeanDate(certification.creation,"-") + "<br>" +
                                    "<strong>Approvazione</strong>: " + iso8601.toEuropeanDate(certification.approved,"-") + "<br>" +
                                    "<strong>Scadenza</strong>: " + iso8601.toEuropeanDate(certification.expiration,"-") + "<br><br>" +
                                    "<button type='button' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='fa fa-download' aria-hidden='true'></i> Scarica</button> ";
                                toBeValidatedDocuments[certification.certificationName]+=((certification.approved==null)?1:0);
                                atLeastOneValidDocuments[certification.certificationName]+=((getCertificationState(certification).includes("Valido e approvato"))?1:0);
                                if (permissions["APPROVE_QUIETANZA_PREMIO"] && certification.approved == null) {
                                    tempHtml += "<button type='button' class='bg-green' onclick='ajaxApproveCompanyDocument(" + certification.companyCertificationId + ");'><i class='fa fa-check' aria-hidden='true'></i> Approva</button> ";
                                }
                                if (loggedInUserInfo.company == companyId) {
                                    tempHtml += "<button type='button' class='bg-red text-white' onclick='safetyControlPage.deleteCompanyCertification(" + certification.companyCertificationId + ");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                                }
                                tempHtml += "<br><br></div>";
                            }
                        });
                        tempHtml += "" +
                            "<div><button type='button' onclick='addCompanyCertification(\"Quietanza premio\");' class='bg-green'>" +
                            "<i class='fa fa-upload' aria-hidden='true'></i> Carica quietanza premio" +
                            "</button></div>" +
                            "<br>";

                        var tempHtml2="";
                        var notValidDocuments = [];
                        var toBeApprovedDocuments = [];

                        certificationsNames.forEach(function(certificationName) {
                            if(toBeValidatedDocuments[certificationName]>0) {
                                toBeApprovedDocuments.push(certificationName);
                                }
                            else {
                                if(atLeastOneValidDocuments[certificationName]<1) {
                                    notValidDocuments.push(certificationName);
                                }
                            }
                        });

                        if(toBeApprovedDocuments.length>0){
                            tempHtml2 += "<div class='message error'>Documenti in attesa di approvazione: " + toBeApprovedDocuments.join(", ") +". All'azienda potrebbe essere impedito di lavorare sui siti che richiedono questi documenti validi ed approvati.</div>";
                        }

                        if(notValidDocuments.length>0){
                            tempHtml2 += "<div class='message error'>Documenti mancanti o scaduti: " + notValidDocuments.join(", ") +". All'azienda potrebbe essere impedito di lavorare sui siti che richiedono questi documenti validi ed approvati.</div>";
                        }

                        document.getElementById("safety-control-container").innerHTML=tempHtml2+"<br>"+tempHtml;
                        scroll.refreshByName("safety-control");

                        tempHtml="";
                        response.sitesAvailableForDUVRI.forEach(function (availableSite) {
                            tempHtml += "<option value='" + availableSite.siteId + "'>" + availableSite.name + "</option>";
                        });
                        tempHtml+="<option value='' selected>--Selezionare--</option>";
                        document.getElementById("new-company-certification-referred-to-site").innerHTML=tempHtml;
                    }
                    else {
                        errorPopup("Errore durante l'elaborazione: " + response.message);
                    }
                }
                catch (e) {
                    errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
                    console.error(e);
                }
            };
            xhttp.open("GET", BASE_URL + "/ws/getCompany.php?companyId=" + companyId, true);
            xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send();
        }
        else {
            document.getElementById("safety-control-container").innerHTML="<span class='tip'>Per utilizzare questa funzione è necessario selezionare un'<strong>azienda</strong>.</span>";
            scroll.refreshByName("safety-control");
        }
    }
};

var companyProblemsPopup = {
    companyProblems:[],
    init:function(){
        tempHtml="";
        companyProblemsPopup.companyProblems.forEach(
            function(companyProblem){
                tempHtml+="<div class='entry'>"+companyProblem+"</div>";
            }
        );
        setInnerHtml(
            document.getElementById("company-problems-list"),
            tempHtml,
            scroll.refreshPopupById("company-problems", false)
        );
    },
    open(){
        companyProblemsPopup.init();
        popup("company-problems", undefined, undefined, false);
    }
};

function toggleLoggedInUserMenu() {
    var loggedInUserTab = document.getElementById("logged-in-user-tab");
    if(dom.hasClass(loggedInUserTab,"visible")) {
        hideLoggedInUserMenu();
    }
    else {
        dom.addClass(loggedInUserTab,"visible");
    }
}

function hideLoggedInUserMenu() {
    var loggedInUserTab = document.getElementById("logged-in-user-tab");
    dom.removeClass(loggedInUserTab,"visible");
}

function loadInitializationData(response, successCallback, fromInternet) {

    if(fromInternet){
        //The user must change its own password
        if(response.loggedInUserInfo.mustChangePassword) {
            errorToast("La password attuale è scaduta, è necessario cambiarla ora");
            popup("change-password", true, false, true);
        }
        //The user must accept a new version of the privacy policy
        if(response.privacyPolicyVersionToAccept!==null) {
            document.getElementById("new-privacy-policy-version-number").value=response.privacyPolicyVersionToAccept.privacyPolicyVersion;
            document.getElementById("new-privacy-policy-version-text").innerHTML=response.privacyPolicyVersionToAccept.text;
            popup("accept-privacy", true, false, true);
        }
    }

    slaLevels=null;

    //currentCompanyId
    currentCompanyId=response.currentCompanyId;

    //userPermissions
    permissions=[];
    userPermissions = response.userPermissions;
    window.userPermissions = response.userPermissions;
    userPermissions.forEach(function(companyPermissions) {
        if(companyPermissions.companyId === currentCompanyId) {
            companyPermissions.permissions.forEach(function(permission){
                permissions[permission.permissionCode]=permission.permissionLevel;
            });
        }
    });
    if(!userPermissions[0]) {
        ajaxGetDashboardData(successCallback);
        return;
    }
    else {
        globalFilterByCompany = userPermissions[0].companyId;
    }
    setInnerHtml(document.getElementById("contract-customer"), "<option value='" + globalFilterByCompany + "'>" + userPermissions[0].companyName + "</option>");
    setInnerHtml(document.getElementById("global-company-filter-holder"), userPermissions[0].companyName);

    document.getElementById("ticket-details").innerHTML = "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>";
    document.getElementById("anomaly-details").innerHTML = "<div class='no-results-message'>Seleziona una segnalazione per vederne qui i dettagli.</div>";

    //loggedInUserInfo
    if(response.loggedInUserInfo.name && response.loggedInUserInfo.surname) {
        document.getElementById("menu-username-holder").innerHTML = response.loggedInUserInfo.name + " " + response.loggedInUserInfo.surname;
    }
    else {
        document.getElementById("menu-username-holder").innerHTML = response.loggedInUserInfo.username;
    }

    if(response.loggedInUserInfo.profilePictureId){
        document.getElementById("topbar-profile-picture").innerHTML = "<img src='"+DIRECT_DOWNLOAD_URL+"/ws/renderImage.php?imageId="+response.loggedInUserInfo.profilePictureId+"&height=28&ott="+response.loggedInUserInfo.profilePictureOtt+"'>";
    }
    else {
        document.getElementById("topbar-profile-picture").innerHTML = "<i class='fa fa-user-circle' aria-hidden='true'></i>";
    }

    try{ //Cookies disabled
        window.localStorage.setItem("cfm_last_global_filter_by_company", globalFilterByCompany);
    }
    catch(e){
        console.error(e);
    }

    if(fromInternet) {
        document.getElementById("home-late-interventions-counter").innerHTML="...";
        document.getElementById("home-anomalies-counter").innerHTML="...";
        document.getElementById("home-to-validate-interventions-counter").innerHTML="...";
        document.getElementById("home-company-problems-counter").innerHTML="...";

        dom.removeClass(document.getElementById("home-late-interventions-counter"),"hidden");
        dom.removeClass(document.getElementById("home-anomalies-counter"),"hidden");
        dom.removeClass(document.getElementById("home-to-validate-interventions-counter"),"hidden");
        dom.removeClass(document.getElementById("home-company-problems-counter"),"hidden");

        dom.removeClass(document.getElementById("home-late-interventions-counter"),"b-red");
        dom.removeClass(document.getElementById("home-anomalies-counter"),"b-red");
        dom.removeClass(document.getElementById("home-to-validate-interventions-counter"),"b-red");
        dom.removeClass(document.getElementById("home-company-problems-counter"),"b-red");

        dom.removeClass(document.getElementById("home-late-interventions-counter"),"b-yellow");
        dom.removeClass(document.getElementById("home-anomalies-counter"),"b-yellow");
        dom.removeClass(document.getElementById("home-to-validate-interventions-counter"),"b-yellow");
        dom.removeClass(document.getElementById("home-company-problems-counter"),"b-yellow");

        dom.removeClass(document.getElementById("home-late-interventions-counter"),"b-green");
        dom.removeClass(document.getElementById("home-anomalies-counter"),"b-green");
        dom.removeClass(document.getElementById("home-to-validate-interventions-counter"),"b-green");
        dom.removeClass(document.getElementById("home-company-problems-counter"),"b-green");
        if(currentPage==="page-home"){
            homePage.refreshCounters();
        }
    }
    else {
        dom.addClass(document.getElementById("home-late-interventions-counter"),"hidden");
        dom.addClass(document.getElementById("home-anomalies-counter"),"hidden");
        dom.addClass(document.getElementById("home-to-validate-interventions-counter"),"hidden");
        dom.addClass(document.getElementById("home-company-problems-counter"),"hidden");
    }

    fullSearchPopup.clear();

    anomalyLevels = response.anomalyLevels;
    buildingsGroups = response.buildingsGroups;
    buildings = response.buildings;
    cadastralCategories = response.cadastralCategories;
    categoryGroups = response.categoryTree.categoryGroups;
    categories = response.categoryTree.categories;
    subcategories = response.categoryTree.subcategories;
    subcategoryGroups.init(response.categoryTree.subcategoryGroups);
    companies = response.companies;
    companyLicenses = response.companyLicenses;
    documentTypes = response.documentTypes;
    documentTags = response.documentTags;
    floors = response.floors;
    areas = response.areas;
    frequencies = response.frequencyTypes;
    interventionManager.offlineInterventions = response.interventions;
    loggedInUserInfo = response.loggedInUserInfo;
    currentLoggedInUserId = loggedInUserInfo.userId;
    meteo = response.meteo;
    notificationManager.notifications = response.notifications;
    outcomes = response.outcomes;
    permissionLevels = response.permissionLevels;
    permissionTypes = response.permissionTypes;
    preFetchedAssets = response.assetInstances;
    qrCodes = response.qrCodes;
    sites = response.sites;
    siteTypes = response.siteTypes;
    data.unitsOfMeasure = response.unitsOfMeasure;
    assetRelationTypes = response.assetRelationTypes;
    costCenter = response.costCenter;

    certifications.load(response.certificationsNames);
    certifications.setOptionsToSelectById("new-company-certification-name",false);
    certifications.setOptionsToSelectById("add-certification-for-user-certification-name",true);

    //Saving data in the local DB
    if(db.localDb && fromInternet) {

        //Keeping track of how many tables are updated succesfully
        var successfullyUpdatedLocalDbTablesCounter=0;
        var TOTAL_TABLES_TO_SYNC=24;
        function increaseUpdateCounter(){
            successfullyUpdatedLocalDbTablesCounter++;
            console.log("successfullyUpdatedLocalDbTablesCounter: "+successfullyUpdatedLocalDbTablesCounter+"/"+TOTAL_TABLES_TO_SYNC);
            if(successfullyUpdatedLocalDbTablesCounter===TOTAL_TABLES_TO_SYNC){
                db.logDataSync(currentCompanyId);
            }
        }

        db.insertOrUpdateUtilityData("lastUserId",currentLoggedInUserId);
        db.updateTickets(response.tickets, currentCompanyId, increaseUpdateCounter);
        db.updateUserPermissions(response.globalUserPermissions, currentLoggedInUserId, increaseUpdateCounter);
        db.updateAnomalyLevels(response.ticketAnomalyLevels, currentCompanyId, increaseUpdateCounter);
        db.updateTicketTypes(response.ticketTypes, currentCompanyId, increaseUpdateCounter);
        db.updateBuildingsGroups(buildingsGroups, currentCompanyId, increaseUpdateCounter);
        db.updateBuildings(buildings, currentCompanyId, increaseUpdateCounter);
        db.updateCadastralCategories(cadastralCategories, currentCompanyId, increaseUpdateCounter);
        db.updateCategoryGroups(categoryGroups, currentCompanyId, increaseUpdateCounter);
        db.updateCategories(categories, currentCompanyId, increaseUpdateCounter);
        db.updateSubcategories(subcategories, currentCompanyId, increaseUpdateCounter);
        db.updateSubcategoryGroups(subcategoryGroups.getAll(), currentCompanyId, increaseUpdateCounter);
        db.updateCompanies(companies, currentCompanyId, increaseUpdateCounter);
        db.updateDocumentTypes(documentTypes, currentCompanyId, increaseUpdateCounter);
        db.updateDocumentTags(documentTags, currentCompanyId, increaseUpdateCounter);
        db.updateFloors(floors, currentCompanyId, increaseUpdateCounter);
        db.updateFrequencyTypes(frequencies, currentCompanyId, increaseUpdateCounter);
        db.updateInterventions(interventionManager.offlineInterventions, currentCompanyId, increaseUpdateCounter);
        db.updateLoggedInUserInfo(loggedInUserInfo, currentCompanyId, increaseUpdateCounter);
        db.updateNotifications(notificationManager.notifications, currentCompanyId, increaseUpdateCounter);
        db.updateOutcomes(outcomes, currentCompanyId, increaseUpdateCounter);
        db.updateQrCodes(qrCodes, currentCompanyId, increaseUpdateCounter);
        db.updateSites(sites, currentCompanyId, increaseUpdateCounter);
        db.updateSiteTypes(siteTypes, currentCompanyId, increaseUpdateCounter);
        db.updateUnitsOfMeasure(data.unitsOfMeasure, currentCompanyId, increaseUpdateCounter);
        db.updateAssetInstances(preFetchedAssets, currentCompanyId, increaseUpdateCounter);
    }

    outboxManager.refreshCounter();
    var tempHtml = "";
    var i;
    for(i = 0; i < companyLicenses.length; i++) {
        tempHtml += "<option value='" + companyLicenses[i].companyId + "'>" + companyLicenses[i].companyName + "</option>";
    }
    tempHtml += "<option value='' disabled='disabled' selected='selected'>-- Selezionare --</option>";
    setInnerHtml(document.getElementById("create-company-linked-to-license-registered-by"), tempHtml);
    setInnerHtml(document.getElementById("company-to-be-addedd-to-permissions"), tempHtml);

    tempHtml = "";
    for(i = 0; i < permissionLevels.length; i++) {
        tempHtml += "<option value='" + permissionLevels[i].permissionLevel + "'>" + permissionLevels[i].description + "</option>";
    }
    setInnerHtml(document.getElementById("create-permission-default-level"), tempHtml);

    tempHtml = "";
    //permissions groups
    if(response.permissionsGroups) {
        response.permissionsGroups.forEach(function(permissionGroup) {
            tempHtml += "<option value='" + permissionGroup.groupCode + "'>" + permissionGroup.groupName + "</option>";
        });
        setInnerHtml(document.getElementById("create-permission-group"), tempHtml);
    }
    applyUserPermissions(permissions);
    tempHtml = "";

    for(i = 0; i < response.ticketTypes.length; i++) {
        tempHtml += "<option value='" + response.ticketTypes[i] + "'>" + response.ticketTypes[i] + "</option>";
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("ticket-type").innerHTML = tempHtml;

    tempHtml = "";
    if(costCenter!=undefined){
        for(i = 0; i < costCenter.length; i++) {
            tempHtml += "<option value='" + costCenter[i].costCenterId + "'>" + costCenter[i].name + "</option>";
        }
    }
    if(tempHtml === ""){
        document.getElementById("cost-center-holder").className += " hidden";
    }
    tempHtml += "<option value='' selected='selected'>-- Nessun centro di costo --</option>";
    document.getElementById("cost-center").innerHTML = tempHtml;

    document.getElementById("entries-container").innerHTML = "";
    setInnerHtml(
        document.querySelector("#notifications-popup .popup-content div"),
        "",
        function(){scroll.refreshPopupById("notifications", false);}
    );
    if(response.loggedInUserInfo.name && response.loggedInUserInfo.surname) {
        document.getElementById("menu-username-holder").innerHTML = response.loggedInUserInfo.name + " " + response.loggedInUserInfo.surname;
    }
    else {
        document.getElementById("menu-username-holder").innerHTML = response.loggedInUserInfo.username;
    }
    tempHtml = "";
    for(i = 0; i < documentTypes.length; i++) {
        tempHtml += "<option value='" + documentTypes[i].documentTypeId + "' style='color:" + ((documentTypes[i].textColor == null) ? "#000000" : documentTypes[i].textColor) + "; background:" + ((documentTypes[i].backgroundColor == null) ? "#F5E5D5" : documentTypes[i].backgroundColor) + "'>" + documentTypes[i].documentType + "</option>";
    }
    document.getElementById("upload-document-type").innerHTML = tempHtml + "<option value='' selected='selected' disabled='disabled' style='color:#000000;background:#F5E5D5;'>-- Selezionare --</option>";
    document.getElementById("edit-document-type").innerHTML = tempHtml;
    document.getElementById("data-room-filter-document-type").innerHTML = tempHtml + "<option value='' selected='selected' style='color:#000000;background:#F5E5D5;'>-- Tutti --</option>";
    dataRoomDocumentTags = [];
    for(i = 0; i < documentTags.length; i++) {
        dataRoomDocumentTags.push(documentTags[i].tag);
    }
    if(textSearchDataRoomDocumentTag !== undefined) {
        textSearchDataRoomDocumentTag.options = dataRoomDocumentTags;
    }
    document.getElementById("upload-document-company").value = globalFilterByCompany;
    document.getElementById("edit-document-company").value = globalFilterByCompany;
    setInnerHtml(
        document.getElementById("data-room-main-col-content"),
        "<div class='tip centered-tip'><button onclick='ajaxReloadDataRoomFilters();'><i class='fa fa-eye' aria-hidden='true'></i> Visualizza documenti</button></div>",
        function() {
            scroll.refreshByName("vdr");
        }
    );
    setInnerHtml(
        document.getElementById("data-room-details-col-content"),
        "",
        function(){
            scroll.refreshByName("vdr-details");
        }
    );
    tempHtml = "";
    for(i = 0; i < frequencies.length; i++) {
        tempHtml += "<option value='" + frequencies[i].frequencyType + "'>" + frequencies[i].frequencyType + "</option>";
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-activity-for-subc-frequency-type").innerHTML = tempHtml;
    document.getElementById("edit-activity-for-subc-frequency-type").innerHTML = tempHtml;
    notificationManager.draw();
    //just the first time
    if(!getNotificationsInitialized) {
        getNotificationsInitialized = true;
        setTimeout(ajaxGetNotifications, 6000);
    }
    maintenancePage.refreshSitesFilters();
    //loading admittable intervention outcomes in selects
    tempHtml = "";
    for(i = 0; i < outcomes.length; i++) {
        tempHtml += "<option value='" + outcomes[i].outcomeId + "'>" + outcomes[i].name + "</option>";
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("end-intervention-outcome").innerHTML = tempHtml;
    //loading companies data in UI
    tempHtml2 = "";
    tempHtml3 = "";
    tempHtml4 = "";
    editableContractorsList = "";
    document.getElementById("contract-customer").innerHTML = "";
    document.getElementById("contract-contractor").innerHTML = "";
    var customersCompanies = 0;
    for(i = 0; i < companies.length; i++) {
        tempHtml4 += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
        if(companies[i].isCustomer) {
            tempHtml2 += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
            customersCompanies++;
        }
        if(companies[i].isContractor) {
            document.getElementById("contract-contractor").innerHTML += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
            tempHtml3 += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
            if(companies[i].isEditable && companies[i].privateVersion != null) {
                editableContractorsList += "<option value='" + companies[i].privateVersion + "'>" + companies[i].companyName + "</option>";
            }
        }
    }
    document.getElementById("select-executor-selector").innerHTML = tempHtml3 + "<option selected='selected' disabled='disabled'>--Selezionare--</option>";
    document.getElementById("team-management-executor-selector").innerHTML = tempHtml3 + "<option value='' selected='selected'>-- Nessuna --</option>";
    document.getElementById("calendar-year-executor-selector").innerHTML = tempHtml3 + "<option value='' selected='selected'>-- Tutti --</option>";
    switch(permissions["VIEW_COMPANY_DATA"]) {
        case "ACCESSIBLE":
            document.getElementById("calendar-executor-selector").innerHTML = tempHtml3 + "<option value='' selected='selected'>-- Tutti --</option>";
            break;
        case "HIDDEN":
            document.getElementById("calendar-executor-selector").innerHTML = "<option value='' selected='selected'>-- Tutti --</option>";
            break;
        case "DISABLED":
            document.getElementById("calendar-executor-selector").innerHTML = "<option value='' selected='selected'>-- Tutti --</option>";
            break;
    }
    tempHtml3 += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("assign-ticket-company-assigned-to-ticket-1").innerHTML = tempHtml3;
    document.getElementById("create-ticket-company-assigned-to-ticket-1").innerHTML = tempHtml3;
    document.getElementById("create-intervention-executor").innerHTML = tempHtml3;
    if(customersCompanies !== 1) {
        tempHtml2 += "<option value='' selected='selected'>-- Tutte --</option>";
    }
    editableContractorsList += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("manage-company-selector").innerHTML = editableContractorsList;
    document.getElementById("safety-control-company-selector").innerHTML = editableContractorsList;
    tempHtml = "";
    tempHtml2="";
    var bgMatched=false;
    for(i = 0; i < buildingsGroups.length; i++) {
        tempHtml += "<option value='" + buildingsGroups[i].buildingGroupId + "'>" + buildingsGroups[i].name + "</option>";
        tempHtml2 += "<option value='" + buildingsGroups[i].buildingGroupId + "' "+(window.localStorage.getItem("cfm_bg")==buildingsGroups[i].buildingGroupId?'selected ':' ')+">" + buildingsGroups[i].name + "</option>";
        if(window.localStorage.getItem("cfm_bg")==buildingsGroups[i].buildingGroupId){
            bgMatched=true;
        }
    }
    //only one element in the select
    if(i === 1) {
        setInnerHtml(document.getElementById("add-asset-buildings-group"), tempHtml, createAssetLoadBuildings);
        setInnerHtml(document.getElementById("add-asset-for-qr-code-buildings-group"), tempHtml, addAssetForQrCodeBuildingGroupChanged);
    }
    else {
        document.getElementById("add-asset-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
        document.getElementById("add-asset-for-qr-code-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    }
    document.getElementById("new-building-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    document.getElementById("common-unit-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    document.getElementById("property-unit-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    document.getElementById("create-accounting-year-buildings-group").innerHTML = tempHtml;
    if(!popupManager.popupIsOpen("edit-site")) {
        document.getElementById("edit-site-buildings-group").innerHTML = tempHtml;
    }
    document.getElementById("edit-building-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    document.getElementById("add-site-to-building-map-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    if(!bgMatched){
        tempHtml2 += "<option value='' selected disabled>-- Selezionare --</option>";
    }
    if(document.getElementById("manage-buildings-buildings-group")!=undefined){
        document.getElementById("manage-buildings-buildings-group").innerHTML = tempHtml2;
        if(bgMatched){
            viewBuildingsOrganization();
        }
        else{
            if(document.getElementById("buildings-organization-viewer")!=undefined){
                setInnerHtml(document.getElementById("buildings-organization-viewer"), "<div class='tip centered-tip'>Seleziona un <strong>gruppo di edifici</strong>.</div>");
            }
            if(document.getElementById("building-details")!=undefined){
                setInnerHtml(document.getElementById("building-details"), "<div class='tip centered-tip'>Seleziona un edificio, un'unità immobiliare o una zona comune nella colonna di sinistra per vederne qui i dettagli.</div>");
            }
        }
    }
    
    
    document.getElementById("accounting-buildings-group").innerHTML = tempHtml + "<option value='' selected disabled>-- Selezionare --</option>";
    document.getElementById("accounting-year-selector").innerHTML = "<option value='' selected disabled>-- Selezionare gruppo --</option>";
    document.getElementById("manage-asset-buildings-group").innerHTML = tempHtml + "<option value='' selected>-- Tutti --</option>";
    document.getElementById("manage-asset-building").innerHTML = "<option value='' selected>-- Tutti --</option>";
    document.getElementById("manage-asset-site").innerHTML = "<option value='' selected>-- Tutti --</option>";
    document.getElementById("manage-asset-floor").innerHTML = "<option value='' selected>-- Tutti --</option>";
    tempHtml = "";
    for(i = 0; i < cadastralCategories.length; i++) {
        tempHtml += "<option value='" + cadastralCategories[i].categoryName + "'>" + cadastralCategories[i].categoryName + "</option>";
    }
    tempHtml += "<option value='' selected>-- Nessuna --</option>";
    document.getElementById("cadastral-category").innerHTML = tempHtml;
    document.getElementById("edit-site-cadastral-category").innerHTML = tempHtml;
    tempHtml = "";
    for(i = 0; i < siteTypes.length; i++) {
        tempHtml += "<option value='" + siteTypes[i] + "'>" + siteTypes[i] + "</option>";
    }
    tempHtml += "<option value='' selected>-- Nessuna --</option>";
    document.getElementById("property-unit-type").innerHTML = tempHtml;
    document.getElementById("edit-site-type").innerHTML = tempHtml;
    try {
        maintenancePage.refresh();
    }
    catch(e){
        console.error(e);
    }
    tempHtml = "";
    var tempCatGroupSelectorHtml = "";
    catTreeTempHtml = "<ul>";
    for(i = 0; i < categoryGroups.length; i++) {
        tempCatGroupSelectorHtml += "<option value='" + categoryGroups[i].categoryGroupId + "'> Gruppo " + categoryGroups[i].categoryGroupId + "</option>";
        var categoriesLabel = "";
        catTreeTempHtml += "<li><div class='cat-group-label b-" + categoryGroups[i].background + "'><button type='button' onclick='editCategoryGroup(" + i + ");' class='manage-categories-cat-tree-edit-btn'><i class='fa fa-pencil' aria-hidden='true'></i></button><button onclick='catTreeToggleCatGroupChildrenVisibility(" + categoryGroups[i].categoryGroupId + ");' class='cat-name'><span><span class='icon'>" + categoryGroups[i].icon + "</span> Gruppo " + categoryGroups[i].categoryGroupId + "</span><span class='manage-categories-cat-group-toggle-arrow' id='manage-categories-cat-group-" + categoryGroups[i].categoryGroupId + "'><i class='fa fa-chevron-down' aria-hidden='true'></i></span></button></div><ul id='cat-tree-children-of-cat-group-" + categoryGroups[i].categoryGroupId + "' class='hidden'>";
        for(var j = 0; j < categories.length; j++) {
            if(categories[j].categoryGroupId == categoryGroups[i].categoryGroupId) {
                categoriesLabel += categories[j].name + " / ";
                catTreeTempHtml += "<li><div class='cat-label b-" + categoryGroups[i].background + "'><button type='button' onclick='editCategory(" + j + ");' class='manage-categories-cat-tree-edit-btn'><i class='fa fa-pencil' aria-hidden='true'></i></button><button onclick='catTreeToggleCatChildrenVisibility(" + categories[j].categoryId + ");' class='cat-name'><span><span class='icon'>" + categories[j].icon + "</span> " + categories[j].name + "</span><span class='manage-categories-cat-toggle-arrow' id='manage-categories-cat-" + categories[j].categoryId + "'><i class='fa fa-chevron-down' aria-hidden='true'></i></span></button></div><ul id='cat-tree-children-of-cat-" + categories[j].categoryId + "' class='hidden'>";
                var foundSubcategories=0;
                for(k = 0; k < subcategories.length; k++) {
                    if(subcategories[k].categoryId == categories[j].categoryId) {
                        if(subcategories[k].name!==null){
                            catTreeTempHtml += "<li><div class='subcat-label'><button type='button' onclick='editSubcategory(" + categories[j].categoryId + "," + subcategories[k].subcategoryId + ");' class='manage-categories-cat-tree-edit-btn'><i class='fa fa-pencil' aria-hidden='true'></i></button><button class='cat-name'><span><span class='icon'>" + subcategories[k].icon + "</span> " + subcategories[k].name + "</span></button></div></li>";
                            foundSubcategories++;
                        }
                    }
                }
                if(foundSubcategories===0){
                    catTreeTempHtml += "<li><span class='no-results-span'>Nessuna sottocategoria</span></li>";
                }
                catTreeTempHtml += "</ul></li>";
            }
        }
        categoriesLabel = "Categorie: " + categoriesLabel.slice(0, -3);
        tempHtml += '<a href="#" id="category-' + categoryGroups[i].categoryGroupId + '" class="category b-' + categoryGroups[i].background + '  father-null" onclick="selectCategory(this);return false;" onmouseover="setCategoryLabel(\'' + categoriesLabel + '\')" onmouseout="unsetCategoryLabel()">' + categoryGroups[i].icon + '</div>';
        catTreeTempHtml += "</ul></li>";
    }
    document.getElementById("manage-categories-cat-tree").innerHTML = catTreeTempHtml;
    scroll.refreshPopupById("manage-categories", false);
    document.getElementById("edit-category-cat-group").innerHTML = tempCatGroupSelectorHtml;
    document.getElementById("edit-subcategory-cat-group").innerHTML = tempCatGroupSelectorHtml;
    tempCatGroupSelectorHtml += "<option selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-subcategory-cat-group").innerHTML = tempCatGroupSelectorHtml;
    document.getElementById("add-category-cat-group").innerHTML = tempCatGroupSelectorHtml;
    tempHtml2 = "";
    var orderedSubcategories = subcategories.sort(function(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
    for(i = 0; i < orderedSubcategories.length; i++) {
        if(orderedSubcategories[i].name!==null && orderedSubcategories[i].deletion==null) {
            tempHtml2 += "<option value='" + orderedSubcategories[i].subcategoryId + "'>" + orderedSubcategories[i].name + "</option>";
        }
    }

    document.getElementById("edit-anomaly-subcategory").innerHTML = tempHtml2 + "<option value='' selected='selected'>-- Nessuna --</option>";

    var tempHtml3 = tempHtml2 + "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("upload-asset-subcategory").innerHTML = tempHtml3;
    document.getElementById("search-asset-for-qr-code-subcategory").innerHTML = tempHtml3;
    document.getElementById("add-activities-to-intervention-subcategory").innerHTML = tempHtml3;

    var tempHtml4 = tempHtml2 + "<option value='' selected='selected'>-- Tutte --</option>";
    document.getElementById("maintenance-subcategory-selector").innerHTML = tempHtml4;
    document.getElementById("calendar-subcategory-selector").innerHTML = tempHtml4;
    document.getElementById("calendar-year-subcategory-selector").innerHTML = tempHtml4;
    tempHtml = "";
    for(i = 0; i < categories.length; i++) {
        tempHtml += "<option value='" + categories[i].categoryId + "'>" + categories[i].name + "</option>";
    }
    document.getElementById("edit-activity-for-subc-category").innerHTML = tempHtml;

    document.getElementById("edit-anomaly-category").innerHTML = tempHtml + "<option value='' selected='selected'>-- Nessuna --</option>";
    document.getElementById("maintenance-category-selector").innerHTML = tempHtml + "<option value='' selected='selected'>-- Tutte --</option>";
    document.getElementById("calendar-category-selector").innerHTML = tempHtml + "<option value='' selected='selected'>-- Tutte --</option>";
    document.getElementById("calendar-year-category-selector").innerHTML = tempHtml + "<option value='' selected='selected'>-- Tutte --</option>";
    document.getElementById("upload-document-category").innerHTML = tempHtml + "<option value='' selected='selected'>-- Nessuna --</option>";
    document.getElementById("edit-document-category").innerHTML = tempHtml + "<option value='' selected='selected'>-- Nessuna --</option>";
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("search-asset-for-qr-code-category").innerHTML = tempHtml;
    document.getElementById("add-asset-for-qr-code-category").innerHTML = tempHtml;
    document.getElementById("view-activities-for-subc-category").innerHTML = tempHtml;
    document.getElementById("view-activities-to-exclude-subc-category").innerHTML = tempHtml;
    document.getElementById("add-activity-for-subc-category").innerHTML = tempHtml;
    document.getElementById("add-subcategory-additional-field-category").innerHTML = tempHtml;
    document.querySelector("#add-asset-form-category-row-1 .add-asset-category").innerHTML = tempHtml;
    document.querySelector("#edit-asset-form-category-row-1 .edit-asset-category").innerHTML = tempHtml;
    clearDropdown("data-room-search-dropdown", true);
    if(dataRoomShowTrash) {
        openDataRoomTrash(true);
    }
    else {
        closeDataRoomTrash(true);
    }
    renderCalendar();
    renderYearCalendar();
    ajaxGetContracts();
    ticketingPage.manualRefresh();
    anomaliesPage.manualRefresh();
    tempHtml = "";
    for(i = 0; i < sites.length; i++) {
        tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</site>";
    }
    if(sites.length === 1) {
        setInnerHtml(document.getElementById("create-intervention-site"), tempHtml);
        setInnerHtml(document.getElementById("upload-asset-site"), tempHtml);
        setInnerHtml(document.getElementById("edit-anomaly-site"), tempHtml);
    }
    else {
        setInnerHtml(document.getElementById("create-intervention-site"), (tempHtml + "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>"));
        setInnerHtml(document.getElementById("upload-asset-site"), (tempHtml + "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>"));
        setInnerHtml(document.getElementById("edit-anomaly-site"), (tempHtml + "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>"));
    }
    document.getElementById("search-asset-for-qr-code-site").innerHTML = tempHtml + "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>";
    document.getElementById("calendar-site-selector").innerHTML = tempHtml + "<option selected='selected' value=''>-- Tutti --</option>";
    document.getElementById("calendar-year-site-selector").innerHTML = tempHtml + "<option selected='selected' value=''>-- Tutti --</option>";
    if(map != null) {
        createSitePushpins();
    }
    //Building the global user permission (which company do you want to work on?) selection form
    globalUserPermissions = response.globalUserPermissions;
    tempHtml = "";
    for(i = 0; i < globalUserPermissions.length; i++) {
        tempHtml += "<option value=" + globalUserPermissions[i].companyId + ">" + globalUserPermissions[i].companyName + "</option>";
    }
    document.getElementById("view-companies-to-exclude-subc-category").innerHTML = tempHtml;
    
    getWorkAreas();
    
    if(response.mustSelectCompany) {
        popup("select-global-company-filter", false, false, false);
    }
    if(!initialUrlHandled) {
        handleUrlPath();
    }
    if(response.wwwMessage && (Capacitor.platform=='web')) {
        warning(response.wwwMessage);
    }
    if(response.appMessage && Capacitor.platform!=='web') {
        warning(response.appMessage);
    }
    maintenancePage.setSiteFilter(null);
    countVdrAppliedFilters();
    countAssetNavAppliedFilters();
    countMonthCalendarAppliedFilters();
    countYearCalendarAppliedFilters();
    countMaintenanceAppliedFilters();
    clearDropdown("asset-search-dropdown");
    clearDropdown("asset-search-dropdown-2");
    clearDropdown("document-search-dropdown-2");
    //Reset companies section
    dom.addClass(document.getElementById("manage-company-edit-info-button"), "hidden");
    setInnerHtml(document.getElementById("page-company-view-information"), "", function(){
        scroll.refreshByName("company-details");
    });
    setInnerHtml(
        document.getElementById("page-company-employees"),
        "<span class='tip'>Per utilizzare questa funzione è necessario selezionare un'<strong>azienda</strong>.</span>",
        function() {
            scroll.refreshByName("company-employees");
        }
    );

    
    //Reset accounting section
    document.getElementById("accounting-link-invoices").disabled = "disabled";
    document.getElementById("accounting-link-repartition-tables").disabled = "disabled";
    document.getElementById("accounting-link-installments").disabled = "disabled";
    document.getElementById("accounting-link-transactions").disabled = "disabled";
    document.getElementById("accounting-link-estimates").disabled = "disabled";
    document.getElementById("accounting-link-consumptives").disabled = "disabled";
    document.getElementById("accounting-link-balances").disabled = "disabled";
    document.getElementById("accounting-link-expenditure-items").disabled = "disabled";
    setInnerHtml(document.getElementById("accounting-status"), "");
    setInnerHtml(document.getElementById("accounting-sites-trend"), "");
    setInnerHtml(document.getElementById("accounting-summary"), "Per gestire la contabilità è necessario selezionare un <strong>gruppo di edifici</strong> ed un relativo <strong>anno di esercizio</strong>.");
    showCorrectActionButton(currentPage);
    dom.addClass(document.getElementById("floor-buttons"), "hidden");
    dom.removeClass(document.getElementById("building-map"), "hidden");
    dom.addClass(document.getElementById("building-map-img"), "hidden");
    if(successCallback) {
        if(typeof successCallback === "function") {
            successCallback();
        }
    }
    dom.addClass(document.getElementById("page-loading-error"), "hidden");
    dom.addClass(document.getElementById("page-loading"), "hidden");

    //Update current page contents when loading a company
    if(currentPage=="page-annual-interventions-report") {
        openAnnualInterventionReport();
    }
    generateCategoryTreeForAssetNavigation();
    //reloadAssetNavFilters();
}

var fullSearchPopup = {
    clear:function() {
        document.getElementById("search-popup-results").innerHTML="";
        scroll.refreshByName("search-popup");
        document.getElementById("search-popup-query").value="";
        window.setTimeout(function () {
            document.getElementById("search-popup-query").focus();
        }, 60);
    }
};

function ajaxFullSearch(searchedQuery)
{
    var resultsContainer = document.getElementById("search-popup-results");
    if(searchedQuery.trim()!="") {
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function () {
            var response = JSON.parse(xhttp.responseText);
            if (xhttp.status === 200) {
                if (response.results.length < 1) {
                    setInnerHtml(
                        resultsContainer,
                        "<div class='no-results-message'>Nessun risultato</div>",
                        function () {
                            scroll.refreshByName("search-popup");
                        }
                    );
                }
                else {
                    setInnerHtml(
                        resultsContainer,
                        "",
                        function() {
                            response.results.forEach(function (result) {
                                var header = "", action;
                                switch (result.type) {
                                    case 'asset':
                                        header = "<i class='fa fa-cube' aria-hidden='true'></i> <strong>Asset</strong><br>";
                                        //function closure pattern
                                        action = (function(){
                                            var storedResultId = result.id;
                                            return function(){
                                                closePopup();
                                                selectAssetInstance(storedResultId);
                                            };
                                        })();
                                        break;
                                    case 'intervention':
                                        header = "<i class='fa fa-wrench' aria-hidden='true'></i> <strong>Intervento</strong>";
                                        //function closure pattern
                                        action = (function(){
                                            var storedResultId = result.id;
                                            return function(){
                                                closePopup();
                                                selectIntervention(storedResultId);
                                            };
                                        })();
                                        break;
                                    case 'ticket':
                                        header = "<i class='fa fa-ticket' aria-hidden='true'></i> <strong>Ticket</strong>";
                                        //function closure pattern
                                        action = (function(){
                                            var storedResultId = result.id;
                                            return function(){
                                                closePopup();
                                                selectTicket(storedResultId,true);
                                            };
                                        })();
                                        break;
                                    case 'anomaly':
                                        header = "<i class='fa fa-crosshairs' aria-hidden='true'></i> <strong>Segnalazione</strong>";
                                        //function closure pattern
                                        action = (function(){
                                            var storedResultId = result.id;
                                            return function(){
                                                closePopup();
                                                selectAnomaly(storedResultId,true);
                                            };
                                        })();
                                        break;
                                    case 'document':
                                        header = "<i class='fa fa-file' aria-hidden='true'></i> <strong>Documento</strong>";
                                        action = (function(){
                                            var storedResultId = result.id;
                                            return function(){
                                                closePopup();
                                                changePage("page-data-room");
                                                ajaxReloadDataRoomFilters(storedResultId,false);
                                            };
                                        })();
                                        break;
                                }
                                var entry = document.createElement("div");
                                dom.addClass(entry,"entry");
                                entry.addEventListener("tap", action, false);
                                entry.innerHTML = header + "<div class='result-text'>" + result.text + "</div>";
                                resultsContainer.appendChild(entry);
                            });
                            scroll.refreshByName("search-popup");
                        }
                    );
                }
            }
            else {
                ajaxSendErrorReport(xhttp.responseURL, "query=" + searchedQuery, xhttp.responseText);
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/search.php?companyId="+ globalFilterByCompany +"&query=" + searchedQuery, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    }
    else{
        setInnerHtml(
            resultsContainer,
            "",
            function () {
                scroll.refreshByName("search-popup");
            }
        );
    }
}

function ajaxGetDashboardDataByEntity(successCallback, entityType, entityValue) {
    //TODO: this method to getDashboardData on the correct company
    dom.removeClass(document.getElementById("page-loading"), "hidden");
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = 20000; // time in milliseconds
    var timeoutErrorAbort = function() {
        if(Capacitor.platform!=='web') {
            loadInitializationDataFromLocalDB(successCallback,globalFilterByCompany);
        }
        else {
            setTimeout(function() {
                ajaxGetDashboardDataByEntity(successCallback, entityType, entityValue);
            }, 1000);
            dom.addClass(document.getElementById("page-loading"), "hidden");
            ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, xhttp.responseText);
            dom.removeClass(document.getElementById("page-loading-error"), "hidden");
        }
    };
    xhttp.onerror = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Errore - Stato: " + ( xhttp.status ? xhttp.status : "No") +"<br>" ;
        timeoutErrorAbort();
    };
    xhttp.onabort = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Connessione interrotta dal client<br>";
        timeoutErrorAbort();
    };
    xhttp.ontimeout = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Timeout di "+ xhttp.timeout +"ms raggiunto<br>";
        timeoutErrorAbort();
    };
    xhttp.onprogress = function (event) {
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Caricamento: "+ formatFileSize(event.loaded) +" scaricati<br>";
    };
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var dt = (new Date()).toLocaleString();
                loadingLog+= dt + " Caricamento completato, Stato: 200 OK<br>";
                if(response.globalFilterByCompany) {
                    globalFilterByCompany = response.globalFilterByCompany;
                }
                loadInitializationData(response, successCallback, true);
            }
            else {
                dom.addClass(document.getElementById("page-loading-error"), "hidden");
                dom.addClass(document.getElementById("page-loading"), "hidden");
                var dt = (new Date()).toLocaleString();
                loadingLog+= dt + " Caricamento completato, Stato: "+xhttp.status+"<br>";
                switch(xhttp.status) {
                    case 403:
                        if(urlNeedsLogin()) {
                            console.log("Auth token not valid, page hash:" + window.location.hash);
                            savedPageHash = window.location.hash.replace("#", "");
                            changePage("page-login");
                        }
                        break;
                    default:
                        setTimeout(function() {
                            ajaxGetDashboardDataByEntity(successCallback, entityType, entityValue);
                        }, 1000);
                        dom.addClass(document.getElementById("page-loading"), "hidden");
                        ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, xhttp.responseText);
                        dom.removeClass(document.getElementById("page-loading-error"), "hidden");
                        break;
                }
            }
        }
        catch(e) {
            console.error(e);
            var dt = (new Date()).toLocaleString();
            loadingLog+= dt + " Errore (da catch): "+e.message+"<br>";
            loadingLog+= dt + " Stack: "+e.stack+"<br>";
            console.error(xhttp.responseText);
            loadingLog+= dt + " Risposta del server (primi 256 caratteri): "+xhttp.responseText.substring(0,256)+"<br>";
            dom.addClass(document.getElementById("page-loading"), "hidden");
            dom.removeClass(document.getElementById("page-loading-error"), "hidden");
            ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, e.message + "<br><br> On line: " + e.lineNumber + "<br><br>" + e.stack + "<br><br>" + xhttp.responseText.substring(0, 1000));
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getDashboardDataByEntity.php?entityType=" + entityType + "&entityId=" + entityValue, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetDashboardData(successCallback) {
    dom.removeClass(document.getElementById("page-loading"), "hidden");
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = 20000; // time in milliseconds
    var timeoutErrorAbort = function() {
        if(Capacitor.platform!=='web') {
            loadInitializationDataFromLocalDB(successCallback,globalFilterByCompany);
        }
        else {
            setTimeout(function() {
                ajaxGetDashboardData(successCallback);
            }, 1000);
            dom.addClass(document.getElementById("page-loading"), "hidden");
            ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, xhttp.responseText);
            dom.removeClass(document.getElementById("page-loading-error"), "hidden");
        }
    };
    xhttp.onerror = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Errore - Stato: " + ( xhttp.status ? xhttp.status : "No") +"<br>";
        timeoutErrorAbort();
    };
    xhttp.onabort = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Connessione interrotta dal client<br>";
        timeoutErrorAbort();
    };
    xhttp.ontimeout = function(){
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Timeout di "+ xhttp.timeout +"ms raggiunto<br>";
        timeoutErrorAbort();
    };
    xhttp.onprogress = function (event) {
        var dt = (new Date()).toLocaleString();
        loadingLog+= dt + " Caricamento: "+ formatFileSize(event.loaded) +" scaricati<br>";
    };
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var dt = (new Date()).toLocaleString();
                loadingLog+= dt + " Caricamento completato, Stato: 200 OK<br>";
                loadInitializationData(response, successCallback, true);
            }
            else {
                dom.addClass(document.getElementById("page-loading-error"), "hidden");
                dom.addClass(document.getElementById("page-loading"), "hidden");

                var dt = (new Date()).toLocaleString();
                loadingLog+= dt + " Caricamento completato, Stato: "+xhttp.status+"<br>";
                switch(xhttp.status) {
                    case 403:
                        if(urlNeedsLogin()) {
                            if(response.message){
                                window.localStorage.removeItem("cfm_at");
                                window.localStorage.removeItem("cfm_last_global_filter_by_company");
                                window.localStorage.removeItem("cfm_last_global_filter_by_workarea");
                                globalFilterByCompany=null;
                                globalFilterByCompanyFromLocalStorage=null;
                                errorToast(response.message);
                            }
                            console.log("Auth token not valid, page hash:" + window.location.hash);
                            savedPageHash = window.location.hash.replace("#", "");
                            changePage("page-login");
                        }
                        break;
                    default:
                        setTimeout(function() {
                            ajaxGetDashboardData(successCallback);
                        }, 5000);
                        dom.addClass(document.getElementById("page-loading"), "hidden");
                        ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, xhttp.responseText);
                        dom.removeClass(document.getElementById("page-loading-error"), "hidden");
                        break;
                }
            }
        }
        catch(e) {
            console.error(e);
            var dt = (new Date()).toLocaleString();
            loadingLog+= dt + " Errore (da catch): "+e.message+"<br>";
            loadingLog+= dt + " Stack: "+e.stack+"<br>";
            console.error(xhttp.responseText);
            loadingLog+= dt + " Risposta del server (primi 256 caratteri): "+xhttp.responseText.substring(0,256)+"<br>";

            dom.addClass(document.getElementById("page-loading"), "hidden");
            dom.removeClass(document.getElementById("page-loading-error"), "hidden");
            ajaxSendErrorReport(xhttp.responseURL, "companyId=" + globalFilterByCompany, e.message + "<br><br> On line: " + e.lineNumber + "<br><br>" + e.stack + "<br><br>" + xhttp.responseText.substring(0, 1000));
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php?companyId=" + globalFilterByCompany, true);
    }
    else {
        xhttp.open("GET", BASE_URL + "/ws/getDashboardData.php", true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function editCategoryGroup(categoryGroupIndex) {
    lastEditedCategoryGroup = categoryGroups[categoryGroupIndex].categoryGroupId;
    document.getElementById("edit-category-group-icon").value = categoryGroups[categoryGroupIndex].icon;
    document.getElementById("edit-category-group-bg-class").value = categoryGroups[categoryGroupIndex].background;
    popup("edit-category-group", false, false, false);
}

function editCategory(categoryIndex) {
    lastEditedCategory = categories[categoryIndex].categoryId;
    document.getElementById("edit-category-name").value = categories[categoryIndex].name;
    document.getElementById("edit-category-icon").value = categories[categoryIndex].icon;
    document.getElementById("edit-category-cat-group").value = categories[categoryIndex].categoryGroupId;
    popup("edit-category", false, false, false);
}

var categoryManager = {
    get: function(categoryId) {
        for(var i=0;i<categories.length;i++){
            if(categories[i].categoryId===categoryId){
                return categories[i];
            }
        }
    }
};

var subcategoryManager = {
    get: function(subcategoryId) {
        for(var i=0;i<subcategories.length;i++){
            if(subcategories[i].subcategoryId===subcategoryId){
                return subcategories[i];
            }
        }
    }
};

function editSubcategory(categoryId, subcategoryId) {
    var category = categoryManager.get(categoryId);
    var subcategory = subcategoryManager.get(subcategoryId);
    lastEditedSubcategory = subcategory.subcategoryId;
    document.getElementById("edit-subcategory-name").value = subcategory.name;
    document.getElementById("edit-subcategory-icon").value = subcategory.icon;
    document.getElementById("edit-subcategory-cat-group").value = subcategory.categoryGroupId;
    var tempHtml = "";
    for(var i = 0; i < categories.length; i++) {
        if(categories[i].categoryGroupId == category.categoryGroupId) {
            if(subcategory.categoryId == categories[i].categoryId) {
                tempHtml += "<option value='" + categories[i].categoryId + "' selected='selected'>" + categories[i].name + "</option>";
            }
            else {
                tempHtml += "<option value='" + categories[i].categoryId + "'>" + categories[i].name + "</option>";
            }
        }
    }
    document.getElementById("edit-subcategory-category").innerHTML = tempHtml;
    scroll.refreshPopupById("edit-subcategory", false);
    popup("edit-subcategory", false, false, false);
}

function catTreeToggleCatGroupChildrenVisibility(categoryGroupId) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var childrenUl = document.getElementById("cat-tree-children-of-cat-group-" + categoryGroupId);
    var toggleArrow = document.getElementById("manage-categories-cat-group-" + categoryGroupId);
    if(dom.hasClass(childrenUl, "hidden")) {
        dom.removeClass(childrenUl, "hidden");
        toggleArrow.innerHTML = "<i class='fa fa-chevron-up' aria-hidden='true'></i>";
    }
    else {
        dom.addClass(childrenUl, "hidden");
        toggleArrow.innerHTML = "<i class='fa fa-chevron-down' aria-hidden='true'></i>";
    }
    scroll.refreshPopupById("manage-categories", false);
}

function catTreeToggleCatChildrenVisibility(categoryId) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var childrenUl = document.getElementById("cat-tree-children-of-cat-" + categoryId);
    var toggleArrow = document.getElementById("manage-categories-cat-" + categoryId);
    if(dom.hasClass(childrenUl, "hidden")) {
        dom.removeClass(childrenUl, "hidden");
        toggleArrow.innerHTML = "<i class='fa fa-chevron-up' aria-hidden='true'></i>";
    }
    else {
        dom.addClass(childrenUl, "hidden");
        toggleArrow.innerHTML = "<i class='fa fa-chevron-down' aria-hidden='true'></i>";
    }
    scroll.refreshPopupById("manage-categories", false);
}

function createSubcategoryReloadChildrenCategories() {
    var selectedCategoryGroup = document.getElementById("add-subcategory-cat-group").value;
    var categoriesTempHtml = "";
    for(var i = 0; i < categories.length; i++) {
        if(categories[i].categoryGroupId == selectedCategoryGroup) {
            categoriesTempHtml += "<option value='" + categories[i].categoryId + "'>" + categories[i].name + "</option>";
        }
    }
    categoriesTempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-subcategory-category").innerHTML = categoriesTempHtml;
    scroll.refreshPopupById("manage-categories", false);
}

function createSitePushpins() {
    mapPushpins = [];
    var locs = [];
    var pushpin;
    mapInfobox = new Microsoft.Maps.Infobox(map.getCenter(), {description: "description", showCloseButton: false, showPointer: false});
    Microsoft.Maps.loadModule("Microsoft.Maps.Clustering", function() {
        for(i = 0; i < sites.length; i++) {
            if(sites[i].latitude != null && sites[i].longitude != null) {
                pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(sites[i].latitude, sites[i].longitude), {
                    text: sites[i].letter,
                    textOffset: new Microsoft.Maps.Point(0, 2),
                    icon: imgPushpin,
                    height: 20,
                    width: 20,
                    anchor: new Microsoft.Maps.Point(10, 10)
                });
                pushpin.clasterFmId = sites[i].siteId;
                Microsoft.Maps.Events.addHandler(pushpin, "click", pushpinClicked);
                Microsoft.Maps.Events.addHandler(pushpin, "mouseover", pushpinHovered);
                Microsoft.Maps.Events.addHandler(pushpin, "mouseout", pushpinUnhovered);
                mapPushpins.push(pushpin);
                locs.push(pushpin.getLocation());
            }
        }
        if(clusterLayer != null) {
            map.layers.remove(clusterLayer);
            clusterLayer.clear();
        }
        clusterLayer = new Microsoft.Maps.ClusterLayer(mapPushpins, {
            clusteredPinCallback: customizeClusteredPin
        });
        map.layers.insert(clusterLayer);
    });
}

function customizeClusteredPin(cluster) {
    Microsoft.Maps.Events.addHandler(cluster, "click", clusterClicked);
    Microsoft.Maps.Events.addHandler(cluster, "mouseover", clusterHovered);
    Microsoft.Maps.Events.addHandler(cluster, "mouseout", clusterUnhovered);
}

function focusSiteOnMap(siteId) {
    var i;
    var toFocusPushpin = null;
    for(i = 0; i < mapPushpins.length; i++) {
        if(mapPushpins[i].clasterFmId == siteId) {
            toFocusPushpin = mapPushpins[i];
            break;
        }
    }
    var locs = [];
    if(toFocusPushpin != null) {
        var pushpinLocation = toFocusPushpin.getLocation();
        locs.push(pushpinLocation);
        var bounds = Microsoft.Maps.LocationRect.fromLocations(locs);
        var mapViewPort = map.getBounds();
        if(mapViewPort && map.getZoom() < 10 || (!mapViewPort.contains(pushpinLocation))) {
            map.setView({bounds: bounds, padding: 100});
            map.setView({zoom: 10});
        }
        else {
            map.setView({center: toFocusPushpin.getLocation()});
        }
        if(lastPushPinSelected != null) {
            lastPushPinSelected.setOptions({icon: imgPushpin, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
        }
        toFocusPushpin.setOptions({icon: imgPushpinSelected, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
        lastPushPinSelected = toFocusPushpin;
    }
}

function centerMapGlobally() {
    var locs = [];
    var i;
    for(i = 0; i < mapPushpins.length; i++) {
        locs.push(mapPushpins[i].getLocation());
    }
    if(locs.length > 0 && Microsoft.Maps.LocationRect) {
        var bounds = Microsoft.Maps.LocationRect.fromLocations(locs);
        map.setView({bounds: bounds, padding: 2000});
        map.setView({zoom: 5});

        if(lastPushPinSelected != null)
            lastPushPinSelected.setOptions({icon: imgPushpin, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
    }
}

function clusterClicked(e) {
    mapInfobox.setHtmlContent("<div></div>");
    if(e.target.containedPushpins) {
        var locs = [];
        for(var i = 0, len = e.target.containedPushpins.length; i < len; i++) {
            //Get the location of each pushpin.
            locs.push(e.target.containedPushpins[i].getLocation());
        }

        //Create a bounding box for the pushpins.
        var bounds = Microsoft.Maps.LocationRect.fromLocations(locs);

        //Zoom into the bounding box of the cluster.
        //Add a padding to compensate for the pixel area of the pushpins.
        map.setView({bounds: bounds, padding: 150});
    }
}

function clusterHovered(event) {
    var loc = event.target.getLocation();
    mapInfoboxClickHandler = Microsoft.Maps.Events.addHandler(mapInfobox, "click", function() {
        clusterClicked(event);
    });
    mapInfobox.setMap(map);
    mapInfobox.setLocation(loc);
    mapInfobox.setHtmlContent('<div id="map-infobox">Clicca per espandere</div>');
}

function clusterUnhovered(event) {
    Microsoft.Maps.Events.removeHandler(mapInfoboxClickHandler);
    mapInfobox.setHtmlContent("<div></div>");
}

var notificationManager = {
    notifications: [],
    draw: function() {
        setInnerHtml(
            document.querySelector("#notifications-popup .popup-content div"),
            "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
            function(){scroll.refreshPopupById("notifications", false);}
        );
        var tempHtml = "<div class='notifications hth'>";
        unreadNotifications = 0;
        unseenNotifications = 0;
        if(notificationManager.notifications.length === 0) {
            tempHtml += "<div class='no-results-message'>Al momento non sono presenti notifiche.</div>";
        }
        notificationManager.notifications.forEach(function(notification) {
            if(!notification.isRead) {
                tempHtml += "<div class='tr unread-notification' onclick='closePopup();notificationManager.notificationSelected(" + notification.notificationId + ")'><div>" + notification.companyName + "<br><span class='timestamp'>" + iso8601.toEuropeanDateTimeWithSeconds(notification.isoTimestamp) + '</span></div><div>' + notification.notificationDescription + '</div></div>';
                unreadNotifications++;
            }
            else {
                tempHtml += "<div class='tr' onclick='closePopup();notificationManager.notificationSelected(" + notification.notificationId + ");'><div>" + notification.companyName + "<br><span class='timestamp'>" + iso8601.toEuropeanDateTimeWithSeconds(notification.isoTimestamp) + "</span></div><div>" + notification.notificationDescription + '</div></div>';
            }
            if(!notification.isSeen) {
                unseenNotifications++;
            }
        });
        refreshUnseenNotificationsCounter();
        tempHtml += "</div>";
        setInnerHtml(
            document.querySelector("#notifications-popup .popup-content div"),
            tempHtml,
            function(){scroll.refreshPopupById("notifications", false);}
        );
    },
    ajaxMarkAsSeen: function() {
        var toMarkNotifications = [];
        notificationManager.notifications.forEach(function(notification) {
            if(!notification.isSeen) {
                notification.isSeen = true;
                toMarkNotifications.push(notification.notificationId);
            }
        });
        if(toMarkNotifications.length > 0) {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    refreshUnseenNotificationsCounter();
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
                    popup("outcome", false, false, false);
                }
            };
            xhttp.open("POST", BASE_URL + "/ws/markNotificationsAsSeen.php", true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(toMarkNotifications));
        }
    },
    ajaxMarkAsRead: function(notificationId) {
        notificationManager.notifications.forEach(function(notification) {
            if(notification.notificationId == notificationId) {
                notification.isRead = true;
            }
        });
        notificationManager.draw();
        var toMarkNotifications = [];
        toMarkNotifications.push(notificationId);
        if(toMarkNotifications.length > 0) {
            var xhttp = new XMLHttpRequest();
            xhttp.onload = function() {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status !== 200) {
                    outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
                    popup("outcome", false, false, false);
                }
            };
            xhttp.open("POST", BASE_URL + "/ws/markNotificationsAsRead.php", true);
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
            xhttp.send(JSON.stringify(toMarkNotifications));
        }
    },
    notificationSelected: function(notificationId) {
        if(!ghostClickController.isOk()){
            return false;
        }
        notificationManager.ajaxMarkAsRead(notificationId);
        notificationManager.notifications.forEach(function(notification) {
            if(notification.notificationId == notificationId) {
                if(notification.link !== null) {
                    preHandleUrlPath(
                        notification.link,
                        function() {
                            handleUrlPath(notification.link);
                        }
                    );
                }
            }
        });
    }
};

function ajaxGetNotifications() {
    if(currentAuthToken!==null) {
        setTimeout(ajaxGetNotifications, 15000);
        var xhttp = new XMLHttpRequest();
        xhttp.timeout = 6000; // Set timeout to 5 seconds (5000 milliseconds)
        xhttp.onload = function() {
            switch(xhttp.status) {
                case 200:
                    lastSyncDateObject = new Date();
                    outboxManager.send(false);
                    var response = JSON.parse(xhttp.responseText);
                    notificationManager.notifications = response.notifications;
                    notificationManager.draw();
                    break;
                case 403:
                    dom.addClass(document.getElementById("page-loading-error"), "hidden");
                    dom.addClass(document.getElementById("page-loading"), "hidden");
                    if(urlNeedsLogin()) {
                        changePage("page-login");
                    }
                    break;
            }
        };
        xhttp.open("GET", BASE_URL + "/ws/getNotifications.php", true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    }
}

function ajaxGetEmployees(companyId) {
    document.getElementById("page-company-employees").innerHTML = "<span class='loading small'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione in corso...</span>";
    scroll.refreshByName("company-employees");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var companyEmployeesHtml = "<span class='important-label'>Dipendenti</span><br><br>";
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            employees = response.employees;
            for(var i = 0; i < employees.length; i++) {
                if(employees[i].hasAccount)
                    companyEmployeesHtml += "<button type='button' onclick='viewEmployeeDetails(" + employees[i].employeeId + ");' class='has-account'><span class='fa-stack fa-2x'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-user fa-stack-1x fa-inverse'></i><i class='fa fa-key fa-stack-1x fa-inverse'></i></span><strong>" + employees[i].name + " " + employees[i].surname + "</strong></button>";
                else
                    companyEmployeesHtml += "<button type='button' onclick='viewEmployeeDetails(" + employees[i].employeeId + ");'><span class='fa-stack fa-2x'><i class='fa fa-circle fa-stack-2x'></i><i class='fa fa-user fa-stack-1x fa-inverse'></i></span><strong>" + employees[i].name + " " + employees[i].surname + "</strong></button>";
            }
            if(employees.length < 1) {
                companyEmployeesHtml += "<div class='no-results-message'>Nessun dipendente registrato</div>";
            }
        }
        else {
            companyEmployeesHtml += "<div class='message error'>" + response.message + "</div>";
        }
        document.getElementById("page-company-employees").innerHTML = companyEmployeesHtml;
        scroll.refreshByName("company-employees");
    };
    xhttp.open("GET", BASE_URL + "/ws/getEmployees.php?companyId=" + companyId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function manageCompanySelectorChanged() {
    var selectedCompanyId = document.getElementById("manage-company-selector").value;
    if(selectedCompanyId!=""){
        var companyInfoHtml = "<div class='important-label'>Informazioni aziendali</div><br>";
        if(selectedCompanyId) {
            dom.removeClass(document.getElementById("manage-company-edit-info-button"), "hidden");
        }
        else {
            dom.addClass(document.getElementById("manage-company-edit-info-button"), "hidden");
        }
        var companyIdToLookForEmployees = selectedCompanyId;
        for(var i = 0; i < companies.length; i++) {
            var j;
            if(companies[i].privateVersion == selectedCompanyId) {
                companyInfoHtml += "<div class='hth'>";
                companyInfoHtml += "<div class='tr'><div>Nome azienda</div><div>" + companies[i].companyName + "</div></div>";
                companyInfoHtml += "<div class='tr'><div>Indirizzo e-mail</div><div>" + (companies[i].eMailAddress == null ? "Non specificato" : ("<a href='mailto:" + companies[i].eMailAddress + "' target='_blank' rel='noreferrer'>" + companies[i].eMailAddress + "</a>")) + "</div></div>";
                companyInfoHtml += "<div class='tr'><div>Numero di telefono</div><div>" + (companies[i].telephoneNumber == null ? "Non specificato" : companies[i].telephoneNumber) + "</div></div>";
                companyInfoHtml += "<div class='tr'><div>Partita IVA</div><div>" + (companies[i].vatNumber == null ? "Non specificata" : companies[i].vatNumber) + "</div></div>";
                companyInfoHtml += "<div class='tr'><div>Codice fiscale</div><div>" + (companies[i].fiscalCode == null ? "Non specificato" : companies[i].fiscalCode) + "</div></div></div><br>";
                for(j = 0; j < companies[i].addresses.length; j++) {
                    companyInfoHtml += "<button type='button' onclick='showCompanyAddress(" + j + ");' id='address-tab-button-" + j + "' class='address-tab-button" + (j == 0 ? " selected" : "") + "'>" + companies[i].addresses[j].addressName + "</button> ";
                }
                for(j = 0; j < companies[i].addresses.length; j++) {
                    companyInfoHtml += "<div class='company-address" + (j === 0 ? "" : " hidden") + "' id='company-address-" + j + "'>";
                    if(companies[i].addresses[j].street != null || companies[i].addresses[j].streetNumber !== null) {
                        companyInfoHtml += "<div class='company-info-row'><strong>Indirizzo</strong><br>" + (companies[i].addresses[j].street == null ? "n°" : (companies[i].addresses[j].street + ", ")) + (companies[i].addresses[j].streetNumber == null ? "" : companies[i].addresses[j].streetNumber) + "</div>";
                    }
                    if(companies[i].addresses[j].zipCode != null || companies[i].addresses[j].city != null || companies[i].addresses[j].district != null) {
                        companyInfoHtml += "<div class='company-info-row'><strong>Città</strong><br>" + ((companies[i].addresses[j].zipCode == null) ? "" : (companies[i].addresses[j].zipCode + " - ")) + (companies[i].addresses[j].city == null ? "" : companies[i].addresses[j].city) + (companies[i].addresses[j].district == null ? "" : " (" + companies[i].addresses[j].district + ")") + "</div>";
                    }
                    if(companies[i].addresses[j].countryCode != null) {
                        companyInfoHtml += "<div class='company-info-row'><strong>Stato</strong><br>" + companies[i].addresses[j].countryCode + "</div>";
                    }
                    if(companies[i].addresses[j].street == null && companies[i].addresses[j].streetNumber === null && companies[i].addresses[j].zipCode == null && companies[i].addresses[j].city == null && companies[i].addresses[j].district == null && companies[i].addresses[j].countryCode == null) {
                        companyInfoHtml += "Indirizzo non presente";
                    }
                    companyInfoHtml += "</div>";
                }
                if(companies[i].certifications.length>0){
                    companyInfoHtml+="<br><div class='important-label'>Documenti aziendali</div>";
                }
                companies[i].certifications.forEach(function(certification){
                    companyInfoHtml+="<div class='data-room-file' onclick='renderDocument("+certification.documentId+",\""+certification.fileFormat+"\");'><i class='data-room-file-icon fa fa-file-pdf-o fa-2x' aria-hidden='true'></i><br>"+certification.documentName+"</div>";
                });
                break;
            }
        }
        document.getElementById("page-company-view-information").innerHTML = companyInfoHtml;
        scroll.refreshByName("company-details");
        /*
         * Leggo i dipendenti registrati sia pubblicati dalle aziende (perciò dati pubblici) sia quelli privati registrati dalla mia azienda
         * Gli ID azienda pubblici e privati non possono collidere perchè sono univoci.
         * Al server comunico l'id dell'azienda privato, cioè quello relativa all'anagrafica dell'azienda corrente, il server collegherà anche i dipendenti registrati dall'azienda pubblica.
         */
        ajaxGetEmployees(companyIdToLookForEmployees);
    }
}

function showCompanyAddress(addressId) {
    var companyTabs = document.querySelectorAll(".company-address");
    var i;
    for(i = 0; i < companyTabs.length; i++) {
        dom.addClass(companyTabs[i], "hidden");
    }
    dom.removeClass(document.getElementById("company-address-" + addressId), "hidden");
    var companyTabsBtns = document.querySelectorAll(".address-tab-button");
    for(i = 0; i < companyTabsBtns.length; i++) {
        dom.removeClass(companyTabsBtns[i], "selected");
    }
    dom.addClass(document.getElementById("address-tab-button-" + addressId), "selected");
}

function showCompanyAddressToEdit(addressId) {
    var companyTabs = document.querySelectorAll(".company-to-edit-address");
    var i;
    for(i = 0; i < companyTabs.length; i++) {
        dom.addClass(companyTabs[i], "hidden");
    }
    dom.removeClass(document.getElementById("company-to-edit-address-" + addressId), "hidden");
    var companyTabsBtns = document.querySelectorAll(".to-edit-address-tab-button");
    for(i = 0; i < companyTabsBtns.length; i++) {
        dom.removeClass(companyTabsBtns[i], "selected");
    }
    dom.addClass(document.getElementById("to-edit-address-tab-button-" + addressId), "selected");
}

function showCompanyCertificationToEdit(certificationId) {
    var companyTabs = document.querySelectorAll(".company-to-edit-certification");
    var i;
    for(i = 0; i < companyTabs.length; i++) {
        dom.addClass(companyTabs[i], "hidden");
    }
    dom.removeClass(document.getElementById("company-to-edit-certification-" + certificationId), "hidden");
    var companyTabsBtns = document.querySelectorAll(".to-edit-certification-tab-button");
    for(i = 0; i < companyTabsBtns.length; i++) {
        dom.removeClass(companyTabsBtns[i], "selected");
    }
    dom.addClass(document.getElementById("to-edit-certification-tab-button-" + certificationId), "selected");
}

function teamManagementExecutorChanged() {
    var selectedExecutor = document.getElementById("team-management-executor-selector").value;
    ajaxGetAvailableTeamComponents(selectedExecutor);
}

function toggleTeamManagementEmployee(employeeId) {
    var checkbox=document.getElementById("team-management-employee-"+employeeId);
    var newValue=(checkbox.getAttribute("data-checked")==="1")?"0":"1";
    checkbox.setAttribute("data-checked",newValue);
}

function ajaxGetAvailableTeamComponents(companyId) {
    if(companyId == "") {
        document.getElementById("team-components-container").innerHTML = "Seleziona un'<strong>azienda esecutrice</strong> per mostrarne gli operatori disponibili";
    }
    else {
        var i;
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            var companyEmployeesHtml;
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                employees = response.employees;
                if(employees.length === 0) {
                    companyEmployeesHtml = "<div class='no-results-message'>Nessun componente del team disponibile</div>";
                }
                else {
                    companyEmployeesHtml = "<div class='hth'>";
                    if(companyId == lastOpenedIntervention.executorId) {
                        var found;
                        for(i = 0; i < employees.length; i++) {
                            found = false;
                            for(var j = 0; j < lastOpenedIntervention.executionTeam.length; j++) {
                                if(lastOpenedIntervention.executionTeam[j].employeeId == employees[i].employeeId) {
                                    found = true;
                                }
                            }
                            var checked = found?"1":"0";
                            companyEmployeesHtml += "<div class='tr'>" +
                                "<div>" +
                                "<button" +
                                " type='button'" +
                                " class='pseudo-checkbox-button'" +
                                " onclick='toggleTeamManagementEmployee(" + employees[i].employeeId + ");'" +
                                ">" +
                                "<span " +
                                " data-checked='"+checked+"'" +
                                " class='pseudo-checkbox team-management-employee' " +
                                " id='team-management-employee-" + employees[i].employeeId + "'" +
                                ">" +
                                "</span>" +
                                "<span>" + employees[i].name + " " + employees[i].surname + "</span>" +
                                "</button>" +
                                "</div>" +
                                "<div>" +
                                "<button " +
                                " type='button' " +
                                " class='small-button' " +
                                " onclick='viewEmployeeDetails(" + employees[i].employeeId + ");'" +
                                ">" +
                                "<i class='fa fa-address-card' aria-hidden='true'></i> Dettagli" +
                                "</button>" +
                                "</div>" +
                                "</div>";
                        }
                    }
                    else {
                        for(i = 0; i < employees.length; i++) {
                            companyEmployeesHtml += "<div class='tr'>" +
                                "<div>" +
                                "<button" +
                                " type='button'" +
                                " class='pseudo-checkbox-button'" +
                                " onclick='toggleTeamManagementEmployee(" + employees[i].employeeId + ");'" +
                                ">" +
                                "<span " +
                                " data-checked='0'" +
                                " class='pseudo-checkbox team-management-employee' " +
                                " id='team-management-employee-" + employees[i].employeeId + "'" +
                                ">" +
                                "<span>" + employees[i].name + " " + employees[i].surname + "</span>" +
                                "</button>" +
                                "</div>" +
                                "<div>" +
                                "<button " +
                                " type='button' " +
                                " class='small-button' " +
                                " onclick='viewEmployeeDetails(" + employees[i].employeeId + ");'" +
                                ">" +
                                "<i class='fa fa-address-card' aria-hidden='true'></i> Dettagli" +
                                "</button>" +
                                "</div>" +
                                "</div>";
                        }
                    }
                    companyEmployeesHtml += "</div>";
                }
            }
            else {
                companyEmployeesHtml = "<div class='message error'>" + response.message + "</div>";
            }
            document.getElementById("team-components-container").innerHTML = companyEmployeesHtml;
        };
        xhttp.open("GET", BASE_URL + "/ws/getEmployeesAvailableForIntervention.php?companyId=" + companyId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    }
}

function ajaxSubmitInterventionTeamModifications() {
    var employeesCheckboxes = document.querySelectorAll(".team-management-employee");
    var newInterventionData = {};
    newInterventionData.teamComponents = [];
    newInterventionData.interventionId = selectedIntervention;
    newInterventionData.executor = parseInt(document.getElementById("team-management-executor-selector").value);
    for(var i = 0; i < employeesCheckboxes.length; i++) {
        if(employeesCheckboxes[i].getAttribute("data-checked")==="1") {
            newInterventionData.teamComponents.push(parseInt(employeesCheckboxes[i].id.replace("team-management-employee-", "")));
        }
    }
    closePopup();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Intervento modificato con successo");
            selectIntervention(selectedIntervention);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editInterventionExecutionTeam.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newInterventionData));
}

function ajaxRegisterAppVersion(appVersion,platform) {
    var requestData = {};
    requestData.appVersion = appVersion;
    requestData.platform = platform;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            console.log("Versione inviata al server");
        }
        else {
            console.error("Errore durante l'invio della versione dell'app al server");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/registerAppVersion.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxDeleteIntervention() {
    if(document.getElementById("delete-intervention-confirm-check").getAttribute("data-checked")!=="1") {
        errorPopup("Per eliminare un intervento è necessario selezionare la checkbox attraverso la quale si conferma di sapere quello che si sta facendo.");
    }
    else {
        var toDeleteIntervention = {};
        toDeleteIntervention.interventionId = selectedIntervention;
        closePopup();
        closePopup();
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    successToast("Intervento eliminato con successo");
                    changePage("page-calendar");
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toDeleteIntervention), xhttp.responseText);
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(toDeleteIntervention), xhttp.responseText);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/deleteIntervention.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(toDeleteIntervention));
    }
}

function finalizeDocumentDeletion(documentId) {
    documentIdToDelete = documentId;
    popup("delete-document", false, false, false);
}

function ajaxFinalizeDocumentDeletion() {
    var documentsToDelete = [];
    documentsToDelete.push(parseInt(documentIdToDelete));
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il documento è stato eliminato definitivamente");
                ajaxReloadDataRoomFilters();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteDocuments.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(documentsToDelete));
}

function manageAssetBuildingsGroupChanged() {
    var selectedCompany = globalFilterByCompany;
    var selectedBuildingsGroup = document.getElementById("manage-asset-buildings-group").value;
    var tempHtml = "";
    var found = 0;
    for(var i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
            for(var j = 0; j < buildings[i].associatedCompanies.length; j++) {
                if(buildings[i].associatedCompanies[j] == selectedCompany || selectedCompany === "") {
                    tempHtml += "<option value='" + buildings[i].buildingId + "'>" + buildings[i].name + "</option>";
                    found++;
                    break;
                }
            }
        }
    }
    if(found === 1) {
        document.getElementById("manage-asset-building").innerHTML = tempHtml;
        manageAssetBuildingChanged();
    }
    else {
        tempHtml += "<option value='' selected='selected'>-- Tutti --</option>";
        document.getElementById("manage-asset-building").innerHTML = tempHtml;
        document.getElementById("manage-asset-site").innerHTML = "<option value='' selected='selected'>-- Tutti --</option>";
        document.getElementById("manage-asset-floor").innerHTML = "<option value='' selected='selected'>-- Tutti --</option>";
        reloadAssetNavFilters();
    }
}

function manageAssetBuildingChanged() {
    var selectedCompany = globalFilterByCompany;
    var selectedBuilding = document.getElementById("manage-asset-building").value;
    var tempHtml = "", tempHtmlFloors="";
    var found = 0, foundFloors=0;
    for(var i = 0; i < sites.length; i++) {
        if(sites[i].buildingId == selectedBuilding) {
            if(sites[i].ownedBy == selectedCompany || selectedCompany === "") {
                tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
                found++;
                for(var j = 0; j < floors.length; j++) {
                    if(floors[j].siteId == sites[i].siteId) {
                            tempHtmlFloors += "<option value='" + floors[j].floorId + "'>" + floors[j].floorName + "</option>";
                            foundFloors++;
                    }
                }
            }
        }
    }
    if(found !== 1) {
        tempHtml += "<option value='' selected='selected'>-- Tutti --</option>";
    }
    document.getElementById("manage-asset-site").innerHTML = tempHtml;
    tempHtmlFloors += "<option value='' selected='selected'>-- Tutti --</option>";
    document.getElementById("manage-asset-floor").innerHTML = tempHtmlFloors;
    reloadAssetNavFilters();
}

var interventionInExecutionContainsTickets;

/**
 * A number, or a string containing a number.
 * @typedef {Object} Intervention
 * @property {Array} allExecCompanyEmployees
 *  @property {numeric} allExecCompanyEmployees.employeeId
 *  @property {String} allExecCompanyEmployees.employeeName
 *  @property {String} allExecCompanyEmployees.employeeSurname
 * @property {Array} activities
 *  @property {boolean} activities.activityIsTicket
 *  @property {?numeric} relatedCustomerTicketId
 * @property {Array} executionTeam
 *  @property {numeric} executionTeam.employeeId
 *  @property {String} executionTeam.employeeName
 *  @property {String} executionTeam.employeeSurname
 * @property {Array} relatedPictures
 * @property {numeric} siteId
 * @property {boolean} completed
 * @property {boolean} isDisabled
 * @property {boolean} siteManagerNotificationsSent
 * @property {boolean} executorNotificationsSent
 * @property {boolean} validationRequested
 * @property {?String} isoStartDate
 * @property {?String} plannedStartDate
 * @property {?String} isoEndDate
 * @property {?String} plannedEndDate
 * @property {?String} isoValidationDatetime
 * @property {String} siteName
 * @property {?String} outcome
 * @property {?String} state
 * @property {?String} validatorUsername
 * @property {?String} interventionExecutionNotes
 */

var assetInstanceManager = {
    getQrFromAsset: function(assetInstanceId){
        for(var i=0; i<preFetchedAssets.length; i++) {
            var assetInstance=preFetchedAssets[i];
            if(assetInstance.assetInstanceId==assetInstanceId){
                return assetInstance.qrCodeId;
            }
        }
        return null;
    },
    getAssetFromQr:function(qrCode){
        for(var i=0; i<preFetchedAssets.length; i++) {
            var assetInstance=preFetchedAssets[i];
            if(assetInstance.qrCodeId==qrCode){
                return assetInstance;
            }
        }
        return null;
    },
    getAssetFromAssetId:function(assetInstanceId){
        for(var i=0; i<preFetchedAssets.length; i++) {
            var assetInstance=preFetchedAssets[i];
            if(assetInstance.assetInstanceId==assetInstanceId){
                return assetInstance;
            }
        }
        return null;
    },
    refreshAssets: function(companyIdToRefresh){
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try{
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    if(globalFilterByCompany == companyIdToRefresh) {
                        preFetchedAssets = response.assetInstances;
                    }
                    if(db.localDb) {
                        db.updateAssetInstances(response.assetInstances, companyIdToRefresh, increaseUpdateCounter);
                    }
                }
            }
            catch(e){
                console.error(e);
            }
        };
        if(companyIdToRefresh) {
            xhttp.open("GET", BASE_URL + "/ws/syncAssetInstances.php?companyId=" + companyIdToRefresh, true);
        }
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    }
};

var interventionManager = {
    offlineInterventions: [],
    interventionsToValidate: [],
    /**
     * @type {Intervention}
     */
    currentInterventionData: {},
    refreshInterventionDetailsScroll: function(){
        scroll.refreshByName("intervention-details");
    },
    /**
     *
     * @type {Intervention}
     */
    selectIntervention: function(interventionData) {
        var i;
        interventionManager.currentInterventionData=interventionData;
        var interventionContainerTempHtml = "";
        var startInterventionButton = document.getElementById("start-intervention-button");
        var requestInterventionValidationButton = document.getElementById("request-validation-for-intervention-button");
        var validateInterventionButton = document.getElementById("validate-intervention-button");
        lastOpenedIntervention = interventionData;
        interventionInExecutionContainsTickets = false;
        interventionPage.subcategories = [];
        interventionPage.siteId = interventionData.siteId;
        var notExecutableBecauseOfCertifications=false;

        var executorCertificationsProblems="";
        if(interventionData.executorCertifications!=undefined&&interventionData.executorCertifications!=null) {
            interventionData.executorCertifications.forEach(function(certification){
                var errorHtmlBeginning;
                var renewalHtmlBeginning;
                var expired=false;
                if(certification.required) {
                    errorHtmlBeginning="<br><span class='intervention-executor-certification-error'><i class='fa fa-hand-paper-o' aria-hidden='true'></i> ";
                    renewalHtmlBeginning="<br><span class='intervention-executor-certification-warning'><i class='fa fa-clock-o' aria-hidden='true'></i> ";
                }
                else {
                    errorHtmlBeginning="<br><span class='intervention-executor-certification-warning'><i class='fa fa-exclamation-triangle' aria-hidden='true'></i> ";
                    renewalHtmlBeginning="<br><span class='intervention-executor-certification-info'><i class='fa fa-clock-o' aria-hidden='true'></i> ";
                }
                if(certification.missing) {
                    executorCertificationsProblems += errorHtmlBeginning + certification.name + " mancante</span>";
                }
                else {
                    if(new Date() > new Date(certification.expiration)) { //Certification expired
                        if(new Date() <= new Date(certification.gracePeriodEnd)) { //But in grace period
                            executorCertificationsProblems += renewalHtmlBeginning + certification.name + " in fase di rinnovo</span>";
                        }
                        else { //And not in grace period
                            executorCertificationsProblems += errorHtmlBeginning + certification.name + " scaduto</span>";
                            expired=true;
                        }
                    }
                }
                if((certification.missing || expired) && certification.required){
                    notExecutableBecauseOfCertifications=true;
                }
            });
        }

        var moveInterventionButton=document.getElementById("edit-intervention-move-intervention-button");
        var splitInterventionButton=document.getElementById("edit-intervention-split-intervention-button");
        var mergeInterventionButton=document.getElementById("edit-intervention-merge-intervention-button");
        var addActivitiesButton=document.getElementById("edit-intervention-add-activities-to-intervention-button");
        var teamManagementInterventionButton = document.getElementById("edit-intervention-team-management-button");
        var addAssetButton=document.getElementById("edit-intervention-add-asset");
        var executorSelector=document.getElementById("team-management-executor-selector");
        var reminderButton=document.getElementById("send-intervention-reminder-button");
        dom.addClass(reminderButton,"hidden");
        if(
            interventionData.state=="Non ancora eseguito"
            || interventionData.state==null
        ) {
            teamManagementInterventionButton.removeAttribute("disabled");
            moveInterventionButton.removeAttribute("disabled");
            splitInterventionButton.removeAttribute("disabled");
            mergeInterventionButton.removeAttribute("disabled");
            addActivitiesButton.removeAttribute("disabled");
            addAssetButton.removeAttribute("disabled");
            executorSelector.removeAttribute("disabled");
        }
        else {
            teamManagementInterventionButton.disabled="disabled";
            moveInterventionButton.disabled="disabled";
            splitInterventionButton.disabled="disabled";
            mergeInterventionButton.disabled="disabled";
            addActivitiesButton.disabled="disabled";
            addAssetButton.disabled="disabled";
            executorSelector.disabled="disabled";
        }

        var seenSubcategories = [];
        for(i = 0; i < interventionData.activities.length; i++) {
            if(seenSubcategories.indexOf(interventionData.activities[i].subcategoryId) < 0) {
                interventionPage.subcategories.push({
                    subcategoryId: interventionData.activities[i].subcategoryId,
                    subcategoryName: interventionData.activities[i].subcategory
                });
                seenSubcategories.push(interventionData.activities[i].subcategoryId);
            }
            if(
                interventionData.activities[i].activityIsTicket
                && interventionData.activities[i].relatedTicketType!="Intervento a contratto"
                && interventionData.activities[i].relatedTicketType!="Ordine"
                && (! interventionData.activities[i].relatedTicketType.toLowerCase().includes("ordine di lavoro"))
                && (! interventionData.activities[i].relatedTicketType.toLowerCase().includes("incluso nel canone"))
            ) {
                interventionInExecutionContainsTickets = true;
            }
            interventionContainerTempHtml += "<div class='entry" + (interventionData.activities[i].activityCompleted?" completed":"") + "' " + (interventionData.activities[i].activityIsTicket ? "onclick='selectTicket(" + interventionData.activities[i].relatedTicketId + ",true);'" : "onclick='selectInterventionActivity(" + interventionData.activities[i].interventionActivityId + ")'") +
                "><div class='entry-body'><div class='dots " + (interventionData.activities[i].activityIsTicket ? "is-ticket" : "") + "'>" +
                (interventionData.activities[i].activityIsTicket ? "<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>" : "") +
                "<div class='category-label'><div class='category-small b-" + interventionData.activities[i].categoryIconBackground + "'>" + interventionData.activities[i].categoryIcon +
                "</div>" + interventionData.activities[i].category + "</div>";
            if(interventionData.activities[i].subcategoryIcon != null) {
                interventionContainerTempHtml += '<div class="subcategory-label"><div class="category-small b-' + interventionData.activities[i].categoryIconBackground + '">' +
                    (interventionData.activities[i].subcategoryIcon) + '</div>' + interventionData.activities[i].subcategory + "</div></div>";
            }
            else {
                interventionContainerTempHtml += "<div class='subcategory-label'></div>";
            }
            interventionContainerTempHtml += ((interventionData.activities[i].relatedCustomerTicketId != null) ? ("Ticket n°" + interventionData.activities[i].relatedCustomerTicketId + " del " + iso8601.toEuropeanDate(interventionData.activities[i].isoRelatedTicketDate,"-") + ": ") : "") + interventionData.activities[i].activityDescription + '</div></div>';
        }
        setInnerHtml(
            document.getElementById("intervention-container"),
            interventionContainerTempHtml,
            function(){scroll.refreshByName("intervention-activities");}
        );
        var interventionTitle = "Intervento " + ((interventionData.parentInterventionId!=null)?interventionData.parentInterventionId:interventionData.interventionId);
        document.querySelector("#page-intervention .page-main-title").innerHTML = interventionTitle;
        if(interventionData.isDisabled) {
            setInnerHtml(
                document.getElementById("intervention-disabled-message"),
                "<strong class='disabled-intervention-text'><i class='fa fa-hand-paper-o' aria-hidden='true'></i> INTERVENTO DISABILITATO</strong><br><br>"
            );
            dom.addClass(document.querySelector("#page-intervention .second-col"), "disabled");
            dom.addClass(document.getElementById("disable-intervention-button"), "hidden");
            dom.removeClass(document.getElementById("enable-intervention-button"), "hidden");
        }
        else {
            setInnerHtml(document.getElementById("intervention-disabled-message"),"");
            dom.removeClass(document.querySelector("#page-intervention .second-col"), "disabled");
            dom.addClass(document.getElementById("enable-intervention-button"), "hidden");
            dom.removeClass(document.getElementById("disable-intervention-button"), "hidden");
        }

        //Drawing intervention events
        var topOffset = -10;
        var spaceBetweenNodes = 40;
        var leftOffset = 10;
        var graphHeight=10+spaceBetweenNodes*(4+interventionData.siteManagerNotificationsSent+interventionData.executorNotificationsSent);
        document.getElementById("intervention-events-graph").style="height:"+graphHeight+"px;";
        interventionEventsCytoscape.resize();
        interventionEventsCytoscape.elements().remove();
        interventionEventsCytoscape.reset();
        var previousEdge = null;
        var linkToPreviousEdge = function(currentEdge){
            if(previousEdge!=null){
                interventionEventsCytoscape.add({
                    group: "edges",
                    data: {
                        source: previousEdge,
                        target: currentEdge
                    }
                });
            }
            previousEdge = currentEdge;
        };

        //top details table
        tempHtml = "<div class='hth'>"+
            "<div class='tr'><div>Sito</div><div>" + interventionData.siteName + "</div></div>" +
            "<div class='tr'><div>Inizio pianificato</div><div>" + iso8601.toEuropeanDate(interventionData.isoPlannedStartDate) + "</div></div>";
        if(permissions["VIEW_INTERVENTION_EXECUTOR"] === "ACCESSIBLE") {
            tempHtml+="<div class='tr'><div>Azienda assegnata</div>" +
                "<div>" + ((interventionData.executor === null) ? "<span class='red-state'>Nessun esecutore assegnato</span>" : interventionData.executor) + executorCertificationsProblems + "</div></div>" +
                "<div class='tr'><div>Team assegnato</div><div>";
            if(interventionData.executionTeam.length > 0) {
                tempHtml += "<ul id='intervention-execution-team-components'>";
                for(var j = 0; j < interventionData.executionTeam.length; j++) {
                    tempHtml += "<li><i class='fa fa-user-circle' style='vertical-align:middle;' aria-hidden='true'></i>  " + interventionData.executionTeam[j].employeeName + " " + interventionData.executionTeam[j].employeeSurname + "</li>";
                }
                tempHtml += "</ul>";
            }
            else {
                tempHtml += "Nessun team specificato";
            }
            tempHtml+="</div></div>";
        }
        tempHtml+="</div>";
        //previous day
        if(interventionData.previousDayIntervention) {
            tempHtml+="<button class='previous-day-intervention' onclick='selectIntervention("+interventionData.previousDayIntervention+");'>" +
                "<i class='fa fa-chevron-left' aria-hidden='true'></i>"+
                "Giornata precedente<br>"
                +iso8601.toEuropeanDate(
                    interventionData.previousDayInterventionStartDate,
                    iso8601.toEuropeanDate(interventionData.previousDayInterventionPlannedStartDate,"")
                )+
                "</button>";
        }
        document.getElementById("intervention-details-top").innerHTML = tempHtml;
        var nodeId;
        //site manager notification sent
        if(interventionData.siteManagerNotificationsSent) {
            topOffset += spaceBetweenNodes;
            nodeId = "site-manager-notification-sent-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: " Inviata notifica al site manager",
                    subtitle: ""
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }

        //executors notification sent
        if(interventionData.executorNotificationsSent) {
            topOffset += spaceBetweenNodes;
            nodeId = "executors-notification-sent-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: " Inviata notifica agli esecutori",
                    subtitle: ""
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }

        //start date
        if(interventionData.isoStartDate) {
            topOffset+=spaceBetweenNodes;
            nodeId = "start-date-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: "Inizio intervento",
                    subtitle: iso8601.toEuropeanDate(interventionData.isoStartDate,"-")
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }
        else {
            //planned start date
            topOffset+=spaceBetweenNodes;
            nodeId = "planned-start-date-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot notcompleted",
                data: {
                    id: nodeId,
                    name: "Inizio intervento (previsto)" ,
                    subtitle: iso8601.toEuropeanDate(interventionData.isoPlannedStartDate,"-")
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }

        //end date
        if(interventionData.isoEndDate) {
            topOffset+=spaceBetweenNodes;
            nodeId = "end-date-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: "Fine intervento"+((interventionData.outcome !== null) ? ": "+interventionData.outcome:""),
                    subtitle: iso8601.toEuropeanDate(interventionData.isoEndDate,"-")
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }
        else {
            topOffset+=spaceBetweenNodes;
            nodeId = "planned-end-date-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot notcompleted",
                data: {
                    id: nodeId,
                    name: "Fine intervento (prevista)",
                    subtitle: iso8601.toEuropeanDate(interventionData.isoPlannedEndDate,"-")
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }

        //validation requested
        if(interventionData.validationRequested){
            topOffset+=spaceBetweenNodes;
            nodeId = "validation-requested-dot";
            interventionEventsCytoscape.add({
                group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: "Validazione richiesta",
                    subtitle: ""
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }
        else {
            //avoid showing validation not requested if validation has already been performed
            if(interventionData.validatorUsername==null) {
                topOffset+=spaceBetweenNodes;
                nodeId = "validation-not-requested-dot";
                interventionEventsCytoscape.add({
                    group: "nodes",
                    classes: "interventiondot notcompleted",
                    data: {
                        id: nodeId,
                        name: " Validazione non ancora richiesta",
                        subtitle:""
                    },
                    position: {x: leftOffset, y: topOffset}
                });
                linkToPreviousEdge(nodeId);
            }
        }

        //validation performed
        if(interventionData.validatorUsername) {
            topOffset+=spaceBetweenNodes;
            nodeId = "validation-performed-dot";
            interventionEventsCytoscape.add({
            group: "nodes",
                classes: "interventiondot completed",
                data: {
                    id: nodeId,
                    name: " Validazione effettuata da "+interventionData.validatorUsername,
                    subtitle: iso8601.toEuropeanDate(interventionData.isoValidationDatetime,"")
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }
        else{
            topOffset+=spaceBetweenNodes;
            nodeId = "validation-not-performed-dot";
            interventionEventsCytoscape.add({
            group: "nodes",
                classes: "interventiondot notcompleted",
                data: {
                    id: nodeId,
                    name: " Validazione non ancora effettuata",
                    subtitle: ""
                },
                position: {x: leftOffset, y: topOffset}
            });
            linkToPreviousEdge(nodeId);
        }

        tempHtml="";

        //following day
        if(interventionData.followingDayIntervention) {
            tempHtml+="<button class='following-day-intervention' onclick='selectIntervention("+interventionData.followingDayIntervention+");'>" +
                "<i class='fa fa-chevron-right' aria-hidden='true'></i>" +
                "Giornata successiva<br>" +
                iso8601.toEuropeanDate(
                    interventionData.followingDayInterventionStartDate,
                    iso8601.toEuropeanDate(interventionData.followingDayInterventionPlannedStartDate,"")
                )+
                "</button>";
        }
        if(interventionData.interventionExecutionNotes !== null) {
            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Note di fine intervento</span>";
            tempHtml += "<pre>" + interventionData.interventionExecutionNotes + "</pre>";
        }
        tempHtml += "<br>";
        if(interventionData.completed) {
            tempHtml += "<button type='button' class='intervention-bottom-buttons' onclick='interventionPage.viewDetailedInterventionExecutionReport(" + interventionData.interventionId + ");'><i class='fa fa-search' aria-hidden='true'></i><br>Dettagli<br>esito attività</button>";
            if(permissions["DOWNLOAD_INTERVENTION_REPORT"]==="ACCESSIBLE") {
                tempHtml += "<button type='button' class='intervention-bottom-buttons' onclick='downloadCompiledInterventionReportPDF(" + interventionData.interventionId + ");'><i class='fa fa-file-pdf-o'></i></span><br>Rapportino<br>compilato</button>";
            }
            if(interventionInExecutionContainsTickets) {
                if(permissions["VIEW_TECHNICAL_CONSUMPTIVE"] === "ACCESSIBLE") {
                    tempHtml += "<button type='button' class='intervention-bottom-buttons' onclick='interventionPage.viewTechnicalInterventionConsumptive(" + interventionData.interventionId + ");'><i class='fa fa-search' aria-hidden='true'></i><br>Consuntivo<br>tecnico</button>";
                }
                if(permissions["VIEW_TECHNICAL_CONSUMPTIVE"] === "DISABLED") {
                    tempHtml += "<button type='button' disabled='disabled' class='intervention-bottom-buttons'><i class='fa fa-search' aria-hidden='true'></i><br>Consuntivo<br>tecnico</button>";
                }
            }
        }
        else {
            if(permissions["DOWNLOAD_INTERVENTION_REPORT"]==="ACCESSIBLE") {
                tempHtml += "<button type='button' class='intervention-bottom-buttons' onclick='downloadInterventionReportPDF(" + interventionData.interventionId + ");'><i class='fa fa-file-pdf-o'></i><br>Scheda di lavoro</button>";
            }
        }
        if(interventionData.relatedPictures.length > 0) {
            tempHtml += "<br><span class='important-label' style='margin-left:0;'>Foto</span><br>";
            for(i = 0; i < interventionData.relatedPictures.length; i++) {
                tempHtml += "<img " +
                    "class='intervention-image' " +
                    "onload='interventionManager.refreshInterventionDetailsScroll();' " +
                    "onclick='imageTheater(\"" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + interventionData.relatedPictures[i] + "&authToken=" + currentAuthToken + "\");' " +
                    "src='" + DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + interventionData.relatedPictures[i] + "&height=160&authToken=" + currentAuthToken + "'> ";
            }
        }
        if((!interventionData.completed)&&(!interventionData.isDisabled)){
            // intervention is late
            if(((new Date(interventionData.isoPlannedEndDate))-(new Date()))<=0){
                if(permissions["SEND_INTERVENTION_REMINDER"]==="ACCESSIBLE"){
                    reminderButton.removeAttribute("disabled");
                    dom.removeClass(reminderButton,"hidden");
                }
                if(permissions["SEND_INTERVENTION_REMINDER"]==="DISABLED"){
                    reminderButton.disabled="disabled";
                    dom.removeClass(reminderButton,"hidden");
                }
            }
        }
        setInnerHtml(
            document.getElementById("intervention-details-bottom"),
            tempHtml,
            function(){scroll.refreshByName("intervention-details");}
        );
        document.getElementById("manage-intervention-button").style.visibility = "visible";
        document.getElementById("send-intervention-mail-button").style.visibility = "visible";
        document.getElementById("view-intervention-log-button").style.visibility = "visible";
        if(interventionData.completed) {
            startInterventionButton.style.display = "none";
            //If the intervention it's not validated yet
            if(!(interventionData.validatorUsername)) {
                if(interventionData.validationRequested){
                    switch(permissions["VALIDATE_INTERVENTION"]) {
                        case "ACCESSIBLE":
                            validateInterventionButton.removeAttribute("disabled");
                            validateInterventionButton.style.removeProperty("display");
                            break;
                        case "HIDDEN":
                            validateInterventionButton.style.display = "none";
                            break;
                        case "DISABLED":
                            validateInterventionButton.style.removeProperty("display");
                            validateInterventionButton.setAttribute("disabled", "disabled");
                            break;
                    }
                }
                else {
                    // Not a bug, only who can execute intervention can ask a validation
                    // (VALIDATE_INTERVENTION is for the validator, not for who asks validation)
                    switch(permissions["EXECUTE_INTERVENTION"]) {
                        case "ACCESSIBLE":
                            requestInterventionValidationButton.removeAttribute("disabled");
                            requestInterventionValidationButton.style.removeProperty("display");
                            break;
                        case "HIDDEN":
                            requestInterventionValidationButton.style.display = "none";
                            break;
                        case "DISABLED":
                            requestInterventionValidationButton.style.removeProperty("display");
                            requestInterventionValidationButton.setAttribute("disabled", "disabled");
                            break;
                    }
                }
            } //The intervention is already validated
            else {
                validateInterventionButton.style.display = "none";
                requestInterventionValidationButton.style.display = "none";
            }
        }
        else {
            validateInterventionButton.style.display = "none";
            requestInterventionValidationButton.style.display = "none";
            if(interventionData.isDisabled || notExecutableBecauseOfCertifications) {
                startInterventionButton.style.removeProperty("display");
                startInterventionButton.setAttribute("disabled", "disabled");
            }
            else {
                switch(permissions["EXECUTE_INTERVENTION"]) {
                    case "ACCESSIBLE":
                        startInterventionButton.removeAttribute("disabled");
                        startInterventionButton.style.removeProperty("display");
                        break;
                    case "HIDDEN":
                        startInterventionButton.style.display = "none";
                        break;
                    case "DISABLED":
                        startInterventionButton.style.removeProperty("display");
                        startInterventionButton.setAttribute("disabled", "disabled");
                        break;
                }
            }
        }
        interventionAdmittableMaxStartDate = iso8601.toDateObject(interventionData.admittableDates.isoStart);
        interventionAdmittableMinEndDate = iso8601.toDateObject(interventionData.admittableDates.isoEnd);
        //preloading data in move intervention popup
        document.getElementById("move-intervention-date").value = iso8601.toEuropeanDate(interventionData.isoPlannedStartDate,"");
        ui.pickerPopupButton.setValue("move-intervention-start-time", [
            iso8601.getHours(interventionData.isoPlannedStartDate),
            ":",
            iso8601.getMinutes(interventionData.isoPlannedStartDate)
        ]);
        ui.pickerPopupButton.setValue("move-intervention-end-time", [
            iso8601.getHours(interventionData.isoPlannedEndDate),
            ":",
            iso8601.getMinutes(interventionData.isoPlannedEndDate)
        ]);
    },
    getInterventionOffline: function(interventionId) {
        for(var i = 0; i < interventionManager.offlineInterventions.length; i++) {
            if(interventionManager.offlineInterventions[i].interventionId === interventionId) {
                return interventionManager.offlineInterventions[i];
            }
        }
        return null;
    },
    addEmployeeClosingInterventionPopup: function() {
        var added=0;
        var tempHtml="";
        interventionManager.currentInterventionData.allExecCompanyEmployees.forEach(function(employee){
            var found=false;
            interventionManager.currentInterventionData.executionTeam.forEach(function(employeeInExecution){
                if(employeeInExecution.employeeId==employee.employeeId){
                    found=true;
                }
            });
            if(!found) {
                tempHtml+="<option value='"+employee.employeeId+"'>"+employee.employeeName+" "+employee.employeeSurname+"</option>";
                added++;
            }
        });
        if(added===0){
            tempHtml="<option value=''>-- Nessun altro disponibile --</option>";
        }
        setInnerHtml(document.getElementById("add-executor-closing-intervention-employee"),tempHtml);
        popup("add-executor-closing-intervention", false, false, false);
    },
    addEmployeeClosingIntervention:function(employeeId){
        var employeeWorkTimeHtml="";
        interventionManager.currentInterventionData.allExecCompanyEmployees.forEach(function(employee){
            if(employee.employeeId==employeeId){
                employeeWorkTimeHtml+=" <div class='inline-form-component'>"+employee.employeeName+" "+employee.employeeSurname+"<br><button type='button' id='end-intervention-work-time-employee-"+employee.employeeId+"' onclick='ui.pickerPopupButton.open(this);'></button></div>";
                var newEmployee={};
                newEmployee.employeeId=employee.employeeId;
                newEmployee.employeeName=employee.employeeName;
                newEmployee.employeeSurname=employee.employeeSurname;
                interventionManager.currentInterventionData.executionTeam.push(newEmployee);
            }
        });
        //Must use innerHTML here to have a synchronous DOM change
        document.getElementById("end-intervention-executors-work-times").innerHTML+=employeeWorkTimeHtml;
        scroll.refreshPopupById("end-intervention", false);
        //Initializing picker
        ui.pickerPopupButton.initialize({
            id:"end-intervention-work-time-employee-"+employeeId,
            components:ui.pickerPopupButton.presets.durationPickerComponents
        });
    },
    refreshInterventions:function(companyIdToRefresh){
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try{
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    if(globalFilterByCompany == companyIdToRefresh) {
                        offlineInterventions = response.interventions;
                    }
                    if(db.localDb) {
                        db.updateInterventions(response.interventions, companyIdToRefresh, increaseUpdateCounter);
                    }
                }
            }
            catch(e){
                console.error(e);
            }
        };
        if(companyIdToRefresh) {
            xhttp.open("GET", BASE_URL + "/ws/syncInterventions.php?companyId=" + companyIdToRefresh, true);
        }
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send("");
    }
};

function ajaxGetInterventionData(interventionId, successCallback) {
    //Avoid blinking buttons that disappear immidiately
    var startInterventionButton = document.getElementById("start-intervention-button");
    var requestInterventionValidationButton = document.getElementById("request-validation-for-intervention-button");
    requestInterventionValidationButton.style.display = "none";
    var validateInterventionButton = document.getElementById("validate-intervention-button");
    validateInterventionButton.style.display = "none";
    startInterventionButton.style.display = "none";
    document.getElementById("manage-intervention-button").style.visibility = "hidden";
    document.getElementById("send-intervention-mail-button").style.visibility = "hidden";
    document.getElementById("view-intervention-log-button").style.visibility = "hidden";
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = INTERVENTION_LOADING_TIMEOUT;
    if(INTERVENTION_LOADING_TIMEOUT < 100000) {
        if(interventionManager.getInterventionOffline(interventionId)===null){
            console.log("Loading intervention from mobile, intervention NOT available offline - 100s timeout");
            xhttp.timeout = 100000; // 100 seconds
        }
        else {
            console.log("Loading intervention from mobile, intervention available offline - 13s timeout");
        }
    }
    else {
        console.log("Loading intervention from desktop - 100s timeout");
    }
    var timeoutErrorAbort = function() {
        dom.removeClass(document.getElementById("intervention-details-bottom"),"hidden");
        document.getElementById("intervention-container").innerHTML = "";
        ajaxSendErrorReport("Errore caricamento intervento (timeoutErrorAbort)", "Intervento "+interventionId, JSON.stringify(loggedInUserInfo));
        var offlineIntervention = interventionManager.getInterventionOffline(interventionId);
        if(offlineIntervention === null) {
            errorToast("Intervento non disponibile in modalità offline, connettersi ad Internet e riprovare");
            setInnerHtml(document.getElementById("intervention-details-top"), "<button class='retry-load-intervention' type='button' onclick='ajaxGetInterventionData(" + interventionId + ");'><i class='fa fa-refresh' aria-hidden='true'></i> Ritenta caricamento intervento</button>");
        }
        else {
            interventionManager.selectIntervention(offlineIntervention);
            if(typeof successCallback === "function") {
                successCallback();
            }
        }
    };
    //Removing elements from the previous opened intervention
    interventionEventsCytoscape.elements().remove();
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    document.getElementById("intervention-details-top").innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento intervento...</span>";
    document.getElementById("intervention-container").innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento attività...</span>";
    dom.addClass(document.getElementById("intervention-details-bottom"),"hidden");
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            interventionManager.selectIntervention(response);
            if(db.localDb) {
                var tmpInterventionArray = [];
                tmpInterventionArray.push(response);
                db.updateInterventions(tmpInterventionArray, currentCompanyId, function(){});
            }
            if(typeof successCallback === "function") {
                successCallback();
            }
        }
        else {
            changePage("page-home");
            setInnerHtml(outcomePopup, "<div class='message error'> Errore: " + response.message + "</div>");
            popup("outcome", true, false, false);
        }
        dom.removeClass(document.getElementById("intervention-details-bottom"),"hidden");
    };
    xhttp.open("GET", BASE_URL + "/ws/getIntervention.php?interventionId=" + interventionId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}


function ajaxGetInterventionActivityData(interventionActivityId) {
    var interventionActivityContainer = document.getElementById("intervention-activity-container");
    var tempHtml = "";
    interventionActivityContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            lastAssetInstances = response.assetInstances;
            document.getElementById("page-intervention-activity").querySelector(".page-main-title").innerHTML = "Intervento " + ((response.parentInterventionId!=null)?response.parentInterventionId:response.interventionId) + " - Attività " + interventionActivityId;
            var tempActivityDetailsHtml = "";
            tempActivityDetailsHtml += "<div class='second-col-header'>Dettagli attività</div>" +
                "<div class='site-label'><span class='site'>" + response.letter + "</span>" + response.siteName + "</div><br>" +
                "<div class='category-label'><div class='category-small b-" + response.categoryIconBackground + "'>" + response.categoryIcon + "</div>" + response.category + "</div>" +
                "<div class='subcategory-label'><div class='category-small b-" + response.categoryIconBackground + "'>" + response.subcategoryIcon + "</div>" + response.subcategory + "</div>" +
                "<br><br><br>" +
                "<div class='activity-on-assets-progress-counter-container'>Asset completati per questa attività: " + response.completedAssetsCounter + " di " + response.totalAssetsCounter + "</div>" +
                "<strong>Descrizione</strong><br>" + response.activityDescription;
            var interventionActivityAssetsCounter = document.getElementById("assets-involved-into-intervention-activity-counter");
            document.getElementById("intervention-activity-details").innerHTML = tempActivityDetailsHtml;
            scroll.refreshByName("intervention-activity-details");
            if(response.assetInstances.length === 0) {
                tempHtml = "<div class='no-results-message'>Nessun asset coinvolto da mostrare</div>";
                setInnerHtml(interventionActivityAssetsCounter,"");
            }
            else {
                tempHtml = "<div id='assets-in-intervention-activity-table'>";
                setInnerHtml(interventionActivityAssetsCounter,"("+response.assetInstances.length+")");
                response.assetInstances.forEach(function(assetInstance, assetIndex){
                    var subcategoryName="";
                    assetInstance.subcategories.forEach(function(subcategory){
                        if(subcategory.deletion == null) {
                            subcategoryName = subcategory.name;
                        }
                    });
                    var additionalClass = assetInstance.assetActivityCompleted?" completed":"";
                    tempHtml += "<div class='entry"+additionalClass+"' onclick='selectAssetInstancePreview(" + assetIndex + ")'>" +
                        "<div class='asset-entry-title'>"+
                        "<strong>"+subcategoryName+"</strong>"+
                        ((assetInstance.qrCodeId !== null) ? ("<div class='entry-right-header'><i class='fa fa-qrcode qr-icon' aria-hidden='true'></i><strong> QR </strong>"+assetInstance.qrCodeId+"</div>") : "") +
                        "</div>"+
                        ((assetInstance.brand !== null) ? ("<div class='inline-asset-detail'><strong>Marca </strong>" + assetInstance.brand + "</div>") : "")  +
                        ((assetInstance.model != null)?("<div class='inline-asset-detail'><strong>Modello </strong>"+ assetInstance.model + "</div>") : "") +
                        ((assetInstance.internalCode !== null) ? ("<div class='inline-asset-detail'><strong>Codice interno </strong>" + assetInstance.internalCode + "</div>") : "") +
                        ((assetInstance.registrationNumber !== null) ? ("<div class='inline-asset-detail'><strong>Matricola </strong>" + assetInstance.registrationNumber + "</div>") : "") +
                        "</div>";
                });
            }
            setInnerHtml(
                interventionActivityContainer,
                tempHtml,
                function(){scroll.refreshByName("intervention-activity-assets");}
            );
        }
        else {
            setInnerHtml(
                interventionActivityContainer,
                "<div class='message error'> Errore: " + response.message + "</div>",
                function(){scroll.refreshByName("intervention-activity-assets");}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInterventionActivity.php?interventionActivityId=" + interventionActivityId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxGetContracts() {
    var contractContainer = document.getElementById("contracts-container");
    setInnerHtml(
        contractContainer,
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){
            scroll.refreshByName("contracts");
        }
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "";
            if(response.contracts.length < 1) {
                tempHtml = "<div class='no-results-message'>Nessun contratto presente</div>";
            }
            else {
                for(var i = 0; i < response.contracts.length; i++) {
                    tempHtml += renderContractEntry(response.contracts[i]);
                }
            }
            setInnerHtml(contractContainer, tempHtml, function(){
                scroll.refreshByName("contracts");
            });
        }
        else {
            setInnerHtml(
                contractContainer,
                "<div class='message error'> Errore: " + response.message + "</div>",
                function(){
                    scroll.refreshByName("contracts");
                }
            );
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL + "/ws/getContracts.php?companyId=" + globalFilterByCompany, true);
    }
    else {
        xhttp.open("GET", BASE_URL + "/ws/getContracts.php", true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxLoadPlans() {
    var planContainer = document.getElementById("plan-container");
    setInnerHtml(
        planContainer,
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){
            scroll.refreshByName("plan");
        }
    );
    tempHtml="";
    categoryGroups.forEach(function(categoryGroup){
        categories.forEach(function(category){
            "<ons-list-item expandable>"+category.name+"<div class='expandable-content'>content</div></ons-list-item>";
            
            /*if(category.categoryGroup==)
            subcategoryGroups.forEach(function(subcategoryGroup){
                subcategories.forEach(function(subcategory){

                })
            })
            */
        });
    });
}

function changeMapFloor(floor) {
    switch(floor) {
        case -1:
            document.getElementById("building-map-img").src = "src/img/p-1.jpeg";
            document.getElementById("floor-minus-1").disabled = "disabled";
            document.getElementById("floor-0").removeAttribute("disabled");
            document.getElementById("floor-1").removeAttribute("disabled");
            break;
        case 0:
            document.getElementById("building-map-img").src = "src/img/p0.jpeg";
            document.getElementById("floor-0").disabled = "disabled";
            document.getElementById("floor-minus-1").removeAttribute("disabled");
            document.getElementById("floor-1").removeAttribute("disabled");
            break;
        case 1:
            document.getElementById("building-map-img").src = "src/img/p1.jpeg";
            document.getElementById("floor-1").disabled = "disabled";
            document.getElementById("floor-0").removeAttribute("disabled");
            document.getElementById("floor-minus-1").removeAttribute("disabled");
            break;
    }
}
function openCompetenceAreaSettings(){
    window.location.href="https://facility.claster.it/dist/#/settings/competence-areas";
    //window.location.href="/dist/index.html/#/settings/competence-areas";
    //location.assign("dist/#/settings/competence-areas");
}
window.openCompetenceAreaSettings=openCompetenceAreaSettings;
function openFloorManagement(siteId,floorId){
    window.location.href="https://facility.claster.it/dist/#/site/"+siteId+"/floor/"+floorId;
    //location.assign("dist/#/site/"+siteId+"/floor/"+floorId);
}
window.openFloorManagement=openFloorManagement;
function viewBuildingsOrganization() {
    var selectedBuildingGroup = parseInt(document.getElementById("manage-buildings-buildings-group").value);
    window.localStorage.setItem("cfm_bg", selectedBuildingGroup);
    /*PATCH*/
    switch(selectedBuildingGroup) {
        case 2: //CC le isole
            dom.addClass(document.getElementById("floor-buttons"), "hidden");
            document.getElementById("building-map-img").src = "src/img/piantina-isole.png";
            dom.addClass(document.getElementById("building-map"), "hidden");
            dom.removeClass(document.getElementById("building-map-img"), "hidden");
            break;
        case 26: //CC Tipo
        case 28: //CC due
            dom.removeClass(document.getElementById("floor-buttons"), "hidden");
            document.getElementById("building-map-img").src = "src/img/p0.jpeg";
            document.getElementById("floor-minus-1").removeAttribute("disabled");
            document.getElementById("floor-1").removeAttribute("disabled");
            document.getElementById("floor-0").disabled = "disabled";
            dom.addClass(document.getElementById("building-map"), "hidden");
            dom.removeClass(document.getElementById("building-map-img"), "hidden");
            break;
        default:
            dom.addClass(document.getElementById("floor-buttons"), "hidden");
            dom.removeClass(document.getElementById("building-map"), "hidden");
            dom.addClass(document.getElementById("building-map-img"), "hidden");
            break;
    }
    /*END PATCH*/
    var el = document.getElementById('manage-buildings-buildings-group');
    var selectedLabel = el.options[el.selectedIndex].innerHTML;
    var tempHtml = "<button id='building-group-selector-btn-" + selectedBuildingGroup + "' class='building-group-selector-btn' onclick='viewBuildingsGroupDetails(" + selectedBuildingGroup + ");'><span class='fa-stack fa-lg fa-same-grouped-icons'><i class='fa fa-building fa-stack-1x'></i><i class='fa fa-building fa-stack-1x'></i><i class='fa fa-building fa-stack-1x'></i></span>" + selectedLabel + "</button>" +
        "<ul class='fa-ul tree-view'>";
    for(var i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingGroup) {
            tempHtml += "<li><button id='building-selector-btn-" + buildings[i].buildingId + "' class='building-selector-btn' onclick='viewBuildingDetails(" + buildings[i].buildingId + ");'><i class='fa fa-building'></i> " + buildings[i].name + "</button>";
            tempHtml += "<ul class='fa-ul'>";
            for(var j = 0; j < sites.length; j++) {
                if(sites[j].buildingId == buildings[i].buildingId) {
                    if(sites[j].isCommon) {
                        tempHtml += "<li><button id='site-selector-btn-" + sites[j].siteId + "' class='site-selector-btn' onclick='viewSiteDetails(" + sites[j].siteId + ",true);'><i class='fa fa-cube'></i><i class='fa fa-users'></i> " + sites[j].name + "</button><ul>";
                    }
                    else {
                        tempHtml += "<li><button id='site-selector-btn-" + sites[j].siteId + "' class='site-selector-btn' onclick='viewSiteDetails(" + sites[j].siteId + ",false);'><i class='fa fa-cubes'></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" + sites[j].name + "</button><ul>";
                    }
                    for(var k=0; k<floors.length; k++) {
                        if(floors[k].siteId == sites[j].siteId) {
                            tempHtml += "<li><button id='floor-selector-btn-" + floors[k].floorId + "' class='floor-selector-btn' onclick='openFloorManagement("+sites[j].siteId+","+floors[k].floorId+")'><i class='fa fa-window-minimize'></i> " + floors[k].floorName + "</button></li>";
                        }
                    }
                    for(var k=0; k<areas.length; k++) {
                        if(areas[k].siteId == sites[j].siteId) {
                            tempHtml += "<li><button id='floor-selector-btn-" + areas[k].areaId + "' class='floor-selector-btn'><i class='fa fa-cube'></i> " + areas[k].code +" "+ areas[k].name + "</button></li>";
                        }
                    }
                    tempHtml += "</ul></li>";
                }
            }
            tempHtml += "</ul></li>";
        }
    }
    tempHtml += "</ul>";
    document.getElementById("buildings-organization-viewer").innerHTML = tempHtml;
    scroll.refreshByName("buildings-tree");
    viewBuildingsGroupDetails(selectedBuildingGroup);
}

function clearBuildingsTreeSelection() {
    var btnsToClear = document.querySelectorAll(".site-selector-btn");
    var i;
    for(i = 0; i < btnsToClear.length; i++) {
        dom.removeClass(btnsToClear[i], "selected");
    }
    btnsToClear = document.querySelectorAll(".building-selector-btn");
    for(i = 0; i < btnsToClear.length; i++) {
        dom.removeClass(btnsToClear[i], "selected");
    }
    btnsToClear = document.querySelectorAll(".building-group-selector-btn");
    for(i = 0; i < btnsToClear.length; i++) {
        dom.removeClass(btnsToClear[i], "selected");
    }
}

function viewBuildingsGroupDetails(buildingGroupId) {
    lastFetchedBuildingsGroup = null;
    clearBuildingsTreeSelection();
    dom.removeClass(document.getElementById("edit-buildings-group-button"), "hidden");
    dom.removeClass(document.getElementById("buildings-create-button"), "hidden");
    dom.addClass(document.getElementById("edit-building-button"), "hidden");
    dom.addClass(document.getElementById("edit-site-button"), "hidden");
    dom.removeClass(document.getElementById("delete-buildings-group-button"), "hidden");
    dom.addClass(document.getElementById("delete-building-button"), "hidden");
    dom.addClass(document.getElementById("delete-site-button"), "hidden");
    dom.addClass(document.getElementById("building-group-selector-btn-" + buildingGroupId), "selected");
    var detailsContainer = document.getElementById("building-details");
    detailsContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                lastFetchedBuildingsGroup = response.buildingsGroup;
                var tempHtml = "<span class='important-label'>Dettagli gruppo di edifici</span>";
                tempHtml += "<div class='building-details-table hth'>";
                tempHtml += "<div class='tr'><div>Nome gruppo</div><div><strong>" + response.buildingsGroup.name + "</strong></div></div>";
                tempHtml += "<div class='tr'><div>Amministratore</div><div>" + ((response.buildingsGroup.administratorName == null) ? "-" : response.buildingsGroup.administratorName) + "</div></div>";
                tempHtml += "<div class='tr'><div>Proprietario</div><div>" + ((response.buildingsGroup.ownerName == null) ? "-" : response.buildingsGroup.ownerName) + "</div></div>";
                tempHtml += "<div class='tr'><div>Indirizzo</div><div>" + ((response.buildingsGroup.street == null) ? "-" : response.buildingsGroup.street) + ((response.buildingsGroup.streetNumber == null) ? "" : (", " + response.buildingsGroup.streetNumber)) + "</div></div>";
                tempHtml += "<div class='tr'><div>Città</div><div>" + ((response.buildingsGroup.city == null) ? "-" : response.buildingsGroup.city) + "</div></div>";
                tempHtml += "<div class='tr'><div>Provincia</div><div>" + ((response.buildingsGroup.district == null) ? "-" : response.buildingsGroup.district) + "</div></div>";
                tempHtml += "<div class='tr'><div>CAP</div><div>" + ((response.buildingsGroup.zipCode == null) ? "-" : response.buildingsGroup.zipCode) + "</div></div>";
                tempHtml += "</div>";
                detailsContainer.innerHTML = tempHtml;
                scroll.refreshByName("building-details");
            }
            else {
                detailsContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
                scroll.refreshByName("building-details");
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
        }
        catch(e) {
            detailsContainer.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            scroll.refreshByName("building-details");
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getBuildingsGroup.php?buildingsGroupId=" + buildingGroupId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function viewBuildingDetails(buildingId) {
    lastFetchedBuilding = null;
    clearBuildingsTreeSelection();
    dom.addClass(document.getElementById("edit-buildings-group-button"), "hidden");
    dom.removeClass(document.getElementById("buildings-create-button"), "hidden");
    dom.removeClass(document.getElementById("edit-building-button"), "hidden");
    dom.addClass(document.getElementById("edit-site-button"), "hidden");
    dom.addClass(document.getElementById("delete-buildings-group-button"), "hidden");
    dom.removeClass(document.getElementById("delete-building-button"), "hidden");
    dom.addClass(document.getElementById("delete-site-button"), "hidden");
    dom.addClass(document.getElementById("building-selector-btn-" + buildingId), "selected");
    var detailsContainer = document.getElementById("building-details");
    detailsContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                //showing details
                lastFetchedBuilding = response.building;
                var tempHtml = "<span class='important-label'>Dettagli edificio</span>";
                tempHtml += "<div class='building-details-table hth'>";
                tempHtml += "<div class='tr'><div>Nome edificio</div><div><strong>" + response.building.name + "</strong></div></div>";
                tempHtml += "<div class='tr'><div>Particella catastale</div><div>" + ((response.building.cadastralParcel == null) ? "-" : response.building.cadastralParcel) + "</div></div>";
                tempHtml += "</div>";
                detailsContainer.innerHTML = tempHtml;
                scroll.refreshByName("building-details");
            }
            else {
                detailsContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
                scroll.refreshByName("building-details");
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
        }
        catch(e) {
            detailsContainer.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            scroll.refreshByName("building-details");
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getBuilding.php?buildingId=" + buildingId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function viewSiteDetails(siteId, isCommon) {
    lastFetchedSite = null;
    clearBuildingsTreeSelection();
    dom.addClass(document.getElementById("edit-buildings-group-button"), "hidden");
    dom.removeClass(document.getElementById("buildings-create-button"), "hidden");
    dom.addClass(document.getElementById("edit-building-button"), "hidden");
    dom.removeClass(document.getElementById("edit-site-button"), "hidden");
    dom.addClass(document.getElementById("delete-buildings-group-button"), "hidden");
    dom.addClass(document.getElementById("delete-building-button"), "hidden");
    dom.removeClass(document.getElementById("delete-site-button"), "hidden");
    dom.addClass(document.getElementById("site-selector-btn-" + siteId), "selected");
    var detailsContainer = document.getElementById("building-details");
    detailsContainer.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                lastFetchedSite = response.site;
                var tempHtml = "<div id='site-details-container'>";
                tempHtml += "<span class='important-label'>Dettagli sito</span>";
                tempHtml += "<div class='building-details-table hth'>";
                tempHtml += "<div class='tr'><div>Nome sito</div><div><strong>" + response.site.name + "</strong></div></div>";
                tempHtml += "<div class='tr'><div>Tipologia di sito</div><div>" + (response.site.isCommon ? "Zona comune" : "Unità immobiliare") + "</div></div>";
                tempHtml += "<div class='tr'><div>Indirizzo</div><div>" + ((response.site.street == null) ? "-" : response.site.street) + ((response.site.streetNumber == null) ? "" : (", " + response.site.streetNumber)) + "</div></div>";
                tempHtml += "<div class='tr'><div>Città</div><div>" + ((response.site.zipCode == null) ? "" : (response.site.zipCode + " - ")) + (((response.site.city == null) ? "-" : response.site.city) + ((response.site.district == null) ? "" : (" (" + response.site.district + ")"))) + "</div></div>";
                tempHtml += "<div class='tr'><div>Coordinate GPS<br>(Lat, Long)</div><div>" + ((response.site.latitude === null) ? "-" : response.site.latitude) + ", " + ((response.site.longitude === null) ? "-" : response.site.longitude) + "</div></div>";
                tempHtml += "<div class='tr'><div>Piano</div><div>" + ((response.site.floor === null) ? "-" : response.site.floor) + "</div></div>";
                tempHtml += "<div class='tr'><div>Interno</div><div>" + ((response.site.internalNumber === null) ? "-" : response.site.internalNumber) + "</div></div>";
                tempHtml += "<div class='tr'><div>Numero unità</div><div>" + ((response.site.siteId == 42) ? "25" : "-") + "</div></div>";
                tempHtml += "<div class='tr'><div>Gross Leasable Area</div><div>" + ((response.site.siteId == 42) ? "98mq" : "-") + "</div></div>";
                tempHtml += "<div class='tr'><div>Gestore</div><div>" + ((response.site.ownerName == null) ? "-" : response.site.ownerName) + "</div></div>";
                tempHtml += "<div class='tr'><div>Tenant</div><div>" + ((response.site.tenantName == null) ? "-" : response.site.tenantName) + "</div></div>";
                tempHtml += "<div class='tr'><div>Codice ATECO</div><div>" + ((response.site.atecoCode == null) ? "-" : response.site.atecoCode) + "</div></div>";
                tempHtml += "</div></div>";

                tempHtml += "<div id='site-cadastral-data-container'>";
                tempHtml += "<span class='important-label'>Dati catastali</span>";
                tempHtml += "<div class='building-details-table hth'>";
                tempHtml += "<div class='tr'><div>Foglio</div><div>" + ((response.site.cadastralSheet === null) ? "-" : response.site.cadastralSheet) + "</div></div>";
                tempHtml += "<div class='tr'><div>Mappale</div><div>" + ((response.site.cadastralMap === null) ? "-" : response.site.cadastralMap) + "</div></div>";
                tempHtml += "<div class='tr'><div>Subalterno</div><div>" + ((response.site.cadastralSubaltern === null) ? "-" : response.site.cadastralSubaltern) + "</div></div>";
                tempHtml += "<div class='tr'><div>Zona</div><div>" + ((response.site.cadastralZone === null) ? "-" : response.site.cadastralZone) + "</div></div>";
                tempHtml += "<div class='tr'><div>Consistenza</div><div>" + ((response.site.cadastralConsistency === null) ? "-" : response.site.cadastralConsistency) + "</div></div>";
                tempHtml += "<div class='tr'><div>Rendita</div><div>" + ((response.site.cadastralRevenue === null) ? "-" : response.site.cadastralRevenue) + "</div></div>";
                tempHtml += "<div class='tr'><div>Categoria</div><div>" + ((response.site.cadastralCategory === null) ? "-" : response.site.cadastralCategory) + "</div></div>";
                tempHtml += "<div class='tr'><div>Classe</div><div>" + ((response.site.cadastralClass === null) ? "-" : response.site.cadastralClass) + "</div></div>";
                tempHtml += "</div></div>";

                tempHtml += "<div>";
                tempHtml += "<span class='important-label'>Comunicazioni disattivate</span>";
                tempHtml += "<div class='building-details-table hth'>";
                response.site.disabledSendingSubcategories.forEach(function(disabledSubcategory) {
                    tempHtml += "<div class='tr'><div>"+getSubcategoryName(disabledSubcategory.subcategoryId)+"</div><div>" + (disabledSubcategory.disableMailNotification?"Notifiche mail disabilitate":"") + ((disabledSubcategory.disableMailNotification&&disabledSubcategory.disableValidationRequestMail)?"<br>":"") + (disabledSubcategory.disableValidationRequestMail?"Rich. di validazione disabilitate":"") + "</div></div>";
                });
                tempHtml += "</div>";
                if(response.site.siteId==42){
                    tempHtml += "<div>";
                    tempHtml += "<span class='important-label'>Efficienza energetica</span>";
                    tempHtml += "<div class='building-details-table hth'>";
                    tempHtml += "<div class='tr'><div>Classe energetica</div><div><div class='energy-class-a'>A</div><div class='energy-class-arrow-a'></div></div></div>";
                    tempHtml += "<div class='tr'><div>Registrata il</div><div>12/12/2018</div></div>";
                    tempHtml += "</div>";
                    tempHtml += "</div>";

                    tempHtml += "<div>";
                    tempHtml += "<span class='important-label'>Autorizzativo</span>";
                    tempHtml += "<div class='building-details-table hth'>";
                    tempHtml += "<div class='tr'><div>Permesso di costruire</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Permesso di costruire.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "<div class='tr'><div>SCIA</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='SCIA.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "</div>";

                    tempHtml += "<div>";
                    tempHtml += "<span class='important-label'>Dichiarazioni di conformità</span>";
                    tempHtml += "<div class='building-details-table hth'>";
                    tempHtml += "<div class='tr'><div>Impianto elettrico</div><div>"+
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Dich.Conf.imp.elettrico.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "<div class='tr'><div>Impianto idrico</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Dich.Conf.imp.idrico.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "<div class='tr'><div>Impianto termico</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Dich.Conf.imp.termico.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "</div>";
                    tempHtml += "</div>";

                    tempHtml += "<div>";
                    tempHtml += "<span class='important-label'>Proprietà e gestione</span>";
                    tempHtml += "<div class='building-details-table hth'>";
                    tempHtml += "<div class='tr'><div>Atto di acquisto</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Atto di acquisto.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "<div class='tr'><div>Atto di vendita</div><div>" +
                        "<a href='https://claster.it/atto-1235.pdf' target='_blank'>" +
                        "<div class='inline-form-component'><input title='Documento' type='text' class='popup-field' value='Atto di vendita.pdf'><button type='button' class='popup-field-button'><i class='fa fa-file-pdf-o' aria-hidden='true'></i></button></div>" +
                        "</a></div></div>";
                    tempHtml += "<div class='tr'><div>Cessioni</div><div>-</div></div>";
                    tempHtml += "<div class='tr'><div>Servitù</div><div>-</div></div>";
                    tempHtml += "</div>";
                    tempHtml += "</div>";
                }
                detailsContainer.innerHTML = tempHtml;
                scroll.refreshByName("building-details");
            }
            else {
                detailsContainer.innerHTML = "<div class='message error'> Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
                scroll.refreshByName("building-details");
            }
        }
        catch(e) {
            detailsContainer.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            scroll.refreshByName("building-details");
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getSite.php?siteId=" + siteId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function loadBuildingGroupEditor() {
    if(lastFetchedBuildingsGroup != null) {
        document.getElementById("edit-buildings-group-buildings-group-id").value = lastFetchedBuildingsGroup.buildingsGroupId;
        document.getElementById("edit-buildings-group-name").value = lastFetchedBuildingsGroup.name;
        document.getElementById("edit-buildings-group-street-name").value = lastFetchedBuildingsGroup.street;
        document.getElementById("edit-buildings-group-street-number").value = lastFetchedBuildingsGroup.streetNumber;
        document.getElementById("edit-buildings-group-city").value = lastFetchedBuildingsGroup.city;
        document.getElementById("edit-buildings-group-district").value = lastFetchedBuildingsGroup.district;
        document.getElementById("edit-buildings-group-zip-code").value = lastFetchedBuildingsGroup.zipCode;
        document.getElementById("edit-buildings-group-admin-name").value = lastFetchedBuildingsGroup.administratorName;
        document.getElementById("edit-buildings-group-admin-id").value = lastFetchedBuildingsGroup.administratorId;
        document.getElementById("edit-buildings-group-owner-name").value = lastFetchedBuildingsGroup.ownerName;
        document.getElementById("edit-buildings-group-owner-id").value = lastFetchedBuildingsGroup.ownerId;
        document.getElementById("edit-buildings-group-owner-admin-match").setAttribute("data-checked",(lastFetchedBuildingsGroup.ownerId === lastFetchedBuildingsGroup.administratorId?"1":"0"));
        editBuildingsGroupOwnerAdminMatchChanged();
        popup("edit-buildings-group", true, false, false);
    }
    else {
        warning("Attendere il caricamento dei dati relativi al gruppo di edifici");
    }

}

function loadBuildingEditor() {
    if(lastFetchedBuilding != null) {
        document.getElementById("edit-building-building-id").value = lastFetchedBuilding.buildingId;
        document.getElementById("edit-building-buildings-group").value = lastFetchedBuilding.buildingGroup;
        document.getElementById("edit-building-name").value = lastFetchedBuilding.name;
        document.getElementById("edit-building-cadastral-parcel").value = lastFetchedBuilding.cadastralParcel;
        popup("edit-building", true, false, false);
    }
    else {
        warning("Attendere il caricamento dei dati relativi all'edificio");
    }
}

function ajaxLoadSiteEditor(siteId) {
    lastFetchedSite = null;
    setInnerHtml(outcomePopup, "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>");
    popup("outcome", false, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                lastFetchedSite = response.site;
                closePopup();
                loadSiteEditor(true, true);
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'> Errore: " + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getSite.php?siteId=" + siteId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

//useAvailableForSiteEdit is used when managing buildings and sites which belong to a company different than the global filter
function loadSiteEditor(dontCallUpdateEditSiteAvailbleBuildings, useAvailableForSiteEdit) {
    if(lastFetchedSite != null) {
        if(!useAvailableForSiteEdit) {
            builidingGroupsAvailableForSiteEdit = buildingsGroups;
            buildingsAvailableForSiteEdit = buildings;
        }
        //else buildingsAvailableForSiteEdit and builidingGroupsAvailableForSiteEdit are already loaded
        var tempHtml = "";
        var tempHtml2 = "";
        for(var i = 0; i < builidingGroupsAvailableForSiteEdit.length; i++) {
            tempHtml += "<option value='" + builidingGroupsAvailableForSiteEdit[i].buildingGroupId + "'>" + builidingGroupsAvailableForSiteEdit[i].name + "</option>";
            for(var j = 0; j < buildingsAvailableForSiteEdit.length; j++) {
                if(buildingsAvailableForSiteEdit[j].buildingsGroup == builidingGroupsAvailableForSiteEdit[i].buildingGroupId) {
                    tempHtml2 += "<option value='" + buildingsAvailableForSiteEdit[j].buildingId + "'>" + buildingsAvailableForSiteEdit[j].name + "</option>";
                }
            }
        }
        document.getElementById("edit-site-buildings-group").innerHTML = tempHtml;
        document.getElementById("edit-site-building").innerHTML = tempHtml2;
        document.getElementById("edit-site-buildings-group").value = lastFetchedSite.buildingsGroupId;
        if(!dontCallUpdateEditSiteAvailbleBuildings) {
            updateEditSiteAvailbleBuildings();
        }
        document.getElementById("edit-site-building").value = lastFetchedSite.buildingId;
        document.getElementById("edit-site-site-id").value = lastFetchedSite.siteId;
        document.getElementById("edit-site-name").value = decodeHTMLEntities(lastFetchedSite.name);
        document.getElementById("edit-site-owner-id").value = lastFetchedSite.ownedBy;
        document.getElementById("edit-site-owner-name").value = decodeHTMLEntities(lastFetchedSite.ownerName);
        document.getElementById("edit-site-type").value = ((lastFetchedSite.siteType === null) ? "" : lastFetchedSite.siteType);
        document.getElementById("edit-site-is-common-unit").setAttribute("data-checked",lastFetchedSite.isCommon?"1":"0");
        document.getElementById("edit-site-is-active").setAttribute("data-checked",lastFetchedSite.isActive?"1":"0");
        document.getElementById("edit-site-floor").value = lastFetchedSite.floor;
        document.getElementById("edit-site-internal-number").value = lastFetchedSite.internalNumber;
        document.getElementById("edit-site-site-manager-mail").value = lastFetchedSite.siteManagerMailTo;
        editSiteManagerCcTaggle.removeAll();
        editSiteExecutorsCcTaggle.removeAll();
        if(lastFetchedSite.siteManagerMailCC) {
            editSiteManagerCcTaggle.add(lastFetchedSite.siteManagerMailCC.split(","));
        }
        if(lastFetchedSite.executorsMailCC) {
            editSiteExecutorsCcTaggle.add(lastFetchedSite.executorsMailCC.split(","));
        }
        document.getElementById("edit-site-cadastral-sheet").value = lastFetchedSite.cadastralSheet;
        document.getElementById("edit-site-cadastral-map").value = lastFetchedSite.cadastralMap;
        document.getElementById("edit-site-cadastral-subaltern").value = lastFetchedSite.cadastralSubaltern;
        document.getElementById("edit-site-cadastral-zone").value = lastFetchedSite.cadastralZone;
        document.getElementById("edit-site-consistency").value = lastFetchedSite.cadastralConsistency;
        document.getElementById("edit-site-revenue").value = lastFetchedSite.cadastralRevenue;
        document.getElementById("edit-site-cadastral-category").value = ((lastFetchedSite.cadastralCategory === null) ? "" : lastFetchedSite.cadastralCategory);
        document.getElementById("edit-site-cadastral-class").value = lastFetchedSite.cadastralClass;
        document.getElementById("edit-site-ateco-code").value = decodeHTMLEntities(lastFetchedSite.atecoCode);
        document.getElementById("edit-site-responsible-for-prevention-and-safety").value = decodeHTMLEntities(lastFetchedSite.responsibleForPreventionAndSafety);
        document.getElementById("edit-site-insiders-for-prevention-and-safety").value = decodeHTMLEntities(lastFetchedSite.insidersForPreventionAndSafety);
        document.getElementById("edit-site-medic").value = decodeHTMLEntities(lastFetchedSite.medic);
        document.getElementById("edit-site-emergency-team").value = decodeHTMLEntities(lastFetchedSite.emergencyTeam);
        document.getElementById("edit-site-first-aid-team").value = decodeHTMLEntities(lastFetchedSite.firstAidTeam);
        document.getElementById("edit-site-workers-safety-representatives").value = decodeHTMLEntities(lastFetchedSite.workersSafetyRepresentatives);
        document.getElementById("edit-site-fire-log-book-responsible").value = decodeHTMLEntities(lastFetchedSite.fireLogBookResponsible);
        document.getElementById("edit-site-latitude").value = lastFetchedSite.latitude;
        document.getElementById("edit-site-longitude").value = lastFetchedSite.longitude;
        document.getElementById("edit-site-fire-prevention-certificate-number").value = lastFetchedSite.firePreventionCertificateNumber;
        document.getElementById("edit-site-fire-prevention-certificate-date").value = iso8601.toEuropeanDate(lastFetchedSite.firePreventionCertificateDate,"");
        if(editSiteFirePreventionCertificateDate && editSiteFirePreventionCertificateDate.destroy) {
            editSiteFirePreventionCertificateDate.destroy();
        }
        editSiteFirePreventionCertificateDate = new Pikaday( {
            field: document.getElementById("edit-site-fire-prevention-certificate-date"),
            maxDate: new Date(),
            firstDay: 1,
            onSelect: function(date) {
                setInnerHtml(document.querySelectorAll("#edit-site-fire-prevention-certificate-date .composite-picker-value")[0],iso8601.toEuropeanDate(date.toISOString(),""));
            },
            format: 'DD/MM/YYYY'
        });
        defineTapListener("clear-edit-site-fire-prevention-certificate-date",function(){
            editSiteFirePreventionCertificateDate.setDate(null);
            setInnerHtml(
                document.querySelectorAll("#edit-site-fire-prevention-certificate-date .composite-picker-value")[0],
                "- - / - - / - - - -"
            );
        });
        setInnerHtml(
            document.querySelectorAll("#edit-site-fire-prevention-certificate-date .composite-picker-value")[0],
            iso8601.toEuropeanDate(lastFetchedSite.firePreventionCertificateDate,"- - / - - / - - - -")
        );
        if(lastFetchedSite.firePreventionCertificateDate == null){
            editSiteFirePreventionCertificateDate.setDate(null);
        }
        document.getElementById("edit-site-fire-prevention-certificate-expiration").value = iso8601.toEuropeanDate(lastFetchedSite.firePreventionCertificateExpiration,"");
        if(editSiteFirePreventionCertificateExpiration && editSiteFirePreventionCertificateExpiration.destroy) {
            editSiteFirePreventionCertificateExpiration.destroy();
        }
        editSiteFirePreventionCertificateExpiration = new Pikaday({
            field: document.getElementById("edit-site-fire-prevention-certificate-expiration"),
            firstDay: 1,
            onSelect: function(date) {
                setInnerHtml(document.querySelectorAll("#edit-site-fire-prevention-certificate-expiration .composite-picker-value")[0],iso8601.toEuropeanDate(date.toISOString(),""));
            },
            format: 'DD/MM/YYYY'
        });
        defineTapListener("clear-edit-site-fire-prevention-certificate-expiration",function(){
            editSiteFirePreventionCertificateExpiration.setDate(null);
            setInnerHtml(
                document.querySelectorAll("#edit-site-fire-prevention-certificate-expiration .composite-picker-value")[0],
                "- - / - - / - - - -"
            );
        });
        setInnerHtml(
            document.querySelectorAll("#edit-site-fire-prevention-certificate-expiration .composite-picker-value")[0],
            iso8601.toEuropeanDate(lastFetchedSite.firePreventionCertificateExpiration,"- - / - - / - - - -")
        );
        if(lastFetchedSite.firePreventionCertificateExpiration == null){
            editSiteFirePreventionCertificateExpiration.setDate(null);
        }
        document.getElementById("edit-site-firefighters-command").value = lastFetchedSite.firefightersCommand;
        document.getElementById("edit-site-header-line-1").value = lastFetchedSite.headerLine1;
        document.getElementById("edit-site-header-line-2").value = lastFetchedSite.headerLine2;
        document.getElementById("edit-site-header-line-3").value = lastFetchedSite.headerLine3;
        document.getElementById("edit-site-header-line-4").value = lastFetchedSite.headerLine4;
        document.getElementById("edit-site-header-line-5").value = lastFetchedSite.headerLine5;
        document.getElementById("edit-site-header-line-6").value = lastFetchedSite.headerLine6;
        document.getElementById("edit-site-header-line-7").value = lastFetchedSite.headerLine7;
        document.getElementById("edit-site-header-logo-id").value = lastFetchedSite.headerImage;

        document.getElementById("edit-site-require-durc").setAttribute("data-checked",(lastFetchedSite.requiredDocuments.DURC!=undefined)?"1":"0");
        document.getElementById("edit-site-require-duvri").setAttribute("data-checked",(lastFetchedSite.requiredDocuments.DUVRI!=undefined)?"1":"0");
        document.getElementById("edit-site-require-visura-camerale").setAttribute("data-checked",(lastFetchedSite.requiredDocuments["Visura camerale"]!=undefined)?"1":"0");
        document.getElementById("edit-site-require-quietanza-premio").setAttribute("data-checked",(lastFetchedSite.requiredDocuments["Quietanza premio"]!=undefined)?"1":"0");
        document.getElementById("edit-site-require-assicurazione").setAttribute("data-checked",(lastFetchedSite.requiredDocuments["Assicurazione"]!=undefined)?"1":"0");

        popup("edit-site", true, false, false);
    }
    else {
        warning("Attendere il caricamento dei dati relativi al sito");
    }
}

function ajaxGetConsumptive() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        var tempHtml = "";
        if(xhttp.status === 200) {
            tempHtml = "<table>";
            tempHtml += "<tr><th>Sito</th><th>Voce di spesa</th><th>Totale spese [EUR]</th><th>Di cui IVA [EUR]</th>";
            for(var i = 0; i < response.consumptive.length; i++) {
                tempHtml += "<tr><td>" + response.consumptive[i].siteName + "</td><td>" + response.consumptive[i].expenditureItem + '</td><td>' + (response.consumptive[i].untaxedAmount + response.consumptive[i].vat + response.consumptive[i].otherTaxes).toFixed(2) + '</td><td>' + response.consumptive[i].vat.toFixed(2) + '</td></tr>';
            }
            tempHtml += "</table>";
        }
        else {
            tempHtml = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        setInnerHtml(
            document.querySelector("#view-consumptive-popup .popup-content div"),
            tempHtml,
            function(){scroll.refreshPopupById("view-consumptive", false);}
        );
    };
    xhttp.open("GET", BASE_URL + "/ws/getConsumptive.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxViewContract(contractId) {
    popup("view-contract", false, false, false);
    var viewContractPopup = document.querySelector("#view-contract-popup .popup-content div");
    setInnerHtml(
        viewContractPopup,
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){scroll.refreshPopupById("view-contract", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        var tempHtml = "";
        if(xhttp.status === 200) {
            lastOpenedContract = response.contract;
            tempHtml = "<div><div id='contract-details-table' class='hth'>";
            tempHtml += "<div class='tr'><div>N° contratto</div><div>" + response.contract.contractId + "</div></div>";
            tempHtml += "<div class='tr'><div>Appaltatore</div><div>" + response.contract.contractorName + "</div></div>";
            tempHtml += "<div class='tr'><div>Cliente</div><div>" + response.contract.customerName + "</div></div>";
            tempHtml += "<div class='tr'><div>Inizio contratto</div><div>" + response.contract.startDate + "</div></div>";
            tempHtml += "<div class='tr'><div>Fine contratto</div><div>" + response.contract.endDate + "</div></div>";
            tempHtml += "<div class='tr'><div>Stato contratto</div><div>" + response.contract.state + "</div></div>";
            tempHtml += "<div class='tr'><div>Contratto attivo</div><div>" + ((response.contract.withdrawalDate === null) ? "si" : "no") + "</div></div>";
            if(response.contract.withdrawalDate !== null) {
                tempHtml += "<div class='tr'><div>Data recesso</div><div>" + response.contract.withdrawalDate + "</div></div>";
            }
            tempHtml += "</div>";
            tempHtml += "<br><button type='button' disabled='disabled'><i class='fa fa-times' aria-hidden='true'></i> Recedi dal contratto</button>";
            switch(permissions["CREATE_CONTRACT"]) {
                case "ACCESSIBLE":
                    tempHtml += " <button type='button' onclick='ajaxCloneContract(" + response.contract.contractId + ");'><i class='fa fa-clone' aria-hidden='true'></i> Clona contratto</button>";
                    break;
                case "DISABLED":
                    tempHtml += " <button type='button' disabled='disabled'><i class='fa fa-clone' aria-hidden='true'></i> Clona contratto</button>";
                    break;
            }
            tempHtml += " <button type='button' onclick='populateExportContractSpecCategories(" + response.contract.contractId + ");'><i class='fa fa-file-pdf-o' aria-hidden='true'></i> Esporta capitolato contratto</button>";
            tempHtml += "<br><br><br><div>";
            for(var i = 0; i < response.contract.activities.length; i++) {
                //TODO: resolve this problem
                if(response.contract.activities[i].frequency !== 0) {
                    tempHtml += "<div class='entry'>" +
                        "<div class='contract-details-top-row'>" +
                        "<div class='contract-details-category-icon'><div class='category-small b-" + response.contract.activities[i].subcategoryBackground + "'>" + response.contract.activities[i].subcategoryIcon + "</div></div>" +
                        "<div class='contract-details-category'> " + response.contract.activities[i].subcategoryName + "</div>" +
                        "<div class='contract-details-site'><i class='fa fa-map-marker' aria-hidden='true'></i> " + response.contract.activities[i].siteName + "</div>" +
                        "</div>" +
                        "<div class='contract-details-activity-description'>" +
                        "<div class='contract-details-frequency'>ogni " + response.contract.activities[i].frequency + " " + response.contract.activities[i].frequencyType + "</div>" +
                        response.contract.activities[i].activityDescription + "</div>" +
                        "</div>";
                }
            }
            tempHtml += "</div><br></div>";
        }
        else {
            tempHtml = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        setInnerHtml(
            viewContractPopup,
            tempHtml,
            function(){scroll.refreshPopupById("view-contract", false);}
        );
    };
    xhttp.open("GET", BASE_URL + "/ws/getContract.php?contractId=" + contractId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function resetCompanySearchField() {
    var vatNumber = document.getElementById("search-company-vat-number");
    var searchState = document.getElementById("search-company-vat-number-state");
    var searchCompanyButton = document.getElementById("search-company-button");
    var addSearchedCompanyButton = document.getElementById("add-searched-company-button");
    dom.removeClass(vatNumber, "b-green");
    dom.removeClass(vatNumber, "b-red");
    dom.removeClass(searchCompanyButton, "hidden");
    dom.addClass(addSearchedCompanyButton, "hidden");
    searchState.innerHTML = "";
}

/*
 *  visualRepresentationInput will contain the textual name of the found company
 *  realValueInput will contain the Id of that company
 */
function searchOrAddCompany(visualRepresentationInput, realValueInput) {
    inputRequestedRealValueField = document.getElementById(realValueInput);
    inputRequestedVisualValueField = document.getElementById(visualRepresentationInput);
    popup("search-or-add-company", true, false, false);
}

function searchAssetForQrCode() {
    document.getElementById("search-asset-for-qr-code-site").value = "";
    document.getElementById("search-asset-for-qr-code-category").value = "";
    document.getElementById("search-asset-for-qr-code-subcategory").value = "";
    document.getElementById("search-results-asset-for-qr-code").innerHTML="<span class='tip'>Per visualizzare degli asset impostare i filtri di ricerca <strong>sito</strong> e <strong>sottocategoria</strong>.<br>Verranno mostrati solo gli asset sprovvisti di QR Code</span>";
    setInnerHtml(
        document.getElementById("search-results-asset-for-qr-code"),
        "<span class='tip'>Per visualizzare degli asset impostare i filtri di ricerca <strong>sito</strong> e <strong>sottocategoria</strong>.<br>Verranno mostrati solo gli asset sprovvisti di QR Code</span>",
        function(){scroll.refreshPopupById("search-asset-for-qr-code", false);}
    );
    popup("search-asset-for-qr-code", false, false, false);
}

function ajaxGetCompanyByVatNumber() {
    var vatNumber = document.getElementById("search-company-vat-number");
    var searchState = document.getElementById("search-company-vat-number-state");
    var searchCompanyButton = document.getElementById("search-company-button");
    var addSearchedCompanyButton = document.getElementById("add-searched-company-button");
    searchState.innerHTML = "<i class='fa fa-cog fa-spin'></i> Caricamento";
    dom.removeClass(vatNumber, "b-green");
    dom.removeClass(vatNumber, "b-red");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            var response = JSON.parse(xhttp.responseText);
            //request ok
            if(response.companyId != null) {
                document.getElementById("searched-company-result-name").value = response.companyName;
                document.getElementById("searched-company-result-id").value = response.companyId;
                searchState.innerHTML = "<i class='fa fa-check-circle' aria-hidden='true'></i> " + response.companyName;
                dom.addClass(vatNumber, "b-green");
                dom.addClass(searchCompanyButton, "hidden");
                dom.removeClass(addSearchedCompanyButton, "hidden");
            }
            else {
                searchState.innerHTML = "<i class='fa fa-exclamation-circle' aria-hidden='true'></i> Nessun risultato";
                dom.addClass(vatNumber, "b-red");
                dom.addClass(addSearchedCompanyButton, "hidden");
            }
        }
        else {
            //request error
            searchState = "<i class='fa fa-exclamation-circle' aria-hidden='true'></i> Errore durante l'elaborazione";
            dom.addClass(addSearchedCompanyButton, "hidden");
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getCompanyIdByVatNumberOrFiscalCode.php?vatNumberOrFiscalCode=" + vatNumber.value.toUpperCase(), true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function registerSearchCompanyResult() {
    inputRequestedRealValueField.value = document.getElementById("searched-company-result-id").value;
    inputRequestedVisualValueField.value = document.getElementById("searched-company-result-name").value;
    //the input references are no longer needed
    inputRequestedRealValueField = null;
    inputRequestedVisualValueField = null;
    closePopup();//Close search or insert company or person popup
}

function popupCreateAsset() {
    setInnerHtml(document.querySelector("#add-asset-popup .popup-header span"), "Crea asset");
    document.getElementById("add-asset-site").removeAttribute("disabled");
    if(document.getElementById("add-asset-buildings-group").length > 1) {
        document.getElementById("add-asset-buildings-group").value = "";
    }
    if(document.getElementById("add-asset-building").length > 1) {
        document.getElementById("add-asset-building").value = "";
    }
    setInnerHtml(document.getElementById("add-asset-site"), "<option value='' selected='selected' disabled='disabled'>-- Selezionare edificio --</option>");
    dom.removeClass(document.getElementById("add-asset-buildings-group-container"), "hidden");
    dom.removeClass(document.getElementById("add-asset-building-container"), "hidden");
    document.getElementById("add-asset-causal").value = "";
    document.getElementById("add-asset-position-hint").value = "";
    popup("add-asset", true, false, false);
}

function popupCompanyLicenses() {
    ajaxGetCompanyLicenses();
    popup("manage-company-licenses", false, false, false);
}

function ajaxGetCompanyLicenses() {
    var popupContent = document.getElementById("manage-company-licenses-container");
    setInnerHtml(
        popupContent,
        "<i class='fa fa-cog fa-spin'></i> Caricamento",
        function(){scroll.refreshPopupById("manage-company-licenses", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var tempHtml="";
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var companyLicenses = response.companyLicenses;
                for(var i = 0; i < companyLicenses.length; i++) {
                    var employeesHtml = "";
                    var j;
                    for(j = 0; j < companyLicenses[i].employees.length; j++) {
                        employeesHtml += "<li><i class='fa fa-user-circle' style='vertical-align:middle;' aria-hidden='true'></i> ";
                        if(companyLicenses[i].employees[j].hasAccount) {
                            employeesHtml += "<i class='fa fa-key' style='vertical-align:middle;' aria-hidden='true'></i> ";
                        }
                        else {
                            employeesHtml += "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
                        }
                        employeesHtml += companyLicenses[i].employees[j].name + " " + companyLicenses[i].employees[j].surname + "</li>";
                    }
                    var sitesHtml = "";
                    for(j = 0; j < companyLicenses[i].sites.length; j++) {
                        sitesHtml += "<li><i class='fa fa-cube' style='vertical-align:middle;' aria-hidden='true'></i> ";
                        sitesHtml += companyLicenses[i].sites[j].name + "</li>";
                    }
                    tempHtml += "<div class='entry'>" +
                        "<div class='entry-top-row'><span class='license-company-name'>" + companyLicenses[i].companyName + "</span>" +
                        "<span class='license-id'> ID:" + companyLicenses[i].companyId + "</span></div>" +
                        "<div class='license-type'><label><input type='checkbox' " + ((companyLicenses[i].isCustomer) ? "checked='checked'" : "") + "><span>Cliente</span></label><br>" +
                        "<label><input type='checkbox' " + ((companyLicenses[i].isContractor) ? "checked='checked'" : "") + "><span>Fornitore</span></label></div>" +
                        "<div class='license-employees'><ul>" + employeesHtml + "</ul></div>" +
                        "<div class='license-sites'><ul>" + sitesHtml + "</ul></div>" +
                        "<div class='license-actions'>" +
                        " <button type='button' onclick='ajaxManageLinkedCompany(" + companyLicenses[i].companyId + ");'><i class='fa fa-link' aria-hidden='true'></i> Anagrafiche collegate</button>" +
                        " <button type='button' onclick='popupEditCompany(" + companyLicenses[i].companyId + ");'><i class='fa fa-pencil' aria-hidden='true'></i> Modifica</button>" +
                        " <button type='button' onclick='addUserToCompanyPopup(" + companyLicenses[i].companyId + ");'><i class='fa fa-user-plus' aria-hidden='true'></i> Crea utente</button>" +
                        " <button type='button' onclick='ajaxManageCompanyProperties(" + companyLicenses[i].companyId + "," + companyLicenses[i].isCustomer + ");' class='" + ((companyLicenses[i].isCustomer) ? "" : "disabled") + "'><i class='fa fa-cube' aria-hidden='true'></i> Siti</button>" +
                        "</div>" +
                        "</div>";
                }
            }
            else {
                tempHtml="<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            }
            setInnerHtml(
                popupContent,
                tempHtml,
                function(){scroll.refreshPopupById("manage-company-licenses", false);}
            );
        }
        catch(e) {
            setInnerHtml(
                popupContent,
                "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>",
                function(){scroll.refreshPopupById("manage-company-licenses", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getCompanyLicenses.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function addUserToCompanyPopup(companyId) {
    popup("add-user", true, false, false);
    document.getElementById("new-user-employee-of-company").value = companyId;
}

function ajaxGetEditCompany(companyId) {
    document.getElementById("edit-company-company-id").value = companyId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                loadDataIntoFormEditCompany(response.company);
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                popup("outcome", true, false, false);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            popup("outcome", true, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getCompany.php?companyId=" + companyId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function popupCreateSupplier() {
    setInnerHtml(document.querySelector("#add-company-popup .popup-title"), "Creazione fornitore");
    document.getElementById("add-company-supplier").value = "1";
    document.getElementById("add-company-customer").value = "0";
    document.getElementById("add-company-is-license").value = "0";
    popup("add-company", true, false, false);
}

function addCompanyFromLicense(companyId) {
    document.getElementById("create-company-linked-to-license-company-id").value = companyId;
    popup("create-company-linked-to-license", true, false, false);
}

function popupCreateSupplierLicense() {
    setInnerHtml(document.querySelector("#add-company-popup .popup-title"), "Creazione licenza fornitore");
    document.getElementById("add-company-supplier").value = "1";
    document.getElementById("add-company-customer").value = "0";
    document.getElementById("add-company-is-license").value = "1";
    popup("add-company", true, false, false);
}

function popupCreateCustomerLicense() {
    setInnerHtml(document.querySelector("#add-company-popup .popup-title"), "Creazione licenza cliente");
    document.getElementById("add-company-supplier").value = "1";
    document.getElementById("add-company-customer").value = "0";
    document.getElementById("add-company-is-license").value = "1";
    popup("add-company", true, false, false);
}

function popupCreateBothCustomerAndSupplierLicense() {
    setInnerHtml(document.querySelector("#add-company-popup .popup-title"), "Creazione licenza cliente e fornitore");
    document.getElementById("add-company-supplier").value = "1";
    document.getElementById("add-company-customer").value = "1";
    document.getElementById("add-company-is-license").value = "1";
    popup("add-company", true, false, false);
}

function popupCreateCustomer() {
    setInnerHtml(document.querySelector("#add-company-popup .popup-title"), "Creazione cliente");
    document.getElementById("add-company-supplier").value = "0";
    document.getElementById("add-company-customer").value = "1";
    document.getElementById("add-company-is-license").value = "0";
    popup("add-company", false, false, false);
}

function popupEditCompany(companyId) {
    document.getElementById("edit-company-company-id").value = companyId;
    document.getElementById("edit-company-success-callback").value = "reload-company-licenses";
    dom.addClass(document.getElementById("edit-public-company"),"hidden");
    ajaxGetEditCompany(companyId);
    popup("edit-company-information", true, false, false);
}

function ajaxUnlinkCompanyFromLicense(companyId) {
    var companyDetails = {};
    companyDetails.companyId = parseInt(companyId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Anagrafica aziendale scollegata dalla licenza");
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/unlinkCompanyFromLicense.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(companyDetails));
}

function popupUnlinkCompanyFromLicense(companyId) {
    document.getElementById("confirm-unlink-company-from-license-company-id").value = companyId;
    popup("confirm-unlink-company-from-license", false, false, false);
}

function ajaxManageLinkedCompany(companyId) {
    popup("manage-linked-company-anagraphics", true, false, false);
    var popupContent = document.querySelector("#manage-linked-company-anagraphics-popup .popup-content div");
    setInnerHtml(
        popupContent,
        "<div><i class='fa fa-cog fa-spin'></i> Caricamento</div>",
        function(){scroll.refreshPopupById("manage-linked-company-anagraphics", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            var tempHtml = "";
            if(xhttp.status === 200) {
                var linkedCompanies = response.linkedCompanies;
                tempHtml += "<div>";
                tempHtml += "<button type='button' onclick='addCompanyFromLicense(" + companyId + ");'><i class='fa fa-plus' aria-hidden='true'></i> Aggiungi anagrafica</button>";
                tempHtml += "</div><br>";
                tempHtml += "<table>";
                tempHtml += "<tr><th>ID</th><th>Azienda</th><th>Registrata da</th><th>Azioni</th></tr>";
                for(var i = 0; i < linkedCompanies.length; i++) {
                    tempHtml += "<tr><td>" + linkedCompanies[i].companyId + "</td>" +
                        "<td>" + linkedCompanies[i].companyName + "</td>" +
                        "<td>" + linkedCompanies[i].dataOwnerCompanyName + "</td>" +
                        "<td>" +
                        " <button type='button' onclick='popupEditCompany(" + linkedCompanies[i].companyId + ");' class='rounded'><i class='fa fa-pencil' aria-hidden='true'></i> Modifica</button>" +
                        " <button type='button' onclick='popupUnlinkCompanyFromLicense(" + linkedCompanies[i].companyId + ");' class='rounded'><i class='fa fa-unlink' aria-hidden='true'></i> Scollega anagrafica</button>" +
                        "</td>" +
                        "</tr>";
                }
                tempHtml += "</table>";
            }
            else {
                tempHtml = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            }
            setInnerHtml(
                popupContent,
                tempHtml,
                function(){scroll.refreshPopupById("manage-linked-company-anagraphics", false);}
            );
        }
        catch(e) {
            setInnerHtml(
                popupContent,
                "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>",
                function(){scroll.refreshPopupById("manage-linked-company-anagraphics", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getLinkedCompanies.php?companyId=" + companyId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxCreateCompanyLinkedToLicense(licenseCompanyId, registeredByCompanyId) {
    var requestData = {};
    requestData.licenseCompanyId = licenseCompanyId;
    requestData.registeredByCompanyId = registeredByCompanyId;

    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            successToast("Anagrafica aziendale creata con successo");
            closePopup();
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "Non è stato possibile creare l'azienda.";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createCompanyLinkedToLicense.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxManageCompanyProperties(companyId, isCustomer) {
    if(!isCustomer) {
        warning("Solo le aziende con una licenza di tipo <strong>Cliente</strong> possono possedere dei siti.<br>Le aziende con licenza di tipo <strong>Fornitore</strong> eseguono interventi sui siti delle aziende con licenza di tipo <strong>Cliente</strong>.");
        return;
    }
    popup("manage-company-properties", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml;
            var buildingsGroups = response.buildingsGroup;
            var buildings = response.buildings;
            var sites = response.sites;
            buildingsAvailableForSiteEdit = buildings;
            builidingGroupsAvailableForSiteEdit = buildingsGroups;
            if(buildingsGroups.length === 0) {
                tempHtml = "<div class='tip centered-tip'>Nessun immobile registrato.</div>";
            }
            else {
                tempHtml = "<ul>";
                for(var i = 0; i < buildingsGroups.length; i++) {
                    tempHtml += "<li>";
                    tempHtml += "<button class='building-group-selector-btn'>";
                    tempHtml += "<span class='fa-stack fa-lg fa-same-grouped-icons'><i class='fa fa-building fa-stack-1x'></i><i class='fa fa-building fa-stack-1x'></i><i class='fa fa-building fa-stack-1x'></i></span>";
                    tempHtml += buildingsGroups[i].name + "</button><br>";
                    for(var j = 0; j < buildings.length; j++) {
                        tempHtml += "<ul>";
                        if(buildings[j].buildingsGroup == buildingsGroups[i].buildingGroupId) {
                            tempHtml += "<li><button class='building-selector-btn'>";
                            tempHtml += "<i class='fa fa-building'></i>";
                            tempHtml += buildings[j].name + "</button><br>";
                            tempHtml += "<ul>";
                            for(var k = 0; k < sites.length; k++) {
                                if(buildings[j].buildingId == sites[k].buildingId) {
                                    tempHtml += "<li>";
                                    tempHtml += "<button class='site-selector-btn' onclick='ajaxLoadSiteEditor(" + sites[k].siteId + ")'>";
                                    tempHtml += "<i class='fa fa-cube'></i>";
                                    tempHtml += sites[k].name + "</button><br>";
                                    tempHtml += "</li>";
                                }
                            }
                            tempHtml += "</ul>";
                            tempHtml += "</li>";
                        }
                        tempHtml += "</ul>";
                    }
                    tempHtml += "</li>";
                }
                tempHtml += "</ul>";
            }
            setInnerHtml(
                document.getElementById("manage-company-properties-tree"),
                tempHtml,
                function(){
                    scroll.refreshPopupById("manage-company-properties", false);
                }
            );
        }
        else {
            setInnerHtml(
                document.getElementById("manage-company-properties-tree"),
                "<div class='message error'> Errore: " + response.message + "</div>",
                function(){
                    scroll.refreshPopupById("manage-company-properties", false);
                }
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getCompanyProperties.php?companyId=" + companyId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxGetExpenditureItems() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var viewExpenditureItemsPopup = document.querySelector("#view-expenditure-items-popup .popup-content div");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        var tempHtml;
        if(xhttp.status === 200) {
            tempHtml = "<table>";
            tempHtml += "<tr><th>Voce di spesa</th><th>Unità di misura</th><th>Elimina</th></tr>";
            for(var i = 0; i < response.expenditureItems.length; i++) {
                if(response.expenditureItems[i].unitOfMeasure != null) {
                    tempHtml += "<tr><td>" + response.expenditureItems[i].name + "</td><td>" + response.expenditureItems[i].unitOfMeasure + "</td>";
                }
                else {
                    tempHtml += "<tr><td>" + response.expenditureItems[i].name + "</td><td> Nessuna </td>";
                }
                tempHtml += '<td><button type="button" class="rounded" onclick="ajaxDeleteExpenditureItem(' + response.expenditureItems[i].expenditureItemId + ');return false;"><i class="fa fa-trash"></i> Elimina</button></td></tr>';
            }
            tempHtml += "</table>";
        }
        else {
            tempHtml = "<div class='message error'> Errore: " + response.message + "</div>";
        }
        setInnerHtml(
            viewExpenditureItemsPopup,
            tempHtml,
            function(){scroll.refreshPopupById("view-expenditure-items", false);}
        );
    };
    xhttp.open("GET", BASE_URL + "/ws/getExpenditureItems.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxDeleteEstimates() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            outcomePopup.innerHTML = "Preventivi eliminati correttamente";
            popup("outcome", false, false, false);
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "Non è stato possibile eliminare i preventivi, probabilmente sono utilizzati altrove nel sistema.";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteEstimates.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("accountingYearId=" + accountingYearId);
}

function ajaxDeleteExpenditureItem(expenditureItemId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            popup("view-expenditure-items", false, false, false);
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>Non è stato possibile eliminare la voce di spesa, probabilmente è utilizzata in preventivi o fatture.</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteExpenditureItem.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("expenditureItemId=" + expenditureItemId);
}

function ajaxDeleteCommonSiteRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            popup("view-repartition-tables-common-sites", false, false, false);
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "Non è stato possibile eliminare la tabella, probabilmente è in uso nel sistema.";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteCommonSiteRepartitionTable.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("repartitionTableId=" + repartitionTableId);
}

function ajaxDeleteBuildingRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            popup("view-repartition-tables-buildings", false, false, false);
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "Non è stato possibile eliminare la tabella, probabilmente è in uso nel sistema.";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteBuildingRepartitionTable.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("repartitionTableId=" + repartitionTableId);
}

function ajaxDeleteBuildingsGroupRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            popup("view-repartition-tables-buildings-group", false, false, false);
            loadAccountingDashboard();
        }
        else {
            outcomePopup.innerHTML = "Non è stato possibile eliminare la tabella, probabilmente è in uso nel sistema.";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteBuildingsGroupRepartitionTable.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("repartitionTableId=" + repartitionTableId);
}

function ajaxUpdateAvailableIntallmentsExpirations() {
    var installmentSite = document.getElementById("register-installment-site").value;
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "";
            var resultsCounter = 0;
            for(var i = 0; i < response.installments.length; i++) {
                if(response.installments[i].siteId == installmentSite) {
                    resultsCounter++;
                    tempHtml += "<option value='" + response.installments[i].installmentId + "'>" + response.installments[i].expiry + "  (€ " + formatCurrency(response.installments[i].totalAmount) + ")</option>";
                }
            }
            if(resultsCounter === 0) {
                tempHtml += "<option value='' disabled selected>-- Nessuna rata disponibile --</option>";
            }
            document.getElementById("register-installment-expiration").innerHTML = tempHtml;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInstallments.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxRegisterInstallmentPayment() {
    var toRegisterPayment = {};
    toRegisterPayment.installmentId = parseInt(document.getElementById("register-installment-expiration").value);
    toRegisterPayment.paymentDate = document.getElementById("register-installment-date").value.split("/").reverse().join("-");
    toRegisterPayment.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Pagamento registrato con successo");
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/registerInstallmentPayment.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(toRegisterPayment));
}

function getUnpaidSuppliers() {
    var tempHtml = "";
    var countAdded = 0;
    for(var i = 0; i < balances.length; i++) {
        if(balances[i].balance < 0) {
            tempHtml += "<option value='" + balances[i].supplierId + "'>" + balances[i].supplierName + "</option>";
            countAdded++;
        }
    }
    if(countAdded < 1) {
        tempHtml += "<option value='' disabled selected>-- Nessuna fattura da pagare --</option>";
    }
    else {
        tempHtml += "<option value='' disabled selected>-- Selezionare --</option>";
    }
    document.getElementById("register-invoice-payment-issued-by").innerHTML = tempHtml;
}

function populateRegisterInvoicePaymentIdSelector() {
    var tempHtml = "";
    var countAdded = 0;
    var selectedSupplier = document.getElementById("register-invoice-payment-issued-by").value;
    for(var i = 0; i < unpaidSupplierInvoices.length; i++) {
        if(unpaidSupplierInvoices[i].supplierId == selectedSupplier) {
            if(unpaidSupplierInvoices[i].causal.trim() === "") {
                tempHtml += "<option value='" + unpaidSupplierInvoices[i].invoiceId + "'>" + unpaidSupplierInvoices[i].date + " (Nessuna causale)</option>";
            }
            else {
                tempHtml += "<option value='" + unpaidSupplierInvoices[i].invoiceId + "'>" + unpaidSupplierInvoices[i].date + " (" + unpaidSupplierInvoices[i].causal + ")</option>";
            }
            countAdded++;
        }
    }
    if(countAdded < 1) {
        tempHtml += "<option value='' disabled selected>-- Nessuna fattura da pagare --</option>";
    }
    else {
        tempHtml += "<option value='' disabled selected>-- Selezionare --</option>";
    }
    document.getElementById("register-invoice-id").innerHTML = tempHtml;
}

function registerInvoicePaymentInvoiceChanged() {
    var invoiceId = document.getElementById("register-invoice-id").value;
    for(var i = 0; i < unpaidSupplierInvoices.length; i++) {
        if(unpaidSupplierInvoices[i].invoiceId == invoiceId) {
            document.getElementById("register-invoice-to-pay-amount").value = formatCurrency(unpaidSupplierInvoices[i].unpaid).replace(",", ".").replace("'", "");
            document.getElementById("register-invoice-already-paid-amount").value = formatCurrency(unpaidSupplierInvoices[i].alreadyPaid).replace(",", ".").replace("'", "");
            document.getElementById("register-invoice-total-amount").value = formatCurrency(unpaidSupplierInvoices[i].invoiceTotal).replace(",", ".").replace("'", "");
        }
    }
}

function ajaxGenerateInstallments() {
    var installmentGeneration = {};
    installmentGeneration.installmentsNumber = parseInt(document.getElementById("generate-installments-installments-number").value);
    installmentGeneration.numberOfMonths = parseInt(document.getElementById("generate-installments-every-months").value);
    installmentGeneration.startDate = document.getElementById("generate-installments-start-date").value.split("/").reverse().join("-");
    installmentGeneration.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast("Rate generate con successo");
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/generateInstallments.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(installmentGeneration));
}

function ajaxRegisterInvoicePayment() {
    var toRegisterPayment = {};
    toRegisterPayment.invoiceId = parseInt(document.getElementById("register-invoice-id").value);
    toRegisterPayment.paymentDate = document.getElementById("register-invoice-payment-date").value.split("/").reverse().join("-");
    toRegisterPayment.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    toRegisterPayment.paidAmount = Math.round(document.getElementById("register-invoice-amount").value * 1e4) / 1e4;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            if(response.transactionId != null) {
                closePopup();
                successToast("Pagamento registrato con successo");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante la registrazione del pagamento</div>";
            }
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/registerInvoicePayment.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(toRegisterPayment));
}

function renderWindIcon(windSpeed){
    if(windSpeed>=13.7){
        return "<img class='meteo-icon' alt='Vento forte, "+windSpeed+"m/s' title='Vento forte, "+windSpeed+"m/s' src='src/img/wind.png'>";
    }
    return "";
}

function renderMeteoIcon(weather){
    switch(weather) {
        case "Clear":
            return "<img class='meteo-icon' alt='Soleggiato' title='Soleggiato' src='"+imgSun+"'>";
        case "Rain":
            return "<img class='meteo-icon' alt='Pioggia' title='Pioggia' src='"+imgRainy+"'>";
        case "Clouds":
            return "<img class='meteo-icon' alt='Nuvoloso' title='Nuvoloso' src='"+imgClouds+"'>";
        case "Snow":
            return "<img class='meteo-icon' alt='Neve' title='Neve' src='"+imgSnow+"'>";
        case null:
            return "";
        default:
            return "&lt;" + weather + "&gt;";
    }
}

function initInterventionsToValidatePopup(){
    tempHtml="";
    interventionManager.interventionsToValidate.forEach(function(interventionToValidate){
        tempHtml+=renderInterventionEntry(interventionToValidate,true);
    });
    setInnerHtml(document.getElementById("interventions-to-validate-container"),tempHtml);
    
}

function renderInterventionsToValidatePopup(){
    popup("interventions-to-validate-list", false, false, false);
}

function countVdrAppliedFilters() {
    var appliedFiltersCounter = 0;
    if(document.getElementById("data-room-filter-document-type").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("data-room-filter-document-subtype").value !== "") {
        appliedFiltersCounter++;
    }
    var appliedFilterCounterText = "";
    if(appliedFiltersCounter > 0) {
        appliedFilterCounterText = appliedFiltersCounter;
        dom.addClass(document.getElementById("filter-button-vdr"), "active");
    }
    else {
        dom.removeClass(document.getElementById("filter-button-vdr"), "active");
    }
    setInnerHtml(document.querySelector("#filter-button-vdr .filter-counter"), appliedFilterCounterText);
}

function countMonthCalendarAppliedFilters() {
    var appliedFiltersCounter = 0;
    if(document.getElementById("calendar-site-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-category-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-subcategory-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-executor-selector").value !== "") {
        appliedFiltersCounter++;
    }
    var appliedFilterCounterText = "";
    if(appliedFiltersCounter > 0) {
        appliedFilterCounterText = appliedFiltersCounter;
        dom.addClass(document.getElementById("filter-button-month-calendar"), "active");
    }
    else {
        dom.removeClass(document.getElementById("filter-button-month-calendar"), "active");
    }
    setInnerHtml(document.querySelector("#filter-button-month-calendar .filter-counter"), appliedFilterCounterText);
}

function countYearCalendarAppliedFilters() {
    var appliedFiltersCounter = 0;
    if(document.getElementById("calendar-year-site-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-year-category-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-year-subcategory-selector").value !== "") {
        appliedFiltersCounter++;
    }
    if(document.getElementById("calendar-year-executor-selector").value !== "") {
        appliedFiltersCounter++;
    }
    var appliedFilterCounterText = "";
    if(appliedFiltersCounter > 0) {
        appliedFilterCounterText = appliedFiltersCounter;
        dom.addClass(document.getElementById("filter-button-year-calendar"), "active");
    }
    else {
        dom.removeClass(document.getElementById("filter-button-year-calendar"), "active");
    }
    setInnerHtml(document.querySelector("#filter-button-year-calendar .filter-counter"), appliedFilterCounterText);
}

function showCorrectActionButton(currentPage) {
    var toKeepButtonIds = [];
    var i;
    switch(currentPage) {
        case "page-maintenance":
            toKeepButtonIds = ["filter-button-maintenance"];
            break;
        case "page-assets":
            toKeepButtonIds = ["filter-button-assets", "action-button-create-asset"];
            break;
        case "page-asset":
            toKeepButtonIds = ["action-button-edit-asset","action-button-delete-asset"];
            break;
        case "page-calendar-year":
            toKeepButtonIds = ["filter-button-year-calendar","action-button-create-intervention"];
            break;
        case "page-calendar":
            toKeepButtonIds = ["filter-button-month-calendar","action-button-create-intervention"];
            break;
        case "page-data-room":
            toKeepButtonIds = ["filter-button-vdr"];
            break;
        case "page-ticketing":
            toKeepButtonIds = ["action-button-create-ticket", "action-button-view-tickets-archive"];
            break;
        case "page-anomalies":
            toKeepButtonIds = ["action-button-create-anomaly", "action-button-view-anomalies-archive"];
            break;
        case "page-buildings":
            toKeepButtonIds = ["action-button-create-for-building"];
            break;
        case "page-contracts":
            toKeepButtonIds = ["action-button-create-contract"];
            break;
        case "page-intervention":
            toKeepButtonIds = ["action-button-edit-intervention"];
            break;
    }
    var filterButtons = document.querySelectorAll(".filter-button");
    for(i = 0; i < filterButtons.length; i++) {
        if(!filterButtons[i].id) {
            console.error("Rilevato pulsante di filtraggio senza id");
        }
        else {
            if(toKeepButtonIds.indexOf(filterButtons[i].id) < 0) {
                dom.addClass(filterButtons[i], "hidden");
            }
            else {
                dom.removeClass(filterButtons[i], "hidden");
            }
        }
    }
    var actionButtons = document.querySelectorAll(".mobile-action-button");
    for(i = 0; i < actionButtons.length; i++) {
        if(!actionButtons[i].id) {
            console.error("Rilevato actionButton senza id");
        }
        else {
            if(toKeepButtonIds.indexOf(actionButtons[i].id) < 0) {
                dom.addClass(actionButtons[i], "hidden");
            }
            else {
                dom.removeClass(actionButtons[i], "hidden");
            }
        }
    }
}

var popupManager = {
    popupStack:[], //Stack containing the last opened popups, in order
    popupScrolls:{}, //Stack containing the last initialized scroll boxes, in order
    getOpenPopupsCount: function(){
        return popupManager.popupStack.length;
    },
    getCurrentPopup: function(){
        var oldPopup = popupManager.popupStack.pop();
        popupManager.popupStack.push(oldPopup);
        return oldPopup;
    },
    //returns true if a popup is open, even in background
    popupIsOpen: function(id){
        return (popupManager.popupStack.includes(id));
    }
};

/**
 * Opens up a popup popup
 * @param {string} id containing the id of the popup without the trailing '-popup'
 * @param {boolean} clear flag indicating if the interactive inputs of the popup needs to be cleared
 * @param {boolean} keepPreviousPopupOpen if true, the previously opened popup (if any) stays visible
 * @param {boolean} cannotBeClose if true, the popup must be submitted, x and back buttons are not shown
 */
function popup(id, clear, keepPreviousPopupOpen, cannotBeClose) {
    var newPopup = document.getElementById(id + "-popup");
    if(newPopup !== undefined) {
        var oldPopup = popupManager.popupStack.pop();
        popupManager.popupStack.push(oldPopup);
        if(id===oldPopup){
            return;
        }
        newPopup.setAttribute("aria-hidden", "false");
        dom.addClass(document.body, "noscroll");
        if(!keepPreviousPopupOpen) {
            if(oldPopup !== undefined) {
                closePopupHook(oldPopup);
                newPopup.setAttribute("aria-hidden", "true");
            }
        }
        popupManager.popupStack.push(id);
        if(clear) {
            switch(id) {
                case "create-contract":
                    resetAddContract();
                    break;
                case "outcome":
                    outcomePopup.innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione in corso...</span>";
                    break;
                case "merge-interventions":
                    document.getElementById("to-merge-intervention-selector").innerHTML = "";
                    document.getElementById("merge-interventions-date").value = "";
                    break;
                case "delete-intervention":
                    document.getElementById("delete-intervention-confirm-check").setAttribute("data-checked","0");
                    break;
                case "change-password":
                    clearChangePasswordForm();
                    break;
                case "search":
                    fullSearchPopup.clear();
                    break;
            }
        }
        dom.addClass(newPopup,"visible-popup");
        if(popupContent = newPopup.querySelector(".popup-content")) {
            //popupManager.popupScrolls[id]=(new IScroll(popupContent,scroll.scrollOptions));
            popupContent.focus();
            //Removing other tabindexes
            popupContent.querySelectorAll("[tabindex]").forEach(function(element) {
                element.removeAttribute("tabindex");
            });
            //Adding new tabindexes
            var i = 1;
            popupContent.querySelectorAll("select, input, textarea, button, a, .scrollable-number-picker").forEach(function(focusableElement) {
                focusableElement.setAttribute("tabindex", i);
                i++;
            });
        }
        var closeButton = newPopup.querySelector(".close");
        if(closeButton) {
            if(cannotBeClose){
                dom.addClass(closeButton,"hidden");
            }
            else {
                dom.removeClass(closeButton,"hidden");
                if(!dom.hasClass(closeButton, "tap-loaded")) {
                    switch(id){
                        case "comment-activity-on-asset-instance-execution":
                            defineTapListenerOnElement(closeButton,checkNoteAndClose);
                            break;
                        default:
                            defineTapListenerOnElement(closeButton,closePopup);
                            break;
                    }
                    dom.addClass(closeButton, "tap-loaded");
                }
            }
        }
        var backButton = newPopup.querySelector(".back");
        if(backButton) {
            if(cannotBeClose) {
                dom.addClass(backButton,"hidden");
            }
            else {
                dom.removeClass(backButton,"hidden");
            }
        }
        //popup open hooks
        switch(id) {
            case "assets-filters":
                dom.removeClass(document.getElementById("action-button-set-asset-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-asset-filter"),"hidden");
                break;
            case "maintenance-filters":
                dom.removeClass(document.getElementById("action-button-set-maintenance-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-maintenance-filter"),"hidden");
                break;
            case "month-calendar-filters":
                dom.removeClass(document.getElementById("action-button-set-month-calendar-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-month-calendar-filter"),"hidden");
                break;
            case "year-calendar-filters":
                dom.removeClass(document.getElementById("action-button-set-year-calendar-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-year-calendar-filter"),"hidden");
                break;
            case "vdr-filters":
                dom.removeClass(document.getElementById("action-button-set-vdr-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-vdr-filter"),"hidden");
                break;
            case "permissions":
                popupUserPermissions();
                break;
            case "view-contracts":
                ajaxGetContracts();
                break;
            case "notifications":
                notificationManager.ajaxMarkAsSeen();
                break;
            case "create-common-unit":
                updateCommonUnitAvailbleBuildings();
                break;
            case "create-property-unit":
                updatePropertyUnitAvailbleBuildings();
                break;
            case "view-balances":
                ajaxGetBalances();
                break;
            case "view-transactions":
                ajaxGetTransactions();
                break;
            case "view-invoices":
                ajaxGetInvoices();
                break;
            case "view-repartition-tables-buildings":
                ajaxGetRepartitionTablesForBuildings();
                break;
            case "view-repartition-tables-buildings-group":
                ajaxGetRepartitionTablesForBuildingsGroup();
                break;
            case "view-repartition-tables-common-sites":
                ajaxGetRepartitionTablesForCommonSites();
                break;
            case "view-installments":
                ajaxGetInstallments();
                break;
            case "view-estimates":
                ajaxGetEstimates();
                break;
            case "add-estimate":
                loadAddEstimatesPopup();
                break;
            case "view-consumptive":
                ajaxGetConsumptive();
                break;
            case "view-expenditure-items":
                ajaxGetExpenditureItems();
                break;
            case "register-invoice-payment":
                getUnpaidSuppliers();
                break;
            case "add-buildings-final-rates":
                ajaxGetRepartitionTablesForBuildingsGroup();
                break;
            case "add-sites-final-rates":
                ajaxGetRepartitionTablesForBuildings();
                break;
            case "view-laws":
                ajaxGetLaws(null);
                break;
            case "add-activity-for-subcategory":
                ajaxGetLaws(null);
                toggleAddActivityForSubcLawRequirementsOptions();//to show or hide options depending on the "statutory requirement" checkbox
                break;
            case "edit-activity-for-subcategory":
                break;
            case "assign-selected-activities":
                ajaxReloadCompanies();
                break;
            case "edit-asset-instance":
                setTimeout(function(){dom.removeClass(document.getElementById("action-button-save-asset-edit"),"hidden");},400);
                initializeEditAssetInstancePopup(lastViewedAssetInstance);
                break;
            case "view-ticket-mail-log":
                loadTicketMailLog(lastSelectedTicketId);
                break;
            case "view-events-log":
                ajaxGetEventsLog();
                break;
            case "split-interventions":
                loadSplitInterventionsPopupData();
                break;
            case "view-activities-for-subcategories":
                ajaxViewActivitiesForSubcategory();
                break;
            case "create-intervention-step-2":
                createSubcategoryGraphicalMultiSelector(
                    "create-intervention-subcat-selector-container",
                    "add-intervention",
                    false,{},{},{}
                );
                break;
            case "view-users":
                ajaxGetUsers();
                break;
            case "search-asset-for-qr-code":
                reloadSearchAssetsForQrCode();
                break;
            /*case "view-geolocation-data":
                ajaxGetUsers();
                ajaxViewGeolocationData();
                break;*/
            case "validate-intervention-otp":
                ajaxGetValidateInterventionOtp();
                break;
            case "add-asset-to-intervention":
                addAssetToInterventionPopup.init();
                break;
            case "manage-sub-categories":
                // Load the excluded subcat tree
                ajaxLoadExcludedSubCategory();
                break;
        }
        if(newPopup) {
            var popupContent = newPopup.querySelector(".popup-content");
            //scrolling to the top of the popup content
            if(popupContent) {
                //TODO update this using iscroll
                popupContent.scrollTop = 0;
            }
        }
    }
    else {
        console.error("Trying to open an unrecognized popup");
    }
}

function ajaxLoadExcludedSubCategory(){
    var popupContent = document.getElementById("view-excluded-subcategory-tree");
    setInnerHtml(
        popupContent,
        "<div class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione in corso...</div>",
        function(){scroll.refreshPopupById("manage-sub-categories", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempHtml = "<div class='view-excluded-subcategory-tree'>";
                response.excludedSubcategory.forEach(function(excluded) {
                    // nome azienda
                    tempHtml += "<h3>"+ excluded.companyName +"</h3>";
                    excluded.subcategories.forEach(function(subcategory) {
                        tempHtml += "<div class='entry'><h3>"
                                + subcategory.subcategoryName +
                                "</h3>" +
                            "Dal: " + subcategory.from + "" +
                            "<button type='button' class='pull-right-button' onclick='ajaxSubcategoryRestoreDisabled("+subcategory.subcategoryId+","+excluded.companyId+");'>Riattiva</button>";
                        tempHtml += "</div>";
                    });
                });
                tempHtml += "<br></div>";
                setInnerHtml(
                    popupContent,
                    tempHtml,
                    function(){scroll.refreshPopupById("manage-sub-categories", false);}
                );
            }
            else {
                setInnerHtml(
                    popupContent,
                    "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>",
                    function(){scroll.refreshPopupById("manage-sub-categories", false);}
                );
            }
        }
        catch(e) {
            console.log(e);
            setInnerHtml(
                popupContent,
                "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>",
                function(){scroll.refreshPopupById("manage-sub-categories", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/subcategory/getExcluded.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxSubcategoryRestoreDisabled(subcategoryId, companyId){
    var requestData = {};
    requestData.subcategoryId = subcategoryId;
    requestData.companyId = companyId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                successToast("Sottocategoria ripristinata.");
                ajaxLoadExcludedSubCategory();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("PATCH", BASE_URL + "/ws/subcategory/restoreExcluded.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function reloadUsersSearch(searchedText){
    setTimeout(function() {
        var popupContent = document.getElementById("view-users-popup-content");
        setInnerHtml(
            popupContent,
            "<div class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione in corso...</div>",
            function(){scroll.refreshPopupById("view-users", false);}
        );
        var tempHtml = "<div class='users-list'>";
        if(searchedText==null||searchedText==undefined){
            searchedText = document.getElementById("users-search-textbox").value;
        }    
        lastFetchedUsers.forEach(function(user) {
            if(
                user.userId==searchedText
                ||(user.username!=null && user.username.toUpperCase().includes(searchedText.toUpperCase()))
                ||(user.name!=null && user.name.toUpperCase().includes(searchedText.toUpperCase()))
                ||(user.surname!=null && user.surname.toUpperCase().includes(searchedText.toUpperCase()))
                ||(user.eMailAddress!=null && user.eMailAddress.toUpperCase().includes(searchedText.toUpperCase()))
                ||searchedText.trim()==''
            ){
                tempHtml += "<div class='entry "+(user.isBlocked?"blocked":"")+"'><h3>" + user.name + " " +
                    user.surname + (user.isBlocked?" <span class='red-state'>Bloccato</span>":"")+"</h3>"+
                    "Username: "+ user.username +
                    "<br>Ultimo cambio password: "+ iso8601.toEuropeanDateTime(user.lastPasswordChange,"mai") +
                    "<br>Mail: " + ((user.eMailAddress === null) ? "-" : ("<a href='mailto:" + user.eMailAddress +
                        "' target='_blank' rel='noopener noreferrer'>" + user.eMailAddress + "</a>")) +
                    "<br><br>" +
                    "<button type='button' onclick='viewUserData(" + user.userId +
                    ");'><i class='fa fa-pencil' aria-hidden='true'></i> Modifica</button> " +
                    "<button type='button' onclick='ajaxGetUserPermissionsOfSpecificUserId(" + user.userId +
                    ",\"" + user.username + "\");'><i class='fa fa-key' aria-hidden='true'></i> Permessi</button> " +
                    "<button type='button' onclick='deleteUser(" + user.userId +
                    ",\"" + user.username + "\");'><i class='fa fa-trash' aria-hidden='true'></i> Elimina</button> ";
                if(!user.isBlocked) {
                    tempHtml += "<button type='button' onclick='blockUser(" + user.userId +
                    ",\"" + user.username + "\");'><i class='fa fa-hand-stop-o' aria-hidden='true'></i> Blocca</button> " ;
                }
                else {
                    tempHtml += "<button type='button' onclick='unblockUser(" + user.userId +
                    ",\"" + user.username + "\");'><i class='fa fa-thumbs-o-up' aria-hidden='true'></i> Sblocca</button> " ;
                }
                if(!user.mustChangePassword) {
                    tempHtml += "<button type='button' onclick='invalidateUserPassword(" + user.userId +
                    ",\"" + user.username + "\");'><i class='fa fa-minus-circle' aria-hidden='true'></i> Invalida password</button> ";
                }
                tempHtml += "<br><br></div>";
            }
        });
        tempHtml += "<br></div>";
        setInnerHtml(
            popupContent,
            tempHtml,
            function(){scroll.refreshPopupById("view-users", false);}
        );
    },200);
}

var lastFetchedUsers=[];
function ajaxGetUsers() {
    var popupContent = document.getElementById("view-users-popup-content");
    setInnerHtml(
        popupContent,
        "<div class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione in corso...</div>",
        function(){scroll.refreshPopupById("view-users", false);}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempUsrSelect = "<option value='' selected='selected'>-- Selezionare --</option>";
                response.users.forEach(function(user) {
                    tempUsrSelect += "<option value='" + user.userId + "'>" + user.username + "</option>";
                });
                lastFetchedUsers=response.users;
                setInnerHtml(document.getElementById("geolocation-data-user-select"), tempUsrSelect);
                setInnerHtml(document.getElementById("send-push-notifications-recipient"), tempUsrSelect);
                reloadUsersSearch();
            }
            else {
                setInnerHtml(
                    popupContent,
                    "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>",
                    function(){scroll.refreshPopupById("view-users", false);}
                );
            }
        }
        catch(e) {
            console.error(e);
            setInnerHtml(
                popupContent,
                "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>",
                function(){scroll.refreshPopupById("view-users", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getUsers.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxSendPushNotifications(pushTitle,pushBody,notificationMessage,recipient) {
    var requestData = {};
    requestData.pushTitle = pushTitle;
    requestData.pushBody = pushBody;
    requestData.notificationMessage = notificationMessage;
    requestData.recipient = recipient;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Notifica inviata correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendPushNotification.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

/**
 * Pops up delete user confirmation popup
 * @param {number} userId id of the user to be deleted
 * @param {string} username username of the user to be deleted
 */
function deleteUser(userId, username) {
    setInnerHtml(document.getElementById("username-to-be-deleted"), username);
    document.getElementById("user-to-be-deleted-id").value = userId;
    popup("delete-user", false, false, false);
}

/**
 * Pops up block user confirmation popup
 * @param {number} userId id of the user to be blocked
 * @param {string} username username of the user to be blocked
 */
function blockUser(userId, username) {
    setInnerHtml(document.getElementById("username-to-be-blocked"), username);
    document.getElementById("user-to-be-blocked-id").value = userId;
    popup("block-user", false, false, false);
}

/**
 * Pops up unblock user confirmation popup
 * @param {number} userId id of the user to be unblocked
 * @param {string} username username of the user to be unblocked
 */
function unblockUser(userId, username) {
    setInnerHtml(document.getElementById("username-to-be-unblocked"), username);
    document.getElementById("user-to-be-unblocked-id").value = userId;
    popup("unblock-user", false, false, false);
}

/**
 * Pops up invalidate user password confirmation popup
 * @param {number} userId id of the user to be invalidated
 * @param {string} username username of the user to be invalidated
 */
function invalidateUserPassword(userId, username) {
    setInnerHtml(document.getElementById("username-to-be-invalidated"), username);
    document.getElementById("user-to-be-invalidated-id").value = userId;
    popup("invalidate-user-password", false, false, false);
}

/**
 * AJAX request to delete a user
 * @param {number} userId id of the user to be deleted
 */
function ajaxDeleteUser(userId) {
    var userData = {};
    userData.userId = parseInt(userId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Utente eliminato correttamente");
                ajaxGetUsers();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/delete.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(userData));
}

/**
 * AJAX request to block a user
 * @param {number} userId id of the user to be blocked
 */
function ajaxBlockUser(userId) {
    var userData = {};
    userData.userId = parseInt(userId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Utente bloccato correttamente");
                ajaxGetUsers();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/block.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(userData));
}

/**
 * AJAX request to unblock a user
 * @param {number} userId id of the user to be unblocked
 */
function ajaxUnblockUser(userId) {
    var userData = {};
    userData.userId = parseInt(userId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Utente sbloccato correttamente");
                ajaxGetUsers();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/user/unblock.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(userData));
}

/**
 * AJAX request to invalidate an user's password
 * @param {number} userId id of the user to be invalidated
 */
function ajaxInvalidateUserPassword(userId) {
    var userData = {};
    userData.userId = parseInt(userId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Password utente invalidata correttamente");
                ajaxGetUsers();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/password/invalidate.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(userData));
}

function viewCurrentUserData() {
    viewUserData(currentLoggedInUserId);
}

function showProfilePictureUploader(){
    dom.removeClass(document.getElementById("data-profile-picture-img-picker"),"hidden");
}

function viewUserData(userId) {
    popup("user-data", false, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        /**
         * @type {Object}
         * @property {Object} userData
         * @property {String} userData.eMailAddress
         * @property {String} userData.username
         * @property {String} userData.telephoneNumber
         */
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var userData = response.userData;
            setInnerHtml(document.getElementById("user-data-username"), userData.username);
            var currentProfilePicture="";
            var profilePictureHolder = document.getElementById("edit-user-data-profile-picture-img");
            var profilePictureEditButton = document.getElementById("edit-user-data-profile-picture-btn");
            var profilePicturePicker = document.getElementById("data-profile-picture-img-picker");
            var datePicker = document.querySelectorAll("#user-data-birthday .composite-picker-value")[0];
            if(userData.profilePictureId) {
                profilePictureHolder.src=DIRECT_DOWNLOAD_URL+"/ws/renderImage.php?imageId="+userData.profilePictureId+"&height=100&ott="+userData.profilePictureOtt;
                dom.removeClass(profilePictureHolder,"hidden");
                dom.addClass(profilePicturePicker,"hidden");
                dom.removeClass(profilePictureEditButton,"hidden");
            }
            else {
                dom.addClass(profilePictureHolder,"hidden");
                dom.removeClass(profilePicturePicker,"hidden");
                dom.addClass(profilePictureEditButton,"hidden");
            }
            document.getElementById("user-data-user-id").value = userId;
            document.getElementById("user-data-profile-picture-file-id").value = "";
            document.getElementById("user-data-profile-picture-id").value = ((userData.profilePictureId!=null)?userData.profilePictureId:"");
            document.getElementById("user-username").value = userData.username;
            document.getElementById("user-data-name").value = (userData.name == null ? "" : userData.name);
            document.getElementById("user-data-surname").value = (userData.surname == null ? "" : userData.surname);
            document.getElementById("user-data-company").innerHTML = "<option selected='selected' value='" + userData.company + "'>" + userData.companyName + "</option>";
            datePicker.innerHTML = iso8601.toEuropeanDate(userData.birthday,"");
            document.getElementById("user-data-mail").value = (userData.eMailAddress == null ? "" : userData.eMailAddress);
            document.getElementById("user-data-phone").value = (userData.telephoneNumber == null ? "" : userData.telephoneNumber);

            if(userDataBirthday && userDataBirthday.destroy) {
                userDataBirthday.destroy();
            }
            var currentYear=(new Date()).getFullYear();
            userDataBirthday = new Pikaday({
                field: document.getElementById("user-data-birthday"),
                firstDay: 1,
                maxDate: new Date(),
                yearRange:[currentYear-110,currentYear],
                onSelect: function(date) {
                    setInnerHtml(
                        datePicker,
                        iso8601.toEuropeanDate(date.toISOString(),"")
                    );
                },
                format: 'DD/MM/YYYY'
            });
            defineTapListener("clear-user-data-birthday",function(){
                userDataBirthday.setDate(null);
                setInnerHtml(datePicker,"- - / - - / - - - -");
            });
            if(userData.birthday == null) {
                userDataBirthday.setDate(null);
                setInnerHtml(datePicker,"- - / - - / - - - -");
            }
            scroll.refreshPopupById("user-data", false);
        }
        else {
            errorToast("Errore: " + response.message);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getUserData.php?userId=" + userId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxEditUserData() {
    var editedUser = {};
    editedUser.userId = parseInt(document.getElementById("user-data-user-id").value);

    editedUser.name = document.getElementById("user-data-name").value;
    if(editedUser.name === "")
        delete editedUser.name;
    editedUser.surname = document.getElementById("user-data-surname").value;
    if(editedUser.surname === "")
        delete editedUser.surname;
    editedUser.birthday = document.getElementById("user-data-birthday").value.split("/").reverse().join("-");
    if(editedUser.birthday === "")
        delete editedUser.birthday;
    editedUser.email = document.getElementById("user-data-mail").value;
    editedUser.telephone = document.getElementById("user-data-phone").value;
    if(editedUser.telephone === "" && editedUser.email === ""){
        errorToast("È necessario compilare almeno uno tra i campi 'E-mail aziendale personale' e 'Telefono cellulare'");
        return;
    }
    if(editedUser.telephone === "") {
        delete editedUser.telephone;
    }
    if(editedUser.email === ""){
        delete editedUser.email;
    }
    editedUser.companyId = document.getElementById("user-data-company").value;
    if(editedUser.companyId === "") {
        delete editedUser.companyId;
    }
    else
        editedUser.companyId = parseInt(editedUser.companyId);

    popup("outcome", true, false, false);

    var ajaxCall = function(editedUser){
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                if(xhttp.status === 200) {
                    closePopup();
                    successToast("Utente modificato con successo");
                    ajaxGetUsers();
                }
                else {
                    setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/user/update.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(editedUser));
    };

    if(document.getElementById("user-data-profile-picture").value!=""){
        ajaxUploadPicture("user-data-profile-picture",null,function(justUploadedFileId) {
            closePopup();
            document.getElementById("user-data-profile-picture-file-id").value = justUploadedFileId;
            document.getElementById("user-data-profile-picture-id").value = "";
            editedUser.profilePictureId = null;
            editedUser.profilePictureFileId = justUploadedFileId;
            ajaxCall(editedUser);
        });
    }
    else {
        closePopup();
        editedUser.profilePictureId=document.getElementById("user-data-profile-picture-id").value;
        if(editedUser.profilePictureId=="") {
            editedUser.profilePictureId = null;
        }
        editedUser.profilePictureFileId = null;
        document.getElementById("user-data-profile-picture-file-id").value = "";
        ajaxCall(editedUser);
    }
}

function renderUserPermissions(userPermissions, userId, username) {
    var popupContent = document.getElementById("view-user-permissions-popup-content");
    var tempHtml = "<div>";
    for(var i = 0; i < userPermissions.length; i++) {
        tempHtml += "<button type='button' onclick='viewPermissionsForCompany(" + i + "," + userId + ",\"" + username + "\",\"" + userPermissions[i].company + "\")'><i class='fa fa-key' aria-hidden='true'></i><br>" + userPermissions[i].company + "</button>";
    }
    tempHtml += "<button type='button' onclick='addCompanyToPermissions(" + userId + ",\"" + username + "\")'><i class='fa fa-plus' aria-hidden='true'></i><br>Aggiungi azienda...</button>";
    tempHtml += "</div>";
    setInnerHtml(popupContent, tempHtml);
}

function showNewCompanyInUserPermissions() {
    var userId = document.getElementById("add-company-for-user-id").value;
    var username = document.getElementById("add-company-for-username").innerHTML;
    closePopup();
    var newCompanySelector = document.getElementById("company-to-be-addedd-to-permissions");
    var companyId = newCompanySelector.value;
    var companyName = newCompanySelector.options[newCompanySelector.selectedIndex].innerHTML;
    var popupContent = document.querySelector("#view-user-permissions-for-company-popup form");
    var tempHtml = "<input type='hidden' id='editing-permissions-for-company-id' value='" + companyId + "'>";
    tempHtml += "<input type='hidden' id='editing-permissions-for-user-id' value='" + userId + "'>";
    tempHtml += "<input type='hidden' id='editing-permissions-for-username' value='" + username + "'>";
    tempHtml += "<div class='all-rows-edit'><strong>Applica a tutte le righe:</strong> ";
    tempHtml += "<select id='edit-all-user-permissions-selector'>";
    for(var i = 0; i < permissionLevels.length; i++) {
        tempHtml += "<option value='" + permissionLevels[i].permissionLevel + "'>" + permissionLevels[i].description + "</option>";
    }
    tempHtml += "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>";
    tempHtml += "</select>";
    tempHtml += "&nbsp;&nbsp;<button type='button' onclick='changeAllUserPermissionsFromSingleSelector();'>Applica</button>";
    tempHtml += "</div>";
    tempHtml += "<div class='permissions-list vth'><div>Permesso</div><div>Livello di permesso</div></tr>";
    for(var j = 0; j < permissionTypes.length; j++) {
        tempHtml += "<div class='tr'><div>" + permissionTypes[j].name + "</div><div>" + renderPermissionLevelsSelector(permissionTypes[j].permissionCode, "HIDDEN") + "</div></div>";
    }
    tempHtml += "</div>";
    tempHtml += "<br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-floppy-o' aria-hidden='true'></i> Salva modifiche</button>";
    setInnerHtml(document.getElementById("user-permissions-on-company-of-username"), username);
    setInnerHtml(document.getElementById("user-permissions-on-company-of-company"), companyName);
    setInnerHtml(popupContent, tempHtml);
    popup("view-user-permissions-for-company", false, false, false);
}

function addCompanyToPermissions(userId, username) {
    setInnerHtml(document.getElementById("add-company-for-username"), username);
    document.getElementById("add-company-for-user-id").value = userId;
    popup("add-permissions-to-company-for-user", false, false, false);
}

function ajaxGetUserPermissionsOfSpecificUserId(userId, username) {
    document.getElementById("user-permissions-of-username").innerHTML = username;
    popup("view-user-permissions", false, false, false);
    var popupContent = document.getElementById("view-user-permissions-popup-content");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                lastViewedUserPermissions = response.userPermissions;
                renderUserPermissions(lastViewedUserPermissions, userId, username);
            }
            else {
                popupContent.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(popupContent, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            console.error(e);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getUserPermissionsByUser.php?userId=" + userId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function renderPermissionLevelsSelector(permissionCode, selectedLevel) {
    var tempHtml = "<select data-permission-level-for-code='" + permissionCode + "' class='permission-level-selector' required='required'>";
    for(var i = 0; i < permissionLevels.length; i++) {
        if(permissionLevels[i].permissionLevel === selectedLevel) {
            tempHtml += "<option selected='selected' value='" + permissionLevels[i].permissionLevel + "'>" + permissionLevels[i].description + "</option>";
        }
        else {
            tempHtml += "<option value='" + permissionLevels[i].permissionLevel + "'>" + permissionLevels[i].description + "</option>";
        }
    }
    tempHtml += "</select>";
    return tempHtml;
}

function changeAllUserPermissionsFromSingleSelector() {
    var newValue = document.getElementById("edit-all-user-permissions-selector").value;
    if(newValue !== "") {
        var levelSelector = document.querySelectorAll(".permission-level-selector");
        for(var i = 0; i < levelSelector.length; i++) {
            levelSelector[i].value = newValue;
        }
    }
}

function copyPermissions() {
    document.getElementById("paste-permissions-btn").removeAttribute("disabled");
    var permissions = document.querySelectorAll(".permission-level-selector");
    copiedPermissions = [];
    for(var i = 0; i < permissions.length; i++) {
        copiedPermissions[i] = {};
        copiedPermissions[i].value = permissions[i].value;
        copiedPermissions[i].code = permissions[i].getAttribute("data-permission-level-for-code");
    }
}

function pastePermissions() {
    var selector;
    for(var i = 0; i < copiedPermissions.length; i++) {
        selector = document.querySelector("[data-permission-level-for-code='" + copiedPermissions[i].code + "']");
        selector.value = copiedPermissions[i].value;
    }
}

function viewPermissionsForCompany(companyIndex, userId, username, companyName) {
    var popupContent = document.querySelector("#view-user-permissions-for-company-popup form");
    var tempHtml = "<input type='hidden' id='editing-permissions-for-company-id' value='" + lastViewedUserPermissions[companyIndex].companyId + "'>";
    tempHtml += "<input type='hidden' id='editing-permissions-for-user-id' value='" + userId + "'>";
    tempHtml += "<input type='hidden' id='editing-permissions-for-username' value='" + username + "'>";
    tempHtml += "<table><tr class='all-rows-edit'><td><button type='button' class='permissions-left-btn' id='copy-permissions-btn' onclick='copyPermissions();'><i class='fa fa-clone' aria-hidden='true'></i> Copia permessi</button> <button type='button' class='permissions-left-btn' onclick='pastePermissions();' " + (copiedPermissions ? "" : "disabled='disabled'") + " id='paste-permissions-btn'><i class='fa fa-clipboard' aria-hidden='true'></i> Incolla permessi</button> <button  class='permissions-left-btn bg-green text-white' type='button' onclick='submitForm(this,false);'><i class='fa fa-check' aria-hidden='true'></i> Salva modifiche</button>Applica a tutte le righe: ";
    tempHtml += "<select id='edit-all-user-permissions-selector'>";
    for(var i = 0; i < permissionLevels.length; i++)
        tempHtml += "<option value='" + permissionLevels[i].permissionLevel + "'>" + permissionLevels[i].description + "</option>";
    tempHtml += "<option selected='selected' disabled='disabled' value=''>-- Selezionare --</option>";
    tempHtml += "</select>";
    tempHtml += "&nbsp;&nbsp;<button type='button' onclick='changeAllUserPermissionsFromSingleSelector();'><i class='fa fa-check' aria-hidden='true'></i> Applica</button> </td></tr></table><br>";
    tempHtml += "<table class='permissions-list'><th>Permesso</th><th>Livello di permesso</th></tr>";
    for(var j = 0; j < lastViewedUserPermissions[companyIndex].permissions.length; j++) {
        tempHtml += "<tr><td>" + lastViewedUserPermissions[companyIndex].permissions[j].name + "</td><td>" + renderPermissionLevelsSelector(lastViewedUserPermissions[companyIndex].permissions[j].permissionCode, lastViewedUserPermissions[companyIndex].permissions[j].permissionLevel) + "</td></tr>";
    }
    tempHtml += "</table>";
    tempHtml += "<br><br><button type='button' onclick='submitForm(this,false);' class='bg-green text-white'><i class='fa fa-check' aria-hidden='true'></i> Salva modifiche</button>";
    setInnerHtml(document.getElementById("user-permissions-on-company-of-username"), username);
    setInnerHtml(document.getElementById("user-permissions-on-company-of-company"), companyName);
    setInnerHtml(popupContent, tempHtml);
    popup("view-user-permissions-for-company", false, false, false);
}

function ajaxSavePermissionsEdit() {
    var levelSelector = document.querySelectorAll(".permission-level-selector");
    var username = document.getElementById("editing-permissions-for-username").value;
    var newPermissions = {};
    newPermissions.companyId = parseInt(document.getElementById("editing-permissions-for-company-id").value);
    newPermissions.userId = parseInt(document.getElementById("editing-permissions-for-user-id").value);
    newPermissions.permissions = [];
    for(var i = 0; i < levelSelector.length; i++) {
        newPermissions.permissions[i] = {};
        //Reading permission code
        newPermissions.permissions[i].permissionCode = levelSelector[i].getAttribute("data-permission-level-for-code");
        //Reading permission level
        newPermissions.permissions[i].permissionLevel = levelSelector[i].value;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("I permessi sono stati aggiornati");
                lastViewedUserPermissions = response.newUserPermissions;
                renderUserPermissions(lastViewedUserPermissions, newPermissions.userId, username);
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>" + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newPermissions), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newPermissions), xhttp.responseText);
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/savePermissions.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newPermissions));
}

function ajaxCreatePermission() {
    var newPermission = {};
    newPermission.code = document.getElementById("create-permission-code").value;
    newPermission.description = document.getElementById("create-permission-description").value;
    newPermission.group = document.getElementById("create-permission-group").value;
    newPermission.defaultLevel = document.getElementById("create-permission-default-level").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("I permessi sono stati aggiornati");
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>" + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newPermission), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newPermission), xhttp.responseText);
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createPermission.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newPermission));
}

/*
 * The popups are organized in a stack, every time a popup opens another popup the new one is pushed into the stack
 * when closing a popup the closed popup is popped from the stack.
 */
function closePopup() {
    lastTimeOpenedNotifications = new Date();//Patch to fix ghost click loop on iOS
    var toClosePopup = popupManager.popupStack.pop();
    if(toClosePopup !== undefined) {
        //Destroying scroll
        var scrollToDestroy = popupManager.popupScrolls[toClosePopup];
        if(scrollToDestroy !== undefined && scrollToDestroy.destroy) {
            scrollToDestroy.destroy();
        }
        closePopupHook(toClosePopup);
        document.getElementById(toClosePopup + "-popup").setAttribute("aria-hidden", true);
        if(popupContent = document.querySelector("#" + toClosePopup + "-popup .popup-content")) {
            //Removing other tabindexes
            popupContent.querySelectorAll("[tabindex]").forEach(function(element) {
                element.removeAttribute("tabindex");
            });
        }
    }
    var levelDownPopup = popupManager.popupStack.pop();
    if(levelDownPopup !== undefined) {
        popupManager.popupStack.push(levelDownPopup);
        dom.addClass(document.getElementById(levelDownPopup + "-popup"),"visible-popup");
        //Recovering action buttons for re-opened popup
        switch(levelDownPopup) {
            case "assets-filters":
                dom.removeClass(document.getElementById("action-button-set-asset-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-asset-filter"),"hidden");
                break;
            case "maintenance-filters":
                dom.removeClass(document.getElementById("action-button-set-maintenance-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-maintenance-filter"),"hidden");
                break;
            case "month-calendar-filters":
                dom.removeClass(document.getElementById("action-button-set-month-calendar-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-month-calendar-filter"),"hidden");
                break;
            case "year-calendar-filters":
                dom.removeClass(document.getElementById("action-button-set-year-calendar-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-year-calendar-filter"),"hidden");
                break;
            case "vdr-filters":
                dom.removeClass(document.getElementById("action-button-set-vdr-filter"),"hidden");
                dom.removeClass(document.getElementById("action-button-remove-vdr-filter"),"hidden");
                break;
            case "edit-asset-instance":
                setTimeout(function(){dom.removeClass(document.getElementById("action-button-save-asset-edit"),"hidden");},400);
                break;
        }
        scroll.refreshPopupById(levelDownPopup, false);
    }
    else {
        dom.removeClass(document.body, "noscroll");
    }
}

/*
 * Special operations performed when closing specific popups
 */
function closePopupHook(id) {
    dom.removeClass(document.getElementById(id + "-popup"),"visible-popup");
    switch(id) {
        case "edit-asset-instance":
            dom.addClass(document.getElementById("action-button-save-asset-edit"),"hidden");
            break;
        case "assets-filters":
            dom.addClass(document.getElementById("action-button-set-asset-filter"),"hidden");
            dom.addClass(document.getElementById("action-button-remove-asset-filter"),"hidden");
            break;
        case "maintenance-filters":
            dom.addClass(document.getElementById("action-button-set-maintenance-filter"),"hidden");
            dom.addClass(document.getElementById("action-button-remove-maintenance-filter"),"hidden");
            break;
        case "month-calendar-filters":
            dom.addClass(document.getElementById("action-button-set-month-calendar-filter"),"hidden");
            dom.addClass(document.getElementById("action-button-remove-month-calendar-filter"),"hidden");
            break;
        case "year-calendar-filters":
            dom.addClass(document.getElementById("action-button-set-year-calendar-filter"),"hidden");
            dom.addClass(document.getElementById("action-button-remove-year-calendar-filter"),"hidden");
            break;
        case "vdr-filters":
            dom.addClass(document.getElementById("action-button-set-vdr-filter"),"hidden");
            dom.addClass(document.getElementById("action-button-remove-vdr-filter"),"hidden");
            break;
        case "upload-document":
            dom.removeClass(document.getElementById("upload-document-step-1"), "hidden");
            dom.addClass(document.getElementById("upload-document-step-2"), "hidden");
            dom.addClass(document.getElementById("upload-document-step-3"), "hidden");
            var progressBar = document.getElementById("upload-document-progress-bar");
            progressBar.innerHTML = " 0.00%";
            progressBar.style.width = "0%";
            break;
        case "document-viewer":
            window.location.hash = "#data-room";
            break;
    }
}

/*
 * Pre-loads data into the split intervention popup's form
 */
function loadSplitInterventionsPopupData() {
    if(lastOpenedIntervention !== null) {
        document.getElementById("split-interventions-date-1").value = iso8601.toEuropeanDate(lastOpenedIntervention.isoPlannedStartDate,"");
        document.getElementById("split-interventions-date-2").value = iso8601.toEuropeanDate(lastOpenedIntervention.isoPlannedStartDate,"");
        ui.pickerPopupButton.setValue("split-intervention-1-start-time", [
            iso8601.getHours(lastOpenedIntervention.isoPlannedStartDate),
            ":",
            iso8601.getMinutes(lastOpenedIntervention.isoPlannedStartDate)
        ]);
        ui.pickerPopupButton.setValue("split-intervention-1-end-time", [
            iso8601.getHours(lastOpenedIntervention.isoPlannedEndDate),
            ":",
            iso8601.getMinutes(lastOpenedIntervention.isoPlannedEndDate)
        ]);
        ui.pickerPopupButton.setValue("split-intervention-2-start-time", ["09", ":", "00"]);
        ui.pickerPopupButton.setValue("split-intervention-2-end-time", ["12", ":", "00"]);
        var tempHtml = "";
        for(var i = 0; i < lastOpenedIntervention.activities.length; i++) {
            tempHtml += "<div class='entry'><div><div title='" + lastOpenedIntervention.activities[i].category + "' class='category-small b-" + lastOpenedIntervention.activities[i].categoryIconBackground + "'>" + lastOpenedIntervention.activities[i].categoryIcon + "</div>" +
                "<div title='" + lastOpenedIntervention.activities[i].subcategory + "' class='category-small b-" + lastOpenedIntervention.activities[i].categoryIconBackground + "'>" + lastOpenedIntervention.activities[i].subcategoryIcon + "</div></div>" +
                "<div>" + lastOpenedIntervention.activities[i].activityDescription + "</div><div><select required='required' id='split-intervention-assign-activity-" + lastOpenedIntervention.activities[i].interventionActivityId + "'><option selected='selected' value='1'>Intervento 1</option><option value='2'>Intervento 2</option></select></div></div>";
        }
        setInnerHtml(
            document.getElementById("split-intervention-assign-activities"),
            tempHtml,
            function(){scroll.refreshPopupById("split-interventions", false);}
        );
    }
}

/*
 * Pre-loads data into the add estimates popup's form
 */
function loadAddEstimatesPopup() {
    var tempHtml = "<form method='POST' onsubmit='ajaxCreateEstimate(); return false;'><table>";
    tempHtml += "<tr><th>Voce di spesa</th><th>Preventivo per l'anno corrente [EUR]</th></tr>";
    for(var i = 0; i < expenditureItems.length; i++) {
        tempHtml += "<tr><td>" + expenditureItems[i].name + "</td><td><input type='number' step='0.01' min='0' id='data-expenditure-item-" + expenditureItems[i].expenditureItemId + "' required></td></tr>";
    }
    tempHtml += "</table><br><br><br><strong>ATTENZIONE: Inserendo un nuovo preventivo verranno eliminati eventuali preventivi precedenti</strong><br><br><button type='button' onclick='submitForm(this,false);'>Aggiungi</button></form>";
    setInnerHtml(
        document.querySelector("#add-estimate-popup .popup-content div"),
        tempHtml,
        function(){scroll.refreshPopupById("add-estimate", false);}
    );
}

function updatePropertyUnitAvailbleBuildings() {
    var tempHtml = "";
    var buildingsGroupSelector = document.getElementById("property-unit-buildings-group");
    var selectedBuildingsGroup = buildingsGroupSelector.options[buildingsGroupSelector.selectedIndex].value;
    for(i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
            tempHtml += "<option value=" + buildings[i].buildingId + ">" + buildings[i].name + "</option>";
        }
    }
    setInnerHtml(document.getElementById("property-unit-building"), tempHtml);
}

function updateCommonUnitAvailbleBuildings() {
    var tempHtml = "";
    var buildingsGroupSelector = document.getElementById("common-unit-buildings-group");
    var selectedBuildingsGroup = buildingsGroupSelector.options[buildingsGroupSelector.selectedIndex].value;
    for(i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
            tempHtml += "<option value=" + buildings[i].buildingId + ">" + buildings[i].name + "</option>";
        }
    }
    document.getElementById("common-unit-building").innerHTML = tempHtml;
}

function updateEditSiteAvailbleBuildings() {
    var tempHtml = "";
    var selectedBuildingsGroup = document.getElementById("edit-site-buildings-group").value;
    for(i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingsGroup)
            tempHtml += "<option value=" + buildings[i].buildingId + ">" + buildings[i].name + "</option>";
    }
    document.getElementById("edit-site-building").innerHTML = tempHtml;
}

function refreshUnseenNotificationsCounter() {
    var notificationCounters = document.querySelectorAll(".notification-counter");
    for(var i = 0; i < notificationCounters.length; i++) {
        if(unseenNotifications > 0) {
            notificationCounters[i].innerHTML = unseenNotifications;
            dom.removeClass(notificationCounters[i], "hidden");
        }
        else {
            dom.addClass(notificationCounters[i], "hidden");
        }
    }
}

function populateActivityOnSubcSubcategories() {
    var selectedCategoryId = parseInt(document.getElementById("add-activity-for-subc-category").value);
    var activityOnSubcOptions = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null) {
            activityOnSubcOptions += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
        }
    }
    document.getElementById("add-activity-for-subc-subcategory").innerHTML = activityOnSubcOptions;
}

function populateEditActivityOnSubcSubcategories() {
    var selectedCategoryId = parseInt(document.getElementById("edit-activity-for-subc-category").value);
    var activityOnSubcOptions = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null) {
            activityOnSubcOptions += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
        }
    }
    document.getElementById("edit-activity-for-subc-subcategory").innerHTML = activityOnSubcOptions;
}

function populateViewActivityOnSubcSubcategories() {
    var selectedCategoryId = parseInt(document.getElementById("view-activities-for-subc-category").value);
    var activityOnSubcOptions = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null) {
            activityOnSubcOptions += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
        }
    }
    document.getElementById("view-activities-for-subc-subcategory").innerHTML = activityOnSubcOptions;
    ajaxViewActivitiesForSubcategory();
}

function populateViewActivityToExcludeSubcCategory() {
    var selectedCategoryId = parseInt(document.getElementById("view-activities-to-exclude-subc-category").value);
    var activityOnSubcOptions = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null) {
            activityOnSubcOptions += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
        }
    }
    document.getElementById("view-activities-to-exclude-subc-subcategory").innerHTML = activityOnSubcOptions;
    ajaxViewActivitiesForSubcategory();
}

// Populate the company dropdown in exclude subcat popup
function populateViewCompanyToExcludeSubcCategory() {
    var activityOnSubcOptions = "";
    for(var i = 0; i < globalUserPermissions.length; i++) {
            activityOnSubcOptions += "<option value=" + globalUserPermissions[i].companyId + ">" + globalUserPermissions[i].companyName + "</option>";
    }
    document.getElementById("view-companies-to-exclude-subc-category").innerHTML = activityOnSubcOptions;
}


function populateSubcategorySelect(selectedCategoryId, subcategorySelectToPopulate, requiredField) {
    subcategorySelectToPopulate.innerHTML = "";
    var matchingSubcategories = 0;
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null) {
            subcategorySelectToPopulate.innerHTML += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
            matchingSubcategories++;
        }
    }
    if(requiredField) {
        if(matchingSubcategories > 1) {
            subcategorySelectToPopulate.innerHTML += "<option value='' selected='selected'>-- Selezionare --</option>";
        }
    }
    else {
        subcategorySelectToPopulate.innerHTML += "<option value='' selected='selected'>-- Nessuna --</option>";
    }
}

function dataRoomDocumentTypeFilterChanged(reloadFilters) {
    var typeSelector = document.getElementById("data-room-filter-document-type");
    var subtypeSelector = document.getElementById("data-room-filter-document-subtype");
    var optionsBackground = "rgba(0,0,0,0.1)";
    var optionsColor = "#000000";
    var subtypesNumber = 0;
    if(documentTypes) {
        for(var i = 0; i < documentTypes.length; i++) {
            if(documentTypes[i].documentTypeId == typeSelector.value) {
                if(documentTypes[i].textColor != null)
                    optionsColor = documentTypes[i].textColor;
                if(documentTypes[i].backgroundColor != null)
                    optionsBackground = documentTypes[i].backgroundColor;

                var tempHtml = "";
                for(var j = 0; j < documentTypes[i].subtypes.length; j++) {
                    subtypesNumber++;
                    tempHtml += "<option value='" + documentTypes[i].subtypes[j].documentSubtypeId + "' style='color:" + optionsColor + "; background:" + optionsBackground + ";'>" + documentTypes[i].subtypes[j].documentSubtype + "</option>";
                }
                break;
            }
        }

        typeSelector.style.backgroundColor = optionsBackground;
        typeSelector.style.color = optionsColor;
        subtypeSelector.style.backgroundColor = optionsBackground;
        subtypeSelector.style.color = optionsColor;

        if(subtypesNumber === 0) {
            if(typeSelector.value === "")
                subtypeSelector.innerHTML = "<option value='' selected='selected' style='color:" + optionsColor + "; background:" + optionsBackground + ";'>-- Tutte --</option>";
            else
                subtypeSelector.innerHTML = "<option value='' selected='selected' style='color:" + optionsColor + "; background:" + optionsBackground + ";'>-- Nessuna sottocat. --</option>";
        }
        else {
            if(subtypesNumber > 1)
                subtypeSelector.innerHTML = tempHtml + "<option value='' selected='selected' style='color:" + optionsColor + "; background:" + optionsBackground + ";'>-- Tutte --</option>";
            else
                subtypeSelector.innerHTML = tempHtml;
        }

        if(reloadFilters) {
            ajaxReloadDataRoomFilters();
        }
    }
}

function populateDataRoomUploadSubcategories() {
    var dataRoomCategorySelector = document.getElementById("upload-document-category");
    var selectedCategoryId = dataRoomCategorySelector.value;
    var dataRoomSubcategorySelector = document.getElementById("upload-document-subcategory");
    dataRoomSubcategorySelector.innerHTML = "";
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++)
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null)
            tempHtml += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
    tempHtml += "<option selected='selected' value=''>-- Selezionare --</option>";
    dataRoomSubcategorySelector.innerHTML = tempHtml;
}

function populateDataRoomEditSubcategories() {
    var dataRoomCategorySelector = document.getElementById("edit-document-category");
    var selectedCategoryId = dataRoomCategorySelector.value;
    var dataRoomSubcategorySelector = document.getElementById("edit-document-subcategory");
    dataRoomSubcategorySelector.innerHTML = "";
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++)
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null)
            tempHtml += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
    tempHtml += "<option selected='selected' value=''>-- Selezionare --</option>";
    dataRoomSubcategorySelector.innerHTML = tempHtml;
}

function populateContractSubcategories(selectedCategoryId) {
    contractActivitySubcategorySelector = document.getElementById("contract-activity-subcategory-selector");
    contractActivitySubcategorySelector.innerHTML = "";
    for(var i = 0; i < subcategories.length; i++)
        if(subcategories[i].categoryId == selectedCategoryId && subcategories[i].deletion==null)
            contractActivitySubcategorySelector.innerHTML += "<option value=" + subcategories[i].subcategoryId + ">" + subcategories[i].name + "</option>";
    ajaxCountSiteAssetsBySubcategory();
}

function createTicketPopupAutocompleteFromAsset(qr){
    var assetInstanceToUse;
    if(qr.charAt(0)==="#"){
        assetInstanceToUse = assetInstanceManager.getAssetFromAssetId(parseInt(qr.slice(1)));
    }
    else{
        assetInstanceToUse = assetInstanceManager.getAssetFromQr(qr.toUpperCase());
    }
    if(assetInstanceToUse!=null) {
        document.getElementById("ticket-site").value = assetInstanceToUse.site;
        document.getElementById("ticket-category").value = assetInstanceToUse.subcategories[0].categoryId;
        populateSubcategorySelect(assetInstanceToUse.subcategories[0].categoryId, document.getElementById("ticket-subcategory"), false);
        document.getElementById("ticket-subcategory").value = assetInstanceToUse.subcategories[0].subcategoryId;
    }
}

function createAnomalyPopupAutocompleteFromAsset(qr){
    var assetInstanceToUse;
    if(qr.charAt(0)==="#"){
        assetInstanceToUse = assetInstanceManager.getAssetFromAssetId(parseInt(qr.slice(1)));
    }
    else{
        assetInstanceToUse = assetInstanceManager.getAssetFromQr(qr.toUpperCase());
    }
    if(assetInstanceToUse!=null) {
        document.getElementById("anomaly-site").value = assetInstanceToUse.site;
        document.getElementById("anomaly-category").value = assetInstanceToUse.subcategories[0].categoryId;
        populateSubcategorySelect(assetInstanceToUse.subcategories[0].categoryId, document.getElementById("anomaly-subcategory"), false);
        document.getElementById("anomaly-subcategory").value = assetInstanceToUse.subcategories[0].subcategoryId;
    }
}

function ajaxGetNextInterventionDate(){
    var companyId= document.getElementById("create-ticket-company-assigned-to-ticket-1").value;
    if(companyId==""){
        document.getElementById("ticket-red-message").innerHTML="La data di intervento verrà determinata al momento della scelta dell'azienda esecutrice";
        return;
    }
    var siteId = document.getElementById("ticket-site").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                if(response.date==null){
                    document.getElementById("ticket-red-message").innerHTML="Nessun intervento programmato per l'azienda esecutrice e nel sito indicati";
                    ticketDayPikaday.setDate(null);
                    document.getElementById("create-ticket-intervention-date-start").removeAttribute("disabled");
                }
                else{
                    document.getElementById("ticket-red-message").innerHTML="";
                    ticketDayPikaday.setDate(new Date(response.date));
                    document.getElementById("create-ticket-intervention-date-start").disabled="disabled";
                }
            }
            else {
                console.error(response.message);
            }
        }
        catch(e) {
            console.error(e);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getNextInterventionDate.php?companyId=" + companyId + "&siteId=" + siteId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}
window.ajaxGetNextInterventionDate=ajaxGetNextInterventionDate;
window.moSla=false;
function evaluateNewTicketSla(newSla){
    var slaHours=0;
    slaLevels.forEach(function(slaLevel){
        if(slaLevel.levelName===newSla){
            slaHours=slaLevel.slaHours;
        }
    });
    window.moSla=false;
    switch(newSla){
        case 'Data':
            ticketDayPikaday.setDate(null);
            document.getElementById("ticket-red-message").innerHTML="";
            document.getElementById("create-ticket-intervention-date-start").removeAttribute("disabled");
            break;
        case 'Eseguibile in M.O.P.':
            window.moSla=true;
            ticketDayPikaday.setDate(null);
            document.getElementById("ticket-red-message").innerHTML="";
            ajaxGetNextInterventionDate();
            document.getElementById("create-ticket-intervention-date-start").disabled="disabled";
            break;
        default:
            var now=new Date();
            document.getElementById("ticket-red-message").innerHTML="";
            console.log(slaHours);
            now.setUTCHours(now.getUTCHours()+slaHours);
            ticketDayPikaday.setDate(now);
            console.log(now);
            document.getElementById("create-ticket-intervention-date-start").disabled="disabled";
            break;
    }
}

window.evaluateNewTicketSla=evaluateNewTicketSla;

function ajaxGetSlaLevels(){
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                initializeTicketSlaSelect();
                slaLevels = response.slas;
            }
            else {
                console.error(response.message);
            }
        }
        catch(e) {
            console.error(e);
        }
    };
    xhttp.open("GET", BASE_URL + "/api/sla/get.php?companyId=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function initializeTicketSlaSelect(){
    if(slaLevels===null){
        ajaxGetSlaLevels();
        return;
    }
    var anomalyLevelSelect = document.getElementById("ticket-anomaly-level");
    anomalyLevelSelect.innerHTML = "";
    for(i = 0; i < slaLevels.length; i++) {
        anomalyLevelSelect.innerHTML += "<option value='" + slaLevels[i].levelName + "'>" + slaLevels[i].levelName + "</option>";
    }
    anomalyLevelSelect.innerHTML += "<option value='' disabled='disabled' selected='selected'>-- Selezionare --</option>";
}

function createTicketPopup(fromAsset, fromAnomaly) {
    document.getElementById("create-ticket-photo-thumbnails").innerHTML="";
    localPhotoUploadsId = 0;
    photoUploads = [];
    createTicketInvolvedAssetsTaggle.removeAll();
    if(fromAnomaly) {
        document.getElementById("ticket-description").value = htmlDecode(lastViewedAnomaly.description);
        document.getElementById("ticket-related-anomaly").value = lastViewedAnomaly.anomalyId;
        //Thumbnails for already linked pictures
        lastViewedAnomaly.relatedPictures.forEach(function(relatedPicture){
            var thumbnail = document.createElement("img");
            thumbnail.setAttribute('src', DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + relatedPicture + "&height=80&authToken=" + currentAuthToken);
            thumbnail.setAttribute('height', '80px');
            document.getElementById("create-ticket-photo-thumbnails").appendChild(thumbnail);
        });
        //Previously linked assets
        lastViewedAnomaly.relatedAssets.forEach(function(relatedAsset){
            createTicketInvolvedAssetsTaggle.add(relatedAsset.qrCode?relatedAsset.qrCode:("#"+relatedAsset.assetId));
        });
    }
    else {
        document.getElementById("ticket-related-anomaly").value = "";
    }
    ticketSiteSelect.innerHTML = "";
    var i;
    for(i = 0; i < sites.length; i++) {
        if(sites[i].siteId == appliedFilters.site && (!fromAsset) && (!fromAnomaly))
            ticketSiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected='selected'>" + sites[i].name + "</option>";
        else {
            if(
                (fromAsset && lastViewedAssetInstance.site == sites[i].siteId)
                ||
                (fromAnomaly && lastViewedAnomaly.siteId == sites[i].siteId)
            ) {
                ticketSiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected='selected'>" + sites[i].name + "</option>";
            }
            else {
                ticketSiteSelect.innerHTML += "<option value=" + sites[i].siteId + ">" + sites[i].name + "</option>";
            }
        }
    }
    document.getElementById("ticket-type").value="";
    ticketCategorySelect=document.getElementById("ticket-category");
    ticketCategorySelect.innerHTML = "";
    for(i = 0; i < categories.length; i++) {
        if(categories[i].categoryId == appliedFilters.category && (!fromAsset) && (!fromAnomaly)) {
            ticketCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
            populateSubcategorySelect(categories[i].categoryId, ticketSubcategorySelect, false);
        }
        else {
            if(fromAsset && lastViewedAssetInstance.subcategories[0].categoryId == categories[i].categoryId) {
                ticketCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
                populateSubcategorySelect(categories[i].categoryId, ticketSubcategorySelect, false);
            }
            else {
                if(fromAnomaly && lastViewedAnomaly.categoryId == categories[i].categoryId) {
                    ticketCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
                    populateSubcategorySelect(categories[i].categoryId, ticketSubcategorySelect, false);
                }
                else {
                    ticketCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + ">" + categories[i].name + "</option>";
                }
            }
        }
    }
    if(!appliedFilters.category && (!fromAsset) && (!fromAnomaly)) {
        ticketCategorySelect.innerHTML += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    }
    if(fromAsset) {
        document.getElementById("ticket-subcategory").value = lastViewedAssetInstance.subcategories[0].subcategoryId;
    }
    if(fromAnomaly) {
        document.getElementById("ticket-subcategory").value = lastViewedAnomaly.subcategoryId;
    }
    if(!appliedFilters.category && (!fromAsset) && (!fromAnomaly)) {
        ticketSubcategorySelect.innerHTML = "<option value='' selected='selected'>-- Selezionare categoria --</option>";
    }
    ticketSiteSelect.removeAttribute("style");
    ticketCategorySelect.removeAttribute("style");
    document.getElementById("ticket-type").removeAttribute("style");
    document.getElementById('ticket-description').removeAttribute("style");
    if(fromAsset) {
        //tags
        var txt=null;
        if(lastViewedAssetInstance.qrCodeId) {
            txt = lastViewedAssetInstance.qrCodeId.toUpperCase();
        }
        else {
            txt = "#" + lastViewedAssetInstance.assetInstanceId;
        }
        if(txt) {
            createTicketInvolvedAssetsTaggle.add(txt);
        }
    }
    if(permissions["ASSIGN_WHILE_CREATING_TICKET"] === "ACCESSIBLE") {
        var previousSelectorsCounter=document.getElementById("create-ticket-company-selector-counter").value;
        document.getElementById("create-ticket-company-assigned-to-ticket-instructions-1").value="";
        document.getElementById("create-ticket-company-assigned-to-ticket-1").value="";
        for(i=previousSelectorsCounter;i>1;i--) { //clearing the company assignation fields
            var removeCompanyButton=document.querySelector("#create-ticket-company-selector-container-"+i+" button");//remove company button
            assignTicketRemoveCompanySelector("create-ticket",removeCompanyButton);
        }
        document.getElementById("assign-while-creating-ticket").value=1;
        //adding required attributes to fields
        document.getElementById("create-ticket-company-assigned-to-ticket-1").required="required";
        document.getElementById("create-ticket-intervention-date-start").required="required";
        //unhiding fields
        dom.removeClass(document.getElementById("create-ticket-assign-companies"),"hidden");
    }
    else{
        document.getElementById("assign-while-creating-ticket").value=0;
        //removing required attributes to fields
        document.getElementById("create-ticket-company-assigned-to-ticket-1").removeAttribute("required");
        document.getElementById("create-ticket-intervention-date-start").removeAttribute("required");
        //hiding fields
        dom.addClass(document.getElementById("create-ticket-assign-companies"),"hidden");
    }
    initializeTicketSlaSelect();
    popup("create-ticket", true, false, false);
}

var competenceAreas=[];
function ajaxGetCompetenceAreas(siteFilter,resourceFilter,currentCompetenceAreaId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "";
            response.resources.forEach(function(resource){
                var i;
                var siteFound=false;
                for(i=0; i<resource.sites.length; i++) {
                    if(resource.sites[i].id==siteFilter){
                        siteFound=true;
                    }
                }
                var resourceFound=false;
                for(i=0; i<resource.managedResources.length; i++) {
                    if(resource.managedResources[i].name==resourceFilter){
                        resourceFound=true;
                    }
                }
                if(siteFound&&resourceFound){
                    if(currentCompetenceAreaId!=resource.id){
                        tempHtml+="<option value='"+resource.id+"'>"+resource.name+"</option>";
                    }
                }
            });
            document.getElementById("new-competence-area").innerHTML = tempHtml;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", "https://api.claster.it/api/v2/competence-areas/", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authorization", 'Bearer '+ window.localStorage.getItem("cfm_at_v2"));
    xhttp.setRequestHeader("X-Session-Id", window.localStorage.getItem("cfm_sc"));
    xhttp.send("");
}

var currentResourceType = null;
var currentResourceId = null;
function changeCompetenceArea(resource,currentCompetenceAreaName,currentCompetenceAreaId,currentSiteId,resourceId){
    document.getElementById("current-competence-area-name").innerHTML=currentCompetenceAreaName;
    currentResourceType=resource;
    currentResourceId=resourceId;
    ajaxGetCompetenceAreas(currentSiteId,resource,currentCompetenceAreaId);
    popup("change-competence-area");
}
window.changeCompetenceArea=changeCompetenceArea;

function ajaxChangeCompetenceArea(){
    console.log("ajaxChangeCompetenceArea");
    var requestData = {};
    requestData.resourceType = currentResourceType;
    requestData.resourceId = currentResourceId;
    requestData.competenceAreaId = parseInt(document.getElementById("new-competence-area").value);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Area di competenza modificata");
                if(currentResourceType=='Ticket'){
                    ajaxGetTicketDetails(currentResourceId);
                }
                if(currentResourceType=='Segnalazioni'){
                    ajaxGetAnomalyDetails(currentResourceId);
                }
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>" + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/changeCompetenceArea.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}
window.ajaxChangeCompetenceArea=ajaxChangeCompetenceArea;

function createAnomalyPopup(fromAsset) {
    //Clearing previously uploaded photos
    setInnerHtml(document.getElementById("create-anomaly-photo-thumbnails"), "");
    localPhotoUploadsId = 0;
    photoUploads = [];
    var i;
    var anomalySiteSelect = document.getElementById("anomaly-site");
    var anomalyCategorySelect = document.getElementById("anomaly-category");
    var anomalySubcategorySelect = document.getElementById("anomaly-subcategory");
    anomalySiteSelect.innerHTML = "";
    for(i = 0; i < sites.length; i++) {
        if(sites[i].siteId == appliedFilters.site && (!fromAsset)) {
            anomalySiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected>" + sites[i].name + "</option>";
        }
        else {
            if(fromAsset && lastViewedAssetInstance.site == sites[i].siteId) {
                anomalySiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected>" + sites[i].name + "</option>";
            }
            else {
                anomalySiteSelect.innerHTML += "<option value=" + sites[i].siteId + ">" + sites[i].name + "</option>";
            }
        }
    }
    anomalyCategorySelect.innerHTML = "";
    for(i = 0; i < categories.length; i++) {
        if(categories[i].categoryId == appliedFilters.category && (!fromAsset)) {
            anomalyCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
            populateSubcategorySelect(categories[i].categoryId, document.getElementById("anomaly-subcategory"), false);
        }
        else {
            if(fromAsset && lastViewedAssetInstance.categoryId == categories[i].categoryId) {
                anomalyCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
                populateSubcategorySelect(categories[i].categoryId, document.getElementById("anomaly-subcategory"), false);
            }
            else {
                anomalyCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + ">" + categories[i].name + "</option>";
            }
        }
    }
    if(!appliedFilters.category && (!fromAsset)) {
        anomalyCategorySelect.innerHTML += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    }
    if(fromAsset) {
        document.getElementById("anomaly-subcategory").value = lastViewedAssetInstance.subcategoryId;
    }
    if(!appliedFilters.category && (!fromAsset)) {
        anomalySubcategorySelect.innerHTML = "<option value='' selected='selected'>-- Selezionare categoria --</option>";
    }
    anomalySiteSelect.removeAttribute("style");
    anomalyCategorySelect.removeAttribute("style");
    document.getElementById('anomaly-description').removeAttribute("style");
    createAnomalyInvolvedAssetsTaggle.removeAll();
    if(fromAsset) {
        var txt=null;
        if(lastViewedAssetInstance.qrCodeId) {
            txt = lastViewedAssetInstance.qrCodeId.toUpperCase();
        }
        else {
            txt = "#" + lastViewedAssetInstance.assetInstanceId;
        }
        if(txt) {
            createAnomalyInvolvedAssetsTaggle.add(txt);
        }
    }
    popup("create-anomaly", true, false, false);
}

function createPrecompiledAnomaly(assetId, siteId, description, categoryId, subcategoryId) {
    createAnomalyInvolvedAssetsTaggle.removeAll();
    if(assetId) {
        var assetQr = assetInstanceManager.getQrFromAsset(assetId);
        if(assetQr===null){
            createAnomalyInvolvedAssetsTaggle.add("#" + assetId);
        }
        else{
            createAnomalyInvolvedAssetsTaggle.add(assetQr);
        }
    }
    //Clearing previously uploaded photos
    setInnerHtml(document.getElementById("create-anomaly-photo-thumbnails"), "");
    localPhotoUploadsId = 0;
    photoUploads = [];
    var i;
    var anomalySiteSelect = document.getElementById("anomaly-site");
    var anomalyCategorySelect = document.getElementById("anomaly-category");
    var anomalySubcategorySelect = document.getElementById("anomaly-subcategory");
    anomalySiteSelect.innerHTML = "";
    for(i = 0; i < sites.length; i++) {
        if(sites[i].siteId == siteId) {
            anomalySiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected>" + sites[i].name + "</option>";
        }
        else {
            if(!siteId) {
                anomalySiteSelect.innerHTML += "<option value=" + sites[i].siteId + ">" + sites[i].name + "</option>";
            }
        }
    }
    anomalyCategorySelect.innerHTML = "";
    for(i = 0; i < categories.length; i++) {
        if(categories[i].categoryId == categoryId || (!categoryId)) {
            anomalyCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
            populateSubcategorySelect(categories[i].categoryId, document.getElementById("anomaly-subcategory"), false);
        }
        else {
            anomalyCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + ">" + categories[i].name + "</option>";
        }
    }
    if(!categoryId) {
        anomalyCategorySelect.innerHTML += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    }
    if(subcategoryId) {
        document.getElementById("anomaly-subcategory").value = subcategoryId;
    }
    else {
        anomalySubcategorySelect.innerHTML = "<option value='' selected='selected'>-- Selezionare categoria --</option>";
    }
    anomalySiteSelect.removeAttribute("style");
    anomalyCategorySelect.removeAttribute("style");
    document.getElementById('anomaly-description').removeAttribute("style");
    document.getElementById('anomaly-description').value = description;
    popup("create-anomaly", true, true, false);
}

function createAnomalyFromInterventionActivity(){
    var description = document.getElementById("activity-on-asset-instance-comment").value;
    var assetId = document.getElementById("comment-asset-instance-id").value;
    var categoryId = document.getElementById("comment-asset-category-id").value;
    var subcategoryId = document.getElementById("comment-asset-subcategory-id").value;
    var siteId = interventionManager.currentInterventionData.siteId;
    createPrecompiledAnomaly(assetId, siteId, description, categoryId, subcategoryId);
}

function createAnomalyFromInterventionDetailedReport(assetId, siteId, caller, categoryId, subcategoryId) {
    var description = caller.getAttribute("data-description");
    createPrecompiledAnomaly(assetId, siteId, description, categoryId, subcategoryId);
}

function createTicketFromInterventionDetailedReport(assetId, siteId, caller, categoryId, subcategoryId) {
    var description = caller.getAttribute("data-description");
    createTicketInvolvedAssetsTaggle.removeAll();
    if(assetId) {
        var assetQrCode=assetInstanceManager.getQrFromAsset(assetId);
        if(assetQrCode==null){
            createTicketInvolvedAssetsTaggle.add("#" + assetId);
        }
        else{
            createTicketInvolvedAssetsTaggle.add(assetQrCode);
        }
    }
    //Clearing previously uploaded photos
    setInnerHtml(document.getElementById("create-ticket-photo-thumbnails"), "");
    localPhotoUploadsId = 0;
    photoUploads = [];
    var i;
    var ticketSiteSelect = document.getElementById("ticket-site");
    var ticketCategorySelect = document.getElementById("ticket-category");
    var ticketSubcategorySelect = document.getElementById("ticket-subcategory");
    ticketSiteSelect.innerHTML = "";
    ticketSiteSelect.innerHTML = "";
    for(i = 0; i < sites.length; i++) {
        if(sites[i].siteId == siteId) {
            ticketSiteSelect.innerHTML += "<option value=" + sites[i].siteId + " selected>" + sites[i].name + "</option>";
        }
        else {
            if(!siteId) {
                ticketSiteSelect.innerHTML += "<option value=" + sites[i].siteId + ">" + sites[i].name + "</option>";
            }
        }
    }
    ticketCategorySelect.innerHTML = "";
    for(i = 0; i < categories.length; i++) {
        if(categories[i].categoryId == categoryId || (!categoryId)) {
            ticketCategorySelect.innerHTML += "<option value=" + categories[i].categoryId + " selected>" + categories[i].name + "</option>";
            populateSubcategorySelect(categories[i].categoryId, document.getElementById("ticket-subcategory"), false);
        }
    }
    if(!categoryId) {
        ticketCategorySelect.innerHTML += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    }
    if(subcategoryId) {
        document.getElementById("ticket-subcategory").value = subcategoryId;
    }
    else {
        ticketSubcategorySelect.innerHTML = "<option value='' selected='selected'>-- Selezionare categoria --</option>";
    }
    ticketSiteSelect.removeAttribute("style");
    ticketCategorySelect.removeAttribute("style");
    document.getElementById('ticket-description').removeAttribute("style");
    document.getElementById('ticket-description').value = htmlDecode(description);
    initializeTicketSlaSelect();
    popup("create-ticket", true, false, false);
}

function newTicketAnomalyLevelChanged() {
    switch(document.getElementById("ticket-anomaly-level").value) {
        case "Emergenza bloccante":
            warning("&Egrave; stata selezionata <strong>Emergenza bloccante</strong> - Intervento entro 2 ore - TELEFONA subito al Facility Manager o al suo Ufficio");
            break;
        case "Emergenza non bloccante":
            warning("&Egrave; stata selezionata <strong>Emergenza non bloccante</strong> - Intervento entro 8 ore - TELEFONA subito al Facility Manager o al suo Ufficio");
            break;
    }
}

function ajaxSubmitTicket() {
    var uploading = document.querySelectorAll("#create-ticket-popup .thumbnail-container.uploading");
    if(uploading.length>0){
        errorToast("Attendere il caricamento delle immagini");
        return;
    }
    var ticketDescription = document.getElementById('ticket-description').value;
    var newTicket = {};
    newTicket.anomalyLevel = document.getElementById("ticket-anomaly-level").value;
    newTicket.ticketType = document.getElementById("ticket-type").value;
    newTicket.siteId = parseInt(ticketSiteSelect.value);
    newTicket.categoryId = parseInt(ticketCategorySelect.value);
    newTicket.subcategoryId = parseInt(ticketSubcategorySelect.value);
    newTicket.description = ticketDescription;
    newTicket.relatedAnomaly = document.getElementById("ticket-related-anomaly").value;
    newTicket.assignWhileCreating=!!(document.getElementById("assign-while-creating-ticket").value!=0);
    newTicket.assignedCompaniesDetails = [];
    newTicket.costCenterId= document.getElementById("cost-center").value;
    var assignedCompaniesCounter = parseInt(document.getElementById("create-ticket-company-selector-counter").value);
    var assignedCompanyDetails;
    for(var i = 1; i <= assignedCompaniesCounter; i++) {
        assignedCompanyDetails = {};
        assignedCompanyDetails.companyId=parseInt(document.getElementById("create-ticket-company-assigned-to-ticket-" + i).value);
        assignedCompanyDetails.instructions=document.getElementById("create-ticket-company-assigned-to-ticket-instructions-" + i).value;
        newTicket.assignedCompaniesDetails.push(assignedCompanyDetails);
    }
    newTicket.interventionDate = document.getElementById("create-ticket-intervention-date-start").value.split("/").reverse().join("-");

    if(newTicket.relatedAnomaly === "") {
        delete newTicket.relatedAnomaly;
    }
    else {
        newTicket.relatedAnomaly = parseInt(newTicket.relatedAnomaly);
    }
    newTicket.relatedAssets=createTicketInvolvedAssetsTaggle.getTagValues();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                closePopup();//Only in case the ticket was opened from an anomaly details popup
                successToast("Ticket aperto con successo");
                newTicketAnomalyLevelChanged();
                photoUploads.forEach(function(photoUpload, photoIndex) {
                    if(photoUpload.uploadTarget === "ticket" && photoUpload.targetId === null) {
                        photoUpload.targetId = response.newTicketId;
                        //photos were already uploaded to server
                        if(photoUpload.photoId !== null) {
                            attachPhotoToEntity(photoIndex);
                        }
                    }
                });
                document.getElementById("ticket-description").value = "";
                ajaxGetTickets();
                //If I'm opening a ticket from an anomaly I'll have to refresh the anomaly section, to show that the anomaly has changed state
                if(newTicket.relatedAnomaly != null) {
                    ajaxGetAnomalies(lastSelectedAnomaly, true);
                }
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newTicket), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newTicket), xhttp.responseText);
            console.error(xhttp.response);
            console.error(e);
        }
    };
    if(newTicket.assignWhileCreating){
        xhttp.open("POST", BASE_URL + "/ws/createAndAssignTicket.php", true);
    }
    else{
        xhttp.open("POST", BASE_URL + "/ws/createTicket.php", true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newTicket));
}

function ajaxSubmitAnomaly() {
    var uploading = document.querySelectorAll("#create-anomaly-popup .thumbnail-container.uploading");
    if(uploading.length>0){
        errorToast("Attendere il caricamento delle immagini");
        return;
    }
    var anomalyDescription = document.getElementById('anomaly-description').value;
    var newAnomaly = {};
    newAnomaly.siteId = parseInt(document.getElementById('anomaly-site').value);
    newAnomaly.categoryId = parseInt(document.getElementById('anomaly-category').value);
    newAnomaly.subcategoryId = parseInt(document.getElementById('anomaly-subcategory').value);
    newAnomaly.description = anomalyDescription;
    newAnomaly.relatedAssets = createAnomalyInvolvedAssetsTaggle.getTagValues();
    closePopup();//closing submit anomaly popup
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                document.getElementById("anomaly-description").value = "";
                if(popupManager.getCurrentPopup()!=="comment-activity-on-asset-instance-execution") {
                    closePopup();
                }
                if(popupManager.getCurrentPopup()!=="comment-activity-on-asset-instance-execution") {
                    closePopup();
                }
                successToast("Segnalazione inviata con successo");
                photoUploads.forEach(function(photoUpload, photoIndex) {
                    if(photoUpload.uploadTarget === "anomaly" && photoUpload.targetId === null) {
                        photoUpload.targetId = response.anomalyId;
                        //photos were already uploaded to server
                        if(photoUpload.photoId !== null) {
                            attachPhotoToEntity(photoIndex);
                        }
                    }
                });
                if(currentPage==="page-anomalies") {
                    ajaxGetAnomalies(null, false);
                }
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newAnomaly), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(JSON.stringify(e), JSON.stringify(newAnomaly), xhttp.responseText);
            console.error(xhttp.response);
            console.error(e);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createAnomaly.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newAnomaly));
}

function changeTicketState(ticketId, newState, actionName, preselectedCompany, confirmed=false) {
    closePopup();
    ticketIdToChange = ticketId;
    if(actionName=="Annulla ticket"){
        if(!confirmed){
            document.getElementById("ticket-id-to-cancel").value=ticketId;
            popup("confirm-cancel-ticket");
            return;
        }
    }
    document.getElementById("assign-ticket-company-assigned-to-ticket-instructions-1").value="";
    if(preselectedCompany===null){
        document.getElementById("assign-ticket-company-assigned-to-ticket-1").value="";
        document.getElementById("assign-ticket-company-assigned-to-ticket-1").removeAttribute("disabled");
    }
    else {
        document.getElementById("assign-ticket-company-assigned-to-ticket-1").value = preselectedCompany;
        document.getElementById("assign-ticket-company-assigned-to-ticket-1").setAttribute("disabled","disabled");
    }

    var previousSelectorsCounter=document.getElementById("assign-ticket-company-selector-counter").value;
    for(var i=previousSelectorsCounter;i>1;i--){ //clearing the popup
        var removeCompanyButton=document.querySelector("#assign-ticket-company-selector-container-"+i+" button");//remove company button
        assignTicketRemoveCompanySelector("assign-ticket",removeCompanyButton);
    }
    switch(actionName){
        case "Aggiungi richiesta di intervento":
            setInnerHtml(document.getElementById("assign-ticket-popup-title"),"Aggiungi richiesta di intervento");
            popup("assign-ticket", false, false, false);
            break;
        case "Presa in carico":
        case "Assegna":
            setInnerHtml(document.getElementById("assign-ticket-popup-title"),"Assegna ticket");
            popup("assign-ticket", false, false, false);
            break;
        default:
            ajaxChangeTicketState(ticketId, newState);
            break;
    }
}

function assignTicketRemoveCompanySelector(prefix,that) {
    var counter = parseInt(that.parentNode.id.replace(prefix+"-company-selector-container-", ""));
    var selectCounter = parseInt(document.getElementById(prefix+"-company-selector-counter").value);
    document.getElementById(prefix+"-company-selector-container-" + counter).parentNode.removeChild(document.getElementById(prefix+"-company-selector-container-" + counter));
    document.getElementById(prefix+"-company-selector-counter").value = selectCounter - 1;
    for(var i = parseInt(counter); i < selectCounter; i++) {
        document.getElementById(prefix+"-company-selector-container-" + (i + 1)).id = prefix+"-company-selector-container-" + i;
        document.getElementById(prefix+"-company-assigned-to-ticket-instructions-" + (i + 1)).id = prefix+"-company-assigned-to-ticket-instructions-" + i;
    }
    switch(prefix){
        case "assign-ticket":
            scroll.refreshPopupById("assign-ticket", false);
            break;
        case "create-ticket":
            scroll.refreshPopupById("create-ticket", false);
            break;
    }
}

function assignTicketAddCompanySelector(prefix) {
    var tempHtml = "<option value='' selected='selected'>-- Selezionare --</option>";
    for(var i = 0; i < companies.length; i++) {
        if(companies[i].isContractor) {
            tempHtml += "<option value='" + companies[i].companyId + "'>" + companies[i].companyName + "</option>";
        }
    }
    var selectCounter = parseInt(document.getElementById(prefix+"-company-selector-counter").value) + 1;
    var newDiv = document.createElement("div");
    newDiv.setAttribute("class", prefix+"-selector-container");
    newDiv.setAttribute("id", prefix+"-company-selector-container-" + selectCounter);
    newDiv.innerHTML = "<div class='inline-form-component'>Azienda<br>" +
        "<select id='"+prefix+"-company-assigned-to-ticket-" + selectCounter + "' required='required'>" + tempHtml + "</select></div>" +
        "<br><br>Istruzioni operative<br>"+
        "<textarea maxlength='1000' title='Istruzioni operative per azienda assegnata' id='"+prefix+"-company-assigned-to-ticket-instructions-"+selectCounter+"'></textarea><br><br>"+
        "<button type='button' class='select-side-button' onclick='assignTicketRemoveCompanySelector(\""+prefix+"\",this);'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi</button>" +
        "</div>";
    document.getElementById(prefix+"-companies-selectors-container").appendChild(newDiv);
    document.getElementById(prefix+"-company-selector-counter").value = selectCounter;
    switch(prefix){
        case "assign-ticket":
            scroll.refreshPopupById("assign-ticket", false);
            break;
        case "create-ticket":
            scroll.refreshPopupById("create-ticket", false);
            break;
    }
}

function ajaxAssignCompanyToTicket() {
    var editingStatusContainer = document.getElementById("ticket-editing-status-container");
    editingStatusContainer.innerHTML = "<i class='fa fa-cog fa-spin fa-fw'></i> Modifica in corso...";
    closePopup();
    var assignedCompaniesCounter = parseInt(document.getElementById("assign-ticket-company-selector-counter").value);
    var ticketChange = {};
    ticketChange.newState = "Assegnato";
    ticketChange.assignedCompanies = []; //Deprecated 9/5/18
    ticketChange.assignedCompaniesDetails = [];
    for(var i = 1; i <= assignedCompaniesCounter; i++) {
        ticketChange.assignedCompanies.push(parseInt(document.getElementById("assign-ticket-company-assigned-to-ticket-" + i).value));
        var assignedCompanyDetails = {};
        assignedCompanyDetails.companyId=parseInt(document.getElementById("assign-ticket-company-assigned-to-ticket-" + i).value);
        assignedCompanyDetails.instructions=document.getElementById("assign-ticket-company-assigned-to-ticket-instructions-" + i).value;
        ticketChange.assignedCompaniesDetails.push(assignedCompanyDetails);
    }
    ticketChange.ticketId = ticketIdToChange;
    ticketChange.interventionDate = document.getElementById("ticket-intervention-date-start").value.split("/").reverse().join("-");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                ajaxGetTickets(ticketIdToChange);
                successToast("Stato del ticket modificato con successo");
            }
            else {
                editingStatusContainer.innerHTML = "<div class='message error'>" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(ticketChange), xhttp.responseText);
            }
        }
        catch(e) {
            editingStatusContainer.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(ticketChange), xhttp.responseText);
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/changeTicketAssignation.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(ticketChange));
}

function ajaxChangeTicketState(ticketId, newState) {
    var editingStatusContainer = document.getElementById("ticket-editing-status-container");
    editingStatusContainer.innerHTML = "<i class='fa fa-cog fa-spin fa-fw'></i> Modifica in corso...";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                ajaxGetTickets(ticketId);
                successToast("Stato del ticket modificato con successo");
            }
            else {
                editingStatusContainer.innerHTML = "<div class='message error'>" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, "newState=" + encodeURIComponent(newState) + "&ticketId=" + ticketId, xhttp.responseText);
            }
        }
        catch(e) {
            editingStatusContainer.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            ajaxSendErrorReport(xhttp.responseURL, "newState=" + encodeURIComponent(newState) + "&ticketId=" + ticketId, xhttp.responseText);
            console.error(xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/changeTicketState.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("newState=" + encodeURIComponent(newState) + "&ticketId=" + ticketId);
}

function pushpinClicked(event) {
    maintenancePage.setSiteFilter(event.target.clasterFmId);
    if(lastPushPinSelected != null) {
        lastPushPinSelected.setOptions({icon: imgPushpin, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
    }
    event.target.setOptions({icon: imgPushpinSelected, height: 20, width: 20, anchor: new Microsoft.Maps.Point(10, 10)});
    lastPushPinSelected = event.target;
}

function pushpinHovered(event) {
    var hoveredSiteName;
    var loc = event.target.getLocation();
    mapInfoboxClickHandler = Microsoft.Maps.Events.addHandler(mapInfobox, "click", function() {
        pushpinClicked(event);
    });
    mapInfobox.setMap(map);
    mapInfobox.setLocation(loc);
    for(i = 0; i < sites.length; i++) {
        if(sites[i].siteId == event.target.clasterFmId) {
            hoveredSiteName = sites[i].name;
            break;
        }
    }
    mapInfobox.setHtmlContent("<div id='map-infobox'>" + hoveredSiteName + "</div>");
}

function pushpinUnhovered() {
    mapInfobox.setHtmlContent("<div></div>");
    Microsoft.Maps.Events.removeHandler(mapInfoboxClickHandler);
}

function loadMapScenario() {
    var mapContainer;
    if(!isSmallViewport()) {
        mapContainer = document.getElementById("map");
    }
    else {
        mapContainer = document.getElementById("mobile-map");
    }
    map = new Microsoft.Maps.Map(mapContainer, {
        credentials: "AgQ7qvNf9Wy80WJgFHAPh6t1M6ejn2cGL0jEuGybVNqXOzXR3hsyuHSfhxIvDX3U",
        center: new Microsoft.Maps.Location(42, 12),
        mapTypeId: Microsoft.Maps.MapTypeId.canvasLight,
        zoom: 5,
        enableClickableLogo: false,
        showCopyright: false,
        showMapTypeSelector: false,
        showDashboard: true
    });
    if(sites !== undefined) {
        createSitePushpins();
    }
}

function renderSubcategoryWithIcon(subcategoryId) {
    var i;
    for(i=0;i<subcategories.length;i++) {
        if(subcategories[i].subcategoryId==subcategoryId) {
            return "<div class='category-small b-" + subcategories[i].background + "'>" + subcategories[i].icon + "</div>" + subcategories[i].name;
        }
    }
    return "";
}

function renderInterventionEntry(intervention,renderDate) {
    var hasTicket;
    var tempHtml3 = "";
    var tempHtml = "";
    var interventionWarning = "";
    var alreadyWrittenSubcategoriesWithoutTicket = [];
    var alreadyWrittenSubcategoriesWithTicket = [];
    for(var j = 0; j < intervention.activities.length; j++) {
        if(
            (
                (!hasTicket)
                && (alreadyWrittenSubcategoriesWithoutTicket.indexOf(intervention.activities[j].subcategoryId) < 0)
            )
            ||
            (
                (hasTicket)
                && (alreadyWrittenSubcategoriesWithTicket.indexOf(intervention.activities[j].subcategoryId) < 0)
            )
        ) {
            var tempHtml2 = "";
            tempHtml2 += "<div class='category-label'><div class='category-small b-" + intervention.activities[j].categoryIconBackground + "'>" + intervention.activities[j].categoryIcon + "</div>" + intervention.activities[j].category + "</div>";

            hasTicket = intervention.activities[j].containsTicket;
            if(intervention.activities[j].subcategoryIcon != null) {
                tempHtml2 += "<div class='subcategory-label'><div class='category-small b-" + intervention.activities[j].categoryIconBackground + "'>" + intervention.activities[j].subcategoryIcon + "</div>" + intervention.activities[j].subcategory + "</div>";
            }
            else {
                tempHtml2 += "<div class='subcategory-label'>&nbsp;</div>";
            }
            if(hasTicket) {
                alreadyWrittenSubcategoriesWithTicket.push(intervention.activities[j].subcategoryId);
            }
            else {
                alreadyWrittenSubcategoriesWithoutTicket.push(intervention.activities[j].subcategoryId);
            }
            if(hasTicket) {
                tempHtml2 += "<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>";
            }
            if(intervention.executor === null) {
                interventionWarning = "<div class='category-small b-red'><i class='fa fa-exclamation' aria-hidden='true'></i></div>";
            }
            tempHtml3 += "<div class='intervention-subcategory-dots " + (hasTicket ? "has-ticket" : "") + "'>";
            tempHtml3 += tempHtml2;
            tempHtml3 += "</div>";
        }
    }

    //If there are filtered activities in the intervention
    if(tempHtml3 !== "") {
        var stateHtml="";
        var entryAdditionalClasses="";
        if(intervention.isDisabled) {
            stateHtml="<span class='entry-disabled-label'>Disabilitato</span>";
            entryAdditionalClasses=" disabled";
        }
        else {
            if(intervention.validationDatetime != null) {
                stateHtml="<span class='entry-completed-label'>Validato</span>";
                entryAdditionalClasses=" completed";
            }
            else {
                if(intervention.endDate != null){
                    stateHtml="<span class='entry-completed-label'>Completato</span>";
                    entryAdditionalClasses=" completed";
                }
            }
        }
        tempHtml += "<div class='intervention-entry entry" + entryAdditionalClasses + ((intervention.interventionCompleted) ? " completed" : "") + "' onclick='closePopup();selectIntervention(" + intervention.interventionId + ");'><div class='entry-body'><div class='dots entry-header'>";
        tempHtml += "<div class='entry-title'>" + interventionWarning + " Intervento " + ((intervention.parentInterventionId!=null)?intervention.parentInterventionId:intervention.interventionId) + stateHtml + "</div>";
        tempHtml += renderWindIcon(intervention.windSpeed);
        tempHtml += renderMeteoIcon(intervention.weather);
        tempHtml += "<div class='site-label'><span class='site'>" + intervention.letter + "</span>" + intervention.siteName + "</div>";
        tempHtml += "<div class='site-label'><span class='site'><i class='fa fa-wrench' aria-hidden='true'></i></span>" + ((intervention.executorName === null) ? "Non assegnato" : intervention.executorName) + "</div>";
        if(renderDate){
            tempHtml += "<div class='time-label'><span class='clock'><i class='fa fa-calendar' aria-hidden='true'></i></span>"+iso8601.toEuropeanDate(intervention.isoPlannedStartDate,"-")+"</div>";
        }
        tempHtml += "</div><div class='dots'>";
        tempHtml += tempHtml3;
        tempHtml += "</div></div></div></div></div>";
    }
    return tempHtml;
}

function filterByCategory(categoryId, categoryGroupId) {
    var categoryGroupButton = document.getElementById("category-" + categoryGroupId);
    var categoryButton = document.getElementById("category-" + categoryGroupId + '-' + categoryId);
    if(!dom.hasClass(categoryGroupButton, "selected")) {
        categoryGroupButton.click();
    }
    if(!dom.hasClass(categoryButton, "selected")) {
        categoryButton.click();
    }
}

function filterBySubcategory(subcategoryId, categoryId, categoryGroupId) {
    var categoryGroupButton = document.getElementById("category-" + categoryGroupId);
    var categoryButton = document.getElementById("category-" + categoryGroupId + '-' + categoryId);
    var subcategoryButton = document.getElementById("category-" + categoryGroupId + '-' + categoryId + '-' + subcategoryId);
    if(!dom.hasClass(categoryGroupButton, "selected"))
        categoryGroupButton.click();
    if(!dom.hasClass(categoryButton, "selected"))
        categoryButton.click();
    if(!dom.hasClass(subcategoryButton, "selected"))
        subcategoryButton.click();
}

function removeMaintenanceFilters() {
    document.getElementById("maintenance-category-selector").value = "";
    document.getElementById("maintenance-site-selector").value="";
    maintenancePage.setSiteFilter(null);
    maintenanceCategoryChanged("");
    countMaintenanceAppliedFilters();
    closePopup();
}

function removeCalendarsFilters() {
    document.getElementById("calendar-site-selector").value = "";
    document.getElementById("calendar-year-site-selector").value = "";

    document.getElementById("calendar-category-selector").value = "";
    calendarCategoryChanged();

    document.getElementById("calendar-year-executor-selector").value = "";
    document.getElementById("calendar-executor-selector").value = "";

    renderCalendar(lastMonth, lastYear);
    renderYearCalendar(lastYear);

    countMonthCalendarAppliedFilters();
    countYearCalendarAppliedFilters();

    closePopup();
}

function countMaintenanceAppliedFilters() {
    var appliedFiltersCounter = 0;
    if(appliedFilters["category"] != null){
        appliedFiltersCounter++;
    }
    if(appliedFilters["subcategory"] != null){
        appliedFiltersCounter++;
    }
    if(document.getElementById("maintenance-site-selector").value!==""){
        appliedFiltersCounter++;
    }
    var appliedFilterCounterText = "";
    if(appliedFiltersCounter > 0) {
        appliedFilterCounterText = appliedFiltersCounter;
        dom.addClass(document.getElementById("filter-button-maintenance"), "active");
    }
    else {
        dom.removeClass(document.getElementById("filter-button-maintenance"), "active");
    }
    setInnerHtml(document.querySelector("#filter-button-maintenance .filter-counter"), appliedFilterCounterText);
}

function maintenanceSubcategoryChanged(subcategoryId) {
    if(subcategoryId === "") {
        subcategoryId = null;
    }
    appliedFilters["subcategory"] = subcategoryId;
    maintenancePage.refresh();
}

function maintenanceCategoryChanged(categoryId) {
    if(categoryId === "") {
        categoryId = null;
    }
    appliedFilters["category"] = categoryId;
    appliedFilters["subcategory"] = null;
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++) {
        if((categoryId == null || subcategories[i].categoryId == categoryId)&& subcategories[i].deletion==null)
            tempHtml += "<option value='" + subcategories[i].subcategoryId + "'>" + subcategories[i].name + "</option>";
    }
    document.getElementById("maintenance-subcategory-selector").innerHTML = tempHtml + "<option value='' selected='selected'>-- Tutte --</option>";
    maintenancePage.refresh();
}

function propagateCategoryChangeToSubcategory(categoryId,subcategorySelectId){
    if(categoryId === "") {
        categoryId = null;
    }
    var tempHtml = "";
    subcategories.forEach(function(subcategory){
        if(categoryId===null||subcategory.categoryId==categoryId){
            if(subcategory.subcategoryId==null){
                tempHtml += "<option value=''>-- Nessuna --</option>";
            }
            else{
                tempHtml += "<option value='" + subcategory.subcategoryId + "'>" + subcategory.name + "</option>";
            }
        }
    });
    setInnerHtml(document.getElementById(subcategorySelectId),tempHtml);
}

function selectIntervention(interventionId, successCallback, backButton) {
    if(backButton) {
        defineTapListener("intervention-back-btn",function(){selectTicket(backButton,true);});
    }
    else {
        defineTapListener("intervention-back-btn",ui.pageBack);
    }
    closePopup();
    changePage('page-intervention', true, true);
    selectedIntervention = interventionId;
    hashChangedInternally = true;
    window.location.hash = "#intervention/" + interventionId;

    //Removing closing intervention data
    document.getElementById("end-intervention-notes").value="";

    ajaxGetInterventionData(interventionId, successCallback);
}

function selectInterventionActivity(interventionActivityId) {
    changePage('page-intervention-activity', true);
    selectedInterventionActivity = interventionActivityId;
    ajaxGetInterventionActivityData(interventionActivityId);
}

function selectAssetInstancePreview(i) {
    var tempHtml = "<div><table class='main-content'>";
    tempHtml += "<tr><th><i onclick='popup( \"help-ticket-assets\" ,false,false,false);' class='fa fa-qrcode qr-icon' aria-hidden='true'></i> QR asset</th><td>" + ((lastAssetInstances[i].qrCodeId === null) ? "Non ancora assegnato" : lastAssetInstances[i].qrCodeId) + "</td></tr>";
    tempHtml += "<tr><th>Codice interno</th><td>" + ((lastAssetInstances[i].internalCode === null) ? "-" : lastAssetInstances[i].internalCode) + "</td></tr>";
    tempHtml += "<tr><th>Matricola</th><td>" + ((lastAssetInstances[i].registrationNumber === null) ? "-" : lastAssetInstances[i].registrationNumber) + "</td></tr>";
    tempHtml += "<tr><th>Marca</th><td>" + ((lastAssetInstances[i].brand === null) ? "-" : lastAssetInstances[i].brand) + "</td></tr>";
    tempHtml += "<tr><th>Modello</th><td>" + ((lastAssetInstances[i].model === null) ? "-" : lastAssetInstances[i].model) + "</td></tr>";
    tempHtml += "</table>";
    tempHtml += "<div class='bottom-bar'><button type='button' onclick='closePopup();ajaxGetAssetInstance(" + lastAssetInstances[i].assetInstanceId + ");'><i class='fa fa-share' aria-hidden='true'></i> Vai alla scheda asset</button></div></div>";
    setInnerHtml(
        document.querySelector("#intervention-asset-details-popup .popup-content div"),
        tempHtml,
        function(){scroll.refreshPopupById("intervention-asset-details", false);}
    );
    popup("intervention-asset-details", false, false, false);
}

function removeAssetDocumentFromPicker(pickerId) {
    document.getElementById(pickerId + "-file").querySelector(".composite-picker-value").innerHTML = "";
    document.getElementById(pickerId + "-file").setAttribute("data-selected-document-id", "");
}

function ajaxDeletePicture(pictureId) {
    var requestData = {};
    requestData.pictureId = parseInt(pictureId);
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Foto eliminata correttamente");
                var thumbnail=document.getElementById("data-picture-id-"+pictureId);
                dom.removeElement(thumbnail);
                ajaxGetAssetInstance(parseInt(lastViewedAssetInstance.assetInstanceId));
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(userData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deletePicture.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function generateThumbnailContainer(pictureId,thumbURI){
    var thumbnailContainer = document.createElement("div");
    //Cancel photo button
    var cancelPhotoButton = document.createElement("button");
    cancelPhotoButton.setAttribute("type","button");
    cancelPhotoButton.innerHTML="×";
    dom.addClass(cancelPhotoButton,"cancel-photo-button");
    cancelPhotoButton.onclick = (function() {
        //Closure to save the value of photoUploadsId
        var savedPictureId = pictureId;
        return function() {
            ajaxDeletePicture(savedPictureId);
        };
    })();
    dom.addClass(thumbnailContainer,"thumbnail-container");
    dom.addClass(thumbnailContainer,"uploading");
    var thumbnail = document.createElement("img");
    thumbnail.setAttribute('src', thumbURI);
    thumbnailContainer.appendChild(thumbnail);
    thumbnailContainer.appendChild(cancelPhotoButton);
    thumbnailContainer.id="data-picture-id-"+pictureId;
    return thumbnailContainer;
}

function initializeEditAssetInstancePopup(asset) {
    var i;
    document.getElementById("edit-asset-photo-thumbnails").innerHTML="";
    var rowsToClear=document.getElementById("edit-asset-form-category-rows-counter").value;
    for(i=2; i<=rowsToClear; i++) {
        dom.removeElement(document.getElementById("edit-asset-form-category-row-"+i));
    }
    document.getElementById("edit-asset-form-category-rows-counter").value = 1;
    var deletedAssetSubcategories = 0, totalUndeletedAssetSubcategories=0 ;
    for(i=0; i<asset.subcategories.length; i++){
        if(asset.subcategories[i].deletion==null){
            totalUndeletedAssetSubcategories++;
        }
    }
    for(i=0; i<asset.subcategories.length; i++){
        if(asset.subcategories[i].deletion==null) {
            var assetCategorySelector = document.querySelector("#edit-asset-form-category-row-"+(i+1-deletedAssetSubcategories)+" .edit-asset-category");
            var assetSubcategorySelector = document.querySelector("#edit-asset-form-category-row-"+(i+1-deletedAssetSubcategories)+" .edit-asset-subcategory");
            assetCategorySelector.value = asset.subcategories[i].categoryId;
            editAssetLoadSubcategories(assetCategorySelector,function(){
                assetSubcategorySelector.value = asset.subcategories[i].subcategoryId;
            });
            if((i+1-deletedAssetSubcategories)<totalUndeletedAssetSubcategories){
                addSubcategoryRowToAssetFromEditPopup();
            }
        }
        else {
            deletedAssetSubcategories++;
        }
    }
    document.getElementById("edit-asset-qr-id").value = decodeHTMLEntities(asset.qrCodeId);
    document.getElementById("edit-asset-internal-code").value = decodeHTMLEntities(asset.internalCode);
    document.getElementById("edit-asset-registration-number").value = decodeHTMLEntities(asset.registrationNumber);
    document.getElementById("edit-asset-brand").value = decodeHTMLEntities(asset.brand);
    document.getElementById("edit-asset-model").value = decodeHTMLEntities(asset.model);

    var tempHtml = "";
    var matchingFloor = false;
    for(i = 0; i < floors.length; i++) {
        if(floors[i].siteId == asset.site) {
            if(asset.floor == floors[i].floorId) {
                tempHtml += "<option value='" + floors[i].floorId + "' selected='selected'>" + floors[i].floorName + "</option>";
                matchingFloor = true;
            }
            else {
                tempHtml += "<option value='" + floors[i].floorId + "'>" + floors[i].floorName + "</option>";
            }
        }
    }
    if(!matchingFloor) {
        tempHtml += "<option value='' selected='selected'>-- Nessuno --</option>";
    }
    else {
        tempHtml += "<option value=''>-- Nessuno --</option>";
    }
    setInnerHtml(document.getElementById("edit-asset-floor"), tempHtml);
    

    tempHtml = "";
    var matchingArea = false;
    for(i = 0; i < areas.length; i++) {
        if(areas[i].siteId == asset.site) {
            if(asset.areaId == areas[i].areaId) {
                tempHtml += "<option value='" + areas[i].areaId + "' selected='selected'>" + areas[i].code + " "+areas[i].name + "</option>";
                matchingArea = true;
            }
            else {
                tempHtml += "<option value='" + areas[i].areaId + "'>" + areas[i].code + " "+areas[i].name + "</option>";
            }
        }
    }
    if(!matchingArea) {
        tempHtml += "<option value='' selected='selected'>-- Nessuno --</option>";
    }
    else {
        tempHtml += "<option value=''>-- Nessuno --</option>";
    }
    setInnerHtml(document.getElementById("edit-asset-area"), tempHtml);

    document.getElementById("edit-asset-type").value = decodeHTMLEntities(asset.type);
    document.getElementById("edit-asset-production-number").value = decodeHTMLEntities(asset.productionNumber);
    document.getElementById("edit-asset-construction-year").value = asset.constructionYear;
    document.getElementById("edit-asset-size").value = decodeHTMLEntities(asset.size);
    document.getElementById("edit-asset-installation-date").value = iso8601.toEuropeanDate(asset.isoInstallationDate,"");
    document.getElementById("edit-asset-expiration").value = iso8601.toEuropeanDate(asset.expiration,"");

    document.getElementById("edit-asset-homologation").value = decodeHTMLEntities(asset.homologation);
    document.getElementById("edit-asset-certification").value = decodeHTMLEntities(asset.certification);
    document.getElementById("edit-asset-position-hint").value = decodeHTMLEntities(asset.positionHint);
    document.getElementById("edit-asset-description").value = decodeHTMLEntities(asset.description);

    document.getElementById("edit-asset-value-as-new").value = decodeHTMLEntities(asset.assetValueAsNew);
    document.getElementById("edit-asset-instantaneous-consumption").value = decodeHTMLEntities(asset.instantaneousConsumption);
    document.getElementById("edit-asset-expected-life-years").value = decodeHTMLEntities(asset.expectedLifeInYears);
    document.getElementById("edit-asset-day-work").value = decodeHTMLEntities(asset.operationHoursOfDay);
    document.getElementById("edit-asset-year-work").value = decodeHTMLEntities(asset.operationDaysOfYear);
    if(asset.categoryName === "Meccanico" || asset.categoryName === "Elettrico") {
        dom.removeClass(document.getElementById("edit-info-available-for-mechanical-and-electrical"), "hidden");
    }
    else {
        dom.addClass(document.getElementById("edit-info-available-for-mechanical-and-electrical"), "hidden");
    }
    var documentButtonsIds = [
        "asset-document-manual",
        "asset-document-project",
        "asset-document-conformity-declaration"
    ];
    var tip;
    documentButtonsIds.forEach(function(tapListenerElementId) {
        unbindTapListenerOnElement(document.getElementById(tapListenerElementId));
        document.getElementById(tapListenerElementId+"-file").value = "";
        document.getElementById(tapListenerElementId+"-file").setAttribute("data-selected-document-id","");
        defineTapListener(tapListenerElementId,(
            (function() {
                // Something declared here will only be available to the function below.
                // Code here is executed only once upon the creation of the inner function
                var savedElementId = tapListenerElementId;
                var tip;
                switch(tapListenerElementId) {
                    case "asset-document-manual":
                        tip = "Selezionare il <strong>Manuale</strong> per questo asset";
                        break;
                    case "asset-document-project":
                        tip = "Selezionare il <strong>Progetto</strong> per questo asset";
                        break;
                    case "asset-document-conformity-declaration":
                        tip = "Selezionare la <strong>Dichiarazione di conformità</strong> per questo asset";
                        break;
                }
                return function(event) {
                    // Button click callback
                    searchDocumentPopup.open(function(selectedDocumentId){
                        //Document selected callback
                        console.log("Documento selezionato: "+selectedDocumentId);
                        lastQueriedDocuments.forEach(function(currentDocument) {
                            if(currentDocument.documentId==selectedDocumentId) {
                                document.getElementById(savedElementId+"-file").querySelector(".composite-picker-value").innerHTML=currentDocument.fileName;
                                document.getElementById(savedElementId+"-file").setAttribute("data-selected-document-id",currentDocument.documentId);
                            }
                        });
                    },tip);
                };
            })()
        ));
    });

    removeAssetDocumentFromPicker("asset-document-manual");
    removeAssetDocumentFromPicker("asset-document-project");
    removeAssetDocumentFromPicker("asset-document-conformity-declaration");

    //After the documents fields are cleared I add the asset documents to the buttons
    if(asset.documents!=undefined) {
        asset.documents.forEach(function(assetDocument) {
            var tapListenerElementId = null;
            var valueElementId = null;
            var documentName = null;
            switch(assetDocument.assetDocumentType) {
                case 'manual':
                    tapListenerElementId = "asset-document-manual";
                    valueElementId = "asset-document-manual-file";
                    break;
                case 'project':
                    tapListenerElementId = "asset-document-project";
                    valueElementId = "asset-document-project-file";
                    break;
                case 'conformityDeclaration':
                    tapListenerElementId = "asset-document-conformity-declaration";
                    valueElementId = "asset-document-conformity-declaration-file";
                    break;
                }
                document.getElementById(valueElementId).querySelector(".composite-picker-value").innerHTML=assetDocument.assetDocumentName;
                document.getElementById(valueElementId).setAttribute("data-selected-document-id",assetDocument.assetDocumentId);
            });
    }
    document.getElementById("edit-asset-photo-thumbnails").innerHTML="";
    if(asset.relatedPictures.length > 0) {
        for(i = 0; i < asset.relatedPictures.length; i++) {
            document.getElementById("edit-asset-photo-thumbnails").appendChild(generateThumbnailContainer(
                asset.relatedPictures[i],
                (DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + asset.relatedPictures[i] + "&height=160&authToken=" + currentAuthToken)
            ));
        }
    }
    document.getElementById("edit-asset-ambient-photo-thumbnails").innerHTML="";
    if(asset.relatedPicturesAmbient.length > 0) {
        for(i = 0; i < asset.relatedPicturesAmbient.length; i++) {
            //Generating the thumbnail in the DOM
            document.getElementById("edit-asset-ambient-photo-thumbnails").appendChild(generateThumbnailContainer(
                asset.relatedPicturesAmbient[i],
                (DIRECT_DOWNLOAD_URL + "/ws/renderImage.php?imageId=" + asset.relatedPicturesAmbient[i] + "&height=160&authToken=" + currentAuthToken)
            ));
        }

    }
    scroll.refreshPopupById("edit-asset-instance", false);
}

function selectAssetInstance(assetInstanceId, changePageAfter) {
    //default value for changePageAfter is TRUE
    changePageAfter = typeof changePageAfter !== 'undefined' ? changePageAfter : true;
    lastViewedAssetInstance = null;
    //TODO: UNIRE lastQueriedAssets E preFetchedAssets IN UN'UNICA GESTIONE ASSET PIU'AGGIORNATA E OTTIMIZZATA POSSIBILE
    /*for(var i = 0; i < preFetchedAssets.length; i++) {
        if(preFetchedAssets[i].assetInstanceId == assetInstanceId) {
            lastViewedAssetInstance = preFetchedAssets[i];
        }
    }*/
    //if(lastViewedAssetInstance === null) {
        ajaxGetAssetInstance(assetInstanceId, null);
   /* }
    else {
        renderAsset(lastViewedAssetInstance, changePageAfter);
        ajaxGetAssetInstanceEventLog(lastViewedAssetInstance.assetInstanceId);
    }*/
}

function renderAssetInstanceEvent(event) {
    var tempHtml="", additionalClass="",datetime,datetimeLabel="";
    if(event.isoEndDateTime!==null){
        datetime = iso8601.toEuropeanDate(event.isoEndDateTime);
        datetimeLabel = event.type=="Anomaly"?"Data segnalazione":"Fine intervento";
    }
    else {
        datetime = iso8601.toEuropeanDate(event.isoPlannedDatetime);
        datetimeLabel = "Fine intervento prevista";
    }
    var eventTypeIcon="",eventTypeTitle="",onclickHtml="";
    switch(event.type) {
        case "Intervention":
            eventTypeIcon = "<div class='category-small b-violet'><i class='fa fa-calendar' aria-hidden='true'></i></div>";
            eventTypeTitle = "M.O.P. - Intervento "+event.interventionId;
            onclickHtml = "onclick='selectIntervention(" + event.interventionId + ");'";
            break;
        case "Ticket":
            eventTypeIcon="<div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>";
            eventTypeTitle = "Ticket "+((event.ticketType.includes("M.S."))?"M.S.":"M.O.")+" - Rich.di Int. "+event.interventionId;
            onclickHtml = "onclick='selectIntervention(" + event.interventionId + ");'";
            break;
        case "Anomaly":
            eventTypeIcon="<div class='category-small b-violet'><i class='fa fa-crosshairs' aria-hidden='true'></i></div>";
            eventTypeTitle = "Segnalazione "+ event.anomalyId;
            onclickHtml = "onclick='selectAnomaly(" + event.anomalyId + ",true);'";
            break;
    }
    if(event.isDisabled){
        additionalClass = "disabled";
        //Overriding previously choosed icon
        eventTypeIcon="<div class='category-small b-grey'><i class='fa fa-hand-paper-o' aria-hidden='true'></i></div>";
    }
    else {
        if(event.type!="Anomaly"){
            if(event.isLate){
                additionalClass = "late";
            }
            if(event.isoEndDateTime !== null) {
                additionalClass = "completed";
            }
        }
    }
    tempHtml += "<div "+onclickHtml+" class='entry asset-event-entry " + additionalClass + "'>" +
        "<div class='entry-header'>" + eventTypeIcon + eventTypeTitle +" </div><div class='entry-body'>" + event.state + "<br>" + datetime + " - " + datetimeLabel + "</div></div>";
    return tempHtml;
}

function renderAssetExpiration(expirationData){
    var tempHtml="",datetime;
    datetime = iso8601.toEuropeanDate(expirationData.plannedStartDate);
    tempHtml += "<div class='entry asset-event-entry'>" +
        "<div class='entry-header'><div class='category-small b-violet'><i class='fa fa-hourglass-half' aria-hidden='true'></i></div>" + (expirationData.assetMustBeReplaced?"Obbligo di sostituzione":"Possibilità di sostituzione") + " </div><div class='entry-body'>" + expirationData.description + "<br> In data " + datetime + "</div></div>";
    return tempHtml;
}

function ajaxGetAssetExpirations(assetInstanceId) {
    var resultsContainer = document.querySelector("#page-asset .following-results-container");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            var tempHtml = "";
            if(xhttp.status === 200) {
                if(response.expirations.length > 0) {
                    tempHtml = "<span class='important-label'>Prossime sostituzioni</span><br><br>";
                    for(var i = 0; i < response.expirations.length; i++) {
                        tempHtml+=renderAssetExpiration(response.expirations[i]);
                    }
                    tempHtml+="<br>";
                }
            }
            setInnerHtml(
                resultsContainer,
                tempHtml,
                function() {
                    scroll.refreshByName("asset-events");
                }
            );
        }
        catch(e) {
            console.error(e);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/asset/getExpirations.php?assetInstanceId=" + assetInstanceId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function ajaxGetAssetInstanceEventLog(assetInstanceId) {
    var resultsContainer = document.querySelector("#page-asset .results-container");
    setInnerHtml(
        resultsContainer,
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function(){scroll.refreshByName("asset-events");}
    );
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            var tempHtml = "";
            if(xhttp.status === 200) {
                tempHtml = "<span class='important-label'>Storico eventi asset</span><br><br>";
                if(response.events.length > 0) {
                    var eventType;
                    var datetime;
                    for(var i = 0; i < response.events.length; i++) {
                        tempHtml+=renderAssetInstanceEvent(response.events[i]);
                    }
                }
                else {
                    tempHtml += "<div class='no-results-message'>Nessun evento da mostrare</div>";
                }
            }
            else {
                tempHtml = "<span class='important-label'>Storico eventi asset</span><br><br><div class='message error'>" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
            setInnerHtml(
                resultsContainer,
                tempHtml,
                function(){scroll.refreshByName("asset-events");}
            );
        }
        catch(e) {
            setInnerHtml(
                resultsContainer,
                "<span class='important-label'>Eventi relativi all'asset</span><br><div class='message error'>Errore durante l'elaborazione: " + e + "</div>",
                function(){scroll.refreshByName("asset-events");}
            );
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getEventsOnAssetInstance.php?assetInstanceId=" + assetInstanceId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function buildingsGroupOwnerAdminMatchChanged() {
    var checkbox = document.getElementById("buildings-group-owner-admin-match");
    if(checkbox.getAttribute("data-checked")!=="1") {
        checkbox.setAttribute("data-checked","1");
        document.getElementById("buildings-group-owner-label").innerHTML = "Proprietario e amministratore";
        dom.addClass(document.getElementById("buildings-group-admin-markup"), "hidden");
        document.getElementById("buildings-group-admin-name").removeAttribute("required");
    }
    else {
        checkbox.setAttribute("data-checked","0");
        document.getElementById("buildings-group-owner-label").innerHTML = "Proprietario";
        dom.removeClass(document.getElementById("buildings-group-admin-markup"), "hidden");
        document.getElementById("buildings-group-admin-name").required = "required";
    }
}

function editBuildingsGroupOwnerAdminMatchChanged() {
    var checkbox = document.getElementById("edit-buildings-group-owner-admin-match");
    if(checkbox.getAttribute("data-checked")!=="1") {
        checkbox.setAttribute("data-checked","1");
        document.getElementById("edit-buildings-group-owner-label").innerHTML = "Proprietario e amministratore";
        dom.addClass(document.getElementById("edit-buildings-group-admin-markup"), "hidden");
        document.getElementById("edit-buildings-group-admin-name").removeAttribute("required");
    }
    else {
        checkbox.setAttribute("data-checked","0");
        document.getElementById("edit-buildings-group-owner-label").innerHTML = "Proprietario";
        dom.removeClass(document.getElementById("edit-buildings-group-admin-markup"), "hidden");
        document.getElementById("edit-buildings-group-admin-name").required = "required";
    }
}

function setNewEntityTypeCompany() {
    dom.addClass(document.getElementById("add-company-name-markup"), "hidden");
    dom.addClass(document.getElementById("add-company-surname-markup"), "hidden");
    dom.removeClass(document.getElementById("add-company-fiscal-name-markup"), "hidden");
    dom.removeClass(document.getElementById("add-company-vat-rate-markup"), "hidden");
    document.getElementById("add-company-fiscal-name").setAttribute("required", "required");
    document.getElementById("add-company-name").removeAttribute("required");
    document.getElementById("add-company-surname").removeAttribute("required");
}

function setNewEntityTypePrivate() {
    dom.addClass(document.getElementById("add-company-vat-rate-markup"), "hidden");
    dom.addClass(document.getElementById("add-company-fiscal-name-markup"), "hidden");
    dom.removeClass(document.getElementById("add-company-name-markup"), "hidden");
    dom.removeClass(document.getElementById("add-company-surname-markup"), "hidden");
    document.getElementById("add-company-fiscal-name").removeAttribute("required");
    document.getElementById("add-company-name").setAttribute("required", "required");
    document.getElementById("add-company-surname").setAttribute("required", "required");
}

function accountingBuildingsGroupChanged() {
    clearAccountingDashboard();
    var selectedBuildingsGroup = document.getElementById("accounting-buildings-group").value;
    ajaxGetAccountingYears(selectedBuildingsGroup);
}

function getPasswordScore(password) {
    var score = 0;
    if(password.length>=8){
        score += password.length * 0.35;
        if(/\d/.test(password))//contains number
            score += 1.75;
        if(/[a-z]/.test(password))//contains low case
            score += 1.75;
        if(/[A-Z]/.test(password))//contains up case
            score += 1.75;
        if(/[^A-Za-z0-9]/.test(password))//contains special char
            score += 1.75;
    }
    return score;
}

function ajaxGetAccountingYears(buildingsGroupId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "";
            for(var i = 0; i < response.accountingYears.length; i++) {
                var beginsDate = parseDate(response.accountingYears[i].begins);
                var endsDate = parseDate(response.accountingYears[i].ends);

                var accountingYearLabel = monthNames[beginsDate.getMonth()].substring(0, 3) + " " + beginsDate.getFullYear() + " - " + monthNames[endsDate.getMonth()].substring(0, 3) + " " + endsDate.getFullYear();

                tempHtml += "<option value='" + response.accountingYears[i].accountingYearId + "'>" + accountingYearLabel + "</option>";
            }
            tempHtml += "<option value='' selected disabled>-- Selezionare --</option>";
            document.getElementById("accounting-year-selector").innerHTML = tempHtml;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAccountingYears.php?buildingGroupId=" + buildingsGroupId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetBalances() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            balances = response.balances;
            tempHtml += "<tr><th>Fornitore</th><th>Saldo [EUR]</th></tr>";
            for(var i = 0; i < response.balances.length; i++) {
                if(response.balances[i].balance < 0)
                    tempHtml += "<tr><td>" + response.balances[i].supplierName + "</td><td class='passive-balance'>" + formatCurrency(response.balances[i].balance) + "</td></tr>";
                else
                    tempHtml += "<tr><td>" + response.balances[i].supplierName + "</td><td class='active-balance'>" + formatCurrency(response.balances[i].balance) + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-balances-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-balances", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getBalances.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetTransactions() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Data movimento</th><th>Azienda</th><th>Causale</th><th>Totale movimento [EUR]</th></tr>";
            for(var i = 0; i < response.transactions.length; i++)
                tempHtml += "<tr><td>" + response.transactions[i].date + "</td><td>" + response.transactions[i].companyName + "</td><td>" + response.transactions[i].causal + "</td><td>" + formatCurrency(response.transactions[i].amount) + "</td></tr>";
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-transactions-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-transactions", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getTransactions.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetInvoices() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>N° reg</th><th>Fornitore</th><th>Cliente</th><th>Importo netto [EUR]</th><th>IVA [EUR]</th><th>Altre imposte [EUR]</th><th>Saldata</th></tr>";
            for(var i = 0; i < response.invoices.length; i++) {
                if(response.invoices[i].registrationNumber == null)
                    tempHtml += "<tr><td>-</td><td>" + response.invoices[i].supplier + "</td><td>" + response.invoices[i].siteName + "</td><td>" + formatCurrency(response.invoices[i].untaxedAmount) + "</td><td>" + formatCurrency(response.invoices[i].vat) + "</td><td>" + formatCurrency(response.invoices[i].otherTaxes) + "</td><td>" + (response.invoices[i].debit > 0 ? "<span class='red-state'>NO</span>" : "<span class='green-state'>SI</span>") + "</td></tr>";
                else
                    tempHtml += "<tr><td>" + response.invoices[i].registrationNumber + "</td><td>" + response.invoices[i].supplier + "</td><td>" + response.invoices[i].siteName + "</td><td>" + formatCurrency(response.invoices[i].untaxedAmount) + "</td><td>" + formatCurrency(response.invoices[i].vat) + "</td><td>" + formatCurrency(response.invoices[i].otherTaxes) + "</td><td>" + (response.invoices[i].debit > 0 ? "<span class='red-state'>NO</span>" : "<span class='green-state'>SI</span>") + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-invoices-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-invoices", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInvoices.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetInstallments() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        /**
         * @type {Object}
         * @param {Array} installments
         * @param {String} installments.site
         */
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Sito</th><th>Importo totale [EUR]</th><th>Scadenza</th><th>Pagato</th></tr>";
            for(var i = 0; i < response.installments.length; i++) {
                tempHtml += "<tr><td>" + response.installments[i].site + "</td><td>" + formatCurrency(response.installments[i].totalAmount) + "</td><td>" + response.installments[i].expiry + "</td><td>" + ((response.installments[i].isPaid) ? "SI" : "NO") + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-installments-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-installments", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInstallments.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetEstimates() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "";
            if(response.estimates.length > 0) {
                tempHtml = "<table>";
                tempHtml += "<tr><th>Voce di spesa</th><th>Preventivo [EUR]</th></tr>";
                for(var i = 0; i < response.estimates.length; i++)
                    tempHtml += "<tr><td>" + response.estimates[i].expenditureItem + "</td><td>" + formatCurrency(response.estimates[i].amount) + "</td></tr>";
                tempHtml += "</table>";
            }
            else {
                tempHtml = "<div class='no-results-message'>Non sono presenti preventivi per il gruppo di edifici corrente</div>";
            }
            setInnerHtml(
                document.querySelector("#view-estimates-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-estimates", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getEstimates.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetRepartitionTablesForBuildings() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        var tempHtml,tempHtml2;
        if(xhttp.status === 200) {
            if(response.repartitionTables.length > 0) {
                //populating both the view repartition tables popup and the finalize rate popup
                tempHtml = "<table>";
                tempHtml2 = "";
                tempHtml += "<tr><th>Edificio</th><th>Voce di spesa</th><th>Tipo di ripartizione</th><th>Elimina tabella</th><th>Visualizza tabella</th></tr>";//buildingGroupRepartitionTableId
                for(var i = 0; i < response.repartitionTables.length; i++) {
                    tempHtml += "<tr><td>" + response.repartitionTables[i].building + "</td><td>" + response.repartitionTables[i].expenditureItem + "</td><td>" + response.repartitionTables[i].contributionType + "</td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxDeleteBuildingRepartitionTable(" + response.repartitionTables[i].buildingRepartitionTableId + ");return false;'><i class='fa fa-trash'></i></button></td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxGetBuildingRepartitionTable(" + response.repartitionTables[i].buildingRepartitionTableId + ");return false;'><i class='fa fa-search'></i></button></td></tr>";
                    if(response.repartitionTables[i].contributionType === "A consumo") {
                        tempHtml2 += "<option value='" + response.repartitionTables[i].buildingRepartitionTableId + "'>" + response.repartitionTables[i].building + " - " + response.repartitionTables[i].expenditureItem + "</option>";
                    }
                }
                tempHtml += "</table>";
            }
            else {
                tempHtml = "<div class='no-results-message'>Non sono presenti tabelle di ripartizione per gli edifici richiesti</div>";
            }
            setInnerHtml(
                document.querySelector("#view-repartition-tables-buildings-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-tables-buildings", false);}
            );
            document.getElementById("add-sites-final-rates-selector").innerHTML = tempHtml2;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTablesForBuildings.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetCommonSiteRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Sito</th><th>Contributo</th></tr>";//buildingGroupRepartitionTableId
            for(var i = 0; i < response.repartitionTableContributions.length; i++) {
                tempHtml += "<tr><td>" + response.repartitionTableContributions[i].site + "</td><td>" + response.repartitionTableContributions[i].contribution + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-repartition-table-common-site-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-table-common-site", false);}
            );
            popup("view-repartition-table-common-site", false, false, false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTableForCommonSite.php?repartitionTableId=" + repartitionTableId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetBuildingRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Sito</th><th>Contributo</th></tr>";//buildingGroupRepartitionTableId
            for(var i = 0; i < response.repartitionTableContributions.length; i++) {
                tempHtml += "<tr><td>" + response.repartitionTableContributions[i].site + "</td><td>" + response.repartitionTableContributions[i].contribution + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-repartition-table-building-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-table-building", false);}
            );
            popup("view-repartition-table-building", false, false, false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTableForBuilding.php?repartitionTableId=" + repartitionTableId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetBuildingsGroupRepartitionTable(repartitionTableId) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Sito</th><th>Contributo</th></tr>";
            for(var i = 0; i < response.repartitionTableContributions.length; i++) {
                tempHtml += "<tr><td>" + response.repartitionTableContributions[i].building + "</td><td>" + response.repartitionTableContributions[i].contribution + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-repartition-table-buildings-group-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-table-buildings-group", false);}
            );
            popup("view-repartition-table-buildings-group", false, false, false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTableForBuildingsGroup.php?repartitionTableId=" + repartitionTableId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetRepartitionTablesForBuildingsGroup() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml,tempHtml2;
            if(response.repartitionTables.length > 0) {
                //populating both the view repartition tables popup and the finalize rate popup
                tempHtml = "<table>";
                tempHtml2 = "";
                tempHtml += "<tr><th>Voce di spesa</th><th>Tipo di ripartizione</th><th>Elimina tabella</th><th>Visualizza tabella</th></tr>";//buildingGroupRepartitionTableId
                for(var i = 0; i < response.repartitionTables.length; i++) {
                    tempHtml += "<tr><td>" + response.repartitionTables[i].expenditureItem + "</td><td>" + response.repartitionTables[i].contributionType + "</td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxDeleteBuildingsGroupRepartitionTable(" + response.repartitionTables[i].buildingGroupRepartitionTableId + ");return false;'><i class='fa fa-trash'></i></button></td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxGetBuildingsGroupRepartitionTable(" + response.repartitionTables[i].buildingGroupRepartitionTableId + ");return false;'><i class='fa fa-search'></i></button></td></tr>";
                    if(response.repartitionTables[i].contributionType === "A consumo")
                        tempHtml2 += "<option value='" + response.repartitionTables[i].buildingGroupRepartitionTableId + "'>Gruppo di edifici corrente - " + response.repartitionTables[i].expenditureItem + "</option>";
                }
                tempHtml += "</table>";
            }
            else {
                tempHtml = "<div class='no-results-message'>Non sono presenti tabelle di ripartizione per il gruppo di edifici corrente</div>";
            }
            setInnerHtml(
                document.querySelector("#view-repartition-tables-buildings-group-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-tables-buildings-group", false);}
            );
            document.getElementById("add-buildings-final-rates-selector").innerHTML = tempHtml2;
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTablesForBuildingsGroup.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetRepartitionTablesForCommonSites() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml;
            if(response.repartitionTables.length > 0) {
                tempHtml = "<table>";
                tempHtml += "<tr><th>Sito comune</th><th>Tipo di ripartizione</th><th>Elimina tabella</th><th>Visualizza tabella</th></tr>";//buildingGroupRepartitionTableId
                for(var i = 0; i < response.repartitionTables.length; i++) {
                    tempHtml += "<tr><td>" + response.repartitionTables[i].commonSite + "</td><td>" + response.repartitionTables[i].contributionType + "</td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxDeleteCommonSiteRepartitionTable(" + response.repartitionTables[i].commonSiteRepartitionTableId + ");return false;'><i class='fa fa-trash'></i></button></td>";
                    tempHtml += "<td><button type='button' class='rounded' onclick='ajaxGetCommonSiteRepartitionTable(" + response.repartitionTables[i].commonSiteRepartitionTableId + ");return false;'><i class='fa fa-search'></i></button></td></tr>";
                }
                tempHtml += "</table>";
            }
            else {
                tempHtml = "<div class='no-results-message'>Non sono presenti tabelle di ripartizione per i siti richiesti</div>";
            }
            setInnerHtml(
                document.querySelector("#view-repartition-tables-common-sites-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-repartition-tables-common-sites", false);}
            );
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTablesForCommonSites.php?accountingYearId=" + accountingYearId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function addInvoiceHideReceivingSite() {
    dom.addClass(document.getElementById("add-invoice-receiving-site"), "hidden");
}

function addInvoiceShowReceivingSite() {
    dom.removeClass(document.getElementById("add-invoice-receiving-site"), "hidden");
}

function disableOptionFromSelect(selectId, optionValue) {
    var options = document.getElementById(selectId).getElementsByTagName("option");
    for(var i = 0; i < options.length; i++)
        if(options[i].value == optionValue) {
            options[i].disabled = "disabled";
            options[i].selected = false;
        }
}

function enableOptionFromSelect(selectId, optionValue) {
    var options = document.getElementById(selectId).getElementsByTagName("option");
    for(var i = 0; i < options.length; i++)
        if(options[i].value == optionValue)
            options[i].removeAttribute("disabled");
}

function newRepartitionTableFor(forWhat) {
    var commonSiteInput = document.getElementById("add-repartition-table-common-site");
    var commonSiteSelector = document.getElementById("add-repartition-table-common-site-selector");
    var buildingInput = document.getElementById("add-repartition-table-building");
    var buildingSelector = document.getElementById("add-repartition-table-building-selector");
    var expenditureItemLabel = document.getElementById("add-repartition-table-expenditure-item-label");
    var expenditureItemSelector = document.getElementById("add-repartition-table-expenditure-item");

    switch(forWhat) {
        case "building-group":
            dom.addClass(commonSiteInput, "hidden");
            dom.addClass(buildingInput, "hidden");
            dom.removeClass(expenditureItemLabel, "hidden");
            expenditureItemSelector.required = "required";
            commonSiteSelector.removeAttribute("required");
            buildingSelector.removeAttribute("required");
            enableOptionFromSelect("add-repartition-table-method", "A consumo");
            break;
        case "building":
            dom.addClass(commonSiteInput, "hidden");
            dom.removeClass(buildingInput, "hidden");
            dom.removeClass(expenditureItemLabel, "hidden");
            buildingSelector.required = "required";
            expenditureItemSelector.required = "required";
            commonSiteSelector.removeAttribute("required");
            enableOptionFromSelect("add-repartition-table-method", "A consumo");
            break;
        case "common-site":
            dom.addClass(buildingInput, "hidden");
            dom.removeClass(commonSiteInput, "hidden");
            dom.addClass(expenditureItemLabel, "hidden");
            commonSiteSelector.required = "required";
            expenditureItemSelector.removeAttribute("required");
            buildingSelector.removeAttribute("required");
            disableOptionFromSelect("add-repartition-table-method", "A consumo");
            break;
    }
}

function ajaxCreateRepartitionTable(tableType) {
    var newRepartitionTable = {}, i;
    var selectedBuildingsGroup = parseInt(document.getElementById("accounting-buildings-group").value);
    var selectedBuilding = parseInt(document.getElementById("add-repartition-table-building-selector").value);
    var selectedCommonSite = parseInt(document.getElementById("add-repartition-table-common-site-selector").value);
    var selectedExpenditureItem = parseInt(document.getElementById("add-repartition-table-expenditure-item").value);
    newRepartitionTable.tableType = tableType;
    newRepartitionTable.accountingYear = parseInt(document.getElementById("accounting-year-selector").value);
    newRepartitionTable.repartitionMethod = document.getElementById("add-repartition-table-method").value;
    newRepartitionTable.values = [];
    switch(tableType) {
        case "building":
            newRepartitionTable.selectedBuilding = selectedBuilding;
            newRepartitionTable.selectedExpenditureItem = selectedExpenditureItem;
            for(i = 0; i < sites.length; i++) {
                if(sites[i].buildingId == selectedBuilding) {
                    newRepartitionTable.values.push({
                        id: sites[i].siteId,
                        value: (Math.round(document.getElementById("building-contribution-site-" + sites[i].siteId).value * 1e4) / 1e4)
                    });
                }
            }
            break;
        case "building-group":
            newRepartitionTable.selectedBuildingsGroup = selectedBuildingsGroup;
            newRepartitionTable.selectedExpenditureItem = selectedExpenditureItem;
            for(i = 0; i < buildings.length; i++) {
                if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
                    newRepartitionTable.values.push({
                        id: buildings[i].buildingId,
                        value: (Math.round(document.getElementById("building-group-contribution-building-" + buildings[i].buildingId).value * 1e4) / 1e4)
                    });
                }
            }
            break;
        case "common-site":
            newRepartitionTable.selectedCommonSite = selectedCommonSite;
            for(i = 0; i < sites.length; i++) {
                if(sites[i].buildingsGroupId == selectedBuildingsGroup && (!sites[i].isCommon)) {
                    newRepartitionTable.values.push({
                        id: sites[i].siteId,
                        value: (Math.round(document.getElementById("common-site-contribution-site-" + sites[i].siteId).value * 1e4) / 1e4)
                    });
                }
            }
            break;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Tabella di ripartizione creata con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createRepartitionTable.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newRepartitionTable));
}

function continueRepartitonTableCreation() {
    var tempHtml;
    var repartitionMethod = document.getElementById("add-repartition-table-method").value;
    var expenditureItemSelector = document.getElementById("add-repartition-table-expenditure-item");
    var selectedExpenditureItem = expenditureItemSelector.options[expenditureItemSelector.selectedIndex].innerHTML;
    var selectedExpenditureItemUom;
    var i;
    for(i = 0; i < expenditureItems.length; i++) {
        if(expenditureItems[i].expenditureItemId == expenditureItemSelector.value) {
            selectedExpenditureItemUom = expenditureItems[i].unitOfMeasure;
        }
    }
    var selectedBuildingsGroup = document.getElementById('accounting-buildings-group').value;
    var selectedBuilding = document.getElementById('add-repartition-table-building-selector').value;
    if(document.getElementById("new-rep-table-building-group").getAttribute("data-checked")==="1") {
        if(repartitionMethod !== "A consumo") {
            //I have to list every building in the building group
            tempHtml = "<form onsubmit=\"ajaxCreateRepartitionTable('building-group'); return false;\"><table>";
            tempHtml += "<tr><th>Edificio</th><th>Contributo " + selectedExpenditureItem.toLowerCase() + " [" + repartitionMethod + "]</th></tr>";
            for(i = 0; i < buildings.length; i++) {
                if(buildings[i].buildingsGroup == selectedBuildingsGroup) {
                    tempHtml += "<tr><td>" + buildings[i].name + "</td><td><input type='number' id='building-group-contribution-building-" + buildings[i].buildingId + "'></td></tr>";
                }
            }
            tempHtml += "</table><br><br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-plus' aria-hidden='true'></i> Aggiungi tabella</button></form>";
        }
        else {
            //If the repartition method is "a consumo" i don't need further specification, the rates will be specified before closing the accounting year
            tempHtml = "<div>Utilizzando il metodo di ripartizione a consumo il valore del contributo andrà specificato alla fine dell'anno di esercizio.<br>Tuttavia ai fini della generazione delle rate è necessario inserire un preventivo di consumi per quest'anno di esercizio.<br><br></div>";
            //I have to list every building in the building group
            tempHtml += "<form onsubmit=\"ajaxCreateRepartitionTable('building-group'); return false;\"><table>";
            if(selectedExpenditureItemUom != null)
                tempHtml += "<tr><th>Edificio</th><th>Consumo stimato " + selectedExpenditureItem.toLowerCase() + " [" + selectedExpenditureItemUom + "]</th></tr>";
            else
                tempHtml += "<tr><th>Edificio</th><th>Consumo stimato " + selectedExpenditureItem.toLowerCase() + "</th></tr>";
            for(i = 0; i < buildings.length; i++) {
                if(buildings[i].buildingsGroup == selectedBuildingsGroup)
                    tempHtml += "<tr><td>" + buildings[i].name + "</td><td><input type='number' id='building-group-contribution-building-" + buildings[i].buildingId + "'></td></tr>";
            }
            tempHtml += "</table><br><br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-plus' aria-hidden='true'></i>  Aggiungi tabella</button></form>";

        }
        setInnerHtml(
            document.querySelector("#add-repartition-table-building-group-popup .popup-content div"),
            tempHtml,
            function(){scroll.refreshPopupById("add-repartition-table-building-group", false);}
        );
        popup("add-repartition-table-building-group", false, false, false);
    }
    else {
        if(document.getElementById("new-rep-table-building").getAttribute("data-checked")==="1") {
            //I have to list every site in the building
            if(repartitionMethod !== "A consumo") {
                tempHtml = "<form onsubmit=\"ajaxCreateRepartitionTable('building'); return false;\"><table>";
                tempHtml += "<tr><th>Sito</th><th>Contributo " + selectedExpenditureItem.toLowerCase() + " [" + repartitionMethod + "]</th></tr>";
                for(i = 0; i < sites.length; i++) {
                    if(sites[i].buildingId == selectedBuilding)
                        tempHtml += "<tr><td>" + sites[i].name + "</td><td><input type='number' id='building-contribution-site-" + sites[i].siteId + "'></td></tr>";
                }
                tempHtml += "</table><br><br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-plus' aria-hidden='true'></i>  Aggiungi tabella</button></form>";
            }
            else {
                //If the repartition method is "a consumo" i don't need further specification, the rates will be specified before closing the accounting year
                tempHtml = "<div>Utilizzando il metodo di ripartizione a consumo il valore del contributo andrà specificato alla fine dell'anno di esercizio.<br>Tuttavia ai fini della generazione delle rate è necessario inserire un preventivo di consumi per quest'anno di esercizio.<br><br></div>";

                tempHtml += "<form onsubmit=\"ajaxCreateRepartitionTable('building'); return false;\"><table>";
                if(selectedExpenditureItemUom != null)
                    tempHtml += "<tr><th>Sito</th><th>Consumo stimato " + selectedExpenditureItem.toLowerCase() + " [" + selectedExpenditureItemUom + "]</th></tr>";
                else
                    tempHtml += "<tr><th>Sito</th><th>Consumo stimato " + selectedExpenditureItem.toLowerCase() + "</th></tr>";
                for(i = 0; i < sites.length; i++) {
                    if(sites[i].buildingId == selectedBuilding)
                        tempHtml += "<tr><td>" + sites[i].name + "</td><td><input type='number' id='building-contribution-site-" + sites[i].siteId + "'></td></tr>";
                }
                tempHtml += "</table><br><br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-plus' aria-hidden='true'></i>  Aggiungi tabella</button></form>";
            }
            setInnerHtml(
                document.querySelector("add-repartition-table-building-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("add-repartition-table-building", false);}
            );
            popup("add-repartition-table-building", false, false, false);
        }
        else {
            //I have to list every site in the building group
            tempHtml = "<form onsubmit=\"ajaxCreateRepartitionTable('common-site'); return false;\"><table>";
            tempHtml += "<tr><th>Sito</th><th>Contributo [" + repartitionMethod + "]</th></tr>";
            for(i = 0; i < sites.length; i++) {
                if(sites[i].buildingsGroupId == selectedBuildingsGroup && (!sites[i].isCommon))
                    tempHtml += "<tr><td>" + sites[i].name + "</td><td><input type='number' id='common-site-contribution-site-" + sites[i].siteId + "'></td></tr>";
            }
            tempHtml += "</table><br><br><br><button type='button' onclick='submitForm(this,false);'><i class='fa fa-plus' aria-hidden='true'></i>  Aggiungi tabella</button></form>";
            setInnerHtml(
                document.querySelector("#add-repartition-table-common-site-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("add-repartition-table-common-site", false);}
            );
            popup("add-repartition-table-common-site", false, false, false);
        }
    }
}

function continueAddingSitesFinalRates() {
    var repartitionTableId = document.getElementById("add-sites-final-rates-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<form onsubmit='return false;'><table>";
            tempHtml += "<tr><th>Sito</th><th>Valore stimato</th><th>Lettura effettiva</th></tr>";//buildingGroupRepartitionTableId
            for(var i = 0; i < response.repartitionTableContributions.length; i++)
                tempHtml += "<tr><td>" + response.repartitionTableContributions[i].site + "</td><td>" + response.repartitionTableContributions[i].contribution + "</td><td><input type='number' min='0' id='adding-final-rate-" + response.repartitionTableContributions[i].buildingContributionId + "' value='" + response.repartitionTableContributions[i].contribution + "'></td></tr>";
            tempHtml += "</table>";

            tempHtml += "<br><br><br><button type='button' onclick='submitForm(this,false);'>Registra lettura</button></form>";

            setInnerHtml(
                document.querySelector("#add-sites-final-rates-data-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("add-sites-final-rates-data", false);}
            );
            popup("add-sites-final-rates-data", false, false, false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTableForBuilding.php?repartitionTableId=" + repartitionTableId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function continueAddingBuildingsFinalRates() {
    var repartitionTableId = document.getElementById("add-buildings-final-rates-selector").value;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<form onsubmit='return false;'><table>";
            tempHtml += "<tr><th>Sito</th><th>Valore stimato</th><th>Lettura effettiva</th></tr>";
            for(var i = 0; i < response.repartitionTableContributions.length; i++)
                tempHtml += "<tr><td>" + response.repartitionTableContributions[i].building + "</td><td>" + response.repartitionTableContributions[i].contribution + "</td><td><input type='number' min='0' id='adding-final-rate-" + response.repartitionTableContributions[i].buildingsGroupContributionId + "' value='" + response.repartitionTableContributions[i].contribution + "'></td></tr>";
            tempHtml += "</table>";
            tempHtml += "<br><br><br><button type='button' onclick='submitForm(this,false);'>Registra lettura</button></form>";
            setInnerHtml(
                document.querySelector("#add-buildings-final-rates-data-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("add-buildings-final-rates-data", false);}
            );
            popup("add-buildings-final-rates-data", false, false, false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getRepartitionTableForBuildingsGroup.php?repartitionTableId=" + repartitionTableId, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxCreateAccountingYear() {
    var newAccountingYear = {};
    newAccountingYear.buildingsGroup = parseInt(document.getElementById('create-accounting-year-buildings-group').value);
    newAccountingYear.startDate = document.getElementById('create-accounting-year-start-date').value.split("/").reverse().join("-");
    newAccountingYear.endDate = document.getElementById('create-accounting-year-end-date').value.split("/").reverse().join("-");
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Anno di esercizio creato con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createAccountingYear.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newAccountingYear));
}

function dontFinalizeEstimates() {
    closePopup();
}

function ajaxFinalizeEstimates() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Preventivi finalizzati con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/finalizeEstimates.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("accountingYearId=" + accountingYearId);
}

function ajaxFinalizeInstallments() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Rate finalizzate con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/finalizeInstallments.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("accountingYearId=" + accountingYearId);
}

function ajaxFinalizeAccountingYear() {
    var accountingYearId = document.getElementById("accounting-year-selector").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Consuntivi finalizzati con successo");
            loadAccountingDashboard();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/finalizeAccountingYear.php", true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("accountingYearId=" + accountingYearId);
}

function getVatRateFromVatId(vatId) {
    var i;
    for(i = 0; i < vatRates.length; i++) {
        if(vatRates[i].vatRateId == vatId) {
            return vatRates[i].ratePercentage;
        }
    }
}

function recalculateVatAddedImport() {
    var selectedVatRateId = document.getElementById('add-invoice-vat-rate').value;
    var untaxedImport = document.getElementById('add-invoice-import').value;
    var vatAddedImport;
    var decimalRegexp = /^-?(\d+|\d+\.\d+|\.\d+)([eE][\-+]?\d+)?$/;
    if((selectedVatRateId !== "") && (untaxedImport.match(decimalRegexp))) {
        vatAddedImport = String(parseFloat(untaxedImport + (untaxedImport / 100 * getVatRateFromVatId(selectedVatRateId))).toFixed(2));

    }
    else {
        vatAddedImport = "";
    }
    document.getElementById("add-invoice-import-vat-added").value = vatAddedImport;
}

//GESTIONE ASSET################################################################
function removeAssetNavFilters() {
    document.getElementById("manage-asset-buildings-group").value = "";
    if(isSmallViewport()){ //If I'm using a small viewport I have to remove also the category and subcategory filters
        toggleEntireAssetNavTree(document.getElementById("m-asset-nav-main-selector").parentNode,true);
    }
    manageAssetBuildingsGroupChanged();
    countAssetNavAppliedFilters();
}

function countAssetNavAppliedFilters() {
    var appliedFiltersCounter = 0;
    if(document.getElementById("asset-nav-main-selector")) {
        if(
            isSmallViewport()
            &&
            (
                (document.getElementById("asset-nav-main-selector").getAttribute("data-checked")==="0")
                || (document.getElementById("asset-nav-main-selector").getAttribute("data-indeterminate")==="1")
            )
        )
            appliedFiltersCounter++;
    }

    if(document.getElementById("manage-asset-buildings-group").value !== "")
        appliedFiltersCounter++;
    if(document.getElementById("manage-asset-building").value !== "")
        appliedFiltersCounter++;
    if(document.getElementById("manage-asset-site").value !== "")
        appliedFiltersCounter++;
    if(document.getElementById("manage-asset-floor").value !== "")
        appliedFiltersCounter++;
    var appliedFilterCounterText = "";
    if(appliedFiltersCounter > 0) {
        appliedFilterCounterText = appliedFiltersCounter;
        dom.addClass(document.getElementById("filter-button-assets"), "active");
    }
    else {
        dom.removeClass(document.getElementById("filter-button-assets"), "active");
    }
    setInnerHtml(document.querySelector("#filter-button-assets .filter-counter"), appliedFilterCounterText);
}

function toggleAssetNavCatChildren(categoryId) {
    var treeAreaPrefixes = ["", "m-"];
    for(var i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        if(dom.hasClass(document.getElementById(prefix + "asset-nav-subcats-children-of-cat-" + categoryId), "hidden")) {
            dom.removeClass(document.getElementById(prefix + "asset-nav-subcats-children-of-cat-" + categoryId), "hidden");
            document.getElementById(prefix + "asset-nav-toggle-arrow-cat-" + categoryId).innerHTML = "<i class='fa fa-chevron-up' aria-hidden='true'></i>";
        }
        else {
            dom.addClass(document.getElementById(prefix + "asset-nav-subcats-children-of-cat-" + categoryId), "hidden");
            document.getElementById(prefix + "asset-nav-toggle-arrow-cat-" + categoryId).innerHTML = "<i class='fa fa-chevron-down' aria-hidden='true'></i>";
        }
    }
    scroll.refreshByName("assets-categories-filter");
    scroll.refreshPopupById("assets-filters", false);
}

function toggleAssetNavSubcategoryGroupChildren(subcategoryGroupId) {
    var treeAreaPrefixes = ["", "m-"];
    for(var i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        var subcategoryGroupChildren = document.getElementById(prefix + "asset-nav-subcats-group-" + subcategoryGroupId);
        var subcategoryGroupToggleChildrenArrow = document.getElementById(prefix + "asset-nav-toggle-arrow-subcategory-group-" + subcategoryGroupId);
        if(dom.hasClass(subcategoryGroupChildren, "hidden")) {
            dom.removeClass(subcategoryGroupChildren, "hidden");
            subcategoryGroupToggleChildrenArrow.innerHTML = "<i class='fa fa-chevron-up' aria-hidden='true'></i>";
        }
        else {
            dom.addClass(subcategoryGroupChildren, "hidden");
            subcategoryGroupToggleChildrenArrow.innerHTML = "<i class='fa fa-chevron-down' aria-hidden='true'></i>";
        }
    }
    scroll.refreshByName("assets-categories-filter");
    scroll.refreshPopupById("assets-filters", false);
}

function toggleEntireAssetNavTree(clickedElement,forcedValue) {
    var i, children;
    clickedElement = clickedElement.querySelector(".pseudo-checkbox");
    if(clickedElement.getAttribute("data-indeterminate")=="1") {
        clickedElement.setAttribute("data-indeterminate","0");
    }
    switch(forcedValue) {
        case true:
            clickedElement.setAttribute("data-checked","1");
            break;
        case false:
            clickedElement.setAttribute("data-checked","0");
            break;
        case null:
            clickedElement.setAttribute("data-checked",(clickedElement.getAttribute("data-checked")==="1")?"0":"1");
            break;
    }
    var parentChecked = clickedElement.getAttribute("data-checked");
    var parentIndeterminate = false;

    //Propagating to other sibling checkboxes
    var treeAreaPrefixes = ["", "m-"];
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        document.getElementById(prefix + "asset-nav-main-selector").setAttribute("data-checked",parentChecked);
        document.getElementById(prefix + "asset-nav-main-selector").setAttribute("data-indeterminate",parentIndeterminate);
    }

    //categories
    children = document.querySelectorAll(".asset-nav-cat-checkbox-container .pseudo-checkbox");
    for(i = 0; i < children.length; i++) {
        children[i].setAttribute("data-checked",parentChecked);
        children[i].setAttribute("data-indeterminate",parentIndeterminate);
    }
    //subcategories
    children = document.querySelectorAll(".asset-nav-subcat");
    for(i = 0; i < children.length; i++) {
        children[i].setAttribute("data-checked",parentChecked);
        children[i].setAttribute("data-indeterminate",parentIndeterminate);
    }
    reloadAssetNavFilters();
}

function generateCategoryTreeForAssetNavigation() {
    var treeAreas = [];
    //Each tree area is an object which contains a reference to the DOM and a prefix for the ids of the tree elements
    treeAreas = [
        {
            domElement: (document.getElementById("mobile-asset-category-tree-container")),
            prefix: "m-",
            callback: function(){
                var buttons;
                scroll.refreshPopupById("assets-filters", false);
                buttons = document.querySelectorAll("#mobile-asset-category-tree-container .asset-nav-cat-name");
                for (i = 0; i < buttons.length; i++) {
                    defineTapListenerOnElement(buttons[i],function(event) {
                        var button = dom.findParentTag(event.target,"BUTTON");
                        toggleAssetNavCatChildren(button.getAttribute("data-category-id"));
                    });
                }
                buttons = document.querySelectorAll("#mobile-asset-category-tree-container .asset-nav-subcat-group-name");
                for (i = 0; i < buttons.length; i++) {
                    defineTapListenerOnElement(buttons[i],function(event) {
                        var button = dom.findParentTag(event.target,"BUTTON");
                        toggleAssetNavSubcategoryGroupChildren(button.getAttribute("data-subcategory-group-id"));
                    });
                }
            }
        },
        {
            domElement: (document.getElementById("assets-filter-desktop-container")),
            prefix: "",
            callback: function(){
                scroll.refreshByName("assets-categories-filter");
                var buttons = document.querySelectorAll("#assets-filter-desktop-container .asset-nav-cat-name");
                for (i = 0; i < buttons.length; i++) {
                    defineTapListenerOnElement(buttons[i],function(event) {
                        var button = dom.findParentTag(event.target,"BUTTON");
                        toggleAssetNavCatChildren(button.getAttribute("data-category-id"));
                    });
                }

                buttons = document.querySelectorAll("#assets-filter-desktop-container .asset-nav-subcat-group-name");
                for (i = 0; i < buttons.length; i++) {
                    defineTapListenerOnElement(buttons[i],function(event) {
                        var button = dom.findParentTag(event.target,"BUTTON");
                        toggleAssetNavSubcategoryGroupChildren(button.getAttribute("data-subcategory-group-id"));
                    });
                }
            }
        }
    ];
    for(var m = 0; m < treeAreas.length; m++) {
        var prefix = treeAreas[m].prefix;
        var tempHtml = "<ul>";
        tempHtml+="<li>"+
            "<div class='asset-nav-cat-label main-asset-nav-toggle'>"+
            "<button type='button' class='pseudo-checkbox-button asset-nav-cat-checkbox-container' onclick='toggleEntireAssetNavTree(this,null);'>"+
                "<span class='pseudo-checkbox' data-checked='1' data-indeterminate='0' id='" + prefix + "asset-nav-main-selector'></span>"+
            "</button>"+
            "<button class='asset-nav-cat-name'>"+
                "<span class='inline-category-small'></span>"+
                "<span>Seleziona tutti</span>"+
                "<span class='asset-nav-cat-toggle-arrow'></span>"+
            "</button>"+
            "</div>"+
            "</li>";
        for(var k = 0; k < categoryGroups.length; k++) {
            for(var i = 0; i < categories.length; i++) {
                if(categories[i].categoryGroupId == categoryGroups[k].categoryGroupId && categories[i].deletion==null) {
                    tempHtml += "<li>" +
                        "<div class='asset-nav-cat-label b-" + categories[i].background + "'>" +
                        "<button type='button' class='pseudo-checkbox-button asset-nav-cat-checkbox-container' onclick='assetNavClickCategory(" + categories[i].categoryId + ",this)'>"+
                            "<span class='pseudo-checkbox' data-checked='1' data-indeterminate='0' id='" + prefix + "asset-nav-cat-selector-" + categories[i].categoryId + "'></span>"+
                        "</button>"+
                        "<button class='asset-nav-cat-name' data-category-id='"+ categories[i].categoryId +"'>"+
                            "<span class='inline-category-small'>" + categories[i].icon + "</span>"+
                            "<span>" + categories[i].name + "</span>"+
                            "<span class='asset-nav-cat-toggle-arrow' id='" + prefix + "asset-nav-toggle-arrow-cat-" + categories[i].categoryId + "'>" +
                                "<i class='fa fa-chevron-down' aria-hidden='true'></i>" +
                            "</span>"+
                        "</button>"+
                        "</div>";
                    tempHtml += "<ul class='hidden' id='" + prefix + "asset-nav-subcats-children-of-cat-" + categories[i].categoryId + "'>";
                    var foundSubcategories=0;
                    var groupedSubcategories = [];
                    var notGroupedSubcategories = "";
                    for(var j = 0; j < subcategories.length; j++) {
                        if(subcategories[j].categoryId == categories[i].categoryId && subcategories[j].deletion==null) {
                            if(subcategories[j].subcategoryId!==null){
                                subcategoryGroupAdditionalClass="";
                                if(subcategories[j].subcategoryGroupId!==null) {
                                    subcategoryGroupAdditionalClass = "asset-nav-child-of-subcategory-group-"+subcategories[j].subcategoryGroupId;
                                }
                                var subcategoryHtml = "<li>"+
                                    "<button type='button' class='pseudo-checkbox-button' onclick='assetNavClickSubcategory(" + categories[i].categoryId + "," + subcategories[j].subcategoryGroupId + "," + subcategories[j].subcategoryId + ",this);'>"+
                                    "<span class='pseudo-checkbox asset-nav-child-of-cat-" + categories[i].categoryId + " " + subcategoryGroupAdditionalClass + " asset-nav-subcat' data-checked='1' data-indeterminate='0' id='" + prefix + "asset-nav-subcat-selector-" + subcategories[j].subcategoryId + "'></span>"+
                                    "<span>"+ subcategories[j].name +"</span>"+
                                    "</button>"+
                                    "</li>";
                                if(subcategories[j].subcategoryGroupId!==null) {
                                    if(groupedSubcategories[subcategories[j].subcategoryGroupId]==undefined) {
                                        var subcatGroup = subcategoryGroups.get(subcategories[j].subcategoryGroupId);
                                        groupedSubcategories[subcategories[j].subcategoryGroupId] = {
                                            id:(subcategoryGroups.getAll().length>0?subcategories[j].subcategoryGroupId:null),
                                            name:(subcatGroup?subcatGroup.name:""),
                                            html:""
                                        };
                                    }
                                    groupedSubcategories[subcategories[j].subcategoryGroupId].html += subcategoryHtml;
                                }
                                else {
                                    notGroupedSubcategories += subcategoryHtml;
                                }
                                foundSubcategories++;
                            }
                        }
                    }
                    if(foundSubcategories===0){
                        tempHtml+="<li><span class='no-results-span'>Nessuna sottocategoria</span></li>";
                    }
                    else {
                        groupedSubcategories.forEach(function(groupedSubcategory){
                            tempHtml += "<li>" +
                            "<div class='asset-nav-cat-label b-" + categories[i].background + "'>" +
                            "<button type='button' class='pseudo-checkbox-button asset-nav-cat-checkbox-container' onclick='assetNavClickSubcategoryGroup(" + categories[i].categoryId + ","+ groupedSubcategory.id + ",this)'>"+
                                "<span class='pseudo-checkbox asset-nav-child-of-cat-" + categories[i].categoryId + "' data-checked='1' data-indeterminate='0' id='" + prefix + "asset-nav-subcategory-group-selector-" + groupedSubcategory.id + "'></span>"+
                            "</button>"+
                            "<button class='asset-nav-subcat-group-name' data-subcategory-group-id='"+ groupedSubcategory.id +"'>"+
                                "<span>" + groupedSubcategory.name + "</span>"+
                                "<span class='asset-nav-cat-toggle-arrow' id='" + prefix + "asset-nav-toggle-arrow-subcategory-group-" + groupedSubcategory.id + "'>" +
                                    "<i class='fa fa-chevron-down' aria-hidden='true'></i>" +
                                "</span>"+
                            "</button>"+
                            "</div><ul class='hidden' id='"+prefix+"asset-nav-subcats-group-"+groupedSubcategory.id+"'>" +
                                groupedSubcategory.html +
                            "</ul></li>";
                        });
                        tempHtml += notGroupedSubcategories;
                    }
                    tempHtml += "</ul>";
                    tempHtml += "</li>";
                }
            }
        }
        tempHtml += "</ul>";
        setInnerHtml(
            treeAreas[m].domElement,
            tempHtml,
            treeAreas[m].callback
        );
    }
    reloadAssetNavFilters();
}

function abortAssetInstancesDeletion() {
    closePopup();
}

function ajaxConfirmAssetInstancesDeletion() {
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try{
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                if(response.deletedAssetsCount > 0) {
                    closePopup();
                    if(response.deletedAssetsCount === 1)
                        successToast("Asset eliminato con successo");
                    else
                        successToast(response.deletedAssetsCount + " asset eliminati con successo");
                    reloadAssetNavFilters();
                    changePage("page-assets");
                }
                else
                    setInnerHtml(outcomePopup, "<div class='message error'>Impossibile eliminare gli asset richiesti</div>");
            }
            else {
                setInnerHtml(outcomePopup, "<div class='message error'>" + response.message + "</div>");
            }
        }
        catch(e){
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }

    };
    xhttp.open("POST", BASE_URL + "/ws/asset/delete.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(readyToDeleteAssetInstances));
}

function refreshAssetNavMainSelector() {
    var children = document.querySelectorAll(".asset-nav-cat-checkbox-container .pseudo-checkbox:not(#asset-nav-main-selector):not(#m-asset-nav-main-selector)");
    var i;
    var countCheckedChildren = 0, countIndeterminateChildren = 0;
    for(i = 0; i < children.length; i++) {
        if(children[i].getAttribute("data-checked")==="1") {
            countCheckedChildren++;
        }
        if(children[i].getAttribute("data-indeterminate"==="1")) {
            countIndeterminateChildren++;
        }
    }
    var treeAreaPrefixes = ["", "m-"];
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        var parent = document.getElementById(prefix + "asset-nav-main-selector");
        if(countCheckedChildren < children.length) {
            if(countCheckedChildren > 0 || countIndeterminateChildren > 0) {
                parent.setAttribute("data-checked","0");
                parent.setAttribute("data-indeterminate","1");
            }
            else {
                parent.setAttribute("data-indeterminate","0");
                parent.setAttribute("data-checked","0");
            }
        }
        else {
            parent.setAttribute("data-indeterminate","0");
            parent.setAttribute("data-checked","1");
        }
    }
}

function assetNavClickCategory(categoryId, clickedElement) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var i;
    clickedElement = clickedElement.querySelector(".pseudo-checkbox");
    if(clickedElement.getAttribute("data-indeterminate")==1) {
        clickedElement.setAttribute("data-indeterminate","0");
    }
    clickedElement.setAttribute("data-checked",(clickedElement.getAttribute("data-checked")==="1")?"0":"1");
    var parentChecked = clickedElement.getAttribute("data-checked");
    var parentIndeterminate = "0";
    //Propagating to other sibling checkboxes
    var treeAreaPrefixes = ["", "m-"];
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        document.getElementById(prefix + "asset-nav-cat-selector-" + categoryId).setAttribute("data-checked",parentChecked);
        document.getElementById(prefix + "asset-nav-cat-selector-" + categoryId).setAttribute("data-indeterminate",parentIndeterminate);
    }
    var children = document.querySelectorAll(".asset-nav-child-of-cat-" + categoryId);
    //Propagating to subcategories and subcategory groups
    for(i = 0; i < children.length; i++) {
        children[i].setAttribute("data-checked",parentChecked);
        children[i].setAttribute("data-indeterminate",parentIndeterminate);
    }
    //Propagating to main selector
    refreshAssetNavMainSelector();
    //Refreshing assets
    reloadAssetNavFilters();
}

function assetNavClickSubcategoryGroup(categoryId, subcategoryGroupId, clickedElement) {
    if(!ghostClickController.isOk()){
        return false;
    }
    var i;
    clickedElement = clickedElement.querySelector(".pseudo-checkbox");
    if(clickedElement.getAttribute("data-indeterminate")==1) {
        clickedElement.setAttribute("data-indeterminate","0");
    }
    clickedElement.setAttribute("data-checked",(clickedElement.getAttribute("data-checked")==="1")?"0":"1");
    var parentChecked = clickedElement.getAttribute("data-checked");
    var parentIndeterminate = "0";
    //Propagating to other sibling checkboxes
    var treeAreaPrefixes = ["", "m-"];
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        var prefix = treeAreaPrefixes[i];
        document.getElementById(prefix + "asset-nav-subcategory-group-selector-" + subcategoryGroupId).setAttribute("data-checked",parentChecked);
        document.getElementById(prefix + "asset-nav-subcategory-group-selector-" + subcategoryGroupId).setAttribute("data-indeterminate",parentIndeterminate);
    }
    var children = document.querySelectorAll(".asset-nav-child-of-subcategory-group-" + subcategoryGroupId);
    //Propagating to subcategories
    for(i = 0; i < children.length; i++) {
        children[i].setAttribute("data-checked",parentChecked);
    }
    //Propagating to categories
    var childrenOfCategory = document.querySelectorAll(".asset-nav-child-of-cat-" + categoryId);
    var countCheckedChildren = 0;
    for(i = 0; i < childrenOfCategory.length; i++) {
        if(childrenOfCategory[i].getAttribute("data-checked")==="1") {
            countCheckedChildren++;
        }
    }
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        prefix = treeAreaPrefixes[i];
        var parent = document.getElementById(prefix + "asset-nav-cat-selector-" + categoryId);
        if(countCheckedChildren < childrenOfCategory.length) {
            if(countCheckedChildren > 0) {
                parent.setAttribute("data-checked","0");
                parent.setAttribute("data-indeterminate","1");
            }
            else {
                parent.setAttribute("data-checked","0");
                parent.setAttribute("data-indeterminate","0");
            }
        }
        else {
            parent.setAttribute("data-checked","1");
            parent.setAttribute("data-indeterminate","0");
        }
    }
    //Propagating to main selector
    refreshAssetNavMainSelector();
    //Refreshing assets
    reloadAssetNavFilters();
}

function assetNavClickSubcategory(categoryId, subcategoryGroupId, subcategoryId, clickedElement) {
    if(!ghostClickController.isOk()) {
        return false;
    }
    clickedElement = clickedElement.querySelector(".pseudo-checkbox");
    clickedElement.setAttribute("data-checked",(clickedElement.getAttribute("data-checked")==="1")?"0":"1");
    var parentChecked = clickedElement.getAttribute("data-checked");
    var prefix;
    var parent;
    //Propagating to other sibling checkboxes
    var treeAreaPrefixes = ["", "m-"];
    var i;
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        prefix = treeAreaPrefixes[i];
        document.getElementById(prefix + "asset-nav-subcat-selector-" + subcategoryId).setAttribute("data-checked",parentChecked);
    }
    //Propagating to subcategory group
    if(subcategoryGroupId!=null) {
        var children = document.querySelectorAll(".asset-nav-child-of-subcategory-group-" + subcategoryGroupId);
        var countCheckedChildren = 0;
        for(i = 0; i < children.length; i++) {
            if(children[i].getAttribute("data-checked")==="1") {
                countCheckedChildren++;
            }
        }
        for(i = 0; i < treeAreaPrefixes.length; i++) {
            prefix = treeAreaPrefixes[i];
            parent = document.getElementById(prefix + "asset-nav-subcategory-group-selector-" + subcategoryGroupId);
            if(countCheckedChildren < children.length) {
                if(countCheckedChildren > 0) {
                    parent.setAttribute("data-checked","0");
                    parent.setAttribute("data-indeterminate","1");
                }
                else {
                    parent.setAttribute("data-checked","0");
                    parent.setAttribute("data-indeterminate","0");
                }
            }
            else {
                parent.setAttribute("data-checked","1");
                parent.setAttribute("data-indeterminate","0");
            }
        }
    }
    //Propagating to category
    children = document.querySelectorAll(".asset-nav-child-of-cat-" + categoryId);
    countCheckedChildren = 0;
    for(i = 0; i < children.length; i++) {
        if(children[i].getAttribute("data-checked")==="1") {
            countCheckedChildren++;
        }
    }
    for(i = 0; i < treeAreaPrefixes.length; i++) {
        prefix = treeAreaPrefixes[i];
        parent = document.getElementById(prefix + "asset-nav-cat-selector-" + categoryId);
        if(countCheckedChildren < children.length) {
            if(countCheckedChildren > 0) {
                parent.setAttribute("data-checked","0");
                parent.setAttribute("data-indeterminate","1");
            }
            else {
                parent.setAttribute("data-checked","0");
                parent.setAttribute("data-indeterminate","0");
            }
        }
        else {
            parent.setAttribute("data-checked","1");
            parent.setAttribute("data-indeterminate","0");
        }
    }
    //Propagating to main selector
    refreshAssetNavMainSelector();
    //Refreshing assets
    reloadAssetNavFilters();
}

function redrawAssetsTable(isNewAssetsView,changedSelectNumber) {
    var assetsNumber = 0;
    var qrAssetsNumber = 0;
    var okAssets = [];
    var i,j,assetOk;
    for(i = 0; i < lastQueriedAssets.length; i++) {
        assetOk=false;
        for(j=0; j<lastQueriedAssets[i].subcategories.length; j++){
            var qrValid = false;
            var selectedQrPreference  = document.getElementById("filter-asset-qr").value;
            switch(selectedQrPreference) {
                case "with-qr":
                    if(lastQueriedAssets[i].qrCodeId!==null){
                        qrValid = true;
                    }
                    break;
                case "without-qr":
                    if(lastQueriedAssets[i].qrCodeId===null){
                        qrValid = true;
                    }
                    break;
                case "":
                    qrValid = true;
                    break;
            }
            if((lastQueriedAssets[i].subcategories[j].deletion==null) && lastQueriedAssets[i].subcategories[j].matchesSearch && qrValid){
                if(lastQueriedAssets[i].deletion===undefined||lastQueriedAssets[i].deletion===null){
                    //If the deletion field does not exist or exists and is set to null
                    assetOk=true;
                    okAssets.push(lastQueriedAssets[i]);
                    assetsNumber++;
                    break;
                }
            }
        }
        if(assetOk && lastQueriedAssets[i].qrCodeId != null){
            qrAssetsNumber++;
        }
    }
    var assetsForPage = null;
    switch(changedSelectNumber) {
        case "show-assets-per-page-1":
            assetsForPage = document.getElementById("show-assets-per-page-1").value;
            document.getElementById("show-assets-per-page-2").value=assetsForPage;
            break;
        case "show-assets-per-page-2":
        default:
            assetsForPage = document.getElementById("show-assets-per-page-2").value;
            document.getElementById("show-assets-per-page-1").value=assetsForPage;
            break;
    }
    var selectedPage;
    //if the assets view is new it means I don't have to keep track of the previous pagination
    if(isNewAssetsView) {
        var totalPages = (parseInt(assetsNumber / assetsForPage) + 1);
        if(assetsNumber % assetsForPage === 0 && totalPages > 1) {
            totalPages--;
        }
        document.getElementById("total-assets-page-1").innerHTML = totalPages;
        document.getElementById("total-assets-page-2").innerHTML = totalPages;
        var tempPagesOptions = "";
        for(i = 1; i <= totalPages; i++) {
            tempPagesOptions += "<option value='" + i + "'>" + i + "</option>";
        }
        selectedPage = 1;
    }
    else {
        switch(changedSelectNumber) {
            case "view-assets-page-1":
                selectedPage = document.getElementById("view-assets-page-1").value;
                document.getElementById("view-assets-page-2").value = selectedPage;
                break;
            case "view-assets-page-2":
                selectedPage = document.getElementById("view-assets-page-2").value;
                document.getElementById("view-assets-page-1").value = selectedPage;
                break;
        }
    }
    if(assetsNumber > 0) {
        var tempHtml = "";
        var pagerEnd = selectedPage * assetsForPage;
        var assetOkCounter = 0;
        for(i = ((selectedPage - 1) * assetsForPage); i < assetsNumber && i < pagerEnd; i++) {
            assetOk=false;
            for(j=0; j<okAssets[i].subcategories.length; j++){
                if((okAssets[i].subcategories[j].deletion==null) && okAssets[i].subcategories[j].matchesSearch){
                    assetOk=true;
                    break;
                }
            }
            if(assetOk) {
                tempHtml += renderAssetEntry(okAssets[i]);
            }
        }
        setInnerHtml(
            document.getElementById("assets-list-container"),
            tempHtml,
            function(){
                scroll.refreshByName("assets-list");
            }
        );
    }
    else {
        setInnerHtml(
            document.getElementById("assets-list-container"),
            "<div class='no-results-message'>I filtri applicati non hanno prodotto risultati</div>",
            function(){
                scroll.refreshByName("assets-list");
            }
        );
    }
    if(isNewAssetsView) {
        document.getElementById("view-assets-page-1").innerHTML = tempPagesOptions;
        document.getElementById("view-assets-page-2").innerHTML = tempPagesOptions;
    }
    var resultsCounterText;
    switch(assetsNumber) {
        case 0:
            resultsCounterText = "Nessun risultato";
            break;
        case 1:
            resultsCounterText = "1 risultato";
            break;
        default:
            resultsCounterText = assetsNumber + " risultati, " + qrAssetsNumber + " con QR";
            resultsCounterText += " <i onclick='popup( \"help-ticket-assets\" ,false,false,false);' class='fa fa-qrcode qr-icon' aria-hidden='true'></i>";
            break;
    }
    var pagers = document.querySelectorAll(".assets-list-pager-bar");
    pagers.forEach(function(pager) {
        setInnerHtml(pager.querySelector(".results-count"), resultsCounterText);
    });
}

function reloadAssetNavFilters() {
    var subcategories = document.querySelectorAll(".asset-nav-subcat");
    var subcategoriesList = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].getAttribute("data-checked")==="1") {
            if(subcategories[i].id.indexOf("m-")<0) {
                subcategoriesList += subcategories[i].id.replace("asset-nav-subcat-selector-", "") + ",";
            }
        }
    }
    subcategoriesList = subcategoriesList.slice(0, -1);
    var selectedBuildingsGroup = document.getElementById("manage-asset-buildings-group").value;
    if(selectedBuildingsGroup !== "") {
        selectedBuildingsGroup = parseInt(selectedBuildingsGroup);
    }
    else {
        selectedBuildingsGroup = null;
    }
    var selectedBuilding = document.getElementById("manage-asset-building").value;
    if(selectedBuilding !== "") {
        selectedBuilding = parseInt(selectedBuilding);
    }
    else {
        selectedBuilding = null;
    }
    var selectedSite = document.getElementById("manage-asset-site").value;
    if(selectedSite !== "") {
        selectedSite = parseInt(selectedSite);
    }
    else {
        selectedSite = null;
    }
    var selectedFloor = document.getElementById("manage-asset-floor").value;
    if(selectedFloor !== "") {
        selectedFloor = parseInt(selectedFloor);
    }
    else {
        selectedFloor = null;
    }
    var selectedQrPreference  = document.getElementById("filter-asset-qr").value;
    setInnerHtml(
        document.getElementById("assets-list-container"),
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>",
        function() {
            scroll.refreshByName("assets-list");
        }
    );
    var xhttp = new XMLHttpRequest();
    var timeoutErrorAbort = function() {
        console.log("Timeout/error/abort while fetching assets, loading from preFetchedAssets");
        lastQueriedAssets = [];
        var searchedSubcategories=subcategoriesList.split(",");
        for(var i = 0; i < preFetchedAssets.length; i++) {
            var subcategoryMatch=false;
            var j;
            for(j=0; j<preFetchedAssets[i].subcategories.length;j++) {
                preFetchedAssets[i].subcategories[j].matchesSearch=false;//matchesSearch is used internally to verifiy which subcategory causes the search to match
                for( var n=0; n<searchedSubcategories.length; n++) {
                    if(preFetchedAssets[i].subcategories[j].deletion==null) {
                        if(parseInt(searchedSubcategories[n]) == parseInt(preFetchedAssets[i].subcategories[j].subcategoryId)) {
                            subcategoryMatch = true;
                            preFetchedAssets[i].subcategories[j].matchesSearch=true;
                        }
                    }
                }
            }
            var siteValid=false;
            for(j=0; j<sites.length; j++){
                if(sites[j].siteId==preFetchedAssets[i].site){
                    siteValid=true;
                    break;
                }
            }
            var qrValid = false;
            switch(selectedQrPreference) {
                case "with-qr":
                    if(preFetchedAssets[i].qrCodeId!==null){
                        qrValid = true;
                    }
                    break;
                case "without-qr":
                    if(preFetchedAssets[i].qrCodeId===null){
                        qrValid = true;
                    }
                    break;
                case "":
                    qrValid = true;
                    break;
            }
            if( subcategoryMatch 
                && (preFetchedAssets[i].site == selectedSite || selectedSite == null) 
                && siteValid 
                && ((selectedFloor==null)||(preFetchedAssets[i].floor==selectedFloor)) 
                && qrValid) {
                    lastQueriedAssets.push(preFetchedAssets[i]);
            }
        }
        redrawAssetsTable(true,null);
    };

    xhttp.timeout = 6000; // time in milliseconds
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            lastQueriedAssets = response.assets;
            redrawAssetsTable(true,null);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAssets.php?s=" + subcategoriesList + "&g=" + selectedBuildingsGroup + "&b=" + selectedBuilding + "&i=" + selectedSite + "&c=" + globalFilterByCompany + "&f=" + selectedFloor, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function animate(elem, style, unit, from, to, time, prop) {
    if(!elem) {
        return;
    }
    var start = new Date().getTime(),
        timer = setInterval(function() {
            var step = Math.min(1, (new Date().getTime() - start) / time);
            if(prop) {
                elem[style] = (from + step * (to - from)) + unit;
            } else {
                elem.style[style] = (from + step * (to - from)) + unit;
            }
            if(step === 1) {
                clearInterval(timer);
            }
        }, 25);
    if(prop) {
        elem[style] = from + unit;
    }
    else {
        elem.style[style] = from + unit;
    }
}

function ajaxReloadDataRoomFilters(documentIdToSelect, doNotDrawDocuments) {
    var selectedDocumentType = document.getElementById("data-room-filter-document-type").value;
    var selectedDocumentSubtype = document.getElementById("data-room-filter-document-subtype").value;
    var selectedCompany = globalFilterByCompany;
    document.getElementById("data-room-details-col-content").innerHTML = "<span class='tip'>Clicca su un <strong>documento</strong> per visualizzarne i dettagli.</span>";
    document.getElementById("data-room-main-col-content").innerHTML = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
    scroll.refreshByName("vdr");
    scroll.refreshByName("vdr-details");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            lastQueriedDocuments = response.documents;
            if(!doNotDrawDocuments) {
                reloadDataRoomSearchFilters("none","","data-room-search-dropdown");
            }
            if(documentIdToSelect !== undefined && documentIdToSelect !== null) {
                //getting document index from document id
                for(var i = 0; i < lastQueriedDocuments.length; i++) {
                    if(lastQueriedDocuments[i].documentId == parseInt(documentIdToSelect)) {
                        selectDataRoomDocument(i);
                        var target = document.getElementById("data-room-file-" + documentIdToSelect);
                        animate(document.getElementById("data-room-main-col-content"), "scrollTop", "", 0, target.offsetTop - 200, 2000, true);
                    }
                }
            }
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getDocuments.php?documentType=" + selectedDocumentType + "&documentSubtype=" + selectedDocumentSubtype + "&company=" + globalFilterByCompany + "&inTrash=" + dataRoomShowTrash, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function toggleDataRoomMultipleSelection() {
    var multipleSelectionButton = document.getElementById("data-room-multiple-selection");
    if(dataRoomMultipleSelection) {
        dataRoomMultipleSelection = false;
        dom.removeClass(multipleSelectionButton, "selected");
        setInnerHtml(multipleSelectionButton, "<i class='fa fa-square-o' aria-hidden='true'></i><br>Selezione multipla");
    }
    else {
        dataRoomMultipleSelection = true;
        dom.addClass(multipleSelectionButton, "selected");
        setInnerHtml(multipleSelectionButton, "<i class='fa fa-check-square' aria-hidden='true'></i><br>Selezione multipla");
    }
}

function updateSelectedDocumentsDetails(documentIndex) {
    var i;
    if(selectedDocuments.length === 1) {
        lastQueriedDocuments[documentIndex].documentLastIndex = documentIndex;
        var selectedDocument = lastQueriedDocuments[documentIndex];
        var tempHtml = "<i class='fa fa-file' class='data-room-file-icon-details' aria-hidden='true'></i> <span class='second-col-header'>" + selectedDocument.fileName + "</span><br><br>";
        tempHtml += "<div class='hth'>"+
            "<div class='tr'><div>Tipo di documento</div>"+
            "<div>" + (selectedDocument.documentType == null ? "-" : selectedDocument.documentType) + "</div></div>" +
            "<div class='tr'><div>Sottotipo</div>"+
            "<div>" + (selectedDocument.documentSubtype == null ? "-" : selectedDocument.documentSubtype) + "</div></div>";
        if(selectedDocument.tags.length > 0) {
            tempHtml += "<div class='tr'><div><i class='fa fa-tags' aria-hidden='true'></i> Tag</div><div>";
            for(i = 0; i < selectedDocument.tags.length; i++) {
                tempHtml += "<div class='document-tag' onclick='closePopup();applyDataRoomSearch(\"tag\",\"" + selectedDocument.tags[i] + "\",\"data-room-search-dropdown\")'>" + selectedDocument.tags[i] + "</div> ";
            }
            tempHtml += "</div></div>";
        }
        tempHtml += "<div class='tr'><div>Categoria</div><div>" + (selectedDocument.categoryName == null ? "-" : selectedDocument.categoryName) + "</div></div>" +
            "<div class='tr'><div>Sottocategoria</div><div>" + (selectedDocument.subcategoryName == null ? "-" : selectedDocument.subcategoryName) + "</div></div>" +
            "<div class='tr'><div>Formato</div><div>"+lastQueriedDocuments[documentIndex].fileFormat+"</div></div>" +
            "<div class='tr'><div>ID documento</div><div>" + selectedDocument.documentId + "</div></div>" +
            "<div class='tr'><div>Dimensione</div><div>" + formatFileSize(selectedDocument.fileSize) + "</div></div>" +
            "<div class='tr'><div>Creazione</div><div>" + iso8601.toEuropeanDateTime(selectedDocument.creationDateTime) + "</div></div>";
        if(selectedDocument.isDeleted) {
            tempHtml += "<div class='tr'><div>Eliminazione</div><div>" + iso8601.toEuropeanDateTime(selectedDocument.deletionDateTime) + "</div></div>";
        }
        tempHtml += "</div><br>" +
            "<div class='document-buttons-holder'>" +
            "<button type='button' onclick='renderDocument(" + lastQueriedDocuments[documentIndex].documentId + ",\""+lastQueriedDocuments[documentIndex].fileFormat+"\");' >" +
            "<i class='fa fa-download' aria-hidden='true'></i><br>Scarica documento" +
            "</button> ";

        if(lastQueriedDocuments[documentIndex].editable){
            switch(permissions["EDIT_DOCUMENT"]) {
                case "ACCESSIBLE":
                    tempHtml += "<button type='button' onclick='loadDocumentEditor(" + documentIndex + ");' ><i class='fa fa-pencil' aria-hidden='true'></i><br>Modifica documento</button> ";
                    break;
                case "DISABLED":
                    tempHtml += "<button type='button' disabled='disabled'><i class='fa fa-pencil' aria-hidden='true'></i><br>Modifica documento</button> ";
                    break;
            }
            if(selectedDocument.isDeleted) {
                tempHtml += "<button type='button' onclick='ajaxRecoverDocumentFromTrash(" + lastQueriedDocuments[documentIndex].documentId + ");' ><i class='fa fa-recycle' aria-hidden='true'></i><br>Ripristina</button> ";
                switch(permissions["DATA_ROOM_DELETE_DOCUMENT"]) {
                    case "ACCESSIBLE":
                        tempHtml += "<button type='button' onclick='finalizeDocumentDeletion(" + lastQueriedDocuments[documentIndex].documentId + ");' ><i class='fa fa-times' aria-hidden='true'></i><br>Elimina</button> ";
                        break;
                    case "DISABLED":
                        tempHtml += "<button type='button' disabled='disabled' ><i class='fa fa-times' aria-hidden='true'></i><br>Elimina</button> ";
                        break;
                }
            }
            else {
                switch(permissions["DATA_ROOM_MOVE_TO_TRASH"]) {
                    case "ACCESSIBLE":
                        tempHtml += "<button type='button' onclick='ajaxMoveDocumentToTrash(" + lastQueriedDocuments[documentIndex].documentId + ");' ><i class='fa fa-trash' aria-hidden='true'></i><br>Sposta nel cestino</button> ";
                        break;
                    case "DISABLED":
                        tempHtml += "<button type='button' disabled='disabled'><i class='fa fa-trash' aria-hidden='true'></i><br>Sposta nel cestino</button> ";
                        break;
                }
            }
        }
        tempHtml += "<button type='button' onclick='popup( \"share-document\" ,undefined,undefined,false);' ><i class='fa fa-share-alt' aria-hidden='true'></i><br>Condividi documento</button> ";
        tempHtml += "</div>";
        setInnerHtml(document.getElementById("popup-document-info-container"), tempHtml);
        setInnerHtml(document.getElementById("data-room-details-col-content"), tempHtml, function(){
            scroll.refreshByName("vdr-details");
        });
        if(isSmallViewport()) {
            if(dataRoomMultipleSelection) {
                dom.removeClass(document.getElementById("data-room-view-selected"), "hidden");
            }
            else {
                dom.addClass(document.getElementById("data-room-view-selected"), "hidden");
                popup("document-info", false, false, false);
            }
        }
        else {
            dom.addClass(document.getElementById("data-room-view-selected"), "hidden");
        }
    }
    else {
        if(selectedDocuments.length > 1) {
            var selectedDocumentsIds = [];
            var selectedDocumentsIndexes = [];
            var htmlSelectedDocumentsList = "";
            for(i = 0; i < selectedDocuments.length; i++) {
                selectedDocumentsIds.push(selectedDocuments[i].documentId);
                selectedDocumentsIndexes.push(i);
                htmlSelectedDocumentsList += "<div class='selected-documents-list-entry' onclick='deselectDataRoomDocument(" + selectedDocuments[i].documentLastIndex + ");'><i class='fa fa-file-pdf-o' aria-hidden='true'></i> " + selectedDocuments[i].fileName + "<br><span class='file-size-detail'>" + formatFileSize(selectedDocuments[i].fileSize) + "</span></div>";
            }
            tempHtml = "<span class='tip'><i class='fa fa-archive' aria-hidden='true'></i>  <strong>" + selectedDocuments.length + " documenti selezionati</strong></span>";
            tempHtml += "<div class='selected-documents-container'>";
            tempHtml += htmlSelectedDocumentsList;

            tempHtml += "</div><div class='document-buttons-holder'><button type='button' onclick='ajaxDownloadMultipleDocumentsFromDataRoom(\"" + (selectedDocumentsIds.join(",")) + "\");' ><i class='fa fa-download' aria-hidden='true'></i><br>Scarica documenti</button> ";
            tempHtml += "<button type='button' onclick='ajaxShareMultipleDocumentsFromDataRoom();' ><i class='fa fa-share-alt' aria-hidden='true'></i><br>Condividi documenti</button> ";
            tempHtml += "</div>";
            setInnerHtml(document.getElementById("data-room-details-col-content"), tempHtml, function(){
                scroll.refreshByName("vdr-details");
            });
            setInnerHtml(document.getElementById("popup-document-info-container"), tempHtml);
            if(isSmallViewport()) {
                dom.removeClass(document.getElementById("data-room-view-selected"), "hidden");
            }
            else {
                dom.addClass(document.getElementById("data-room-view-selected"), "hidden");
            }
        }
        //no document selected
        else {
            tempHtml = "<span class='tip'>Clicca su un <strong>documento</strong> per visualizzarne i dettagli.</span>";
            setInnerHtml(document.getElementById("data-room-details-col-content"), tempHtml, function(){
                scroll.refreshByName("vdr-details");
            });
            setInnerHtml(document.getElementById("popup-document-info-container"), tempHtml);
            dom.addClass(document.getElementById("data-room-view-selected"), "hidden");
        }
    }
}

function formatFileSize(sizeInBytes) {
    if(sizeInBytes) {
        if(sizeInBytes < 1024) {
            return sizeInBytes + " B";
        }
        if(sizeInBytes < 1024 * 1024) {
            return parseInt(sizeInBytes / 1024) + " KB";
        }
        return parseFloat(sizeInBytes / (1024 * 1024)).toFixed(1) + " MB";
    }
    return "-";
}

function deselectDataRoomDocument(documentIndex) {
    dom.removeClass(document.getElementById("data-room-file-" + lastQueriedDocuments[documentIndex].documentId), "selected");
    var index = selectedDocuments.indexOf(lastQueriedDocuments[documentIndex]);
    if(index !== -1) {
        selectedDocuments.splice(index, 1);
    }
    updateSelectedDocumentsDetails(documentIndex);
}

function calculateLicenseCost() {
    createSubcategoryGraphicalMultiSelector(
        "calculate-license-subcategories",
        "calculate-licenses",
        false,{},{},{}
    );
    popup("calculate-license-cost", false, false, false);
    calculateLicenseCostImport();
}

function arrayUnique(arr) {
    var a = [];
    for (var i=0, l=arr.length; i<l; i++) {
        if(a.indexOf(arr[i]) === -1 && arr[i] !== '') {
            a.push(arr[i]);
        }
    }
    return a;
}

function calculateLicenseCostImport(){
    var assetRanges=[
        {range:1,from:0,to:10,price:75},
        {range:2,from:11,to:30,price:175},
        {range:3,from:31,to:60,price:225},
        {range:4,from:61,to:100,price:250},
        {range:5,from:101,to:150,price:350},
        {range:6,from:151,to:250,price:500},
        {range:7,from:251,to:500,price:875},
        {range:8,from:501,to:750,price:1100},
        {range:9,from:751,to:1000,price:1350},
        {range:10,from:1001,to:2500,unit:1600},
        {range:11,from:2501,to:15000,unit:2000}
    ];
    var assetNumber=document.getElementById("calculate-license-asset-number").value;
    setInnerHtml(document.getElementById("calculate-license-asset-count"),assetNumber);
    var appliedRange;
    assetRanges.forEach(function(range){
        if(assetNumber<=range.to&&assetNumber>=range.from){
            appliedRange=range;
        }
    });
    setInnerHtml(document.getElementById("calculate-license-asset-range"),"fascia "+appliedRange.range);
    setInnerHtml(document.getElementById("calculate-license-asset-cost"),"€"+appliedRange.price.toFixed(2).replace(".",","));
    var subcategoriesNumber=0;
    var categories=[];
    var subcategoriesCheckboxes = document.querySelectorAll(".calculate-licenses-contract-subcats");
    subcategoriesCheckboxes.forEach(function(subcategory){
        if(subcategory.getAttribute("data-checked")==="1"){
            subcategoriesNumber++;
            categories.push(subcategory.getAttribute("data-category"));
        }
    });
    categories=arrayUnique(categories);
    setInnerHtml(document.getElementById("calculate-license-category-count"),""+categories.length);
    setInnerHtml(document.getElementById("calculate-license-category-cost"),"€"+(categories.length*50).toFixed(2).replace(".",","));
    setInnerHtml(document.getElementById("calculate-license-subcategory-count"),""+subcategoriesNumber);
    setInnerHtml(document.getElementById("calculate-license-subcategory-cost"),"€"+(subcategoriesNumber*35).toFixed(2).replace(".",","));
    setInnerHtml(document.getElementById("calculate-license-result"),"€"+(parseInt(1200)+(categories.length*50)+(subcategoriesNumber*35)+appliedRange.price).toFixed(2).replace(".",","));
}

function selectDataRoomDocument(documentIndex, event) {
    var deselecting = (selectedDocuments.indexOf(lastQueriedDocuments[documentIndex]) != -1);
    var startFileIndex;
    var endFileIndex;
    var i, index;
    if(shiftPressed) {
        if(selectedDocuments.length > 0) {
            lastSelectedDocument = selectedDocuments.pop();
            selectedDocuments.push(lastSelectedDocument);
            startFileIndex = document.getElementById("data-room-file-" + lastSelectedDocument.documentId).getAttribute("data-file-index");
            if(startFileIndex > documentIndex) {
                endFileIndex = startFileIndex;
                startFileIndex = documentIndex;
            }
            else {
                endFileIndex = documentIndex;
            }
            shiftPressedOk = true;
        }
        else {
            shiftPressedOk = false;
        }
        if(event) {
            preventEvent(event);
        }
    }
    if(!ctrlPressed && !shiftPressed && !dataRoomMultipleSelection) {
        for(i = 0; i < selectedDocuments.length; i++) {
            dom.removeClass(document.getElementById("data-room-file-" + selectedDocuments[i].documentId), "selected");
        }
        selectedDocuments = [];
    }
    if (deselecting) {
        if (ctrlPressed || dataRoomMultipleSelection) {
            dom.removeClass(document.getElementById("data-room-file-" + lastQueriedDocuments[documentIndex].documentId), "selected");
            index = selectedDocuments.indexOf(lastQueriedDocuments[documentIndex]);
            if (index != -1) {
                selectedDocuments.splice(index, 1);
            }
        }
        if (shiftPressed && shiftPressedOk) {
            for (i = startFileIndex; i <= endFileIndex; i++) {
                dom.removeClass(document.getElementById("data-room-file-" + lastQueriedDocuments[i].documentId), "selected");
                index = selectedDocuments.indexOf(lastQueriedDocuments[i]);
                if (index != -1) {
                    selectedDocuments.splice(index, 1);
                }
            }
        }
    }
    else {
        if (shiftPressed && shiftPressedOk) {
            for (i = startFileIndex; i <= endFileIndex; i++) {
                if (!dom.hasClass(document.getElementById("data-room-file-" + lastQueriedDocuments[i].documentId), "selected")) {
                    dom.addClass(document.getElementById("data-room-file-" + lastQueriedDocuments[i].documentId), "selected");
                    lastQueriedDocuments[i].documentLastIndex = i;
                    selectedDocuments.push(lastQueriedDocuments[i]);
                }
            }
        }
        else {
            lastQueriedDocuments[documentIndex].documentLastIndex = documentIndex;
            selectedDocuments.push(lastQueriedDocuments[documentIndex]);
            dom.addClass(document.getElementById("data-room-file-" + lastQueriedDocuments[documentIndex].documentId), "selected");
            if ((lastSelectedDocumentId !== null) && (!ctrlPressed) && (!dataRoomMultipleSelection) && (lastQueriedDocuments[documentIndex].documentId != lastSelectedDocumentId)) {
                dom.removeClass(document.getElementById("data-room-file-" + lastSelectedDocumentId), "selected");
            }
        }
    }
    lastSelectedDocumentId = lastQueriedDocuments[documentIndex].documentId;
    updateSelectedDocumentsDetails(documentIndex);
}

function loadDocumentEditor(documentIndex) {
    document.getElementById("edit-document-name").value = lastQueriedDocuments[documentIndex].fileName;
    document.getElementById("edit-document-document-id").value = lastQueriedDocuments[documentIndex].documentId;
    document.getElementById("edit-document-type").value = lastQueriedDocuments[documentIndex].documentTypeId;
    editDocumentTypeChanged();
    document.getElementById("edit-document-subtype").value = ((lastQueriedDocuments[documentIndex].documentSubtypeId === null) ? "" : lastQueriedDocuments[documentIndex].documentSubtypeId);
    document.getElementById("edit-document-company").value = lastQueriedDocuments[documentIndex].documentCompanyId;
    document.getElementById("edit-document-category").value = ((lastQueriedDocuments[documentIndex].categoryId === null) ? "" : lastQueriedDocuments[documentIndex].categoryId);
    populateDataRoomEditSubcategories();
    document.getElementById("edit-document-subcategory").value = ((lastQueriedDocuments[documentIndex].subcategoryId === null) ? "" : lastQueriedDocuments[documentIndex].subcategoryId);
    editDocumentTagsTaggle.removeAll();
    editDocumentTagsTaggle.add(lastQueriedDocuments[documentIndex].tags);
    popup("edit-document", false, false, false);
}

var downloadFailureCounter=0;
function downloadRemoteFile(fileEntry, uri, successCallback) {
    var fileTransfer = new FileTransfer();
    var fileURL = fileEntry.toURL();
    fileTransfer.onprogress = function(progressEvent) {
        if(progressEvent.lengthComputable) {
            successToast("Caricamento del documento... " + ((progressEvent.loaded * 100 / progressEvent.total).toFixed(2)) + "%", 8000);
        }
    };
    fileTransfer.download(
        uri,
        fileURL,
        function(entry) {
            downloadFailureCounter=0;
            console.log("download complete: " + entry.toURL());
            successCallback(entry.toURL());
        },
        function(error) {
            switch(error.code){
                case 1: errorPopup("Errore durante il trasferimento del file: file non trovato<br>" +
                    "<strong>Sorgente: </strong>"+error.source+"<br>" +
                    "<strong>Destinazione: </strong>"+error.target+"<br>"+
                    "<strong>Codice errore: </strong> FILE_NOT_FOUND_ERR");
                    break;
                case 2: errorPopup("Errore durante il trasferimento del file: indirizzo del file non valido<br>" +
                    "<strong>Sorgente: </strong>"+error.source+"<br>" +
                    "<strong>Destinazione: </strong>"+error.target+"<br>"+
                    "<strong>Codice errore: </strong> INVALID_URL_ERR");
                    break;
                case 3:
                    if(downloadFailureCounter<3){
                        downloadFailureCounter++;
                        setTimeout(function(){downloadRemoteFile(fileEntry, uri, successCallback);},1000);
                    }
                    else{
                        errorPopup("Errore durante il trasferimento del file: Internet non disponibile<br>" +
                            "<strong>Sorgente: </strong>"+error.source+"<br>" +
                            "<strong>Destinazione: </strong>"+error.target+"<br>"+
                            "<strong>Codice errore: </strong> CONNECTION_ERR");
                        downloadFailureCounter=0;
                    }
                    break;
                case 4: errorPopup("Errore durante il trasferimento del file: connessione annullata<br>" +
                    "<strong>Sorgente: </strong>"+error.source+"<br>" +
                    "<strong>Destinazione: </strong>"+error.target+"<br>"+
                    "<strong>Codice errore: </strong> ABORT_ERR");
                    break;
                case 5: errorPopup("Errore durante il trasferimento del file: NOT_MODIFIED_ERR<br>" +
                    "<strong>Sorgente: </strong>"+error.source+"<br>" +
                    "<strong>Destinazione: </strong>"+error.target+"<br>"+
                    "<strong>Codice errore: </strong> NOT_MODIFIED_ERR");
                    break;
                default:
                    errorPopup("Errore durante il trasferimento del file: <br>" +
                       JSON.stringify(error)
                    );
            }
        },
        null, // or, pass false
        {
            //headers: {
            //    "Authorization": "Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA=="
            //}
        }
    );
}

function ajaxGenerateContractAccessOtt(contractId,callback){
    var requestData={};
    requestData.contractId=contractId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                callback(response.ott);
            }
            else {
                errorPopup("Errore durante l'autenticazione: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione: (Codice HTTP " + xhttp.status + ")");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/generateContractAccessOtt.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxGenerateDocumentAccessOtt(documentId,callback){
    var requestData={};
    requestData.documentId=documentId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                callback(response.ott);
            }
            else {
                errorPopup("Errore durante l'autenticazione: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione: (Codice HTTP " + xhttp.status + ")");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/generateDocumentAccessOtt.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function ajaxGenerateInterventionAccessOtt(interventionId,callback) {
    var requestData={};
    requestData.interventionId=interventionId;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                callback(response.ott);
            }
            else {
                errorPopup("Errore durante l'autenticazione: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'autenticazione: (Codice HTTP " + xhttp.status + ")");
            console.error(e);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/generateInterventionAccessOtt.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function renderDocument(documentId,format){
    var newWindow = null;
    if(Capacitor.platform=='web') {
        newWindow=window.open();
    }
    ajaxGenerateDocumentAccessOtt(documentId,function(ott){
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/renderDocument.php?documentId=" + documentId + "&ott=" + ott, "Claster-VDR-documento-" + documentId + "."+format, false, newWindow, format);
    });
}

var cordovaDownloadInProgress=false;
function ajaxOpenRemoteDocument(remotePdfUrl, exportFileName, openInBrowser, newWindowReference, fileType) {
    if(Capacitor.platform!=='web') {
        if(cordovaDownloadInProgress){
            successToast("Attendere termine del download in corso...");
            return;
        }
        else {
            cordovaDownloadInProgress=true;
        }
        var onErrorCreateFile = function(error) {
            cordovaDownloadInProgress=false;
            errorPopup("Errore durante la scrittura del file in memoria locale " + JSON.stringify(error));
        };
        var onErrorCreateFs = function(error) {
            cordovaDownloadInProgress=false;
            errorPopup("Errore durante l'acquisizione del file system locale " + JSON.stringify(error));
        };
        var mimeType = "application/pdf";
        switch(fileType) {
            case "txt":
                mimeType = 'text/plain';
                break;
            case "doc":
                mimeType = 'application/msword';
                break;
            case "docx":
                mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                break;
            case "xls":
                mimeType = 'application/vnd.ms-excel';
                break;
            case "xlsx":
                mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case "odt":
                mimeType = 'application/vnd.oasis.opendocument.text';
                break;
            case "ods":
                mimeType = 'application/vnd.oasis.opendocument.spreadsheet';
                break;
            case "pdf":
                mimeType = 'application/pdf';
                break;
            case "jpg":
            case "jpeg":
                mimeType = 'image/jpeg';
                break;
            case "png":
                mimeType = 'image/png';
                break;
        }
        window.requestFileSystem(
            window.TEMPORARY,
            10 * 1024 * 1024, /*10MiB*/
            function(fileSystem) {
                fileSystem.root.getFile(
                    exportFileName,
                    {
                        create: true,
                        exclusive: false
                    },
                    function(fileEntry) {
                        downloadRemoteFile(fileEntry, remotePdfUrl,
                            function(entryUrl) {
                                cordova.plugins.fileOpener2.open(
                                    entryUrl,
                                    mimeType,
                                    {
                                        error: function() {
                                            cordovaDownloadInProgress=false;
                                            errorPopup("Errore durante l'apertura del documento");
                                        },
                                        success: function() {
                                            cordovaDownloadInProgress=false;
                                        }
                                    }
                                );
                            }
                        );
                    },
                    onErrorCreateFile
                );
            },
            onErrorCreateFs
        );
    }
    else {
        if(openInBrowser && fileType=="pdf") {
            popup("document-viewer", false, false, false);
            document.getElementById("document-viewer-box").innerHTML = "<object width='100%' height='100%' type='application/pdf' data='" + remotePdfUrl + "'></object>";
        }
        else {
            if(newWindowReference!==null) {
                newWindowReference.document.write("" +
                    "<!doctype html>" +
                    "<html>" +
                    "<head>" +
                    "<title>Download...</title>" +
                    "<link rel='stylesheet' href='css/downloadPage.css?v=1.0'>" +
                    "</head>" +
                    "<body>" +
                    "<h1>Il download verrà avviato a breve...</h1>" +
                    "<button onclick='window.close()'>Chiudi scheda</button>" +
                    "</body>" +
                    "</html>"
                );
                newWindowReference.location.replace(remotePdfUrl);
            }
            else {
                var win = window.open(remotePdfUrl, "_blank");
            }
        }
    }
}

function ajaxRenderPublicSharedDocument(documentId, documentToken) {
    ajaxOpenRemoteDocument(BASE_URL + "/ws/renderPublicSharedDocument.php?documentId=" + documentId + "&sharedDocumentToken=" + documentToken, "Claster-VDR-documento-" + documentId + ".pdf", true, null, "pdf");
}

function ajaxDownloadInterventionYearReport() {
    ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/downloadInterventionYearReportPDF.php?year=" + lastYear + "&companyId=" + globalFilterByCompany + "&authToken=" + currentAuthToken, "Report-annuale-intervento.pdf", false, null, "pdf");
}

function ajaxDownloadMultipleDocumentsFromDataRoom(documentIds) {
    var win = window.open(DIRECT_DOWNLOAD_URL + "/ws/downloadDataRoomMultipleDocuments.php?documentIds=" + documentIds + "&authToken=" + currentAuthToken, "_blank");
}

function ajaxMoveDocumentToTrash(documentId) {
    var documentsToDelete = [];
    documentsToDelete.push(parseInt(documentId));
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il documento è stato spostato nel cestino");
                ajaxReloadDataRoomFilters();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/moveDocumentsToTrash.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(documentsToDelete));
}

function ajaxRecoverDocumentFromTrash(documentId) {
    var documentsToRecover = [];
    documentsToRecover.push(parseInt(documentId));
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il documento è stato ripristinato");
                ajaxReloadDataRoomFilters();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/recoverDocumentsFromTrash.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(documentsToRecover));
}

function openDataRoomTrash(doNotDrawDocuments) {
    document.querySelector("#page-data-room .page-main-title").innerHTML = "Cestino";
    dom.addClass(document.getElementById("enter-data-room-trash-button"), "hidden");
    dom.removeClass(document.getElementById("exit-data-room-trash-button"), "hidden");
    dom.removeClass(document.getElementById("action-button-close-vdr-trash"), "hidden");
    dom.addClass(document.getElementById("export-fire-log-book-button"), "hidden");
    dom.addClass(document.getElementById("upload-document-button"), "hidden");
    dom.addClass(document.getElementById("export-asset-field-for-subcategory-report-button"), "hidden");
    dom.addClass(document.getElementById("export-customer-satisfaction-report-button"), "hidden");
    dataRoomShowTrash = true;
    ajaxReloadDataRoomFilters(null,doNotDrawDocuments);
}

function closeDataRoomTrash(doNotDrawDocuments) {
    document.querySelector("#page-data-room .page-main-title").innerHTML = "Virtual Data Room";
    dom.addClass(document.getElementById("exit-data-room-trash-button"), "hidden");
    dom.addClass(document.getElementById("action-button-close-vdr-trash"), "hidden");
    dom.removeClass(document.getElementById("enter-data-room-trash-button"), "hidden");
    dom.removeClass(document.getElementById("upload-document-button"), "hidden");
    dom.removeClass(document.getElementById("export-fire-log-book-button"), "hidden");
    dom.removeClass(document.getElementById("export-asset-field-for-subcategory-report-button"), "hidden");
    dom.removeClass(document.getElementById("export-customer-satisfaction-report-button"), "hidden");
    dataRoomShowTrash = false;
    ajaxReloadDataRoomFilters(null,doNotDrawDocuments);
}

function downloadInterventionsReport() {
    var win = window.open(DIRECT_DOWNLOAD_URL + "/ws/downloadInterventionsReport.php?companyId=" + globalFilterByCompany + "&authToken=" + currentAuthToken);
}

function downloadInterventionTechnicalConsumptivePDF() {
    successToast("Apertura consuntivo tecnico in corso...");
    var interventionId = selectedIntervention;
    var newWindow = null;
    if(Capacitor.platform=='web') {
        newWindow=window.open();
    }
    ajaxGenerateInterventionAccessOtt(interventionId,function(ott) {
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/downloadInterventionTechnicalConsumptivePDF.php?intervention=" + interventionId + "&ott=" + ott, "Consuntivo-tecnico-intervento.pdf", false, newWindow, "pdf");
    });
}

function downloadInterventionReportPDF() {
    successToast("Apertura scheda di lavoro in corso...");
    var interventionId = selectedIntervention;
    var newWindow = null;
    if(Capacitor.platform=='web') {
        newWindow=window.open();
    }
    ajaxGenerateInterventionAccessOtt(interventionId,function(ott) {
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/downloadInterventionReportPDF.php?intervention=" + interventionId + "&ott=" + ott, "Rapportino-intervento.pdf", false, newWindow, "pdf");
    });
}

function downloadCompiledInterventionReportPDF() {
    successToast("Apertura rapportino compilato in corso...");
    var interventionId = selectedIntervention;
    var newWindow = null;
    if(Capacitor.platform=='web') {
        newWindow=window.open();
    }
    ajaxGenerateInterventionAccessOtt(interventionId,function(ott) {
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/downloadCompiledInterventionReportPDF.php?intervention=" + interventionId + "&ott=" + ott, "Rapportino-intervento-compilato.pdf", false, newWindow, "pdf");
    });
}

function sendInterventionMailToSiteManager() {
    var interventionToNotify = {};
    interventionToNotify.interventionId = parseInt(selectedIntervention);
    closePopup();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il messaggio è stato inviato");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendInterventionMailToSiteManager.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToNotify));
}

function sendInterventionCustomMail() {
    var interventionToNotify = {};
    interventionToNotify.interventionId = parseInt(selectedIntervention);
    interventionToNotify.mailTo = document.getElementById("intervention-custom-notification-to").value;
    interventionToNotify.mailCC = document.getElementById("intervention-custom-notification-cc").value;
    if(interventionToNotify.mailCC.trim() === "") {
        delete interventionToNotify.mailCC;
    }
    closePopup();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il messaggio è stato inviato");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendInterventionCustomMail.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToNotify));
}

function sendInterventionMailToExecutor() {
    var interventionToNotify = {};
    interventionToNotify.interventionId = parseInt(selectedIntervention);
    closePopup();
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Il messaggio è stato inviato");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendInterventionMailToExecutor.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionToNotify));
}

function downloadConsumptivePdf() {
    var selectedAccountingYear = document.getElementById("accounting-year-selector").value;
    ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/generateConsumptivePdf.php?accountingYearId=" + selectedAccountingYear + "&authToken=" + currentAuthToken, "Consuntivo.pdf", true, null, "pdf");
}

function downloadContractSpec() {
    var subcategories = document.querySelectorAll(".exp-contract-contract-subcats");
    var subcategoriesList = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].getAttribute("data-checked")==="1") {
            subcategoriesList += subcategories[i].id.replace("exp-contract-subcat-selector-", "") + ",";
        }
    }
    subcategoriesList = subcategoriesList.slice(0, -1);
    var contractId = lastOpenedContract.contractId;
    var newWindow = (Capacitor.platform!='web'?window.open():null);
    ajaxGenerateContractAccessOtt(contractId,function(ott){
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/downloadContractSpecPDF.php?ott=" + ott + "&c=" + contractId + "&s=" + subcategoriesList, "Capitolato-contratto-" + contractId + ".pdf", false, newWindow, "pdf");
    });
}

function viewEmployeeDetails(employeeId) {
    for(var i = 0; i < employees.length; i++) {
        if(employees[i].employeeId == employeeId) {
            var tempHtml = "<table>";
            tempHtml += "<tr><th>Nome</th><td>" + employees[i].name + "</td></tr>";
            tempHtml += "<tr><th>Cognome</th><td>" + employees[i].surname + "</td></tr>";
            tempHtml += "<tr><th>Data di nascita</th><td>" + iso8601.toEuropeanDate(employees[i].birthday,"-") + "</td></tr>";
            tempHtml += "<tr><th>Indirizzo E-Mail</th><td>" + ((employees[i].eMailAddress == null) ? "-" : ("<a href='mailto:" + employees[i].eMailAddress + "'>" + employees[i].eMailAddress + "</a>")) + "</td></tr>";
            tempHtml += "<tr><th>Telefono</th><td>" + ((employees[i].telephoneNumber == null) ? "-" : employees[i].telephoneNumber) + "</td></tr>";
            tempHtml += "<tr><th><i class='fa fa-key' aria-hidden='true'></i> Ha un account?</th><td>" + ((employees[i].hasAccount) ? "si" : "no") + "</td></tr>";
            tempHtml += "</table>";
            setInnerHtml(
                document.querySelector("#view-employee-popup .popup-content div"),
                tempHtml,
                function(){scroll.refreshPopupById("view-employee", false);}
            );
            break;
        }
    }
    popup("view-employee", false, false, false);
}


function loadDataIntoFormEditCompany(companyObject) {
    if(companyObject.publicVersion === companyObject.companyId) {
        dom.removeClass(document.getElementById("edit-public-company"),"hidden");
    }
    else {
        dom.addClass(document.getElementById("edit-public-company"),"hidden");
    }

    document.getElementById("edit-company-fiscal-name").value = companyObject.companyName == null ? "" : companyObject.companyName;
    document.getElementById("edit-company-mail").value = companyObject.eMailAddress == null ? "" : companyObject.eMailAddress;
    document.getElementById("edit-company-phone").value = companyObject.telephoneNumber == null ? "" : companyObject.telephoneNumber;
    document.getElementById("edit-company-vat-number").value = companyObject.vatNumber == null ? "" : companyObject.vatNumber;
    document.getElementById("edit-company-fiscal-code").value = companyObject.fiscalCode == null ? "" : companyObject.fiscalCode;

    //Company documents header information
    document.getElementById("edit-company-header-line-1").value = companyObject.headerLine1;
    document.getElementById("edit-company-header-line-2").value = companyObject.headerLine2;
    document.getElementById("edit-company-header-line-3").value = companyObject.headerLine3;
    document.getElementById("edit-company-header-line-4").value = companyObject.headerLine4;
    document.getElementById("edit-company-header-line-5").value = companyObject.headerLine5;
    document.getElementById("edit-company-header-line-6").value = companyObject.headerLine6;
    document.getElementById("edit-company-header-line-7").value = companyObject.headerLine7;
    document.getElementById("edit-company-header-logo-id").value = companyObject.headerImage;

    //Company addresses
    var addressesZoneHtml = "";
    document.getElementById("company-addresses-count").value = companyObject.addresses.length;
    var j;
    for(j = 0; j < companyObject.addresses.length; j++) {
        addressesZoneHtml += "<button type='button' onclick='showCompanyAddressToEdit(" + j + ");' id='to-edit-address-tab-button-" + j + "' class='to-edit-address-tab-button" + (j == 0 ? " selected" : "") + "'>" + companyObject.addresses[j].addressName + "</button> ";
    }
    var addCompanyAddressAdditionalClasses="";
    if(companyObject.addresses.length===0){
        addCompanyAddressAdditionalClasses="no-elements";
    }
    addressesZoneHtml += "<button type='button' onclick='addCompanyAddress();' class='add-address-tab-button "+addCompanyAddressAdditionalClasses+"'><i class='fa fa-plus' aria-hidden='true'></i> Aggiungi sede</button>";
    for(j = 0; j < companyObject.addresses.length; j++) {
        addressesZoneHtml += "<div class='company-to-edit-address" + (j === 0 ? "" : " hidden") + "' id='company-to-edit-address-" + j + "'>";
        addressesZoneHtml += "<div class='inline-form-component'>Indirizzo <span class='optional'>(opzionale)</span><br><input type='text' maxlength='50' id='edit-company-street-name-" + j + "' value='" + (companyObject.addresses[j].street == null ? "" : companyObject.addresses[j].street) + "'></div>";
        addressesZoneHtml += "<div class='inline-form-component'>N°civico <span class='optional'>(opzionale)</span><br><input type='text' maxlength='10' id='edit-company-street-number-" + j + "' value='" + (companyObject.addresses[j].streetNumber == null ? "" : companyObject.addresses[j].streetNumber) + "'></div><br><br>";
        addressesZoneHtml += "<div class='inline-form-component'>Città <span class='optional'>(opzionale)</span><br><input type='text' maxlength='30' id='edit-company-city-" + j + "' value='" + (companyObject.addresses[j].city == null ? "" : companyObject.addresses[j].city) + "'></div>";
        addressesZoneHtml += "<div class='inline-form-component'>Provincia <span class='optional'>(opzionale)</span><br><input type='text' maxlength='3' id='edit-company-district-" + j + "' value='" + (companyObject.addresses[j].district == null ? "" : companyObject.addresses[j].district) + "'></div>";
        addressesZoneHtml += "<div class='inline-form-component'>CAP <span class='optional'>(opzionale)</span><br><input type='text' maxlength='7' id='edit-company-zip-code-" + j + "' value='" + (companyObject.addresses[j].zipCode == null ? "" : companyObject.addresses[j].zipCode) + "'></div>";
        addressesZoneHtml += "<input type='hidden' id='edit-company-address-name-" + j + "' value='" + (companyObject.addresses[j].addressName == null ? "" : companyObject.addresses[j].addressName) + "'>";
        addressesZoneHtml += "<input type='hidden' id='edit-company-address-id-" + j + "' value='" + (companyObject.addresses[j].addressId == null ? "" : companyObject.addresses[j].addressId) + "'>";
        addressesZoneHtml += "<br><br><button type='button' class='bg-red text-white' onclick='deleteCompanyAddress(" + j + ");'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi sede</button>";
        addressesZoneHtml += "</div>";
    }
    document.getElementById("edit-company-addresses-zone").innerHTML = addressesZoneHtml;
    scroll.refreshPopupById("edit-company-information", false);
}

function addCompanyAddress() {
    popup("choose-new-company-address-name", true, false, false);
}

function addCompanyCertification(certificationType) {
    document.getElementById("new-company-certification-name").value = certificationType;
    dom.removeClass(document.getElementById("new-company-certification-end-validity-holder"),"hidden");
    document.getElementById("new-company-certification-max-insurance").value="";
    if(certificationType=="Assicurazione"){
        dom.removeClass(document.getElementById("new-company-certification-max-insurance-holder"),"hidden");
        document.getElementById("new-company-certification-max-insurance").required="required";
    }
    else{
        dom.addClass(document.getElementById("new-company-certification-max-insurance"),"hidden");
        document.getElementById("new-company-certification-max-insurance").removeAttribute("required");
    }
    document.getElementById("new-company-certification-end-validity").required="required";
    document.getElementById("new-company-certification-file-upload").value="";
    document.getElementById("new-company-certification-referred-to-site").value="";
    popup("choose-new-company-certification-name", false, false, false);
}

function addCompanyAddressStep2() {
    closePopup();
    var addressesCount = parseInt(document.getElementById("company-addresses-count").value);
    var newAddressName = document.getElementById("new-company-address-name").value;
    document.getElementById("company-addresses-count").value = parseInt(addressesCount) + 1;
    document.getElementById("edit-company-addresses-zone").innerHTML = "<button type='button' onclick='showCompanyAddressToEdit(" + addressesCount + ");' id='to-edit-address-tab-button-" + addressesCount + "' class='to-edit-address-tab-button'>" + newAddressName + "</button> " + document.getElementById("edit-company-addresses-zone").innerHTML;
    scroll.refreshPopupById("edit-company-information", false);
    var addressesZoneHtml = "";
    addressesZoneHtml += "<div class='company-to-edit-address" + (addressesCount === 0 ? "" : " hidden") + "' id='company-to-edit-address-" + addressesCount + "'>";
    addressesZoneHtml += "<div class='inline-form-component'>Indirizzo <span class='optional'>(opzionale)</span><br><input type='text' maxlength='50' id='edit-company-street-name-" + addressesCount + "'></div>";
    addressesZoneHtml += "<div class='inline-form-component'>N°civico <span class='optional'>(opzionale)</span><br><input type='text' maxlength='10' id='edit-company-street-number-" + addressesCount + "'></div><br><br>";
    addressesZoneHtml += "<div class='inline-form-component'>Città <span class='optional'>(opzionale)</span><br><input type='text' maxlength='30' id='edit-company-city-" + addressesCount + "'></div>";
    addressesZoneHtml += "<div class='inline-form-component'>Provincia <span class='optional'>(opzionale)</span><br><input type='text' maxlength='3' id='edit-company-district-" + addressesCount + "'></div>";
    addressesZoneHtml += "<div class='inline-form-component'>CAP <span class='optional'>(opzionale)</span><br><input type='text' maxlength='7' id='edit-company-zip-code-" + addressesCount + "'></div>";
    addressesZoneHtml += "<input type='hidden' id='edit-company-address-name-" + addressesCount + "' value='" + newAddressName + "'>";
    addressesZoneHtml += "<input type='hidden' id='edit-company-address-id-" + addressesCount + "'>";
    addressesZoneHtml += "<br><br><button type='button' class='bg-red text-white' onclick='deleteCompanyAddress(" + addressesCount + ");'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi sede</button>";
    addressesZoneHtml += "</div>";
    document.getElementById("edit-company-addresses-zone").innerHTML += addressesZoneHtml;
    scroll.refreshPopupById("edit-company-information", false);
    dom.removeClass(document.querySelector(".add-address-tab-button"),"no-elements");
    showCompanyAddressToEdit(addressesCount);
}

function addCompanyCertificationStep2(){
    closePopup();
    var newCertificationName = document.getElementById("new-company-certification-name").value;
    var newCertificationDocumentName = document.getElementById("new-company-certification-file-upload").value.split(/(\\|\/)/g).pop(); //getting file name
    var el = document.getElementById("new-company-certification-referred-to-site");
    var newCompanyCertificationSiteId = el.value;
    var newCompanyCertificationSiteName = el.options[el.selectedIndex].innerHTML;

    ajaxUploadDocument("new-company-certification-file-upload",null,function(justUploadedFileId) {
        var requestData = {};
        requestData.companyId = parseInt(document.getElementById("safety-control-company-selector").value);
        requestData.certificationName = newCertificationName;
        requestData.endValidity = iso8601.fromEuropeanDate(document.getElementById("new-company-certification-end-validity").value);
        requestData.fileId = justUploadedFileId;
        requestData.siteId = newCompanyCertificationSiteId;
        requestData.maxInsurance = document.getElementById("new-company-certification-max-insurance").value;
        if(requestData.maxInsurance===""){
            requestData.maxInsurance=null;
        }
        popup("outcome", true, false, false);
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    closePopup();
                    safetyControlPage.refresh();
                    successToast("Il documento è stato creato");
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/companyCertification/create.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestData));
    });
}

function ajaxCreateCertificationForUser(){
    var requestPayload={};
    requestPayload.employeeId = parseInt(document.getElementById("add-certification-for-user-employee-id").value);
    requestPayload.certificationName = document.getElementById("add-certification-for-user-certification-name").value;
    requestPayload.endValidity = iso8601.fromEuropeanDate(document.getElementById("add-certification-for-user-expiration").value);
    requestPayload.companyId = parseInt(document.getElementById("add-certification-for-user-company-id").value);
    popup("uploading-file", false, false, false);
    ajaxUploadDocument("add-certification-for-user-file-upload","uploading-file-progress-bar",function(justUploadedFileId) {
        requestPayload.fileId=justUploadedFileId;
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            closePopup(); //closing uploading-file-popup
            closePopup(); //closing form popup
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    successToast("Certificazione creata con successo");
                    yourCompanyUsersPopup.refresh();
                }
                else {
                    errorPopup("Errore: " + response.message);
                }
            }
            catch(e) {
                errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/addCertificationToUser.php", true);
        xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(JSON.stringify(requestPayload));
    });
}

function deleteCompanyAddress(toDeleteAddressPosition) {
    var lastAddressPosition = parseInt(document.getElementById("company-addresses-count").value) - 1;
    document.getElementById("company-addresses-count").value = lastAddressPosition;
    //if the deleted address it's not the last
    if(toDeleteAddressPosition <= lastAddressPosition) {
        //shifting addresses from toDeleteAddressPosition to lastAddressPosition
        for(var i = toDeleteAddressPosition; i < lastAddressPosition; i++) {
            document.getElementById("edit-company-street-name-" + i).value = document.getElementById("edit-company-street-name-" + (i + 1)).value;
            document.getElementById("edit-company-street-number-" + i).value = document.getElementById("edit-company-street-number-" + (i + 1)).value;
            document.getElementById("edit-company-city-" + i).value = document.getElementById("edit-company-city-" + (i + 1)).value;
            document.getElementById("edit-company-district-" + i).value = document.getElementById("edit-company-district-" + (i + 1)).value;
            document.getElementById("edit-company-zip-code-" + i).value = document.getElementById("edit-company-zip-code-" + (i + 1)).value;
            document.getElementById("edit-company-address-name-" + i).value = document.getElementById("edit-company-address-name-" + (i + 1)).value;
            document.getElementById("edit-company-address-id-" + i).value = document.getElementById("edit-company-address-id-" + (i + 1)).value;
            document.getElementById("to-edit-address-tab-button-" + i).innerHTML = document.getElementById("to-edit-address-tab-button-" + (i + 1)).innerHTML;
        }
    }
    //deleting last address
    var parent = document.getElementById("edit-company-addresses-zone");
    parent.removeChild(document.getElementById("to-edit-address-tab-button-" + lastAddressPosition));
    parent.removeChild(document.getElementById("company-to-edit-address-" + lastAddressPosition));
    if(lastAddressPosition > 0) {
        showCompanyAddressToEdit(lastAddressPosition - 1);
    }
    else {
        dom.addClass(document.querySelector(".add-address-tab-button"),"no-elements");
    }
    scroll.refreshPopupById("edit-company-information", false);
}

function deleteCompanyCertification(toDeleteCertificationPosition) {
    var lastCertificationPosition = parseInt(document.getElementById("company-certifications-count").value) - 1;
    document.getElementById("company-certifications-count").value = lastCertificationPosition;
    //if the deleted certification it's not the last
    if(toDeleteCertificationPosition <= lastCertificationPosition) {
        //shifting certifications from toDeleteCertificationPosition to lastCertificationPosition
        for(var i = toDeleteCertificationPosition; i < lastCertificationPosition; i++) {
            console.log(toDeleteCertificationPosition);
            console.log(lastCertificationPosition);
            //document.getElementById("edit-company-district-" + i).value = document.getElementById("edit-company-district-" + (i + 1)).value;
            document.getElementById("edit-company-certification-end-validity-" + i).value = document.getElementById("edit-company-certification-end-validity-" + (i + 1)).value;
            document.getElementById("edit-company-certification-name-" + i).value = document.getElementById("edit-company-certification-name-" + (i + 1)).value;
            document.getElementById("edit-company-certification-id-" + i).value = document.getElementById("edit-company-certification-id-" + (i + 1)).value;
            document.getElementById("to-edit-certification-tab-button-" + i).innerHTML = document.getElementById("to-edit-certification-tab-button-" + (i + 1)).innerHTML;
        }
    }
    //deleting last address
    var parent = document.getElementById("edit-company-certifications-zone");
    parent.removeChild(document.getElementById("to-edit-certification-tab-button-" + lastCertificationPosition));
    parent.removeChild(document.getElementById("company-to-edit-certification-" + lastCertificationPosition));
    if(lastCertificationPosition > 0) {
        showCompanyCertificationToEdit(lastCertificationPosition - 1);
    }
    else {
        dom.addClass(document.querySelector(".add-certification-tab-button"),"no-elements");
    }
}

function popupEditSelectedSupplier() {
    ajaxGetEditCompany(document.getElementById("manage-company-selector").value);
    popup("edit-company-information", true, false, false);
    document.getElementById("edit-company-success-callback").value = "";
}

function ajaxEditCompanyDocuments(){
    var requestPayload = {};
    requestPayload.companyId = parseInt(document.getElementById("your-company-documents-company-id").value);
    toEditCertificationsCount = parseInt(document.getElementById("company-certifications-count").value);
    requestPayload.certifications = [];
    for(i = 0; i < toEditCertificationsCount; i++) {
        var thisCertification = {};
        thisCertification.certificationName = document.getElementById("edit-company-certification-name-" + i).value;
        if(thisCertification.certificationName === "") {
            delete thisCertification.certificationName;
        }
        thisCertification.endValidity = iso8601.fromEuropeanDate(document.getElementById("edit-company-certification-end-validity-" + i).value);
        thisCertification.documentId = document.getElementById("edit-company-certification-document-id-" + i).value;
        if(thisCertification.documentId === "") {
            delete thisCertification.documentId;
            thisCertification.fileId = document.getElementById("edit-company-certification-file-id-" + i).value;
            if(thisCertification.fileId === "") {
                delete thisCertification.fileId;
            }
            else {
                thisCertification.fileId = parseInt(thisCertification.fileId);
            }
        }
        else {
            thisCertification.documentId = parseInt(thisCertification.documentId);
        }
        thisCertification.siteId = document.getElementById("edit-company-certification-site-id-" + i).value;
        if(thisCertification.siteId === "") {
            delete thisCertification.siteId;
        }
        else {
            thisCertification.siteId = parseInt(thisCertification.siteId);
        }
        thisCertification.companyCertificationId = document.getElementById("edit-company-certification-id-" + i).value;
        if(thisCertification.companyCertificationId === "") {
            delete thisCertification.companyCertificationId;
        }
        else {
            thisCertification.companyCertificationId = parseInt(thisCertification.companyCertificationId);
        }
        requestPayload.certifications.push(thisCertification);
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        closePopup();
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                successToast("Documentazione aziendale modificata con successo");
                ajaxReloadCompanies();
            }
            else {
                errorPopup("Errore: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editCompanyCertifications.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestPayload));
}


function ajaxEditCompanyInformation() {
    var editedCompany = {};
    var successCallbackId = document.getElementById("edit-company-success-callback").value;
    editedCompany.companyId = parseInt(document.getElementById("edit-company-company-id").value);
    editedCompany.name = document.getElementById("edit-company-fiscal-name").value;
    if(editedCompany.name === "") {
        delete editedCompany.name;
    }
    editedCompany.vatNumber = document.getElementById("edit-company-vat-number").value;
    if(editedCompany.vatNumber === "") {
        delete editedCompany.vatNumber;
    }
    editedCompany.fiscalCode = document.getElementById("edit-company-fiscal-code").value;
    if(editedCompany.fiscalCode === "") {
        delete editedCompany.fiscalCode;
    }
    editedCompany.email = document.getElementById("edit-company-mail").value;
    if(editedCompany.email === "") {
        delete editedCompany.email;
    }
    editedCompany.telephone = document.getElementById("edit-company-phone").value;
    if(editedCompany.telephone === "") {
        delete editedCompany.telephone;
    }

    editedCompany.headerLine1 = document.getElementById("edit-company-header-line-1").value;
    editedCompany.headerLine2 = document.getElementById("edit-company-header-line-2").value;
    editedCompany.headerLine3 = document.getElementById("edit-company-header-line-3").value;
    editedCompany.headerLine4 = document.getElementById("edit-company-header-line-4").value;
    editedCompany.headerLine5 = document.getElementById("edit-company-header-line-5").value;
    editedCompany.headerLine6 = document.getElementById("edit-company-header-line-6").value;
    editedCompany.headerLine7 = document.getElementById("edit-company-header-line-7").value;
    editedCompany.headerImage = parseInt(document.getElementById("edit-company-header-logo-id").value);

    var toEditAddressesCount = parseInt(document.getElementById("company-addresses-count").value);
    editedCompany.addresses = [];
    var i;
    for(i = 0; i < toEditAddressesCount; i++) {
        var thisAddress = {};
        thisAddress.streetName = document.getElementById("edit-company-street-name-" + i).value;
        if(thisAddress.streetName === "") {
            delete thisAddress.streetName;
        }
        thisAddress.streetNumber = document.getElementById("edit-company-street-number-" + i).value;
        if(thisAddress.streetNumber === "") {
            delete thisAddress.streetNumber;
        }
        thisAddress.city = document.getElementById("edit-company-city-" + i).value;
        if(thisAddress.city === "") {
            delete thisAddress.city;
        }
        thisAddress.district = document.getElementById("edit-company-district-" + i).value;
        if(thisAddress.district === "") {
            delete thisAddress.district;
        }
        thisAddress.zipCode = document.getElementById("edit-company-zip-code-" + i).value;
        if(thisAddress.zipCode === "") {
            delete thisAddress.zipCode;
        }
        thisAddress.addressName = document.getElementById("edit-company-address-name-" + i).value;
        if(thisAddress.addressName === "") {
            delete thisAddress.addressName;
        }
        thisAddress.addressId = document.getElementById("edit-company-address-id-" + i).value;
        if(thisAddress.addressId === "") {
            delete thisAddress.addressId;
        }
        else {
            thisAddress.addressId = parseInt(thisAddress.addressId);
        }
        editedCompany.addresses.push(thisAddress);
    }

    var toUploadCompanyHeaderLogo = document.getElementById("edit-company-header-logo");

    closePopup();
    popup("outcome", true, false, false);

    if(toUploadCompanyHeaderLogo.files.length === 0) {
        ajaxSendEditCompanyRequest(editedCompany,successCallbackId);
    }
    else {
        var data = new FormData();
        data.append("newPicture", toUploadCompanyHeaderLogo.files[0]);

        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            try {
                var response = JSON.parse(xhttp.responseText);
                if(xhttp.status === 200) {
                    editedCompany.headerImage=response.newFileId;
                    ajaxSendEditCompanyRequest(editedCompany,successCallbackId);
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
                }
            }
            catch(e) {
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                console.error(e);
            }
        };
        xhttp.open("POST", BASE_URL + "/ws/uploadPicture.php", true);
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send(data);
    }
}

function ajaxSendEditCompanyRequest(editedCompany,successCallbackId){
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        closePopup();
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                successToast("Azienda modificata con successo");
                ajaxReloadCompanies();
                switch(successCallbackId) {
                    case "reload-company-licenses":
                        ajaxGetCompanyLicenses();
                        break;
                    default:
                        break;
                }
            }
            else {
                errorPopup("Errore: " + response.message);
            }
        }
        catch(e) {
            errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editCompany.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(editedCompany));
}


function ajaxEditAssetInstance() {
    photoUploads.forEach(function(photoUpload, photoIndex) {
        if(photoUpload.uploadTarget === "asset" && photoUpload.targetId === null) {
            photoUpload.targetId = parseInt(lastViewedAssetInstance.assetInstanceId);
            //photos were already uploaded to server
            if(photoUpload.photoId !== null) {
                attachPhotoToEntity(photoIndex);
            }
        }
    });
    photoUploads.forEach(function(photoUpload, photoIndex) {
        if(photoUpload.uploadTarget === "asset-ambient" && photoUpload.targetId === null) {
            photoUpload.targetId = parseInt(lastViewedAssetInstance.assetInstanceId);
            //photos were already uploaded to server
            if(photoUpload.photoId !== null) {
                attachPhotoToEntity(photoIndex);
            }
        }
    });
    var editedAssetInstance = {};
    editedAssetInstance.assetInstanceId = parseInt(lastViewedAssetInstance.assetInstanceId);
    var subcategorySelectors = document.querySelectorAll(".edit-asset-subcategory");
    editedAssetInstance.subcategories = [];
    for(var i = 0; i < subcategorySelectors.length; i++) {
        editedAssetInstance.subcategories[i] = parseInt(subcategorySelectors[i].value);
    }
    editedAssetInstance.assetQrId = document.getElementById("edit-asset-qr-id").value;
    if(editedAssetInstance.assetQrId === "") {
        delete editedAssetInstance.assetQrId;
    }
    editedAssetInstance.description = document.getElementById("edit-asset-description").value;
    if(editedAssetInstance.description === "") {
        delete editedAssetInstance.description;
    }
    editedAssetInstance.internalCode = document.getElementById("edit-asset-internal-code").value;
    if(editedAssetInstance.internalCode === "") {
        delete editedAssetInstance.internalCode;
    }
    editedAssetInstance.registrationNumber = document.getElementById("edit-asset-registration-number").value;
    if(editedAssetInstance.registrationNumber === "") {
        delete editedAssetInstance.registrationNumber;
    }
    editedAssetInstance.brand = document.getElementById("edit-asset-brand").value;
    if(editedAssetInstance.brand === "") {
        delete editedAssetInstance.brand;
    }
    editedAssetInstance.model = document.getElementById("edit-asset-model").value;
    if(editedAssetInstance.model === "") {
        delete editedAssetInstance.model;
    }
    editedAssetInstance.type = document.getElementById("edit-asset-type").value;
    if(editedAssetInstance.type === "") {
        delete editedAssetInstance.type;
    }
    editedAssetInstance.productionNumber = document.getElementById("edit-asset-production-number").value;
    if(editedAssetInstance.productionNumber === "") {
        delete editedAssetInstance.productionNumber;
    }
    editedAssetInstance.constructionYear = document.getElementById("edit-asset-construction-year").value;
    if(editedAssetInstance.constructionYear === "") {
        delete editedAssetInstance.constructionYear;
    }
    else {
        editedAssetInstance.constructionYear = parseInt(editedAssetInstance.constructionYear);
    }
    editedAssetInstance.installationDate = document.getElementById("edit-asset-installation-date").value.split("/").reverse().join("-");
    if(editedAssetInstance.installationDate === "") {
        delete editedAssetInstance.installationDate;
    }
    editedAssetInstance.expiration = document.getElementById("edit-asset-expiration").value.split("/").reverse().join("-");
    if(editedAssetInstance.expiration === "") {
        delete editedAssetInstance.expiration;
    }
    editedAssetInstance.floor = document.getElementById("edit-asset-floor").value;
    if(editedAssetInstance.floor === "") {
        delete editedAssetInstance.floor;
    }
    editedAssetInstance.areaId = document.getElementById("edit-asset-area").value;
    if(editedAssetInstance.areaId === "") {
        delete editedAssetInstance.areaId;
    }
    else{
        editedAssetInstance.areaId=parseInt(editedAssetInstance.areaId);
    }
    editedAssetInstance.homologation = document.getElementById("edit-asset-homologation").value;
    if(editedAssetInstance.homologation === "") {
        delete editedAssetInstance.homologation;
    }
    editedAssetInstance.certification = document.getElementById("edit-asset-certification").value;
    if(editedAssetInstance.certification === "") {
        delete editedAssetInstance.certification;
    }
    editedAssetInstance.positionHint = document.getElementById("edit-asset-position-hint").value;
    if(editedAssetInstance.positionHint === "") {
        delete editedAssetInstance.positionHint;
    }
    editedAssetInstance.size = document.getElementById("edit-asset-size").value;
    if(editedAssetInstance.size === "") {
        delete editedAssetInstance.size;
    }
    editedAssetInstance.assetValueAsNew = document.getElementById("edit-asset-value-as-new").value;
    if(editedAssetInstance.assetValueAsNew === "") {
        delete editedAssetInstance.assetValueAsNew;
    }
    else {
        editedAssetInstance.assetValueAsNew = parseFloat(editedAssetInstance.assetValueAsNew).toFixed(2);
    }
    editedAssetInstance.instantaneousConsumption = document.getElementById("edit-asset-instantaneous-consumption").value;
    if(editedAssetInstance.instantaneousConsumption === "") {
        delete editedAssetInstance.instantaneousConsumption;
    }
    else {
        editedAssetInstance.instantaneousConsumption = parseFloat(editedAssetInstance.instantaneousConsumption).toFixed(3);
    }
    editedAssetInstance.expectedLifeInYears = document.getElementById("edit-asset-expected-life-years").value;
    if(editedAssetInstance.expectedLifeInYears === "") {
        delete editedAssetInstance.expectedLifeInYears;
    }
    else {
        editedAssetInstance.expectedLifeInYears = parseFloat(editedAssetInstance.expectedLifeInYears).toFixed(2);
    }
    editedAssetInstance.operationHoursOfDay = document.getElementById("edit-asset-day-work").value;
    if(editedAssetInstance.operationHoursOfDay === "") {
        delete editedAssetInstance.operationHoursOfDay;
    }
    else {
        editedAssetInstance.operationHoursOfDay = parseInt(editedAssetInstance.operationHoursOfDay);
    }
    editedAssetInstance.operationDaysOfYear = document.getElementById("edit-asset-year-work").value;
    if(editedAssetInstance.operationDaysOfYear === "") {
        delete editedAssetInstance.operationDaysOfYear;
    }
    else {
        editedAssetInstance.operationDaysOfYear = parseInt(editedAssetInstance.operationDaysOfYear);
    }

    editedAssetInstance.manual = document.getElementById("asset-document-manual-file").getAttribute("data-selected-document-id");
    if(editedAssetInstance.manual==""){
        editedAssetInstance.manual = null;
    }
    else{
        editedAssetInstance.manual = parseInt(editedAssetInstance.manual);
    }
    editedAssetInstance.project = document.getElementById("asset-document-project-file").getAttribute("data-selected-document-id");
    if(editedAssetInstance.project==""){
        editedAssetInstance.project = null;
    }
    else{
        editedAssetInstance.project = parseInt(editedAssetInstance.project);
    }
    editedAssetInstance.conformityDeclaration = document.getElementById("asset-document-conformity-declaration-file").getAttribute("data-selected-document-id");
    if(editedAssetInstance.conformityDeclaration==""){
        editedAssetInstance.conformityDeclaration = null;
    }
    else{
        editedAssetInstance.conformityDeclaration = parseInt(editedAssetInstance.conformityDeclaration);
    }

    closePopup();
    outboxManager.addOperation(editedAssetInstance,"/ws/asset/update.php","Modifica asset",function(){
        assetInstanceManager.refreshAssets(globalFilterByCompany);
        successToast("Asset modificato con successo");
        reloadAssetNavFilters();
        ajaxGetAssetInstance(parseInt(lastViewedAssetInstance.assetInstanceId));
    });
}

function startIntervention() {
    document.querySelector("#page-execute-intervention .page-main-title").innerHTML = "Esecuzione intervento " + ((lastOpenedIntervention.parentInterventionId!=null)?lastOpenedIntervention.parentInterventionId:lastOpenedIntervention.interventionId);
    hashChangedInternally = true;
    window.location.hash = "#execute-intervention/" + lastOpenedIntervention.interventionId;
    changePage("page-execute-intervention", true, true);
    document.getElementById("interventions-on-asset-instance-details").innerHTML = "<div class='second-col-header'>Attività da effettuare sull'asset</div><div class='no-results-message'>Per visualizzare le attività seleziona un asset attraverso la lista a destra o scansionando un codice QR con il pulsante a sinistra.</div>";
    document.getElementById("involved-assets-container").innerHTML = "";
    scroll.refreshByName("execute-intervention-asset");
    scroll.refreshByName("execute-intervention-assets-list");
}

function selectAssetInstanceInExecution(scannedQrCode) {
    var found = false;
    for(var i = 0; i < assetInstancesInExecution.length; i++) {
        if(assetInstancesInExecution[i].qrCode === scannedQrCode) {
            found = true;
            var assetRow=document.getElementById("a-" + assetInstancesInExecution[i].assetInstanceId);
            var assetInstanceKey=assetRow.getAttribute("data-asset-instance-key");
            showActivitiesOnAssetInstance(assetInstanceKey,true,false);
            break;
        }
    }
    if(!found) {
        errorPopup("Il QR code scansionato non è collegato ad un asset incluso nell'intervento in corso.");
    }
}

function loadInterventionExecutionData(response) {
    assetInstancesInExecution = response.assetInstances;
    lastAssetInstances = response.assetInstances;
    interventionInExecutionContainsTickets = false;
    var tempHtml = "";
    var interventionAssetsHtml = "";
    var activitiesWithoutAssetsHtml = "";
    var atLeastOneAssetWithQr = false;
    for(var i = 0; i < response.assetInstances.length; i++) {
        var currentAsset = response.assetInstances[i];
        var categoryBackground, categoryIcon, subcategoryIcon,subcategoryName;
        var completed = (currentAsset.completedActivitiesCounter === currentAsset.totalActivitiesNumber);
        if(currentAsset.relatedTickets.length > 0) {
            /* Showing ticket form inputs only if the intervention contains
             * ticket with type different from 'Intervento a contratto' or 'Ordine'
             */
            currentAsset.relatedTickets.forEach(function(ticket){
                if(
                    ticket.ticketType!="Intervento a contratto" &&
                    ticket.ticketType!="Ordine" &&
                    (! ticket.ticketType.toLowerCase().includes("ordine di lavoro")) &&
                    (! ticket.ticketType.toLowerCase().includes("incluso nel canone"))
                ) {
                    interventionInExecutionContainsTickets = true;
                }
            });
        }
        if(currentAsset.assetInstanceId != null) {
            if(currentAsset.qrCode != null) {
                atLeastOneAssetWithQr = true;
            }
            categoryBackground = currentAsset.subcategories[0].categoryIconBackground;
            categoryIcon = currentAsset.subcategories[0].categoryIcon;
            subcategoryIcon = currentAsset.subcategories[0].subcategoryIcon;
            subcategoryName = currentAsset.subcategories[0].subcategoryName;
            interventionAssetsHtml += "<div class='entry " + (completed ? "completed" : "") + "' " +
                " onclick='if(ghostClickController.isOk()){showActivitiesOnAssetInstance(" + i + ",false,false);}' " +
                " data-asset-instance-key='" + i + "' " +
                " id='a-" + currentAsset.assetInstanceId + "'" +
                " >" +
                "<div>" +
                "<div class='category-small b-" + categoryBackground + "'>" + categoryIcon + "</div>" +
                "<div class='category-small b-" + categoryBackground + "'>" + subcategoryIcon + "</div>" +
                "</div>" +
                "<div>" +
                "<div class='inline-asset-detail'>"+subcategoryName+"</div>"+
                ((currentAsset.qrCode == null) ? "" : ("<div class='inline-asset-detail'><i class='fa fa-qrcode qr-icon' aria-hidden='true'></i><strong> QR </strong>" + currentAsset.qrCode + "</div>")) +
                ((currentAsset.brand == null) ? "" : ("<div class='inline-asset-detail'><strong> Marca </strong>" + currentAsset.brand + "</div>")) +
                ((currentAsset.model == null) ? "" : ("<div class='inline-asset-detail'><strong> Modello </strong>" + currentAsset.model + "</div>")) +
                ((currentAsset.internalCode == null) ? "" : ("<div class='inline-asset-detail'><strong> Cod. Interno </strong>" + currentAsset.internalCode + "</div>")) +
                ((currentAsset.registrationNumber == null) ? "" : ("<div class='inline-asset-detail'><strong> Matr. </strong>" + currentAsset.registrationNumber + "</div>")) +
                ((currentAsset.positionHint == null) ? "" : ("<br><div class='asset-location'><strong> <i class='fa fa-map-marker' aria-hidden='true'></i> </strong>" + currentAsset.positionHint + "</div>")) +
                "</div>" +
                "</div>";
        }
        else {
            for(var j = 0; j < response.assetInstances[i].relatedTickets.length; j++) {
                var relatedTicket = response.assetInstances[i].relatedTickets[j];
                var okRadioChecked = relatedTicket.outcomeOk && relatedTicket.interventionActivityIsCompleted;
                var issueRadioChecked = (!relatedTicket.outcomeOk) && relatedTicket.interventionActivityIsCompleted;
                var commentIcon;
                if(relatedTicket.notes) {
                    if(relatedTicket.notes.trim() !== "") {
                        commentIcon = "fa-commenting";
                    }
                    else {
                        commentIcon = "fa-comment";
                    }
                }
                else {
                    commentIcon = "fa-comment";
                }
                var sentToServerIcon = "<i class='fa fa-check' aria-hidden='true'></i>";
                var completionClass = "";
                if(okRadioChecked) {
                    completionClass = "activity-entry-ok";
                }
                if(issueRadioChecked) {
                    completionClass = "activity-entry-issue";
                }
                activitiesWithoutAssetsHtml += "<div class='entry ticket-in-execution-entry " + completionClass + "' id='ticket-in-execution-" + relatedTicket.ticketId + "'>" +
                    "<div class='icon-holder'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div></div>" +
                    "<div><strong>Ticket N°" + relatedTicket.customerTicketId + "</strong><br>" + relatedTicket.description + "</div>" +
                    "<span class='sent-to-server-icon'>" + sentToServerIcon + "</span>" +
                    "<div style='display:block;'>" +
                    "<button " +
                    " type='button'" +
                    " class='execute-intervention-ok-radio' " +
                    " id='activity-on-ticket-" + relatedTicket.ticketId + "-ok'" +
                    " onclick='toggleActivityOnTicketCheckbox(" + i + "," + j + ",\"ok\"," + relatedTicket.ticketId + ");'" +
                    " data-checked="+(okRadioChecked?"1":"0")+
                    ">" +
                    "<span></span>" +
                    "</button>" +
                    "<button " +
                    " type='button'" +
                    " class='execute-intervention-issue-radio' " +
                    " id='activity-on-ticket-" + relatedTicket.ticketId + "-issue' " +
                    " onclick='toggleActivityOnTicketCheckbox(" + i + "," + j + ",\"issue\"," + relatedTicket.ticketId + ");'" +
                    " data-checked="+(issueRadioChecked?"1":"0")+
                    ">" +
                    "<span></span>" +
                    "</button>" +
                    "<button " +
                    " type='button'" +
                    " class='comment-activity-on-asset-instance-execution-btn' " +
                    " onclick='commentActivityOnTicketInExecution(" + i + "," + j + ");'>" +
                    "<i class='fa " + commentIcon + "' aria-hidden='true'></i>" +
                    "</button>" +
                    "</div>";
            }
        }
    }
    if(atLeastOneAssetWithQr) {
        document.getElementById("executing-intervention-last-asset-completed-scan-qr-button").removeAttribute("disabled");
        document.getElementById("executing-intervention-asset-completed-scan-qr-button").removeAttribute("disabled");
        document.getElementById("intervention-execution-scan-qr").removeAttribute("disabled");
    }
    else {
        document.getElementById("executing-intervention-last-asset-completed-scan-qr-button").disabled="disabled";
        document.getElementById("executing-intervention-asset-completed-scan-qr-button").disabled="disabled";
        document.getElementById("intervention-execution-scan-qr").disabled="disabled";
    }
    if(interventionAssetsHtml === "") {
        tempHtml = "<div class='no-results-message'>Nessun asset collegato da mostrare</div><br><br><br>";
    }
    else {
        tempHtml = "<div id='assets-in-intervention-activity-table'>";
    }
    tempHtml += interventionAssetsHtml + "</div>";
    if(activitiesWithoutAssetsHtml !== "") {
        tempHtml += "<span class='important-label'>Altre attività</span><br>";
        tempHtml += activitiesWithoutAssetsHtml;
    }
    document.getElementById("involved-assets-container").innerHTML = tempHtml;
    scroll.refreshByName("execute-intervention-assets-list");
    document.getElementById("close-intervention-button").removeAttribute("disabled");
}

function ajaxStartIntervention() {
    dom.addClass(document.getElementById("close-intervention-correction-ended-button"),"hidden");
    dom.removeClass(document.getElementById("close-intervention-button"),"hidden");
    selectedAssetInstanceId=null;
    setInnerHtml(
        document.getElementById("involved-assets-container"),
        "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento intervento in corso...</span>",
        function() {
            scroll.refreshByName("intervention-activity-assets");
        }
    );
    var xhttp = new XMLHttpRequest();
    var timeoutErrorAbort = function() {
        console.log("esecuzione intervento offline");
        var response={};
        db.getAssetsOnIntervention(
            globalFilterByCompany,
            selectedIntervention,
            function(resultSet) {
                response.assetInstances = [];
                for(var i=0;i<resultSet.rows.length;i++) {
                    var row = resultSet.rows.item(i);
                    var k;
                    if(!(row.assetInstanceId===null&&row.ticketId===null)) {
                        var newAssetIndex=null;
                        response.assetInstances.forEach(function(assetInstance,index){
                            if(assetInstance.assetInstanceId==row.assetInstanceId){
                                newAssetIndex=index;
                            }
                        });
                        if(newAssetIndex===null) { //new asset instance not seen before in this object
                            var asset = {};
                            asset.subcategories = [];
                            asset.completedActivitiesCounter = 0;
                            asset.totalActivitiesNumber = 0;
                            asset.relatedTickets = [];
                            asset.activitiesOnAssetInstance = [];
                            if(row.assetInstanceId !== null) {
                                asset.assetInstanceId = row.assetInstanceId;
                                asset.model = row.model;
                                asset.brand = row.brand;
                                asset.internalCode = row.internalCode;
                                asset.registrationNumber = row.registrationNumber;
                                asset.positionHint = row.positionHint;
                                if(row.qrCode === null) {
                                    asset.qrCode = null;
                                }
                                else {
                                    asset.qrCode = row.qrCode;
                                }
                            }
                            else {
                                asset.assetInstanceId = null;
                            }
                            var assetInstancesLength=response.assetInstances.push(asset);
                            newAssetIndex=assetInstancesLength-1;
                        }
                        if(row.ticketId !== null) {
                            var ticketIndex=null;
                            response.assetInstances[newAssetIndex].relatedTickets.forEach(function(ticket,index){
                                if(ticket.ticketId==row.ticketId){
                                    ticketIndex=index;
                                }
                            });
                            if(ticketIndex===null){
                                ticket = [];
                                ticket.ticketId = row.ticketId;
                                ticket.customerTicketId = row.customerTicketId;
                                ticket.description = row.ticketDescription;
                                ticket.notes = row.ticketExecutionNotes;
                                ticket.rowId = null;
                                ticket.interventionActivityIsCompleted = row.ticketIsCompleted;
                                ticket.outcomeOk = row.ticketSuccess;
                                ticket.categoryId = row.ticketCategoryId;
                                ticket.subcategoryId = row.ticketSubcategoryId;
                                ticket.ticketType = row.ticketType;
                                response.assetInstances[newAssetIndex].relatedTickets.push(ticket);
                            }
                        }
                        if(row.activityOnAssetInstanceId !== null) {
                            var activityIndex=null;
                            response.assetInstances[newAssetIndex].activitiesOnAssetInstance.forEach(function(activity,index){
                                if(activity.activityOnAssetInstanceId==row.activityOnAssetInstanceId) {
                                    activityIndex=index;
                                }
                            });
                            if(activityIndex===null) {
                                tmpActivityOnAssetInstance = [];
                                tmpActivityOnAssetInstance.activityOnAssetInstanceId = row.activityOnAssetInstanceId;
                                tmpActivityOnAssetInstance.interventionActivityId = row.interventionActivityId;
                                tmpActivityOnAssetInstance.interventionActivityIsCompleted = !!row.activityIsCompleted;
                                tmpActivityOnAssetInstance.notes = row.activityOnAssetInstanceNotes;
                                tmpActivityOnAssetInstance.rowId = null;
                                tmpActivityOnAssetInstance.outcomeOk = !!row.activityOnAssetInstanceSuccess;
                                response.assetInstances[newAssetIndex].activitiesOnAssetInstance.push(tmpActivityOnAssetInstance);
                                response.assetInstances[newAssetIndex].totalActivitiesNumber++;
                                response.assetInstances[newAssetIndex].completedActivitiesCounter += row.activityIsCompleted;
                            }
                        }
                        if(row.subcategoryId !== null) {
                            var subcategoryIndex=null;
                            response.assetInstances[newAssetIndex].subcategories.forEach(function(subcategory,index){
                                if(subcategory.subcategoryId==row.subcategoryId){
                                    subcategoryIndex=index;
                                }
                            });
                            if(subcategoryIndex===null) {
                                subcategory = [];
                                subcategory.subcategoryId = row.subcategoryId;
                                subcategory.categoryIcon = row.categoryIcon;
                                subcategory.subcategoryIcon = row.subcategoryIcon;
                                subcategory.subcategoryName = row.subcategoryName;
                                subcategory.categoryIconBackground = row.categoryBackground;
                                response.assetInstances[newAssetIndex].subcategories.push(subcategory);
                            }
                        }
                    }
                }
                if(resultSet.rows.length>0) {
                    loadInterventionExecutionData(response);
                }
                else {
                    setInnerHtml(
                        document.getElementById("involved-assets-container"),
                        "<div class='message error'> Intervento non disponibile offline </div>",
                        scroll.refreshByName("intervention-activity-assets")
                    );
                    document.getElementById("close-intervention-button").disabled="disabled";
                    document.getElementById("intervention-execution-scan-qr").disabled="disabled";
                }
            }
        );
    };
    xhttp.timeout = 10000; // time in milliseconds
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            loadInterventionExecutionData(response);
        }
        else { //xhttp.status != 200
            setInnerHtml(
                document.getElementById("involved-assets-container"),
                "<div class='message error'> Errore: " + response.message + "</div><button onclick='ui.pageBack();' class='error-standalone-back-button'><i class='fa fa-chevron-left' aria-hidden='true'></i> Indietro</button>",
                function(){
                    scroll.refreshByName("intervention-activity-assets");
                }
            );
            document.getElementById("close-intervention-button").disabled="disabled";
            document.getElementById("intervention-execution-scan-qr").disabled="disabled";
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/intervention/start.php?interventionId=" + selectedIntervention, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

var selectedAssetInstanceId;

function correctAssetInstanceInIntervention(assetInstanceKey){
    showActivitiesOnAssetInstance(assetInstanceKey,true,true);
}

function replaceAssetDuringIntervention(assetInstanceKey,activityIndex,isMobileView){
    document.getElementById("asset-replacement-new-asset-replaced-asset-id").value = assetInstancesInExecution[assetInstanceKey].assetInstanceId;
    document.getElementById("asset-replacement-new-asset-replaced-asset-key").value = assetInstanceKey;
    document.getElementById("asset-replacement-activity-on-asset-instance-key").value = activityIndex;
    document.getElementById("asset-replacement-new-asset-brand").value = assetInstancesInExecution[assetInstanceKey].brand;
    document.getElementById("asset-replacement-new-asset-model").value = assetInstancesInExecution[assetInstanceKey].model;
    document.getElementById("asset-replacement-new-asset-registration-number").value = "";
    document.getElementById("asset-replacement-new-asset-notes").value = "";
    popup("asset-replacement-scan-qr",false,false,false);
}

//reliableSelection it's true when the asset in intervention is scanned from a qr code, or it's selected manually from the list for a valid reason
function showActivitiesOnAssetInstance(assetInstanceKey,reliableSelection,correcting) {
    var isMobileView=isSmallViewport()||correcting;
    if(correcting) {
        dom.addClass(document.getElementById("activities-on-asset-popup"),"correct-intervention-asset");
        dom.addClass(document.getElementById("interventions-on-asset-instance-details"),"correct-intervention-asset");
    }
    else {
        dom.removeClass(document.getElementById("activities-on-asset-popup"),"correct-intervention-asset");
        dom.removeClass(document.getElementById("interventions-on-asset-instance-details"),"correct-intervention-asset");
    }
    if(!reliableSelection && assetInstancesInExecution[assetInstanceKey].qrCode!==null){
        document.getElementById("should-use-qr-selected-asset-instance-key").value=assetInstanceKey;
        popup("should-use-qr", undefined, undefined, false);
        return;
    }
    if(selectedAssetInstanceId !== null) {
        dom.removeClass(document.getElementById("a-" + assetInstancesInExecution[selectedAssetInstanceId].assetInstanceId), "selected");
    }
    dom.addClass(document.getElementById("a-" + assetInstancesInExecution[parseInt(assetInstanceKey)].assetInstanceId), "selected");
    selectedAssetInstanceId = assetInstanceKey;
    var activityDescription = "";
    var completedAssets = 0;
    var i;
    for(i = 0; i < assetInstancesInExecution.length; i++) {
        if(assetInstancesInExecution[i].completedActivitiesCounter === assetInstancesInExecution[i].totalActivitiesNumber) {
            completedAssets++;
        }
    }
    //TODO: CHECK IF GEOLOCALIZATION IS ENABLED FOR COMPANY
    //if(completedAssets===(Math.floor(assetInstancesInExecution.length/2))){
        //geolocalizeDevice();
    //}
    var tempHtml = "<div class='activity-on-assets-progress-counter-container'>Asset completati: <span id='activity-on-assets-progress-counter'>" + (completedAssets + " di " + assetInstancesInExecution.length) + "</span></div>";
    var activityOnAssetsProgressCounters=document.querySelectorAll(".activity-on-assets-progress-counter");
    activityOnAssetsProgressCounters.forEach(function(activityOnAssetsProgressCounter) {
        setInnerHtml(activityOnAssetsProgressCounter,completedAssets + " di " + assetInstancesInExecution.length);
    });

    tempHtml += "<div class='second-col-header'>Asset selezionato</div>";
    tempHtml += "<div class='hth'>";
    var asset = assetInstancesInExecution[assetInstanceKey];
    tempHtml += "<div class='tr'><div>Categoria</div><div>";
    i=0;
    asset.subcategories.forEach(function(subcategory){
        if(subcategory.deletion === null){
            if(i>0) {
                tempHtml+="<br>";
            }
            tempHtml += "<div class='category-small b-" + subcategory.categoryIconBackground + "'>" + subcategory.categoryIcon + "</div>";
            tempHtml += "<div class='category-small b-" + subcategory.categoryIconBackground + "'>" + subcategory.subcategoryIcon + "</div>";
            tempHtml += subcategory.subcategoryName;
            i++;
        }
    });
    tempHtml+="</div></div>";
    tempHtml += "<div class='tr'><div><i onclick='popup( \"help-ticket-assets\" ,false,false,false);' class='fa fa-qrcode qr-icon' aria-hidden='true'></i> QR Asset</div><div>" + (assetInstancesInExecution[assetInstanceKey].qrCode == null ? "<span class='red-state'>Non presente</span>" : assetInstancesInExecution[assetInstanceKey].qrCode) + "</div></div>";
    tempHtml +=  (assetInstancesInExecution[assetInstanceKey].model !== null ? ("<div class='tr'><div>Modello</div><div>" + assetInstancesInExecution[assetInstanceKey].model+ "</div></div>") : "") ;
    tempHtml +=  (assetInstancesInExecution[assetInstanceKey].brand !== null ? ("<div class='tr'><div>Marca</div><div>" + assetInstancesInExecution[assetInstanceKey].brand+ "</div></div>") : "") ;
    tempHtml +=  (assetInstancesInExecution[assetInstanceKey].internalCode !== null ? ("<div class='tr'><div>Codice interno</div><div>" + assetInstancesInExecution[assetInstanceKey].internalCode+ "</div></div>") : "") ;
    tempHtml +=  (assetInstancesInExecution[assetInstanceKey].registrationNumber !== null ? ("<div class='tr'><div>Matricola</div><div>" + assetInstancesInExecution[assetInstanceKey].registrationNumber+ "</div></div>") : "") ;
    tempHtml += "<div class='tr'><div>Attività sull'asset completate</div><div><span id='completed-activities-on-asset-counter'>" + assetInstancesInExecution[assetInstanceKey].completedActivitiesCounter + "</span> di " + assetInstancesInExecution[assetInstanceKey].totalActivitiesNumber + "</div></div>";
    tempHtml += "</div><br>";
    tempHtml += "<div class='second-col-header'>Attività da effettuare sull'asset</div>";
    var viewportPrefix = "";
    if(isSmallViewport()) {
        viewportPrefix = "m-";
    }
    for(i = 0; i < assetInstancesInExecution[assetInstanceKey].totalActivitiesNumber; i++) {
        for(var j = 0; j < lastOpenedIntervention.activities.length; j++) {
            if(lastOpenedIntervention.activities[j].interventionActivityId == assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].interventionActivityId) {
                activityDescription = lastOpenedIntervention.activities[j].activityDescription;
            }
        }
        var entryClass = "", okRadioChecked = false, issueRadioChecked = false;
        if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].interventionActivityIsCompleted) {
            if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].outcomeOk) {
                entryClass = "activity-entry-ok";
                okRadioChecked = true;
                issueRadioChecked = false;
            }
            else {
                entryClass = "activity-entry-issue";
                okRadioChecked = false;
                issueRadioChecked = true;
            }
        }
        var commentIcon;
        if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].notes) {
            if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].notes.trim() !== "") {
                commentIcon = "fa-commenting";
            }
            else {
                commentIcon = "fa-comment";
            }
        }
        else {
            commentIcon = "fa-comment";
        }
        var iaId = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].interventionActivityId;
        var aoaiId = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].activityOnAssetInstanceId;
        //Sent to server indicator
        var sentToServerIcon = "";
        if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].sentToServer !== undefined) {
            if(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].sentToServer) {
                sentToServerIcon = "<i class='fa fa-check' aria-hidden='true'></i>";
            }
            else {
                sentToServerIcon = "<i class='fa fa-clock-o' aria-hidden='true'></i>";
            }
        }
        else {
            sentToServerIcon = "<i class='fa fa-check' aria-hidden='true'></i>"; //downloaded from server
        }
        //Rendering the activity HTML
        tempHtml += "<div class='execute-intervention-activity-on-asset-entry " + entryClass +
            "' data-intervention-activity-id='" + iaId + "' data-activity-on-asset-instance-id='" +
            aoaiId + "' id='" + viewportPrefix +
            "intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + i + "'>" +
            "<span class='sent-to-server-icon'>" + sentToServerIcon + "</span>" +
            "<span>" + activityDescription;
        var assetMustBeReplaced = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetMustBeReplaced===true;
        var hasAssetField = (assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldType!==null &&
            assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldType!==undefined);
        var assetFieldWrongValue,hasWrongValue,assetFieldValue,assetFieldHasValue;
        if(hasAssetField) {
            assetFieldWrongValue = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldWrongValue;
            hasWrongValue = (assetFieldWrongValue!==null)&&(assetFieldWrongValue!=="");
            assetFieldValue = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldValue;
            assetFieldHasValue = (assetFieldValue!=null && assetFieldValue!="");
            //Field name
            tempHtml+="<br><br><strong>"+assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldName+"</strong><br>";
            //Previous value
            tempHtml+="<span id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field-prev' " +
                "class='activity-on-asset-instance-custom-field-prev "+(assetFieldHasValue?"":"hidden")+"'>Dato precedente: "+
                ((assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldPreviousValue!==null)?
                assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldPreviousValue:"nessuno") +
                "</span>";
            //Wrong value
            tempHtml+="<span id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field-wrong' " +
                "class='activity-on-asset-instance-custom-field-wrong "+(hasWrongValue?"":"hidden")+"'>Errato: "+
                "<span class='activity-on-asset-instance-custom-field-wrong-holder'>" +
                (hasWrongValue?assetFieldWrongValue:"") +
                "</span></span><br>";
            var previousFieldDataValue = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldPreviousValue===null?"":assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldPreviousValue;
            var fieldValueIdDataValue = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldValueId===null?"":assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldValueId;
            //Input field
            switch(assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldType){
                case "integer":
                    tempHtml+="<input type='number' " +
                        "id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field' " +
                        "step='1' " +
                        "data-asset-field-type='integer' " +
                        "data-asset-field-type-id='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldTypeId +"' " +
                        "data-asset-field-ge-zero='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGeZero +"' " +
                        "data-asset-field-ge-precedent='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGePrecedent +"' " +
                        "data-asset-field-previous-value='"+ previousFieldDataValue +"' " +
                        "data-asset-field-name='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldName +"' " +
                        "data-asset-field-value-id='"+ fieldValueIdDataValue +"' " +
                        "class='activity-on-asset-instance-custom-field-input'";
                    if(assetFieldHasValue){
                        tempHtml+= " value='"+assetFieldValue+"'" +
                            " disabled='disabled'";
                    }
                    tempHtml+="><br><br>";
                    break;
                case "decimal":
                    tempHtml+="<input type='number' " +
                        "id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field' " +
                        "step='0.01' " +
                        "data-asset-field-type='decimal' " +
                        "data-asset-field-type-id='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldTypeId +"' " +
                        "data-asset-field-ge-zero='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGeZero +"' " +
                        "data-asset-field-ge-precedent='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGePrecedent +"' " +
                        "data-asset-field-previous-value='"+ previousFieldDataValue +"' " +
                        "data-asset-field-name='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldName +"' " +
                        "data-asset-field-value-id='"+ fieldValueIdDataValue +"' " +
                        "class='activity-on-asset-instance-custom-field-input'";
                    if(assetFieldHasValue){
                        tempHtml+= " value='"+assetFieldValue+"'" +
                            " disabled='disabled'";
                    }
                    tempHtml+="><br><br>";
                    break;
                case "bool":
                    tempHtml+="<input type='number' " +
                        "id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field' " +
                        "step='1' " +
                        "data-asset-field-type='bool' " +
                        "data-asset-field-type-id='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldTypeId +"' " +
                        "data-asset-field-ge-zero='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGeZero +"' " +
                        "data-asset-field-ge-precedent='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGePrecedent +"' " +
                        "data-asset-field-previous-value='"+ previousFieldDataValue +"' " +
                        "data-asset-field-name='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldName +"' " +
                        "data-asset-field-value-id='"+ fieldValueIdDataValue +"' " +
                        "min='0' " +
                        "max='1' " +
                        "class='activity-on-asset-instance-custom-field-input'";
                    if(assetFieldHasValue){
                        tempHtml+= " value='"+assetFieldValue+"'" +
                            " disabled='disabled'";
                    }
                    tempHtml+="><br><br>";
                    break;
                case "text":
                    tempHtml+="<input type='text' " +
                        "id='activity-on-asset-" + assetInstanceKey + "-" + i + "-asset-field' " +
                        "maxlength='30' " +
                        "data-field-type='text' " +
                        "data-asset-field-type-id='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldTypeId +"' " +
                        "data-asset-field-ge-zero='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGeZero +"' " +
                        "data-asset-field-ge-precedent='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldGePrecedent +"' " +
                        "data-asset-field-previous-value='"+ previousFieldDataValue +"' " +
                        "data-asset-field-name='"+ assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[i].assetFieldName +"' " +
                        "data-asset-field-value-id='"+ fieldValueIdDataValue +"' " +
                        "class='activity-on-asset-instance-custom-field-input'";
                    if(assetFieldHasValue){
                        tempHtml+= " value='"+assetFieldValue+"'" +
                            " disabled='disabled'";
                    }
                    tempHtml+="><br><br>";
                    break;
            }
        }
        tempHtml+="</span>";
        if(assetMustBeReplaced) {
            tempHtml += "<button " +
                " type='button'" +
                " class='execute-intervention-replace-asset-button' " +
                " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-ok'" +
                " onclick='replaceAssetDuringIntervention(" + assetInstanceKey + "," + i + ","+isMobileView+");'" +
                " data-checked=" + (okRadioChecked ? "1" : "0") +
                ">" +
                "<i class='fa fa-refresh'></i> Sostituisci" +
                "</button>"+
                "</div>";
            //Fake buttons that are hidden and cannot be clicked just to store the same data on them for replacement activities
            tempHtml += "<button " +
                " type='button'" +
                " class='execute-intervention-ok-radio forced-hidden' " +
                " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-ok'" +
                " data-checked=" + (okRadioChecked ? "1" : "0") +
                ">" +
                "<span></span>" +
                "</button>";
                "<button " +
                " type='button'" +
                " class='execute-intervention-edit-radio hidden forced-hidden' " +
                " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-edit'" +
                ">" +
                "<span></span>" +
                "</button>";
                "<button " +
                " type='button'" +
                " class='execute-intervention-issue-radio forced-hidden' " +
                " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-issue'" +
                " data-checked="+(issueRadioChecked?"1":"0")+
                ">" +
                "<span></span>" +
                "</button>" +
                "<button " +
                " class='comment-activity-on-asset-instance-execution-btn forced-hidden' " +
                " type='button'" +
                ">" +
                "<i class='fa " + commentIcon + "' aria-hidden='true'></i>" +
                "</button>" +
                "</div>";

        }
        else {
            if((!hasAssetField)||(hasAssetField&&(!assetFieldHasValue))) {
                tempHtml += "<button " +
                    " type='button'" +
                    " class='execute-intervention-ok-radio' " +
                    " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-ok'" +
                    " onclick='toggleActivityOnAssetInstanceCheckbox(" + assetInstanceKey + "," + i + ", \"ok\" ," + isMobileView + ",false);'" +
                    " data-checked=" + (okRadioChecked ? "1" : "0") +
                    ">" +
                    "<span></span>" +
                    "</button>";
                tempHtml += "<button " +
                    " type='button'" +
                    " class='execute-intervention-edit-radio hidden' " +
                    " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-edit'" +
                    " onclick='activityOnAssetInstanceEnableEditAssetField(" + assetInstanceKey + "," + i + ","+isMobileView+");'" +
                    ">" +
                    "<span></span>" +
                    "</button>";
            }
            else {
                tempHtml += "<button " +
                    " type='button'" +
                    " class='execute-intervention-ok-radio hidden' " +
                    " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-ok'" +
                    " onclick='toggleActivityOnAssetInstanceCheckbox(" + assetInstanceKey + "," + i + ", \"ok\" ," + isMobileView + ",false);'" +
                    " data-checked=" + (okRadioChecked ? "1" : "0") +
                    ">" +
                    "<span></span>" +
                    "</button>";
                tempHtml += "<button " +
                    " type='button'" +
                    " class='execute-intervention-edit-radio' " +
                    " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-edit'" +
                    " onclick='activityOnAssetInstanceEnableEditAssetField(" + assetInstanceKey + "," + i + ","+isMobileView+");'" +
                    ">" +
                    "<span></span>" +
                    "</button>";
            }
            tempHtml+="<button " +
                " type='button'" +
                " class='execute-intervention-issue-radio' " +
                " id='activity-on-asset-" + assetInstanceKey + "-" + i + "-issue'" +
                " onclick='toggleActivityOnAssetInstanceCheckbox(" + assetInstanceKey + "," + i + ", \"issue\" ," + isMobileView + ",false);'" +
                " data-checked="+(issueRadioChecked?"1":"0")+
                ">" +
                "<span></span>" +
                "</button>" +
                "<button " +
                " class='comment-activity-on-asset-instance-execution-btn' " +
                " type='button'" +
                " onclick='commentActivityOnAssetInstanceExecution(" + assetInstanceKey + "," + i + ");'" +
                ">" +
                "<i class='fa " + commentIcon + "' aria-hidden='true'></i>" +
                "</button>" +
                "</div>";
        }

    }
    if(correcting) {
        document.getElementById("interventions-on-asset-instance-details").innerHTML = "<div class='second-col-header'>Attività da effettuare sull'asset</div><div class='no-results-message'>Per visualizzare gli interventi seleziona un asset attraverso la lista a destra o scansionando un codice QR con il pulsante a sinistra.</div>";
        scroll.refreshByName("execute-intervention-asset");
    }

    if(isMobileView) {
        if(correcting) {
            tempHtml += "<button type='button' id='executing-intervention-asset-corrected-button' class='bg-green text-white' onclick='closePopup();verifyInterventionDataPopup.refresh();'><i class='fa fa-check fa-2x' aria-hidden='true'></i><br>Correzioni completate</button></div><br><br><br>";
        }
        else {
            if(completedAssets===assetInstancesInExecution.length) {
                tempHtml += "<button type='button' id='executing-intervention-asset-completed-button' class='bg-green text-white' onclick='popup( \"executing-intervention-last-asset-completed\" ,undefined,undefined,false);'><i class='fa fa-check fa-2x' aria-hidden='true'></i><br>Asset completato</button></div><br><br><br>";
            }
            else{
                tempHtml += "<button type='button' id='executing-intervention-asset-completed-button' class='bg-green text-white' onclick='popup( \"executing-intervention-asset-completed\" ,false,true,false);'><i class='fa fa-check fa-2x' aria-hidden='true'></i><br>Asset completato</button></div><br><br><br>";
            }
        }
        setInnerHtml(
            document.getElementById("activities-on-asset-popup-container"),
            tempHtml,
            function(){scroll.refreshPopupById("activities-on-asset", false);}
        );
        popup("activities-on-asset", false, false, false);
    }
    else {
        setInnerHtml(
            document.getElementById("interventions-on-asset-instance-details"),
            tempHtml,
            function(){
                scroll.refreshByName("execute-intervention-assets-list");
                scroll.refreshByName("execute-intervention-asset");
            }
        );
    }
}

function ajaxReportNotUsedQr(){
    var assetInstanceKey=document.getElementById("should-use-qr-selected-asset-instance-key").value;
    var requestData = {};
    requestData.interventionId = parseInt(lastOpenedIntervention.interventionId);
    requestData.asstetInstanceId = parseInt(assetInstancesInExecution[assetInstanceKey].assetInstanceId);
    requestData.reason = getPseudoRadioValueByClass("manual-asset-select-reason");
    if(requestData.reason === null){
        errorToast("È necessario indicare un motivo per procedere");
        return;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {

        }
        else {
            errorPopup("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/reportNotUsedQr.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function commentActivityOnAssetInstanceExecution(assetInstanceKey, activityOnAssetInstanceKey) {
    popup("comment-activity-on-asset-instance-execution", false, false, false);
    document.getElementById("activity-on-asset-instance-comment").value = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey].notes;
    document.getElementById("comment-asset-instance-key").value = assetInstanceKey;
    document.getElementById("comment-activity-on-asset-instance-key").value = activityOnAssetInstanceKey;
    document.getElementById("comment-activity-on-ticket-key").value = "";
    document.getElementById("comment-asset-instance-id").value = assetInstancesInExecution[assetInstanceKey].assetInstanceId;
    document.getElementById("comment-asset-subcategory-id").value = assetInstancesInExecution[assetInstanceKey].subcategories[0].subcategoryId;
    var searchedSubcategoryId=assetInstancesInExecution[assetInstanceKey].subcategories[0].subcategoryId;
    for(var i=0;i<subcategories.length;i++){
        if(subcategories[i].subcategoryId==searchedSubcategoryId ){
            document.getElementById("comment-asset-category-id").value = subcategories[i].categoryId;
        }
    }
}

function commentActivityOnTicketInExecution(assetInstanceKey, ticketKey) {
    popup("comment-activity-on-asset-instance-execution", false, false, false);
    document.getElementById("comment-asset-instance-id").value = assetInstancesInExecution[assetInstanceKey].assetInstanceId;
    document.getElementById("comment-asset-category-id").value = assetInstancesInExecution[assetInstanceKey].relatedTickets[ticketKey].categoryId;
    document.getElementById("comment-asset-subcategory-id").value = assetInstancesInExecution[assetInstanceKey].relatedTickets[ticketKey].subcategoryId;
    document.getElementById("activity-on-asset-instance-comment").value = assetInstancesInExecution[assetInstanceKey].relatedTickets[ticketKey].notes;
    document.getElementById("comment-asset-instance-key").value = assetInstanceKey;
    document.getElementById("comment-activity-on-asset-instance-key").value = "";
    document.getElementById("comment-activity-on-ticket-key").value = ticketKey;
}

function toggleCommentButtonIcon(commentButton, newNotes) {
    if(newNotes.trim() !== "") {
        dom.removeClass(commentButton, "fa-comment");
        dom.addClass(commentButton, "fa-commenting");
    }
    else {
        dom.addClass(commentButton, "fa-comment");
        dom.removeClass(commentButton, "fa-commenting");
    }
}

function checkNoteAndClose() {
    lastTimeOpenedNotifications = new Date();//Patch to fix ghost click loop on iOS
    var assetInstanceKey = document.getElementById("comment-asset-instance-key").value;
    var activityOnAssetInstanceKey = document.getElementById("comment-activity-on-asset-instance-key").value;
    var activityOnTicketKey = document.getElementById("comment-activity-on-ticket-key").value;
    var activityContainer, currentActivity, activityContainerMobile;
    //Outcome not ok, there must be a at least a two characters note
    if(activityOnAssetInstanceKey !== "") { //activity on asset instance
        currentActivity = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey];
        activityContainer = document.getElementById("intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
        activityContainerMobile = document.getElementById("m-intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
        document.getElementById("activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey+"-ok").setAttribute("data-checked","0");
        document.getElementById("activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey+"-issue").setAttribute("data-checked","0");
        //TODO: here I should unset the outcome of "activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey
    }
    else {
        currentActivity = assetInstancesInExecution[assetInstanceKey].relatedTickets[activityOnTicketKey];
        activityContainer = document.getElementById("ticket-in-execution-" + currentActivity.ticketId);
        activityContainerMobile = null;
        //TODO: check this
        if(currentActivity.outcomeOk===false){
            toggleActivityOnTicketCheckbox(assetInstanceKey, activityOnTicketKey, null, currentActivity.ticketId);
        }
    }
    if(currentActivity.outcomeOk===false) {
        if(currentActivity.notes === undefined
            || currentActivity.notes === null
            || currentActivity.notes.length < 3
        ) {
            //The notes are too short, i have to rollback the negative outcome on the activity
            if(currentActivity.interventionActivityIsCompleted) {
                var completedCounter = document.getElementById("completed-activities-on-asset-counter");
                if(completedCounter!=null){
                    completedCounter.innerHTML = parseInt(completedCounter.innerHTML) - 1;
                }
                assetInstancesInExecution[assetInstanceKey].completedActivitiesCounter--;
            }
            currentActivity.completionDatetime=null;
            currentActivity.outcomeOk = null;
            //mark that the activity has changes that need to be sent to the server
            currentActivity.sentToServer = false;
            currentActivity.interventionActivityIsCompleted = false;
            currentActivity.completionDatetime = null;
            if(activityContainer) {
                dom.removeClass(activityContainer, "activity-entry-ok");
                dom.removeClass(activityContainer, "activity-entry-issue");
                currentActivity.activityContainer = activityContainer;
                setInnerHtml(activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
            }
            if(activityContainerMobile) {
                dom.removeClass(activityContainerMobile, "activity-entry-ok");
                dom.removeClass(activityContainerMobile, "activity-entry-issue");
                currentActivity.activityContainerMobile = activityContainerMobile;
                setInnerHtml(activityContainerMobile.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
            }
            var activityUpdate = {};
            activityUpdate.assetInstanceKey = assetInstanceKey;
            activityUpdate.interventionActivityIsCompleted = false;
            activityUpdate.outcomeOk = null;
            activityUpdate.notes = currentActivity.notes;
            activityUpdate.completionDatetime = null;
            var now = new Date();
            activityUpdate.requestDatetime = now.toISOString();
            if(activityOnAssetInstanceKey !== "") { //activity on asset instance
                activityUpdate.activityOnAssetInstanceKey = activityOnAssetInstanceKey;
                activityUpdate.activityOnAssetInstanceId = parseInt(currentActivity.activityOnAssetInstanceId);
                //Operation with operation type "Modifica stato attività intervento" have a special callback in outboxManager
                outboxManager.addOperation(
                    activityUpdate,
                    "/ws/updateActivityOnAssetInstance.php",
                    "Modifica stato attività intervento",
                    null,
                    currentActivity.rowId,
                    function(rowId) {
                        //Used to update existing operations instead of creating new ones
                        currentActivity.rowId = rowId;
                    }
                );
            }
            else {
                activityUpdate.activityOnTicketKey = activityOnTicketKey;
                activityUpdate.ticketId = parseInt(currentActivity.ticketId);
                //Operation with operation type "Modifica stato ticket durante intervento" have a special callback in outboxManager
                outboxManager.addOperation(
                    activityUpdate,
                    "/ws/updateActivityOnTicket.php",
                    "Modifica stato ticket durante intervento",
                    null,
                    currentActivity.rowId,
                    function(rowId){
                        //Used to update existing operations instead of creating new ones
                        currentActivity.rowId=rowId;
                    }
                );
            }
        }
    }
    closePopup();
}

//TODO: refactoring this mess! (code duplicated for asset instances and tickets)
function registerCommentOnActivityOnAssetInstanceExecution() {
    var assetInstanceKey = document.getElementById("comment-asset-instance-key").value;
    var activityOnAssetInstanceKey = document.getElementById("comment-activity-on-asset-instance-key").value;
    var activityOnTicketKey = document.getElementById("comment-activity-on-ticket-key").value;
    var newNotes = document.getElementById("activity-on-asset-instance-comment").value;
    var currentActivity;
    var activityUpdate;
    var commentButton;
    var activityContainer;
    var now = new Date();
    //Outcome not ok, there must be a at least a two characters note
    if(activityOnAssetInstanceKey !== "") { //activity on asset instance
        currentActivity = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey];
    }
    else {
        currentActivity = assetInstancesInExecution[assetInstanceKey].relatedTickets[activityOnTicketKey];
    }

    if(currentActivity.outcomeOk===false) {
        if(newNotes.length<2) {
            errorToast("È stata indicata un'attività con esito negativo, in questo caso le note sono obbligatorie");
        }
        else {
            closePopup();
        }
    }
    else {
        closePopup();
    }

    currentActivity.notes = newNotes;
    currentActivity.completionDatetime = now.toISOString();
    //mark that the activity has changes that need to be sent to the server
    currentActivity.sentToServer = false;

    //Activity on asset instance
    if(activityOnAssetInstanceKey !== "") {
        activityContainer = document.getElementById("intervention-activity-on-asset-entry-" + assetInstanceKey.toString() + "-" + activityOnAssetInstanceKey.toString());
        var activityContainerMobile = document.getElementById("m-intervention-activity-on-asset-entry-" + assetInstanceKey.toString() + "-" + activityOnAssetInstanceKey.toString());
        //desktop version
        if(activityContainer) {
            currentActivity.activityContainer = activityContainer;
            setInnerHtml(activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
            commentButton = activityContainer.querySelector(".comment-activity-on-asset-instance-execution-btn i");
            toggleCommentButtonIcon(commentButton, newNotes);
        }
        //mobile version
        if(activityContainerMobile) {
            currentActivity.activityContainerMobile = activityContainerMobile;
            setInnerHtml(activityContainerMobile.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
            commentButton = activityContainerMobile.querySelector(".comment-activity-on-asset-instance-execution-btn i");
            toggleCommentButtonIcon(commentButton, newNotes);
        }
        activityUpdate = {};
        activityUpdate.assetInstanceKey=assetInstanceKey;
        activityUpdate.activityOnAssetInstanceKey=activityOnAssetInstanceKey;
        activityUpdate.activityOnAssetInstanceId = parseInt(currentActivity.activityOnAssetInstanceId);
        activityUpdate.interventionActivityIsCompleted = (!!currentActivity.interventionActivityIsCompleted);
        activityUpdate.outcomeOk = (!!currentActivity.outcomeOk);
        activityUpdate.notes = currentActivity.notes;
        activityUpdate.completionDatetime = currentActivity.completionDatetime;
        //Operation with operation type "Modifica stato attività intervento" have a special callback in outboxManager
        outboxManager.addOperation(
            activityUpdate,
            "/ws/updateActivityOnAssetInstance.php",
            "Modifica stato attività intervento",
            null,
            currentActivity.rowId,
            function(rowId){
                //Used to update existing operations instead of creating new ones
                currentActivity.rowId=rowId;
            }
        );
    }
    //Execution of a ticket
    else {
        activityContainer = document.getElementById("ticket-in-execution-" + currentActivity.ticketId);
        if(activityContainer) {
            currentActivity.activityContainer = activityContainer;
            setInnerHtml(activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
            commentButton = activityContainer.querySelector(".comment-activity-on-asset-instance-execution-btn i");
            toggleCommentButtonIcon(commentButton, newNotes);
        }
        activityUpdate = {};
        activityUpdate.assetInstanceKey = assetInstanceKey;
        activityUpdate.activityOnTicketKey = activityOnTicketKey;
        activityUpdate.ticketId = parseInt(currentActivity.ticketId);
        activityUpdate.interventionActivityIsCompleted = (!!currentActivity.interventionActivityIsCompleted);
        activityUpdate.outcomeOk = (!!currentActivity.outcomeOk);
        activityUpdate.notes = currentActivity.notes;
        activityUpdate.completionDatetime = currentActivity.completionDatetime;
        //Operation with operation type "Modifica stato ticket durante intervento" have a special callback in outboxManager
        outboxManager.addOperation(
            activityUpdate,
            "/ws/updateActivityOnTicket.php",
            "Modifica stato ticket durante intervento",
            null,
            currentActivity.rowId,
            function(rowId){
                //Used to update existing operations instead of creating new ones
                currentActivity.rowId=rowId;
            }
        );
    }
}

function activityOnAssetInstanceEnableEditAssetField(assetInstanceKey, activityOnAssetInstanceKey, isMobileView) {
    if(!ghostClickController.isOk()){return;}
    var activityContainer = document.getElementById("intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
    var activityContainerMobile = document.getElementById("m-intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
    var currentActivityContainer;
    if(isMobileView){
        currentActivityContainer = activityContainerMobile;
    }
    else {
        currentActivityContainer = activityContainer;
    }
    var customFieldInput = currentActivityContainer.querySelector(".activity-on-asset-instance-custom-field-input");
    var assetFieldWrong,assetFieldWrongHolder;

    //mobile
    if(activityContainerMobile) {
        //Clearing input and moving value to wrongValue
        assetFieldWrong = activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-wrong");
        assetFieldWrongHolder = activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-wrong-holder");
        assetFieldWrongHolder.innerHTML = customFieldInput.value;
        dom.removeClass(assetFieldWrong,"hidden");
        activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-input").removeAttribute("disabled");
        //Recovering save check button and hiding edit button
        dom.removeClass(activityContainerMobile.querySelector(".execute-intervention-ok-radio"),"hidden");
        dom.addClass(activityContainerMobile.querySelector(".execute-intervention-edit-radio"),"hidden");
    }

    //desktop
    if(activityContainer) {
        //Clearing input and moving value to wrongValue
        assetFieldWrong = activityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong");
        assetFieldWrongHolder = activityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong-holder");
        assetFieldWrongHolder.innerHTML = customFieldInput.value;
        dom.removeClass(assetFieldWrong,"hidden");
        activityContainer.querySelector(".activity-on-asset-instance-custom-field-input").removeAttribute("disabled");
        //Recovering save check button and hiding edit button
        dom.removeClass(activityContainer.querySelector(".execute-intervention-ok-radio"),"hidden");
        dom.addClass(activityContainer.querySelector(".execute-intervention-edit-radio"),"hidden");
    }

    if(activityContainerMobile){
        activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-input").value="";
    }
    if(activityContainer) {
        activityContainer.querySelector(".activity-on-asset-instance-custom-field-input").value="";
    }
}

function toggleActivityOnAssetInstanceCheckbox(assetInstanceKey, activityOnAssetInstanceKey, state, isMobileView, skipGhostClickValidation) {
    if(!skipGhostClickValidation){
        if(!ghostClickController.isOk()){return;}
    }
    var activityContainer = document.getElementById("intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
    var activityContainerMobile = document.getElementById("m-intervention-activity-on-asset-entry-" + assetInstanceKey + "-" + activityOnAssetInstanceKey);
    var activityOnAssetIssue=document.getElementById("activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey+"-issue");
    switch(state){
        case "ok":
            document.getElementById("activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey+"-ok").setAttribute("data-checked","1");
            if(activityOnAssetIssue!=null){activityOnAssetIssue.setAttribute("data-checked","0");}
            break;
        case "issue":
            if(activityOnAssetIssue!=null){activityOnAssetIssue.setAttribute("data-checked","1");}
            document.getElementById("activity-on-asset-"+assetInstanceKey+"-"+activityOnAssetInstanceKey+"-ok").setAttribute("data-checked","0");
            break;
    }
    var activityUpdate = {};
    var currentActivityContainer;
    if(isMobileView){
        currentActivityContainer = activityContainerMobile;
    }
    else {
        currentActivityContainer = activityContainer;
    }
    var customFieldInput = currentActivityContainer.querySelector(".activity-on-asset-instance-custom-field-input");
    if(customFieldInput) {
        activityUpdate.assetFieldWrongValue = currentActivityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong-holder").innerHTML;
        activityUpdate.assetFieldValue = customFieldInput.value;
        activityUpdate.assetFieldValueId = customFieldInput.getAttribute("data-asset-field-value-id");
        if(activityUpdate.assetFieldValueId==""){
            activityUpdate.assetFieldValueId=null;
        }
        activityUpdate.assetFieldName = customFieldInput.getAttribute("data-asset-field-name");
        activityUpdate.assetFieldTypeId = customFieldInput.getAttribute("data-asset-field-type-id");

        if(activityOnAssetInstanceKey !== "") { //activity on asset instance
            assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey].assetFieldValue = activityUpdate.assetFieldValue;
            assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey].assetFieldWrongValue = activityUpdate.assetFieldWrongValue;
        }

        var dataType = customFieldInput.getAttribute("data-field-type");
        var gePrecedent = customFieldInput.getAttribute("data-asset-field-ge-precedent");
        var geZero = customFieldInput.getAttribute("data-asset-field-ge-zero");
        var previousValue = customFieldInput.getAttribute("data-asset-field-previous-value");

        if(dataType!="text" && state=="ok" && Number.isNaN(Number.parseFloat(activityUpdate.assetFieldValue))) {
            errorToast("Valore rilevato mancante");
            return;
        }

        if(gePrecedent && previousValue!="" && previousValue!=null && previousValue>activityUpdate.assetFieldValue){
            errorPopup("Il valore inserito risulta minore di quello precedentemente rilevato");
        }

        if(geZero && activityUpdate.assetFieldValue<0){
            errorPopup("Il valore inserito dovrebbe essere un numero positivo");
        }

        if(activityContainerMobile) {
            if(activityUpdate.assetFieldWrongValue&&activityUpdate.assetFieldWrongValue.trim()!=""){
                dom.removeClass(activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-wrong"),"hidden");
            }
            else{
                dom.addClass(activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-wrong"),"hidden");
            }
            activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-input").value = activityUpdate.assetFieldValue;
            activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-wrong-holder").innerHTML = currentActivityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong-holder").innerHTML;
            activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-input").disabled="disabled";
            //Showing edit button and hiding check button
            dom.addClass(activityContainerMobile.querySelector(".execute-intervention-ok-radio"),"hidden");
            dom.removeClass(activityContainerMobile.querySelector(".execute-intervention-edit-radio"),"hidden");
            if(activityUpdate.assetFieldValue!=null && activityUpdate.assetFieldValue!="") {
                dom.removeClass(activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-prev"),"hidden");
            }
            else {
                dom.addClass(activityContainerMobile.querySelector(".activity-on-asset-instance-custom-field-prev"),"hidden");
            }
        }

        if(activityContainer) {
            if(activityUpdate.assetFieldWrongValue&&activityUpdate.assetFieldWrongValue.trim()!=""){
                dom.removeClass(activityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong"),"hidden");
            }
            else{
                dom.addClass(activityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong"),"hidden");
            }
            activityContainer.querySelector(".activity-on-asset-instance-custom-field-input").value = activityUpdate.assetFieldValue;
            activityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong-holder").innerHTML = currentActivityContainer.querySelector(".activity-on-asset-instance-custom-field-wrong-holder").innerHTML;
            activityContainer.querySelector(".activity-on-asset-instance-custom-field-input").disabled="disabled";
            //Showing edit button and hiding check button
            dom.addClass(activityContainer.querySelector(".execute-intervention-ok-radio"),"hidden");
            dom.removeClass(activityContainer.querySelector(".execute-intervention-edit-radio"),"hidden");
            if(activityUpdate.assetFieldValue!=null && activityUpdate.assetFieldValue!="") {
                dom.removeClass(activityContainer.querySelector(".activity-on-asset-instance-custom-field-prev"),"hidden");
            }
            else {
                dom.addClass(activityContainer.querySelector(".activity-on-asset-instance-custom-field-prev"),"hidden");
            }
        }

    }
    else {
        activityUpdate.assetFieldWrongValue=null;
        activityUpdate.assetFieldValue=null;
        activityUpdate.assetFieldValueId=null;
        activityUpdate.assetFieldName=null;
        activityUpdate.assetFieldTypeId=null;
    }

    var currentActivity = assetInstancesInExecution[assetInstanceKey].activitiesOnAssetInstance[activityOnAssetInstanceKey];
    var completed = 0;
    var completedCounter = document.getElementById("completed-activities-on-asset-counter");
    if(!currentActivity.interventionActivityIsCompleted){
        completedCounter.innerHTML = parseInt(completedCounter.innerHTML) + 1;
        assetInstancesInExecution[assetInstanceKey].completedActivitiesCounter++;
        currentActivity.interventionActivityIsCompleted = true;
    }
    if((parseInt(completedCounter.innerHTML)) >= assetInstancesInExecution[assetInstanceKey].totalActivitiesNumber) {
        dom.addClass(document.getElementById("a-" + assetInstancesInExecution[assetInstanceKey].assetInstanceId), "completed");
        assetInstancesInExecution[assetInstanceKey].completedCounter++;
    }
    for(var i = 0; i < assetInstancesInExecution.length; i++) {
        if(assetInstancesInExecution[i].completedActivitiesCounter == assetInstancesInExecution[i].totalActivitiesNumber) {
            completed++;
        }
    }
    var activityOnAssetsProgressCounter = document.getElementById("activity-on-assets-progress-counter");
    if(activityOnAssetsProgressCounter) {
        setInnerHtml(activityOnAssetsProgressCounter, (completed + " di " + assetInstancesInExecution.length));
    }
    var activityOnAssetsProgressCounters=document.querySelectorAll(".activity-on-assets-progress-counter");
    activityOnAssetsProgressCounters.forEach(function(activityOnAssetsProgressCounter) {
        setInnerHtml(activityOnAssetsProgressCounter,completed + " di " + assetInstancesInExecution.length);
    });
    var assetCompletedButton=document.getElementById("executing-intervention-asset-completed-button");
    if(assetCompletedButton!=null) {
        //TODO: stop using onclick on other occurences of assetCompletedButton
        assetCompletedButton.onclick=null;
        if(completed === assetInstancesInExecution.length) {
            unbindTapListenerOnElement(assetCompletedButton);
            defineTapListenerOnElement(assetCompletedButton,function(){popup("executing-intervention-last-asset-completed", false, true, false);});
        }
        else {
            unbindTapListenerOnElement(assetCompletedButton);
            defineTapListenerOnElement(assetCompletedButton,function(){popup("executing-intervention-asset-completed", false, true, false);});
        }
    }
    var now = new Date();
    currentActivity.completionDatetime = now.toISOString();
    if(state === "ok") {
        currentActivity.outcomeOk = true;
        if(activityContainer) {
            dom.removeClass(activityContainer, "activity-entry-issue");
            dom.addClass(activityContainer, "activity-entry-ok");
        }
        if(activityContainerMobile) {
            dom.removeClass(activityContainerMobile, "activity-entry-issue");
            dom.addClass(activityContainerMobile, "activity-entry-ok");
        }
    }
    else {
        currentActivity.outcomeOk = false;
        if(activityContainer) {
            dom.removeClass(activityContainer, "activity-entry-ok");
            dom.addClass(activityContainer, "activity-entry-issue");
        }
        if(activityContainerMobile) {
            dom.removeClass(activityContainerMobile, "activity-entry-ok");
            dom.addClass(activityContainerMobile, "activity-entry-issue");
        }
        commentActivityOnAssetInstanceExecution(assetInstanceKey, activityOnAssetInstanceKey);
    }
    if(activityContainer) {
        currentActivity.activityContainer = activityContainer;
        setInnerHtml(activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
    }
    if(activityContainerMobile) {
        currentActivity.activityContainerMobile = activityContainerMobile;
        setInnerHtml(activityContainerMobile.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
    }

    //mark that the activity has changes that need to be sent to the server
    currentActivity.sentToServer = false;

    activityUpdate.assetInstanceKey=assetInstanceKey;
    activityUpdate.activityOnAssetInstanceKey=activityOnAssetInstanceKey;
    activityUpdate.activityOnAssetInstanceId = parseInt(currentActivity.activityOnAssetInstanceId);
    activityUpdate.interventionActivityIsCompleted = (!!currentActivity.interventionActivityIsCompleted);
    activityUpdate.outcomeOk = (!!currentActivity.outcomeOk);
    activityUpdate.notes = currentActivity.notes;
    activityUpdate.completionDatetime = currentActivity.completionDatetime;
    //Operation with operation type "Modifica stato attività intervento" have a special callback in outboxManager
    outboxManager.addOperation(
        activityUpdate,
        "/ws/updateActivityOnAssetInstance.php",
        "Modifica stato attività intervento",
        null,
        currentActivity.rowId,
        function(rowId){
            //Used to update existing operations instead of creating new ones
            currentActivity.rowId=rowId;
        }
    );
}

function toggleActivityOnTicketCheckbox(assetInstanceKey, ticketKey, state, ticketId) {
    switch(state) {
        case "ok":
            document.getElementById("activity-on-ticket-"+ticketId+"-ok").setAttribute("data-checked","1");
            document.getElementById("activity-on-ticket-"+ticketId+"-issue").setAttribute("data-checked","0");
            break;
        case "issue":
            document.getElementById("activity-on-ticket-"+ticketId+"-ok").setAttribute("data-checked","0");
            document.getElementById("activity-on-ticket-"+ticketId+"-issue").setAttribute("data-checked","1");
            break;
        case null:
            document.getElementById("activity-on-ticket-"+ticketId+"-ok").setAttribute("data-checked","0");
            document.getElementById("activity-on-ticket-"+ticketId+"-issue").setAttribute("data-checked","0");
            break;
    }
    var currentActivity = assetInstancesInExecution[assetInstanceKey].relatedTickets[ticketKey];
    var activityContainer = document.getElementById("ticket-in-execution-" + currentActivity.ticketId);
    currentActivity.interventionActivityIsCompleted = true;
    var now = new Date();
    if(state === "ok") {
        currentActivity.outcomeOk = true;
        if(activityContainer) {
            dom.removeClass(activityContainer, "activity-entry-issue");
            dom.addClass(activityContainer, "activity-entry-ok");
        }
        currentActivity.completionDatetime = now.toISOString();
    }
    if(state==="issue") {
        currentActivity.outcomeOk = false;
        if(activityContainer) {
            dom.removeClass(activityContainer, "activity-entry-ok");
            dom.addClass(activityContainer, "activity-entry-issue");
        }
        commentActivityOnTicketInExecution(assetInstanceKey, ticketKey);
        currentActivity.completionDatetime = now.toISOString();
    }
    if(state===null) {
        currentActivity.outcomeOk = false;
        if(activityContainer) {
            dom.removeClass(activityContainer, "activity-entry-ok");
            dom.removeClass(activityContainer, "activity-entry-issue");
        }
        currentActivity.completionDatetime=null;
    }
    if(activityContainer) {
        currentActivity.activityContainer = activityContainer;
        setInnerHtml(activityContainer.querySelector(".sent-to-server-icon"), "<i class='fa fa-clock-o' aria-hidden='true'></i>");
    }
    //mark that the activity has changes that need to be sent to the server
    currentActivity.sentToServer = false;
    var activityUpdate = {};
    activityUpdate.assetInstanceKey = assetInstanceKey;
    activityUpdate.activityOnTicketKey = ticketKey;
    activityUpdate.ticketId = parseInt(currentActivity.ticketId);
    activityUpdate.interventionActivityIsCompleted = (!!currentActivity.interventionActivityIsCompleted);
    activityUpdate.outcomeOk = (!!currentActivity.outcomeOk);
    activityUpdate.notes = currentActivity.notes;
    activityUpdate.completionDatetime = currentActivity.completionDatetime;
    //Operation with operation type "Modifica stato ticket durante intervento" have a special callback in outboxManager
    outboxManager.addOperation(
        activityUpdate,
        "/ws/updateActivityOnTicket.php",
        "Modifica stato ticket durante intervento",
        null,
        currentActivity.rowId,
        function(rowId){
            //Used to update existing operations instead of creating new ones
            currentActivity.rowId=rowId;
        }
    );
}

function closeInterventionCommon(plannedEndDate,containsTicket,timeString,canChangeDateTime) {
    dom.removeClass(document.getElementById("close-intervention-correction-ended-button"),"hidden");
    dom.addClass(document.getElementById("close-intervention-button"),"hidden");
    var workProsecutionDateContainer = document.getElementById("end-intervention-work-prosecution-date-container");
    var workProsecutionDate = document.getElementById("end-intervention-work-prosecution-date");
    workProsecutionDate.removeAttribute("required");
    dom.addClass(workProsecutionDateContainer,"hidden");
    var employeeWorkTimeHtml="";
    employeeWorkTimeHtml="<h3>Ore di lavoro dei singoli operatori</h3>";
    var shouldAddCurrentEmployee=true;
    if(loggedInUserInfo){
        employeeWorkTimeHtml+=" <div class='inline-form-component'>"+loggedInUserInfo.name+" "+loggedInUserInfo.surname+"<br><button type='button' id='end-intervention-work-time-employee-"+loggedInUserInfo.employeeId+"' onclick='ui.pickerPopupButton.open(this);'></button></div>";
    }
    interventionManager.currentInterventionData.executionTeam.forEach(function(employee){
        if(loggedInUserInfo.employeeId!==employee.employeeId){ //Already added before
           employeeWorkTimeHtml+=" <div class='inline-form-component'>"+employee.employeeName+" "+employee.employeeSurname+"<br><button type='button' id='end-intervention-work-time-employee-"+employee.employeeId+"' onclick='ui.pickerPopupButton.open(this);'></button></div>";
        }
        else{
            shouldAddCurrentEmployee=false;
        }
    });
    if(shouldAddCurrentEmployee){
        //Keeping trace of the fact that I added the current logged in employee to the intervention's execution team
        var newEmployee={};
        newEmployee.employeeId=loggedInUserInfo.employeeId;
        newEmployee.employeeName=loggedInUserInfo.name;
        newEmployee.employeeSurname=loggedInUserInfo.surname;
        interventionManager.currentInterventionData.executionTeam.push(newEmployee);
    }
    localPhotoUploadsId = 0;
    photoUploads = [];
    setInnerHtml(document.getElementById("intervention-photo-thumbnails"),"");
    //Must use innerHTML here to have a synchronous DOM change
    document.getElementById("end-intervention-executors-work-times").innerHTML=employeeWorkTimeHtml;
    //Initializing pickers
    interventionManager.currentInterventionData.executionTeam.forEach(function(employee) {
        ui.pickerPopupButton.initialize({
            id:"end-intervention-work-time-employee-"+employee.employeeId,
            components:ui.pickerPopupButton.presets.durationPickerComponents
        });
    });
    if(canChangeDateTime){
        document.getElementById("end-intervention-end-date").removeAttribute("disabled");
        document.getElementById("end-intervention-end-time").removeAttribute("disabled");
    }
    else{
        document.getElementById("end-intervention-end-date").setAttribute("disabled","disabled");
        document.getElementById("end-intervention-end-time").setAttribute("disabled","disabled");
    }
    ui.pickerPopupButton.setValue(
        "end-intervention-end-time",
        [
            (timeString.split(":")[0]),
            ":",
            (timeString.split(":")[1])
        ]
    );
    popup("end-intervention", false, false, false);
    setInnerHtml(document.getElementById("end-intervention-materials-container"), "");
    document.getElementById("end-intervention-is-night-time").setAttribute("data-checked","0");
    document.getElementById("end-intervention-is-call-right").setAttribute("data-checked","0");
    document.getElementById("end-intervention-is-urgent").setAttribute("data-checked","0");
    if(containsTicket) {
        endInterventionPopup.showTicketDataForm();
    }
    else {
        endInterventionPopup.hideTicketDataForm();
    }
    //geolocalizeDevice();
    scroll.refreshPopupById("end-intervention", false);
}

function markInterventionAsEnded() {
    calCurrentDate = new Date();
    document.getElementById("end-intervention-end-date").value = ((calCurrentDate.getDate() < 10) ? ("0" + calCurrentDate.getDate()) : (calCurrentDate.getDate())) + "/" + ((calCurrentDate.getMonth() < 9) ? ("0" + (calCurrentDate.getMonth() + 1)) : (calCurrentDate.getMonth() + 1)) + "/" + calCurrentDate.getFullYear();
    var plannedEndDate = new Date(lastOpenedIntervention.isoPlannedEndDate);
    var timeString = calCurrentDate.getHours()+":"+ calCurrentDate.getMinutes();
    closeInterventionCommon(plannedEndDate,interventionInExecutionContainsTickets,timeString,false);
    var completedAssets = 0;
    for(var i = 0; i < assetInstancesInExecution.length; i++) {
        if(assetInstancesInExecution[i].completedActivitiesCounter === assetInstancesInExecution[i].totalActivitiesNumber) {
            completedAssets++;
        }
    }

    //Checking if ticket activities not related to assets are all executed
    var ticketActivitiesNotExecuted = false;
    document.querySelectorAll(".ticket-in-execution-entry").forEach(function(activityEntry){
        if(!(
            dom.hasClass(activityEntry,"activity-entry-ok")
            || dom.hasClass(activityEntry,"activity-entry-issue")
        )){
            ticketActivitiesNotExecuted = true;
        }
    });

    var tempHtml = "";
    if(completedAssets !== assetInstancesInExecution.length || ticketActivitiesNotExecuted) {
        warning("<strong>ATTENZIONE</strong>: si sta chiudendo un intervento con attività non ancora eseguite!<br>L'esito <strong>Completato correttamente</strong> non sarà disponibile.");
        for(i = 0; i < outcomes.length; i++) {
            if(outcomes[i].outcomeId !== 1) {
                tempHtml += "<option value='" + outcomes[i].outcomeId + "'>" + outcomes[i].name + "</option>";
            }
        }
    }
    else {
        for(i = 0; i < outcomes.length; i++) {
            tempHtml += "<option value='" + outcomes[i].outcomeId + "'>" + outcomes[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("end-intervention-outcome").innerHTML = tempHtml;
    scroll.refreshPopupById("end-intervention", false);
}

function interventionOutcomeChanged(outcome) {
    outcome=parseInt(outcome);
    var workProsecutionDateContainer = document.getElementById("end-intervention-work-prosecution-date-container");
    var workProsecutionDate = document.getElementById("end-intervention-work-prosecution-date");
    switch(outcome) {
        case MORE_WORK_REQUIRED_OUTCOME:
            document.getElementById("end-intervention-notes").required="required";
            workProsecutionDate.required="required";
            dom.removeClass(workProsecutionDateContainer,"hidden");
            break;
        case THIRD_PARTY_INTERVENTION_REQUIRED_OUTCOME:
            document.getElementById("end-intervention-notes").required="required";
            workProsecutionDate.removeAttribute("required");
            dom.addClass(workProsecutionDateContainer,"hidden");
            break;
        case INTERVENTION_NOT_POSSIBLE:
            document.getElementById("end-intervention-notes").required="required";
            workProsecutionDate.removeAttribute("required");
            dom.addClass(workProsecutionDateContainer,"hidden");
            break;
        default:
            document.getElementById("end-intervention-notes").removeAttribute("required");
            workProsecutionDate.removeAttribute("required");
            dom.addClass(workProsecutionDateContainer,"hidden");
            break;
    }
    scroll.refreshPopupById("end-intervention", false);
}

function registerCloseInterventionData(retryCount) {
    var uploading = document.querySelectorAll("#end-intervention-popup .thumbnail-container.uploading");
    if(uploading.length>0){
        errorToast("Attendere il caricamento delle immagini");
        return;
    }
    //Linking photos to intervention
    photoUploads.forEach(function(photoUpload, photoIndex) {
        if(photoUpload.uploadTarget === "intervention" && photoUpload.targetId === null) {
            photoUpload.targetId = ((selectedIntervention == null) ? null : parseInt(selectedIntervention));
            //photos were already uploaded to server
            if(photoUpload.photoId !== null) {
                attachPhotoToEntity(photoIndex);
            }
        }
    });
    var closedIntervention = {};
    closedIntervention.interventionId = ((selectedIntervention == null) ? null : parseInt(selectedIntervention));
    closedIntervention.endDate = document.getElementById("end-intervention-end-date").value.split("/").reverse().join("-");
    if(!closedIntervention.endDate) {
        delete closedIntervention.endDate;
    }
    closedIntervention.endTime = ui.pickerPopupButton.getValue("end-intervention-end-time");
    if(!closedIntervention.endTime) {
        delete closedIntervention.endTime;
    }
    closedIntervention.outcome = document.getElementById("end-intervention-outcome").value;
    if(closedIntervention.outcome === "") {
        delete closedIntervention.outcome;
    }
    else {
        closedIntervention.outcome = parseInt(closedIntervention.outcome);
    }
    if(closedIntervention.outcome == MORE_WORK_REQUIRED_OUTCOME) {
        //TODO: recover original formattable toString pikaday function that I had broke!
        closedIntervention.workProsecutionDate = pikadays.endInterventionWorkProsecution.toString().split("/").reverse().join("-");
    }
    closedIntervention.notes = document.getElementById("end-intervention-notes").value;
    if(closedIntervention.notes === "") {
        delete closedIntervention.notes;
    }

    if(assetInstancesInExecution){
        closedIntervention.activitiesData = assetInstancesInExecution;
    }

    closedIntervention.containsTicket = (document.getElementById("close-intervention-containing-ticket").value=="1");
    closedIntervention.employees = [];
    var abort=false;
    interventionManager.currentInterventionData.executionTeam.forEach(function(employee) {
        var employeeData={};
        employeeData.employeeName=employee.employeeName;
        employeeData.employeeSurname=employee.employeeSurname;
        employeeData.employeeId=parseInt(employee.employeeId);
        employeeData.workTime=ui.pickerPopupButton.getValue("end-intervention-work-time-employee-"+employee.employeeId);
        if((employeeData.workTime==="0:00" || employeeData.workTime==="00:00" || employeeData.workTime==="0:0")
            && closedIntervention.containsTicket && retryCount===1) {
            if(!abort){
                popup("confirm-close-intervention", false, false, false);
                abort=true;
            }
        }
        closedIntervention.employees.push(employeeData);
    });
    if(abort){
        return;
    }
    closedIntervention.transferDistance = document.getElementById("end-intervention-transfer-distance").value;
    if(closedIntervention.transferDistance==""){
        closedIntervention.transferDistance=0;
    }
    closedIntervention.fixedTransfer = 0;
    closedIntervention.transferHours = ui.pickerPopupButton.getValue("end-intervention-transfer-time").split(":")[0];
    closedIntervention.transferMinutes = ui.pickerPopupButton.getValue("end-intervention-transfer-time").split(":")[1];
    closedIntervention.workHours = ui.pickerPopupButton.getValue("end-intervention-work-time").split(":")[0];
    closedIntervention.workMinutes = ui.pickerPopupButton.getValue("end-intervention-work-time").split(":")[1];
    closedIntervention.isNightTime = document.getElementById("end-intervention-is-night-time").getAttribute("data-checked")==="1";
    closedIntervention.isCallRight = document.getElementById("end-intervention-is-call-right").getAttribute("data-checked")==="1";
    closedIntervention.isUrgent = document.getElementById("end-intervention-is-urgent").getAttribute("data-checked")==="1";
    closedIntervention.materials = [];
    document.querySelectorAll(".end-intervention-materials-row").forEach(function(materialRow) {
        var material = {};
        material.quantity = parseInt(materialRow.querySelector(".end-intervention-material-quantity").value);
        material.unitOfMeasure = materialRow.querySelector(".end-intervention-material-unit-of-measure").value;
        material.description = materialRow.querySelector(".end-intervention-material-description").value;
        closedIntervention.materials.push(material);
    });
    verifyInterventionDataPopup.init(closedIntervention);
    outboxManager.addOperation(closedIntervention,"/ws/closeIntervention.php","Chiusura intervento",function(){
        successToast("Dati di chiusura intervento salvati");
    });
}

function createAssetLoadBuildings() {
    var selectedBuildingGroup = document.getElementById("add-asset-buildings-group").value;
    var tempHtml = "";
    var matchingBuildings = 0;
    for(var i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingGroup) {
            tempHtml += "<option value='" + buildings[i].buildingId + "'>" + buildings[i].name + "</option>";
            matchingBuildings++;
        }
    }
    switch(matchingBuildings) {
        case 0:
            tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Nessun edificio presente --</option>";
            document.getElementById("add-asset-building").innerHTML = tempHtml;
            document.getElementById("add-asset-site").value = "";
            break;
        case 1:
            //TODO: riga sotto temporanea, fixare
            tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
            document.getElementById("add-asset-building").innerHTML = tempHtml;
            document.getElementById("add-asset-site").value = "";
            //createAssetLoadSites();
            break;
        default: //matchingBuildings>1
            tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
            document.getElementById("add-asset-building").innerHTML = tempHtml;
            document.getElementById("add-asset-site").value = "";
            break;
    }

}

function createAssetLoadSites() {
    var selectedBuilding = document.getElementById("add-asset-building").value;
    var tempHtml = "";
    for(var i = 0; i < sites.length; i++) {
        if(sites[i].buildingId == selectedBuilding) {
            tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-asset-site").innerHTML = tempHtml;
}

function createAssetLoadFloors(selectedSiteId) {
    var tempHtml = "";
    for(var i = 0; i < floors.length; i++) {
        if(floors[i].siteId == selectedSiteId) {
            tempHtml += "<option value='" + floors[i].floorId + "'>" + floors[i].floorName + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected'>-- Nessuno --</option>";
    setInnerHtml(document.getElementById("add-asset-floor"), tempHtml);
}

function createAssetForQrLoadFloors(selectedSiteId) {
    var tempHtml = "";
    for(var i = 0; i < floors.length; i++) {
        if(floors[i].siteId == selectedSiteId) {
            tempHtml += "<option value='" + floors[i].floorId + "'>" + floors[i].floorName + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected'>-- Nessuno --</option>";
    setInnerHtml(document.getElementById("add-asset-for-qr-code-floor"), tempHtml);
}

function createAssetLoadSubcategories(calledBySelect) {
    var subcategoryRowId = calledBySelect.parentNode.parentNode.id.replace("add-asset-form-category-row-", "");
    var selectedCategory = document.querySelector("#add-asset-form-category-row-" + subcategoryRowId + " .add-asset-category").value;
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategory && subcategories[i].deletion==null) {
            tempHtml += "<option value='" + subcategories[i].subcategoryId + "'>" + subcategories[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.querySelector("#add-asset-form-category-row-" + subcategoryRowId + " .add-asset-subcategory").innerHTML = tempHtml;
}

function editAssetLoadSubcategories(calledBySelect,callback) {
    var subcategoryRowId = calledBySelect.parentNode.parentNode.id.replace("edit-asset-form-category-row-", "");
    var selectedCategory = document.querySelector("#edit-asset-form-category-row-" + subcategoryRowId + " .edit-asset-category").value;
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategory && subcategories[i].deletion==null) {
            tempHtml += "<option value='" + subcategories[i].subcategoryId + "'>" + subcategories[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.querySelector("#edit-asset-form-category-row-" + subcategoryRowId + " .edit-asset-subcategory").innerHTML = tempHtml;
    if(typeof callback === "function"){
        callback();
    }
}

function addSubcategoryRowToAssetFromCreationPopup() {
    var totalCounter = parseInt(document.getElementById("add-asset-form-category-rows-counter").value) + 1;
    document.getElementById("add-asset-form-category-rows-counter").value = totalCounter;
    var categoryHtmlOptions = "";
    for(var i = 0; i < categories.length; i++) {
        categoryHtmlOptions += "<option value='" + categories[i].categoryId + "'>" + categories[i].name + "</option>";
    }
    categoryHtmlOptions += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    var element = document.createElement("div");
    element.id = "add-asset-form-category-row-" + totalCounter;
    element.className = "asset-form-category-row";
    element.innerHTML = "" +
        "    <div class='inline-form-component'>" +
        "        Categoria<br>" +
        "        <select class='add-asset-category' onchange='createAssetLoadSubcategories(this);' required>" + categoryHtmlOptions + "</select>" +
        "    </div>" +
        "    <div class='inline-form-component'>" +
        "        Sottocategoria<br>" +
        "        <select class='add-asset-subcategory' required><option value='' selected='selected'>-- Selezionare categoria --</option></select>" +
        "    </div>" +
        "    <div class='inline-form-component s-inline-form-component'>" +
        "    <br><button type='button' class='select-side-button' onclick='createAssetRemoveSubcategoryRow(this);'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi</button>" +
        "    </div>";
    document.getElementById("add-asset-form-category-rows").appendChild(element);
    scroll.refreshPopupById("add-asset", false);
}

function addSubcategoryRowToAssetFromEditPopup() {
    var totalCounter = parseInt(document.getElementById("edit-asset-form-category-rows-counter").value) + 1;
    document.getElementById("edit-asset-form-category-rows-counter").value = totalCounter;
    var categoryHtmlOptions = "";
    for(var i = 0; i < categories.length; i++) {
        categoryHtmlOptions += "<option value='" + categories[i].categoryId + "'>" + categories[i].name + "</option>";
    }
    categoryHtmlOptions += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    var element = document.createElement("div");
    element.id = "edit-asset-form-category-row-" + totalCounter;
    element.className = "asset-form-category-row";
    element.innerHTML = "" +
        "    <div class='inline-form-component'>" +
        "        Categoria<br>" +
        "        <select class='edit-asset-category' onchange='editAssetLoadSubcategories(this,null);' required>" + categoryHtmlOptions + "</select>" +
        "    </div>" +
        "    <div class='inline-form-component'>" +
        "        Sottocategoria<br>" +
        "        <select class='edit-asset-subcategory' required><option value='' selected='selected'>-- Selezionare categoria --</option></select>" +
        "    </div>" +
        "    <div class='inline-form-component s-inline-form-component'>" +
        "    <br><button type='button' class='select-side-button' onclick='editAssetRemoveSubcategoryRow(this);'><i class='fa fa-trash' aria-hidden='true'></i> Rimuovi</button>" +
        "    </div>";
    document.getElementById("edit-asset-form-category-rows").appendChild(element);
    scroll.refreshPopupById("edit-asset-instance", false);
}

//todo: merge following two functions:
function createAssetRemoveSubcategoryRow(calledByButton) {
    var elementCounter = parseInt(calledByButton.parentNode.parentNode.id.replace("add-asset-form-category-row-", ""));
    var totalCounter = parseInt(document.getElementById("add-asset-form-category-rows-counter").value);
    document.getElementById("add-asset-form-category-row-" + elementCounter).parentNode.removeChild(document.getElementById("add-asset-form-category-row-" + elementCounter));
    document.getElementById("add-asset-form-category-rows-counter").value = totalCounter - 1;
    for(var i = parseInt(elementCounter); i < totalCounter; i++) {
        document.getElementById("add-asset-form-category-row-" + (i + 1)).id = "add-asset-form-category-row-" + i;
    }
    scroll.refreshPopupById("add-asset", false);
}
function editAssetRemoveSubcategoryRow(calledByButton) {
    var elementCounter = parseInt(calledByButton.parentNode.parentNode.id.replace("edit-asset-form-category-row-", ""));
    var totalCounter = parseInt(document.getElementById("edit-asset-form-category-rows-counter").value);
    document.getElementById("edit-asset-form-category-row-" + elementCounter).parentNode.removeChild(document.getElementById("edit-asset-form-category-row-" + elementCounter));
    document.getElementById("edit-asset-form-category-rows-counter").value = totalCounter - 1;
    for(var i = parseInt(elementCounter); i < totalCounter; i++) {
        document.getElementById("edit-asset-form-category-row-" + (i + 1)).id = "edit-asset-form-category-row-" + i;
    }
    scroll.refreshPopupById("edit-asset-instance", false);
}

function addAssetForQrCodeBuildingChanged() {
    var selectedBuilding = document.getElementById("add-asset-for-qr-code-building").value;
    var tempHtml = "";
    for(var i = 0; i < sites.length; i++) {
        if(sites[i].buildingId == selectedBuilding) {
            tempHtml += "<option value='" + sites[i].siteId + "'>" + sites[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-asset-for-qr-code-site").innerHTML = tempHtml;
}

function addAssetForQrCodeBuildingGroupChanged() {
    var selectedBuildingGroup = document.getElementById("add-asset-for-qr-code-buildings-group").value;
    var tempHtml = "";
    for(var i = 0; i < buildings.length; i++) {
        if(buildings[i].buildingsGroup == selectedBuildingGroup) {
            tempHtml += "<option value='" + buildings[i].buildingId + "'>" + buildings[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-asset-for-qr-code-building").innerHTML = tempHtml;
    document.getElementById("add-asset-for-qr-code-site").value = "";
}

function addAssetForQrCodeCategoryChanged() {
    var selectedCategory = document.getElementById("add-asset-for-qr-code-category").value;
    var tempHtml = "";
    for(var i = 0; i < subcategories.length; i++) {
        if(subcategories[i].categoryId == selectedCategory && subcategories[i].deletion==null) {
            tempHtml += "<option value='" + subcategories[i].subcategoryId + "'>" + subcategories[i].name + "</option>";
        }
    }
    tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
    document.getElementById("add-asset-for-qr-code-subcategory").innerHTML = tempHtml;
}

function addAssetForQrSubcategoryChanged() {
    var selectedSubcategory = document.getElementById("add-asset-for-qr-code-subcategory").value;

    //TODO: gestire umanamente questa cosa
    if(selectedSubcategory === 17)//Illuminazione emergenza
    {
        var tempHtml = "";
        tempHtml = "<option value='No bus'>No bus</option>";
        tempHtml += "<option value='SA - sempre accesa'>SA - sempre accesa</option>";
        tempHtml += "<option value='SE - solo emergenza'>SE - solo emergenza</option>";
        tempHtml += "<option value='Segnalazione esodo'>Segnalazione esodo</option>";
        tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
        document.getElementById("add-asset-for-qr-code-type-select").innerHTML = tempHtml;
        dom.removeClass(document.getElementById("add-asset-for-qr-code-type-select"), "hidden");
        dom.addClass(document.getElementById("add-asset-for-qr-code-type-text"), "hidden");
        tempHtml = "<option value='Bandiera SA Smartexit'>Bandiera SA Smartexit</option>";
        tempHtml += "<option value='SE parete Exiway Smartled'>SE parete Exiway Smartled</option>";
        tempHtml += "<option value='SA parete Exiway Smartled'>SA parete Exiway Smartled</option>";
        tempHtml += "<option value='' selected='selected' disabled='disabled'>-- Selezionare --</option>";
        document.getElementById("add-asset-for-qr-code-model-select").innerHTML = tempHtml;
        dom.removeClass(document.getElementById("add-asset-for-qr-code-model-select"), "hidden");
        dom.addClass(document.getElementById("add-asset-for-qr-code-model-text"), "hidden");
    }
    else {
        dom.addClass(document.getElementById("add-asset-for-qr-code-type-select"), "hidden");
        dom.removeClass(document.getElementById("add-asset-for-qr-code-type-text"), "hidden");
        dom.addClass(document.getElementById("add-asset-for-qr-code-model-select"), "hidden");
        dom.removeClass(document.getElementById("add-asset-for-qr-code-model-text"), "hidden");
    }
}

function togglePseudoCheckbox(callerButton){
    var checkbox = callerButton.querySelector(".pseudo-checkbox");
    var newValue = checkbox.getAttribute("data-checked")==="1"?"0":"1";
    checkbox.setAttribute("data-checked",newValue);
}

function setPseudoCheckbox(id,value){
    var callerButton = document.getElementById(id);
    var checkbox = callerButton.querySelector(".pseudo-checkbox");
    checkbox.setAttribute("data-checked",value);
}

function unsetPseudoRadioByClass(className){
    document.querySelectorAll("."+className).forEach(function(radio) {
        radio.setAttribute("data-checked","0");
    });
}

function togglePseudoRadioByClass(className,callerButton) {
    unsetPseudoRadioByClass(className);
    callerButton.querySelector("."+className).setAttribute("data-checked","1");
}

function getPseudoRadioValueByClass(className) {
    var returnValue=null;
    document.querySelectorAll("."+className).forEach(function(radio) {
        if(radio.getAttribute("data-checked")==="1"){
            returnValue=radio.getAttribute("data-value");
        }
    });
    return returnValue;
}

function reloadSearchAssetsForQrCodeTextSearch() {
    var searchedText = document.getElementById("search-asset-for-qr-code-text-search").value;
    var searchedTextPos;
    var highlightedText;
    var showedAssets=0;
    var tempHtml = "";
    for(var i = 0; i < lastFetchedAssetsFromQr.length; i++) {
        if(searchedText !== "" && ((searchedTextPos = lastFetchedAssetsFromQr[i].text.toUpperCase().indexOf(searchedText.toUpperCase())) >= 0)) {
            highlightedText = lastFetchedAssetsFromQr[i].text.substring(0, searchedTextPos) +
                "<strong>" + lastFetchedAssetsFromQr[i].text.substring(searchedTextPos, searchedTextPos + searchedText.length) + "</strong>" +
                lastFetchedAssetsFromQr[i].text.substring(searchedTextPos + searchedText.length, lastFetchedAssetsFromQr[i].text.length);
            tempHtml += "<button " +
                " type='button' " +
                " class='pseudo-radio-button'" +
                " onclick='togglePseudoRadioByClass(\"selected-asset-for-qr-link\",this);'" +
                ">" +
                "<span " +
                " data-value='" + lastFetchedAssetsFromQr[i].assetInstanceId + "' " +
                " data-checked='0'" +
                " class='pseudo-radio selected-asset-for-qr-link'" +
                ">" +
                highlightedText +
                "</span>" +
                "</button>";
            showedAssets++;
        }
        if(searchedText === "") {
            tempHtml += "<button " +
                " type='button' " +
                " class='pseudo-radio-button'" +
                " onclick='togglePseudoRadioByClass(\"selected-asset-for-qr-link\",this);'" +
                ">" +
                "<span " +
                " data-value='" + lastFetchedAssetsFromQr[i].assetInstanceId + "' " +
                " data-checked='0'" +
                " class='pseudo-radio selected-asset-for-qr-link' " +
                ">" +
                lastFetchedAssetsFromQr[i].text +
                "</span>" +
                "</button>";
            showedAssets++;
        }
    }
    if(showedAssets === 0) {
        tempHtml = "<div class='no-results-message'>Nessun risultato.</div>";
    }
    document.getElementById("search-results-asset-for-qr-code").innerHTML = tempHtml;
    scroll.refreshPopupById("search-asset-for-qr-code", false);
}

var lastFetchedAssetsFromQr = [];

function reloadSearchAssetsForQrCode() {
    var siteFilter = document.getElementById("search-asset-for-qr-code-site").value;
    var subcategoryFilter = document.getElementById("search-asset-for-qr-code-subcategory").value;
    var tempHtml = "";
    document.getElementById("search-asset-for-qr-code-text-search").value = "";
    dom.removeClass(document.getElementById("search-asset-for-qr-code-text-search-container"), "hidden");
    if(subcategoryFilter === "" || !subcategoryFilter) {
        var allSubcategoriesId = [];
        for(var i = 0; i < subcategories.length; i++) {
            allSubcategoriesId.push(subcategories[i].subcategoryId);
        }
        subcategoryFilter = allSubcategoriesId.join(",");
    }
    if(siteFilter === "") {
        siteFilter = "null";
    }
    var xhttp = new XMLHttpRequest();
    var timeoutErrorAbort = function() {
        lastFetchedAssetsFromQr = [];
        var matchedAssets = 0;
        for(var i = 0; i < preFetchedAssets.length; i++) {
            for(var j=0; j< preFetchedAssets[i].subcategories.length; j++) {
                if((preFetchedAssets[i].subcategories[j].subcategoryId == subcategoryFilter || subcategoryFilter === "") && (preFetchedAssets[i].site == siteFilter || siteFilter === "")) {
                    if(preFetchedAssets[i].qrCodeId === null) {
                        matchedAssets++;
                        tempHtml += "<button " +
                            " type='button'" +
                            " class='pseudo-radio-button'" +
                            " onclick='togglePseudoRadioByClass(\"selected-asset-for-qr-link\",this);'" +
                            ">" +
                            "<span " +
                            " data-value='" + preFetchedAssets[i].assetInstanceId + "'" +
                            " data-checked='0'" +
                            " class='pseudo-radio selected-asset-for-qr-link'" +
                            ">" +
                            ((preFetchedAssets[i].subcategories[j].subcategoryName === null) ? "" : preFetchedAssets[i].subcategories[j].subcategoryName) +
                            " " + ((preFetchedAssets[i].internalCode === null) ? "" : (" Cod. int. " + preFetchedAssets[i].internalCode)) +
                            " " + ((preFetchedAssets[i].model === null) ? "" : (" Mod. " + preFetchedAssets[i].model)) +
                            " " + ((preFetchedAssets[i].registrationNumber === null) ? "" : ("Matr. " + preFetchedAssets[i].registrationNumber)) +
                            " " + ((preFetchedAssets[i].positionHint === null) ? "" : ("Posizione: " + preFetchedAssets[i].positionHint)) +
                            "</span>" +
                            "</button>";
                        lastFetchedAssetsFromQr.push({
                            assetInstanceId: preFetchedAssets[i].assetInstanceId,
                            text: ((preFetchedAssets[i].subcategories[j].subcategoryName === null) ? "" : preFetchedAssets[i].subcategories[j].subcategoryName) +
                            " " + ((preFetchedAssets[i].internalCode === null) ? "" : (" Cod. int. " + preFetchedAssets[i].internalCode)) +
                            " " + ((preFetchedAssets[i].model === null) ? "" : (" Mod. " + preFetchedAssets[i].model)) +
                            " " + ((preFetchedAssets[i].registrationNumber === null) ? "" : ("Matr. " + preFetchedAssets[i].registrationNumber)) +
                            " " + ((preFetchedAssets[i].positionHint === null) ? "" : ("Posizione: " + preFetchedAssets[i].positionHint))
                        });
                        break;//breaks subcategories cycle
                    }
                }
            }
        }
        if(matchedAssets === 0) {
            tempHtml = "<div class='no-results-message'>Nessun risultato.</div>";
        }
        document.getElementById("search-results-asset-for-qr-code").innerHTML = tempHtml;
        scroll.refreshPopupById("search-asset-for-qr-code", false);
    };
    xhttp.timeout = 3500; // time in milliseconds
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            lastFetchedAssetsFromQr = [];
            if(response.assets.length === 0) {
                tempHtml = "<div class='no-results-message'>Nessun risultato.</div>";
            }
            else {
                for (var i = 0; i < response.assets.length; i++) {
                    if (response.assets[i].qrCodeId === null) {
                        tempHtml += "<button " +
                            " type='button'" +
                            " class='pseudo-radio-button' " +
                            " onclick='togglePseudoRadioByClass(\"selected-asset-for-qr-link\",this);'" +
                            ">" +
                            "<span " +
                            " data-value='" + response.assets[i].assetInstanceId + "' " +
                            " data-checked='0'" +
                            " class='pseudo-radio selected-asset-for-qr-link'" +
                            ">" +
                            ((response.assets[i].subcategoryName === null) ? "" : response.assets[i].subcategoryName) +
                            " " + ((response.assets[i].internalCode === null) ? "" : (" Cod. int. " + response.assets[i].internalCode)) +
                            " " + ((response.assets[i].model === null) ? "" : (" Mod. " + response.assets[i].model)) +
                            " " + ((response.assets[i].registrationNumber === null) ? "" : ("Matr. " + response.assets[i].registrationNumber)) +
                            " " + ((response.assets[i].positionHint === null) ? "" : ("Posizione: " + response.assets[i].positionHint)) +
                            "</span>" +
                            "</button>";
                        lastFetchedAssetsFromQr.push({
                            assetInstanceId: response.assets[i].assetInstanceId,
                            text: ((response.assets[i].subcategoryName === null) ? "" : response.assets[i].subcategoryName) + " " + ((response.assets[i].internalCode === null) ? "" : (" Cod. int. " + response.assets[i].internalCode)) + " " + ((response.assets[i].model === null) ? "" : (" Mod. " + response.assets[i].model)) + " " + ((response.assets[i].registrationNumber === null) ? "" : ("Matr. " + response.assets[i].registrationNumber)) + " " + ((response.assets[i].positionHint === null) ? "" : ("Posizione: " + response.assets[i].positionHint))
                        });
                    }
                }
            }
            document.getElementById("search-results-asset-for-qr-code").innerHTML = tempHtml;
            scroll.refreshPopupById("search-asset-for-qr-code", false);
        }
        else {
            outcomePopup.innerHTML = "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>";
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAssets.php?s=" + subcategoryFilter + "&g=null&b=null&i=" + siteFilter + "&c=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxLinkSearchedAssetToQrCode() {
    var toLinkAsset = {};
    toLinkAsset.assetId = getPseudoRadioValueByClass("selected-asset-for-qr-link");
    if(toLinkAsset.assetId === null) {
        errorToast("Selezionare un asset dalla lista per procedere all'associazione");
    }
    else {
        toLinkAsset.assetId=parseInt(toLinkAsset.assetId);
        toLinkAsset.qrCode = lastScannedQrCode;
        closePopup();
        closePopup();
        outboxManager.addOperation(toLinkAsset,"/ws/linkAssetToQrCode.php","Associazione asset - QR code",function(responseText){
            var response = JSON.parse(responseText);
            if(response.linkedQrCodes == null) {
                errorPopup("Errore: Il QR code risulta essere già in uso.");
            }
            else {
                closePopup();
                successToast("Asset collegato con successo al QR code " + lastScannedQrCode);
            }
            ajaxGetQrCodeData(lastScannedQrCode);
        });
    }
}

function toggleAddAssetAutomaticInternalNumber() {
    var internalNumberAutomaticInput = document.getElementById("add-asset-automatic-internal-number-start");
    var manualContainer = document.getElementById("add-asset-internal-number-container");
    var automaticContainer = document.getElementById("add-asset-automatic-internal-number-start-container");
    var checkbox = document.getElementById("add-asset-automatic-internal-number");
    if(checkbox.getAttribute("data-checked")==="1") {
        checkbox.setAttribute("data-checked","0");
        dom.removeClass(manualContainer, "hidden");
        dom.addClass(automaticContainer, "hidden");
        internalNumberAutomaticInput.removeAttribute("required");
    }
    else {
        checkbox.setAttribute("data-checked","1");
        dom.removeClass(automaticContainer, "hidden");
        dom.addClass(manualContainer, "hidden");
        internalNumberAutomaticInput.required = "required";
    }
    scroll.refreshPopupById("add-asset", false);
}

function ajaxCreateAsset() {
    var toCreateAssets = {};
    toCreateAssets.siteId = parseInt(document.getElementById("add-asset-site").value);
    var subcategorySelectors = document.querySelectorAll(".add-asset-subcategory");
    toCreateAssets.subcategories = [];
    for(var i = 0; i < subcategorySelectors.length; i++) {
        toCreateAssets.subcategories[i] = parseInt(subcategorySelectors[i].value);
    }
    toCreateAssets.brand = document.getElementById("add-asset-brand").value;
    if(toCreateAssets.brand === "") {
        delete toCreateAssets.brand;
    }
    toCreateAssets.operationCausal = document.getElementById("add-asset-causal").value;
    if(toCreateAssets.operationCausal === "") {
        delete toCreateAssets.operationCausal;
    }
    toCreateAssets.positionHint = document.getElementById("add-asset-position-hint").value;
    if(toCreateAssets.positionHint === "") {
        delete toCreateAssets.positionHint;
    }
    toCreateAssets.floor = document.getElementById("add-asset-floor").value;
    if(toCreateAssets.floor === "") {
        delete toCreateAssets.floor;
    }
    else {
        toCreateAssets.floor = parseInt(toCreateAssets.floor);
    }
    toCreateAssets.model = document.getElementById("add-asset-model").value;
    if(toCreateAssets.model === "") {
        delete toCreateAssets.model;
    }
    toCreateAssets.toInsertAssetsNumber = document.getElementById("add-asset-number").value;
    if(toCreateAssets.toInsertAssetsNumber === "") {
        delete toCreateAssets.toInsertAssetsNumber;
    }
    else {
        toCreateAssets.toInsertAssetsNumber = parseInt(toCreateAssets.toInsertAssetsNumber);
    }
    if(document.getElementById("add-asset-automatic-internal-number").getAttribute("data-checked")==="1") {
        toCreateAssets.internalNumber = null;
        toCreateAssets.internalNumberStartFrom = document.getElementById("add-asset-automatic-internal-number-start").value;
        if(toCreateAssets.internalNumberStartFrom === "") {
            delete toCreateAssets.internalNumberStartFrom;
        }
        else {
            toCreateAssets.internalNumberStartFrom = parseInt(toCreateAssets.internalNumberStartFrom);
        }
    }
    else {
        toCreateAssets.internalNumberStartFrom = null;
        toCreateAssets.internalNumber = document.getElementById("add-asset-internal-number").value;
        if(toCreateAssets.internalNumber === "") {
            delete toCreateAssets.internalNumber;
        }
    }
    closePopup();
    closePopup();
    outboxManager.addOperation(toCreateAssets,"/ws/asset/create.php","Creazione asset",function(responseText){
        assetInstanceManager.refreshAssets(globalFilterByCompany);
        var response = JSON.parse(responseText);
        closePopup();
        if(response.addedToInterventions.length>0&&response.addedToInterventions[0].interventions.length>0){
            successToast("Asset creati con successo, aggiunti a "+response.addedToInterventions[0].interventions.length+" interventi preesistenti");
        }
        else{
            successToast("Asset creati con successo");
        }
        reloadAssetNavFilters();
    });

}

var lastCreatedAsset;

function ajaxCreateAssetForQrCode() {
    var toCreateAssets = {};
    toCreateAssets.siteId = parseInt(document.getElementById("add-asset-for-qr-code-site").value);
    toCreateAssets.subcategoryId = parseInt(document.getElementById("add-asset-for-qr-code-subcategory").value);
    toCreateAssets.brand = document.getElementById("add-asset-for-qr-code-brand").value;
    if(toCreateAssets.brand === "") {
        delete toCreateAssets.brand;
    }
    toCreateAssets.operationCausal = document.getElementById("add-asset-for-qr-code-causal").value;
    if(toCreateAssets.operationCausal === "") {
        delete toCreateAssets.operationCausal;
    }
    if(dom.hasClass(document.getElementById("add-asset-for-qr-code-model-text"), "hidden")) {
        toCreateAssets.model = document.getElementById("add-asset-for-qr-code-model-select").value;
    }
    else {
        toCreateAssets.model = document.getElementById("add-asset-for-qr-code-model-text").value;
    }
    if(toCreateAssets.model === "") {
        delete toCreateAssets.model;
    }
    if(dom.hasClass(document.getElementById("add-asset-for-qr-code-type-text"), "hidden")) {
        toCreateAssets.type = document.getElementById("add-asset-for-qr-code-type-select").value;
    }
    else {
        toCreateAssets.type = document.getElementById("add-asset-for-qr-code-type-text").value;
    }
    if(toCreateAssets.type === "")
        delete toCreateAssets.type;
    toCreateAssets.positionHint = document.getElementById("add-asset-for-qr-code-position-hint").value;
    if(toCreateAssets.positionHint === "")
        delete toCreateAssets.positionHint;
    toCreateAssets.floor = document.getElementById("add-asset-for-qr-code-floor").value;
    if(toCreateAssets.floor === "")
        delete toCreateAssets.floor;
    else
        toCreateAssets.floor = parseInt(toCreateAssets.floor);
    toCreateAssets.toInsertAssetsNumber = 1;
    toCreateAssets.internalNumber = document.getElementById("add-asset-for-qr-code-internal-number").value;
    if(toCreateAssets.internalNumber === "") {
        delete toCreateAssets.internalNumber;
    }
    toCreateAssets.registrationNumber = document.getElementById("add-asset-for-qr-code-registration-number").value;
    if(toCreateAssets.registrationNumber === "") {
        delete toCreateAssets.registrationNumber;
    }
    toCreateAssets.relatedQrCode = lastScannedQrCode;
    lastCreatedAsset = toCreateAssets;
    closePopup();
    closePopup();
    outboxManager.addOperation(toCreateAssets,"/ws/asset/create.php","Creazione asset da QR code",function(responseText){
        /*TODO: It is possibile that the globalFilterByCompany is not the same company for which the asset was created,
        this would need a closure, but for now it's ok since if the company has changed, when the user will switch to the
        new company new data will be downloaded, additionally, mobile application will update local db data when they
        receive an update push message
        */
        assetInstanceManager.refreshAssets(globalFilterByCompany);
        var response = JSON.parse(responseText);
        lastCreatedAssetId = response.createdAssetsIds[0];
        lastCreatedAsset.assetId = lastCreatedAssetId;
        var tempHtml = "Asset creato con successo, collegato al QR code " + lastScannedQrCode;
        if(response.addedToInterventions.length>0) {
            tempHtml+= " e inserito automaticamente in " +response.addedToInterventions.length + " interventi preesistenti";
        }
        succesToast(tempHtml);
        closePopup();
        ajaxGetQrCodeData(lastScannedQrCode);
    });
}

function createNewContractForAssetInstance() {
    popup("create-contract", true, false, false);
}

function uploadAsset() {
    var toUploadFile = document.getElementById("upload-asset-file");
    if(toUploadFile.files.length === 0) {
        return;
    }
    var data = new FormData();
    data.append("csvFile", toUploadFile.files[0]);
    dom.addClass(document.getElementById("upload-assets-step-1"), "hidden");
    dom.removeClass(document.getElementById("upload-assets-step-2"), "hidden");
    var uploadAssetPopup = document.getElementById("upload-assets-step-3");
    var progressBar = document.getElementById("upload-asset-progress-bar");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        dom.addClass(document.getElementById("upload-assets-step-2"), "hidden");
        dom.removeClass(document.getElementById("upload-assets-step-3"), "hidden");
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                justUploadedFile = response;
                var tempHtml, i;
                var tempOptions = "";
                for(i = 0; i < response.admittableColumnsMatch.length; i++)
                    tempOptions += "<option value='" + response.admittableColumnsMatch[i] + "'>" + response.admittableColumnsMatch[i] + "</option>";
                tempHtml = "<div class='message success'>File CSV caricato con successo. Il file contiene " + response.csvRows + " righe e " + response.csvHeaders.length + " colonne.</div>";
                tempHtml += "<br><strong>Indicare la corrispondenza delle colonne del file:</strong><br><br><br><table>";
                for(i = 0; i < response.csvHeaders.length; i++) {
                    tempHtml += "<tr><td>" + response.csvHeaders[i] + "</td><td><select id='col-" + i + "-match'>" + tempOptions + "</select></td></tr>";
                }
                tempHtml += "</table><br><br><button type='button' onclick='restartAssetsUpload();'><i class='fa fa-chevron-left' aria-hidden='true'></i> Indietro</button> <button type='button' onclick='ajaxCompleteAssetsUpload();'><i class='fa fa-chevron-right' aria-hidden='true'></i> Prosegui</button>";
                uploadAssetPopup.innerHTML = tempHtml;
            }
            else {
                uploadAssetPopup.innerHTML = "<div class='message error'>" + response.message + "</div>";
                uploadAssetPopup.innerHTML += "<br><br><button type='button' onclick='restartAssetsUpload();'><i class='fa fa-chevron-left' aria-hidden='true'></i> Indietro</button> <button type='button' onclick='abortAssetsUpload();closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Annulla</button>";
            }
        }
        catch(error) {
            uploadAssetPopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
            uploadAssetPopup.innerHTML += "<br><br><button type='button' onclick='restartAssetsUpload();'><i class='fa fa-chevron-left' aria-hidden='true'></i> Indietro</button> <button type='button' onclick='abortAssetsUpload();closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Annulla</button>";
        }
    };

    // Progress Bar Calculation
    function uploadProgress(e) {
        if(e.lengthComputable) {
            progressBar.innerHTML = " " + ((e.loaded / e.total) * 100).toFixed(2) + "%";
            progressBar.style.width = ((e.loaded / e.total) * 100) + "%";
        }
        else {
            dom.addClass(progressBar, "not-computable");
        }
    }

    // Listen to the upload progress for each upload.
    xhttp.upload.onprogress = uploadProgress;
    xhttp.open("POST", BASE_URL + "/ws/uploadAssets.php", true);
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(data);
}

function ajaxUploadDocument(inputFieldId,progressBarId,successCallback){
    var toUploadFile = document.getElementById(inputFieldId);
    if(toUploadFile.files.length === 0) {
        return;
    }
    var data = new FormData();
    data.append("pdfFile", toUploadFile.files[0]);
    var progressBar;
    if(progressBarId!=null) {
        progressBar = document.getElementById(progressBarId);
    }
    else{
        progressBar = null;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var justUploadedFileId = response["newFileId"];
                if(typeof successCallback==="function") {
                    successCallback(justUploadedFileId);
                }
            }
            else {
                closePopup();//closing progress bar popup
                toUploadFile.value="";
                errorPopup("Errore durante l'elaborazione del documento: "+response.message);
            }
        }
        catch(error) {
            closePopup();//closing progress bar popup
            errorPopup("Errore durante l'elaborazione del documento");
            console.error(error);
        }
    };

    // Progress Bar Calculation
    function uploadProgress(e) {
        if(e.lengthComputable) {
            if(progressBar!=null) {
                progressBar.innerHTML = " " + ((e.loaded / e.total) * 100).toFixed(2) + "%";
                progressBar.style.width = ((e.loaded / e.total) * 100) + "%";
            }
        }
        else {
            if(progressBar!=null){
                dom.addClass(progressBar, "not-computable");
            }
        }
    }

    // Listen to the upload progress for each upload.
    xhttp.upload.onprogress = uploadProgress;
    xhttp.open("POST", BASE_URL + "/ws/uploadDocument.php", true);
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(data);
}

function ajaxUploadPicture(inputFieldId,progressBarId,successCallback){
    var toUploadFile = document.getElementById(inputFieldId);
    if(toUploadFile.files.length === 0) {
        return;
    }
    var data = new FormData();
    data.append("newPicture", toUploadFile.files[0]);
    var progressBar;
    if(progressBarId!=null) {
        progressBar = document.getElementById(progressBarId);
    }
    else{
        progressBar = null;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var justUploadedFileId = response["newFileId"];
                if(typeof successCallback==="function") {
                    successCallback(justUploadedFileId);
                }
            }
            else {
                var newFileInput = document.createElement("INPUT");
                newFileInput.setAttribute("type", "file");
                newFileInput.id = inputFieldId;
                fileInput.parentNode.replaceChild(newFileInput, toUploadFile);
                errorPopup("Errore durante l'elaborazione del documento: "+response.message);
            }
        }
        catch(error) {
            errorPopup("Errore durante l'elaborazione del documento");
        }
    };

    // Progress Bar Calculation
    function uploadProgress(e) {
        if(e.lengthComputable) {
            if(progressBar!=null) {
                progressBar.innerHTML = " " + ((e.loaded / e.total) * 100).toFixed(2) + "%";
                progressBar.style.width = ((e.loaded / e.total) * 100) + "%";
            }
        }
        else {
            if(progressBar!=null){
                dom.addClass(progressBar, "not-computable");
            }
        }
    }

    // Listen to the upload progress for each upload.
    xhttp.upload.onprogress = uploadProgress;
    xhttp.open("POST", BASE_URL + "/ws/uploadPicture.php", true);
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(data);
}

function uploadDocument() {
    var toUploadFile = document.getElementById("upload-document-file");
    if(toUploadFile.files.length === 0) {
        return;
    }
    var data = new FormData();
    data.append("pdfFile", toUploadFile.files[0]);
    dom.addClass(document.getElementById("upload-document-step-1"), "hidden");
    dom.removeClass(document.getElementById("upload-document-step-2"), "hidden");
    var uploadDocumentPopup = document.getElementById("upload-document-step-3");
    var progressBar = document.getElementById("upload-document-progress-bar");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        dom.addClass(document.getElementById("upload-document-step-2"), "hidden");
        dom.removeClass(document.getElementById("upload-document-step-3"), "hidden");
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                justUploadedFile = response["newFileId"];
                tempHtml = "<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Elaborazione del documento caricato in corso...</span>";
                uploadDocumentPopup.innerHTML = tempHtml;
                finalizeDocumentUpload();
            }
            else {
                var newFileInput = document.createElement("INPUT");
                newFileInput.setAttribute("type", "file");
                newFileInput.id = "upload-document-file";
                toUploadFile.parentNode.replaceChild(newFileInput, toUploadFile);

                uploadDocumentPopup.innerHTML = "<div class='message error'>" + response.message + "</div><br><br><button type='button' onclick='closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Chiudi</button>";
            }
        }
        catch(error) {
            uploadDocumentPopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione</div><br><br><button type='button' onclick='closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Chiudi</button>";
        }
    };

    // Progress Bar Calculation
    function uploadProgress(e) {
        if(e.lengthComputable) {
            progressBar.innerHTML = " " + ((e.loaded / e.total) * 100).toFixed(2) + "%";
            progressBar.style.width = ((e.loaded / e.total) * 100) + "%";
        }
        else {
            dom.addClass(progressBar, "not-computable");
        }
    }

    // Listen to the upload progress for each upload.
    xhttp.upload.onprogress = uploadProgress;
    xhttp.open("POST", BASE_URL + "/ws/uploadDocument.php", true);
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(data);
}

function generatePrivateLinkForSelectedDocument() {
    lastGeneratedDocumentsShare = [];
    var documentShared = {};
    documentShared.fileName = selectedDocuments[0].fileName;
    documentShared.link = "https://facility.claster.it/#data-room/document/" + lastSelectedDocumentId;
    lastGeneratedDocumentsShare.push(documentShared);
    document.getElementById("private-link-container").value = "https://facility.claster.it/#data-room/document/" + lastSelectedDocumentId;
    document.getElementById("private-link-container").select();
    popup("private-document-link", false, false, false);
}

function ajaxShareMultipleDocumentsFromDataRoom() {
    popup("share-multiple-documents", false, false, false);
}

function generatePrivateLinksForSelectedDocuments() {
    var textValue = "";
    var i;
    lastGeneratedDocumentsShare = [];
    for(i = 0; i < selectedDocuments.length; i++) {
        var documentShared = {};
        documentShared.fileName = selectedDocuments[i].fileName;
        documentShared.link = "https://facility.claster.it/#data-room/document/" + selectedDocuments[i].documentId;
        textValue += selectedDocuments[i].fileName + "\n";
        textValue += "https://facility.claster.it/#data-room/document/" + selectedDocuments[i].documentId + "\n\n";
        lastGeneratedDocumentsShare.push(documentShared);
    }
    document.getElementById("private-links-container").value = textValue;
    document.getElementById("private-links-container").select();
    popup("private-documents-links", false, false, false);
}

function generatePublicLinksForSelectedDocuments() {
    var i;
    dom.removeClass(document.getElementById("public-documents-links-not-ready"), "hidden");
    dom.addClass(document.getElementById("public-documents-links-ready"), "hidden");
    popup("public-documents-links", false, false, false);
    var sharingInformation = {};
    sharingInformation.documents = [];
    for(i = 0; i < selectedDocuments.length; i++) {
        if(selectedDocuments[i].documentId !== null && selectedDocuments[i].documentId !== undefined) {
            var newDocument = {};
            newDocument.documentId = parseInt(selectedDocuments[i].documentId);
            newDocument.fileName = selectedDocuments[i].fileName;
            sharingInformation.documents.push(newDocument);
        }
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            dom.addClass(document.getElementById("public-documents-links-not-ready"), "hidden");
            dom.removeClass(document.getElementById("public-documents-links-ready"), "hidden");
            var tempText = "";
            lastGeneratedDocumentsShare = [];

            for(var i = 0; i < response.documents.length; i++) {
                var documentShared = {};
                documentShared.fileName = response.documents[i].fileName;
                documentShared.link = "https://facility.claster.it/#data-room/public-document/" + response.documents[i].documentId + "/" + response.documents[i].documentSharingToken;
                lastGeneratedDocumentsShare.push(documentShared);
                tempText += response.documents[i].fileName + "\n";
                tempText += "https://facility.claster.it/#data-room/public-document/" + response.documents[i].documentId + "/" + response.documents[i].documentSharingToken + "\n\n";
            }
            document.getElementById("public-links-container").value = tempText;
            document.getElementById("public-links-container").select();
        }
        else {
            closePopup();
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocuments.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function generatePublicLinkForSelectedDocument() {
    dom.removeClass(document.getElementById("public-document-link-not-ready"), "hidden");
    dom.addClass(document.getElementById("public-document-link-ready"), "hidden");
    popup("public-document-link", false, false, false);
    var sharingInformation = {};
    sharingInformation.documentId = parseInt(lastSelectedDocumentId);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            dom.addClass(document.getElementById("public-document-link-not-ready"), "hidden");
            dom.removeClass(document.getElementById("public-document-link-ready"), "hidden");

            lastGeneratedDocumentsShare = [];
            var documentShared = {};
            documentShared.fileName = selectedDocuments[0].fileName;
            documentShared.link = "https://facility.claster.it/#data-room/public-document/" + lastSelectedDocumentId + "/" + response.documentSharingToken;
            lastGeneratedDocumentsShare.push(documentShared);
            document.getElementById("public-link-container").value = "https://facility.claster.it/#data-room/public-document/" + lastSelectedDocumentId + "/" + response.documentSharingToken;
            document.getElementById("public-link-container").select();
        }
        else {
            closePopup();
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocument.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function sharePublicDocumentsLinksByMail() {
    var sharingInformation = {};
    sharingInformation.documents = lastGeneratedDocumentsShare;
    sharingInformation.mailTo = document.getElementById("share-public-documents-links-by-mail-to").value;
    sharingInformation.mailCC = document.getElementById("share-public-documents-links-by-mail-cc").value;
    if(sharingInformation.mailCC.trim() === "") {
        delete sharingInformation.mailCC;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Messaggio inviato correttamente");
        }
        else {
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocumentsByMail.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function sharePrivateDocumentsLinksByMail() {
    var sharingInformation = {};
    sharingInformation.documents = lastGeneratedDocumentsShare;
    sharingInformation.mailTo = document.getElementById("share-private-documents-links-by-mail-to").value;
    sharingInformation.mailCC = document.getElementById("share-private-documents-links-by-mail-cc").value;
    if(sharingInformation.mailCC.trim() === "") {
        delete sharingInformation.mailCC;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Messaggio inviato correttamente");
        }
        else {
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocumentsByMail.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function sharePublicDocumentLinkByMail() {
    var sharingInformation = {};
    sharingInformation.documents = lastGeneratedDocumentsShare;
    sharingInformation.mailTo = document.getElementById("share-public-document-link-by-mail-to").value;
    sharingInformation.mailCC = document.getElementById("share-public-document-link-by-mail-cc").value;
    if(sharingInformation.mailCC.trim() === "") {
        delete sharingInformation.mailCC;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Messaggio inviato correttamente");
        }
        else {
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocumentsByMail.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function sharePrivateDocumentLinkByMail() {
    var sharingInformation = {};
    sharingInformation.documents = lastGeneratedDocumentsShare;
    sharingInformation.mailTo = document.getElementById("share-private-document-link-by-mail-to").value;
    sharingInformation.mailCC = document.getElementById("share-private-document-link-by-mail-cc").value;
    if(sharingInformation.mailCC === "") {
        delete sharingInformation.mailCC;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            closePopup();
            successToast("Messaggio inviato correttamente");
        }
        else {
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/shareDataRoomDocumentsByMail.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(sharingInformation));
}

function uploadDocumentTypeChanged() {
    var selectedDocumentType = document.getElementById("upload-document-type").value;
    var tempHtml = "";
    var matchCounter = 0;
    for(var i = 0; i < documentTypes.length; i++) {
        if(documentTypes[i].documentTypeId == selectedDocumentType) {
            for(var j = 0; j < documentTypes[i].subtypes.length; j++) {
                tempHtml += "<option value='" + documentTypes[i].subtypes[j].documentSubtypeId + "'>" + documentTypes[i].subtypes[j].documentSubtype + "</option>";
                matchCounter++;
            }
            break;
        }
    }
    if(matchCounter === 0) {
        tempHtml += "<option value='' selected='selected'>-- Non presenti --</option>";
    }
    if(matchCounter > 1) {
        tempHtml += "<option value='' selected='selected'>-- Selezionare --</option>";
    }
    document.getElementById("upload-document-subtype").innerHTML = tempHtml;
}

function editDocumentTypeChanged() {
    var selectedDocumentType = document.getElementById("edit-document-type").value;
    var tempHtml = "";
    var matchCounter = 0;
    for(var i = 0; i < documentTypes.length; i++) {
        if(documentTypes[i].documentTypeId == selectedDocumentType) {
            for(var j = 0; j < documentTypes[i].subtypes.length; j++) {
                tempHtml += "<option value='" + documentTypes[i].subtypes[j].documentSubtypeId + "'>" + documentTypes[i].subtypes[j].documentSubtype + "</option>";
                matchCounter++;
            }
            break;
        }
    }
    if(matchCounter === 0) {
        tempHtml += "<option value='' selected='selected'>-- Non presenti --</option>";
    }
    if(matchCounter > 1) {
        tempHtml += "<option value='' selected='selected'>-- Selezionare --</option>";
    }
    document.getElementById("edit-document-subtype").innerHTML = tempHtml;
}

function finalizeDocumentUpload() {
    var uploadDocumentPopup = document.getElementById("upload-document-step-3");
    var documentDetails = {};
    documentDetails.documentType = parseInt(document.getElementById("upload-document-type").value);
    if(documentDetails.documentType === "") {
        delete documentDetails.documentType;
    }
    documentDetails.documentSubtype = parseInt(document.getElementById("upload-document-subtype").value);
    if(documentDetails.documentSubtype === "") {
        delete documentDetails.documentSubtype;
    }
    documentDetails.company = parseInt(document.getElementById("upload-document-company").value);
    if(documentDetails.company === "") {
        delete documentDetails.company;
    }
    documentDetails.subcategory = parseInt(document.getElementById("upload-document-subcategory").value);
    if(documentDetails.subcategory === "") {
        delete documentDetails.subcategory;
    }
    documentDetails.category = parseInt(document.getElementById("upload-document-category").value);
    if(documentDetails.category === "") {
        delete documentDetails.category;
    }
    documentDetails.fileId = parseInt(justUploadedFile);
    documentDetails.tags = uploadDocumentTagsTaggle.getTagValues();
    if(documentDetails.tags.length < 1) {
        delete documentDetails.tags;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        closePopup();//Closing finalizing upload spinner popup
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                successToast("Documento caricato con successo");
                uploadDocumentPopup.innerHTML = tempHtml;
                //Resetting the file input after file successful submission
                var fileInput = document.getElementById("upload-document-file");
                var newFileInput = document.createElement("INPUT");
                newFileInput.setAttribute("type", "file");
                newFileInput.id = "upload-document-file";
                fileInput.parentNode.replaceChild(newFileInput, fileInput);
                ajaxReloadDataRoomFilters();
            }
            else {
                uploadDocumentPopup.innerHTML = "<div class='message error'>" + response.message + "</div><br><br><button type='button' onclick='closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Chiudi</button>";
            }
        }
        catch(error) {
            uploadDocumentPopup.innerHTML = "<div class='message error'>Errore durante l'elaborazione</div><br><br><button type='button' onclick='closePopup();'><i class='fa fa-times' aria-hidden='true'></i> Chiudi</button>";
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/finalizeDocumentUpload.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(documentDetails));
}

function ajaxEditDocument() {
    var documentDetails = {};
    documentDetails.fileName = document.getElementById("edit-document-name").value;
    documentDetails.documentId = parseInt(document.getElementById("edit-document-document-id").value);
    documentDetails.documentType = parseInt(document.getElementById("edit-document-type").value);
    if(documentDetails.documentType === "")
        delete documentDetails.documentType;
    documentDetails.documentSubtype = parseInt(document.getElementById("edit-document-subtype").value);
    if(documentDetails.documentSubtype === "")
        delete documentDetails.documentSubtype;
    documentDetails.company = parseInt(document.getElementById("edit-document-company").value);
    if(documentDetails.company === "")
        delete documentDetails.company;
    documentDetails.subcategory = parseInt(document.getElementById("edit-document-subcategory").value);
    if(documentDetails.subcategory === "")
        delete documentDetails.subcategory;
    documentDetails.category = parseInt(document.getElementById("edit-document-category").value);
    if(documentDetails.category === "")
        delete documentDetails.category;

    documentDetails.tags = editDocumentTagsTaggle.getTagValues();
    if(documentDetails.tags.length < 1) {
        delete documentDetails.tags;
    }
    closePopup();
    popup("outcome", false, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var tempHtml;
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Documento modificato con successo");
                ajaxReloadDataRoomFilters();
            }
            else {
                tempHtml = "<div class='message error'>Errore durante l'elaborazione: " + response.message + "</div>";
            }
        }
        catch(error) {
            tempHtml = "<div class='message error'>Errore durante l'elaborazione</div>";
        }
        outcomePopup.innerHTML = tempHtml;
    };
    xhttp.open("POST", BASE_URL + "/ws/editDocument.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(documentDetails));
}

function initializeAssets() {
    dom.removeClass(document.getElementById("upload-assets-step-1"), "hidden");
    dom.addClass(document.getElementById("upload-assets-step-2"), "hidden");
    var progressBar = document.getElementById("upload-asset-progress-bar");
    progressBar.innerHTML = " 0.00%";
    progressBar.style.width = "0%";
    dom.addClass(document.getElementById("upload-assets-step-3"), "hidden");
}

function restartAssetsUpload() {
    initializeAssets();
}

function abortAssetsUpload() {
    closePopup();
    initializeAssets();
}

function ajaxCompleteAssetsUpload() {
    var toCreateAssetsInformation = {};
    toCreateAssetsInformation.fileId = justUploadedFile.fileId;
    toCreateAssetsInformation.siteId = parseInt(document.getElementById("upload-asset-site").value);
    toCreateAssetsInformation.subcategoryId = parseInt(document.getElementById("upload-asset-subcategory").value);
    toCreateAssetsInformation.headersMatch = [];
    for(var i = 0; i < justUploadedFile.csvHeaders.length; i++) {
        toCreateAssetsInformation.headersMatch[i] = document.getElementById("col-" + i + "-match").value;
    }
    closePopup();
    initializeAssets();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            closePopup();
            successToast(response.createdAssets.length + " asset creati con successo");
            reloadAssetNavFilters();
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/completeAssetsUpload.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(toCreateAssetsInformation));
}

function interventionTeamManagement() {
    document.getElementById("team-management-executor-selector").value = ((lastOpenedIntervention.executorId === null) ? "" : lastOpenedIntervention.executorId);
    teamManagementExecutorChanged();
    popup("team-management", false, false, false);
}

function ajaxSaveEditActivityForSubcategory() {
    var newActivity = {};
    newActivity.editedActivityOnSubcategoryId = parseInt(editingActivityOnSubcategoryId);
    newActivity.subcategory = document.getElementById("edit-activity-for-subc-subcategory").value;
    if(newActivity.subcategory === ""){
        delete newActivity.subcategory;
    }
    else {
        newActivity.subcategory = parseInt(newActivity.subcategory);
    }
    newActivity.description = document.getElementById("edit-activity-for-subc-description").value;
    if(document.getElementById("edit-activity-for-subc-is-statutory-requirement").getAttribute("data-checked")==="1")//this means that there's a law defining minimum frequency of this activity
    {
        newActivity.isStatutoryRequirement = true;
        newActivity.relatedLaw = document.getElementById("edit-activity-for-subc-law").value;
        if(newActivity.relatedLaw === "")
            delete newActivity.relatedLaw;
        else
            newActivity.relatedLaw = parseInt(newActivity.relatedLaw);
        newActivity.minFrequency = document.getElementById("edit-activity-for-subc-frequency").value;
        if(newActivity.minFrequency === "")
            delete newActivity.minFrequency;
        else
            newActivity.minFrequency = parseInt(newActivity.minFrequency);
        newActivity.frequencyType = document.getElementById("edit-activity-for-subc-frequency-type").value;
    }
    else {
        newActivity.isStatutoryRequirement = false;
    }
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Attività modificata con successo");
                ajaxViewActivitiesForSubcategory();//refresh the results after editing
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newActivity), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newActivity), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editActivityForSubcategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newActivity));
}

function disableActivityOnSubcategory(i) {
    setInnerHtml(document.getElementById("disable-activity-description"),ui.unescapeHtml(lastFetchedActivitiesForSubcategory[i].description));
    document.getElementById("disable-activity-id").value = lastFetchedActivitiesForSubcategory[i].activityOnAssetTypeId;
    popup("disable-activity-for-subcategory", false, false, false);
}

function editActivityOnSubcategory(i) {
    editingActivityOnSubcategoryId = lastFetchedActivitiesForSubcategory[i].activityOnAssetTypeId;
    var categorySelector = document.getElementById("edit-activity-for-subc-category");
    categorySelector.value = lastFetchedActivitiesForSubcategory[i].category;
    populateEditActivityOnSubcSubcategories();
    document.getElementById("edit-activity-for-subc-subcategory").value = lastFetchedActivitiesForSubcategory[i].subcategory;
    document.getElementById("edit-activity-for-subc-description").value = ui.unescapeHtml(lastFetchedActivitiesForSubcategory[i].description);
    popup("edit-activity-for-subcategory", false, false, false);
    document.getElementById("edit-activity-for-subc-must-be-replaced").setAttribute("data-checked",lastFetchedActivitiesForSubcategory[i].assetMustBeReplaced?"1":"0");
    document.getElementById("edit-activity-for-subc-can-be-replaced").setAttribute("data-checked",lastFetchedActivitiesForSubcategory[i].assetCanBeReplaced?"1":"0");
    if(lastFetchedActivitiesForSubcategory[i].lawNumber) {
        ajaxGetLaws(lastFetchedActivitiesForSubcategory[i].lawId);
        document.getElementById("edit-activity-for-subc-frequency").value = lastFetchedActivitiesForSubcategory[i].frequency;
        document.getElementById("edit-activity-for-subc-frequency-type").value = lastFetchedActivitiesForSubcategory[i].frequencyType;
        document.getElementById("edit-activity-for-subc-is-statutory-requirement").setAttribute("data-checked","1");
        dom.removeClass(document.getElementById("edit-activity-for-subc-law-requirements"), "hidden");
    }
    else {
        ajaxGetLaws(null);
        dom.addClass(document.getElementById("edit-activity-for-subc-law-requirements"), "hidden");
        document.getElementById("edit-activity-for-subc-is-statutory-requirement").setAttribute("data-checked","0");
    }
}

function ajaxViewActivitiesForSubcategory() {
    var subcategoryId = document.getElementById("view-activities-for-subc-subcategory").value;
    var categoryId = document.getElementById("view-activities-for-subc-category").value;

    //Propagate category and subcategory to new activity popup
    document.getElementById("add-activity-for-subc-category").value = categoryId;
    document.getElementById("add-activity-for-subc-subcategory").value = subcategoryId;

    var activitiesContainer = document.getElementById("activities-for-subcategories-container");
    activitiesContainer.innerHTML="";
    if(subcategoryId === "") {
        var span = document.createElement("span");
        dom.addClass(span,"tip");
        span.innerHTML="<br><br>Selezionare una <strong>categoria</strong> ed una <strong>sottocategoria</strong>.<br><br>";
        activitiesContainer.appendChild(span);
        scroll.refreshPopupById("view-activities-for-subcategories", false);
    }
    else {
        var xhttp = new XMLHttpRequest();
        xhttp.onload = function() {
            var response = JSON.parse(xhttp.responseText);
            var tempHtml;
            if(xhttp.status === 200) {
                lastFetchedActivitiesForSubcategory = response.activities;
                for(var i = 0; i < response.activities.length; i++) {
                    var showFrequency = (response.activities[i].frequency != null && response.activities[i].frequencyType != null);
                    var entry = document.createElement("div");
                    dom.addClass(entry,"entry");
                    if(response.activities[i].disabledFrom !== null) {
                        dom.addClass(entry,"blocked");
                    }
                    entry.setAttribute("data-activity-on-asset-instance-id",response.activities[i].activityOnAssetTypeId);
                    entry.setAttribute("data-activity-on-asset-instance-index",i);
                    entry.innerHTML = "<br>"+response.activities[i].description + "<br>" +
                        (response.activities[i].disabledFrom === null ? "" : ("<span class='red-state'><strong>Disattivato dal:</strong> " + iso8601.toEuropeanDate(response.activities[i].disabledFrom) + "</span><br>")) +
                        (response.activities[i].lawNumber === null ? "" : ("<strong>Regolato dalla normativa:</strong> " + response.activities[i].lawNumber + "<br>")) +
                        (showFrequency ? ("Una volta ogni " + response.activities[i].frequency + " " + response.activities[i].frequencyType + "<br>") : "") + "<br>" +
                        (response.activities[i].disabledFrom === null ? ("<button type='button' class='rounded' onclick='editActivityOnSubcategory(" + i + ")'><i class='fa fa-pencil'></i></button> "):"")+
                        (response.activities[i].disabledFrom === null ? ("<button type='button' class='rounded' onclick='disableActivityOnSubcategory(" + i + ")'><i class='fa fa-trash'></i></button> "):"")+
                        "<button type='button' class='rounded move-down-button' onclick='ajaxSaveNewActivitiesForSubcategoryOrder(\"down\"," + i + ")'><i class='fa fa-chevron-down'></i></button> " +
                        "<button type='button' class='rounded move-up-button' onclick='ajaxSaveNewActivitiesForSubcategoryOrder(\"up\"," + i + ")'><i class='fa fa-chevron-up'></i></button>" +
                        "<br>"+(response.activities[i].executedOnEveryAsset?"":"<strong>Attività eseguita una sola volta per intervento</strong>")+"<br><br>";
                    activitiesContainer.appendChild(entry);
                }
                scroll.refreshPopupById("view-activities-for-subcategories", false);
            }
            else {
                setInnerHtml(
                    activitiesContainer,
                    "<div class='message error'> Errore: " + response.message + "</div>",
                    function(){scroll.refreshPopupById("view-activities-for-subcategories", false);}
                );
            }

        };
        xhttp.open("GET", BASE_URL + "/ws/getActivitiesOnSubcategory.php?subcategoryId=" + subcategoryId, true);
        xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
        xhttp.send();
    }
}

function ajaxSaveNewActivitiesForSubcategoryOrder(direction, index) {
    var newOrder = {}, i;
    newOrder.activities = [];
    var entries = document.querySelectorAll("#activities-for-subcategories-container .entry");
    for(i = 0; i < entries.length; i++) {
        if (entries[i].getAttribute("data-activity-on-asset-instance-index") == index) {
            switch (direction) {
                case "up":
                    if (i > 0) {
                        entries[i].parentNode.insertBefore(entries[i], entries[i - 1]);
                    }
                    break;
                case "down":
                    if (entries.length > (i + 1)) {
                        insertAfter(entries[i + 1], entries[i]);
                    }
                    break;
            }
        }
    }
    //reloading with new positions
    entries = document.querySelectorAll("#activities-for-subcategories-container .entry");
    for(i = 0; i < entries.length; i++) {
        if(entries[i].getAttribute("data-activity-on-asset-instance-id") != null) {
            var activity = {};
            activity.activityForSubcategoryId = parseInt(entries[i].getAttribute("data-activity-on-asset-instance-id"));
            activity.order = i;
            newOrder.activities.push(activity);
        }
    }
    var savingIcon = document.querySelector("#view-activities-for-subcategories-popup .saving-icon-holder");
    dom.removeClass(savingIcon, "hidden");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            if(xhttp.status === 200) {
                dom.addClass(savingIcon, "hidden");
            }
            else {
                dom.addClass(savingIcon, "hidden");
                popup("outcome", true, false, false);
                setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newOrder), xhttp.responseText);
            }
        }
        catch(e) {
            dom.addClass(savingIcon, "hidden");
            popup("outcome", true, false, false);
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newOrder), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/saveNewActivitiesForSubcategoryOrder.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newOrder));
}

function moveIntervention() {
    popup("move-intervention", false, false, false);
}

function ajaxSendReminder(method){
    var request = {};
    request.interventionId = parseInt(selectedIntervention);
    request.method = method;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            selectIntervention(selectedIntervention);
            closePopup();
            successToast("Sollecito inviato con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/sendInterventionReminder.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(request));
}

function ajaxConfirmMoveIntervention() {
    var interventionEdit = {};
    var newDateComponents = document.getElementById("move-intervention-date").value.split("/");
    var newStart = new Date();
    newStart.setFullYear(newDateComponents[2],newDateComponents[1]-1,newDateComponents[0]);
    var startTimeComponents = ui.pickerPopupButton.getValue("move-intervention-start-time").split(":");
    newStart.setHours(startTimeComponents[0],startTimeComponents[1],0,0);
    var newEnd = new Date();
    newEnd.setFullYear(newDateComponents[2],newDateComponents[1]-1,newDateComponents[0]);
    var endTimeComponents = ui.pickerPopupButton.getValue("move-intervention-end-time").split(":");
    newEnd.setHours(endTimeComponents[0],endTimeComponents[1],0,0);
    interventionEdit.newStart = newStart.toISOString();
    interventionEdit.newEnd = newEnd.toISOString();
    interventionEdit.interventionId = parseInt(selectedIntervention);
    interventionEdit.description = document.getElementById("move-intervention-causal").value;
    if(interventionEdit.description === "") {
        delete interventionEdit.description;
    }
    interventionEdit.resendNotificationsToExecutors = (document.getElementById("move-intervention-resend-notification-to-executors").getAttribute("data-checked")==="1");
    interventionEdit.resendNotificationsToSiteManagers = (document.getElementById("move-intervention-resend-notification-to-site-managers").getAttribute("data-checked")==="1");
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status === 200) {
            selectIntervention(selectedIntervention);
            closePopup();
            successToast("Data intervento modificata con successo");
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/intervention/move.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(interventionEdit));
}

function tagLikeInputlostFocus(ev) {
    var tagLikeInput = ev.currentTarget;
    var tagLikeContainer = tagLikeInput.parentElement;
    var txt = tagLikeInput.value.replace(/[^a-zA-Z0-9\u0020\u00e0\u00e8\u00ec\u00f2\u00f9\u00c0\u00c8\u00cc\u00d2\u00d9\u00e1\u00e9\u00ed\u00f3\u00fa\u00fd\u00c1\u00c9\u00cd\u00d3\u00da\u00dd\u00e2\u00ea\u00ee\u00f4\u00fb\u00c2\u00ca\u00ce\u00d4\u00db\u00e3\u00f1\u00f5\u00c3\u00d1\u00d5\u00e4\u00eb\u00ef\u00f6\u00fc\u00ff\u00c4\u00cb\u00cf\u00d6\u00dc\u0178\u00e7\u00c7\u00df\u00d8\u00f8\u00c5\u00e5\u00c6\u00e6\u0153+\-.#]/ig, ""); // allowed characters
    if(txt) {
        var newSpan = document.createElement("span");
        newSpan.innerHTML = txt;
        newSpan.setAttribute("data-text", txt);
        defineTapListenerOnElement(newSpan,function(e) {
            tagLikeContainer.removeChild(e.currentTarget);
            var currentTags = tagLikeContainer.querySelector("input[type=hidden]").value.split(",");
            currentTags.splice(currentTags.indexOf(e.currentTarget.getAttribute("data-text")), 1);
            tagLikeContainer.querySelector("input[type=hidden]").value = currentTags.join(",");
        });
        tagLikeContainer.insertBefore(newSpan, tagLikeContainer.querySelector("input[type=text]"));
        var currentTags = tagLikeContainer.querySelector("input[type=hidden]").value.split(",");
        if(currentTags.length === 1 && currentTags[0] === "") {
            currentTags = [];
        }
        currentTags.push(txt);
        tagLikeContainer.querySelector("input[type=hidden]").value = currentTags.join(",");
    }
    ev.currentTarget.value = "";
}

function attachEventsToTagLikeInputContainer(containerId) {
    var tagLikeContainer = document.getElementById(containerId);
    var tagLikeInput = tagLikeContainer.querySelector("input[type=text]");
    tagLikeInput.onblur = tagLikeInputlostFocus;
    tagLikeInput.onkeydown = function(e) {
        // e.which contains the pressed button that triggered the onkeyup event
        if((e.which || e.keyCode) === KEYCODE_ENTER) {
            tagLikeInputlostFocus(e);//to trigger focusout from the element I focus the body
            e.preventDefault();
            return false;
        }
    };
    tagLikeInput.onkeyup = function(e) {
        // e.which contains the pressed button that triggered the onkeyup event
        if((e.which || e.keyCode) === KEYCODE_COMMA) {
            tagLikeInputlostFocus(e);//to trigger focusout from the element I focus the body
        }
    };
}

function createTicketFromAsset() {
    document.getElementById("ticket-site").value = lastViewedAssetInstance.siteId;
    createTicketPopup(true, false);
}

function createTicketFromAnomaly() {
    document.getElementById("ticket-site").value = lastViewedAnomaly.siteId;
    createTicketPopup(false, true);
}

function createAnomalyFromAsset() {
    document.getElementById("anomaly-site").value = lastViewedAssetInstance.siteId;
    createAnomalyPopup(true);
}

function showAvailableCustomerSatisfactionReportButtons() {
    popup("customer-satisfaction-report", false, false, false);
    var documentsArea = document.getElementById("customer-satisfaction-reports-container");
    var tempHtml = "";
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                for(var i = 0; i < response.customerSatisfactionPoints.length; i++) {
                    tempHtml += "<div class='data-room-file' onclick='ajaxGetCustomerSatisfactionReport(" + response.customerSatisfactionPoints[i].id + ",\"" + response.customerSatisfactionPoints[i].name + "\");'><i class='data-room-file-icon fa fa-file-pdf-o fa-2x' aria-hidden='true'></i><br>" + response.customerSatisfactionPoints[i].name + "</div>";
                }
                tempHtml+="<br><br>";
            }
            else {
                tempHtml = "<div class='message error'>Errore: " + response.message + "</div>";
            }
        }
        catch(e) {
            tempHtml = "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>";
        }
        documentsArea.innerHTML = tempHtml;
        scroll.refreshPopupById("customer-satisfaction-report", false);
    };
    xhttp.open("GET", BASE_URL + "/ws/customerSatisfactionPoint/get.php?companyId=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function showAvailableFireLogBooks() {
    popup("fire-log-book", false, false, false);
    var documentsArea = document.getElementById("fire-log-books-container");
    var tempHtml = "";
    for(var i = 0; i < sites.length; i++) {
        tempHtml += "<div class='data-room-file' onclick='ajaxGetFireLogBookBySiteId(" + sites[i].siteId + ");'><i class='data-room-file-icon fa fa-file-pdf-o fa-2x' aria-hidden='true'></i><br>" + sites[i].name + "</div>";
    }
    tempHtml+="<br>";
    documentsArea.innerHTML = tempHtml;
    scroll.refreshPopupById("fire-log-book", false);
}

function ajaxGetFireLogBookBySiteId(siteId) {
    successToast("Apertura registro antincendio in corso...");
    ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/generateFireLogBookPDF.php?siteId=" + siteId + "&authToken=" + currentAuthToken, "Registro-antincendio.pdf", false, null, "pdf");
}

function ajaxGetCustomerSatisfactionReport(customerSatisfactionPointId,customerSatisfactionPointName) {
    successToast("Apertura report in corso...");
    var newWindow = null;
    if(Capacitor.platform=='web') {
        newWindow=window.open();
    }
    ajaxGenerateCustomerSatisfactionPointOtt(customerSatisfactionPointId,function(ott) {
        var regex = / /gi;
        var filename = "Report-customer-satisfaction-" + customerSatisfactionPointName.replace(regex,"-") + ".pdf";
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/customerSatisfactionPoint/report.php?customerSatisfactionPointId=" + customerSatisfactionPointId + "&ott=" + ott, filename, false, newWindow, "pdf");
    });

}
function ajaxCreateCategoryGroup() {
    var newCategoryGroup = {};
    newCategoryGroup.background = document.getElementById("add-category-group-bg-class").value;
    newCategoryGroup.icon = document.getElementById("add-category-group-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Gruppo di categorie creato correttamente");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategoryGroup), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategoryGroup), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createCategoryGroup.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCategoryGroup));
}

function ajaxCreateCategory() {
    var newCategory = {};
    newCategory.categoryGroup = parseInt(document.getElementById("add-category-cat-group").value);
    newCategory.name = document.getElementById("add-category-name").value;
    newCategory.icon = document.getElementById("add-category-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Categoria creata correttamente");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategory), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategory), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createCategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCategory));
}

function ajaxCreateSubcategory() {
    var newSubcategory = {};
    newSubcategory.category = parseInt(document.getElementById("add-subcategory-category").value);
    newSubcategory.name = document.getElementById("add-subcategory-name").value;
    newSubcategory.icon = document.getElementById("add-subcategory-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Sottocategoria creata correttamente");
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newSubcategory), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(JSON.stringify(e), JSON.stringify(newSubcategory), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/createSubcategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newSubcategory));
}

function ajaxEditCategoryGroup() {
    var newCategoryGroup = {};
    newCategoryGroup.categoryGroup = parseInt(lastEditedCategoryGroup);
    newCategoryGroup.background = document.getElementById("edit-category-group-bg-class").value;
    newCategoryGroup.icon = document.getElementById("edit-category-group-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Gruppo di categorie modificato correttamente");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategoryGroup), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategoryGroup), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editCategoryGroup.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCategoryGroup));
}

function ajaxEditCategory() {
    var newCategory = {};
    newCategory.categoryGroup = parseInt(document.getElementById("edit-category-cat-group").value);
    newCategory.category = parseInt(lastEditedCategory);
    newCategory.name = document.getElementById("edit-category-name").value;
    newCategory.icon = document.getElementById("edit-category-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Categoria modificata correttamente");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategory), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newCategory), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editCategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newCategory));
}

function ajaxEditSubcategory() {
    var newSubcategory = {};
    newSubcategory.category = parseInt(document.getElementById("edit-subcategory-category").value);
    newSubcategory.subcategory = parseInt(lastEditedSubcategory);
    newSubcategory.name = document.getElementById("edit-subcategory-name").value;
    newSubcategory.icon = document.getElementById("edit-subcategory-icon").value;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Sottocategoria modificata correttamente");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newSubcategory), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(newSubcategory), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/editSubcategory.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(newSubcategory));
}

function annualInterventionReportPreviousYear(){
    lastYear = lastYear-1;
    renderAnnualInterventionReport(lastYear);
}

function annualInterventionReportFollowingYear(){
    lastYear = lastYear+1;
    renderAnnualInterventionReport(lastYear);
}

function openAnnualInterventionReport() {
    changePage("page-annual-interventions-report");
    renderAnnualInterventionReport(lastYear);
}

function renderAnnualInterventionReport(year){
    document.getElementById("page-annual-interventions-report-container").innerHTML = "<div class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</div>";
    scroll.refreshByName("interventions-report");
    document.getElementById("annual-report-year-container").innerHTML = year;
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            applyAnnualInterventionReportFilters(response.report);
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
            popup("outcome", false, false, false);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getInterventionsYearReport.php?year=" + year + "&companyId=" + globalFilterByCompany, true);
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function ajaxGetInterventionsByYear(requestedYear) {
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            selectedYearInterventions = response.interventions;
        }
        else {
            setInnerHtml(outcomePopup, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
            popup("outcome", false, false, false);
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL + "/ws/getInterventionsByYear.php?companyId=" + globalFilterByCompany + "&year=" + requestedYear, true);
    }
    else {
        xhttp.open("GET", BASE_URL + "/ws/getInterventionsByYear.php?year=" + requestedYear, true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send("");
}

function toggleInterventionReport(reportId) {
    var reportTable = document.getElementById("annual-intervention-report-" + reportId);
    var toggleArrow = document.getElementById("annual-intervention-report-toggle-btn-" + reportId);
    if(dom.hasClass(reportTable, "hidden")) {
        dom.removeClass(reportTable, "hidden");
        setInnerHtml(
            toggleArrow,
            "<i class='fa fa-chevron-up' aria-hidden='true'></i>"
        );
    }
    else {
        dom.addClass(reportTable, "hidden");
        setInnerHtml(
            toggleArrow,
            "<i class='fa fa-chevron-down' aria-hidden='true'></i>"
        );
    }
    scroll.refreshByName("interventions-report");
}

function applyAnnualInterventionReportFilters(downloadedYearReport) {
    var tempHtml = "";
    //every report is related to an executor, iterating through executors
    if(downloadedYearReport && downloadedYearReport.length>0) {
        for(var i = 0; i < downloadedYearReport.length; i++) {
            //printing executor
            tempHtml += "<h3>" + ((downloadedYearReport[i].executorName === null) ? "Nessun esecutore assegnato" : downloadedYearReport[i].executorName) + "</h3>";
            //itering through sites
            for(var j in downloadedYearReport[i].sites) {
                if(downloadedYearReport[i].sites.hasOwnProperty(j)) {
                    //printing site
                    tempHtml += "<div><button class='toggle-annual-intervention-report' onclick='toggleInterventionReport(\"" + i + "-" + j + "\");'><span>" + ((downloadedYearReport[i].executorName === null) ? "Nessun esecutore assegnato" : downloadedYearReport[i].executorName) + " - " + downloadedYearReport[i].sites[j].siteName + "</span><span class='annual-intervention-report-toggle-btn' id='annual-intervention-report-toggle-btn-" + i + "-" + j + "'><i class='fa fa-chevron-down' aria-hidden='true'></i></span></button></div>";
                    tempHtml += "<table id='annual-intervention-report-" + i + "-" + j + "' class='hidden'><tr><th>Categoria</th><th>Sottocategoria</th><th>Date interventi M.O.P.</th><th>Date interventi ticket</th></tr>";
                    //iterating through subcategories
                    for(var k in downloadedYearReport[i].sites[j].subcategories) {
                        if(downloadedYearReport[i].sites[j].subcategories.hasOwnProperty(k)) {
                            //printing subcategory
                            tempHtml += "<tr><td class='td-containing-icon'><div class='category-small b-" + downloadedYearReport[i].sites[j].subcategories[k].categoryIconBackground + "'>" + downloadedYearReport[i].sites[j].subcategories[k].categoryIcon + "</div>" + downloadedYearReport[i].sites[j].subcategories[k].category + "</td><td class='td-containing-icon'><div class='category-small b-" + downloadedYearReport[i].sites[j].subcategories[k].categoryIconBackground + "'>" + downloadedYearReport[i].sites[j].subcategories[k].subcategoryIcon + "</div>" + downloadedYearReport[i].sites[j].subcategories[k].subcategory + "</td>";
                            //iterating through interventions
                            var mpDates = [];
                            var ticketDates = [];
                            for(var m in downloadedYearReport[i].sites[j].subcategories[k].interventions) {
                                if(downloadedYearReport[i].sites[j].subcategories[k].interventions.hasOwnProperty(m)) {
                                    var intervention = {};
                                    intervention.date = downloadedYearReport[i].sites[j].subcategories[k].interventions[m].plannedStartDate.split(" ")[0].split("-").reverse().join("/");
                                    intervention.id = downloadedYearReport[i].sites[j].subcategories[k].interventions[m].interventionId;
                                    intervention.completed = downloadedYearReport[i].sites[j].subcategories[k].interventions[m].completed;
                                    if(downloadedYearReport[i].sites[j].subcategories[k].interventions[m].containsOrdinaryMaintenance) {
                                        mpDates.push(intervention);
                                    }
                                    if(downloadedYearReport[i].sites[j].subcategories[k].interventions[m].containsTicket) {
                                        ticketDates.push(intervention);
                                    }
                                }
                            }
                            //Since the date is formatted as european I can do this
                            mpDates.sort(function(a, b) {
                                var aDateComponents = a.date.split("/");
                                var bDateComponents = b.date.split("/");
                                var aValue = (parseInt(aDateComponents[0])) + (parseInt(aDateComponents[1] * 100)) + (parseInt(aDateComponents[2] * 10000));
                                var bValue = (parseInt(bDateComponents[0])) + (parseInt(bDateComponents[1] * 100)) + (parseInt(bDateComponents[2] * 10000));
                                if(aValue < bValue) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });
                            ticketDates.sort(function(a, b) {
                                var aDateComponents = a.date.split("/");
                                var bDateComponents = b.date.split("/");
                                var aValue = (parseInt(aDateComponents[0])) + (parseInt(aDateComponents[1] * 100)) + (parseInt(aDateComponents[2] * 10000));
                                var bValue = (parseInt(bDateComponents[0])) + (parseInt(bDateComponents[1] * 100)) + (parseInt(bDateComponents[2] * 10000));
                                if(aValue < bValue) {
                                    return -1;
                                }
                                else {
                                    return 1;
                                }
                            });

                            tempHtml += "<td>";
                            mpDates.forEach(function(mpDate){
                                tempHtml+="<div class='inline-dates-report'>";
                                tempHtml+="<a "+(mpDate.completed?"class='completed'":"")+" onclick='selectIntervention("+mpDate.id+");'>"+mpDate.date+"</a>";
                                tempHtml+="</div>";
                            });
                            tempHtml += "</td>";
                            tempHtml += "<td>";
                            ticketDates.forEach(function(ticketDate){
                                tempHtml+="<div class='inline-dates-report'>";
                                tempHtml+="<a "+(ticketDate.completed?"class='completed'":"")+" onclick='selectIntervention("+ticketDate.id+");'>"+ticketDate.date+"</a>";
                                tempHtml+="</div>";
                            });
                            tempHtml += "</td>";
                            tempHtml += "</tr>";
                        }
                    }
                    tempHtml += "</table><br>";
                }
            }
        }
    }
    else {
        tempHtml = "<div class='no-results-message'>Nessun intervento pianificato per quest'anno.</div>";
    }
    setInnerHtml(
        document.getElementById("page-annual-interventions-report-container"),
        tempHtml,
        function(){
            scroll.refreshByName("interventions-report");
        }
    );
}

/* When the user clicks on the button,
toggle between hiding and showing the dropdown content */
function dropdownClicked(id) {
    dropdownFocused(id);
    if(document.getElementById(id + "-text").value === "") {
        setInnerHtml(document.getElementById(id), "");
    }
    switch(id) {
        case "data-room-search-dropdown":
            if(lastQueriedDocuments == null)
                ajaxReloadDataRoomFilters();
            break;
    }
    setTimeout(function() {
        if(dom.hasClass(document.getElementById(id), "show"))
            dom.removeClass(document.getElementById(id), "show");
        else
            dom.addClass(document.getElementById(id), "show");
    }, 5);
}

function dropdownFocused(id) {
    dom.addClass(document.getElementById(id + "-text"), "focused");
}

function dropdownLostFocus(id) {
    var focusedElements = document.getElementById(id + "-text").parentNode.querySelectorAll(".focused");
    for(var i = 0; i < focusedElements.length; i++) {
        dom.removeClass(focusedElements[i], "focused");
    }
}

function dropdownChanged(id, event) {
    dropdownFocused(id);
    var ignoreChange = false;
    if(event) {
        switch(event.which || event.keyCode) {
            case KEYCODE_ARROW_DOWN:
            case KEYCODE_ARROW_UP:
            case KEYCODE_ARROW_LEFT:
            case KEYCODE_ARROW_RIGHT:
                ignoreChange = true;
                preventEvent(event);
                break;
        }
    }
    if(!ignoreChange) {
        if(document.getElementById(id + "-text").value === "") {
            setInnerHtml(document.getElementById(id), "");
        }
        switch(id) {
            case "asset-search-dropdown":
            case "asset-search-dropdown-2":
                reloadAssetSearchMatchResults(id);
                dom.addClass(document.getElementById(id), "show");
                break;
            case "ticket-search-dropdown":
                reloadTicketSearchMatchResults();
                dom.addClass(document.getElementById("ticket-search-dropdown"), "show");
                break;
            case "data-room-search-dropdown":
            case "document-search-dropdown-2":
                reloadDataRoomSearchMatchResults(id);
                dom.addClass(document.getElementById(id), "show");
                break;
        }
        if(document.getElementById(id + "-text").value !== "") {
            dom.removeClass(document.getElementById(id + "-clear"), "hidden");
        }
        else {
            dom.addClass(document.getElementById(id + "-clear"), "hidden");
        }
    }
}

function clearDropdown(id, doNotDrawResults) {
    document.getElementById(id + "-text").value = "";
    if(id === "data-room-search-dropdown") {
        removeDataRoomSearchFilters();
        if(!doNotDrawResults) {
            reloadDataRoomSearchFilters("none", "",id);
        }
    }
    if(!doNotDrawResults) {
        dropdownChanged(id, null);
    }
}

function removeDataRoomSearchFilters() {
    dataRoomAppliedFilter = {};
    dataRoomAppliedFilter.filterType = "none";
    dataRoomAppliedFilter.filterValue = "";
}

function applyDataRoomSearch(filterType, filterValue,id) {
    dataRoomAppliedFilter.filterType = filterType;
    dataRoomAppliedFilter.filterValue = filterValue;
    if(filterType === "none") {
        dom.addClass(document.getElementById(id+"-clear"), "hidden");
        if(id === "data-room-search-dropdown") {
            removeDataRoomSearchFilters();
        }
    }
    else {
        dom.removeClass(document.getElementById(id+"-clear"), "hidden");
    }
    document.getElementById(id+"-text").value = filterValue;
    reloadDataRoomSearchFilters(filterType, filterValue,id);
}

function clearDataRoomFilters() {
    document.getElementById("data-room-filter-document-subtype").value = "";
    document.getElementById("data-room-filter-document-type").value = "";
    dataRoomDocumentTypeFilterChanged(true);
    ajaxReloadDataRoomFilters();
    countVdrAppliedFilters();
    closePopup();
}
function renderDocumentIcon(format,x2){
    var additionalClass = x2?"fa-2x":"";
    var icon = "<i class='data-room-file-icon fa fa-file "+additionalClass+"' aria-hidden='true'></i>";
    switch(format) {
        case "pdf":
            icon = "<i class='data-room-file-icon fa fa-file-pdf-o "+additionalClass+"' aria-hidden='true'></i>";
            break;
        case "doc":
        case "docx":
        case "odt":
            icon = "<i class='data-room-file-icon fa fa-file-word-o "+additionalClass+"' aria-hidden='true'></i>";
            break;
        case "xls":
        case "xlsx":
        case "ods":
            icon = "<i class='data-room-file-icon fa fa-file-excel-o "+additionalClass+"' aria-hidden='true'></i>";
            break;
        case "txt":
            icon = "<i class='data-room-file-icon fa fa-file-text-o "+additionalClass+"' aria-hidden='true'></i>";
            break;
        case "jpg":
        case "png":
            icon = "<i class='data-room-file-icon fa fa-file-image-o "+additionalClass+"' aria-hidden='true'></i>";
            break;
    }
    return icon;
}

function renderDocumentEntry(documentToBeRendered,documentIndex) {
    var icon = renderDocumentIcon(documentToBeRendered.fileFormat,true);
    return "<div class='data-room-file' id='data-room-file-" + documentToBeRendered.documentId + "' data-file-index='" + documentIndex + "' onclick='selectDataRoomDocument(" + documentIndex + ",event);'>"+icon+"<br>" + documentToBeRendered.fileName + "</div>";
}

function renderDocumentRadio(documentToBeRendered,documentIndex) {
    var subcategoriesTextHtml = "";
    var tempHtml = "<button " +
        " type='button' " +
        " class='pseudo-radio-button'" +
        " onclick='togglePseudoRadioByClass(\"selected-document-for-document-search\",this);'" +
        ">" +
        "<span " +
        " data-value='" + documentToBeRendered.documentId + "' " +
        " data-checked='0'" +
        " class='pseudo-radio selected-document-for-document-search' " +
        ">" +
        "<i class='fa fa-file-pdf-o' aria-hidden='true'></i>  " +
        documentToBeRendered.fileName +
        "</span>" +
        "</button>";
    return tempHtml;
}

function reloadDataRoomSearchFilters(filterType,filterValue,id) {
    var targetElement; //Where the resulting assets will be rendered
    var targetElementUpdateCallback; //Callback after the assets are rendered
    var renderingFunction; //The function to use to render the assets
    var filterNoneCallback; //Callback after a "none" filter is applied (empty search box)
    switch(id) {
        case "data-room-search-dropdown":
            targetElement = document.getElementById("data-room-main-col-content");
            targetElementUpdateCallback = function () {
                scroll.refreshByName("vdr");
            };
            renderingFunction = renderDocumentEntry;
            filterNoneCallback = function() {
                dom.addClass(document.getElementById(id+"-clear"), "hidden");
                var tempHtml = "";
                if(lastQueriedDocuments != null) {
                    var showedDocuments = 0;
                    for(var i = 0; i < lastQueriedDocuments.length; i++) {
                        tempHtml += renderingFunction(lastQueriedDocuments[i],i);
                        showedDocuments++;
                    }
                    if(showedDocuments === 0) {
                        tempHtml = "<span class='no-results-message'>Nessun documento corrisponde ai filtri di ricerca applicati.</span>";
                    }
                    targetElement.innerHTML = tempHtml;
                    targetElementUpdateCallback();
                }
            };
            break;
        case "document-search-dropdown-2":
            targetElement = document.getElementById("search-document-popup-results");
            targetElementUpdateCallback = function() {
                scroll.refreshPopupById("search-document", false);
            };
            renderingFunction = renderDocumentRadio;
            filterNoneCallback = function() {
                targetElement.innerHTML="";
                targetElementUpdateCallback();
            };
            break;
    }

    if(filterType === "none") {
        filterNoneCallback();
    }
    else {
        dom.removeClass(document.getElementById(id+"-clear"), "hidden");
        var showedDocuments, i, tempHtml="";
        switch(filterType) {
            case "filename":
                tempHtml = "";
                if (lastQueriedDocuments != null) {
                    showedDocuments = 0;
                    for (i = 0; i < lastQueriedDocuments.length; i++) {
                        if (lastQueriedDocuments[i].fileName.toUpperCase() === filterValue.toUpperCase()) {
                            tempHtml += renderingFunction(lastQueriedDocuments[i],i);
                            showedDocuments++;
                        }
                    }
                    if (showedDocuments === 0) {
                        tempHtml = "<span class='no-results-message'>Nessun documento corrisponde ai filtri di ricerca applicati.</span>";
                    }
                }
                break;
            case "tag":
                tempHtml = "";
                if (lastQueriedDocuments != null) {
                    showedDocuments = 0;
                    for (i = 0; i < lastQueriedDocuments.length; i++) {
                        var found = 0;
                        for (var j = 0; j < lastQueriedDocuments[i].tags.length; j++) {
                            if (lastQueriedDocuments[i].tags[j].toUpperCase() === filterValue.toUpperCase()) {
                                found++;
                                break;
                            }
                        }
                        if (found > 0) {
                            tempHtml += renderingFunction(lastQueriedDocuments[i],i);
                            showedDocuments++;
                        }
                    }
                    if (showedDocuments === 0) {
                        tempHtml = "<span class='no-results-message'>Nessun documento corrisponde ai filtri di ricerca applicati.</span>";
                    }
                }
                break;
        }
        setInnerHtml(
            targetElement,
            tempHtml,
            targetElementUpdateCallback
        );
    }
}

// Update the count down every 1 second
var ticketsCountdownInterval = setInterval(function() {
    // Get current date and time
    var now;
    if(tickets) {
        for(var i = 0; i < tickets.length; i++) {
            ticketRowToWriteTo = document.getElementById("ticket-row-id-" + tickets[i].ticketId);
            if(ticketRowToWriteTo) {
                var tempHtml = "";
                var showSlaStates = ["Nuovo", "Assegnato"];
                if(tickets[i].slaExpiration && (showSlaStates.indexOf(tickets[i].state) >= 0)) {
                    var d = new Date(tickets[i].isoSlaExpiration);
                    var countDownDate = d.getTime();
                    // Find the distance between now an the count down date in milliseconds
                    now = new Date().getTime();
                    var distance = parseInt((countDownDate - now) / 1000);
                    // Time calculations for days, hours, minutes and seconds
                    var days,hours,minutes,seconds;
                    if(distance>=0) {
                        days = Math.floor(distance / (60 * 60 * 24));
                        hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
                        minutes = Math.floor((distance % (60 * 60)) / (60));
                        seconds = Math.floor((distance % (60)));
                    }
                    else {
                        days = Math.ceil(distance / (60 * 60 * 24));
                        hours = Math.ceil((distance % (60 * 60 * 24)) / (60 * 60));
                        minutes = Math.ceil((distance % (60 * 60)) / (60));
                        seconds = Math.ceil((distance % (60)));
                    }                    
                    // Display the result in the element with id="demo"
                    if(distance < 0) {
                        tempHtml += "scaduto da ";
                        tempHtml += ((days !== 0) ? (((-1) * days) + "g ") : "") + ((-1) * hours) + "h " + ((-1) * minutes) + "m " + ((-1) * seconds) + "s ";
                        dom.removeClass(document.getElementById("ticket-row-id-" + tickets[i].ticketId).querySelector(".sla-container"), "green-sla");
                        dom.addClass(document.getElementById("ticket-row-id-" + tickets[i].ticketId).querySelector(".sla-container"), "red-sla");
                    }
                    else {
                        tempHtml += ((days !== 0) ? (days + "g ") : "") + hours + "h " + minutes + "m " + seconds + "s ";
                        dom.removeClass(document.getElementById("ticket-row-id-" + tickets[i].ticketId).querySelector(".sla-container"), "red-sla");
                        dom.addClass(document.getElementById("ticket-row-id-" + tickets[i].ticketId).querySelector(".sla-container"), "green-sla");
                    }
                }
                setInnerHtml(ticketRowToWriteTo.querySelector(".sla-container"), tempHtml);
            }
        }
    }
}, 1000);

function applyAssetSearch(filterType, filterValue, id) {
    var targetElement; //Where the resulting assets will be rendered
    var targetElementUpdateCallback; //Callback after the assets are rendered
    var renderingFunction; //The function to use to render the assets
    var filterNoneCallback; //Callback after a "none" filter is applied (empty search box)
    switch(id) {
        case "asset-search-dropdown":
            targetElement = document.getElementById("assets-list-container");
            targetElementUpdateCallback = function(){
                scroll.refreshByName("assets-list");
            };
            renderingFunction = renderAssetEntry;
            filterNoneCallback = reloadAssetNavFilters;
            break;
        case "asset-search-dropdown-2":
            targetElement = document.getElementById("search-asset-popup-results");
            targetElementUpdateCallback = function() {
                scroll.refreshPopupById("search-asset", false);
            };
            renderingFunction = renderAssetRadio;
            filterNoneCallback = function() {
                targetElement.innerHTML="";
                targetElementUpdateCallback();
            };
            break;
    }
    if(filterType === "none") {
        dom.addClass(document.getElementById(id+"-clear"), "hidden");
        filterNoneCallback();
    }
    else {
        dom.removeClass(document.getElementById(id+"-clear"), "hidden");
        switch(filterType) {
            case "subcategory":
                var subcategoryName="Sottocategoria";
                subcategories.forEach(function(subcategory){
                    if(subcategory.subcategoryId==filterValue){
                        subcategoryName = subcategory.name;
                    }
                });
                document.getElementById(id+"-text").value = subcategoryName;
                break;
            default:
                document.getElementById(id+"-text").value = filterValue;
                break;
        }
        var tempHtml = "";
        if(preFetchedAssets != null) {
            var showedAssets = 0;
            for(var i = 0; i < preFetchedAssets.length; i++) {
                if(preFetchedAssets[i].deletion===null) {
                    switch(filterType) {
                        case "qr":
                            if(preFetchedAssets[i].qrCodeId) {
                                if(preFetchedAssets[i].qrCodeId.toUpperCase() === filterValue.toUpperCase()) {
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            }
                            break;
                        case "internalcode":
                            if(preFetchedAssets[i].internalCode) {
                                if(preFetchedAssets[i].internalCode.toUpperCase() === filterValue.toUpperCase()) {
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            }
                            break;
                        case "registrationnumber":
                            if(preFetchedAssets[i].registrationNumber) {
                                if(preFetchedAssets[i].registrationNumber.toUpperCase() === filterValue.toUpperCase()) {
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            }
                            break;
                        case "subcategory":
                            preFetchedAssets[i].subcategories.forEach(function(subcategory){
                                if(subcategory.subcategoryId == filterValue){
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            });
                            break;
                        case "brand":
                            if(preFetchedAssets[i].brand) {
                                if(preFetchedAssets[i].brand.toUpperCase() === filterValue.toUpperCase()) {
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            }
                            break;
                        case "model":
                            if(preFetchedAssets[i].model) {
                                if(preFetchedAssets[i].model.toUpperCase() === filterValue.toUpperCase()) {
                                    tempHtml += renderingFunction(preFetchedAssets[i]);
                                    showedAssets++;
                                }
                            }
                            break;
                    }
                }
            }
            if(showedAssets === 0) {
                tempHtml = "<span class='no-results-message'>Nessun asset corrisponde ai filtri di ricerca applicati.</span>";
            }
            setInnerHtml(
                targetElement,
                tempHtml,
                targetElementUpdateCallback
            );
        }
    }
}

function renderAssetEntry(asset) {
    var tempHtml = "";
    tempHtml += "<div class='entry' id='assets-nav-table-" + asset.assetInstanceId + "' onclick='selectAssetInstance(" + asset.assetInstanceId + ");'><div>";
    var iconsHtml = "";
    var subcategoriesTextHtml = [];
    for(var j = 0; j < asset.subcategories.length; j++) {
        if(asset.subcategories[j].deletion==null) {
            iconsHtml += "<div class='category-small b-" + asset.subcategories[j].categoryIconBackground + "'>" + asset.subcategories[j].categoryIcon + "</div>";
            subcategoriesTextHtml.push("<div><strong>" + asset.subcategories[j].subcategoryName + "</strong></div>");
        }
    }
    tempHtml += "<div>" + iconsHtml + "</div>";
    tempHtml += "<div>" + subcategoriesTextHtml.join(" / ");
    if(asset.brand !== null) {
        tempHtml += "<div>" + asset.brand + "</div>";
    }
    if(asset.model !== null) {
        tempHtml += "<div>" + asset.model + "</div>";
    }
    if(asset.type !== null) {
        tempHtml += "<div>" + asset.type + "</div>";
    }
    if(asset.qrCodeId !== null) {
        tempHtml += "<div><strong>QR </strong>" + asset.qrCodeId + "</div>";
    }
    if(asset.internalCode !== null) {
        tempHtml += "<div><strong>Cod. interno </strong>" + asset.internalCode + "</div>";
    }
    if(asset.registrationNumber !== null) {
        tempHtml += "<div><strong>Matr. </strong>" + asset.registrationNumber + "</div>";
    }
    if(asset.floor!=null||asset.positionHint!=null){
        tempHtml+="<div class='asset-location-holder'>";
        if(asset.floor !== null) {
            tempHtml += "<div class='asset-floor'><strong><i class='fa fa-arrows-v' aria-hidden='true'></i> </strong>" + getFloorNameFromFloorId(asset.floor) + "</div>";
        }
        if(asset.positionHint !== null) {
            tempHtml += "<div class='asset-location'><strong><i class='fa fa-map-marker' aria-hidden='true'></i> </strong>" + asset.positionHint + "</div>";
        }
        tempHtml+="</div>";
    }
    
    tempHtml += "</div>";
    tempHtml += "</div></div></div>";
    return tempHtml;
}

function renderAssetRadio(asset) {
    var subcategoriesTextHtml = "";
    for(var j = 0; j < asset.subcategories.length; j++) {
        if(asset.subcategories[j].deletion==null) {
            subcategoriesTextHtml+="<strong>" + asset.subcategories[j].subcategoryName + "</strong> ";
        }
    }
    var tempHtml = "<button " +
        " type='button' " +
        " class='pseudo-radio-button'" +
        " onclick='togglePseudoRadioByClass(\"selected-asset-for-asset-search\",this);'" +
        ">" +
        "<span " +
        " data-value='" + asset.assetInstanceId + "' " +
        " data-checked='0'" +
        " class='pseudo-radio selected-asset-for-asset-search' " +
        ">" +
        "<strong>" + subcategoriesTextHtml + "</strong>" +
        ((asset.brand!==null)?(" " + asset.brand):"") +
        ((asset.model!==null)?(" " + asset.model):"") +
        ((asset.qrCodeId!==null)?(" <strong>QR</strong> " + asset.qrCodeId):"") +
        ((asset.internalCode!==null)?(" <strong>Cod. interno</strong> " + asset.internalCode):"") +
        ((asset.registrationNumber!==null)?(" <strong>Matr.</strong> " + asset.registrationNumber):"") +
        ((asset.positionHint!==null)?("&nbsp;&nbsp;<strong><i class='fa fa-map-marker' aria-hidden='true'></i> </strong>" + asset.positionHint):"") +
        "</span>" +
        "</button>";
    return tempHtml;
}

function renderContractEntry(contract) {
    var tempHtml = "<div onclick='ajaxViewContract(" + contract.contractId + ");' class='entry' id='contract-row-id-" + contract.contractId + "'>" +
        "<div class='contract-top-row'>" +
        "<span class='contract-number'>N°" + doubleZeroPadding(contract.contractId) + "</span> " +
        "<span class='order-number'>" + ((contract.orderNumber) ? doubleZeroPadding(contract.orderNumber) : "") + "</span> " +
        "<span class='contract-state " + ((contract.state === "Approvato") ? "green-state" : "") + "'>" + contract.state + "</span> " +
        "<br><span class='sla-container'></span>" +
        "</div>" +
        "<div class='contract-activities'>";
    tempHtml += "</div>" +
        "<div class='contract-bottom-row'>" +
        "<span class='contract-period'><span class='ticket-bottom-row-title'>DAL</span><span>" + contract.startDate + "</span><br><span class='ticket-bottom-row-title'>AL</span><span>" + contract.endDate + "</span></span> " +
        "<span class='ticket-reported-by'><span class='ticket-bottom-row-title'>Cliente</span><span>" + contract.customerCallName + "</span></span>" +
        "<span class='ticket-assigned-to'><span class='ticket-bottom-row-title'>Appaltatore</span><span>" + contract.contractorCallName + "</span></span>" +
        "</div></div>";
    return tempHtml;
}

function renderAnomalyEntry(anomaly, lastSelectedAnomaly) {
    var tempHtml = "<div onclick='selectAnomaly(" + anomaly.anomalyId + ",true);' class='entry " + ((anomaly.relatedTicket === null) ? "new " : "") + ((lastSelectedAnomaly == anomaly.anomalyId) ? "selected" : "") + "' id='anomaly-row-id-" + anomaly.anomalyId + "' data-anomaly-id='" + anomaly.anomalyId + "'>" +
        "<div class='anomaly-top-row'>" +
        "<span class='anomaly-number'>N°" + doubleZeroPadding(anomaly.customerAnomalyId) + "</span>";
    if(anomaly.state === "Aperto ticket") {
        tempHtml += " <span class='anomaly-state green-state'>Aperto ticket</span>";
    }
    else {
        tempHtml += " <span class='anomaly-state'>" + anomaly.state + "</span>";
    }
    tempHtml += "<span class='anomaly-location'><i class='fa fa-map-marker' aria-hidden='true'></i> " + anomaly.siteName + "</span>" +
        "</div>" +
        "<div class='anomaly-description'>" + anomaly.description + "</div>" +
        "<div class='anomaly-bottom-row'>" +
        "<span class=''><span class='anomaly-bottom-row-title'>Aperto da</span><span>" + anomaly.reportedBy + "</span></span>" +
        "<span class=''><span class='anomaly-bottom-row-title'>Data e ora</span><span>" + iso8601.toEuropeanDateTimeWithSeconds(anomaly.reportTimestamp) + "</span></span> " +
        "<span class=''><span class='anomaly-bottom-row-title'></span><span></span></span>" +
        "</div></div>";
    return tempHtml;
}

function renderTicketEntry(ticket, lastSelectedTicket) {
    var tempHtml = "<div onclick='selectTicket(" + ticket.ticketId + ",true);' class='entry " + ((lastSelectedTicket == ticket.ticketId) ? "selected" : "") + ((ticket.state === "Nuovo") ? " new" : "") + "' id='ticket-row-id-" + ticket.ticketId + "' data-ticket-id='" + ticket.ticketId + "'>" +
        "<div class='ticket-top-row'>" +
        "<span class='ticket-number'>N°" + doubleZeroPadding(ticket.customerTicketId) + "</span> " +
        "<span class='ticket-state " + ((ticket.state === "Chiuso") ? "green-state" : "") + ((ticket.state === "Annullato") ? "red-state" : "") + "'>" + ticket.state + "</span> " +
        "<span class='ticket-location'><i class='fa fa-map-marker' aria-hidden='true'></i> " + ticket.siteName + "</span> " +
        "<span class='sla-container'></span>" +
        "</div>" +
        "<div class='ticket-description'>" + ticket.description + "</div>" +
        "<div class='ticket-bottom-row'>" +
        "<span class='ticket-sla-type'><span class='ticket-bottom-row-title'>SLA</span><span>" + ticket.anomalyLevel + "</span></span> " +
        "<span class='ticket-reported-by'><span class='ticket-bottom-row-title'>Aperto da</span><span>" + ticket.reportedBy + "</span></span>";
    if(ticket.assignedCompanies.length > 0) {
        tempHtml += "<span class='ticket-assigned-to'><span class='ticket-bottom-row-title'>Assegnato a</span><span>";
        for(var i = 0; i < ticket.assignedCompanies.length; i++) {
            if(i > 0) {
                tempHtml += ", ";
            }
            tempHtml += ticket.assignedCompanies[i].companyName;
        }
        tempHtml += "</span>";
    }
    else {
        tempHtml += "<span class='ticket-assigned-to'></span>";
    }
    tempHtml += "</span></div></div>";
    return tempHtml;
}

function applyTicketSearch(filterType, filterValue) {
    if(filterType === "none") {
        dom.addClass(document.getElementById("ticket-search-dropdown-clear"), "hidden");
    }
    else {
        dom.removeClass(document.getElementById("ticket-search-dropdown-clear"), "hidden");
    }
    document.getElementById("ticket-search-dropdown-text").value = filterValue;
    var showedTickets = 0;
    var tempHtml = "";
    var ticketsCount = (tickets ? tickets.length : 0);
    for(var i = 0; i < ticketsCount; i++) {
        if(tickets[i].deletion===null){
            switch(filterType) {
                case "description":
                    if(tickets[i].description.toUpperCase() === filterValue.toUpperCase()) {
                        tempHtml += renderTicketEntry(tickets[i], lastSelectedTicket);
                        showedTickets++;
                    }
                    break;
                case "none":
                    tempHtml += renderTicketEntry(tickets[i], lastSelectedTicket);
                    showedTickets++;
                    break;
            }
        }
    }
    tempHtml += "";
    if(showedTickets === 0) {
        tempHtml = "<span class='no-results-message'>Nessun ticket corrisponde ai filtri di ricerca applicati.</span>";
    }
    document.getElementById("tickets-list").innerHTML = tempHtml;
}

function reloadAssetSearchMatchResults(id) {
    var searchedText = document.getElementById(id+"-text").value;
    if(preFetchedAssets != null) {
        if(searchedText === "") {
            applyAssetSearch("none", "",id);
        }
        else {
            var showedAssets = 0;
            //Do not use setInnerHtml here:
            document.getElementById(id).innerHTML = "";
            var allowedSitesId = [];
            var i;
            for(i = 0; i < sites.length; i++) {
                allowedSitesId.push(sites[i].siteId);
            }

            var alreadyAddedModels=[], alreadyAddedBrands=[];

            for(i = 0; i < preFetchedAssets.length; i++) {
                var start,text,dropdownEntry,dropdownEntryHammer;

                //Results match by asset QR code
                if(preFetchedAssets[i].deletion == null) {
                    if(preFetchedAssets[i].qrCodeId !== null && allowedSitesId.indexOf(preFetchedAssets[i].site) >= 0) {
                        if(preFetchedAssets[i].qrCodeId.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0) {
                            start = preFetchedAssets[i].qrCodeId.toUpperCase().indexOf(searchedText.toUpperCase());
                            text = preFetchedAssets[i].qrCodeId.substr(0, start) +
                                "<strong>" +
                                preFetchedAssets[i].qrCodeId.substr(start, searchedText.length) +
                                "</strong>" +
                                preFetchedAssets[i].qrCodeId.substr(start + searchedText.length);
                            dropdownEntry = document.createElement("BUTTON");
                            dropdownEntry.className = "dropdown-entry";
                            //dropdownEntryHammer = new Hammer(dropdownEntry);
                            //PreventGhostClick(dropdownEntry);
                            dropdownEntry.addEventListener("click", (
                                (function() {
                                    // Something declared here will only be available to the function below.
                                    // Code here is executed only once upon the creation of the inner function
                                    var currentAsset = preFetchedAssets[i];
                                    return function(event) {
                                        // Actual callback here
                                        applyAssetSearch("qr", currentAsset.qrCodeId,id);
                                        preventEvent(event);
                                        var dropdowns = document.querySelectorAll(".dropdown-content");
                                        var i;
                                        for(i = 0; i < dropdowns.length; i++) {
                                            var openDropdown = dropdowns[i];
                                            if(dom.hasClass(openDropdown, "show"))
                                                dom.removeClass(openDropdown, "show");
                                        }
                                    };
                                })())
                            );
                            //Do not use setInnerHtml here:
                            dropdownEntry.innerHTML = "<i class='fa fa-qrcode' aria-hidden='true'></i> " + text;
                            document.getElementById(id).appendChild(dropdownEntry);
                            showedAssets++;
                        }
                    }
                }

                //Results match by internal code
                if(preFetchedAssets[i].internalCode !== null && allowedSitesId.indexOf(preFetchedAssets[i].site) >= 0) {
                    if( preFetchedAssets[i].internalCode.toUpperCase()===searchedText.toUpperCase()
                        || (searchedText.length>=2 && preFetchedAssets[i].internalCode.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0)
                    ) {
                        start = preFetchedAssets[i].internalCode.toUpperCase().indexOf(searchedText.toUpperCase());
                        text = preFetchedAssets[i].internalCode.substr(0, start) +
                            "<strong>" +
                            preFetchedAssets[i].internalCode.substr(start, searchedText.length) +
                            "</strong>" +
                            preFetchedAssets[i].internalCode.substr(start + searchedText.length);
                        dropdownEntry = document.createElement("BUTTON");
                        dropdownEntry.className = "dropdown-entry";
                        //dropdownEntryHammer = new Hammer(dropdownEntry);
                        //PreventGhostClick(dropdownEntry);
                        dropdownEntry.addEventListener("click", (
                            (function() {
                                // Something declared here will only be available to the function below.
                                // Code here is executed only once upon the creation of the inner function
                                var currentAsset = preFetchedAssets[i];
                                return function(event) {
                                    // Actual callback here
                                    applyAssetSearch("internalcode", currentAsset.internalCode,id);
                                    preventEvent(event);
                                    var dropdowns = document.querySelectorAll(".dropdown-content");
                                    var i;
                                    for(i = 0; i < dropdowns.length; i++) {
                                        var openDropdown = dropdowns[i];
                                        if(dom.hasClass(openDropdown, "show"))
                                            dom.removeClass(openDropdown, "show");
                                    }
                                };
                            })())
                        );
                        if(preFetchedAssets[i].subcategoryName == undefined){
                            preFetchedAssets[i].subcategoryName = preFetchedAssets[i].subcategories[0].subcategoryName;
                        }
                        //Do not use setInnerHtml here:
                        dropdownEntry.innerHTML = "<i class='fa fa-hashtag' aria-hidden='true'></i> " + preFetchedAssets[i].subcategories[0].subcategoryName + " Cod. Int. " + text;
                        document.getElementById(id).appendChild(dropdownEntry);
                        showedAssets++;
                    }
                }

                //Results match by registration number
                if(preFetchedAssets[i].registrationNumber !== null && allowedSitesId.indexOf(preFetchedAssets[i].site) >= 0) {
                    if(preFetchedAssets[i].registrationNumber.toUpperCase()===searchedText.toUpperCase()
                        || (searchedText.length>=2 && preFetchedAssets[i].registrationNumber.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0)
                    ) {
                        start = preFetchedAssets[i].registrationNumber.toUpperCase().indexOf(searchedText.toUpperCase());
                        text = preFetchedAssets[i].registrationNumber.substr(0, start) +
                            "<strong>" +
                            preFetchedAssets[i].registrationNumber.substr(start, searchedText.length) +
                            "</strong>" +
                            preFetchedAssets[i].registrationNumber.substr(start + searchedText.length);
                        dropdownEntry = document.createElement("BUTTON");
                        dropdownEntry.className = "dropdown-entry";
                       // dropdownEntryHammer = new Hammer(dropdownEntry);
                       // PreventGhostClick(dropdownEntry);
                       dropdownEntry.addEventListener("click", (
                            (function() {
                                // Something declared here will only be available to the function below.
                                // Code here is executed only once upon the creation of the inner function
                                var currentAsset = preFetchedAssets[i];
                                return function(event) {
                                    // Actual callback here
                                    applyAssetSearch("registrationnumber", currentAsset.registrationNumber,id);
                                    preventEvent(event);
                                    var dropdowns = document.querySelectorAll(".dropdown-content");
                                    var i;
                                    for(i = 0; i < dropdowns.length; i++) {
                                        var openDropdown = dropdowns[i];
                                        if(dom.hasClass(openDropdown, "show"))
                                            dom.removeClass(openDropdown, "show");
                                    }
                                };
                            })())
                        );
                        //Do not use setInnerHtml here:
                        dropdownEntry.innerHTML = "<i class='fa fa-tag' aria-hidden='true'></i> " + preFetchedAssets[i].subcategories[0].subcategoryName + " Matr. " + text;
                        document.getElementById(id).appendChild(dropdownEntry);
                        showedAssets++;
                    }
                }

                //Results match by brand
                if(preFetchedAssets[i].brand !== null && allowedSitesId.indexOf(preFetchedAssets[i].site) >= 0 && alreadyAddedBrands.indexOf(preFetchedAssets[i].brand)<0) {
                    if(preFetchedAssets[i].brand.toUpperCase()===searchedText.toUpperCase()
                        || (searchedText.length>=2 && preFetchedAssets[i].brand.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0)
                    ) {
                        alreadyAddedBrands.push(preFetchedAssets[i].brand);
                        start = preFetchedAssets[i].brand.toUpperCase().indexOf(searchedText.toUpperCase());
                        text = preFetchedAssets[i].brand.substr(0, start) +
                            "<strong>" +
                            preFetchedAssets[i].brand.substr(start, searchedText.length) +
                            "</strong>" +
                            preFetchedAssets[i].brand.substr(start + searchedText.length);
                        dropdownEntry = document.createElement("BUTTON");
                        dropdownEntry.className = "dropdown-entry";
                   //     dropdownEntryHammer = new Hammer(dropdownEntry);
                   //     PreventGhostClick(dropdownEntry);
                        dropdownEntry.addEventListener("click", (
                            (function() {
                                // Something declared here will only be available to the function below.
                                // Code here is executed only once upon the creation of the inner function
                                var currentAsset = preFetchedAssets[i];
                                return function(event) {
                                    // Actual callback here
                                    applyAssetSearch("brand", currentAsset.brand,id);
                                    preventEvent(event);
                                    var dropdowns = document.querySelectorAll(".dropdown-content");
                                    var i;
                                    for(i = 0; i < dropdowns.length; i++) {
                                        var openDropdown = dropdowns[i];
                                        if(dom.hasClass(openDropdown, "show"))
                                            dom.removeClass(openDropdown, "show");
                                    }
                                };
                            })())
                        );
                        //Do not use setInnerHtml here:
                        dropdownEntry.innerHTML = "<i class='fa fa-tag' aria-hidden='true'></i> Marca: " + text;
                        document.getElementById(id).appendChild(dropdownEntry);
                        showedAssets++;
                    }
                }

                //Results match by model
                if(preFetchedAssets[i].model !== null && allowedSitesId.indexOf(preFetchedAssets[i].site) >= 0 && alreadyAddedModels.indexOf(preFetchedAssets[i].model)<0) {
                    if(preFetchedAssets[i].model.toUpperCase()===searchedText.toUpperCase()
                        || (searchedText.length>=2 && preFetchedAssets[i].model.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0)
                    ) {
                        alreadyAddedModels.push(preFetchedAssets[i].model);
                        start = preFetchedAssets[i].model.toUpperCase().indexOf(searchedText.toUpperCase());
                        text = preFetchedAssets[i].model.substr(0, start) +
                            "<strong>" +
                            preFetchedAssets[i].model.substr(start, searchedText.length) +
                            "</strong>" +
                            preFetchedAssets[i].model.substr(start + searchedText.length);
                        dropdownEntry = document.createElement("BUTTON");
                        dropdownEntry.className = "dropdown-entry";
                       // dropdownEntryHammer = new Hammer(dropdownEntry);
                       // PreventGhostClick(dropdownEntry);
                        dropdownEntry.addEventListener("click", (
                            (function() {
                                // Something declared here will only be available to the function below.
                                // Code here is executed only once upon the creation of the inner function
                                var currentAsset = preFetchedAssets[i];
                                return function(event) {
                                    // Actual callback here
                                    applyAssetSearch("model", currentAsset.model,id);
                                    preventEvent(event);
                                    var dropdowns = document.querySelectorAll(".dropdown-content");
                                    var i;
                                    for(i = 0; i < dropdowns.length; i++) {
                                        var openDropdown = dropdowns[i];
                                        if(dom.hasClass(openDropdown, "show")){
                                            dom.removeClass(openDropdown, "show");
                                        }
                                    }
                                };
                            })())
                        );
                        //Do not use setInnerHtml here:
                        dropdownEntry.innerHTML = "<i class='fa fa-tag' aria-hidden='true'></i> Modello: " + text;
                        document.getElementById(id).appendChild(dropdownEntry);
                        showedAssets++;
                    }
                }
            }
            for(i=0; i<subcategories.length; i++) {
                //Results match by subcategory
                var matchedSubcategoryName = null;
                var matchedSubcategoryId = null;
                if(subcategories[i].name!==null && subcategories[i].deletion==null) {
                    if(subcategories[i].name.toUpperCase()===searchedText.toUpperCase()
                    || (searchedText.length>=2 && subcategories[i].name.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0)
                    ) {
                        matchedSubcategoryName = subcategories[i].name;
                        matchedSubcategoryId = subcategories[i].subcategoryId;
                    }
                }
                if(matchedSubcategoryName!=null) {
                    start = matchedSubcategoryName.toUpperCase().indexOf(searchedText.toUpperCase());
                    text = matchedSubcategoryName.substr(0, start) +
                        "<strong>" +
                        matchedSubcategoryName.substr(start, searchedText.length) +
                        "</strong>" +
                        matchedSubcategoryName.substr(start + searchedText.length);
                    dropdownEntry = document.createElement("BUTTON");
                    dropdownEntry.className = "dropdown-entry";
                   // dropdownEntryHammer = new Hammer(dropdownEntry);
                   // PreventGhostClick(dropdownEntry);
                    dropdownEntry.addEventListener("click", (
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var currentSubcategoryId = matchedSubcategoryId;
                            return function(event) {
                                // Actual callback here
                                applyAssetSearch("subcategory", currentSubcategoryId,id);
                                preventEvent(event);
                                var dropdowns = document.querySelectorAll(".dropdown-content");
                                var i;
                                for(i = 0; i < dropdowns.length; i++) {
                                    var openDropdown = dropdowns[i];
                                    if(dom.hasClass(openDropdown, "show"))
                                        dom.removeClass(openDropdown, "show");
                                }
                            };
                        })())
                    );
                    //Do not use setInnerHtml here:
                    dropdownEntry.innerHTML = "<i class='fa fa-sitemap' aria-hidden='true'></i> " + text;
                    document.getElementById(id).appendChild(dropdownEntry);
                    showedAssets++;
                }
            }
            if(showedAssets === 0) {
                //Do not use setInnerHtml here:
                document.getElementById(id).innerHTML = "<span class='no-results-message'>Nessun risultato.</span>";
            }
        }
    }
    else {
        setInnerHtml(document.getElementById(id), "<span><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento asset in corso...</span>");
        setTimeout(function() {
            reloadAssetSearchMatchResults();
        }, 1000);
    }
}

function reloadTicketSearchMatchResults() {
    var searchedText = document.getElementById("ticket-search-dropdown-text").value;
    if(tickets != null) {
        document.getElementById("ticket-details").innerHTML = "<div class='no-results-message'>Seleziona un ticket per vederne qui i dettagli.</div>";
        if(searchedText === "") {
            applyTicketSearch("none", "");
        }
        else {
            var showedTickets = 0;
            //Do not use setInnerHtml here:
            document.getElementById("ticket-search-dropdown").innerHTML = "";
            //Results match by ticket description
            for(var i = 0; i < tickets.length; i++) {
                if(tickets[i].description.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0) {
                    var start = tickets[i].description.toUpperCase().indexOf(searchedText.toUpperCase());
                    var text = tickets[i].description.substr(0, start) +
                        "</strong>" +
                        tickets[i].description.substr(start, searchedText.length) +
                        "</strong>" +
                        tickets[i].description.substr(start + searchedText.length);
                    var dropdownEntry = document.createElement("BUTTON");
                    dropdownEntry.className = "dropdown-entry";
                   // var dropdownEntryHammer = new Hammer(dropdownEntry);
                 //   PreventGhostClick(dropdownEntry);
                    dropdownEntry.addEventListener("click", (
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var currentTicket = tickets[i];
                            return function(event) {
                                // Actual callback here
                                applyTicketSearch("description", currentTicket.description);
                                preventEvent(event);
                                var dropdowns = document.querySelectorAll(".dropdown-content");
                                var i;
                                for(i = 0; i < dropdowns.length; i++) {
                                    var openDropdown = dropdowns[i];
                                    if(dom.hasClass(openDropdown, "show")) {
                                        dom.removeClass(openDropdown, "show");
                                    }
                                }
                            };
                        })())
                    );
                    //Do not use setInnerHtml here:
                    dropdownEntry.innerHTML = "<i class='fa fa-ticket' aria-hidden='true'></i> " + text;
                    document.getElementById("ticket-search-dropdown").appendChild(dropdownEntry);
                    showedTickets++;
                }
            }
            if(showedTickets === 0) {
                //Do not use setInnerHtml here:
                document.getElementById("ticket-search-dropdown").innerHTML = "<span class='no-results-message'>Nessun risultato.</span>";
            }
        }
    }
    else {
        setInnerHtml(document.getElementById("ticket-search-dropdown"), "<span><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento ticket in corso...</span>");
        setTimeout(function() {
            reloadTicketSearchMatchResults();
        }, 1000);
    }
}

function reloadDataRoomSearchMatchResults(id) {
    var searchedText = document.getElementById(id+"-text").value;
    if(lastQueriedDocuments != null) {
        document.getElementById("data-room-details-col-content").innerHTML = "<span class='tip'>Clicca su un <strong>documento</strong> per visualizzarne i dettagli.</span>";
        scroll.refreshByName("vdr-details");
        if(searchedText === "") {
            applyDataRoomSearch("none", "",id);
        }
        else {
            var i, start, text, dropdownEntry, dropdownEntryHammer;
            var showedDocuments = 0;
            document.getElementById(id).innerHTML = "";
            //Results match by document tag
            for(i = 0; i < dataRoomDocumentTags.length; i++) {
                if(dataRoomDocumentTags[i].toUpperCase().indexOf(searchedText.toUpperCase()) >= 0) {
                    start = dataRoomDocumentTags[i].toUpperCase().indexOf(searchedText.toUpperCase());
                    text = dataRoomDocumentTags[i].substr(0, start) +
                        "</strong>" +
                        dataRoomDocumentTags[i].substr(start, searchedText.length) +
                        "</strong>" +
                        dataRoomDocumentTags[i].substr(start + searchedText.length);
                    dropdownEntry = document.createElement("BUTTON");
                    dropdownEntry.className = "dropdown-entry";
                    //dropdownEntryHammer = new Hammer(dropdownEntry);
                    //PreventGhostClick(dropdownEntry);
                    dropdownEntry.addEventListener("click", (
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var currentTag = dataRoomDocumentTags[i];
                            return function(event) {
                                // Actual callback here
                                applyDataRoomSearch("tag", currentTag,id);
                                preventEvent(event);
                                var dropdowns = document.querySelectorAll(".dropdown-content");
                                var i;
                                for(i = 0; i < dropdowns.length; i++) {
                                    var openDropdown = dropdowns[i];
                                    if(dom.hasClass(openDropdown, "show"))
                                        dom.removeClass(openDropdown, "show");
                                }
                            };
                        })())
                    );
                    //Do not use setInnerHtml here:
                    dropdownEntry.innerHTML = "<i class='fa fa-tag' aria-hidden='true'></i> " + text;
                    document.getElementById(id).appendChild(dropdownEntry);
                    showedDocuments++;
                }
            }
            //Results match by filename
            for(i = 0; i < lastQueriedDocuments.length; i++) {
                if(lastQueriedDocuments[i].fileName.toUpperCase().indexOf(searchedText.toUpperCase()) >= 0) {
                    start = lastQueriedDocuments[i].fileName.toUpperCase().indexOf(searchedText.toUpperCase());
                    text = lastQueriedDocuments[i].fileName.substr(0, start) +
                        "</strong>" +
                        lastQueriedDocuments[i].fileName.substr(start, searchedText.length) +
                        "</strong>" +
                        lastQueriedDocuments[i].fileName.substr(start + searchedText.length);
                    dropdownEntry = document.createElement("BUTTON");
                    dropdownEntry.className = "dropdown-entry";
                    //dropdownEntryHammer = new Hammer(dropdownEntry);
                    //PreventGhostClick(dropdownEntry);
                    dropdownEntry.addEventListener("click", (
                        (function() {
                            // Something declared here will only be available to the function below.
                            // Code here is executed only once upon the creation of the inner function
                            var currentDocument = lastQueriedDocuments[i];
                            return function(event) {
                                // Actual callback here
                                applyDataRoomSearch("filename", currentDocument.fileName,id);
                                preventEvent(event);
                                var dropdowns = document.querySelectorAll(".dropdown-content");
                                var i;
                                for(i = 0; i < dropdowns.length; i++) {
                                    var openDropdown = dropdowns[i];
                                    if(dom.hasClass(openDropdown, "show"))
                                        dom.removeClass(openDropdown, "show");
                                }
                            };
                        })())
                    );
                    //Do not use setInnerHtml here:
                    dropdownEntry.innerHTML = "<i class='fa fa-file' aria-hidden='true'></i> " + text;
                    document.getElementById(id).appendChild(dropdownEntry);
                    showedDocuments++;
                }
            }
            if(showedDocuments === 0) {
                document.getElementById(id).innerHTML = "<span class='no-results-message'>Nessun risultato.</span>";
            }
        }
    }
    else {
        setInnerHtml(document.getElementById(id), "<span><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento documenti in corso...</span>");
        setTimeout(function() {
            reloadDataRoomSearchMatchResults(id);
        }, 1000);
    }
}

function deleteCategoryGroup() {
    errorPopup("Funzione disabilitata per motivi di sicurezza, se desideri eliminare un gruppo di categorie contatta l'assistenza tecnica all'indirizzo facility@claster.it");
}

function deleteCategory() {
    errorPopup("Funzione disabilitata per motivi di sicurezza, se desideri eliminare una categoria contatta l'assistenza tecnica all'indirizzo facility@claster.it");
}

function deleteSubcategory() {
    errorPopup("Funzione disabilitata per motivi di sicurezza, se desideri eliminare una sottocategoria contatta l'assistenza tecnica all'indirizzo facility@claster.it");
}

function deleteBuilding() {
    setInnerHtml(document.getElementById("to-delete-building-name"), lastFetchedBuilding.name);
    popup("delete-building", false, false, false);
}

function ajaxDeleteBuilding() {
    var buildingData = {};
    buildingData.buildingId = parseInt(lastFetchedBuilding.buildingId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Edificio eliminato");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(buildingData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(buildingData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteBuilding.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(buildingData));
}

function deleteSite() {
    setInnerHtml(document.getElementById("to-delete-site-name"), lastFetchedSite.name);
    popup("delete-site", false, false, false);
}

function ajaxDeleteSite() {
    var siteData = {};
    siteData.siteId = parseInt(lastFetchedSite.siteId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                if(response.involvedSites > 0) {
                    closePopup();
                    successToast("Sito eliminato");
                    ajaxGetDashboardData();
                }
                else {
                    outcomePopup.innerHTML = "<div class='message error'>Impossibile eliminare il sito: su questo sito è attiva la gestione della contabilità.</div>";
                }
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(siteData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(siteData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteSite.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(siteData));
}

function deleteBuildingsGroup() {
    setInnerHtml(document.getElementById("to-delete-building-group-name"), lastFetchedBuildingsGroup.name);
    popup("delete-buildings-group", false, false, false);
}

function ajaxDeleteBuildingsGroup() {
    var buildingsGroupData = {};
    buildingsGroupData.buildingsGroupId = parseInt(lastFetchedBuildingsGroup.buildingsGroupId);
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Gruppo di edifici eliminato");
                ajaxGetDashboardData();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore: " + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(buildingsGroupData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(buildingsGroupData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/deleteBuildingsGroup.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(buildingsGroupData));
}

function handleQrScanError(errorCode) {
    //QR scanner errors
    var UNEXPECTED_ERROR = 0;
    var CAMERA_ACCESS_DENIED = 1;
    var CAMERA_ACCESS_RESTRICTED = 2;
    var BACK_CAMERA_UNAVAILABLE = 3;
    var FRONT_CAMERA_UNAVAILABLE = 4;
    var CAMERA_UNAVAILABLE = 5;
    var SCAN_CANCELED = 6;
    var LIGHT_UNAVAILABLE = 7;
    var OPEN_SETTINGS_UNAVAILABLE = 8;

    switch(errorCode) {
        case UNEXPECTED_ERROR:
            errorPopup("Si è verificato un errore durante la scansione del QR code");
            break;
        case CAMERA_ACCESS_DENIED:
            errorPopup("Errore: L'utente ha negato il permesso all'utilizzo della fotocamera");
            break;
        case CAMERA_ACCESS_RESTRICTED:
            errorPopup("Errore: L'utilizzo della fotocamera è stato negato da una politica di sicurezza aziendale o da un sistema di controllo parentale");
            break;
        case BACK_CAMERA_UNAVAILABLE:
            errorPopup("Errore: La fotocamera posteriore non è disponibile");
            break;
        case FRONT_CAMERA_UNAVAILABLE:
            errorPopup("Errore: La fotocamera anteriore non è disponibile");
            break;
        case CAMERA_UNAVAILABLE:
            errorPopup("Errore: La fotocamera non è disponibile");
            break;
        case SCAN_CANCELED:
            break;
        case LIGHT_UNAVAILABLE:
            errorPopup("Errore: Il flash non è disponibile");
            break;
        case OPEN_SETTINGS_UNAVAILABLE:
            errorPopup("Errore durante la configurazione della fotocamera");
            break;
    }
}

function scanQrCodeForInterventionExecution() {
    if(Capacitor.platform=='web') {
        warning("Questa funzione non è disponibile da browser: per utilizzare lo scanner di QR code scarica la nostra app!");
    }
    else {
        var handleQrScanOutcome = function(err, text) {
            ui.hideQrScanner();
            if(err) {
                // An error occurred, or the scan was canceled (error code `6`)
                handleQrScanError(err);
            }
            else {
                var finalCode;
                // The scan completed
                if(text.includes("https://claster.it/qr/") || text.includes("claster.it/qr/")) {
                    finalCode = text.replace("https://claster.it/qr/", "");
                    finalCode = finalCode.replace("claster.it/qr/", "");
                    selectAssetInstanceInExecution(finalCode);
                }
                else {
                    if(text.includes("http://edilservizi.it/qr/") || text.includes("edilservizi.it/qr/")) {
                        finalCode = text.replace("http://edilservizi.it/qr/", "");
                        finalCode = finalCode.replace("edilservizi.it/qr/", "");
                        selectAssetInstanceInExecution(finalCode);
                    }
                    else {
                        errorPopup("Il codice scansionato non è un QR code Claster FM valido");
                    }
                }
            }
        };
        // Starts a scan. Scanning will continue until something is detected or
        // `QRScanner.cancelScan()` is called.
        ui.showQrScanner(handleQrScanOutcome);
    }
}

function scanAssetQrCodeToTaggle(taggle) {
    if(Capacitor.platform=='web') {
        warning("Questa funzione non è disponibile da browser: per utilizzare lo scanner di QR code scarica la nostra app!");
    }
    else {
        var handleQrScanOutcome = function(err, text) {
            ui.hideQrScanner();
            if(err) {
                // An error occurred, or the scan was canceled (error code `6`)
                handleQrScanError(err);
            }
            else {
                var finalCode;
                // The scan completed
                if(text.includes("https://claster.it/qr/") || text.includes("claster.it/qr/")) {
                    finalCode = text.replace("https://claster.it/qr/", "");
                    finalCode = finalCode.replace("claster.it/qr/", "");
                    taggle.add(finalCode);
                }
                else {
                    if(text.includes("http://edilservizi.it/qr/") || text.includes("edilservizi.it/qr/")) {
                        finalCode = text.replace("http://edilservizi.it/qr/", "");
                        finalCode = finalCode.replace("edilservizi.it/qr/", "");
                        taggle.add(finalCode);
                    }
                    else {
                        errorPopup("Il codice scansionato non è un QR code Claster FM valido");
                    }
                }
            }
        };
        // Starts a scan. Scanning will continue until something is detected or
        // `QRScanner.cancelScan()` is called.
        ui.showQrScanner(handleQrScanOutcome);
    }
}

function geolocalizeDevice() {
    if(Capacitor.platform!='web' && navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            ajaxGeolocationSuccess,
            ajaxGeolocationFailed,
            {
                maximumAge: 5000,
                timeout: 8000,
                enableHighAccuracy: true
            }
        );
    }
}

function ajaxGeolocationFailed(positionError) {
    var failure = {};
    if(positionError) {
        failure.error = positionError.code + " " + positionError.message;
    }
    else {
        failure.error = "Unknown error";
    }
    failure.captureDateTime = dateToEnglishTimestamp(new Date());
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status !== 200) {
            console.error("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(failure), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/registerGeolocationFailure.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(failure));
}

function ajaxGeolocationSuccess(position) {
    var requestData = {};
    requestData.latitude = parseFloat(position.coords.latitude);
    requestData.longitude = parseFloat(position.coords.longitude);
    requestData.altitude = parseFloat(position.coords.altitude);
    requestData.activeInterventionId = selectedIntervention;
    if(!requestData.altitude) {
        delete requestData.altitude;
    }
    requestData.accuracy = parseFloat(position.coords.accuracy);
    requestData.altitudeAccuracy = parseFloat(position.coords.altitudeAccuracy);
    if(!requestData.altitudeAccuracy) {
        delete requestData.altitudeAccuracy;
    }
    requestData.captureDateTime = dateToEnglishTimestamp(new Date(position.timestamp));
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        if(xhttp.status !== 200) {
            console.error("Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/registerGeolocation.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

function getQrCodeDataByScanningQrCode() {
    scanQrCode(
        function(qrCode){
            ajaxGetQrCodeData(qrCode);
        }
    );
}

function editAssetScanQR() {
    scanQrCode(
        function(qrCode){
            document.getElementById("edit-asset-qr-id").value=qrCode;
        }
    );
}

/**
 * Allows to scan a ClasterFM QR code and returns the alphanumeric code of the QR code
 * @param callback the detected QR code
 */
function scanQrCode(callback) {
    if(Capacitor.platform=='web') {
        errorPopup("Questa funzione non è disponibile da browser: per utilizzare lo scanner di QR code scarica la nostra app!");
    }
    else {
        try {
            var handleQrScanOutcome = function(err, text) {
                ui.hideQrScanner();
                if(err) {
                    // An error occurred, or the scan was canceled (error code `6`)
                    handleQrScanError(err);
                }
                else {
                    var finalCode;
                    // The scan completed
                    if(text.includes("https://claster.it/qr/") || text.includes("claster.it/qr/")) {
                        finalCode = text.replace("https://claster.it/qr/", "");
                        finalCode = finalCode.replace("claster.it/qr/", "");
                        if(typeof(callback)==="function") {
                            callback(finalCode);
                        }
                    }
                    else {
                        if(text.includes("http://edilservizi.it/qr/") || text.includes("edilservizi.it/qr/")) {
                            finalCode = text.replace("http://edilservizi.it/qr/", "");
                            finalCode = finalCode.replace("edilservizi.it/qr/", "");
                            if(typeof(callback)==="function") {
                                callback(finalCode);
                            }
                        }
                        else {
                            errorPopup("Il codice scansionato non è un QR code Claster FM valido");
                        }
                    }
                }
            };
            // Starts a scan. Scanning will continue until something is detected or
            // `QRScanner.cancelScan()` is called.
            ui.showQrScanner(handleQrScanOutcome);
        }
        catch(e) {
            errorPopup("Errore durante la scansione: " + e.message);
        }
    }
}

function validateIntervention() {
    var interventionId = parseInt(lastOpenedIntervention.interventionId);
    var container = document.getElementById("validate-intervention-execution-details");
    interventionPage.ajaxViewDetailedInterventionExecutionReport(interventionId, container);
    var container2 = document.getElementById("validate-intervention-execution-consumptive");
    if(interventionInExecutionContainsTickets) {
        container2.innerHTML="<span class='loading'><i class='fa fa-cog fa-spin fa-fw'></i> Caricamento...</span>";
        interventionPage.ajaxViewTechnicalInterventionConsumptive(interventionId, container2);
    }
    else{
        container2.innerHTML="";
    }
    popup("validate-intervention", true, false, false);
}

function requestInterventionValidation() {
    if(Capacitor.platform=='web') {
        errorPopup("Questa funzione non è disponibile da browser: per utilizzare lo scanner di QR code scarica la nostra app!");
    }
    else {
        try {
            var handleQrScanOutcome = function(err, text) {
                ui.hideQrScanner();
                if(err) {
                    // An error occurred, or the scan was canceled (error code `6`)
                    handleQrScanError(err);
                }
                else {
                    closePopup();
                    // The scan completed
                    if(text.length === 32) {
                        ajaxValidateInterventionWithToken(text);
                    }
                    else {
                        warning("Questo codice QR non può essere utilizzato per la validazione di un intervento.<br> Il codice QR ti deve essere mostrato dal site manager o da un'altra persona autorizzata ad effettuare la validazione per il sito nel quale l'intervento si è svolto.");
                    }
                }
            };
            // Starts a scan. Scanning will continue until something is detected or
            // `QRScanner.cancelScan()` is called.
            ui.showQrScanner(handleQrScanOutcome);
        }
        catch(e) {
            console.error(e);
        }
    }
}

function ajaxValidateIntervention() {
    var validationRequest = {};
    validationRequest.interventionId = parseInt(lastOpenedIntervention.interventionId);
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = 6000; // time in milliseconds
    var timeoutErrorAbort = function() {
        setInnerHtml(outcomePopup, "<div class='message error'>Impossibile raggiungere il server, verificare che Internet sia disponibile</div>");
        ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(validationRequest), xhttp.responseText);
    };
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            switch(xhttp.status) {
                case 200:
                    closePopup();
                    closePopup();
                    successToast("Intervento validato correttamente");
                    ajaxGetInterventionData(validationRequest.interventionId);
                    break;
                default:
                    setInnerHtml(outcomePopup, "<div class='message error'>Errore: " + response.message + "</div>");
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(validationRequest), xhttp.responseText);
                    break;
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(validationRequest), e + "<br>" + xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/validateIntervention.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(validationRequest));
}

function ajaxValidateInterventionWithToken(validationToken) {
    var validationRequest = {};
    validationRequest.validationToken = validationToken;
    validationRequest.interventionId = parseInt(lastOpenedIntervention.interventionId);
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            switch(xhttp.status) {
                case 200:
                    closePopup();
                    successToast("Intervento validato correttamente");
                    ajaxGetInterventionData(validationRequest.interventionId);
                    break;
                default:
                    setInnerHtml(outcomePopup, "<div class='message error'>Errore: " + response.message + "</div>");
                    ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(validationRequest), xhttp.responseText);
                    break;
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(validationRequest), e + "<br>" + xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/validateInterventionWithToken.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(validationRequest));
}

function ajaxViewGeolocationData() {
    var geolocationDataUserSelect=document.getElementById("geolocation-data-user-select");
    var userId = geolocationDataUserSelect.value;
    var dateFrom = document.getElementById("view-geolocation-data-start-date").value.split("/").reverse().join("-");
    var dateTo = document.getElementById("view-geolocation-data-end-date").value.split("/").reverse().join("-");
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            var tempHtml = "<table id='geolocation-viewer-table'><tr><th>ID sessione</th><th>Latitudine</th><th>Longitudine</th><th>Accuratezza</th>" +
                "<th>Altitudine</th><th>Accuratezza altitudine</th><th>Acquisizione</th><th>Registrazione</th></tr>";
            for(var i = 0; i < response.entries.length; i++) {
                var entry = response.entries[i];
                tempHtml += "<tr onclick='openGoogleMaps(" + entry.latitude + "," + entry.longitude + ");'><td>" + entry.sessionId + "</td><td>" + entry.latitude + "</td><td>" +
                    entry.longitude + "</td><td>" + ((entry.accuracy === null) ? "-" : entry.accuracy) + "</td><td>" + ((entry.altitude === null) ? "-" : entry.altitude) +
                    "</td><td>" + ((entry.altitudeAccuracy === null) ? "-" : entry.altitudeAccuracy) + "</td><td>" + iso8601.toEuropeanDateTimeWithSeconds(entry.isoCaptureDateTime) +
                    "</td><td>" + iso8601.toEuropeanDateTimeWithSeconds(entry.isoRegistrationDateTime) + "</td></tr>";
            }
            tempHtml += "</table>";
            setInnerHtml(
                document.getElementById("geolocation-data-container"),
                tempHtml,
                function(){scroll.refreshPopupById("view-geolocation-data", false);}
            );
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getGeolocationData.php?userId=" + userId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo, true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function openGoogleMaps(lat, long) {
    window.open("https://www.google.com/maps/place/" + lat + "," + long, "_blank");
}

function setQrPreset(presetName) {
    var pageHeight = document.getElementById("generate-qr-page-height");
    var pageWidth = document.getElementById("generate-qr-page-width");
    var marginTop = document.getElementById("generate-qr-page-margin-top");
    var marginLeft = document.getElementById("generate-qr-page-margin-left");
    var rows = document.getElementById("generate-qr-rows");
    var cols = document.getElementById("generate-qr-cols");
    var gap = document.getElementById("generate-qr-gap");
    var side = document.getElementById("generate-qr-side");
    var quietZone = document.getElementById("generate-qr-quiet-zone");
    var correctionLevel = document.getElementById("generate-qr-error-correction-level");
    var fontSize = document.getElementById("generate-qr-font-size");
    switch(presetName) {
        case "big":
            pageHeight.value = 1130;
            pageWidth.value = 940;
            marginTop.value = 10;
            marginLeft.value = 10;
            rows.value = 20;
            cols.value = 19;
            gap.value = 3;
            side.value = 35;
            quietZone.value = 5;
            correctionLevel.value = "H";
            fontSize.value = 13;
            setPseudoCheckbox("generate-qr-show-logo",1);
            setPseudoCheckbox("generate-qr-show-text",1);
            document.getElementById("generate-qr-show-logo-holder").removeAttribute("disabled");
            document.getElementById("generate-qr-show-text-holder").removeAttribute("disabled");
            break;
        case "medium":
            pageHeight.value = 1130;
            pageWidth.value = 940;
            marginTop.value = 10;
            marginLeft.value = 10;
            rows.value = 24;
            cols.value = 24;
            gap.value = 3;
            side.value = 25;
            quietZone.value = 5;
            correctionLevel.value = "M";
            fontSize.value = 12;
            setPseudoCheckbox("generate-qr-show-logo",1);
            setPseudoCheckbox("generate-qr-show-text",1);
            document.getElementById("generate-qr-show-logo-holder").removeAttribute("disabled");
            document.getElementById("generate-qr-show-text-holder").removeAttribute("disabled");
            break;
        case "small":
            pageHeight.value = 1130;
            pageWidth.value = 940;
            marginTop.value = 10;
            marginLeft.value = 10;
            rows.value = 33;
            cols.value = 35;
            gap.value = 3;
            side.value = 15;
            quietZone.value = 4;
            correctionLevel.value = "L";
            fontSize.value = 9;
            setPseudoCheckbox("generate-qr-show-logo",1);
            setPseudoCheckbox("generate-qr-show-text",1);
            document.getElementById("generate-qr-show-logo-holder").removeAttribute("disabled");
            document.getElementById("generate-qr-show-text-holder").removeAttribute("disabled");
            break;
        case "micro":
            pageHeight.value = 297;
            pageWidth.value = 210;
            marginTop.value = 7;
            marginLeft.value = 7;
            rows.value = 23;
            cols.value = 16;
            gap.value = 3;
            side.value = 5;
            quietZone.value = 2;
            correctionLevel.value = "L";
            fontSize.value = 0;
            setPseudoCheckbox("generate-qr-show-logo",0);
            setPseudoCheckbox("generate-qr-show-text",0);
            document.getElementById("generate-qr-show-logo-holder").disabled="disabled";
            document.getElementById("generate-qr-show-text-holder").disabled="disabled";
            break;
    }
}

function generateQr() {
    if(formIsValid(document.getElementById("generate-qr-form"))) {
        var pageHeight = document.getElementById("generate-qr-page-height").value;
        var pageWidth = document.getElementById("generate-qr-page-width").value;
        var pageTopMargin = document.getElementById("generate-qr-page-margin-top").value;
        var pageLeftMargin = document.getElementById("generate-qr-page-margin-left").value;
        var qrRows = document.getElementById("generate-qr-rows").value;
        var qrCols = document.getElementById("generate-qr-cols").value;
        var qrQuietZone = document.getElementById("generate-qr-quiet-zone").value;
        var qrGap = document.getElementById("generate-qr-gap").value;
        var qrSideLength = document.getElementById("generate-qr-side").value;
        var qrErrorCorrection = document.getElementById("generate-qr-error-correction-level").value;
        var qrFontSize = document.getElementById("generate-qr-font-size").value;
        var showLogo = document.getElementById("generate-qr-show-logo").getAttribute("data-checked")==="1";
        var showText = document.getElementById("generate-qr-show-text").getAttribute("data-checked")==="1";
        var lastGenerated = document.getElementById("generate-qr-last-generated").value;
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/generateQr.php?pageHeight=" + pageHeight +
            "&pageWidth=" + pageWidth +
            "&pageTopMargin=" + pageTopMargin +
            "&pageLeftMargin=" + pageLeftMargin +
            "&qrRows=" + qrRows +
            "&qrCols=" + qrCols +
            "&qrQuietZone=" + qrQuietZone +
            "&qrGap=" + qrGap +
            "&qrSideLength=" + qrSideLength +
            "&qrErrorCorrection=" + qrErrorCorrection +
            "&qrFontSize=" + qrFontSize +
            "&showLogo=" + showLogo +
            "&showText=" + showText +
            "&lastGenerated="+ lastGenerated +
            "&authToken=" + currentAuthToken, "QR-code.pdf", false, null, "pdf");
        document.getElementById("generate-qr-last-generated").value="";
    }
}

function generateQrCutLines() {
    if(formIsValid(document.getElementById("generate-qr-form"))) {
        var pageHeight = document.getElementById("generate-qr-page-height").value;
        var pageWidth = document.getElementById("generate-qr-page-width").value;
        var pageTopMargin = document.getElementById("generate-qr-page-margin-top").value;
        var pageLeftMargin = document.getElementById("generate-qr-page-margin-left").value;
        var qrRows = document.getElementById("generate-qr-rows").value;
        var qrCols = document.getElementById("generate-qr-cols").value;
        var qrQuietZone = document.getElementById("generate-qr-quiet-zone").value;
        var qrGap = document.getElementById("generate-qr-gap").value;
        var qrSideLength = document.getElementById("generate-qr-side").value;
        var qrFontSize = document.getElementById("generate-qr-font-size").value;
        ajaxOpenRemoteDocument(DIRECT_DOWNLOAD_URL + "/ws/generateQrCutLines.php?pageHeight=" + pageHeight +
            "&pageWidth=" + pageWidth +
            "&pageTopMargin=" + pageTopMargin +
            "&pageLeftMargin=" + pageLeftMargin +
            "&qrRows=" + qrRows +
            "&qrCols=" + qrCols +
            "&qrQuietZone=" + qrQuietZone +
            "&qrGap=" + qrGap +
            "&qrSideLength=" + qrSideLength +
            "&qrFontSize=" + qrFontSize +
            "&authToken=" + currentAuthToken, "Linee-di-taglio.pdf", false, null, "pdf");
    }
}

function showAllGeneratedQrCodes() {
    var popupContent = document.getElementById("all-qr-codes-container");
    popup("view-all-qr", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                var tempHtml="";
                for(var i = 0; i < response.qrCodes.length; i++) {
                    var qrCode = response.qrCodes[i];
                    var relatedString = "Non collegato";
                    if(qrCode.relatedIntervention !== null) {
                        relatedString = "Collegato all'intervento " + qrCode.relatedIntervention;
                    }
                    if(qrCode.relatedAsset !== null) {
                        relatedString = "Collegato all'asset " + qrCode.relatedAsset;
                    }
                    if(qrCode.relatedTicket !== null) {
                        relatedString = "Collegato al ticket " + qrCode.relatedTicket;
                    }
                    tempHtml += "<div class='entry'><h3>" + qrCode.qrCode + "</h3>"+
                        "<div>"+relatedString+"</div><div>Associazione: "+iso8601.toEuropeanDateTimeWithSeconds(qrCode.isoCreation)+"</div>" +
                    "</div>";
                }
                tempHtml += "<br>";
                setInnerHtml(
                    popupContent,
                    tempHtml,
                    function(){scroll.refreshPopupById("view-all-qr", false);}
                );
            }
            else {
                setInnerHtml(popupContent, "<div class='message error'> Errore durante l'elaborazione: " + response.message + "</div>");
                ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(popupContent, "<div class='message error'> Si è verificato un Errore durante l'elaborazione.</div>");
            console.error(e);
            ajaxSendErrorReport(xhttp.responseURL, "", xhttp.responseText);
        }
    };
    xhttp.open("GET", BASE_URL + "/ws/getAllQrCodes.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send();
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function ajaxGetValidateInterventionOtp() {
    var img = new Image();
    var x = document.getElementById("intervention-validation-qr-container");
    img.onload = function() {
        x.src = img.src;
    };
    x.src = BASE_URL + "/ws/getInterventionValidationQr.php?authToken=" + currentAuthToken + "&rand=" + randomIntFromInterval(1, 99999);
}

function ajaxCreateSubcategoryAdditionalField(subcategoryId,fieldName,fieldType,keepHistory,gePrecedent,geZero) {
    var requestData = {};
    requestData.subcategory=subcategoryId;
    requestData.fieldName=fieldName;
    requestData.fieldType=fieldType;
    requestData.keepHistory=keepHistory;
    requestData.gePrecedent=gePrecedent;
    requestData.geZero=geZero;
    closePopup();
    popup("outcome", true, false, false);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        try {
            var response = JSON.parse(xhttp.responseText);
            if(xhttp.status === 200) {
                closePopup();
                successToast("Campo personalizzato creato");
                manageSubcategoryAdditionalFieldsPopup.refresh();
            }
            else {
                outcomePopup.innerHTML = "<div class='message error'>Errore:" + response.message + "</div>";
                ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
            }
        }
        catch(e) {
            setInnerHtml(outcomePopup, "<div class='message error'>Errore durante l'elaborazione (Codice HTTP " + xhttp.status + ").</div>");
            ajaxSendErrorReport(xhttp.responseURL, JSON.stringify(requestData), xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL + "/ws/subcategoryAdditionalField/create.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}

/******** calendars.js start **********/

function getCalendarHeader(calMonth,calYear) {
    var monthName = calMonthsLabels[calMonth];
    var prevMonth,prevYear,nextMonth,nextYear;
    if(calMonth==0) {
        prevMonth=11;
        prevYear=calYear-1;
    }
    else {
        prevMonth=calMonth-1;
        prevYear=calYear;
    }
    if(calMonth==11) {
        nextMonth=0;
        nextYear=calYear+1;
    }
    else {
        nextMonth=calMonth+1;
        nextYear=calYear;
    }
    return "<button onclick='renderCalendar(" + prevMonth + "," + prevYear + ");' class='prev'>❮</button><button onclick='renderCalendar(" + nextMonth + "," + nextYear + ");' class='next'>❯</button><div style='text-align:center'>" + monthName + " " + calYear + "</div>";
}

function getYearCalendarHeader(calYear) {
    return "<button onclick='renderYearCalendar(" + (calYear - 1) + ");' class='prev'>❮</button><button onclick='renderYearCalendar(" + (calYear + 1) + ");' class='next'>❯</button><div style='text-align:center'>" + calYear + "</div>";
}

function drawCalendarBody(calMonth,calYear,interventions) {
    interventionsMonthlyCounter=0;
    completedInterventionsMonthlyCounter=0;
    lateInterventionsMonthlyCounter=0;
    lateInterventionsHtml="";

    var html = "<table class='calendar-table' cellspacing='0'>";
    html += "<tr class='calendar-header'>";
    var i;
    for(i = 0; i <= 6; i++ ) {
        html += "<td class='calendar-header-day'>";
        html += calDaysLabels[i];
        html += "</td>";
    }
    html+="</tr><tr>";
    // get first day of month
    var firstDay = new Date(calYear, calMonth, 1);
    var startingDay = firstDay.getDay();
    //first day of week is monday, not sunday
    if(startingDay===0) {
        startingDay=6;
    }
    else {
        startingDay--;
    }
    // find number of days in month
    var monthLength = calDaysInMonth[calMonth];
    // compensate for leap year
    if(calMonth == 1) {
        // February only!
        if((calYear % 4 === 0 && calYear % 100 !== 0) || calYear % 400 === 0) {
            monthLength = 29;
        }
    }
    // fill in the days
    var day = 1;
    // this loop is for is weeks (rows)
    for(i=0; i<9; i++) {
        // this loop is for weekdays (cells)
        for(var j=0; j<=6; j++) {
            var isToday = calCurrentDate.getMonth() === calMonth && calCurrentDate.getFullYear() === calYear && calCurrentDate.getDate() === day;
            var isHoliday = (getHolidayMessageForDate(day+"/"+(calMonth+1)+"/"+calYear)!==null);

            var date="", dayStr="", calMonthStr;
            if (day <= monthLength && (i > 0 || j >= startingDay)) {
                dayStr += day;
                date=calYear+"-"+(calMonth+1)+"-"+day;
                if(calMonth<9) {
                    calMonthStr="0"+(calMonth+1);
                }
                else {
                    calMonthStr=calMonth+1;
                }
                if(day<=9) {
                    dayStr="0"+day;
                }
                else {
                    dayStr=day;
                }
                if(interventions) {
                    dayStr+=getInterventionsCountForDate(interventions,calYear+'-'+calMonthStr+'-'+dayStr);
                }
                day++;
            }
            //I open the div calendar-day-wrapper here but I close it into getInterventionsCountForDate and put it into dayStr. I know, I know...
            if(isToday&&dayStr!=="") {
                html += "<td class='calendar-day today"+(isHoliday?" holiday":"")+"' onclick='getInterventionsForDay(\""+date+"\");'><div class='calendar-day-wrapper'>"+dayStr+"</div></td>";
            }
            else
            {
                if(date!=="") {
                    html += "<td class='calendar-day "+(isHoliday?" holiday":"")+"' onclick='getInterventionsForDay(\""+date+"\");'><div class='calendar-day-wrapper'>"+dayStr+"</td>";
                }
                else {
                    html += "<td class='calendar-day disabled'></td>";
                }
            }
        }
        // stop making rows if we've run out of days
        if(day > monthLength) {
            break;
        }
        else {
            html += "</tr><tr>";
        }
    }
    html += "</tr></table>";
    setInnerHtml(
        document.querySelector("#calendar-page"),
        html,
        function(){scroll.refreshByName("calendar");}
    );
    setInnerHtml(
        document.getElementById("calendar-completed-interventions-counter"),
        completedInterventionsMonthlyCounter+"/"+interventionsMonthlyCounter
    );
    if(completedInterventionsMonthlyCounter===interventionsMonthlyCounter&&interventionsMonthlyCounter>0) {
        dom.addClass(document.getElementById("calendar-completed-interventions-counter").parentNode,"bg-green");
    }
    else {
        dom.removeClass(document.getElementById("calendar-completed-interventions-counter").parentNode,"bg-green");
    }
    setInnerHtml(document.getElementById("calendar-late-interventions-counter"),lateInterventionsMonthlyCounter);
    if(lateInterventionsMonthlyCounter>0) {
        dom.addClass(document.getElementById("calendar-late-interventions-counter").parentNode,"bg-red");
    }
    else {
        dom.removeClass(document.getElementById("calendar-late-interventions-counter").parentNode,"bg-red");
    }
    setInnerHtml(
        document.getElementById("late-interventions-container"),
        lateInterventionsHtml,
        function(){scroll.refreshPopupById("late-interventions", false);}
    );
}

function ajaxGetCalendarBody(calMonth,calYear) {
    dom.removeClass(document.getElementById("calendar-loading"),"hidden");
    drawCalendarBody(calMonth,calYear);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        dom.addClass(document.getElementById("calendar-loading"),"hidden");
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            selectedMonthInterventions=response.interventions;
            drawCalendarBody(calMonth,calYear,response.interventions);
        }
        else {
            outcomePopup.innerHTML="<div class='message error'> Errore durante l'elaborazione: "+response.message+"</div>";
            popup("outcome", false, false, false);
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL+"/ws/getInterventionsByMonth.php?companyId="+globalFilterByCompany+"&month="+(calMonth+1)+"&year="+calYear, true);
    }
    else {
        xhttp.open("GET", BASE_URL+"/ws/getInterventionsByMonth.php?month="+(calMonth+1)+"&year="+calYear, true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token",currentAuthToken);
    xhttp.send("");
}

function getInterventionsForDay(date) {
    var dateComponents=date.split("-");
    var dateDay=parseInt(dateComponents[2]);
    var dateMonth=parseInt(dateComponents[1]);
    var dateYear=parseInt(dateComponents[0]);
    var dateDayString = dateDay;
    if(dateDayString<10) {
        dateDayString="0"+dateDayString;
    }
    var dateMonthString = dateMonth;
    if(dateMonthString<10) {
        dateMonthString ="0"+dateMonthString;
    }

    var currentDay = new Date(dateYear,(dateMonth-1),dateDay);
    // 864e5 = 86400000 = 24*60*60*1000
    var tomorrow = new Date(currentDay.getTime() + 864e5);
    var yesterday = new Date(currentDay.getTime() - 864e5);
    //daylight savings time bug
    if(tomorrow.getDate()===dateDay) {
        tomorrow = new Date(currentDay.getTime() + (864e5*2));
    }
    if(yesterday.getDate()===dateDay) {
        yesterday = new Date(currentDay.getTime() - (864e5*2));
    }
    var tomorrowString=tomorrow.getFullYear()+"-"+(tomorrow.getMonth()+1)+"-"+tomorrow.getDate();
    var yesterdayString=yesterday.getFullYear()+"-"+(yesterday.getMonth()+1)+"-"+yesterday.getDate();
    //TODO: handle date in a better date
    var todayString=dateYear+"-"+dateMonth+"-"+dateDay;
    var monthDownChanges=((yesterday.getMonth()+1)!==dateMonth)?("renderCalendar("+(yesterday.getMonth())+","+yesterday.getFullYear()+");"):"";
    var monthUpChanges=((tomorrow.getMonth()+1)!==dateMonth)?("renderCalendar("+(tomorrow.getMonth())+","+tomorrow.getFullYear()+");"):"";
    var adjacentDaysButtonsHtml="<div class='adjacent-days-buttons'><button title='Giorno precedente' onclick='"+monthDownChanges+"getInterventionsForDay(\""+yesterdayString+"\");'><i class='fa fa-chevron-left' aria-hidden='true'></i></button>";
    switch(permissions["CREATE_INTERVENTION"]) {
        case "ACCESSIBLE":
            adjacentDaysButtonsHtml+="<button title='Crea intervento' onclick='closePopup();createInterventionPopup.init(\""+todayString+"\");'><i class='fa fa-plus' aria-hidden='true'></i></button>";
            break;
        case "DISABLED":
            adjacentDaysButtonsHtml+="<button title='Crea intervento' disabled='disabled'><i class='fa fa-plus' aria-hidden='true'></i></button>";
            break;
    }
    adjacentDaysButtonsHtml+="<button title='Giorno successivo' onclick='"+monthUpChanges+"getInterventionsForDay(\""+tomorrowString+"\");'><i class='fa fa-chevron-right' aria-hidden='true'></i></button></div>";
    document.querySelector("#interventions-of-day-popup .popup-title").innerHTML="Interventi del giorno "+dateDayString+"-"+dateMonthString+"-"+dateYear;
    var tempHtml="<div>"+adjacentDaysButtonsHtml;
    var interventionsCount=0;
    var calendarCompanyFilter=globalFilterByCompany;
    var calendarSiteFilter=document.getElementById("calendar-site-selector").value;
    var calendarCategoryFilter=document.getElementById("calendar-category-selector").value;
    var calendarSubcategoryFilter=document.getElementById("calendar-subcategory-selector").value;
    var executorFilter=document.getElementById("calendar-executor-selector").value;
    for(var i=0; i<selectedMonthInterventions.length;i++) {
        var intervDate=selectedMonthInterventions[i].plannedStartDate.split(" ")[0];
        var intervComponents=intervDate.split("-");
        var intervDay=parseInt(intervComponents[2]);
        var intervMonth=parseInt(intervComponents[1]);
        var intervYear=parseInt(intervComponents[0]);
        var intervStartTime=selectedMonthInterventions[i].plannedStartDate.split(" ")[1];
        intervStartTime=intervStartTime.substring(0, intervStartTime.lastIndexOf(":"));
        var intervEndTime=selectedMonthInterventions[i].plannedEndDate.split(" ")[1];
        intervEndTime=intervEndTime.substring(0, intervEndTime.lastIndexOf(":"));
        if(dateDay===intervDay&&dateMonth===intervMonth&&dateYear===intervYear) {
            //The intervention's site must match the applied company filter, if any
            if(selectedMonthInterventions[i].siteOwnedBy==calendarCompanyFilter||calendarCompanyFilter=="") {
                //The intervention's site must match the applied site filter, if any
                if(selectedMonthInterventions[i].siteId==calendarSiteFilter||calendarSiteFilter=="") {
                    if(selectedMonthInterventions[i].executor==executorFilter||executorFilter=="") {
                        var hasTicket;
                        var tempHtml3="";
                        var notMatchingSubcategories=[];
                        var interventionWarning="";
                        for(var j=0;j<selectedMonthInterventions[i].interventionActivities.length;j++) {
                            if(selectedMonthInterventions[i].interventionActivities[j].categoryId==calendarCategoryFilter||calendarCategoryFilter=="") {
                                if(selectedMonthInterventions[i].interventionActivities[j].subcategoryId==calendarSubcategoryFilter||calendarSubcategoryFilter=="") {
                                    var tempHtml2="";
                                    tempHtml2+="<div class='category-label'><div class='category-small b-"+selectedMonthInterventions[i].interventionActivities[j].categoryIconBackground+"'>"+selectedMonthInterventions[i].interventionActivities[j].categoryIcon+"</div>"+selectedMonthInterventions[i].interventionActivities[j].category+"</div>";

                                    if(selectedMonthInterventions[i].interventionActivities[j].subcategoryIcon!=null) {
                                        tempHtml2+="<div class='subcategory-label'><div class='category-small b-"+selectedMonthInterventions[i].interventionActivities[j].categoryIconBackground+"'>"+selectedMonthInterventions[i].interventionActivities[j].subcategoryIcon+"</div>"+selectedMonthInterventions[i].interventionActivities[j].subcategory+"</div>";
                                    }
                                    else {
                                        tempHtml2+="<div class='subcategory-label'>&nbsp;</div>";
                                    }

                                    if(selectedMonthInterventions[i].interventionActivities[j].containsTicket) {
                                        tempHtml2+="<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>";
                                        hasTicket=true;
                                    }
                                    else {
                                        hasTicket=false;
                                    }
                                    if(selectedMonthInterventions[i].executor===null) {
                                        interventionWarning="<div class='category-small b-red'><i class='fa fa-exclamation' aria-hidden='true'></i></div>";
                                    }
                                    tempHtml3+="<div class='intervention-subcategory-dots "+(hasTicket?"has-ticket":"")+"'>";
                                    tempHtml3+=tempHtml2;
                                    tempHtml3+="</div>";
                                    interventionsCount++;
                                }
                                else{
                                    if(!notMatchingSubcategories.includes(selectedMonthInterventions[i].interventionActivities[j].subcategoryId)){
                                        notMatchingSubcategories.push(selectedMonthInterventions[i].interventionActivities[j].subcategoryId);
                                    }
                                }
                            }
                            else{
                                if(!notMatchingSubcategories.includes(selectedMonthInterventions[i].interventionActivities[j].subcategoryId)){
                                    notMatchingSubcategories.push(selectedMonthInterventions[i].interventionActivities[j].subcategoryId);
                                }
                            }
                        }
                        //If there are filtered activities in the intervention i draw an entry
                        if(tempHtml3!=="") {
                            var nowDateTime = new Date();
                            var interventionDateTime = new Date(selectedMonthInterventions[i].plannedStartDate.replace(" ","T"));
                            var isLate=(interventionDateTime<nowDateTime)&&(!selectedMonthInterventions[i].isDisabled)&&(!selectedMonthInterventions[i].interventionCompleted);
                            tempHtml+="<div class='entry"+((isLate)?" late":"")+((selectedMonthInterventions[i].isDisabled)?" disabled":"")+((selectedMonthInterventions[i].interventionCompleted)?" completed":"")+"' onclick='closePopup();selectIntervention("+selectedMonthInterventions[i].interventionId+");'><div class='entry-body'><div class='dots entry-header'>";
                            tempHtml+=interventionWarning+" "+"<div class='entry-title'>Intervento "+((selectedMonthInterventions[i].parentInterventionId!=null)?selectedMonthInterventions[i].parentInterventionId:selectedMonthInterventions[i].interventionId)+
                                ((selectedMonthInterventions[i].interventionCompleted)?(selectedMonthInterventions[i].isValidated?"<span class='entry-completed-label'>Validato<span>":"<span class='entry-completed-label'>Completato<span>"):"")+
                                ((selectedMonthInterventions[i].isDisabled)?"<span class='entry-disabled-label'>Disabilitato<span>":"")+
                                ((isLate)?"<span class='entry-late-label'>In ritardo<span>":"")+
                                "</div>";
                            tempHtml+="<div class='site-label'><span class='site'>"+selectedMonthInterventions[i].letter+"</span>"+selectedMonthInterventions[i].siteName+"</div>";
                            tempHtml+="<div class='site-label'><span class='site'><i class='fa fa-wrench' aria-hidden='true'></i></span>"+((selectedMonthInterventions[i].executorName===null)?"Non assegnato":selectedMonthInterventions[i].executorName)+"</div>";
                            tempHtml+="<div class='time-label'><span class='clock'><i class='fa fa-clock-o' aria-hidden='true'></i></span>"+intervStartTime+" - "+intervEndTime+"</div>";
                            tempHtml+="</div><div class='dots'>";
                            tempHtml+=tempHtml3;
                            if(notMatchingSubcategories.length===1){
                                tempHtml+="<div class='intervention-subcategory-dots'>" +
                                    "<div class='category-label'><div class='category-small b-grey'><i class='fa fa-ellipsis-h fa-2x' aria-hidden='true'></i></div>Un'altra sottocategoria</div>" +
                                    "</div>";
                            }
                            if(notMatchingSubcategories.length>1){
                                tempHtml+="<div class='intervention-subcategory-dots'>" +
                                    "<div class='category-label'><div class='category-small b-grey'><i class='fa fa-ellipsis-h fa-2x' aria-hidden='true'></i></div>Altre "+notMatchingSubcategories.length+" sottocategorie</div>" +
                                    "</div>";
                            }
                            tempHtml+="</div></div></div>";
                        }
                    }
                }
            }
        }
    }
    if(interventionsCount===0) {
        tempHtml+="<div class='no-results-message'>Nessun intervento pianificato per questo giorno corrisponde ai filtri applicati.<br><br><br></div>";
    }
    tempHtml+="</div>";
    setInnerHtml(
        document.querySelector("#interventions-of-day-popup .popup-content div"),
        tempHtml,
        function(){scroll.refreshPopupById("interventions-of-day", false);}
    );
    if(popupManager.getCurrentPopup()!=="interventions-of-day") {
        popup("interventions-of-day", false, false, false);
    }
}

function getInterventionsCountForDate(interventionList,dateString) {
    //company filter
    var selectedCompanyFilter=globalFilterByCompany;
    if(selectedCompanyFilter!=="") {
        selectedCompanyFilter=parseInt(selectedCompanyFilter);
    }
    else {
        selectedCompanyFilter=null;
    }
    //site filter
    var selectedSiteFilter=document.getElementById("calendar-site-selector").value;
    if(selectedSiteFilter!=="") {
        selectedSiteFilter=parseInt(selectedSiteFilter);
    }
    else {
        selectedSiteFilter=null;
    }
    //category filter
    var selectedCategoryFilter=document.getElementById("calendar-category-selector").value;
    if(selectedCategoryFilter!=="") {
        selectedCategoryFilter=parseInt(selectedCategoryFilter);
    }
    else {
        selectedCategoryFilter=null;
    }
    //subcategory filter
    var selectedSubcategoryFilter=document.getElementById("calendar-subcategory-selector").value;
    if(selectedSubcategoryFilter!=="") {
        selectedSubcategoryFilter=parseInt(selectedSubcategoryFilter);
    }
    else {
        selectedSubcategoryFilter=null;
    }
    //executor filter
    var selectedExecutorFilter=document.getElementById("calendar-executor-selector").value;
    if(selectedExecutorFilter!=="") {
        selectedExecutorFilter=parseInt(selectedExecutorFilter);
    }
    else {
        selectedExecutorFilter=null;
    }

    var redCounter=0,yellowCounter=0,blueCounter=0,greenCounter=0;
    var warning=0,ticketInterventions=0,interventionsDailyCounter=0,completedCounter=0,lateInterventionCounter=0;
    if(interventionList!==undefined&&interventionList!==null) {
        for(var i=0; i<interventionList.length; i++) {
            //checking intervention date
            if(interventionList[i].plannedStartDate.substring(0, interventionList[i].plannedStartDate.indexOf(' '))===dateString) {
                //checking intervention site's company
                if(interventionList[i].siteOwnedBy==selectedCompanyFilter||selectedCompanyFilter===null) {
                    //checking intervention site
                    if(interventionList[i].siteId==selectedSiteFilter||selectedSiteFilter===null) {
                        if(interventionList[i].executor==selectedExecutorFilter||selectedExecutorFilter===null) {
                            var addedAtLeastOne=false, j;
                            for(j=0; j<interventionList[i].interventionActivities.length; j++) {
                                //checking intervention category
                                if(interventionList[i].interventionActivities[j].categoryId==selectedCategoryFilter||selectedCategoryFilter===null) {
                                    if(interventionList[i].interventionActivities[j].subcategoryId==selectedSubcategoryFilter||selectedSubcategoryFilter===null) {
                                        addedAtLeastOne=true;
                                        switch(interventionList[i].interventionActivities[j].categoryIconBackground) {
                                            case "red":
                                                redCounter++;
                                                break;
                                            case "yellow":
                                                yellowCounter++;
                                                break;
                                            case "blue":
                                                blueCounter++;
                                                break;
                                            case "green":
                                                greenCounter++;
                                                break;
                                        }
                                        if(interventionList[i].executor===null) {
                                            warning++;
                                        }
                                        if(interventionList[i].interventionActivities[j].containsTicket) {
                                            ticketInterventions++;
                                        }
                                    }
                                }
                            }
                            if(addedAtLeastOne) {
                                if(interventionList[i].interventionCompleted) {
                                    completedCounter++;
                                }
                                else {
                                    var nowDateTime = new Date();
                                    var interventionDateTime = new Date(interventionList[i].plannedStartDate.replace(" ","T"));
                                    if(interventionDateTime<nowDateTime&&(!interventionList[i].isDisabled)) {
                                        var interventionActivitiesHtml="";
                                        //This intervention is late, I add it to the late interventions popup
                                        var calendarCategoryFilter=document.getElementById("calendar-category-selector").value;
                                        var calendarSubcategoryFilter=document.getElementById("calendar-subcategory-selector").value;
                                        for(j=0;j<interventionList[i].interventionActivities.length;j++) {
                                            if(interventionList[i].interventionActivities[j].categoryId==calendarCategoryFilter||calendarCategoryFilter==="") {
                                                if(interventionList[i].interventionActivities[j].subcategoryId==calendarSubcategoryFilter||calendarSubcategoryFilter==="") {
                                                    hasTicket=interventionList[i].interventionActivities[j].containsTicket;
                                                    interventionActivitiesHtml+="<div class='intervention-subcategory-dots "+(hasTicket?"has-ticket":"")+"'>";
                                                    interventionActivitiesHtml+="<div class='category-label'><div class='category-small b-"+
                                                        interventionList[i].interventionActivities[j].categoryIconBackground+"'>"+
                                                        interventionList[i].interventionActivities[j].categoryIcon+"</div>"+
                                                        interventionList[i].interventionActivities[j].category+"</div>";
                                                    if(interventionList[i].interventionActivities[j].subcategoryIcon!=null) {
                                                        interventionActivitiesHtml+="<div class='subcategory-label'><div class='category-small b-"+
                                                            interventionList[i].interventionActivities[j].categoryIconBackground+"'>"+
                                                            interventionList[i].interventionActivities[j].subcategoryIcon+"</div>"+
                                                            interventionList[i].interventionActivities[j].subcategory+"</div>";
                                                    }
                                                    else {
                                                        interventionActivitiesHtml+="<div class='subcategory-label'>&nbsp;</div>";
                                                    }
                                                    if(hasTicket) {
                                                        interventionActivitiesHtml+="<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>";
                                                    }
                                                    interventionActivitiesHtml+="</div>";
                                                }
                                            }
                                        }
                                        lateInterventionsHtml+="<div class='entry late' onclick='closePopup();selectIntervention("+interventionList[i].interventionId+");'>"+
                                            "<div class='entry-body'>"+
                                            "<div class='dots entry-header'>"+
                                            " "+"<div class='entry-title'>Intervento "+((selectedMonthInterventions[i].parentInterventionId!=null)?selectedMonthInterventions[i].parentInterventionId:selectedMonthInterventions[i].interventionId)+
                                            "<span class='entry-late-label'>In ritardo<span>"+
                                            "</div>"+
                                            "<div class='site-label'><span class='site'>"+selectedMonthInterventions[i].letter+"</span>"+selectedMonthInterventions[i].siteName+"</div>"+
                                            "<div class='site-label'><span class='site'><i class='fa fa-wrench' aria-hidden='true'></i></span>"+((selectedMonthInterventions[i].executorName===null)?"Non assegnato":selectedMonthInterventions[i].executorName)+"</div>"+
                                            "<div class='time-label'><span class='clock'><i class='fa fa-calendar' aria-hidden='true'></i></span>"+iso8601.toEuropeanDate(interventionList[i].isoPlannedStartDate,"-")+"</div>"+
                                            "</div><div class='dots'>"+interventionActivitiesHtml+"</div></div></div></div></div>";
                                        lateInterventionCounter++;
                                    }
                                }
                                interventionsDailyCounter++;
                            }
                        }
                    }
                }
            }
        }
    }
    var html="";
    var col=0;
    if(warning>0) {
        html+="<span class='calendar-day-warning' title='"+warning+" interventi non assegnati'><span class='warning-counter'>"+warning+"</span><i class='fa fa-exclamation-circle' aria-hidden='true'></i></span>";
    }
    if(ticketInterventions>0) {
        html+="<span class='calendar-day-ticket' title='"+ticketInterventions+" ticket pianificati'><span class='warning-counter'>"+ticketInterventions+"</span><i class='fa fa-ticket' aria-hidden='true'></i></span>";
    }
    html+="<br/>";
    if(redCounter>0) {
        html+="<span class='b-red calendar-counter'><i class='fa fa-fire' aria-hidden='true'></i>"+redCounter+"</span>";
        col++;
    }
    if(yellowCounter>0) {
        html+="<span class='b-yellow calendar-counter'><i class='fa fa-home' aria-hidden='true'></i>"+yellowCounter+"</span>";
        col++;
    }
    if(col===2) {
        html+="<br/>";
    }
    if(blueCounter>0) {
        html+="<span class='b-blue calendar-counter'><span class='fa-stack'><i class='fa fa-bolt fa-stack-1x stacked' aria-hidden='true'></i><i class='fa fa-tint fa-stack-1x stacked' aria-hidden='true'></i></span>"+blueCounter+"</span>";
        col++;
        if(col===2)
            html+="<br/>";
    }
    if(greenCounter>0) {
        html+="<span class='b-green calendar-counter'><i class='fa fa-leaf' aria-hidden='true'></i>"+greenCounter+"</span>";
    }
    interventionsMonthlyCounter+=interventionsDailyCounter;
    completedInterventionsMonthlyCounter+=completedCounter;
    lateInterventionsMonthlyCounter+=lateInterventionCounter;
    var greenPercentage,redPercentage,greyPercentage;
    if(interventionsDailyCounter>0) {
        greenPercentage=completedCounter/interventionsDailyCounter*100;
        redPercentage=lateInterventionCounter/interventionsDailyCounter*100;
        greyPercentage=100-(greenPercentage+redPercentage);
    }
    else {
        redPercentage=0;
        greenPercentage=0;
        greyPercentage=0;
    }
    html+="</div><div class='day-interventions-completion-bar green-bar' style='width:"+greenPercentage+"%'></div><div class='day-interventions-completion-bar grey-bar' style='width:"+greyPercentage+"%'></div><div class='day-interventions-completion-bar red-bar' style='width:"+redPercentage+"%'></div>";
    return html;
}

function getInterventionsCountForDateYearCalendar(interventionList,dateString) {
    //company filter
    var selectedCompanyFilter=globalFilterByCompany;
    //site filter
    var selectedSiteFilter=document.getElementById("calendar-year-site-selector").value;
    if(selectedSiteFilter!=="") {
        selectedSiteFilter=parseInt(selectedSiteFilter);
    }
    else {
        selectedSiteFilter=null;
    }
    //category filter
    var selectedCategoryFilter=document.getElementById("calendar-year-category-selector").value;
    if(selectedCategoryFilter!=="") {
        selectedCategoryFilter=parseInt(selectedCategoryFilter);
    }
    else {
        selectedCategoryFilter=null;
    }
    //subcategory filter
    var selectedSubcategoryFilter=document.getElementById("calendar-year-subcategory-selector").value;
    if(selectedSubcategoryFilter!=="") {
        selectedSubcategoryFilter=parseInt(selectedSubcategoryFilter);
    }
    else {
        selectedSubcategoryFilter=null;
    }
    //executor filter
    var selectedExecutorFilter=document.getElementById("calendar-year-executor-selector").value;
    if(selectedExecutorFilter!=="") {
        selectedExecutorFilter=parseInt(selectedExecutorFilter);
    }
    else {
        selectedExecutorFilter=null;
    }
    var redCounter=0,yellowCounter=0,blueCounter=0,greenCounter=0;
    var j,interventionsDailyCounter=0,completedCounter=0,lateInterventionCounter=0;
    if(interventionList!==undefined&&interventionList!==null) {
        for(var i=0; i<interventionList.length; i++) {
            //checking intervention date
            if(interventionList[i].plannedStartDate.substring(0, interventionList[i].plannedStartDate.indexOf(' '))==dateString) {
                //checking intervention site's company
                if(interventionList[i].siteOwnedBy==selectedCompanyFilter||selectedCompanyFilter===null) {
                    //checking intervention site
                    if(interventionList[i].siteId==selectedSiteFilter||selectedSiteFilter===null) {
                        if(interventionList[i].executor==selectedExecutorFilter||selectedExecutorFilter===null) {
                            var addedAtLeastOne=false;
                            for(j=0; j<interventionList[i].interventionActivities.length; j++) {
                                //checking intervention category
                                if(interventionList[i].interventionActivities[j].categoryId==selectedCategoryFilter||selectedCategoryFilter===null) {
                                    if(interventionList[i].interventionActivities[j].subcategoryId==selectedSubcategoryFilter||selectedSubcategoryFilter===null) {
                                        addedAtLeastOne=true;
                                        switch(interventionList[i].interventionActivities[j].categoryIconBackground) {
                                            case "red":
                                                redCounter++;
                                                break;
                                            case "yellow":
                                                yellowCounter++;
                                                break;
                                            case "blue":
                                                blueCounter++;
                                                break;
                                            case "green":
                                                greenCounter++;
                                                break;
                                        }
                                    }
                                }
                            }
                            if(addedAtLeastOne) {
                                if(interventionList[i].interventionCompleted) {
                                    completedCounter++;
                                }
                                else {
                                    var nowDateTime = new Date();
                                    var interventionDateTime = new Date(interventionList[i].plannedStartDate.replace(" ","T"));
                                    if(interventionDateTime<nowDateTime&&(!interventionList[i].isDisabled)) {
                                        var interventionActivitiesHtml="";
                                        //This intervention is late, I add it to the late interventions popup
                                        var calendarCategoryFilter=document.getElementById("calendar-year-category-selector").value;
                                        var calendarSubcategoryFilter=document.getElementById("calendar-year-subcategory-selector").value;
                                        for(j=0;j<interventionList[i].interventionActivities.length;j++) {
                                            if(interventionList[i].interventionActivities[j].categoryId==calendarCategoryFilter||calendarCategoryFilter==="") {
                                                if(interventionList[i].interventionActivities[j].subcategoryId==calendarSubcategoryFilter||calendarSubcategoryFilter==="") {
                                                    hasTicket=interventionList[i].interventionActivities[j].containsTicket;
                                                    interventionActivitiesHtml+="<div class='intervention-subcategory-dots "+(hasTicket?"has-ticket":"")+"'>";
                                                    interventionActivitiesHtml+="<div class='category-label'><div class='category-small b-"+
                                                        interventionList[i].interventionActivities[j].categoryIconBackground+"'>"+
                                                        interventionList[i].interventionActivities[j].categoryIcon+"</div>"+
                                                        interventionList[i].interventionActivities[j].category+"</div>";
                                                    if(interventionList[i].interventionActivities[j].subcategoryIcon!=null) {
                                                        interventionActivitiesHtml+="<div class='subcategory-label'><div class='category-small b-"+
                                                            interventionList[i].interventionActivities[j].categoryIconBackground+"'>"+
                                                            interventionList[i].interventionActivities[j].subcategoryIcon+"</div>"+
                                                            interventionList[i].interventionActivities[j].subcategory+"</div>";
                                                    }
                                                    else {
                                                        interventionActivitiesHtml+="<div class='subcategory-label'>&nbsp;</div>";
                                                    }
                                                    if(hasTicket) {
                                                        interventionActivitiesHtml+="<div class='ticket-label'><div class='category-small b-violet'><i class='fa fa-ticket' aria-hidden='true'></i></div>Ticket</div>";
                                                    }
                                                    interventionActivitiesHtml+="</div>";
                                                }
                                            }
                                        }
                                        lateInterventionsHtml+="<div class='entry late' onclick='closePopup();selectIntervention("+interventionList[i].interventionId+");'>"+
                                            "<div class='entry-body'>"+
                                            "<div class='dots entry-header'>"+
                                            " "+"<div class='entry-title'>Intervento "+((selectedMonthInterventions[i].parentInterventionId!=null)?selectedMonthInterventions[i].parentInterventionId:selectedMonthInterventions[i].interventionId)+
                                            "<span class='entry-late-label'>In ritardo<span>"+
                                            "</div>"+
                                            "<div class='site-label'><span class='site'>"+selectedMonthInterventions[i].letter+"</span>"+selectedMonthInterventions[i].siteName+"</div>"+
                                            "<div class='site-label'><span class='site'><i class='fa fa-wrench' aria-hidden='true'></i></span>"+((selectedMonthInterventions[i].executorName===null)?"Non assegnato":selectedMonthInterventions[i].executorName)+"</div>"+
                                            "<div class='time-label'><span class='clock'><i class='fa fa-calendar' aria-hidden='true'></i></span>"+iso8601.toEuropeanDate(interventionList[i].isoPlannedStartDate,"-")+"</div>"+
                                            "</div><div class='dots'>"+interventionActivitiesHtml+"</div></div></div></div></div>";
                                        lateInterventionCounter++;
                                    }
                                }
                                interventionsDailyCounter++;
                            }
                        }
                    }
                }
            }
        }
    }
    completedInterventionsYearlyCounter+=completedCounter;
    lateInterventionsYearlyCounter+=lateInterventionCounter;
    interventionsYearlyCounter+=interventionsDailyCounter;
    var html="<span class='tiniest-dots'>";
    if(redCounter>0) {
        html+="<span style='color:#E53935'>•</span>";
    }
    if(yellowCounter>0) {
        html+="<span style='color:#fba92d'>•</span>";
    }
    if(blueCounter>0) {
        html+="<span style='color:#2196F3'>•</span>";
    }
    if(greenCounter>0) {
        html+="<span style='color:#97c701'>•</span>";
    }
    html+="</span>";
    return html;
}

function renderCalendar(month,year) {
    var calMonth = (isNaN(month) || month == null) ? calCurrentDate.getMonth() : month;
    var calYear  = (isNaN(year) || year == null) ? calCurrentDate.getFullYear() : year;
    lastMonth=calMonth;
    lastYear=calYear;
    ajaxGetCalendarBody(calMonth,calYear);
    document.querySelector("#page-calendar .month").innerHTML=getCalendarHeader(calMonth,calYear);
}

function goToTodayFromMonthCalendar() {
    var now = new Date();
    //currentMonth already selected
    if(now.getMonth()==lastMonth&&now.getFullYear()==lastYear) {
        getInterventionsForDay(now.getFullYear()+"-"+(now.getMonth()+1)+"-"+now.getDate());
    }
    else {
        //going to current month
        renderCalendar(now.getMonth(),now.getFullYear());
    }
}

function goToTodayFromYearCalendar() {
    var now = new Date();
    //currentMonth already selected
    if(now.getFullYear()==lastYear) {
        getInterventionsForDay(now.getFullYear()+"-"+(now.getMonth()+1)+"-"+now.getDate());
    }
    else {
        //going to current month
        renderYearCalendar(now.getFullYear());
    }
}

function drawCalendarYearBody(calYear,interventions) {
    interventionsYearlyCounter=0;
    completedInterventionsYearlyCounter=0;
    lateInterventionsYearlyCounter=0;
    lateInterventionsHtml="";
    document.getElementById("calendar-year-page").innerHTML="";
    scroll.refreshByName("year-calendar");
    var calMonth=0;
    for(;calMonth<12;calMonth++) {
        var i;
        var html = "<table class='calendar-table' cellspacing='0'>";
        html += "<tr class='calendar-month-name-header'><td colspan='7'>"+monthNames[calMonth]+"</td></tr>";
        html += "<tr class='calendar-header'>";
        for(i = 0; i <= 6; i++ ) {
            html += "<td class='calendar-header-day'>";
            html += calDaysLabels[i];
            html += "</td>";
        }
        html+="</tr><tr>";
        // get first day of month
        var firstDay = new Date(calYear, calMonth, 1);
        var startingDay = firstDay.getDay();
        //first day of week is monday, not sunday
        if(startingDay===0) {
            startingDay=6;
        }
        else {
            startingDay--;
        }
        // find number of days in month
        var monthLength = calDaysInMonth[calMonth];
        // compensate for leap year
        if (calMonth === 1) {
            // February only!
            if((calYear % 4 === 0 && calYear % 100 !== 0) || calYear % 400 === 0)
                monthLength = 29;
        }
        // fill in the days
        var day = 1;
        // this loop is for is weeks (rows)
        for(i=0; i<9; i++) {
            // this loop is for weekdays (cells)
            for(var j=0; j<=6; j++) {
                var isToday = calCurrentDate.getMonth() === calMonth && calCurrentDate.getFullYear() === calYear && calCurrentDate.getDate() === day;
                var date="",dayStr="",calMonthStr;
                if (day <= monthLength && (i > 0 || j >= startingDay)) {
                    dayStr += day;
                    date=calYear+"-"+(calMonth+1)+"-"+day;
                    if(calMonth<9) {
                        calMonthStr="0"+(calMonth+1);
                    }
                    else {
                        calMonthStr=calMonth+1;
                    }
                    if(day<=9) {
                        dayStr="0"+day;
                    }
                    else {
                        dayStr=day;
                    }
                    if(interventions) {
                        dayStr+=getInterventionsCountForDateYearCalendar(interventions,calYear+'-'+calMonthStr+'-'+dayStr);
                    }
                    day++;
                }
                if(isToday&&dayStr!=="") {
                    html += "<td class='calendar-day today' onclick='getInterventionsForDay(\""+date+"\");'>"+dayStr+"</td>";
                }
                else {
                    if(date!=="") {
                        html += "<td class='calendar-day' onclick='getInterventionsForDay(\""+date+"\");'>"+dayStr+"</td>";
                    }
                    else {
                        html += "<td class='calendar-day disabled'></td>";
                    }
                }
            }
            // stop making rows if we've run out of days
            if (day > monthLength) {
                break;
            }
            else {
                html += "</tr><tr>";
            }
        }
        html += "</tr></table>";
        document.getElementById("calendar-year-page").innerHTML+=html;
    }
    scroll.refreshByName("year-calendar");
    setInnerHtml(
        document.getElementById("year-calendar-completed-interventions-counter"),
        completedInterventionsYearlyCounter+"/"+interventionsYearlyCounter
    );
    if(completedInterventionsYearlyCounter==interventionsYearlyCounter&&interventionsYearlyCounter>0) {
        dom.addClass(document.getElementById("year-calendar-completed-interventions-counter").parentNode,"bg-green");
    }
    else {
        dom.removeClass(document.getElementById("year-calendar-completed-interventions-counter").parentNode,"bg-green");
    }
    setInnerHtml(
        document.getElementById("year-calendar-late-interventions-counter"),
        lateInterventionsYearlyCounter
    );
    if(lateInterventionsYearlyCounter>0) {
        dom.addClass(document.getElementById("year-calendar-late-interventions-counter").parentNode,"bg-red");
    }
    else {
        dom.removeClass(document.getElementById("year-calendar-late-interventions-counter").parentNode,"bg-red");
    }
    setInnerHtml(
        document.getElementById("year-late-interventions-container"),
        lateInterventionsHtml,
        function(){scroll.refreshPopupById("year-late-interventions", false);}
    );
}

function ajaxGetCalendarYearBody(calYear) {
    dom.removeClass(document.getElementById("calendar-year-loading"),"hidden");
    drawCalendarYearBody(calYear);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            selectedMonthInterventions=response.interventions;
            dom.addClass(document.getElementById("calendar-year-loading"),"hidden");
            drawCalendarYearBody(calYear,response.interventions);
        }
        else {
            outcomePopup.innerHTML="<div class='message error'> Errore durante l'elaborazione: "+response.message+"</div>";
            popup("outcome", false, false, false);
        }
    };
    if(globalFilterByCompany) {
        xhttp.open("GET", BASE_URL+"/ws/getInterventionsByYear.php?companyId="+globalFilterByCompany+"&year="+calYear, true);
    }
    else {
        xhttp.open("GET", BASE_URL+"/ws/getInterventionsByYear.php?year="+calYear, true);
    }
    xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhttp.setRequestHeader("Authentication-Token",currentAuthToken);
    xhttp.send("");
}

function viewYearCalendar() {
    changePage("page-calendar-year");
    renderYearCalendar();
    countYearCalendarAppliedFilters();
}

function viewMonthCalendar() {
    changePage("page-calendar");
    renderCalendar();
    countMonthCalendarAppliedFilters();
}

function renderYearCalendar(year) {
    var calYear = (!year) ? (lastYear || calCurrentDate.getFullYear()) : year;
    lastYear=calYear;
    ajaxGetCalendarYearBody(calYear);
    setInnerHtml(
        document.querySelector("#page-calendar-year .year"),
        getYearCalendarHeader(calYear),
        function(){scroll.refreshByName("year-calendar");}
    );
}

/******** localDB.js start ********/


var db = {
    currentVersion: "1.10",
    localDb: null,
    createLocalTables: function(successCallback, failureCallback) {
        db.localDb.sqlBatch([
            "CREATE TABLE IF NOT EXISTS Utilities (" +
            " utilityKey TEXT PRIMARY KEY," +
            " utilityData TEXT" +
            ")",
            "CREATE TABLE IF NOT EXISTS OfflineOperationQueue (" +
            " userId INT NOT NULL, " +
            " jsonRequest TEXT, " +
            " ws TEXT, " +
            " operationType TEXT, " +
            " creation TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP, " +
            " oneTimeToken VARCHAR(32) DEFAULT NULL, " +
            " lastAttempt TIMESTAMP, " +
            " lastAttemptError TEXT " +
            ")",
            "CREATE TABLE IF NOT EXISTS User (" +
            " userId INT NOT NULL PRIMARY KEY, " +
            " username VARCHAR(30) NOT NULL, " +
            " employeeId INT DEFAULT NULL," +
            " authToken TEXT, " +
            " touchIdEnabled INT NOT NULL DEFAULT '0', " +
            " lastEdit TEXT NOT NULL" +
            ")",
            "CREATE TABLE IF NOT EXISTS UserPermission (" +
            " userId INT NOT NULL," +
            " companyId INT NOT NULL," +
            " permissionCode VARCHAR(40) NOT NULL," +
            " permissionLevel VARCHAR(10) NOT NULL," +
            " lastEdit TEXT NOT NULL," +
            " PRIMARY KEY(userId, companyId, permissionCode)" +
            ")",
            "CREATE TABLE IF NOT EXISTS AnomalyLevel (" +
            " anomalyLevel VARCHAR(30) NOT NULL," +
            " slaHours INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(anomalyLevel, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Company (" +
            " companyId INT NOT NULL," +
            " companyName VARCHAR(64) NOT NULL," +
            " eMailAddress TEXT DEFAULT NULL," +
            " fiscalCode VARCHAR(16) DEFAULT NULL," +
            " isContractor INT NOT NULL," +
            " isCustomer INT NOT NULL," +
            " isEditable INT NOT NULL," +
            " privateVersion INT DEFAULT NULL," +
            " publicVersion INT DEFAULT NULL," +
            " telephoneNumber VARCHAR(15) DEFAULT NULL," +
            " vatNumber VARCHAR(20) DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(companyId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS BuildingGroup (" +
            " buildingGroupId INT NOT NULL," +
            " associatedCompanies TEXT DEFAULT NULL, " +
            " name VARCHAR(30) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(buildingGroupId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Building (" +
            " buildingId INT NOT NULL," +
            " buildingGroupId INT NOT NULL," +
            " name VARCHAR(40) NOT NULL," +
            " associatedCompanies TEXT DEFAULT NULL, " +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(buildingId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS CadastralCategory (" +
            " categoryName VARCHAR(4) NOT NULL," +
            " description VARCHAR(160) DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(categoryName, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS CategoryGroup (" +
            " categoryGroupId INT NOT NULL," +
            " background VARCHAR(15) NOT NULL," +
            " icon VARCHAR(200) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(categoryGroupId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Category (" +
            " categoryId INT NOT NULL," +
            " categoryGroupId INT NOT NULL," +
            " background VARCHAR(15) NOT NULL," +
            " name VARCHAR(45) NOT NULL," +
            " icon VARCHAR(200) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(categoryId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Subcategory (" +
            " subcategoryId INT NOT NULL," +
            " categoryId INT NOT NULL," +
            " name VARCHAR(45) NOT NULL," +
            " icon VARCHAR(50) NOT NULL," +
            " orderKey INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(subcategoryId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS DocumentType (" +
            " documentTypeId INT NOT NULL," +
            " documentType VARCHAR(65) NOT NULL," +
            " backgroundColor VARCHAR(7) NOT NULL," +
            " textColor VARCHAR(7) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(documentTypeId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS DocumentSubtype (" +
            " documentSubtypeId INT NOT NULL," +
            " documentSubtype VARCHAR(65) NOT NULL," +
            " documentTypeId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(documentSubtypeId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS DocumentTag (" +
            " tag VARCHAR(40) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(tag, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Floor (" +
            " floorId INT NOT NULL," +
            " floorName VARCHAR(25) NOT NULL," +
            " siteId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(floorId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS FrequencyType (" +
            " frequencyType VARCHAR(20) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(frequencyType, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS InterventionActivity (" +
            " interventionActivityId INT NOT NULL," +
            " interventionId INT NOT NULL," +
            " activityOnAssetType INT DEFAULT NULL," +
            " relatedTicket INT DEFAULT NULL," +
            " relatedContractActivity INT DEFAULT NULL," +
            " admittableExecutionStart TEXT DEFAULT NULL," +
            " admittableExecutionEnd TEXT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(interventionActivityId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS ActivityForSubcategory (" +
            " activityForSubcategoryId INT NOT NULL," +
            " subcategoryId INT NOT NULL," +
            " description INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(activityForSubcategoryId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS ActivityOnAssetInstance (" +
            " activityOnAssetInstanceId INT NOT NULL," +
            " interventionActivity INT NOT NULL," +
            " assetInstance INT NOT NULL," +
            " completionDatetime TEXT DEFAULT NULL," +
            " success INT DEFAULT NULL," +
            " notes TEXT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(activityOnAssetInstanceId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Intervention (" +
            " interventionId INT NOT NULL," +
            " completed INT NOT NULL," +
            " contractor INT DEFAULT NULL," +
            " executorId INT DEFAULT NULL," +
            " executorNotificationsSent INT NOT NULL," +
            " fireLogBookNumber VARCHAR(10) DEFAULT NULL," +
            " interventionExecutionNotes TEXT DEFAULT NULL," +
            " isDisabled INT NOT NULL," +
            " startDate TEXT DEFAULT NULL," +
            " endDate TEXT DEFAULT NULL," +
            " plannedStartDate TEXT NOT NULL," +
            " plannedEndDate TEXT NOT NULL," +
            " outcomeName TEXT DEFAULT NULL," +
            " siteId INT NOT NULL," +
            " validationDatetime TEXT DEFAULT NULL," +
            " validationRequested INT NOT NULL DEFAULT 0," +
            " validatorUsername VARCHAR(30) DEFAULT NULL," +
            " weather VARCHAR(40) DEFAULT NULL," +
            " weatherDateTime TEXT DEFAULT NULL," +
            " windSpeed REAL DEFAULT NULL," +
            " parentInterventionId INT DEFAULT NULL," +
            " followingDayIntervention INT DEFAULT NULL," +
            " previousDayIntervention INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(interventionId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS EmployeeOnIntervention (" +
            " employeeOnInterventionId INT NOT NULL," +
            " employeeId INT NOT NULL," +
            " interventionId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(employeeOnInterventionId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Employee (" +
            " employeeId INT NOT NULL," +
            " name VARCHAR(40) DEFAULT NULL," +
            " surname VARCHAR(40) DEFAULT NULL," +
            " companyId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(employeeId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS ActivityOnAssetInstance (" +
            " activityOnAssetInstanceId INT NOT NULL," +
            " interventionActivity INT NOT NULL," +
            " assetInstance INT NOT NULL," +
            " completionDatetime TEXT DEFAULT NULL," +
            " success INT DEFAULT NULL," +
            " notes TEXT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(activityOnAssetInstanceId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Notification (" +
            " notificationId INT NOT NULL," +
            " companyId INT DEFAULT NULL," +
            " isRead INT NOT NULL," +
            " isSeen INT NOT NULL," +
            " timestamp TEXT NOT NULL," +
            " link VARCHAR(128) DEFAULT NULL," +
            " description TEXT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(notificationId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Outcome (" +
            " outcomeId INT NOT NULL," +
            " name VARCHAR(40) NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(outcomeId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS AssetInstance (" +
            " assetInstanceId INT NOT NULL," +
            " brand VARCHAR(50) DEFAULT NULL," +
            " certification VARCHAR(20) DEFAULT NULL," +
            " constructionYear INT DEFAULT NULL," +
            " creationCausal VARCHAR(40) DEFAULT NULL," +
            " floor INT DEFAULT NULL," +
            " homologation VARCHAR(20) DEFAULT NULL," +
            " installationDate TEXT DEFAULT NULL," +
            " internalCode VARCHAR(120) DEFAULT NULL," +
            " model VARCHAR(130) DEFAULT NULL," +
            " positionHint TEXT DEFAULT NULL," +
            " productionNumber VARCHAR(10) DEFAULT NULL," +
            " registrationNumber VARCHAR(40) DEFAULT NULL," +
            " revisionExpiration VARCHAR(5) DEFAULT NULL," +
            " siteId INT NOT NULL," +
            " size VARCHAR(35) DEFAULT NULL," +
            " testExpiration VARCHAR(5) DEFAULT NULL," +
            " type INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(assetInstanceId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS AssetSubcategory (" +
            " assetInstanceId INT NOT NULL," +
            " subcategoryId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(assetInstanceId, subcategoryId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS QrCode (" +
            " qrCode VARCHAR(10) NOT NULL," +
            " creation TEXT NOT NULL," +
            " relatedAsset INT DEFAULT NULL," +
            " relatedIntervention INT DEFAULT NULL," +
            " relatedTicket INT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(qrCode, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Site (" +
            " siteId INT NOT NULL," +
            " buildingId INT DEFAULT NULL," +
            " city VARCHAR(30) DEFAULT NULL," +
            " district VARCHAR(3) DEFAULT NULL," +
            " isActive INT NOT NULL," +
            " isCommon INT NOT NULL," +
            " latitude DECIMAL(10,8) DEFAULT NULL," +
            " longitude DECIMAL(11,8) DEFAULT NULL," +
            " letter VARCHAR(1) DEFAULT NULL," +
            " name VARCHAR(40) NOT NULL," +
            " ownedBy INT DEFAULT NULL," +
            " siteType VARCHAR(30) DEFAULT NULL," +
            " street VARCHAR(50) DEFAULT NULL," +
            " streetNumber VARCHAR(10) DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(siteId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS SiteType (" +
            " siteType VARCHAR(30) DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(siteType, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS TicketType (" +
            " ticketType VARCHAR(30) DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(ticketType, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS UnitOfMeasure (" +
            " unitName VARCHAR(10) NOT NULL," +
            " description VARCHAR(35) DEFAULT NULL," +
            " interventionExecution INT NOT NULL," +
            " accountingSection INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(unitName, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS Ticket (" +
            " ticketId INT NOT NULL," +
            " customerTicketId INT DEFAULT NULL," +
            " siteId INT NOT NULL," +
            " ticketType VARCHAR(30) DEFAULT NULL," +
            " reportedBy INT NOT NULL," +
            " reportTimestamp TEXT NOT NULL," +
            " description TEXT DEFAULT NULL," +
            " categoryId INT DEFAULT NULL," +
            " subcategoryId INT DEFAULT NULL," +
            " state VARCHAR(30) DEFAULT NULL," +
            " anomalyLevel VARCHAR(30) DEFAULT NULL," +
            " executionNotes TEXT DEFAULT NULL," +
            " success INT DEFAULT NULL," +
            " completionDatetime TEXT DEFAULT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(ticketId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS TicketAssignedCompany (" +
            " ticketAssignationId INT NOT NULL," +
            " ticketId INT NOT NULL," +
            " assignedCompanyId INT NOT NULL," +
            " owner INT NOT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(ticketAssignationId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS SubcategoryGroup (" +
            " subcategoryGroupId INT NOT NULL," +
            " name INT NOT NULL," +
            " owner INT DEFAULT NULL," +
            " lastEdit TEXT NOT NULL, " +
            " PRIMARY KEY(subcategoryGroupId, owner) " +
            ")",
            "CREATE TABLE IF NOT EXISTS DataSync (" +
            " companyId INT NOT NULL PRIMARY KEY," +
            " lastFullSync TEXT " +
            ")"
        ], function() {
            console.log('Populated database OK');
            successCallback();
        }, function(error) {
            errorPopup('Errore durante la creazione del DB locale: ' + error.message);
            failureCallback();
        });
    },

    upgradeSchemaFromVersion: function(version){

        var operationStartIndex=-1;
        var localDbOperations;
        var executeNextLocalDbOperation = function(){
            if(operationStartIndex+1<localDbOperations.length) {
                operationStartIndex++;
                localDbOperations[operationStartIndex]();
            }
        };

        var localDbOperationsV1_10 = [
            function() {
                //Adding table SubcategoryGroup
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS SubcategoryGroup (" +
                    " subcategoryGroupId INT NOT NULL," +
                    " name INT NOT NULL," +
                    " owner INT NOT NULL," +
                    " lastEdit TEXT NOT NULL, " +
                    " PRIMARY KEY(subcategoryGroupId, owner) " +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            }
        ];

        //Correcting missing UserPermission Primary Keys
        var localDbOperationsV1_9 = [
            function() {
                //Adding column oneTimeToken
                db.localDb.executeSql(
                    "DROP TABLE UserPermission ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS UserPermission (" +
                    " userId INT NOT NULL," +
                    " companyId INT NOT NULL," +
                    " permissionCode VARCHAR(40) NOT NULL," +
                    " permissionLevel VARCHAR(10) NOT NULL," +
                    " lastEdit TEXT NOT NULL," +
                    " PRIMARY KEY(userId, companyId, permissionCode)" +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            }
        ];

        //removing NOT NULL from Notification.companyId
        var localDbOperationsV1_8 = [
            function() {
                //Adding column oneTimeToken
                db.localDb.executeSql(
                    "DROP TABLE Notification ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS Notification (" +
                    " notificationId INT NOT NULL," +
                    " companyId INT DEFAULT NULL," +
                    " isRead INT NOT NULL," +
                    " isSeen INT NOT NULL," +
                    " timestamp TEXT NOT NULL," +
                    " link VARCHAR(128) DEFAULT NULL," +
                    " description TEXT NOT NULL," +
                    " owner INT NOT NULL," +
                    " lastEdit TEXT NOT NULL, " +
                    " PRIMARY KEY(notificationId, owner) " +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            }
        ];

        //add  parentInterventionId, previousDayIntervention, followingDayIntervention
        var localDbOperationsV1_6_V1_7 = [
            function() {
                db.localDb.executeSql(
                    "ALTER TABLE Intervention RENAME TO Intervention_old ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS Intervention (" +
                    " interventionId INT NOT NULL," +
                    " completed INT NOT NULL," +
                    " contractor INT DEFAULT NULL," +
                    " executorId INT DEFAULT NULL," +
                    " executorNotificationsSent INT NOT NULL," +
                    " fireLogBookNumber VARCHAR(10) DEFAULT NULL," +
                    " interventionExecutionNotes TEXT DEFAULT NULL," +
                    " isDisabled INT NOT NULL," +
                    " startDate TEXT DEFAULT NULL," +
                    " endDate TEXT DEFAULT NULL," +
                    " plannedStartDate TEXT NOT NULL," +
                    " plannedEndDate TEXT NOT NULL," +
                    " outcomeName TEXT DEFAULT NULL," +
                    " siteId INT NOT NULL," +
                    " validationDatetime TEXT DEFAULT NULL," +
                    " validationRequested INT NOT NULL DEFAULT 0," +
                    " validatorUsername VARCHAR(30) DEFAULT NULL," +
                    " weather VARCHAR(40) DEFAULT NULL," +
                    " weatherDateTime TEXT DEFAULT NULL," +
                    " windSpeed REAL DEFAULT NULL," +
                    " parentInterventionId INT DEFAULT NULL," +
                    " previousDayIntervention INT DEFAULT NULL," +
                    " followingDayIntervention INT DEFAULT NULL," +
                    " owner INT NOT NULL," +
                    " lastEdit TEXT NOT NULL, " +
                    " PRIMARY KEY(interventionId, owner) " +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "INSERT INTO Intervention " +
                    "  (" +
                    "    interventionId," +
                    "    completed," +
                    "    contractor," +
                    "    executorId," +
                    "    executorNotificationsSent," +
                    "    fireLogBookNumber," +
                    "    interventionExecutionNotes," +
                    "    isDisabled," +
                    "    startDate," +
                    "    endDate," +
                    "    plannedStartDate," +
                    "    plannedEndDate," +
                    "    outcomeName," +
                    "    siteId," +
                    "    validationDatetime," +
                    "    validationRequested," +
                    "    validatorUsername," +
                    "    weather," +
                    "    weatherDateTime," +
                    "    windSpeed," +
                    "    owner," +
                    "    lastEdit" +
                    "  ) " +
                    "  SELECT " +
                    "    interventionId," +
                    "    completed," +
                    "    contractor," +
                    "    executorId," +
                    "    executorNotificationsSent," +
                    "    fireLogBookNumber," +
                    "    interventionExecutionNotes," +
                    "    isDisabled," +
                    "    startDate," +
                    "    endDate," +
                    "    plannedStartDate," +
                    "    plannedEndDate," +
                    "    outcomeName," +
                    "    siteId," +
                    "    validationDatetime," +
                    "    validationRequested," +
                    "    validatorUsername," +
                    "    weather," +
                    "    weatherDateTime," +
                    "    windSpeed," +
                    "    owner," +
                    "    ?" +
                    "  FROM Intervention_old ",
                    [iso8601.now()],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                //Adding column oneTimeToken
                db.localDb.executeSql(
                    "DROP TABLE Intervention_old ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            }
        ];

        var localDbOperationsV1_4_V1_5 = [
            function() {
                db.localDb.executeSql(
                    "DROP TABLE DataSync ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS DataSync (" +
                    " companyId INT NOT NULL PRIMARY KEY," +
                    " lastFullSync TEXT " +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "DROP TABLE OfflineOperationQueue ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                //Adding column oneTimeToken
                db.localDb.executeSql(
                    "CREATE TABLE IF NOT EXISTS OfflineOperationQueue (" +
                    " userId INT NOT NULL, " +
                    " jsonRequest TEXT, " +
                    " ws TEXT, " +
                    " operationType TEXT, " +
                    " creation TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP, " +
                    " oneTimeToken VARCHAR(32) DEFAULT NULL, " +
                    " lastAttempt TIMESTAMP, " +
                    " lastAttemptError TEXT " +
                    ")",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            },
            function() {
                db.localDb.executeSql(
                    "ALTER TABLE Intervention RENAME TO Intervention_old ",
                    [],
                    function() {
                        executeNextLocalDbOperation();
                    },
                    function(error) {
                        errorPopup("Errore durante l'aggiornamento dello schema del DB locale: " + error.message);
                    }
                );
            }
        ];

        var localDbOperationsTermination = [function(){
                db.insertOrUpdateUtilityData("DB_VERSION",db.currentVersion);
                onDbReady();
            }
        ];

        //Changing local DB version
        db.createLocalTables(function() {
                switch(version){
                    case "1.10":
                        localDbOperations = localDbOperationsV1_10.concat(localDbOperationsTermination);
                        break;
                    case "1.9":
                        localDbOperations = localDbOperationsV1_9.concat(localDbOperationsV1_10).concat(localDbOperationsTermination);
                        break;
                    case "1.8":
                        localDbOperations = localDbOperationsV1_8.concat(localDbOperationsV1_9).concat(localDbOperationsV1_10).concat(localDbOperationsTermination);
                        executeNextLocalDbOperation();
                        break;
                    case "1.6":
                    case "1.7":
                        localDbOperations = localDbOperationsV1_6_V1_7.concat(localDbOperationsV1_8).concat(localDbOperationsV1_9).concat(localDbOperationsV1_10).concat(localDbOperationsTermination);
                        executeNextLocalDbOperation();
                        break;
                    case "1.4":
                    case "1.5":
                        localDbOperations = localDbOperationsV1_4_V1_5.concat(localDbOperationsV1_6_V1_7).concat(localDbOperationsV1_8).concat(localDbOperationsV1_9).concat(localDbOperationsV1_10).concat(localDbOperationsTermination);
                        executeNextLocalDbOperation();
                        break;
                }
            },
            function(error){
                errorPopup("Errore durante la creazione del DB locale: " + error.message);
            }
        );
    },
    insertOrUpdateUtilityData: function(utilityKey, utilityData) {
        var sqlTxt = "INSERT OR REPLACE INTO Utilities (" +
            " utilityKey," +
            " utilityData" +
            ") " +
            "VALUES (?,?)";
        db.localDb.executeSql(
            sqlTxt,
            [utilityKey, utilityData],
            function() {
                if(utilityKey==="DB_VERSION") {
                    /*local DB schema updated*/
                }
            },
            function(error) {
                errorPopup("Errore durante la memorizzazione di " + utilityKey + ": " + error.message);
            }
        );
    },
    getUtilityDataRow: function(utilityKey, successCallback, failureCallback) {
        var sqlTxt = " SELECT " +
            " utilityData " +
            " FROM " +
            " Utilities " +
            " WHERE " +
            " utilityKey = ?";
        db.localDb.executeSql(
            sqlTxt,
            [utilityKey],
            function(resultSet) {
                if(typeof successCallback === "function") {
                    if(resultSet.rows.length>0) {
                        successCallback(resultSet.rows.item(0).utilityData);
                    }
                    else {
                        successCallback(null);
                    }
                }
                else {
                    errorPopup("The given callback is not a function!");
                }
            },
            failureCallback
        );
    },
    registerUserSession: function(userId, username, authToken) {
        var errorCallback = function(error){ console.error("Errore durante la registrazione della sessione utente: " + JSON.stringify(error)); };
        var prepareUserTable="CREATE TABLE IF NOT EXISTS User (" +
            " userId INT NOT NULL PRIMARY KEY, " +
            " username VARCHAR(30) NOT NULL, " +
            " employeeId INT DEFAULT NULL," +
            " authToken TEXT, " +
            " touchIdEnabled INT NOT NULL DEFAULT '0', " +
            " lastEdit TEXT NOT NULL" +
            ")";
        var sqlTxt = "INSERT OR REPLACE INTO User (" +
            " userId," +
            " username," +
            " employeeId," +
            " authToken," +
            " touchIdEnabled,"+
            " lastEdit" +
            ") " +
            "VALUES (" +
            " ?, " +
            " ?, " +
            " (SELECT employeeId FROM User WHERE userId=?), " +
            " ?, " +
            " (SELECT touchIdEnabled FROM User WHERE userId=?), " +
            " ? " +
            " ) ";
        db.localDb.executeSql(
            prepareUserTable,
            [],
            function(){
                db.localDb.executeSql(
                    sqlTxt,
                    [
                        userId,
                        username,
                        userId,
                        authToken,
                        userId,
                        iso8601.now()
                    ],
                    function(){},
                    errorCallback
                );
            },
            errorCallback
        );
    },
    getLastUserSession: function(successCallback) {
        db.localDb.executeSql(
            "SELECT authToken " +
            "FROM User " +
            "WHERE authToken IS NOT NULL " +
            "AND authToken!='' " +
            "LIMIT 1 ",
            [],
            function(resultSet) {
                if(typeof successCallback === "function") {
                    if(resultSet.rows.length>0) {
                        successCallback(resultSet.rows.item(0).authToken);
                    }
                    else {
                        successCallback(null);
                    }
                }
                else {
                    errorPopup("The given callback is not a function!");
                }
            },
            function(error) {
                errorPopup("Errore durante la lettura dei QR: " + error.message);
            }
        );
    },
    deleteUserSessions: function(){
        db.localDb.executeSql("DELETE FROM " + " User");
    },
    enqueueOfflineOperation: function(jsonRequest,ws,operationType,currentUserId,previousOperationsRowId,successCallback){
        var sqlTxt;
        if(previousOperationsRowId!==null&&previousOperationsRowId!==undefined){
            //Query di update dell'operazione precedente
            sqlTxt = "UPDATE OfflineOperationQueue SET " +
                " userId=?, " +
                " jsonRequest=?," +
                " ws=?," +
                " operationType=? " +
                " WHERE ROWID = ?";
            db.localDb.executeSql(
                sqlTxt,
                [
                    currentUserId,
                    jsonRequest,
                    ws,
                    operationType,
                    previousOperationsRowId
                ],
                function() {
                    // Success callback
                    if(typeof successCallback === "function") {
                        successCallback(previousOperationsRowId);
                    }
                    else {
                        console.error("The given callback is not a function!");
                    }
                },
                function(error){
                    errorPopup("Errore durante l'aggiornamento di un'operazione nella coda offline: " + error.message);
                }
            );
        }
        else {
            //New operation
            sqlTxt = "INSERT INTO OfflineOperationQueue (" +
                " userId, " +
                " jsonRequest," +
                " ws," +
                " operationType" +
                ") " +
                "VALUES (?,?,?,?)";
            db.localDb.executeSql(
                sqlTxt,
                [
                    currentUserId,
                    jsonRequest,
                    ws,
                    operationType
                ],
                function(resultSet) {
                    // Success callback
                    if(typeof successCallback === "function") {
                        successCallback(resultSet.insertId);
                    }
                    else {
                        console.error("The given callback is not a function!");
                    }
                },
                function(error){
                    errorPopup("Errore durante l'inserimento di un'operazione nella coda offline: " + error.message);
                }
            );
        }
    },
    getEnqueuedOfflineOperationsForUser: function(currentUserId, successCallback) {
        db.localDb.executeSql(
            " SELECT ROWID,jsonRequest,ws,operationType,creation,lastAttemptError,lastAttempt " +
            " FROM OfflineOperationQueue " +
            " WHERE userId = ?" +
            " ORDER BY creation ASC ",
            [currentUserId],
            function(resultSet) {
                if(typeof successCallback === "function") {
                    //ROWID,jsonRequest,ws,operationType,creation
                    successCallback(resultSet);
                }
                else {
                    errorPopup("Errore in getEnqueuedOfflineOperationsForUser: Il callback non è una funzione!");
                }
            },
            function(error) {
                errorPopup("Errore durante la lettura della coda operazioni offline: " + error.message);
            }
        );
    },
    removeOfflineOperationFromQueue: function(rowId,successCallback){
        db.localDb.executeSql(
            " DELETE FROM OfflineOperationQueue "+
            " WHERE ROWID = ? ",
            [rowId],
            function() {
                if(typeof successCallback === "function"){
                    successCallback();
                }
            },
            function(error) {
                errorPopup("Errore durante la rimozione di un'attività in coda dal DB locale: " + error.message);
            }
        );
    },
    logOfflineOperationAttempt: function(rowId, error, successCallback) {
        db.localDb.executeSql(
            " UPDATE OfflineOperationQueue " +
            " SET lastAttemptError = ?, " +
            " lastAttempt = CURRENT_TIMESTAMP " +
            " WHERE ROWID = ?",
            [
                error,
                rowId
            ],
            function() {
                if(typeof successCallback === "function"){
                    successCallback();
                }
            },
            function(error) {
                errorPopup("Errore durante l'aggiornamento di un'operazione nella coda operazioni offline: " + error.message);
            }
        );
    },
    getOldestEnqueuedOfflineOperationForUser: function(currentUserId, successCallback) {
        db.localDb.executeSql(
            " SELECT ROWID,jsonRequest,ws,operationType,creation,lastAttemptError,lastAttempt " +
            " FROM OfflineOperationQueue " +
            " WHERE userId = ?" +
            " ORDER BY lastAttempt ASC " +
            " LIMIT 1",
            [currentUserId],
            function(resultSet) {
                if(typeof successCallback === "function") {
                    if(resultSet.rows.length===0){
                        successCallback(null);
                    }
                    else {
                        successCallback(resultSet.rows.item(0));
                    }
                }
                else {
                    errorPopup("Errore in getEnqueuedOfflineOperationsForUser: La callback non è una funzione!");
                }
            },
            function(error) {
                errorPopup("Errore durante la lettura della coda operazioni offline: " + error.message);
            }
        );
    },
    getEnqueuedOfflineOperationsCountForUser: function(currentUserId, successCallback) {
        db.localDb.executeSql(
            " SELECT count(*) AS counter " +
            " FROM OfflineOperationQueue " +
            " WHERE userId = ?",
            [currentUserId],
            function(resultSet) {
                if(typeof successCallback === "function") {
                    successCallback(resultSet.rows.item(0).counter);
                }
                else {
                    errorPopup("Errore in getEnqueuedOfflineOperationsCountForUser: La callback non è una funzione!");
                }
            },
            function(error) {
                errorPopup("Errore durante la lettura della coda operazioni offline: " + error.message);
            }
        );
    },
    updateAnomalyLevels: function(anomalyLevels, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella AnomalyLevel: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO AnomalyLevel (" +
            " anomalyLevel," +
            " slaHours, " +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?)";
        db.localDb.executeSql(
            "DELETE FROM AnomalyLevel "+
            "WHERE owner = ?",
            [currentCompanyId],
            function(){
                anomalyLevels.forEach(function(anomalyLevel) {
                    db.localDb.executeSql(
                        insertQuery,
                        [
                            anomalyLevel.levelName,
                            anomalyLevel.slaHours,
                            currentCompanyId,
                            anomalyLevel.lastEdit
                        ],
                        function(){},
                        errorCallback
                    );
                });
            },
            errorCallback
        );
        successCallback(); //todo this as success callback of a transaction
    },
    updateTicketTypes: function(ticketTypes, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella TicketType: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO TicketType (" +
            " ticketType," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?)";
        db.localDb.executeSql(
            "DELETE FROM TicketType "+
            "WHERE owner = ?",
            [currentCompanyId],
            function(){
                ticketTypes.forEach(function(ticketType) {
                    db.localDb.executeSql(
                        insertQuery,
                        [
                            ticketType,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(){},
                        errorCallback
                    );
                });
            },
            errorCallback
        );
        successCallback();//todo this as success callback of a transaction
    },
    updateUserPermissions: function(userPermissions, userId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella UserPermissions: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO UserPermission (" +
            " userId," +
            " companyId," +
            " permissionCode," +
            " permissionLevel," +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                userPermissions.forEach(function(companyPermissions) {
                    companyPermissions.permissions.forEach(function(permission) {
                        tx.executeSql(
                            insertQuery,
                            [
                                userId,
                                companyPermissions.companyId,
                                permission.permissionCode,
                                permission.permissionLevel,
                                iso8601.now()
                            ],
                            function(tx, resultSet) {
                            },
                            function(tx, error) {
                                console.error('Query error: ' + error.message);
                            }
                        );
                    });
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateBuildingsGroups: function(buildingsGroups, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella BuildingsGroups: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO BuildingGroup (" +
            " buildingGroupId," +
            " associatedCompanies," +
            " name," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                buildingsGroups.forEach(function(buildingGroup) {
                    tx.executeSql(
                        insertQuery,
                        [
                            buildingGroup.buildingGroupId,
                            buildingGroup.associatedCompanies.join(","),
                            buildingGroup.name,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateBuildings: function(buildings, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Building: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Building (" +
            " buildingId," +
            " buildingGroupId," +
            " name," +
            " associatedCompanies," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                buildings.forEach(function(building) {
                    tx.executeSql(
                        insertQuery,
                        [
                            building.buildingId,
                            building.buildingsGroup,
                            building.associatedCompanies.join(","),
                            building.name,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateCadastralCategories: function(cadastralCategories, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella CadastralCategory: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO CadastralCategory (" +
            " categoryName," +
            " description," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                cadastralCategories.forEach(function(cadastralCategory) {
                    tx.executeSql(
                        insertQuery,
                        [
                            cadastralCategory.categoryName,
                            cadastralCategory.description,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateCategoryGroups: function(categoryGroups, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella CategoryGroup: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO CategoryGroup (" +
            " categoryGroupId," +
            " background," +
            " icon," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                categoryGroups.forEach(function(categoryGroup) {
                    tx.executeSql(
                        insertQuery,
                        [
                            categoryGroup.categoryGroupId,
                            categoryGroup.background,
                            categoryGroup.icon,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateCategories: function(categories, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Category: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Category (" +
            " categoryId," +
            " categoryGroupId," +
            " background," +
            " name," +
            " icon," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                categories.forEach(function(category) {
                    tx.executeSql(
                        insertQuery,
                        [
                            category.categoryId,
                            category.categoryGroupId,
                            category.background,
                            category.name,
                            category.icon,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateSubcategories: function(subcategories, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Subcategory: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Subcategory (" +
            " subcategoryId," +
            " categoryId," +
            " name," +
            " icon," +
            " orderKey," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,NULL,?,?)";
        db.localDb.transaction(
            function(tx) {
                subcategories.forEach(function(subcategory) {
                    if(subcategory.subcategoryId!=null){
                        tx.executeSql(
                            insertQuery,
                            [
                                subcategory.subcategoryId,
                                subcategory.categoryId,
                                subcategory.name,
                                subcategory.icon,
                                currentCompanyId,
                                iso8601.now()
                            ],
                            function(tx, resultSet) {
                            },
                            function(tx, error) {
                                console.error('Query error: ' + error.message);
                            }
                        );
                    }
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateSubcategoryGroups: function(subcategoryGroups, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella SubcategoryGroup: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO SubcategoryGroup (" +
            " subcategoryGroupId," +
            " name," +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?)";
        db.localDb.transaction(
            function(tx) {
                subcategoryGroups.forEach(function(subcategoryGroup) {
                    if(subcategoryGroup.subcategoryGroupId!=null){
                        tx.executeSql(
                            insertQuery,
                            [
                                subcategoryGroup.subcategoryGroupId,
                                subcategoryGroup.name,
                                iso8601.now()
                            ],
                            function(tx, resultSet) {
                            },
                            function(tx, error) {
                                console.error('Query error: ' + error.message);
                            }
                        );
                    }
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateCompanies: function(companies, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Company: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Company (" +
            " companyId," +
            " companyName," +
            " eMailAddress," +
            " fiscalCode," +
            " isContractor," +
            " isCustomer," +
            " isEditable," +
            " privateVersion," +
            " publicVersion," +
            " telephoneNumber," +
            " vatNumber," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                companies.forEach(function(company) {
                    tx.executeSql(
                        insertQuery,
                        [
                            company.companyId,
                            company.companyName,
                            company.eMailAddress,
                            company.fiscalCode,
                            company.isContractor,
                            company.isCustomer,
                            company.isEditable,
                            company.privateVersion,
                            company.publicVersion,
                            company.telephoneNumber,
                            company.vatNumber,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateDocumentTypes: function(documentTypes, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella DocumentType: <br>" + JSON.stringify(error)); };
        var insertDocumentTypesQuery = "INSERT OR REPLACE INTO DocumentType (" +
            " documentTypeId," +
            " documentType," +
            " backgroundColor," +
            " textColor," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?)";
        var insertDocumentSubtypesQuery = "INSERT OR REPLACE INTO DocumentSubtype (" +
            " documentSubtypeId," +
            " documentSubtype," +
            " documentTypeId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                documentTypes.forEach(function(documentType) {
                    tx.executeSql(
                        insertDocumentTypesQuery,
                        [
                            documentType.documentTypeId,
                            documentType.documentType,
                            documentType.backgroundColor,
                            documentType.textColor,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                    documentType.subtypes.forEach(function(documentSubtype) {
                        tx.executeSql(
                            insertDocumentSubtypesQuery,
                            [
                                documentSubtype.documentSubtypeId,
                                documentSubtype.documentSubtype,
                                documentType.documentTypeId,
                                currentCompanyId,
                                iso8601.now()
                            ],
                            function(tx, resultSet) {
                            },
                            function(tx, error) {
                                console.error('Query error: ' + error.message);
                            }
                        );
                    });
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateTickets: function(tickets, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Ticket: <br>" + JSON.stringify(error)); };
        var insertTicketQuery = "INSERT OR REPLACE INTO Ticket (" +
            " ticketId, " +
            " customerTicketId, " +
            " siteId, " +
            " ticketType, " +
            " reportedBy, " +
            " reportTimestamp, " +
            " description, " +
            " categoryId, " +
            " subcategoryId, " +
            " state, " +
            " anomalyLevel, " +
            " executionNotes, " +
            " success, " +
            " completionDatetime, " +
            " owner," +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)";
        var insertTicketAssignedCompanyQuery = "INSERT OR REPLACE INTO TicketAssignedCompany (" +
            " ticketAssignationId, " +
            " ticketId, " +
            " assignedCompanyId, " +
            " owner, " +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?,?,?)";
        var updateUsersQuery = "INSERT OR REPLACE INTO User (" +
            " userId," +
            " username," +
            " employeeId," +
            " authToken," +
            " touchIdEnabled,"+
            " lastEdit" +
            ") " +
            "VALUES (" +
            " ?, " +
            " ?, " +
            " (SELECT employeeId FROM User WHERE userId=?), " +
            " (SELECT authToken FROM User WHERE userId=?), " +
            " (SELECT touchIdEnabled FROM User WHERE userId=?), " +
            " ? " +
            " ) ";
        db.localDb.transaction(
            function(tx) {
                tickets.forEach(function(ticket) {
                    tx.executeSql(
                        updateUsersQuery,
                        [
                            ticket.reportedById,
                            ticket.reportedBy,
                            ticket.reportedById,
                            ticket.reportedById,
                            ticket.reportedById,
                            iso8601.now()
                        ],
                        function() {},
                        errorCallback
                    );
                    tx.executeSql(
                        insertTicketQuery,
                        [
                            ticket.ticketId,
                            ticket.customerTicketId,
                            ticket.siteId,
                            ticket.ticketType,
                            ticket.reportedById,
                            ticket.reportTimestamp,
                            ticket.description,
                            ticket.category,
                            ticket.subcategory,
                            ticket.state,
                            ticket.anomalyLevel,
                            ticket.executionNotes,
                            ticket.success,
                            ticket.completionDatetime,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function() {},
                        errorCallback
                    );
                    ticket.assignedCompanies.forEach(function(assignedCompany) {
                        tx.executeSql(
                            insertTicketAssignedCompanyQuery,
                            [
                                assignedCompany.ticketAssignationId,
                                ticket.ticketId,
                                assignedCompany.companyId,
                                currentCompanyId,
                                iso8601.now()
                            ],
                            function() {},
                            errorCallback
                        );
                    });
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateDocumentTags: function(documentTags, currentCompanyId, successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella DocumentTag: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR IGNORE INTO DocumentTag (" +
            " tag," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?)";
        db.localDb.transaction(
            function(tx) {
                documentTags.forEach(function(documentTag) {
                    tx.executeSql(
                        insertQuery,
                        [
                            documentTag.tag,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateFloors: function(floors, currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Floor: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Floor (" +
            " floorId," +
            " floorName," +
            " siteId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                floors.forEach(function(floor) {
                    tx.executeSql(
                        insertQuery,
                        [
                            floor.floorId,
                            floor.floorName,
                            floor.siteId,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateFrequencyTypes: function(frequencyTypes, currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella FrequencyType: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR IGNORE INTO FrequencyType (" +
            " frequencyType," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?)";
        db.localDb.transaction(
            function(tx) {
                frequencyTypes.forEach(function(frequencyType) {
                    tx.executeSql(
                        insertQuery,
                        [
                            frequencyType.frequencyType,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateInterventions: function(interventions, currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Intervention: <br>" + JSON.stringify(error)); };
        var insertInterventionQuery = "INSERT OR REPLACE INTO Intervention (" +
            " interventionId," +
            " parentInterventionId," +
            " followingDayIntervention," +
            " previousDayIntervention," +
            " completed," +
            " contractor," +
            " executorId," +
            " executorNotificationsSent," +
            " fireLogBookNumber," +
            " interventionExecutionNotes," +
            " isDisabled," +
            " startDate," +
            " endDate," +
            " plannedStartDate," +
            " plannedEndDate," +
            " siteId," +
            " validationDatetime," +
            " validationRequested," +
            " validatorUsername," +
            " weather," +
            " weatherDateTime," +
            " windSpeed," +
            " outcomeName," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)";
        var insertInterventionActivityQuery = "INSERT OR REPLACE INTO InterventionActivity (" +
            " interventionActivityId," +
            " interventionId," +
            " activityOnAssetType," +
            " relatedTicket," +
            " relatedContractActivity," +
            " admittableExecutionStart," +
            " admittableExecutionEnd," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?)";
        var insertActivityOnAssetInstance = "INSERT OR REPLACE INTO ActivityOnAssetInstance (" +
            " activityOnAssetInstanceId," +
            " interventionActivity," +
            " assetInstance," +
            " completionDatetime," +
            " success," +
            " notes," +
            " owner," +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?)";
        var insertActivityForSubcategoryQuery = "INSERT OR REPLACE INTO ActivityForSubcategory (" +
            " activityForSubcategoryId," +
            " subcategoryId," +
            " description," +
            " owner," +
            " lastEdit " +
            ") " +
            "VALUES (?,?,?,?,?)";
        var insertEmployeeOnInterventionQuery = "INSERT OR REPLACE INTO EmployeeOnIntervention (" +
            " employeeOnInterventionId," +
            " employeeId," +
            " interventionId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?)";
        var insertEmployeeQuery = "INSERT OR REPLACE INTO Employee (" +
            " employeeId," +
            " name," +
            " surname," +
            " companyId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                interventions.forEach(function(intervention) {
                    tx.executeSql(
                        insertInterventionQuery,
                        [
                            intervention.interventionId,
                            intervention.parentInterventionId,
                            intervention.followingDayIntervention,
                            intervention.previousDayIntervention,
                            intervention.completed ? 1 : 0,
                            intervention.contractor,
                            intervention.executorId,
                            intervention.executorNotificationsSent,
                            intervention.fireLogBookNumber,
                            intervention.interventionExecutionNotes,
                            intervention.isDisabled,
                            intervention.isoStartDate,
                            intervention.isoEndDate,
                            intervention.isoPlannedStartDate,
                            intervention.isoPlannedEndDate,
                            intervention.siteId,
                            intervention.isoValidationDatetime,
                            intervention.validationRequested,
                            intervention.validatorUsername,
                            intervention.weather,
                            intervention.weatherDateTime,
                            intervention.windSpeed,
                            intervention.outcome,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                    if(intervention.activities) {
                        intervention.activities.forEach(function(activity) {
                            tx.executeSql(
                                insertInterventionActivityQuery,
                                [
                                    activity.interventionActivityId,
                                    intervention.interventionId,
                                    activity.activityOnAssetType,
                                    activity.relatedTicketId,
                                    activity.relatedContractActivity,
                                    activity.admittableExecutionStart,
                                    activity.admittableExecutionEnd,
                                    currentCompanyId,
                                    iso8601.now()
                                ],
                                function(tx, resultSet) {
                                },
                                function(tx, error) {
                                    console.error('Query error: ' + error.message);
                                }
                            );
                            if(activity.activityOnAssetType != null) {
                                tx.executeSql(
                                    insertActivityForSubcategoryQuery,
                                    [
                                        activity.activityOnAssetType,
                                        activity.subcategoryId,
                                        activity.activityDescription,
                                        currentCompanyId,
                                        iso8601.now()
                                    ],
                                    function(tx, resultSet) {
                                    },
                                    function(tx, error) {
                                        console.error('Query error: ' + error.message);
                                    }
                                );
                            }
                        });
                    }
                    if(intervention.activitiesOnAssetInstance) {
                        intervention.activitiesOnAssetInstance.forEach(function(activityOnAssetInstance) {
                            tx.executeSql(
                                insertActivityOnAssetInstance,
                                [
                                    activityOnAssetInstance.activityOnAssetInstanceId,
                                    activityOnAssetInstance.interventionActivityId,
                                    activityOnAssetInstance.assetInstanceId,
                                    activityOnAssetInstance.completionDatetime,
                                    activityOnAssetInstance.outcomeOk,
                                    activityOnAssetInstance.notes,
                                    currentCompanyId,
                                    iso8601.now()
                                ],
                                function(tx, resultSet) {
                                },
                                function(tx, error) {
                                    console.error('Query error: ' + error.message);
                                }
                            );
                        });
                    }
                    if(intervention.executionTeam) {
                        intervention.executionTeam.forEach(function(employee) {
                            tx.executeSql(
                                insertEmployeeOnInterventionQuery,
                                [
                                    employee.employeeOnInterventionId,
                                    employee.employeeId,
                                    intervention.interventionId,
                                    currentCompanyId,
                                    iso8601.now()
                                ],
                                function(tx, resultSet) {
                                },
                                function(tx, error) {
                                    console.error('Query error: ' + error.message);
                                }
                            );
                            tx.executeSql(
                                insertEmployeeQuery,
                                [
                                    employee.employeeId,
                                    employee.name,
                                    employee.surname,
                                    intervention.executorId,
                                    currentCompanyId,
                                    iso8601.now()
                                ],
                                function(tx, resultSet) {
                                },
                                function(tx, error) {
                                    console.error('Query error: ' + error.message);
                                }
                            );
                        });
                    }
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateLoggedInUserInfo: function(loggedInUserInfo,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella User: <br>" + JSON.stringify(error)); };
        var updateUserQuery = "UPDATE User " +
            " SET employeeId = ?," +
            " username = ? " +
            " WHERE userId = ?";
        var insertEmployeeQuery = "INSERT OR REPLACE INTO Employee (" +
            " employeeId," +
            " name," +
            " surname," +
            " companyId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                tx.executeSql(
                    updateUserQuery,
                    [
                        loggedInUserInfo.employeeId,
                        loggedInUserInfo.username,
                        loggedInUserInfo.userId
                    ],
                    function(tx, resultSet) {
                    },
                    function(tx, error) {
                        console.error('Query error: ' + error.message);
                    }
                );
                tx.executeSql(
                    insertEmployeeQuery,
                            [
                        loggedInUserInfo.employeeId,
                        loggedInUserInfo.name,
                        loggedInUserInfo.surname,
                        loggedInUserInfo.company,
                        currentCompanyId,
                        iso8601.now()
                    ],
                    function(tx, resultSet) {
                    },
                    function(tx, error) {
                        console.error('Query error: ' + error.message);
                    }
                );
            },
            errorCallback,
            successCallback
        );
    },
    updateNotifications: function(notifications,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Notification: <br>" + JSON.stringify(error) + " " + error.message); };
        var insertNotificationQuery = "INSERT OR REPLACE INTO Notification (" +
            " notificationId," +
            " companyId," +
            " isRead," +
            " isSeen," +
            " timestamp," +
            " link," +
            " description," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                notifications.forEach(function(notification) {
                    tx.executeSql(
                        insertNotificationQuery,
                        [
                            notification.notificationId,
                            notification.companyId,
                            notification.isRead,
                            notification.isSeen,
                            notification.isoTimestamp,
                            notification.link,
                            notification.notificationDescription,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateOutcomes: function(outcomes,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Outcome: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Outcome (" +
            " outcomeId," +
            " name," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                outcomes.forEach(function(outcome) {
                    tx.executeSql(
                        insertQuery,
                        [
                            outcome.outcomeId,
                            outcome.name,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateAssetInstances: function(assetInstances,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella AssetInstance: <br>" + JSON.stringify(error)); };
        var insertAssetInstanceQuery = "INSERT OR REPLACE INTO AssetInstance (" +
            " assetInstanceId," +
            " brand, " +
            " certification," +
            " constructionYear, " +
            " creationCausal, " +
            " floor, " +
            " homologation, " +
            " installationDate, " +
            " internalCode, " +
            " model, " +
            " positionHint, " +
            " productionNumber, " +
            " registrationNumber, " +
            " revisionExpiration, " +
            " siteId, " +
            " size, " +
            " testExpiration, " +
            " type, " +
            " owner, " +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)";
        var insertAssetSubcategoryQuery = "INSERT OR REPLACE INTO AssetSubcategory (" +
            " assetInstanceId," +
            " subcategoryId," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                assetInstances.forEach(function(assetInstance) {
                    tx.executeSql(
                        insertAssetInstanceQuery,
                        [
                            assetInstance.assetInstanceId,
                            assetInstance.brand,
                            assetInstance.certification,
                            assetInstance.constructionYear,
                            assetInstance.creationCausal,
                            assetInstance.floor,
                            assetInstance.homologation,
                            assetInstance.isoInstallationDate,
                            assetInstance.internalCode,
                            assetInstance.model,
                            assetInstance.positionHint,
                            assetInstance.productionNumber,
                            assetInstance.registrationNumber,
                            assetInstance.revisionExpiration,
                            assetInstance.site,
                            assetInstance.size,
                            assetInstance.testExpiration,
                            assetInstance.type,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                    assetInstance.subcategories.forEach(function(assetSubcategory){
                        tx.executeSql(
                            insertAssetSubcategoryQuery,
                            [
                                assetInstance.assetInstanceId,
                                assetSubcategory.subcategoryId,
                                currentCompanyId,
                                iso8601.now()
                            ],
                            function(tx, resultSet) {
                            },
                            function(tx, error) {
                                console.error('Query error: ' + error.message);
                            }
                        );
                    });
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateQrCodes: function(qrCodes,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella QrCode: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO QrCode (" +
            " qrCode," +
            " creation," +
            " relatedAsset," +
            " relatedIntervention," +
            " relatedTicket," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?)";
        console.log('Started updating QrCode');
        db.localDb.transaction(
            function(tx) {
                qrCodes.forEach(function(qrCode) {
                    tx.executeSql(
                        insertQuery,
                        [
                            qrCode.qrCode,
                            qrCode.isoCreation,
                            qrCode.relatedAsset,
                            qrCode.relatedIntervention,
                            qrCode.relatedTicket,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateSites: function(sites,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella Site: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO Site (" +
            " siteId," +
            " buildingId," +
            " city," +
            " district," +
            " isActive," +
            " isCommon," +
            " latitude," +
            " longitude," +
            " letter," +
            " name," +
            " ownedBy," +
            " siteType," +
            " street," +
            " streetNumber," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                sites.forEach(function(site) {
                    tx.executeSql(
                        insertQuery,
                        [
                            site.siteId,
                            site.buildingId,
                            site.city,
                            site.district,
                            site.isActive,
                            site.isCommon,
                            site.latitude,
                            site.longitude,
                            site.letter,
                            site.name,
                            site.ownedBy,
                            site.siteType,
                            site.street,
                            site.streetNumber,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateSiteTypes: function(siteTypes,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella SiteType: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO SiteType (" +
            " siteType," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?)";
        db.localDb.transaction(
            function(tx) {
                siteTypes.forEach(function(siteType) {
                    tx.executeSql(
                        insertQuery,
                        [
                            siteType,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    updateUnitsOfMeasure: function(unitsOfMeasure,currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella UnitOfMeasure: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO UnitOfMeasure (" +
            " unitName," +
            " description," +
            " interventionExecution," +
            " accountingSection," +
            " owner," +
            " lastEdit" +
            ") " +
            "VALUES (?,?,?,?,?,?)";
        db.localDb.transaction(
            function(tx) {
                unitsOfMeasure.forEach(function(unitOfMeasure) {
                    tx.executeSql(
                        insertQuery,
                        [
                            unitOfMeasure.unitName,
                            unitOfMeasure.description,
                            unitOfMeasure.interventionExecution,
                            unitOfMeasure.accountingSection,
                            currentCompanyId,
                            iso8601.now()
                        ],
                        function(tx, resultSet) {
                        },
                        function(tx, error) {
                            console.error('Query error: ' + error.message);
                        }
                    );
                });
            },
            errorCallback,
            successCallback
        );
    },
    logDataSync: function(currentCompanyId){
        var errorCallback = function(error){ console.error("Errore durante l'aggiornamento della tabella DataSync: <br>" + JSON.stringify(error)); };
        var insertQuery = "INSERT OR REPLACE INTO DataSync (" +
            " lastFullSync," +
            " companyId " +
            ") " +
            "VALUES (?,?)";
        db.localDb.executeSql(
            insertQuery,
            [
                iso8601.now(),
                currentCompanyId
            ],
            function(){console.log("Sincronizzazione registrata: "+iso8601.now()+" per l'azienda:"+currentCompanyId);},
            errorCallback
        );
    },
    getAnomalyLevels: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella AnomalyLevel: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " anomalyLevel," +
            " lastEdit" +
            " FROM AnomalyLevel  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getBuildingGroups: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella BuildingGroup: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " buildingGroupId," +
            " associatedCompanies," +
            " name," +
            " owner," +
            " lastEdit" +
            " FROM BuildingGroup  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getBuildings: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Building: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " buildingId," +
            " buildingGroupId," +
            " name," +
            " associatedCompanies," +
            " owner," +
            " lastEdit" +
            " FROM Building  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getCadastralCategories: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella CadastralCategory: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " categoryName," +
            " description," +
            " owner," +
            " lastEdit" +
            " FROM CadastralCategory  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getCategoryGroups: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella CategoryGroup: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " categoryGroupId," +
            " background," +
            " icon," +
            " owner," +
            " lastEdit" +
            " FROM CategoryGroup  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getCategories: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Category: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " categoryId," +
            " categoryGroupId," +
            " background," +
            " name," +
            " icon," +
            " owner," +
            " lastEdit" +
            " FROM Category  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getSubcategories: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Subcategory: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " subcategoryId, " +
            " Subcategory.categoryId, " +
            " categoryGroupId, "+
            " Category.background, " +
            " Subcategory.name, " +
            " Subcategory.icon, " +
            " orderKey " +
            " FROM Subcategory  " +
            " INNER JOIN Category " +
            " ON Subcategory.categoryId = Category.categoryId" +
            " WHERE Category.owner = ? " +
            " AND Subcategory.owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId,currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getSubcategoryGroups: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella SubcategoryGroup: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " subcategoryGroupId, " +
            " name" +
            " FROM SubcategoryGroup" +
            " WHERE SubcategoryGroup.owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getCompanies: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Company: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " companyId," +
            " companyName," +
            " eMailAddress," +
            " fiscalCode," +
            " isContractor," +
            " isCustomer," +
            " isEditable," +
            " privateVersion," +
            " publicVersion," +
            " telephoneNumber," +
            " vatNumber" +
            " FROM Company  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getDocumentTypes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella DocumentType: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " documentTypeId," +
            " documentType," +
            " backgroundColor," +
            " textColor," +
            " owner," +
            " lastEdit" +
            " FROM DocumentType  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getDocumentTags: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella DocumentTag: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " tag," +
            " owner," +
            " lastEdit" +
            " FROM DocumentTag  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getFloors: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Floor: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " floorId," +
            " floorName," +
            " siteId," +
            " owner," +
            " lastEdit" +
            " FROM Floor  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getFrequencyTypes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella FrequencyType: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " frequencyType," +
            " owner," +
            " lastEdit" +
            " FROM FrequencyType  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getInterventions: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Intervention: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " Intervention.interventionId," +
            " Intervention.parentInterventionId," +
            " Intervention.followingDayIntervention," +
            " Intervention.previousDayIntervention," +
            " Intervention.completed," +
            " contractor," +
            " executorId," +
            " executorNotificationsSent," +
            " fireLogBookNumber," +
            " interventionExecutionNotes," +
            " isDisabled," +
            " startDate," +
            " endDate," +
            " plannedStartDate," +
            " plannedEndDate," +
            " outcomeName, " +
            " Intervention.siteId," +
            " validationDatetime," +
            " validationRequested," +
            " validatorUsername," +
            " weather," +
            " weatherDateTime," +
            " windSpeed, " +
            " Site.letter AS siteLetter," +
            " Site.city AS siteCity," +
            " Site.district AS siteDistrict," +
            " Site.name AS siteName," +
            " Site.street AS siteStreet," +
            " Site.streetNumber AS siteStreetNumber," +
            " Company.companyName AS executorName," +
            " InterventionActivity.interventionActivityId," +
            " InterventionActivity.activityOnAssetType," +
            " relatedTicket," +
            " relatedContractActivity," +
            " Ticket.ticketType AS relatedTicketType," +
            " InterventionActivity.admittableExecutionStart," +
            " InterventionActivity.admittableExecutionEnd," +
            " IFNULL(ActivityForSubcategory.description,Ticket.description) AS activityDescription, " +
            " ActivityForSubcategory.subcategoryId AS subcategoryId," +
            " Subcategory.icon AS subcategoryIcon," +
            " Subcategory.name AS subcategory, " +
            " Category.name AS category," +
            " Category.categoryId AS categoryId," +
            " Category.icon AS categoryIcon," +
            " Category.background AS categoryIconBackground," +
            " Category.categoryGroupId AS categoryGroupId " +
            " FROM Intervention  " +
            " LEFT JOIN Site " +
            " ON Intervention.siteId = Site.siteId" +
            " LEFT JOIN InterventionActivity " +
            " ON InterventionActivity.interventionId = Intervention.interventionId " +
            " LEFT JOIN ActivityForSubcategory " +
            " ON ActivityForSubcategory.activityForSubcategoryId=InterventionActivity.activityOnAssetType " +
            " LEFT JOIN Ticket " +
            " ON Ticket.ticketId = InterventionActivity.relatedTicket" +
            " LEFT JOIN Subcategory " +
            " ON ( " +
            "   ( ActivityForSubcategory.subcategoryId IS NOT NULL AND Subcategory.subcategoryId = ActivityForSubcategory.subcategoryId ) " +
            "   OR " +
            "   ( InterventionActivity.relatedTicket IS NOT NULL AND Subcategory.subcategoryId = Ticket.subcategoryId ) " +
            " ) " +
            " LEFT JOIN Category " +
            " ON ( " +
            "   ( Subcategory.categoryId IS NOT NULL AND Category.categoryId = Subcategory.categoryId )" +
            "   OR" +
            "   ( InterventionActivity.relatedTicket IS NOT NULL AND Subcategory.categoryId IS NULL AND Category.categoryId = Ticket.categoryId ) " +
            " ) " +
            " LEFT JOIN Company " +
            " ON Intervention.executorId = Company.companyId" +
            " WHERE Intervention.owner = ? " +
            " AND ( Company.owner = ? OR Company.owner IS NULL ) " +
            " AND ( InterventionActivity.owner = ? OR InterventionActivity.owner IS NULL ) " +
            " AND ( Site.owner = ? OR Site.owner IS NULL)" +
            " AND ( Category.owner = ? OR Category.owner IS NULL)" +
            " AND ( Subcategory.owner = ? OR Subcategory.owner IS NULL)" +
            " AND ( Ticket.owner = ? OR Ticket.owner IS NULL)" +
            " AND ( ActivityForSubcategory.owner = ? OR ActivityForSubcategory.owner IS NULL)";
        db.localDb.executeSql(
            selectQuery,
            [
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId
            ],
            successCallback,
            errorCallback
        );
    },
    getAssetsOnIntervention: function(currentCompanyId,interventionId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Intervention: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " InterventionActivity.interventionActivityId, " +
            " AssetInstance.assetInstanceId, " +
            " AssetInstance.model, " +
            " AssetInstance.brand, " +
            " AssetInstance.internalCode, " +
            " AssetInstance.registrationNumber, " +
            " AssetInstance.positionHint, " +
            " Category.icon AS categoryIcon," +
            " Category.background AS categoryBackground," +
            " Subcategory.icon AS subcategoryIcon, " +
            " Subcategory.subcategoryId, " +
            " Subcategory.name AS subcategoryName, " +
            " (ActivityOnAssetInstance.completionDatetime IS NOT NULL) AS activityIsCompleted, " +
            " QrCode.qrCode, " +
            " ActivityOnAssetInstance.notes AS activityOnAssetInstanceNotes, " +
            " ActivityOnAssetInstance.activityOnAssetInstanceId, " +
            " ActivityOnAssetInstance.success AS activityOnAssetInstanceSuccess, " +
            " Ticket.ticketId," +
            " Ticket.customerTicketId, " +
            " Ticket.description AS ticketDescription, " +
            " Ticket.executionNotes AS ticketExecutionNotes," +
            " (Ticket.completionDatetime IS NOT NULL) AS ticketIsCompleted," +
            " Ticket.success AS ticketSuccess," +
            " Ticket.categoryId AS ticketCategoryId," +
            " Ticket.subcategoryId AS ticketSubcategoryId," +
            " Ticket.ticketType AS ticketType " +
            " FROM Intervention  " +
            " LEFT JOIN Site " +
            " ON Intervention.siteId = Site.siteId" +
            " LEFT JOIN InterventionActivity " +
            " ON InterventionActivity.interventionId = Intervention.interventionId " +
            " LEFT JOIN Ticket " +
            " ON InterventionActivity.relatedTicket = Ticket.ticketId " +
            " LEFT JOIN ActivityForSubcategory " +
            " ON ActivityForSubcategory.activityForSubcategoryId=InterventionActivity.activityOnAssetType " +
            " LEFT JOIN ActivityOnAssetInstance " +
            " ON ActivityOnAssetInstance.interventionActivity=InterventionActivity.interventionActivityId" +
            " LEFT JOIN AssetInstance " +
            " ON AssetInstance.assetInstanceId = ActivityOnAssetInstance.assetInstance " +
            " LEFT JOIN AssetSubcategory " +
            " ON AssetSubcategory.assetInstanceId = AssetInstance.assetInstanceId " +
            " LEFT JOIN Subcategory " +
            " ON Subcategory.subcategoryId = AssetSubcategory.subcategoryId " +
            " LEFT JOIN Category " +
            " ON Category.categoryId = Subcategory.categoryId " +
            " LEFT JOIN Company " +
            " ON Intervention.executorId = Company.companyId" +
            " LEFT JOIN QrCode " +
            " ON QrCode.relatedAsset = AssetInstance.assetInstanceId " +
            " WHERE Intervention.owner = ? " +
            " AND Intervention.interventionId=? " +
            " AND ( Site.owner = ? OR Site.owner IS NULL)" +
            " AND ( InterventionActivity.owner = ? OR InterventionActivity.owner IS NULL ) " +
            " AND ( Ticket.owner = ? OR Ticket.owner IS NULL ) " +
            " AND ( ActivityForSubcategory.owner = ? OR ActivityForSubcategory.owner IS NULL ) " +
            " AND ( ActivityOnAssetInstance.owner = ? OR ActivityOnAssetInstance.owner IS NULL ) " +
            " AND ( AssetSubcategory.owner = ? OR AssetSubcategory.owner IS NULL)" +
            " AND ( Category.owner = ? OR Category.owner IS NULL)" +
            " AND ( Subcategory.owner = ? OR Subcategory.owner IS NULL)" +
            " AND ( QrCode.owner = ? OR QrCode.owner IS NULL)" +
            " AND ( Company.owner = ? OR Company.owner IS NULL ) ";
        db.localDb.executeSql(
            selectQuery,
            [
                currentCompanyId,
                interventionId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId,
                currentCompanyId
            ],
            successCallback,
            errorCallback
        );
    },
    getLoggedInUserInfo: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella User: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " userId, " +
            " username, " +
            " User.employeeId AS employeeId, " +
            " authToken, " +
            " touchIdEnabled, "+
            " name, " +
            " surname, " +
            " Employee.companyId, " +
            " Company.companyName AS companyName " +
            " FROM User  " +
            " JOIN Employee " +
            " ON Employee.employeeId = User.employeeId " +
            " LEFT JOIN Company " +
            " ON Employee.companyId = Company.companyId " +
            " WHERE Employee.owner = ? OR Employee.owner IS NULL" +
            " AND Company.owner = ? OR Company.owner IS NULL " +
            " AND userId IN (SELECT utilityData FROM Utilities WHERE utilityKey='lastUserId') ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId,currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getOutcomes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Outcome: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " outcomeId, " +
            " name " +
            " FROM Outcome  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getAssetInstances: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella AssetInstance: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " AssetInstance.assetInstanceId," +
            " brand, " +
            " certification," +
            " constructionYear, " +
            " creationCausal, " +
            " floor, " +
            " homologation, " +
            " installationDate, " +
            " internalCode, " +
            " model, " +
            " positionHint, " +
            " productionNumber, " +
            " registrationNumber, " +
            " revisionExpiration, " +
            " Site.siteId, " +
            " size, " +
            " testExpiration, " +
            " type, " +
            " AssetSubcategory.subcategoryId AS subcategoryId, " +
            " Subcategory.subcategoryId, " +
            " Subcategory.categoryId, " +
            " Category.categoryGroupId, "+
            " Category.icon AS categoryIcon, " +
            " Category.name AS categoryName, " +
            " Category.background AS background, " +
            " Subcategory.name AS subcategoryName, " +
            " Subcategory.icon AS subcategoryIcon, " +
            " Subcategory.orderKey," +
            " Site.letter AS siteLetter, " +
            " Site.name AS siteName, " +
            " QrCode.qrCode AS qrCode "+
            " FROM AssetInstance " +
            " LEFT JOIN AssetSubcategory " +
            " ON AssetSubcategory.assetInstanceId = AssetInstance.assetInstanceId " +
            " LEFT JOIN Subcategory " +
            " ON Subcategory.subcategoryId = AssetSubcategory.subcategoryId " +
            " LEFT JOIN Category " +
            " ON Subcategory.categoryId = Category.categoryId " +
            " LEFT JOIN Site " +
            " ON Site.siteId = AssetInstance.siteId " +
            " LEFT JOIN QrCode " +
            " ON QrCode.relatedAsset = AssetInstance.assetInstanceId " +
            " WHERE AssetInstance.owner = ? " +
            " AND (Subcategory.owner = ? OR Subcategory.owner IS NULL)" +
            " AND (Category.owner = ? OR Category.owner IS NULL)" +
            " AND (Site.owner = ? OR Site.owner IS NULL)" +
            " AND (QrCode.owner = ? OR QrCode.owner IS NULL)" +
            " AND (AssetSubcategory.owner = ? OR AssetSubcategory.owner IS NULL)";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId,currentCompanyId,currentCompanyId,currentCompanyId,currentCompanyId,currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getQrCodes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella QrCode: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " qrCode, " +
            " creation, " +
            " relatedAsset, " +
            " relatedIntervention, " +
            " relatedTicket " +
            " FROM QrCode  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getSites: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Site: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " siteId," +
            " Site.buildingId," +
            " city," +
            " district," +
            " isActive," +
            " isCommon," +
            " latitude," +
            " longitude," +
            " letter," +
            " Site.name AS siteName," +
            " Site.ownedBy," +
            " siteType," +
            " street," +
            " streetNumber," +
            " Building.buildingGroupId AS buildingGroupId" +
            " FROM Site  " +
            " INNER JOIN Building " +
            " ON Building.buildingId = Site.buildingId " +
            " WHERE Building.owner = ? " +
            " AND Site.owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId,currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getSiteTypes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella SiteType: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " siteType," +
            " lastEdit" +
            " FROM SiteType  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getUnitsOfMeasure: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella UnitOfMeasure: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " unitName," +
            " description," +
            " interventionExecution," +
            " accountingSection" +
            " FROM UnitOfMeasure  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getUserPermissions: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella UserPermission: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " companyId," +
            " permissionCode," +
            " permissionLevel" +
            " FROM UserPermission  " +
            " WHERE userId IN (SELECT utilityData FROM Utilities WHERE utilityKey='lastUserId') " +
            " AND companyId = ?";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getTicketTypes: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella TicketType: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " ticketType," +
            " lastEdit" +
            " FROM TicketType  " +
            " WHERE owner = ? ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId],
            successCallback,
            errorCallback
        );
    },
    getGlobalUserPermissions: function(successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella UserPermission: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " UP.companyId," +
            " Company.companyName," +
            " UP.permissionCode," +
            " UP.permissionLevel" +
            " FROM " +
            " ( SELECT " +
            "     companyId," +
            "     permissionCode," +
            "     permissionLevel " +
            "   FROM UserPermission " +
            "   WHERE userId IN (" +
            "      SELECT utilityData " +
            "      FROM Utilities " +
            "      WHERE utilityKey='lastUserId'" +
            "    )" +
            " )AS UP " +
            " JOIN Company " +
            " ON Company.companyId = UP.companyId " +
            "  ";
        console.log(selectQuery);
        console.log(db.localDb.executeSql(
            selectQuery,
            [],
            successCallback,
            errorCallback
        ));
    },
    getDataSync: function(companyToCheck,successCallback){
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella DataSync: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " lastFullSync " +
            " FROM DataSync  " +
            " WHERE companyId = ? ";
        db.localDb.executeSql(
            selectQuery,
            [companyToCheck],
            successCallback,
            errorCallback
        );
    },
    getTickets: function(currentCompanyId,successCallback) {
        var errorCallback = function(error){ console.error("Errore durante la lettura della tabella Ticket: <br>" + JSON.stringify(error)); };
        var selectQuery = "SELECT " +
            " Ticket.ticketId, " +
            " customerTicketId, " +
            " Ticket.siteId, " +
            " ticketType, " +
            " reportedBy, " +
            " User.username AS reportedByUsername, " +
            " reportTimestamp, " +
            " description, " +
            " categoryId, " +
            " subcategoryId, " +
            " state, " +
            " Ticket.anomalyLevel, " +
            " executionNotes, " +
            " success, " +
            " completionDatetime," +
            " Site.letter AS siteLetter, " +
            " Site.name AS siteName," +
            " AnomalyLevel.slaHours," +
            " TicketAssignedCompany.assignedCompanyId," +
            " TicketAssignedCompany.ticketAssignationId AS ticketAssignationId," +
            " Company.companyName " +
            " FROM Ticket " +
            " LEFT JOIN Site " +
            " ON Site.siteId = Ticket.siteId " +
            " LEFT JOIN AnomalyLevel " +
            " ON AnomalyLevel.anomalyLevel = Ticket.anomalyLevel " +
            " LEFT JOIN TicketAssignedCompany" +
            " ON TicketAssignedCompany.ticketId = Ticket.ticketId " +
            " LEFT JOIN Company " +
            " ON Company.companyId = TicketAssignedCompany.assignedCompanyId " +
            " LEFT JOIN User " +
            " ON User.userId = Ticket.reportedBy" +
            " WHERE Ticket.owner = ? " +
            " AND Site.owner = ? " +
            " AND AnomalyLevel.owner = ? " +
            " AND TicketAssignedCompany.owner = ?" +
            " ORDER BY Ticket.ticketId DESC ";
        db.localDb.executeSql(
            selectQuery,
            [currentCompanyId,currentCompanyId,currentCompanyId,currentCompanyId],
            successCallback,
            errorCallback
        );
    }
};

/******** forms.js start **********/

function submitForm(callerButton, skipGhostClickValidation) {
    if((!skipGhostClickValidation)  && (!ghostClickController.isOk()) ){
        return false;
    }
    var submittedForm = dom.findParentTag(callerButton,"FORM");
    if(submittedForm === null) {
        console.error("submitForm: caller button is not located into a form!");
        return false;
    }
    resetFormValidationMessages();
    if(formIsValid(submittedForm)) {
        switch(submittedForm.name) {
            case "login-form":
                ajaxLogin();
                break;
            case "edit-company-information-form":
                ajaxEditCompanyInformation();
                break;
            case "edit-company-documents-form":
                ajaxEditCompanyDocuments();
                break;
            case "add-certification-for-user-form":
                ajaxCreateCertificationForUser();
                break;
            case "submit-ticket-form":
                ajaxSubmitTicket();
                break;
            case "submit-anomaly-form":
                ajaxSubmitAnomaly();
                break;
            case "next-create-contract-page-1-form":
                nextContractFormPage("form-page-1");
                break;
            case "next-create-contract-page-2-form":
                nextContractFormPage("form-page-2");
                break;
            case "next-create-contract-page-3-form":
                nextContractFormPage("form-page-3");
                break;
            case "next-create-contract-page-4-form":
                nextContractFormPage("form-page-4");
                break;
            case "next-create-contract-page-5-form":
                ajaxCreateContract();
                break;
            case "create-common-unit-form":
                ajaxCreateCommonUnit();
                break;
            case "create-property-unit-form":
                ajaxCreatePropertyUnit();
                break;
            case "create-building-form":
                ajaxCreateBuilding();
                break;
            case "create-buildings-group-form":
                ajaxCreateBuildingsGroup();
                break;
            case "add-company-form":
                ajaxCreateCompanyOrPerson();
                break;
            case "search-company-by-vat-number-form":
                ajaxGetCompanyByVatNumber();
                break;
            case "register-installment-payment-form":
                ajaxRegisterInstallmentPayment();
                break;
            case "register-invoice-payment-form":
                ajaxRegisterInvoicePayment();
                break;
            case "create-invoice-form":
                ajaxCreateInvoice();
                break;
            case "add-repartition-table-form":
                continueRepartitonTableCreation();
                break;
            case "generate-installments-form":
                ajaxGenerateInstallments();
                break;
            case "add-buildings-final-rates-form":
                continueAddingBuildingsFinalRates();
                break;
            case "add-sites-final-rates-form":
                continueAddingSitesFinalRates();
                break;
            case "add-expenditure-item-form":
                ajaxCreateExpenditureItem();
                break;
            case "add-asset-form":
                ajaxCreateAsset();
                break;
            case "create-accounting-year-form":
                ajaxCreateAccountingYear();
                break;
            case "end-intervention-form":
                registerCloseInterventionData(1);
                break;
            case "team-management-form":
                ajaxSubmitInterventionTeamModifications();
                break;
            case "upload-assets-step-1-form":
                uploadAsset();
                break;
            case "upload-document-step-1-form":
                uploadDocument();
                break;
            case "confirm-asset-instances-deletion-form":
                ajaxConfirmAssetInstancesDeletion();
                break;
            case "edit-asset-instance-form":
                ajaxEditAssetInstance();
                break;
            case "add-law-form":
                ajaxCreateLaw();
                break;
            case "add-activity-for-subcategory-form":
                ajaxCreateActivityForSubcategory();
                break;
            case "edit-activity-for-subcategory-form":
                ajaxSaveEditActivityForSubcategory();
                break;
            case "move-intervention-form":
                ajaxConfirmMoveIntervention();
                break;
            case "assign-ticket-form":
                ajaxAssignCompanyToTicket();
                break;
            case "add-category-group-form":
                ajaxCreateCategoryGroup();
                break;
            case "add-category-form":
                ajaxCreateCategory();
                break;
            case "add-subcategory-form":
                ajaxCreateSubcategory();
                break;
            case "edit-category-group-form":
                ajaxEditCategoryGroup();
                break;
            case "edit-category-form":
                ajaxEditCategory();
                break;
            case "edit-subcategory-form":
                ajaxEditSubcategory();
                break;
            case "choose-new-company-address-name-form":
                addCompanyAddressStep2();
                break;
            case "add-site-to-building-map-form":
                registerSiteDrawingToBuildingMap();
                break;
            case "add-activities-to-intervention-form":
                ajaxAddActivitiesToIntervention();
                break;
            case "delete-intervention-activity-form":
                ajaxDeleteInterventionActivity();
                break;
            case "create-intervention-step-1-form":
                closePopup();
                createInterventionPopup.ajaxGetAssetsCountForSubcategoriesBySite(document.getElementById('create-intervention-site').value);
                break;
            case "create-intervention-step-2-form":
                ajaxCreateActivitySelectorFromSubcategoriesGraphicalMultiSelector("create-intervention-activity-selector-container", "add-intervention");
                break;
            case "create-intervention-step-3-form":
                ajaxCreateIntervention();
                break;
            case "merge-interventions-form":
                ajaxMergeInterventions();
                break;
            case "split-interventions-form":
                ajaxSplitInterventions();
                break;
            case "delete-intervention-form":
                ajaxDeleteIntervention();
                break;
            case "delete-document-form":
                ajaxFinalizeDocumentDeletion();
                break;
            case "add-user-form":
                ajaxCreateUser();
                break;
            case "add-asset-for-qr-code-form":
                ajaxCreateAssetForQrCode();
                break;
            case "search-asset-for-qr-code-form":
                ajaxLinkSearchedAssetToQrCode();
                break;
            case "edit-buildings-group-form":
                ajaxEditBuildingsGroup();
                break;
            case "edit-building-form":
                ajaxEditBuilding();
                break;
            case "edit-site-form":
                ajaxEditSite();
                break;
            case "edit-document-form":
                ajaxEditDocument();
                break;
            case "export-contract-spec-form":
                downloadContractSpec();
                break;
            case "intervention-custom-notification-form":
                sendInterventionCustomMail();
                break;
            case "share-public-documents-links-by-mail":
                sharePublicDocumentsLinksByMail();
                break;
            case "share-private-documents-links-by-mail":
                sharePrivateDocumentsLinksByMail();
                break;
            case "share-public-document-link-by-mail":
                sharePublicDocumentLinkByMail();
                break;
            case "share-private-document-link-by-mail":
                sharePrivateDocumentLinkByMail();
                break;
            case "edit-intervention-order-number":
                ajaxEditInterventionOrderNumbers();
                break;
            case "edit-intervention-fire-log-book-number":
                ajaxEditInterventionFireLogBookNumber();
                break;
            case "save-permissions-edit-form":
                ajaxSavePermissionsEdit();
                break;
            case "add-company-to-user-permissions-form":
                showNewCompanyInUserPermissions();
                break;
            case "login-ask-assistance-form":
                loginAskAssistanceFormSubmit();
                break;
            case "create-permission-form":
                ajaxCreatePermission();
                break;
            case "comment-activity-on-asset-instance-execution-form":
                registerCommentOnActivityOnAssetInstanceExecution();
                break;
            case "edit-user-data-form":
                ajaxEditUserData();
                break;
            case "change-password-form":
                ajaxChangePassword();
                break;
            case "confirm-unlink-company-from-license-form":
                ajaxUnlinkCompanyFromLicense(
                    parseInt(document.getElementById("confirm-unlink-company-from-license-company-id").value)
                );
                break;
            case "link-existing-company-to-license-form":
                ajaxLinkExistingCompanyToLicense(
                    parseInt(document.getElementById("link-existing-company-to-license-company-id").value),
                    parseInt(document.getElementById("link-existing-company-to-license-registered-by").value)
                );
                break;
            case "create-company-linked-to-license-form":
                ajaxCreateCompanyLinkedToLicense(
                    parseInt(document.getElementById("create-company-linked-to-license-company-id").value),
                    parseInt(document.getElementById("create-company-linked-to-license-registered-by").value)
                );
                break;
            case "delete-user-form":
                ajaxDeleteUser(
                    document.getElementById("user-to-be-deleted-id").value
                );
                break;
            case "block-user-form":
                ajaxBlockUser(
                    document.getElementById("user-to-be-blocked-id").value
                );
                break;
            case "unblock-user-form":
                ajaxUnblockUser(
                    document.getElementById("user-to-be-unblocked-id").value
                );
                break;
            case "invalidate-user-password-form":
                ajaxInvalidateUserPassword(
                    document.getElementById("user-to-be-invalidated-id").value
                );
                break;
            case "add-asset-to-intervention-form":
                addAssetToInterventionPopup.submit();
                break;
            case "select-executor-form":
                createContractPopup.addChosenRelatedExecutor();
                break;
            case "should-use-qr-form":
                if(getPseudoRadioValueByClass("manual-asset-select-reason")===null) {
                    errorToast("Indicare il motivo per il quale si è selezionato l'asset manualmente");
                }
                else {
                    closePopup();
                    showActivitiesOnAssetInstance(
                        document.getElementById("should-use-qr-selected-asset-instance-key").value,
                        true,
                        false
                    );
                    ajaxReportNotUsedQr();
                }
                break;
            case "add-executor-closing-intervention-form":
                closePopup();
                interventionManager.addEmployeeClosingIntervention(
                    document.getElementById("add-executor-closing-intervention-employee").value
                );
                break;
            case "edit-anomaly-form":
                closePopup();
                ajaxEditAnomaly();
                break;
            case "confirm-intervention-refuse-form":
                closePopup();
                ajaxRefuseInterventionRequest(
                    parseInt(document.getElementById("confirm-refuse-intervention-intervention-id").value),
                    parseInt(document.getElementById("confirm-refuse-intervention-ticket-id").value)
                );
                break;
            case "confirm-intervention-cancel-form":
                closePopup();
                ajaxCancelInterventionRequest(
                    parseInt(document.getElementById("confirm-cancel-intervention-intervention-id").value),
                    parseInt(document.getElementById("confirm-cancel-intervention-ticket-id").value),
                    document.getElementById("cancel-intervention-request-notes").value
                );
                break;
            case "choose-new-company-certification-name-form":
                addCompanyCertificationStep2();
                break;
            case "disable-activity-for-subcategory-form":
                ajaxDisableActivityForSubcategory(
                    parseInt(document.getElementById("disable-activity-id").value),
                    iso8601.fromEuropeanDate(document.getElementById("disable-activity-for-subcategory-date").value)
                );
                break;
            case "warning-form":
                closePopup();
                break;
            case "add-subcategory-additional-field-form":
                ajaxCreateSubcategoryAdditionalField(
                    parseInt(document.getElementById("add-subcategory-additional-field-subcategory").value),
                    document.getElementById("add-subcategory-additional-field-name").value,
                    document.getElementById("add-subcategory-additional-field-type").value,
                    document.getElementById("add-subcategory-additional-field-keep-history").getAttribute("data-checked")==="1",
                    document.getElementById("add-subcategory-additional-field-keep-ge-precedent").getAttribute("data-checked")==="1",
                    document.getElementById("add-subcategory-additional-field-keep-ge-zero").getAttribute("data-checked")==="1"
                );
                break;
            case "send-push-notifications-form":
                ajaxSendPushNotifications(
                    document.getElementById("send-push-notifications-title").value,
                    document.getElementById("send-push-notifications-body").value,
                    document.getElementById("send-push-notifications-notification").value,
                    parseInt(document.getElementById("send-push-notifications-recipient").value)
                );
                break;
            case "export-asset-field-for-subcategory-report-form":
                exportAssetFieldForSubcategoryReportPopup.submit(
                    document.getElementById("export-asset-field-for-subcategory-report-field-type").value,
                    document.getElementById("export-asset-field-for-subcategory-report-date-from").value,
                    document.getElementById("export-asset-field-for-subcategory-report-date-to").value
                );
                break;
            case "reset-password-form":
                ajaxResetPassword();
                break;
            case "asset-replacement-form":
                ajaxReplaceAsset();
                break;
            case "upload-estimate-for-ticket-form":
                chatPopup.uploadEstimation();
                break;
            case "upload-approved-estimate-for-ticket-form":
                chatPopup.uploadApprovedEstimation();
                break;
            case "upload-order-form-for-ticket-form":
                chatPopup.uploadOrderForm();
                break;
            case "set-excluded-subcategory-form":
                ajaxSetExcludedSubcategory();
                break;
            case "login-register-form":
                ajaxSelfRegisterUser();
                break;
            default:
                console.error("Trying to submit an unrecognized form ("+submittedForm.name+")");
                break;
        }
    }
    else {
        //If the form is not valid I have to refresh the scroll that contains the form,
        // since its content was updated with error messages
        scroll.refreshAll();
    }
    return false;
}

function markInputAsNotValid(input,errorDescription) {
    dom.addClass(input,"input-validation-failed");
    input.setAttribute("data-validation-message-id",dataValidationMessageIdsCounter);
    var errorMessageDiv = document.createElement("div");
    errorMessageDiv.className="validation-failed-message";
    errorMessageDiv.innerHTML=errorDescription;
    errorMessageDiv.setAttribute("data-validation-message-id",String(dataValidationMessageIdsCounter));
    insertAfter(input,errorMessageDiv);
    dataValidationMessageIdsCounter++;
}

function formIsValid(formToValidate) {
    var inputs=formToValidate.getElementsByTagName("input");
    var textareas=formToValidate.getElementsByTagName("textarea");
    var selects=formToValidate.getElementsByTagName("select");
    var formValid=true;
    var inputValid;
    var errorDescription="Dato non valido";
    var i;
    for(i=0; i<inputs.length; i++) {
        inputValid=true;
        switch(inputs[i].type) {
            case "radio":
                break;
            case "hidden":
                break;
            case "checkbox":
                break;
            case "number":
                if(inputs[i].hasAttribute("required")&&inputs[i].value==="") {
                    inputValid=false;
                    errorDescription="Campo richiesto";
                }
                if(inputs[i].hasAttribute("step")) {
                    if(!isDivisible(inputs[i].value,inputs[i].step)) {
                        inputValid=false;
                        errorDescription="Deve essere un multiplo di "+inputs[i].step;
                    }
                }
                if(inputs[i].hasAttribute("max")) {
                    if(parseFloat(inputs[i].value)>parseFloat(inputs[i].max)) {
                        inputValid=false;
                        errorDescription="Valore massimo ammesso "+inputs[i].max;
                    }
                }
                if(inputs[i].hasAttribute("min")) {
                    if(parseFloat(inputs[i].value)<parseFloat(inputs[i].min)) {
                        inputValid=false;
                        errorDescription="Valore minimo ammesso "+inputs[i].min;
                    }
                }
                break;
            case "text":
                if(inputs[i].hasAttribute("maxlength")) {
                    if(inputs[i].value.length>parseInt(inputs[i].maxlength)) {
                        inputValid=false;
                        errorDescription="Lunghezza massima "+inputs[i].maxlength+" caratteri";
                    }
                }
                if(inputs[i].hasAttribute("minlength")) {
                    if(inputs[i].value.length<parseInt(inputs[i].minlength)) {
                        inputValid=false;
                        errorDescription="Lunghezza minima "+inputs[i].minlength+" caratteri";
                    }
                }
                if(inputs[i].hasAttribute("required")&&inputs[i].value==="") {
                    inputValid=false;
                    errorDescription="Campo richiesto";
                }
                break;
            case "email":
                if(inputs[i].hasAttribute("maxlength")) {
                    if(inputs[i].value.length>parseInt(inputs[i].maxlength)) {
                        inputValid=false;
                        errorDescription="Lunghezza massima "+inputs[i].maxlength+" caratteri";
                    }
                }
                if(inputs[i].value==="") {
                    if(inputs[i].hasAttribute("required")) {
                        inputValid=false;
                        errorDescription="Campo richiesto";
                    }
                }
                else {
                    if(!validation.emailIsValid(inputs[i].value)) {
                        inputValid=false;
                        errorDescription="Formato mail non corretto";
                    }
                }
                break;
            default:
                if(inputs[i].hasAttribute("required")&&inputs[i].value==="") {
                    inputValid=false;
                    errorDescription="Campo richiesto";
                }
        }
        if(!inputValid) {
            formValid=false;
            markInputAsNotValid(inputs[i],errorDescription);
        }
    }
    for(i=0; i<selects.length; i++) {
        if(selects[i].hasAttribute("required")&&selects[i].value==="") {
            markInputAsNotValid(selects[i],"Campo richiesto");
            formValid=false;
        }
    }
    for(i=0; i<textareas.length; i++) {
        if(textareas[i].hasAttribute("maxlength")) {
            if(textareas[i].value.length>parseInt(textareas[i].maxlength)) {
                markInputAsNotValid(textareas[i],"Lunghezza massima "+inputs[i].maxlength+" caratteri");
                formValid=false;
            }
        }
        if(textareas[i].hasAttribute("minlength")) {
            if(textareas[i].value.length<parseInt(textareas[i].minlength)) {
                markInputAsNotValid(textareas[i],"Lunghezza minima "+inputs[i].minlength+" caratteri");
                formValid=false;
            }
        }
        if(textareas[i].hasAttribute("required")&&textareas[i].value==="") {
            markInputAsNotValid(textareas[i],"Campo richiesto");
            formValid=false;
        }
        if(textareas[i].hasAttribute("data-comma-separated-mails")){
            if(textareas[i].value.trim()!==""){
                var emails=textareas[i].value.split(",");
                emails.forEach(function(email){
                    if(!validation.emailIsValid(email)){
                        markInputAsNotValid(textareas[i],"Contiene e-mail non valide");
                        formValid=false;
                    }
                });
            }
        }
    }
    if(!formValid) {
        errorToast("Alcuni campi non sono compilati correttamente");
    }
    return formValid;
}

function resetFormValidationMessages() {
    var validationHighlights=document.getElementsByClassName("input-validation-failed");
    for(var i=0; i<validationHighlights.length; i++) {
        dom.removeClass(validationHighlights[i],"input-validation-failed");
    }
    var validationMessages=document.getElementsByClassName("validation-failed-message");
    while(validationMessages.length > 0) {
        validationMessages[0].parentNode.removeChild(validationMessages[0]);
    }
    dataValidationMessageIdsCounter=0;
}

/******** firebase.js start ********/

var mustSendNewFirebaseToken=false;

//Firebase allows to get push notifications from the server

function ajaxSendNewFirebaseTokenToServer(firebaseCloudMessagingToken)
{
    if(!currentAuthToken||!firebaseCloudMessagingToken){
        return false;
    }

    var firebaseToken = {};
    firebaseToken.firebaseToken=firebaseCloudMessagingToken;

    var timeoutErrorAbort = function() {
        ajaxSendErrorReport(xhttp.responseURL,JSON.stringify(firebaseToken),xhttp.responseText);
        console.error("Errore durante l'invio del token Firebase. Codice risposta:"+xhttp.status+" "+xhttp.responseText);
        console.error(xhttp.responseText);
        mustSendNewFirebaseToken=true;
    };

    var xhttp = new XMLHttpRequest();
    xhttp.onload = function(){
        try {
            var response = JSON.parse(xhttp.responseText);
            console.log("Token Firebase inviato al server: " + firebaseCloudMessagingToken);
            mustSendNewFirebaseToken = false;
        }
        catch(e) {
            timeoutErrorAbort();
        }
    };
    xhttp.onerror = timeoutErrorAbort;
    xhttp.onabort = timeoutErrorAbort;
    xhttp.ontimeout = timeoutErrorAbort;
    xhttp.open("POST", BASE_URL+"/ws/registerNewFirebaseToken.php", true);
    xhttp.setRequestHeader("Content-Type","application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token",currentAuthToken);
    xhttp.send(JSON.stringify(firebaseToken));
    return true;
}

/******** buildingMap.js start *********/

//Contains the shape that I'm drawing
var newShape;

/*
 * Contains the type of object I'm drawing, admittable values are:
 * - site
 * - asset
 */
var drawing=null;

//If I'm goingo to draw a site drawingSiteId must contain the ID of the site I'm going to draw before i start drawing
var drawingSiteId=null;

//siteShapes is an array of objects, each object contains the shape vertex and the siteId
var siteShapes=[];

function registerSiteDrawingToBuildingMap()
{
    drawingSiteId=document.getElementById("add-site-to-building-map-site").value;
    closePopup();
    buildingMapStartDrawingSite();
}

function initializeLayerIfNeeded(canvas,autoAdjustLayerSize)
{
    if(!isLayerInitialized(canvas))
        initializeLayer(canvas,autoAdjustLayerSize);
}

function isLayerInitialized(canvas)
{
    return(canvas.getAttribute("data-initialized")==="true");
}

function initializeLayer(canvas,autoAdjustLayerSize)
{
    canvas.onclick=function(event){
        handleLayerClick(canvas,event);
    };
    canvas.addEventListener("mousemove", function(event) {
        handleLayerHover(canvas,event);
    }, false);
    if(autoAdjustLayerSize)
    {
        if(adjustCanvasSize(canvas))
            canvas.setAttribute("data-initialized","true");
        window.addEventListener("resize", function(event){
            adjustCanvasSize(canvas);
        });
    }
    else
        canvas.setAttribute("data-initialized","true");
}

//Returns true in case of success, else false
function adjustCanvasSize(canvas)
{
    var parentBoundingInfo=canvas.parentElement.getBoundingClientRect();
    if(parentBoundingInfo.width)
    {
        var side=Math.floor(parentBoundingInfo.width/100*95);
        canvas.width=side;
        canvas.height=side;
        return true;
    }
    return false;
}

function isPointOverShape(shape, point)
{
    var c = false;
    var vertNum=shape.vert.length;
    for(var i=0, j=vertNum-1; i<vertNum; j=i++)
    {
        if
        (
            ((shape.vert[i].y>point.y)!==(shape.vert[j].y>point.y))
            &&
            (point.x<(shape.vert[j].x-shape.vert[i].x)*(point.y-shape.vert[i].y)/(shape.vert[j].y-shape.vert[i].y)+shape.vert[i].x)
        )
        {
            c=!c;
        }
    }
    return c;
}

//Returns the hovered site shape, if any, note that this function doesn't handle overlapped site shapes
function getHoveredSiteShape(siteShapes,point)
{
    for(var i=0; i<siteShapes.length; i++)
    {
        if(isPointOverShape(siteShapes[i].shape, point))
            return siteShapes[i];
    }
    return null;
}

function buildingMapStartDrawingSite()
{
    dom.addClass(document.getElementById("building-map"),"being-edited");
    dom.addClass(document.getElementById("edit-building-map-btn"),"hidden");
    var messageContainer=document.getElementById("building-map-messages");
    messageContainer.innerHTML="Clicca sulla mappa per disegnare i vertici del sito, per chiudere la figura fai click sul primo vertice disegnato.";
    dom.removeClass(messageContainer,"hidden");
    closePopup();
    newShape=getNewShape();
    drawing="site";
}

function startDrawingAssets()
{
    drawing="asset";
}

function stopDrawing()
{
    drawing=null;
    dom.addClass(document.getElementById("building-map-messages"),"hidden");
    dom.removeClass(document.getElementById("edit-building-map-btn"),"hidden");
    dom.removeClass(document.getElementById("building-map"),"being-edited");
}

function getNewShape()
{
    shape={};
    shape.vert=[];
    return shape;
}


//adds a point to a shape, if the point is near the first point of the shape, the shape will be filled and the drawingFinishedCallback will be executed
function addPointToShape(canvas,shape,point,fillStyle,drawingFinishedCallback)
{
    if(shape.vert.length>0 && isPointNearPoint(shape.vert[0],point,10))
    {
        if(shape.vert.length>1)
        {
            ctx=canvas.getContext("2d");
            ctx.beginPath();
            ctx.moveTo(shape.vert[shape.vert.length-1].x,shape.vert[shape.vert.length-1].y);
            ctx.lineTo(shape.vert[0].x,shape.vert[0].y);
            ctx.closePath();
            ctx.stroke();
        }
        drawShape(canvas,shape,fillStyle);
        drawingFinishedCallback(shape);
        stopDrawing();
        refreshCanvas(canvas);
    }
    else
    {
        shape.vert.push(point);
        drawShapeVertex(canvas,point);
        if(shape.vert.length>1)
        {
            ctx=canvas.getContext("2d");
            ctx.beginPath();
            ctx.moveTo(shape.vert[shape.vert.length-2].x,shape.vert[shape.vert.length-2].y);
            ctx.lineTo(shape.vert[shape.vert.length-1].x,shape.vert[shape.vert.length-1].y);
            ctx.closePath();
            ctx.stroke();
        }
    }
}

function refreshCanvas(canvas)
{
    var ctx=canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for(var i=0; i<siteShapes.length; i++) {
        drawShape(canvas, siteShapes[i].shape, "#ceb7a1");
    }
}

function drawShape(canvas,shape,fillStyle)
{
    var ctx=canvas.getContext("2d");
    ctx.fillStyle = fillStyle;
    ctx.beginPath();
    ctx.moveTo(shape.vert[0].x,shape.vert[0].y);
    for(var i=0;i<shape.vert.length;i++)
    {
        ctx.lineTo(shape.vert[i].x,shape.vert[i].y);
    }
    ctx.closePath();
    ctx.fill();
}

//returns true if p1 is distant from p2 less than maxDistance
function isPointNearPoint(p1,p2,maxDistance)
{
    return Math.sqrt((p1.x-p2.x)*(p1.x-p2.x)+(p1.y-p2.y)*(p1.y-p2.y)) < maxDistance;
}

//returns a point from its coordinates
function createPoint(x,y)
{
    var point={};
    point.x=x;
    point.y=y;
    return point;
}

function drawPoint(canvas,point,radius,fillStyle){
    var ctx = canvas.getContext("2d");
    ctx.fillStyle = fillStyle; // Red color
    ctx.beginPath();
    ctx.arc(point.x, point.y, radius, 0, Math.PI*2, true);
    ctx.fill();
}

function drawShapeVertex(canvas,point)
{
    drawPoint(canvas,point,3,"#333333");
}

function drawAsset(canvas,point)
{
    drawPoint(canvas,point,3,"#ff2626");
}

function registerNewSiteShape(shape)
{
    var newSiteShape={};
    newSiteShape.shape=shape;
    newSiteShape.siteId=drawingSiteId;
    siteShapes.push(newSiteShape);
}

function drawSiteAddVertex(canvas,point)
{
    addPointToShape(canvas,newShape,point,"#ceb7a1",registerNewSiteShape);
}

function getMousePosition(canvas,event)
{
    var rect = canvas.getBoundingClientRect();
    var x = Math.floor((event.clientX-rect.left)/(rect.right-rect.left)*canvas.width);
    var y = Math.floor((event.clientY-rect.top)/(rect.bottom-rect.top)*canvas.height);
    return createPoint(x,y);
}

function handleLayerClick(canvas,event)
{
    var point=getMousePosition(canvas,event);
    switch(drawing)
    {
        case "site":
            drawSiteAddVertex(canvas,point);
            break;
        case "asset":
            drawAsset(canvas,point);
            break;
    }
}
var lastHoveredSiteShape=null;
function handleLayerHover(canvas,event)
{
    var point = getMousePosition(canvas,event);
    var hoveredSiteShape = getHoveredSiteShape(siteShapes,point);
    if(drawing==null)
    {
        if(hoveredSiteShape!=null)
        {
            if((lastHoveredSiteShape==null)||(lastHoveredSiteShape.siteId!=hoveredSiteShape.siteId))
            {
                drawShape(canvas,hoveredSiteShape.shape,"#fac56b");
                lastHoveredSiteShape=hoveredSiteShape;
            }
        }
        else
        {
            if(lastHoveredSiteShape!=null)
            {
                drawShape(canvas,lastHoveredSiteShape.shape,"#ceb7a1");
                lastHoveredSiteShape=null;
            }
        }

    }
}

/******** camera.js start *********/

function attachPhotoToEntity(localPhotoUploadId) {
    var linkInfo = {};
    linkInfo.photoId = photoUploads[localPhotoUploadId].photoId;
    linkInfo.entityId = photoUploads[localPhotoUploadId].targetId;
    linkInfo.entityType = photoUploads[localPhotoUploadId].uploadTarget;
    ajaxAttachPhotoToEntity(linkInfo);
}

function ajaxAttachPhotoToEntity(linkInfo) {
    var xhttp = new XMLHttpRequest();
    xhttp.timeout = 5000;
    xhttp.ontimeout=function() {
        setTimeout(function(){ajaxAttachPhotoToEntity(linkInfo);},5000);
    };
    xhttp.onerror=function() {
        setTimeout(function(){ajaxAttachPhotoToEntity(linkInfo);},5000);
    };
    xhttp.onload = function() {
        try {
            if(xhttp.status === 200) {
            }
            else {
                ajaxSendErrorReport(xhttp.responseURL,JSON.stringify(linkInfo),xhttp.responseText);
            }
        }
        catch(e) {
            ajaxSendErrorReport(xhttp.responseURL,JSON.stringify(e)+"<br>"+JSON.stringify(linkInfo),xhttp.responseText);
        }
    };
    xhttp.open("POST", BASE_URL+"/ws/linkPhotoToEntity.php", true);
    xhttp.setRequestHeader("Content-Type","application/json; charset=utf-8");
    xhttp.setRequestHeader("Authentication-Token",currentAuthToken);
    xhttp.send(JSON.stringify(linkInfo));
}

//allowed values for targetType: intervention,ticket,anomaly
function attachPhotoUploadToTarget(targetType)
{
    localPhotoUploadsId++;
    photoUploads[localPhotoUploadsId]={uploadTarget:targetType,photoId:null,targetId:null};
    var successCallback = (function(){
        var localTempId = localPhotoUploadsId;
        return function(responseJson){
            photoUploads[localTempId].photoId = responseJson.newFileId;
            //The target was already sent to server
            if(photoUploads[localTempId].targetId!==null){
                attachPhotoToEntity(localTempId);
            }
        };
    }());
    capturePhoto(successCallback,targetType,localPhotoUploadsId);
}

function initializeCamera() {
    document.getElementById("close-intervention-add-photo-button").removeAttribute("disabled");
    document.getElementById("close-intervention-add-photo-button").onclick=function(){attachPhotoUploadToTarget("intervention");};
    document.getElementById("create-ticket-add-photo-button").removeAttribute("disabled");
    document.getElementById("create-ticket-add-photo-button").onclick=function(){attachPhotoUploadToTarget("ticket");};
    document.getElementById("create-anomaly-add-photo-button").removeAttribute("disabled");
    document.getElementById("create-anomaly-add-photo-button").onclick=function(){attachPhotoUploadToTarget("anomaly");};
    document.getElementById("edit-asset-add-photo-button").removeAttribute("disabled");
    document.getElementById("edit-asset-add-photo-button").onclick=function(){attachPhotoUploadToTarget("asset");};
    document.getElementById("edit-asset-add-ambient-photo-button").removeAttribute("disabled");
    document.getElementById("edit-asset-add-ambient-photo-button").onclick=function(){attachPhotoUploadToTarget("asset-ambient");};
}

function removeAttachedPhotoFromButton(photoUploadsId)
{
    var thumbnail=document.getElementById("data-temp-upload-id-"+photoUploadsId);
    dom.removeElement(thumbnail);
    photoUploads.splice(photoUploadsId, 1);
    localPhotoUploadsId--;
    scroll.refreshPopupById("create-anomaly", false);
    scroll.refreshPopupById("end-intervention", false);
    scroll.refreshPopupById("create-ticket", false);
    scroll.refreshPopupById("edit-asset",false);
}

function ajaxUploadPhoto(fileURI,uploadSuccessCallback,uploadFailureCallback,thumbnailContainer)
{
    //FileUploadOptions and FileTransfer are global and provided by FileTransfer plugin, if available
    if(FileUploadOptions && FileTransfer) {
        var options = new FileUploadOptions();
        options.fileKey = "newPicture";
        options.fileName = fileURI.substr(fileURI.lastIndexOf('/') + 1);
        options.mimeType = "image/jpeg";
        options.httpMethod = "POST";
        options.chunkedMode = false; //to prevent problems during the upload, very important!
        options.headers = {
            "Authentication-Token":currentAuthToken
        };
        var fileTransfer = new FileTransfer();
        fileTransfer.onprogress = function(progressEvent) {
            if (progressEvent.lengthComputable) {
                thumbnailContainer.querySelector(".upload-progress").innerHTML=((progressEvent.loaded / progressEvent.total)*100).toFixed(0)+"%";
            }
        };
        fileTransfer.upload(
            fileURI,
            encodeURI(BASE_URL+"/ws/uploadPicture.php"),
            function(response){
                uploadSuccessCallback(response,thumbnailContainer);
            },
            function(error){
                uploadFailureCallback(error,thumbnailContainer);
            },
            options
        );
    }
    else {
        errorToast("Componente FileUploadOptions o FileTransfer non disponibile");
    }
}

function approvePrivacyPolicyVersion(){
    var requestData = {};
    requestData.privacyPolicyVersion = parseInt(document.getElementById("new-privacy-policy-version-number").value);
    var xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
        //var response = JSON.parse(xhttp.responseText);
        if(xhttp.status === 200) {
            successToast("Consenso registrato");
        }
        closePopup();
    };
    xhttp.open("POST", BASE_URL + "/ws/acceptPrivacyPolicyVersion.php", true);
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("Authentication-Token", currentAuthToken);
    xhttp.send(JSON.stringify(requestData));
}
window.approvePrivacyPolicyVersion=approvePrivacyPolicyVersion;

function onCapturePhoto(fileURI,localUploadSuccessCallback,targetType,photoUploadsId)
{
    var thumbnail, thumbnailContainer;
    //Upload success callback
    var uploadSuccessCallback = function(httpResponse,thumbnailContainer) {
        if(httpResponse.responseCode===200) {
            dom.removeClass(thumbnailContainer, "uploading");
            try {
                var response = JSON.parse(httpResponse.response);
                localUploadSuccessCallback(response);
                thumbnailContainer.setAttribute("data-uploaded-photo-id",response.newFileId);
                dom.addClass(thumbnailContainer,"upload-success");
                dom.removeClass(thumbnailContainer,"upload-error");
            }
            catch(e) {
                errorPopup("Errore nel caricamento della foto: "+e.message);
                dom.addClass(thumbnailContainer,"upload-error");
                dom.removeClass(thumbnailContainer,"upload-success");
            }
        }
    };
    //After the successful creation of the thumbnail I'll start uploading the photo
    var startPhotoUpload = function(thumbURI) {
        if(Capacitor){
            console.log("converting file path...");
            thumbURI=Capacitor.convertFileSrc(thumbURI); 
            console.log("Converted file path:"+thumbURI);
        }
        else{
            console.log("Capacitor not defined!");
        }
        //Generating the thumbnail in the DOM
        var thumbnailContainer = document.createElement("div");
        //Uploading icon
        var uploadingIcon = document.createElement("div");
        uploadingIcon.innerHTML="<i class='fa fa-spinner fa-pulse fa-3x fa-fw' aria-hidden='true'></i>";
        dom.addClass(uploadingIcon,"uploading-icon");
        //Upload progress container
        var uploadProgressContainer = document.createElement("div");
        dom.addClass(uploadProgressContainer,"upload-progress");
        //Upload error icon
        var uploadErrorIcon = document.createElement("div");
        uploadErrorIcon.innerHTML="<i class='fa fa-exclamation-triangle' aria-hidden='true'></i>";
        dom.addClass(uploadErrorIcon,"upload-error-icon");
        //Cancel photo button
        var cancelPhotoButton = document.createElement("button");
        cancelPhotoButton.setAttribute("type","button");
        cancelPhotoButton.innerHTML="×";
        dom.addClass(cancelPhotoButton,"cancel-photo-button");
        cancelPhotoButton.onclick = (function() {
            //Closure to save the value of photoUploadsId
            var savedPhotoUploadsId = photoUploadsId;
            return function() {
                removeAttachedPhotoFromButton(savedPhotoUploadsId);
                scroll.refreshPopupById("create-anomaly", false);
                scroll.refreshPopupById("end-intervention", false);
                scroll.refreshPopupById("create-ticket", false);
                scroll.refreshPopupById("edit-asset",false);
            };
        })();
        dom.addClass(thumbnailContainer,"thumbnail-container");
        dom.addClass(thumbnailContainer,"uploading");
        var thumbnail = document.createElement("img");
        thumbnail.setAttribute('src', thumbURI);
        thumbnailContainer.appendChild(thumbnail);
        thumbnailContainer.appendChild(uploadingIcon);
        thumbnailContainer.appendChild(uploadProgressContainer);
        thumbnailContainer.appendChild(uploadErrorIcon);
        thumbnailContainer.appendChild(cancelPhotoButton);
        thumbnailContainer.id="data-temp-upload-id-"+photoUploadsId;
        dom.addClass(thumbnail,"uploading");
        switch(targetType) {
            case "intervention":
                document.getElementById("intervention-photo-thumbnails").appendChild(thumbnailContainer);
                scroll.refreshPopupById("end-intervention", false);
                break;
            case "anomaly":
                document.getElementById("create-anomaly-photo-thumbnails").appendChild(thumbnailContainer);
                scroll.refreshPopupById("create-anomaly", false);
                break;
            case "ticket":
                document.getElementById("create-ticket-photo-thumbnails").appendChild(thumbnailContainer);
                scroll.refreshPopupById("create-ticket", false);
                break;
            case "asset":
                document.getElementById("edit-asset-photo-thumbnails").appendChild(thumbnailContainer);
                scroll.refreshPopupById("edit-asset-instance", false);
                break;
            case "asset-ambient":
                document.getElementById("edit-asset-ambient-photo-thumbnails").appendChild(thumbnailContainer);
                scroll.refreshPopupById("edit-asset-instance", false);
                break;
        }

        var ajaxUploadPhotoErrorCallback = function(error,thumbnailContainer) {
            errorToast("Errore durante il caricamento di una foto, verificare la connessione ad Internet");
            dom.removeClass(thumbnailContainer, "uploading");
            dom.addClass(thumbnailContainer,"upload-error");
            dom.removeClass(thumbnailContainer,"upload-success");

            setTimeout(
                function(){
                    ajaxUploadPhoto(
                        fileURI,
                        uploadSuccessCallback,
                        ajaxUploadPhotoErrorCallback,
                        thumbnailContainer
                    );
                },
                5000
            );
        };

        //Uploading the photo
        ajaxUploadPhoto(
            fileURI,
            uploadSuccessCallback,
            ajaxUploadPhotoErrorCallback,
            thumbnailContainer
        );

    };

    /* scale the image to generate the thumbnail, when i have a thumbnail to show the upload progress
     * I can start uploading the photo, if no thumbnail is available I use the full resolution image
     */    
    if(window.ImageResizer) {
        var randomToken = Math.random().toString(36).substr(2);
        window.ImageResizer.resize(
            {
                uri: fileURI,
                quality: 90,
                width: 80,
                height: 80,
                base64: false,
                folderName: 'Claster FM',
                fileName: (randomToken+'.jpg')
            },
            startPhotoUpload,
            function(err) {
                //Resizing error, using full resolution image as a thumbnail
                startPhotoUpload(fileURI);
                ajaxSendErrorReport("window.ImageResizer.resize errorCallback","fileUri:"+fileURI+" targetType:"+targetType+" photoUploadsId:"+photoUploadsId,err);
            }
        );
    }
    else {
        //ImageResizer not available, using full resolution image as a thumbnail
        startPhotoUpload(fileURI);
        ajaxSendErrorReport("window.ImageResizer not available","fileUri:"+fileURI+" targetType:"+targetType+" photoUploadsId:"+photoUploadsId,"");
    }

    //Adding new photo to photo library
    if(cordova.plugins.photoLibrary) {
        //adding "&ext=.jpg" to patch this issue: https://github.com/terikon/cordova-plugin-photo-library/issues/116
        cordova.plugins.photoLibrary.saveImage(
            fileURI+"&ext=.jpg",
            "Claster FM",
            function(libraryItem) {
                //Successfully saved image to photo library
            },
            /**
             * @param {string} err - error message
             */
            function(err) {
                //Telling the user that there is an error while saving to photo library only if it's permission related
                if(err.startsWith('Permission')) {
                    // Call requestAuthorization, and retry
                    cordova.plugins.photoLibrary.requestAuthorization(
                        function () {
                            // User gave us permission to his library, retry...
                            onCapturePhoto(fileURI,localUploadSuccessCallback,targetType,photoUploadsId);
                        },
                        function (err) {
                            // User denied the access
                            errorToast("Errore durante il salvataggio in galleria dell'immagine, permesso negato: "+err);
                            ajaxSendErrorReport("cordova.plugins.photoLibrary.requestAuthorization errorCallback","fileUri:"+fileURI+" targetType:"+targetType+" photoUploadsId:"+photoUploadsId,err);
                        },
                        {
                            read: true,
                            write: true
                        }
                    );
                }
            }
        );
    }
    else {
        //Photo library component not available, sending a report without informing the user
        ajaxSendErrorReport("cordova.plugins.photoLibrary not available","fileUri:"+fileURI+" targetType:"+targetType+" photoUploadsId:"+photoUploadsId,"");
    }
}

async function capturePhoto(uploadSuccessCallback,targetType,photoUploadsId) {

        const image = await Camera.getPhoto({
            quality: 75,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            promptLabelCancel: "Annulla",
            promptLabelHeader: "Scelta fotografia",
            promptLabelPhoto: "Scegli dalla galleria",
            promptLabelPicture: "Scatta una foto",
            saveToGallery: true,
        });
        var fileURI = image.path;
        onCapturePhoto(fileURI,uploadSuccessCallback,targetType,photoUploadsId);
}

/******** initialize.js start *********/

//Key codes
var KEYCODE_ENTER = 13;
var KEYCODE_SHIFT = 16;
var KEYCODE_CTRL = 17;
var KEYCODE_ESC = 27;
var KEYCODE_ARROW_LEFT = 37;
var KEYCODE_ARROW_UP = 38;
var KEYCODE_ARROW_RIGHT = 39;
var KEYCODE_ARROW_DOWN = 40;
var KEYCODE_COMMA = 188;

//Event directions
//var DIRECTION_LEFT = 2;
//var DIRECTION_RIGHT = 4;
var DIRECTION_UP = 8;
var DIRECTION_DOWN = 16;

var SUCCESS_OUTCOME = 1;
var MORE_WORK_REQUIRED_OUTCOME = 2;
var THIRD_PARTY_INTERVENTION_REQUIRED_OUTCOME = 3;
var INTERVENTION_NOT_POSSIBLE = 4;

var ctrlPressed=false;
var shiftPressed=false;
var shiftPressedOk=false;

var dbReady=false;

//Data saved from server
var data = {
    unitsOfMeasure:[]
};

//Used to check touchstart events with scroll
var touchMoved;

var globalFilterByCompany;
var globalUserPermissions=null;

// Used for administration
var buildingsAvailableForSiteEdit=[];
var builidingGroupsAvailableForSiteEdit=[];

// Counter used to generate unique local uploads id, it can only be increased
var localPhotoUploadsId=-1;
/*
 *  Array of objects containing the following data: {uploadTarget,photoId,targetId}
 *  The index of every array element must be the localPhotoUploadsId
 *  When the form is submitted and the photos are still uploading the photoId is null, so the targetId will be set
 *  When the form is not yet submitted but the photos are already uploaded the photoId is set, and the targetId is null
 */
var photoUploads=[];

var savedPageHash=null; // page hash saved before redirecting to login page
var assetRelationTypes = [];
var currentPage; //Page currently viewed
var currentAuthToken=null; //Authentication token

var currentAuthTokenV2 = null; //Auth token for new server authentication
var currentSessionCode = null; //Session code for new server authentication

var currentFcmToken=null;
var currentSessionId=null; //Current session Id
var currentLoggedInUserId=null; //Currently logged in user
var newContract;//contract that is being created using the wizard
var newContractActivityCounter;
var newContractActivityUniqueCounter;
var unreadNotifications;
var unseenNotifications;
var selectedIntervention=null;//contains the last opened intervention id
var selectedInterventionActivity=null;//contains the last opened intervention activity id
var justUploadedFile=null;
var assetsCategoryTreeGenerated=false;
var lastMonth;//last month selected in calendar page
var lastYear;//last year selected in calendar page
var lastViewedAnomaly;
var lastViewedAssetInstance;
var lastViewedUserPermissions;
var lastFetchedActivitiesForSubcategory;
var editingActivityOnSubcategoryId;
var lastOpenedContract;
var lastSelectedTicket=null;
var lastSelectedAnomaly=null;
var clusterLayer=null;//pushpin layer used by bing maps
var tickets=null;
var lastSelectedDocumentId=null;
var textSearchDataRoomDocumentTag;
var dataRoomDocumentTags=[];
var dataRoomAppliedFilter={};
var dataRoomMultipleSelection=false;
var lastQueriedDocuments;
var documentIdToDelete;
var qrCodeInUrl;//can contain the qr code present in the URL
var calCurrentDate = new Date();
var lastGeneratedDocumentsShare;
var hashChangedInternally=false;//for hash navigation and history
var initialUrlHandled=false;
var ticketIdToChange;
var lastPushPinSelected=null;
var interventionInExecutionContainsTickets=false;
var dataValidationMessageIdsCounter=0;
var dataRoomShowTrash=false;
var selectedDocuments=[];
var permissionLevels;
var permissionTypes;
var qrCodes;

var loadingLog="";

var lastSyncDateObject=new Date();

var lastScannedQrCode="";

//Variables required by popup input fields
var inputRequestedRealValueField=null;
var inputRequestedVisualValueField=null;

var interventionsMonthlyCounter;
var completedInterventionsMonthlyCounter;
var lateInterventionsMonthlyCounter;

var interventionsYearlyCounter;
var completedInterventionsYearlyCounter;
var lateInterventionsYearlyCounter;

var lateInterventionsHtml;

var lastFetchedBuilding;
var lastFetchedBuildingsGroup;
var lastFetchedSite;
var lastEditedCategoryGroup;
var lastEditedCategory;
var lastEditedSubcategory;
var lastCreatedAssetId;

var interventionAdmittableMaxStartDate;
var interventionAdmittableMinEndDate;

var pageStack=[];//Stack containing the last opened pages, in order

var map=null;
var mapInfobox;
var mapInfoboxClickHandler;
var mapPushpins = Array();

//References to the DOM
var ticketSiteSelect;
var ticketCategorySelect;
var ticketSubcategorySelect;
var outcomePopup;

//Data fetched via AJAX, alphabetical order, please
var anomalyLevels;
var assetInstancesInExecution;
var balances;
var buildings;
var buildingsGroups;
var cadastralCategories;
var categories;
var categoryGroups;
var companies;
var companyLicenses;
var currentCompanyId=null;
var documentTypes;
var employees;
var expenditureItems;
var floors;
var areas;
var frequencies;
var interventions;
var lastAssetInstances;
var lastOpenedIntervention=null;
var lastQueriedAssets;
var laws;
var logEvents;
var meteo;
var permissions;
var preFetchedAssets;
var selectedMonthInterventions;
var selectedYearInterventions;
var sites;
var subcategories;
var unpaidSupplierInvoices;
var vatRates;

window.userPermissions=[];

var ticketDayPikaday=null;


var subcategoryGroups = {
   data: [],
   init: function(subcategoryGroupsArray){
       subcategoryGroups.data = []; //clearing old data
        subcategoryGroupsArray.forEach(function(subcategoryGroup){
            subcategoryGroups.data[subcategoryGroup.subcategoryGroupId]=subcategoryGroup;
        });
   },
   get: function(id){
        return subcategoryGroups.data[id];
   },
   getAll: function(){
       return subcategoryGroups.data;
   }
};

//Wrapper object for ajax fetched data
var certifications = {
    data: [],
    load: function(data){
        if(data===undefined || data===null) {
            certifications.data = [];
        }
        else {
            certifications.data=data;
        }
    },
    getOptions: function(certificationForUser){
        var tempHtml="";
        var addedCertifications=0;
        certifications.data.forEach(function(certification){
            if(certificationForUser===certification.forUsers){
                tempHtml+="<option value='"+certification.name+"'>"+certification.name+"</option>";
                addedCertifications++;
            }
        });
        if(addedCertifications===0){
            tempHtml="<option value=''>-- Nessuna disponibile --</option>";
        }
        return tempHtml;
    },
    setOptionsToSelectById(id,certificationForUser){
        var select=document.getElementById(id);
        if(select!=null) {
            setInnerHtml(select,certifications.getOptions(certificationForUser));
        }
        else{
            console.error("elemento con id:" + id + " non esistente");
        }
    },
    companyCertificationChanged(value){
        var siteHolder = document.getElementById("new-company-certification-referred-to-site-holder");
        var siteInput = document.getElementById("new-company-certification-referred-to-site");
        var insuranceMaxHolder = document.getElementById("new-company-certification-max-insurance-holder");
        var insuranceMaxInput = document.getElementById("new-company-certification-max-insurance");
        switch(value) {
            case "DUVRI":
                dom.removeClass(siteHolder, "hidden");
                siteInput.setAttribute("required", "required");
                dom.addClass(insuranceMaxHolder, "hidden");
                insuranceMaxInput.removeAttribute("required");
                insuranceMaxInput.value = "";
                break;
            case "Assicurazione":
                dom.removeClass(insuranceMaxHolder, "hidden");
                insuranceMaxInput.setAttribute("required", "required");
                dom.addClass(siteHolder, "hidden");
                input.removeAttribute("required");
                input.value = "";
                break;
            default:
                dom.addClass(siteHolder, "hidden");
                input.removeAttribute("required");
                dom.addClass(insuranceMaxHolder, "hidden");
                insuranceMaxInput.removeAttribute("required");
                insuranceMaxInput.value = "";
                input.value = "";
                break;
        }
    }
};

var copiedPermissions;
var userLoggedOut=false;
var getNotificationsInitialized=false;

var appliedFilters =
{
    "category":null,
    "subcategory":null,
    "categoryGroup":null,
    "interventionDate":null,
    "company":null,
    "site":null
};

var weekday = ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"];
var monthNames = ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];

//pikaday inputs loaded dinamically
var userDataBirthday, editSiteFirePreventionCertificateDate, editSiteFirePreventionCertificateExpiration;
var today = new Date();
var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
var afterTomorrow = new Date(today.getTime() + (2 * 24 * 60 * 60 * 1000));

var globalFilterByCompanyFromLocalStorage;
try{ //Cookies disabled
    globalFilterByCompanyFromLocalStorage=window.localStorage.getItem("cfm_last_global_filter_by_company");
}
catch(e){
    console.error(e);
    globalFilterByCompanyFromLocalStorage=null;
}

if(globalFilterByCompanyFromLocalStorage)
{
    globalFilterByCompany=globalFilterByCompanyFromLocalStorage;
}
//labels for the days of the week
var calDaysLabels = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

//human-readable month name labels, in order
var calMonthsLabels = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile',
    'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre',
    'Ottobre', 'Novembre', 'Dicembre'
];

//the days of the week for each month, in order
var calDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

// this is the current date
Date.prototype.toDateInputValue = (function() {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return ((local.getDate()<10)?"0"+local.getDate():local.getDate())+"/"+((local.getMonth()+1<10)?"0"+(local.getMonth()+1):(local.getMonth()+1))+"/"+local.getFullYear();
});

var timeString=calCurrentDate.toTimeString().split(' ')[0];

var editSiteManagerCcTaggle, editSiteExecutorsCcTaggle, editDocumentTagsTaggle, uploadDocumentTagsTaggle,
    createTicketInvolvedAssetsTaggle, createAnomalyInvolvedAssetsTaggle;

var pikadays = {};
var interventionEventsCytoscape;
function initializeCytoscape()
{
    interventionEventsCytoscape = cytoscape({
        container: document.getElementById("intervention-events-graph"),
        elements: [],
        layout: {
            name: "preset"
        },
        panningEnabled: false,
        style: [
            {
                selector: "node",
                style: {
                    "font-size":"13px",
                    "background-color": "#dccebf",
                    "text-valign": "center",
                    "text-halign": "right",
                    "font-family":"Avenir",
                    'height': 20,
                    'width': 20,
                }
            },
            {
                selector: "edge",
                style: {
                    "curve-style": "bezier",
                    "width": 3,
                    "target-arrow-shape": "triangle"
                }
            },
            {
                selector: ".completed",
                style: {
                    "background-image": checkDot
                }
            },
            {
                selector: ".notcompleted",
                style: {
                    "background-image": waitDot
                }
            }
        ]
    });
    interventionEventsCytoscape.nodeHtmlLabel([{
        query: ".interventiondot",
        valign: "center",
        halign: "right",
        valignBox: "center",
        halignBox: "right",
        tpl: function(data) {
            return "<span class='cy-title'>" + data.name + "</span>" + "<span class='cy-subtitle'>" + data.subtitle + "</span>";
        }
    }]);
    interventionEventsCytoscape.on('tap', 'node', function (evt) {
         switch(evt.target.id()) {
             case "previous-day-intervention-dot":
                 selectIntervention(interventionManager.currentInterventionData.previousDayIntervention);
                 break;
             case "following-day-intervention-dot":
                 selectIntervention(interventionManager.currentInterventionData.followingDayIntervention);
                 break;
         }
    });
}

function defineTapListener(id,callback) {
    defineTapListenerOnElement(document.getElementById(id),callback);
}

function unbindTapListenerOnElement(element){
    //Hammer(element).off("tap");
}

function defineTapListenerOnElement(el,callback) {
  function handleInteraction(evt) {
        if(!ghostClickController.isOk()){
            return false;
        }
        if(someParentIsDisabled(event.target)) {
            return;
        }
        if(typeof callback === "function") {
            callback(event);
        }
  }
  //el.addEventListener('touchstart', handleInteraction);
  el.addEventListener('click', handleInteraction);
}

import qrScanTarget from '../img/qr-scan-area.png';
import changeCam from '../img/change-cam.png';
import checkDot from '../img/check-dot.svg';
import imgClouds from '../img/clouds.png';
import imgExampleQr from '../img/esempio-qr.png';
import flashOff from '../img/flash-off.png';
import flashOn from '../img/flash-on.png';
import imgLogo from '../img/logo.png';
import imgNoData from '../img/nodata.png';
import imgOfflineLogo from '../img/offline.png';
import imgPushpinSelected from '../img/pushpin-selected.png';
import imgPushpin from '../img/pushpin.png';
import imgRainy from '../img/rainy.png';
import imgSnow from '../img/snow.png';
import imgSpinner from '../img/spinner.gif';
import imgSun from '../img/sun.png';
import waitDot from '../img/wait-dot.svg';
import imgWind from '../img/wind.png';
import imgWinterLoader from '../img/winterloader.gif';
import imgClasterLogo from '../img/claster-logo.png';
import imgClasterCornerLogo from '../img/claster-corner-logo.png';
import imgWinterLogo from '../img/wintericon.png';


var tmpImg = document.createElement("img");
tmpImg.src=imgWinterLoader;
tmpImg.src=imgWind;
tmpImg.src=imgLogo;
tmpImg.src=imgClasterCornerLogo;
tmpImg.src=imgWinterLogo;

function initialize()
{
    document.getElementById("qr-scan-target").src=qrScanTarget;
    document.getElementById("img-flash-on").src=flashOn;
    document.getElementById("img-flash-off").src=flashOff;
    document.getElementById("img-change-cam").src=changeCam;
    document.getElementById("img-about-claster-logo").src=imgClasterLogo;
    document.getElementById("img-offline-logo").src=imgOfflineLogo;
    document.getElementById("building-map-img").src=imgSpinner;
    document.getElementById("img-example-qr").src=imgExampleQr;
    document.getElementById("intervention-validation-qr-container").src=imgSpinner;
    editSiteManagerCcTaggle = new Taggle(
        "edit-site-site-manager-cc",
        {
            saveOnBlur: true,
            delimeter: [',',';'],
            onBeforeTagAdd: function(event, tag) {
                if(!validation.emailIsValid(tag)){
                    errorToast("Indirizzo mail immesso non valido");
                    return false;
                }
                return true;
            }
        }
    );
    editSiteExecutorsCcTaggle = new Taggle(
        "edit-site-executors-cc",
        {
            saveOnBlur: true,
            delimeter: [',',';'],
            onBeforeTagAdd: function(event, tag) {
                if(!validation.emailIsValid(tag)){
                    errorToast("Indirizzo mail immesso non valido");
                    return false;
                }
                return true;
            }
        }
    );
    editDocumentTagsTaggle = new Taggle(
        "edit-document-tags-container",
        {
            preserveCase: true,
            delimeter: [',',';'],
            saveOnBlur: true,
            onBeforeTagAdd: function(event, tag) {
                if(tag.length>40){
                    errorToast("La lunghezza massima di un tag è di 40 caratteri");
                    return false;
                }
                return true;
            }
        }
    );
    uploadDocumentTagsTaggle = new Taggle(
        "upload-document-tags-container",
        {
            preserveCase: true,
            delimeter: [',',';'],
            saveOnBlur: true,
            onBeforeTagAdd: function(event, tag) {
                if(tag.length>40){
                    errorToast("La lunghezza massima di un tag è di 40 caratteri");
                    return false;
                }
                return true;
            }
        }
    );
    createTicketInvolvedAssetsTaggle = new Taggle(
        "create-ticket-involved-assets",
        {
            placeholder: "Scrivi qui o scansiona QR",
            preserveCase: true,
            delimeter: [",",";"],
            saveOnBlur: true,
            onBeforeTagAdd: function(event, tag) {
                if(tag.length>15){
                    errorToast("La lunghezza massima di un codice asset è di 15 caratteri");
                    return false;
                }
                if(!validation.qrOrAssetIdIsValid(tag)){
                    errorToast("Il formato del codice asset non è corretto");
                    return false;
                }
                createTicketPopupAutocompleteFromAsset(tag);
                return true;
            },
            tagFormatter: function(element){
                element.querySelector(".taggle_text").innerHTML=element.querySelector(".taggle_text").innerHTML.toUpperCase();
                return element;
            }
        }
    );
    createAnomalyInvolvedAssetsTaggle = new Taggle(
        "create-anomaly-involved-assets",
        {
            placeholder: "Scrivi qui o scansiona QR",
            preserveCase: true,
            delimeter: [",",";"],
            saveOnBlur: true,
            onBeforeTagAdd: function(event, tag) {
                if(tag.length>15){
                    errorToast("La lunghezza massima di un codice asset è di 15 caratteri");
                    return false;
                }
                if(!validation.qrOrAssetIdIsValid(tag)){
                    errorToast("Il formato del codice asset non è corretto");
                    return false;
                }
                createAnomalyPopupAutocompleteFromAsset(tag);
                return true;
            },
            tagFormatter: function(element){
                element.querySelector(".taggle_text").innerHTML=element.querySelector(".taggle_text").innerHTML.toUpperCase();
                return element;
            }
        }
    );

    //Patch to fix close notifications - show notifications loop (ghost click?)
    var lastTimeOpenedNotifications = new Date();
    defineTapListener("tab-notifications",function(){
        var now = new Date();
        console.log((now - lastTimeOpenedNotifications) + "ms");
        if(now - lastTimeOpenedNotifications > 2000){
            lastTimeOpenedNotifications = now;
            popup("notifications", false, false, false);
        }
    });
    defineTapListener("tab-outbox",function(){outboxManager.drawList();popup("outbox", false, false, false);});
    defineTapListener("tab-settings",function(){popup("settings", false, false, false);});
    defineTapListener("tab-info-privacy-mobile",function(){popup("info-privacy", false, false, false);});
    defineTapListener("tab-safety-control",function(){changePage("page-safety-control");});
    defineTapListener("tab-settings-mobile",function(){popup("settings", false, false, false);});
    defineTapListener("tab-accounting",function(){changePage("page-accounting");});
    defineTapListener("tab-contracts",function(){changePage("page-contracts");});
    defineTapListener("tab-plan",function(){changePage("page-plan");});
    defineTapListener("tab-company",function(){changePage("page-company");});
    defineTapListener("tab-buildings",function(){changePage("page-buildings");});
    defineTapListener("tab-data-room",function(){changePage("page-data-room");});
    defineTapListener("tab-manage-asset",function(){changePage("page-assets");});
    defineTapListener("tab-calendar",function(){changePage("page-calendar");});
    defineTapListener("tab-maintenance",function(){changePage("page-maintenance");});
    defineTapListener("tab-anomalies",function(){changePage("page-anomalies");});
    defineTapListener("tab-ticketing",function(){changePage("page-ticketing");});
    defineTapListener("tab-home",function(){changePage("page-home");});
    defineTapListener("mobile-menu-toggler",function(event){toggleMobileMenu(event);});
    defineTapListener("login-assistance-button",function(){popup("login-ask-assistance", false, false, false);});
    defineTapListener("login-register-button",function(){popup("login-register", false, false, false);});
    defineTapListener("recheck-required-gps",function(){
        hardwareAvailability.checkGpsPermission();
        hardwareAvailability.checkGpsEnabled();
    });
    defineTapListener("resolve-required-gps-enabling",function(){
        hardwareAvailability.checkGpsPermission();
        hardwareAvailability.checkGpsEnabled();
        hardwareAvailability.requestGpsEnable();
    });
    defineTapListener("resolve-required-gps-always-denied",function(){
        hardwareAvailability.checkGpsPermission();
        hardwareAvailability.checkGpsEnabled();
        hardwareAvailability.requestGpsPermissionWhenAlwaysDenied();
    });
    defineTapListener("resolve-required-gps-denied",function(){
        hardwareAvailability.checkGpsPermission();
        hardwareAvailability.checkGpsEnabled();
        hardwareAvailability.requestGpsPermission();
    });
    defineTapListener("qr-scanner-close",qrScanner.close);
    defineTapListener("qr-scanner-change-cam",qrScanner.changeCam);
    defineTapListener("qr-scanner-turn-off-flash",function(){qrScanner.turnOffFlash(null);});
    defineTapListener("qr-scanner-turn-on-flash",function(){qrScanner.turnOnFlash();});
    defineTapListener("home-btn-search",function(){popup("search", true, false, false);});
    defineTapListener("home-anomalies-counter",function(){changePage("page-anomalies");});
    defineTapListener("home-btn-ticketing",function(){changePage("page-ticketing");});
    defineTapListener("home-btn-anomalies",function(){changePage("page-anomalies");});
    defineTapListener("home-btn-maintenance",function(){changePage("page-maintenance");});
    defineTapListener("home-btn-manage-asset",function(){changePage("page-assets");});
    defineTapListener("home-btn-calendar",function(){changePage("page-calendar");});
    defineTapListener("home-btn-validate-intervention",function(){popup("validate-intervention-otp", false, false, false);});
    defineTapListener("home-qr-scanner-button",getQrCodeDataByScanningQrCode);
    defineTapListener("map-popup-button",function(){popup("map-viewer", false, false, false);});
    defineTapListener("action-button-create-asset",function(){popup("mobile-create-asset-menu", false, false, false);});
    defineTapListener("action-button-create-anomaly",function(){createAnomalyPopup(false);});
    defineTapListener("action-button-create-ticket",function(){createTicketPopup(false);});
    defineTapListener("action-button-create-contract",function(){popup("create-contract", true, false, false);});
    defineTapListener("action-button-view-anomalies-archive",function(){popup("anomaly-filters",false,false,false)});
    defineTapListener("action-button-close-anomalies-archive",function(){popup("anomaly-filters",false,false,false)});
    defineTapListener("action-button-view-tickets-archive",function(){popup("ticket-filters",false,false,false);});
    defineTapListener("action-button-create-intervention",function(){popup("create-intervention", false, false, false);});
    defineTapListener("action-button-close-vdr-trash",function(){closeDataRoomTrash(false);});
    defineTapListener("action-button-close-tickets-archive",function(){popup("ticket-filters",false,false,false);});
    defineTapListener("confirm-disconnection-button",function(){closePopup();ajaxLogout();});
    defineTapListener("cancel-disconnection-button",closePopup);
    defineTapListener("do-not-finalize-installments-button",closePopup);
    defineTapListener("do-not-finalize-consumptives-button",closePopup);
    defineTapListener("close-help-year-period-popup-button",closePopup);
    defineTapListener("close-help-ticket-assets-popup-button",closePopup);
    defineTapListener("cancel-create-intervention-popup-button-1",closePopup);
    defineTapListener("close-merge-interventions-popup-button",closePopup);
    defineTapListener("close-split-interventions-popup-button",closePopup);
    defineTapListener("cancel-delete-document-popup-button",closePopup);
    defineTapListener("close-help-password-strenght-popup-button",closePopup);
    defineTapListener("close-private-documents-links-popup-button",closePopup);
    defineTapListener("close-share-public-document-link-by-mail-popup-button-1",closePopup);
    defineTapListener("close-share-public-document-link-by-mail-popup-button-2",closePopup);
    defineTapListener("close-public-documents-links-popup-button-1",closePopup);
    defineTapListener("close-public-documents-links-popup-button-2",closePopup);
    defineTapListener("close-private-filters-link-popup-button",closePopup);
    defineTapListener("close-validate-intervention-popup-button",closePopup);
    defineTapListener("close-change-password-popup-button",closePopup);
    defineTapListener("close-confirm-unlink-company-from-license-popup-button",closePopup);
    defineTapListener("close-create-company-linked-to-license-popup-button",closePopup);
    defineTapListener("close-should-use-qr-popup-button",closePopup);
    defineTapListener("close-confirm-refuse-intervention-popup-button",closePopup);
    defineTapListener("change-competence-area-back-button",closePopup);
    defineTapListener("create-intervention-step-2-back-button",function(){closePopup();popup('create-intervention',false,false,false);});
    defineTapListener("create-intervention-step-3-back-button",function(){closePopup();popup('create-intervention-step-2',false,false,false);});
    defineTapListener("cancel-create-anomaly-button",closePopup);
    defineTapListener("cancel-ignore-anomaly",closePopup);
    defineTapListener("cancel-create-ticket-button",closePopup);
    defineTapListener("cancel-delete-anomaly-button",closePopup);
    defineTapListener("cancel-approve-anomaly-button",closePopup);
    defineTapListener("confirm-ignore-anomaly",function(){closePopup();ajaxIgnoreAnomaly();});
    defineTapListener("create-contract-page-2-back-button",function(){prevContactFormPage("form-page-2");});
    defineTapListener("create-contract-page-3-back-button",function(){prevContactFormPage("form-page-3");});
    defineTapListener("create-contract-page-4-back-button",function(){prevContactFormPage("form-page-4");});
    defineTapListener("create-contract-page-5-back-button",function(){prevContactFormPage("form-page-5");});
    defineTapListener("confirm-approve-anomaly-button",function(){closePopup();ajaxApproveAnomaly();});
    defineTapListener("confirm-delete-anomaly-button",function(){closePopup();ajaxDeleteAnomaly();});
    defineTapListener("cancel-cancel-ticket-button",closePopup);
    defineTapListener("confirm-cancel-ticket-button",function(){
        closePopup();
        changeTicketState(
            document.getElementById("ticket-id-to-cancel").value,
            "Annullato", 
            "Annulla ticket", 
            null, 
            true
        );
    });
    defineTapListener("cancel-edit-anomaly-button",closePopup);
    defineTapListener("cancel-private-document-link-button",closePopup);
    defineTapListener("confirm-buildings-group-deletion-button",ajaxDeleteBuildingsGroup);
    defineTapListener("confirm-building-deletion-button",ajaxDeleteBuilding);
    defineTapListener("confirm-site-deletion-button",ajaxDeleteSite);
    defineTapListener("assign-qr-no-internet-cancel-button",function(){closePopup();changePage("page-home");});
    defineTapListener("assign-qr-no-internet-new-asset-button",function(){popup("add-asset-for-qr-code", false, false, false);});
    defineTapListener("assign-qr-no-internet-existing-asset-button",searchAssetForQrCode);
    defineTapListener("qr-preset-big-button",function(){setQrPreset("big");});
    defineTapListener("qr-preset-medium-button",function(){setQrPreset("medium");});
    defineTapListener("qr-preset-small-button",function(){setQrPreset("small");});
    defineTapListener("qr-preset-micro-button",function(){setQrPreset("micro");});
    defineTapListener("cancel-comment-activity-on-asset-execution-button",checkNoteAndClose);
    defineTapListener("comment-activity-on-asset-execution-create-anomaly-button",createAnomalyFromInterventionActivity);
    defineTapListener("export-interventions-button",downloadInterventionsReport);
    defineTapListener("cancel-delete-user-button",closePopup);
    defineTapListener("cancel-block-user-button",closePopup);
    defineTapListener("cancel-unblock-user-button",closePopup);
    defineTapListener("cancel-invalidate-user-button",closePopup);
    defineTapListener("manage-company-licenses-add-license-button",function(){popup("add-company-license", false, false, false);});
    defineTapListener("import-asset-button-mobile",function(){popup("upload-asset", true, false, false);});
    defineTapListener("executing-intervention-last-asset-completed-close-intervention-button",function(){closePopup();closePopup();markInterventionAsEnded();});
    defineTapListener("executing-intervention-last-asset-completed-scan-qr-button",function(){closePopup();closePopup();scanQrCodeForInterventionExecution();});
    defineTapListener("executing-intervention-last-asset-completed-pick-from-list-button",function(){closePopup();closePopup();});
    defineTapListener("executing-intervention-asset-completed-scan-qr-button",function(){closePopup();closePopup();scanQrCodeForInterventionExecution();});
    defineTapListener("executing-intervention-asset-completed-pick-from-list-button",function(){closePopup();closePopup();});
    defineTapListener("cancel-add-executor-closing-intervention-button",closePopup);
    defineTapListener("cancel-close-intervention-button",closePopup);
    defineTapListener("verify-intervention-data-next-button",function(){
        closePopup();
        closePopup();
        closePopup();
        selectIntervention(selectedIntervention);
        popup("request-intervention-validation", false, false, false);
    });
    defineTapListener("your-company-info-button",yourCompanyPopup.init);
    defineTapListener("your-company-users-button",yourCompanyUsersPopup.init);
    defineTapListener("cancel-confirm-cancel-intervention-button",closePopup);
    defineTapListener("view-privacy-info-button",function(){window.location.href="https://dps.srl/privacy?from=clasterfm";});
    defineTapListener("view-terms-info-button",function(){window.location.href="https://claster.it/terms";});
    defineTapListener("user-popup-change-password-button",function(){popup("change-password", true, false, false);});
    defineTapListener("cancel-add-asset-to-intervention-button",closePopup);
    defineTapListener("navbar-button-select-global-company-filter",function(){popup("select-global-company-filter", false, false, false);});
    defineTapListener("navbar-button-your-company",function(){popup("your-company", false, false, false);});
    defineTapListener("navbar-button-permissions",function(){popup("permissions", false, false, false);});
    defineTapListener("navbar-button-view-current-user-data",viewCurrentUserData);
    defineTapListener("navbar-button-sign-out",function(){popup("exit-confirm", false, false, false);});
    defineTapListener("intervention-back-btn",ui.pageBack);
    defineTapListener("validate-intervention-button",validateIntervention);
    defineTapListener("manage-intervention-button",function(){popup("manage-intervention", false, false, false);});
    defineTapListener("action-button-edit-intervention",function(){popup("manage-intervention", false, false, false);});
    defineTapListener("send-intervention-mail-button",function(){popup("send-intervention-mail-notifications", false, false, false);});
    defineTapListener("send-intervention-reminder-button",function(){popup("send-intervention-reminder", false, false, false);});
    defineTapListener("view-intervention-log-button",ajaxGetInterventionEvents);
    defineTapListener("start-intervention-button",startIntervention);
    defineTapListener("export-fire-log-book-button",showAvailableFireLogBooks);
    defineTapListener("request-validation-for-intervention-button",function(){popup("request-intervention-validation", false, false, false);});
    defineTapListener("export-customer-satisfaction-report-button",showAvailableCustomerSatisfactionReportButtons);
    defineTapListener("data-room-multiple-selection",toggleDataRoomMultipleSelection);
    defineTapListener("data-room-view-selected",function(){popup("document-info", false, false, false);});
    defineTapListener("exit-data-room-trash-button",function(){closeDataRoomTrash(false);});
    defineTapListener("upload-document-button",function(){popup("upload-document", false, false, false);});
    defineTapListener("enter-data-room-trash-button",function(){openDataRoomTrash(false);});
    defineTapListener("initial-view-vdr-documents",function(){ajaxReloadDataRoomFilters();});
    defineTapListener("intervention-execution-scan-qr",scanQrCodeForInterventionExecution);
    defineTapListener("close-intervention-button",markInterventionAsEnded);
    defineTapListener("close-intervention-correction-ended-button",function(){popup("end-intervention", false, false, false);});
    defineTapListener("execute-intervention-back-button",ui.pageBack);
    defineTapListener("remove-activity-from-intervention-button",function(){popup("delete-intervention-activity", false, false, false);});
    defineTapListener("intervention-activity-page-back-button",ui.pageBack);
    defineTapListener("create-intervention-button",function(){popup("create-intervention", false, false, false);});
    defineTapListener("annual-report-button",openAnnualInterventionReport);
    defineTapListener("go-to-today-month-calendar",goToTodayFromMonthCalendar);
    defineTapListener("go-to-today-year-calendar",goToTodayFromYearCalendar);
    defineTapListener("create-asset-button",popupCreateAsset);
    defineTapListener("annual-report-button-2",openAnnualInterventionReport);
    defineTapListener("create-contract-button",function(){popup("create-contract", true, false, false);});
    defineTapListener("import-asset-button",function(){popup('upload-asset', true, false, false);});
    defineTapListener("create-intervention-button-year",function(){popup("create-intervention", false, false, false);});
    defineTapListener("create-anomaly-from-asset-btn",createAnomalyFromAsset);
    defineTapListener("create-ticket-from-asset-btn",createTicketFromAsset);
    defineTapListener("page-asset-details-delete-button",function(){popup("confirm-asset-instances-deletion", false, false, false);});
    defineTapListener("action-button-delete-asset",function(){popup("confirm-asset-instances-deletion", false, false, false);});
    defineTapListener("page-asset-details-edit-button",function(){popup("edit-asset-instance", false, false, false);});
    defineTapListener("action-button-edit-asset",function(){popup("edit-asset-instance", false, false, false);});
    defineTapListener("edit-asset-tree-button",editAssetTreePopup.open);
    defineTapListener("add-asset-relation-btn",function(){
        searchAssetPopup.open(
            function(assetId){
                addAssetRelationToAsset(assetId);
            },
            "Indicare con quale asset creare una relazione con l'asset corrente"
        );
    });
    defineTapListener("manage-company-edit-info-button",popupEditSelectedSupplier);
    defineTapListener("tickets-archive-back-btn",function(){popup("ticket-filters");});
    defineTapListener("create-ticket-btn",function(){createTicketPopup(false,false);});
    defineTapListener("refresh-tickets-btn",function(){ticketingPage.manualRefresh();});
    defineTapListener("tickets-archive-btn",function(){popup("ticket-filters");});
    defineTapListener("anomalies-archive-back-btn",function(){popup("anomaly-filters");});
    defineTapListener("create-anomaly-btn",function(){createAnomalyPopup(false);});
    defineTapListener("refresh-anomalies-btn",function(){anomaliesPage.manualRefresh();});
    defineTapListener("anomalies-archive-btn",function(){popup("anomaly-filters");});
    defineTapListener("edit-site-button",function(){loadSiteEditor();});
    defineTapListener("edit-building-button",function(){loadBuildingEditor();});
    defineTapListener("edit-buildings-group-button",function(){loadBuildingGroupEditor();});
    defineTapListener("delete-buildings-group-button",function(){deleteBuildingsGroup();});
    defineTapListener("delete-building-button",deleteBuilding);
    defineTapListener("delete-site-button",deleteSite);
    defineTapListener("send-intervention-mail-to-site-manager-confirm-btn",sendInterventionMailToSiteManager);
    defineTapListener("send-intervention-mail-to-site-manager-cancel-btn",closePopup);
    defineTapListener("send-intervention-mail-to-executor-cancel-btn",closePopup);
    defineTapListener("send-intervention-mail-to-executor-confirm-btn",sendInterventionMailToExecutor);
    defineTapListener("send-intervention-mail-to-custom-recipient-cancel-btn",closePopup);
    defineTapListener("tab-user",toggleLoggedInUserMenu);
    defineTapListener("filter-button-maintenance",function(){popup("maintenance-filters", false, false, false);});
    defineTapListener("filter-button-month-calendar",function(){popup("month-calendar-filters", false, false, false);});
    defineTapListener("filter-button-year-calendar",function(){popup("year-calendar-filters", false, false, false);});
    defineTapListener("filter-button-assets",function(){popup("assets-filters", false, false, false);});
    defineTapListener("filter-button-vdr",function(){popup("vdr-filters", false, false, false);});
    defineTapListener("send-intervention-mail-notitification-to-site-manager-btn",function(){popup("confirm-intervention-notification-to-site-manager", false, false, false);});
    defineTapListener("send-intervention-mail-notitification-to-executor-btn",function(){popup("confirm-intervention-notification-to-executor", false, false, false);});
    defineTapListener("send-intervention-mail-notitification-to-custom-recipient-btn",function(){ popup("confirm-intervention-custom-notification", false, false, false); });
    defineTapListener("send-intervention-reminder-notification-btn",function(){ ajaxSendReminder("notification"); });
    defineTapListener("send-intervention-reminder-mail-btn",function(){ ajaxSendReminder("mail"); });
    defineTapListener("buildings-create-button",function(){ popup("create-from-building",false,false,false); });
    defineTapListener("action-button-create-for-building",function(){ popup("create-from-building",false,false,false); });
    defineTapListener("edit-intervention-team-management-button",interventionTeamManagement);
    defineTapListener("edit-intervention-move-intervention-button",moveIntervention);
    defineTapListener("edit-intervention-order-number-button",editInterventionOrderNumber);
    defineTapListener("edit-intervention-fire-log-book-number-button",editInterventionFireLogBookNumber);
    defineTapListener("generate-documents-private-share-links-btn",generatePrivateLinksForSelectedDocuments);
    defineTapListener("generate-document-public-share-link-btn",generatePublicLinkForSelectedDocument);
    defineTapListener("generate-document-private-share-link-btn",generatePrivateLinkForSelectedDocument);
    defineTapListener("generate-documents-public-share-links-btn",generatePublicLinksForSelectedDocuments);
    defineTapListener("confirm-finalize-estimates-btn",ajaxFinalizeEstimates);
    defineTapListener("download-intervention-year-report",ajaxDownloadInterventionYearReport);
    defineTapListener("page-annual-interventions-report-back-btn",function(){ changePage("page-calendar"); });
    defineTapListener("create-common-unit-button",function(){ popup("create-common-unit", true, false, false); });
    defineTapListener("create-property-unit-button",function(){ popup("create-property-unit", true, false, false); });
    defineTapListener("create-building-button",function(){ popup("create-building", true, false, false); });
    defineTapListener("create-buildings-group-button",function(){ popup("create-buildings-group", true, false, false); });
    defineTapListener("manage-company-create-supplier-btn",popupCreateSupplier);
    defineTapListener("page-asset-details-back-btn",ui.pageBack);
    defineTapListener("view-month-calendar-btn",viewMonthCalendar);
    defineTapListener("year-calendar-late-interventions-counter-btn",function(){ popup("year-late-interventions", false, false, false); });
    defineTapListener("view-year-calendar-btn",viewYearCalendar);
    defineTapListener("calendar-late-interventions-counter-btn",function(){ popup("late-interventions", false, false, false); });
    defineTapListener("generate-qr-cut-lines-btn",generateQrCutLines);
    defineTapListener("generate-qr-btn",generateQr);
    defineTapListener("confirm-finalize-estimates-btn",ajaxFinalizeEstimates);
    defineTapListener("add-estimate-btn",function(){ popup("add-estimate", true, false, false); });
    defineTapListener("view-estimates-btn",function(){ popup("view-estimates", true, false, false); });
    defineTapListener("finalize-estimates-btn",function(){ popup("finalize-estimates", true, false, false); });
    defineTapListener("delete-estimates-btn",ajaxDeleteEstimates);
    defineTapListener("add-invoice-issued-by-company-btn",function(){ searchOrAddCompany("add-invoice-issued-by-company-name","add-invoice-issued-by-company-id"); });
    defineTapListener("create-company-btn",function(){ closePopup(); popupCreateCustomer(); });
    defineTapListener("add-searched-company-button",registerSearchCompanyResult);
    defineTapListener("add-company-type-is-private-btn",function(){
        setNewEntityTypePrivate();
        togglePseudoRadioByClass(
            "add-company-type",
            document.getElementById("add-company-type-is-private-btn")
        );
    });
    defineTapListener("add-company-type-is-company-btn",function(){
        setNewEntityTypeCompany();
        togglePseudoRadioByClass(
            "add-company-type",
            document.getElementById("add-company-type-is-company-btn")
        );
    });
    defineTapListener("create-ticket-scan-asset-qr",function(){
        if(document.getElementById("create-ticket-scan-asset-qr").disabled===false) {
            scanAssetQrCodeToTaggle(createTicketInvolvedAssetsTaggle);
        }
    });
    defineTapListener("create-anomaly-scan-asset-qr",function(){
        if(document.getElementById("create-anomaly-scan-asset-qr").disabled===false) {
            scanAssetQrCodeToTaggle(createAnomalyInvolvedAssetsTaggle);
        }
    });
    defineTapListener("buildings-group-owner-matches-administrator-btn",buildingsGroupOwnerAdminMatchChanged);
    defineTapListener("property-unit-owner-btn",function(){ searchOrAddCompany("property-unit-owner-name","property-unit-owner-id"); });
    defineTapListener("create-ticket-add-company-selector-button",function(){ assignTicketAddCompanySelector("create-ticket"); });
    defineTapListener("toggle-asset-internal-number-button",toggleAddAssetAutomaticInternalNumber);
    defineTapListener("add-subcategory-while-creating-asset-button",addSubcategoryRowToAssetFromCreationPopup);
    defineTapListener("send-push-notifications-button",function(){ ajaxGetUsers();popup("send-push-notifications", true, false, false); });
    defineTapListener("add-activity-for-subcategory-button",function(){ popup("add-activity-for-subcategory", true, false, false); });
    defineTapListener("cancel-add-subcategory-additional-field-button",closePopup);
    defineTapListener("cancel-manage-subcategory-additional-fields-button",closePopup);
    defineTapListener("add-subcategory-additional-fields-button",function(){ popup("add-subcategory-additional-field", false, false, false); });
    defineTapListener("annual-interventions-report-next",annualInterventionReportFollowingYear);
    defineTapListener("annual-interventions-report-prev",annualInterventionReportPreviousYear);
    defineTapListener("request-remote-intervention-validation-btn",ajaxRequestInterventionValidation);
    defineTapListener("validate-intervention-via-qr-btn",requestInterventionValidation);
    defineTapListener("validate-intervention-confirm-btn",ajaxValidateIntervention);
    defineTapListener("get-validate-intervention-otp-btn",ajaxGetValidateInterventionOtp);
    defineTapListener("enable-intervention-button",ajaxEnableIntervention);
    defineTapListener("disable-intervention-button",ajaxDisableIntervention);
    defineTapListener("cancel-finalize-estimates-btn",dontFinalizeEstimates);
    defineTapListener("add-employee-closing-intervention-popup-button",interventionManager.addEmployeeClosingInterventionPopup);
    defineTapListener("end-intervention-add-materials-row-button",endInterventionPopup.addMaterialsRow);
    defineTapListener("abort-asset-deletion-button",abortAssetInstancesDeletion);
    defineTapListener("edit-asset-add-subcategory-row-button",addSubcategoryRowToAssetFromEditPopup);
    defineTapListener("help-ticket-assets-button",function(){ popup("help-ticket-assets",false,false,false); });
    defineTapListener("edit-asset-installation-date-calendar-button",function(){document.getElementById("edit-asset-installation-date").click();});
    defineTapListener("edit-asset-expiration-date-calendar-button",function(){document.getElementById("edit-asset-expiration").click();});
    defineTapListener("edit-asset-relations-close-popup-button",closePopup);
    defineTapListener("toggle-activity-for-subcategory-law-requirement-button",toggleAddActivityForSubcLawRequirementsOptions);
    defineTapListener("toggle-edit-activity-for-subcategory-law-requirement-button",toggleEditActivityForSubcLawRequirementsOptions);
    defineTapListener("shield-meaning-help-close-popup-button",closePopup);
    defineTapListener("add-site-to-planimetry-button",function(){ popup("add-site-to-building-map",true,false,false); });
    defineTapListener("assign-ticket-add-company-selector-button",function(){ assignTicketAddCompanySelector("assign-ticket"); });
    defineTapListener("ticket-intervention-start-date-calendar-button",function(){document.getElementById('ticket-intervention-date-start').click();});
    defineTapListener("manage-categories-add-category-group-button",function(){ popup('add-category-group',true,false,false); });
    defineTapListener("set-excluded-subcategory-button",function(){ popup('set-excluded-subcategory',true,false,false); });
    defineTapListener("manage-categories-add-category-button",function(){ popup('add-category',true,false,false); });
    defineTapListener("manage-categories-add-subcategory-button",function(){ popup('add-subcategory',true,false,false); });
    defineTapListener("close-outcome-popup-button",closePopup);
    defineTapListener("close-error-popup-button",closePopup);
    defineTapListener("edit-category-group-popup-delete-category-group-button",deleteCategoryGroup);
    defineTapListener("edit-category-group-delete-category-button",deleteCategory);
    defineTapListener("edit-subcategory-delete-subcategory-button",deleteSubcategory);
    defineTapListener("close-delete-intervention-activity-popup-button",closePopup);
    defineTapListener("export-asset-field-for-subcategory-report-button",function(){ exportAssetFieldForSubcategoryReportPopup.init(); });
    defineTapListener("asset-replacement-scan-qr-button",scanQrForAssetReplacement);
    defineTapListener("asset-replacement-scan-no-qr-button",function(){assetReplacementNewAssetPopup.open(null);});
    defineTapListener("remove-asset-filter-btn",function(){removeAssetNavFilters();closePopup();});
    defineTapListener("set-asset-filter-btn",function(){countAssetNavAppliedFilters();closePopup();});
    defineTapListener("action-button-remove-asset-filter",function(){removeAssetNavFilters();closePopup();});
    defineTapListener("action-button-set-asset-filter",function(){countAssetNavAppliedFilters();closePopup();});
    defineTapListener("remove-vdr-filter-btn",function(){clearDataRoomFilters();closePopup();});
    defineTapListener("set-vdr-filter-btn",function(){countVdrAppliedFilters();closePopup();});
    defineTapListener("action-button-remove-vdr-filter",function(){clearDataRoomFilters();closePopup();});
    defineTapListener("action-button-set-vdr-filter",function(){countVdrAppliedFilters();closePopup();});
    defineTapListener("remove-month-calendar-filter-btn",function(){removeCalendarsFilters();closePopup();});
    defineTapListener("set-month-calendar-filter-btn",function(){countMonthCalendarAppliedFilters();closePopup();});
    defineTapListener("action-button-remove-month-calendar-filter",function(){removeCalendarsFilters();closePopup();});
    defineTapListener("action-button-set-month-calendar-filter",function(){countMonthCalendarAppliedFilters();closePopup();});
    defineTapListener("remove-year-calendar-filter-btn",function(){removeCalendarsFilters();closePopup();});
    defineTapListener("set-year-calendar-filter-btn",function(){countYearCalendarAppliedFilters();closePopup();});
    defineTapListener("action-button-remove-year-calendar-filter",function(){removeCalendarsFilters();closePopup();});
    defineTapListener("action-button-set-year-calendar-filter",function(){countYearCalendarAppliedFilters();closePopup();});
    defineTapListener("remove-maintenance-filter-btn",function(){removeMaintenanceFilters();closePopup();});
    defineTapListener("set-maintenance-filter-btn",function(){countMaintenanceAppliedFilters();closePopup();});
    defineTapListener("action-button-remove-maintenance-filter",function(){removeMaintenanceFilters();closePopup();});
    defineTapListener("action-button-set-maintenance-filter",function(){countMaintenanceAppliedFilters();closePopup();});
    defineTapListener("cancel-export-asset-field-for-subcategory-report-button",closePopup);
    defineTapListener("search-asset-popup-submit-button",searchAssetPopup.resultSelectedCallback);
    defineTapListener("action-button-save-asset-edit",function(){
        submitForm(document.getElementById("edit-asset-instance-form"), true);
    });
    defineTapListener("search-document-popup-submit-button",searchDocumentPopup.resultSelectedCallback);
    defineTapListener("add-asset-relation-swap-asset",addAssetRelationSwapAssets);
    defineTapListener("add-asset-relation-popup-submit-button",ajaxCreateAssetRelation);
    defineTapListener("clear-asset-document-manual",function(){removeAssetDocumentFromPicker("asset-document-manual");});
    defineTapListener("clear-asset-document-project",function(){removeAssetDocumentFromPicker("asset-document-project");});
    defineTapListener("clear-asset-document-conformity-declaration",function(){removeAssetDocumentFromPicker("asset-document-conformity-declaration");});
    defineTapListener("pick-asset-qr",function(){
        editAssetScanQR();
    });
    defineTapListener("clear-asset-qr",function(){
        document.getElementById("edit-asset-qr-id").value="";
    });
    defineTapListener("send-chat-message-button",chatPopup.sendMessage);
    defineTapListener("upload-estimate-from-chat-button",function(){uploadEstimateForTicketPopup.init(null,null,null,null);});

    defineTapListener("view-loading-log-button",function(){
        document.getElementById("loading-log-content").innerHTML=loadingLog;
        popup('loading-log',true,false,false);
    });
    defineTapListener("view-competence-area-settings-button",openCompetenceAreaSettings);

    document.getElementById("new-chat-message").onkeydown = function(e){
        if(e.key === "Enter") {
            chatPopup.sendMessage();
            e.preventDefault();
        }
    };

    dom.addClass(document.getElementById("navbar-left-zone"),"showable");
    document.getElementById('end-intervention-end-date').value = calCurrentDate.toDateInputValue();
    outcomePopup=document.getElementById("outcome-popup-content");
    ticketSiteSelect=document.getElementById("ticket-site");
    ticketCategorySelect=document.getElementById("ticket-category");
    ticketSubcategorySelect=document.getElementById("ticket-subcategory");

    ui.pickerPopupButton.initialize({
        id:"end-intervention-end-time",
        components:ui.pickerPopupButton.presets.timePickerEnd
    });
    ui.pickerPopupButton.initialize({
        id:"end-intervention-transfer-time",
        components:ui.pickerPopupButton.presets.durationPickerComponents
    });
    ui.pickerPopupButton.initialize({
        id:"end-intervention-work-time",
        components:ui.pickerPopupButton.presets.durationPickerComponents
    });
    ui.pickerPopupButton.initialize({
        id:"create-intervention-start-time",
        components:ui.pickerPopupButton.presets.timePickerFastStart
    });
    ui.pickerPopupButton.initialize({
        id:"create-intervention-end-time",
        components:ui.pickerPopupButton.presets.timePickerFastEnd
    });

    ui.pickerPopupButton.initialize({
        id:"move-intervention-start-time",
        components:ui.pickerPopupButton.presets.timePickerFastStart
    });
    ui.pickerPopupButton.initialize({
        id:"move-intervention-end-time",
        components:ui.pickerPopupButton.presets.timePickerFastEnd
    });
    ui.pickerPopupButton.initialize({
        id:"split-intervention-1-start-time",
        components:ui.pickerPopupButton.presets.timePickerFastStart
    });
    ui.pickerPopupButton.initialize({
        id:"split-intervention-2-start-time",
        components:ui.pickerPopupButton.presets.timePickerFastStart
    });
    ui.pickerPopupButton.initialize({
        id:"split-intervention-1-end-time",
        components:ui.pickerPopupButton.presets.timePickerFastEnd
    });
    ui.pickerPopupButton.initialize({
        id:"split-intervention-2-end-time",
        components:ui.pickerPopupButton.presets.timePickerFastEnd
    });

    new Pikaday(
    {
        field: document.getElementById("add-certification-for-user-expiration"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("disable-activity-for-subcategory-date"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("ticket-intervention-date-start"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    ticketDayPikaday = new Pikaday(
    {
        field: document.getElementById("create-ticket-intervention-date-start"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("create-intervention-request-intervention-date-start"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("contract-date-start"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("contract-date-end"),
        firstDay: 1,
        minDate: new Date(),
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("edit-asset-installation-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("edit-asset-expiration"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("add-law-date-start"),
        minDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("create-accounting-year-start-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("generate-installments-start-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("create-accounting-year-end-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("register-invoice-payment-date"),
        maxDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    var moveInterventionDatePicker=document.getElementById("move-intervention-date");
    new Pikaday(
    {
        field: moveInterventionDatePicker,
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    moveInterventionDatePicker.addEventListener("change",function() {
        checkHolidayForDate(moveInterventionDatePicker.value);
    });
    new Pikaday(
    {
        field: document.getElementById("merge-interventions-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    var splitInterventionsDatePicker1=document.getElementById("split-interventions-date-1");
    new Pikaday(
    {
        field: splitInterventionsDatePicker1,
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    splitInterventionsDatePicker1.addEventListener("change",function() {
        checkHolidayForDate(splitInterventionsDatePicker1.value);
    });
    var splitInterventionsDatePicker2=document.getElementById("split-interventions-date-2");
    new Pikaday(
    {
        field: splitInterventionsDatePicker2,
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    splitInterventionsDatePicker2.addEventListener("change",function() {
        checkHolidayForDate(splitInterventionsDatePicker2.value);
    });
    var createInterventionStartDatePicker=document.getElementById("create-intervention-start-date");
    createInterventionPopup.createInterventionStartPikaday = new Pikaday(
    {
        field: createInterventionStartDatePicker,
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    createInterventionStartDatePicker.addEventListener("change",function() {
        checkHolidayForDate(createInterventionStartDatePicker.value);
    });

    new Pikaday(
    {
        field: document.getElementById("register-installment-date"),
        maxDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("create-employee-birthday"),
        trigger: document.getElementById("create-employee-birthday-container"),
        maxDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("add-invoice-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("upload-approved-estimate-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("upload-order-form-date"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("end-intervention-end-date"),
        trigger: document.getElementById("end-intervention-end-date-container"),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    var viewGeolocationDataStartDate=document.getElementById("view-geolocation-data-start-date");
    var viewGeolocationDataEndDate=document.getElementById("view-geolocation-data-end-date");
    new Pikaday(
    {
        field: viewGeolocationDataStartDate,
        maxDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: viewGeolocationDataEndDate,
        maxDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    new Pikaday(
    {
        field: document.getElementById("new-company-certification-end-validity"),
        minDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    pikadays.endInterventionWorkProsecution = new Pikaday(
    {
        field: document.getElementById("end-intervention-work-prosecution-date"),
        trigger: document.getElementById("end-intervention-work-prosecution-date-container"),
        minDate: new Date(),
        firstDay: 1,
        format: 'DD/MM/YYYY'
    });
    //TODO: recover original formattable toString pikaday function that I had broke!
    viewGeolocationDataStartDate.onchange = ajaxViewGeolocationData;
    viewGeolocationDataEndDate.onchange = ajaxViewGeolocationData;

    scroll.loadAll();

    document.querySelectorAll(".top-first-col.logo").forEach(function(logoHolder){
        var now = new Date();
        //From 8th december to 26th december
        if(now.getMonth()===11 && now.getDate()>7 && now.getDate()<27){
            logoHolder.innerHTML="<img src='src/img/wintericon.png' alt='Claster Christmas logo'>";
        }
        else{
            logoHolder.innerHTML="<img src='src/img/claster-corner-logo.png' alt='Claster logo'>";
        }
    });
}

var scroll = {
    scrollOptions: {
        mouseWheel: true,
        scrollbars: false,
        bounce: false,
        click: true,
        preventDefaultException: {
          tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|LABEL)$/ // by default it's missing "label", which should be there for proper use of checkbox and radio inputs
        }
    },
    scrollOptionsWithoutClick: {
        mouseWheel: true,
        scrollbars: false,
        bounce: false,
        click: false,
        tap:true,
        preventDefaultException: {
          tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|LABEL)$/ // by default it's missing "label", which should be there for proper use of checkbox and radio inputs
        }
    },
    //The elements MUST have a child that will contain the scrolled content
    smallViewportScrolls:{
        "home-page":"#page-home",
        "tickets":"#page-ticketing",
        "ticket-details":"#ticket-info-popup .popup-content",
        "anomalies":"#page-anomalies",
        "anomaly-details":"#page-anomalies",
        "maintenance":"#page-maintenance",
        "vdr":"#page-data-room",
        "vdr-details":"#document-info-popup .popup-content",
        "buildings-tree":"#page-buildings",
        "building-details":"#page-buildings",
        "company-details":"#page-company",
        "company-employees":"#page-company",
        "contracts":"#page-contracts",
        "plan":"#page-plan",
        "interventions-report":"#page-annual-interventions-report",
        "execute-intervention-asset":"#activities-on-asset-popup .popup-content",
        "execute-intervention-assets-list":"#page-execute-intervention",
        "intervention-details":"#page-intervention",
        "intervention-activities":"#page-intervention",
        "intervention-activity-assets":"#page-intervention-activity",
        "intervention-activity-details":"#page-intervention-activity",
        "assets-list":"#page-assets",
        "assets-categories-filter":"#assets-filters-popup .popup-content",
        "asset-events":"#page-asset",
        "asset-details":"#page-asset",
        "asset-relations":"#page-asset",
        "calendar":"#page-calendar",
        "year-calendar":"#page-calendar-year",
        "search-popup":"#search-popup-results-wrapper",
        "safety-control":"#page-safety-control"
    },
    largeViewportScrolls:{
        "home-page":"#page-home .main-col",
        "tickets":"#page-ticketing .second-col",
        "ticket-details":"#page-ticketing .third-col",
        "anomalies":"#page-anomalies .second-col",
        "anomaly-details":"#page-anomalies .third-col",
        "maintenance":"#page-maintenance .third-col",
        "vdr":"#page-data-room .main-col",
        "vdr-details":"#data-room-details-col",
        "buildings-tree":"#page-buildings .second-col",
        "building-details":"#page-buildings .third-col",
        "company-details":"#page-company .second-col",
        "company-employees":"#page-company .third-col",
        "contracts":"#page-contracts .main-col",
        "plan":"#page-plan .main-col",
        "interventions-report":"#page-annual-interventions-report .main-col",
        "execute-intervention-asset":"#page-execute-intervention .second-col",
        "execute-intervention-assets-list":"#page-execute-intervention .third-col",
        "intervention-details":"#page-intervention .second-col",
        "intervention-activities":"#page-intervention .third-col",
        "intervention-activity-assets":"#page-intervention-activity .third-col",
        "intervention-activity-details":"#page-intervention-activity .second-col",
        "assets-list":"#assets-list",
        "assets-categories-filter":"#page-assets .second-col",
        "asset-events":"#page-asset .third-col",
        "asset-details":"#page-asset .second-col",
        "asset-relations":"#page-asset .fourth-col",
        "calendar":"#page-calendar .calendar-col",
        "year-calendar":"#page-calendar-year .calendar-col",
        "search-popup":"#search-popup-results-wrapper",
        "safety-control":"#page-safety-control .main-col"
    },
    activeScrolls:{},
    /*
     * On small viewports the scroll area is the entire page, not the columns,
     * so I have to destroy the scroll area set on columns
     */
    refreshByName: function(name){/*
        var scrollSelector;
        var toDestroyScrollSelector;
        if(isSmallViewport()){
            scrollSelector = scroll.smallViewportScrolls[name];
            toDestroyScrollSelector = scroll.largeViewportScrolls[name];
        }
        else {
            scrollSelector = scroll.largeViewportScrolls[name];
            toDestroyScrollSelector = scroll.smallViewportScrolls[name];
        }
        //The scroll area name is defined
        if(toDestroyScrollSelector!==scrollSelector){
            if(toDestroyScrollSelector !== undefined) {
                //There's something initialized for this scroll area
                if(scroll.activeScrolls[toDestroyScrollSelector]!==undefined) {
                    setTimeout(function () {
                        if(scroll.activeScrolls[toDestroyScrollSelector]!==undefined) {
                            scroll.activeScrolls[toDestroyScrollSelector].destroy();
                            dom.removeClass(document.querySelector(toDestroyScrollSelector), "scroll-content");
                            scroll.activeScrolls[toDestroyScrollSelector] = undefined;
                        }
                    }, 100);
                }
            }
        }
        //The scroll area name is defined
        if(scrollSelector !== undefined) {
            //There's something initialized for this scroll area
            if(scroll.activeScrolls[scrollSelector]!==undefined && scroll.activeScrolls[scrollSelector].refresh!==undefined) {
                setTimeout(function () {
                    if(scroll.activeScrolls[scrollSelector]!==undefined && scroll.activeScrolls[scrollSelector].refresh!==undefined) {
                        //scroll.activeScrolls[scrollSelector].refresh();
                    }
                }, 100);
            }
            else {
                var element = document.querySelector(scrollSelector);
                if(name!=="search-popup") {
                    //scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptions);
                }
                else {
                    //scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptionsWithoutClick);
                }
                dom.addClass(element,"scroll-content");
            }
        }*/
    },
    refreshPopupById: function (id, scrollToBottom) {/*
        var scrollSelector = "#"+id+"-popup .popup-content";
        var scrollObject = popupManager.popupScrolls[id];
        //There's something initialized for this scroll area
        if(scrollObject!==undefined && scrollObject.refresh!==undefined) {
            setTimeout(function () {
                scrollObject.refresh();
            }, 100);
        }
        else {
            var scrollArea = document.querySelector(scrollSelector);
            //popupManager.popupScrolls[id] = new IScroll(scrollArea,scroll.scrollOptions);
            dom.addClass(scrollArea,"scroll-content");
        }
        if(scrollToBottom){
            popupManager.popupScrolls[id].scrollTo(0, popupManager.popupScrolls[id].maxScrollY, 0);
        }*/
    },
    refreshAll: function() {/*
        var scrollSelectors;
        var toDestroyScrollSelectors;
        // Since scroll logic changes between small viewports and large viewports I have to destroy and create a
        // different scroll area entirely for each position
        if(isSmallViewport()) {
            scrollSelectors = scroll.smallViewportScrolls;
            toDestroyScrollSelectors = scroll.largeViewportScrolls;
        }
        else {
            scrollSelectors=scroll.largeViewportScrolls;
            toDestroyScrollSelectors = scroll.smallViewportScrolls;
        }
        Object.keys(toDestroyScrollSelectors).forEach(function(toDestroyScrollSelectorKey) {
            var toDestroyScrollSelector=toDestroyScrollSelectors[toDestroyScrollSelectorKey];
            if(scroll.activeScrolls[toDestroyScrollSelector]!==undefined) {
                scroll.activeScrolls[toDestroyScrollSelector].destroy();
                dom.removeClass(document.querySelector(toDestroyScrollSelector),"scroll-content");
                scroll.activeScrolls[toDestroyScrollSelector]=undefined;
            }
        });
        Object.keys(scrollSelectors).forEach(function(scrollSelectorKey) {
            if(scrollSelectorKey!=="search-popup") {
                var scrollSelector = scrollSelectors[scrollSelectorKey];
                if (scroll.activeScrolls[scrollSelector] !== undefined) {
                    setTimeout(function () {
                        scroll.activeScrolls[scrollSelector].refresh();
                    }, 100);
                }
                else {
                    var element = document.querySelector(scrollSelector);
                    if(scrollSelectorKey!=="search-popup") {
                        scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptions);
                    }
                    else {
                        scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptionsWithoutClick);
                    }
                    dom.addClass(element, "scroll-content");
                }
            }
        });
        //Refreshing also all the open popups' contents
        Object.keys(popupManager.popupScrolls).forEach(function(scrollSelectorKey) {
            if(scrollSelectorKey!=="search-popup"){
                var popupScroll = popupManager.popupScrolls[scrollSelectorKey];
                if(popupScroll.refresh!==undefined){
                    popupScroll.refresh();
                }
            }
        });*/
    },
    loadAll: function(){/*
        var scrollSelectors;
        if(isSmallViewport()){
            scrollSelectors=scroll.smallViewportScrolls;
        }
        else {
            scrollSelectors=scroll.largeViewportScrolls;
        }
        Object.keys(scrollSelectors).forEach(function(scrollSelectorKey) {
            if(scrollSelectorKey!=="search-popup") {
                var scrollSelector = scrollSelectors[scrollSelectorKey];
                if (scroll.activeScrolls[scrollSelector] !== undefined) {
                    setTimeout(function () {
                        console.log("refreshing scroll " + scrollSelector);
                        scroll.activeScrolls[scrollSelector].refresh();
                    }, 100);
                }
                else {
                    var element = document.querySelector(scrollSelector);
                    if(scrollSelectorKey!=="search-popup"){
                        scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptions);
                    }
                    else{
                        scroll.activeScrolls[scrollSelector] = new IScroll(element,scroll.scrollOptionsWithoutClick);
                    }
                    dom.addClass(element, "scroll-content");
                }
            }
        });*/
    }
};

document.addEventListener("keyup", function(e) {
    if(e.key !== undefined) {
        switch(e.key) {
            case "Escape":
                if((popupManager.getCurrentPopup())!=="create-contract") {
                    closePopup();
                }
                else {
                    errorToast("Tasto esc disabilitato in questo popup");
                }
                break;
            case "Control":
                ctrlPressed=false;
                break;
            case "Shift":
                shiftPressed=false;
                break;
        }
    }
    else { //Backward compatibility
        var code;
        if (e.which !== undefined) {
            code = e.which;
        } else if (e.charCode !== undefined) {
            code = e.charCode;
        } else if (e.keyCode !== undefined) {
            code = e.keyCode;
        }
        switch(code)
        {
            case KEYCODE_ESC:
                if(popupManager.getCurrentPopup()!=="create-contract") {
                    closePopup();
                }
                else {
                    errorToast("Tasto esc disabilitato in questo popup");
                }
                break;
            case KEYCODE_CTRL:
                ctrlPressed=false;
                break;
            case KEYCODE_SHIFT:
                shiftPressed=false;
                break;
        }
    }
});

document.addEventListener("keydown", function(e) {
    var focusedElementByClass=document.getElementsByClassName("focused")[0];
    var dropdownOptionsContainer, dropdownOptions, dropdownSelectedOption;
    var searchInput, keyEvent;
    if(e.key !== undefined) {
        switch(e.key) {
            case "Control":
                ctrlPressed = true;
                break;
            case "Shift":
                shiftPressed = true;
                break;
            case "ArrowDown":
            case "ArrowRight":
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-input")) {
                        dropdownOptionsContainer=document.getElementById(focusedElementByClass.id.replace("-text",""));
                        dom.removeClass(focusedElementByClass,"focused");
                        focusedElementByClass.blur();
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                }
                if(dropdownOptions)
                {
                    if(dropdownSelectedOption)
                    {
                        dom.removeClass(dropdownSelectedOption,"selected");
                        if(dropdownSelectedOption.nextSibling)
                        {
                            dom.addClass(dropdownSelectedOption.nextSibling,"selected");
                            dom.addClass(dropdownSelectedOption.nextSibling,"focused");
                            if(dropdownSelectedOption.previousSibling)
                                animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.previousSibling.offsetTop,dropdownSelectedOption.offsetTop,50,true);
                            else
                                animate(dropdownOptionsContainer,"scrollTop","",0,dropdownSelectedOption.offsetTop,50,true);
                        }
                        else
                        {
                            animate(dropdownOptionsContainer,"scrollTop","",dropdownOptions[dropdownOptions.length-1].offsetTop,0,50,true);
                            dom.addClass(dropdownOptions[0],"selected");
                            dom.addClass(dropdownOptions[0],"focused");
                        }
                    }
                    else
                    {
                        dom.addClass(dropdownOptions[0],"selected");
                        dom.addClass(dropdownOptions[0],"focused");
                    }
                }
                break;
            case "ArrowLeft":
            case "ArrowUp":
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-input"))
                    {
                        dropdownOptionsContainer=document.getElementById(focusedElementByClass.id.replace("-text",""));
                        dom.removeClass(focusedElementByClass,"focused");
                        focusedElementByClass.blur();
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                }
                if(dropdownOptions)
                {
                    if(dropdownSelectedOption)
                    {
                        dom.removeClass(dropdownSelectedOption,"selected");
                        if(dropdownSelectedOption.previousSibling)
                        {
                            dom.addClass(dropdownSelectedOption.previousSibling,"selected");
                            dom.addClass(dropdownSelectedOption.previousSibling,"focused");
                            if(dropdownSelectedOption.nextSibling)
                            {
                                if(dropdownSelectedOption.previousSibling.previousSibling)
                                    animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.nextSibling.offsetTop,dropdownSelectedOption.previousSibling.previousSibling.offsetTop,50,true);
                                else
                                    animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.nextSibling.offsetTop,dropdownSelectedOption.previousSibling.offsetTop,50,true);
                            }
                            else
                                animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.offsetTop,dropdownSelectedOption.previousSibling.offsetTop,50,true);
                        }
                        else
                        {
                            animate(dropdownOptionsContainer,"scrollTop","",0,dropdownOptions[dropdownOptions.length-1].offsetTop,50,true);
                            dom.addClass(dropdownOptions[dropdownOptions.length-1],"selected");
                            dom.addClass(dropdownOptions[dropdownOptions.length-1],"focused");
                        }
                    }
                    else
                    {
                        if(dropdownOptions[0]!==undefined) {
                            dom.addClass(dropdownOptions[0],"selected");
                            dom.addClass(dropdownOptions[0],"focused");
                        }
                    }
                }
                break;
            case "Enter":
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                        dropdownSelectedOption.click();
                    }
                }
                break;
            default:
                if(focusedElementByClass&&dom.hasClass(focusedElementByClass,"dropdown-entry"))
                {
                    searchInput=document.getElementById(focusedElementByClass.parentNode.id+"-text");
                    keyEvent = new KeyboardEvent("keydown", {key: e.key,which: e.which,keyCode: e.keyCode, char: (e.which || e.keyCode)});
                    searchInput.focus();
                    searchInput.dispatchEvent(keyEvent);
                }
                break;
        }
    }
    else { //Backward compatibility
        switch(e.which || e.keyCode)
        {
            case KEYCODE_CTRL:
                ctrlPressed=true;
                break;
            case KEYCODE_SHIFT:
                shiftPressed=true;
                break;
            case KEYCODE_ARROW_RIGHT:
            case KEYCODE_ARROW_DOWN:
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-input")) {
                        dropdownOptionsContainer=document.getElementById(focusedElementByClass.id.replace("-text",""));
                        dom.removeClass(focusedElementByClass,"focused");
                        focusedElementByClass.blur();
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                }
                if(dropdownOptions)
                {
                    if(dropdownSelectedOption)
                    {
                        dom.removeClass(dropdownSelectedOption,"selected");
                        if(dropdownSelectedOption.nextSibling)
                        {
                            dom.addClass(dropdownSelectedOption.nextSibling,"selected");
                            dom.addClass(dropdownSelectedOption.nextSibling,"focused");
                            if(dropdownSelectedOption.previousSibling)
                                animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.previousSibling.offsetTop,dropdownSelectedOption.offsetTop,50,true);
                            else
                                animate(dropdownOptionsContainer,"scrollTop","",0,dropdownSelectedOption.offsetTop,50,true);
                        }
                        else
                        {
                            animate(dropdownOptionsContainer,"scrollTop","",dropdownOptions[dropdownOptions.length-1].offsetTop,0,50,true);
                            dom.addClass(dropdownOptions[0],"selected");
                            dom.addClass(dropdownOptions[0],"focused");
                        }
                    }
                    else
                    {
                        dom.addClass(dropdownOptions[0],"selected");
                        dom.addClass(dropdownOptions[0],"focused");
                    }
                }
                break;
            case KEYCODE_ARROW_LEFT:
            case KEYCODE_ARROW_UP:
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-input"))
                    {
                        dropdownOptionsContainer=document.getElementById(focusedElementByClass.id.replace("-text",""));
                        dom.removeClass(focusedElementByClass,"focused");
                        focusedElementByClass.blur();
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                    }
                }
                if(dropdownOptions)
                {
                    if(dropdownSelectedOption)
                    {
                        dom.removeClass(dropdownSelectedOption,"selected");
                        if(dropdownSelectedOption.previousSibling)
                        {
                            dom.addClass(dropdownSelectedOption.previousSibling,"selected");
                            dom.addClass(dropdownSelectedOption.previousSibling,"focused");
                            if(dropdownSelectedOption.nextSibling)
                            {
                                if(dropdownSelectedOption.previousSibling.previousSibling)
                                    animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.nextSibling.offsetTop,dropdownSelectedOption.previousSibling.previousSibling.offsetTop,50,true);
                                else
                                    animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.nextSibling.offsetTop,dropdownSelectedOption.previousSibling.offsetTop,50,true);
                            }
                            else
                                animate(dropdownOptionsContainer,"scrollTop","",dropdownSelectedOption.offsetTop,dropdownSelectedOption.previousSibling.offsetTop,50,true);
                        }
                        else
                        {
                            animate(dropdownOptionsContainer,"scrollTop","",0,dropdownOptions[dropdownOptions.length-1].offsetTop,50,true);
                            dom.addClass(dropdownOptions[dropdownOptions.length-1],"selected");
                            dom.addClass(dropdownOptions[dropdownOptions.length-1],"focused");
                        }
                    }
                    else
                    {
                        dom.addClass(dropdownOptions[0],"selected");
                        dom.addClass(dropdownOptions[0],"focused");
                    }
                }
                break;
            case KEYCODE_ENTER:
                if(focusedElementByClass)
                {
                    if(dom.hasClass(focusedElementByClass,"dropdown-entry"))
                    {
                        dropdownOptionsContainer=focusedElementByClass.parentNode;
                        dom.removeClass(focusedElementByClass,"focused");
                        dropdownOptions=dropdownOptionsContainer.getElementsByClassName("dropdown-entry");
                        dropdownSelectedOption=dropdownOptionsContainer.querySelector(".dropdown-entry.selected");
                        dropdownSelectedOption.click();
                    }
                }
                break;
            default:
                if(focusedElementByClass && dom.hasClass(focusedElementByClass,"dropdown-entry"))
                {
                    searchInput=document.getElementById(focusedElementByClass.parentNode.id+"-text");
                    keyEvent = new KeyboardEvent("keydown", {key: e.key,which: e.which,keyCode: e.keyCode, char: (e.which || e.keyCode)});
                    searchInput.focus();
                    searchInput.dispatchEvent(keyEvent);
                }
        }
    }

});

//event listener to remove error validation color onclick
document.addEventListener("mousedown", function(e) {
    if(dom.hasClass(e.target, "input-validation-failed"))
    {
        dom.removeClass(e.target,"input-validation-failed");
        var validationMessages=document.getElementsByClassName("validation-failed-message");
        for(var i=0; i<validationMessages.length; i++)
        {
            if(validationMessages[i].getAttribute("data-validation-message-id")===e.target.getAttribute("data-validation-message-id"))
            {
                validationMessages[i].parentElement.removeChild(validationMessages[i]);
            }
        }
    }
}, false);

//An additional touch listener, just to close opened dropdown menus
document.addEventListener("click",function(event) {
    if(!someParentHasClass(event.target,"dropdown-input")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if(dom.hasClass(openDropdown,"show"))
                dom.removeClass(openDropdown,"show");
        }
    }
    if (!someParentHasClass(event.target,"tab-link")) {
        if(!touchMoved) {
            hideMobileMenu();
        }
    }
    if(!someParentHasClass(event.target,"tab-link-menu")){
        if(!touchMoved) {
            hideLoggedInUserMenu();
        }
    }
    /* Since I use this additional event listener just on the dropdown menus, when I'm here I have already checked
     * the menus and I no longer want this event to be propagated
     */
    preventEvent(event);
});
/*
// credit: http://www.javascriptkit.com/javatutors/touchevents2.shtml
function swipedetect(el, callback){
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){};
    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        dist = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        e.preventDefault()
    }, false);
    touchsurface.addEventListener('touchmove', function(e){
        e.preventDefault() // prevent scrolling when inside DIV
    }, false);
    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir)
        e.preventDefault()
    }, false);
}
swipedetect(document, function(swipedir){
    // swipedir contains either "none", "left", "right", "top", or "down"
    switch(swipedir){
        case "left":
            hideMobileMenu(true);
            break;
        case "right":
            var mobileMenu=document.getElementById("navbar-left-zone");
            dom.addClass(mobileMenu,"mobile-visible");
            dom.addClass(mobileMenu,"hidable");
            break;
    }
});*/

function handleUrl(eventData)
{
    if(eventData.url.includes("%23")){
        eventData.url = eventData.url.replace("%23","#");
        window.location = eventData.url;
        handleUrl(eventData);
        return;
    }
    //Password recovery page
    if(eventData.url.includes("/password/reset")){
        var username=getAllUrlParams(eventData.url).u;
        var passwordResetToken=getAllUrlParams(eventData.url).t;
        window.location="password/reset.html?t="+passwordResetToken+"&u="+username;
        return;
    }
    if(!urlNeedsLogin()) {
        handleUrlPath();
    }
    else {
        if(window.location.hash.replace("#","")==="login/password-reset-completed"){
            ajaxLogout(true);
        }
        else {
            if(currentAuthToken==null) {
                //savedPageHash allows to redirect to the original requested page when authentication is needed
                savedPageHash=window.location.hash.replace("#","");
                changePage("page-login");
            }
            else {
                //Resuming previous session
                dom.addClass(document.getElementById("page-loading-error"),"hidden");
                dom.addClass(document.getElementById("page-loading"),"hidden");
                //Selects the correct company for the required resource
                preHandleUrlPath(
                    eventData.url.split("#")[1],
                    function() {
                        if(eventData.url.indexOf("#")>=0) {
                            handleUrlPath(eventData.url.split("#")[1]);
                        }
                        else {
                            changePage("page-home");
                        }
                    }
                );
            }
        }
    }
}

function onDbReady() {
    dom.removeClass(document.getElementById("tab-outbox-container"),"hidden");
    dbReady=true;
}

var lockStartupUrlPathHandler=false;
var deviceReady=false;

var hardwareAvailability = {
    gpsEnabled:true,
    gpsHasPermission:true,
    gpsPermissionAlwaysDenied:false,
    autoCheckInterval:null,
    checkRequirements: function() {
        console.log("checking hardware requirements");
        if(hardwareAvailability.gpsPermissionAlwaysDenied) {
            dom.removeClass(document.getElementById("resolve-required-gps-always-denied"),"hidden");
        }
        else {
            dom.addClass(document.getElementById("resolve-required-gps-always-denied"),"hidden");
            if(hardwareAvailability.gpsHasPermission) {
                dom.addClass(document.getElementById("resolve-required-gps-denied"),"hidden");
            }
            else {
                dom.removeClass(document.getElementById("resolve-required-gps-denied"),"hidden");
            }
        }
        if(hardwareAvailability.gpsEnabled) {
            dom.addClass(document.getElementById("resolve-required-gps-enabling"),"hidden");
        }
        else {
            dom.removeClass(document.getElementById("resolve-required-gps-enabling"),"hidden");
        }
        if((!hardwareAvailability.gpsEnabled)||(!hardwareAvailability.gpsHasPermission)) {
            if(currentPage!=="page-missing-permissions") {
                changePage("page-missing-permissions");
            }
            dom.addClass(document.getElementById("navbar"), "missing-permission-hidden");
        }
        else {
            if(hardwareAvailability.autoCheckInterval) {
                clearInterval(hardwareAvailability.autoCheckInterval);
            }
            dom.removeClass(document.getElementById("navbar"), "missing-permission-hidden");
            if(currentPage==="page-missing-permissions") {
                if(ui.isPageHistoryEmpty) {
                    if(currentLoggedInUserId!==null) {
                        changePage("page-home");
                    }
                    else {
                        changePage("page-login");
                    }
                }
                else {
                    ui.pageBack();
                }
            }
        }
    },
    checkGpsEnabled: function() {
        if(typeof cordova.plugins.diagnostic.isGpsLocationEnabled !== "function"){
            cordova.plugins.diagnostic.isLocationEnabled(function(enabled){
                hardwareAvailability.gpsEnabled=enabled;
                hardwareAvailability.checkRequirements();
            }, function(error){
                errorPopup("Errore durante la verifica del sistema GPS:"+error);
            });
        }
        else{
            cordova.plugins.diagnostic.isGpsLocationEnabled(function(enabled){
                hardwareAvailability.gpsEnabled=enabled;
                hardwareAvailability.checkRequirements();
            }, function(error){
                errorPopup("Errore durante la verifica del sistema GPS:"+error);
            });
        }
    },
    analyzeDiagnosticGpsStatus: function(status) {
        switch(status){
            case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
            case cordova.plugins.diagnostic.permissionStatus.DENIED:
                hardwareAvailability.gpsPermissionAlwaysDenied=false;
                hardwareAvailability.gpsHasPermission=false;
                break;
            case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                hardwareAvailability.gpsPermissionAlwaysDenied=true;
                hardwareAvailability.gpsHasPermission=false;
                break;
            case cordova.plugins.diagnostic.permissionStatus.GRANTED:
            case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                hardwareAvailability.gpsPermissionAlwaysDenied=false;
                hardwareAvailability.gpsHasPermission=true;
                break;
        }
    },
    checkGpsPermission: function() {
        cordova.plugins.diagnostic.getLocationAuthorizationStatus(
            function(status) {
                hardwareAvailability.analyzeDiagnosticGpsStatus(status);
                hardwareAvailability.checkRequirements();
                if(status===cordova.plugins.diagnostic.permissionStatus.DENIED || status===cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED){
                    hardwareAvailability.requestGpsPermission();
                }
            },
            function(error) {
                errorPopup("Errore durante la lettura dei permessi:" + error);
            }
        );
    },
    requestGpsPermission: function() {
        cordova.plugins.diagnostic.requestLocationAuthorization(
            function(status){
                hardwareAvailability.checkGpsEnabled();
                hardwareAvailability.analyzeDiagnosticGpsStatus(status);
                hardwareAvailability.checkRequirements();
            },
            function(error) {
                console.error("Errore durante la richiesta dei permessi:" + error);
            }
        );
    },
    requestGpsEnable: function() {
        cordova.plugins.diagnostic.switchToLocationSettings();
        hardwareAvailability.autoCheckInterval=setInterval(hardwareAvailability.checkRequirements,3000);
    },
    requestGpsPermissionWhenAlwaysDenied: function() {
        cordova.plugins.diagnostic.switchToSettings();
        hardwareAvailability.autoCheckInterval=setInterval(hardwareAvailability.checkRequirements,3000);
    }
};

function onResume() {
    // Handle the resume event
    hardwareAvailability.checkRequirements();
    if(hardwareAvailability.autoCheckInterval){
        clearInterval(hardwareAvailability.autoCheckInterval);
    }
}

function getVersion() {
    if(AppVersion){
        setInnerHtml(document.getElementById("version-number")," v"+AppVersion.version);
        ajaxRegisterAppVersion(AppVersion.version,"-");
    }
}

function onDeviceReady() {
    console.log("device ready");
    deviceReady=true;
    document.addEventListener("resume", onResume, false);
    //Checking GPS availability
    // hardwareAvailability.requestGpsPermission();
    //Universal links management
    //if(universalLinks) {
    //    universalLinks.subscribe("handleUrl", handleUrl);
    //}
    //Printing version in menu
    getVersion();
    //Initializing camera from camera.js
    initializeCamera();
    //Local database initialization
    db.localDb = window.sqlitePlugin.openDatabase({ name: 'clasterFM.db', location: 'default' }, function() {
        console.log("Claster DB opened, Checking DB version...");
        db.getUtilityDataRow(
            "DB_VERSION",
            function(version){
                console.log("DB version: "+version);
                setInnerHtml(document.getElementById("db-version-number")," DB v"+version);
                switch(version){
                    case null:
                        db.createLocalTables(function() {
                                db.insertOrUpdateUtilityData("DB_VERSION",db.currentVersion);
                                //from HERE the local DB is available for sure
                                onDbReady();
                            },
                            function(error){
                                errorPopup("Errore durante la creazione del DB locale: " + error.message);
                            }
                        );
                        break;
                    case "1.0":
                        ajaxSendErrorReport("-","-","App con DB v1.0 non retrocompatibile");
                        break;
                    case db.currentVersion:
                        onDbReady();
                        break;
                    default:
                        db.upgradeSchemaFromVersion(version);
                        break;
                }

            },
            function(error){
                console.log(error);
                db.createLocalTables(function(){
                    console.log("creating local tables...");
                    db.insertOrUpdateUtilityData("DB_VERSION",db.currentVersion);
                    //from HERE the local DB is available for sure
                    onDbReady();
                },
                function(error){
                    errorPopup("Errore durante la creazione del DB locale: " + error.message);
                });
            }
        );
    }, function(error) {
        warning('Impossibile aprire il DB locale: ' + JSON.stringify(error));
    });

    if(QRScanner){
        document.getElementById("create-anomaly-scan-asset-qr").removeAttribute("disabled");
        document.getElementById("create-ticket-scan-asset-qr").removeAttribute("disabled");
        document.getElementById("home-qr-scanner-button").removeAttribute("disabled");
        document.getElementById("pick-asset-qr").removeAttribute("disabled");
        document.getElementById("asset-replacement-scan-qr-button").removeAttribute("disabled");
    }

    //Firebase is available only on Cordova applications, for now
    if(window.FirebasePlugin)
    {
        console.log("Firebase plugin is available");
        //Getting push notification premissions on iOS
        window.FirebasePlugin.hasPermission(function(hasPermission){
            if(!hasPermission) {
                window.FirebasePlugin.grantPermission(function(){
                    console.log("Permission is granted for iOS");    
                }, function(error){
                    console.log(error);
                });
            }
        });

        window.FirebasePlugin.onTokenRefresh(function(fcmToken) {
            console.log("Firebase token refreshed");
            currentFcmToken=fcmToken;
            if(currentAuthToken&&currentFcmToken)
                ajaxSendNewFirebaseTokenToServer(currentFcmToken);
            // fcmToken is the new Firebase Cloud Messaging token to be saved on the server
        }, function(error) {
            console.error(error);
            ajaxSendErrorReport("window.FirebasePlugin.onTokenRefresh",JSON.stringify(error),error);
        });

        window.FirebasePlugin.onMessageReceived(function(notification) {
            switch(notification.type) {
                case "action":
                    if(notification.tap=="foreground"){
                        if(notification.action){
                            lockStartupUrlPathHandler=true;
                            preHandleUrlPath(
                                notification.action,
                                function() {
                                    handleUrlPath(notification.action);
                                }
                            );
                        }
                    }
                    else {
                        ajaxGetNotifications();
                    }
                    break;
                case "update":
                    switch(notification.update) {
                        case "ASSET":
                            assetInstanceManager.refreshAssets(notification.companyToUpdate);
                            break;
                        case "INTERVENTION":
                            interventionManager.refreshInterventions(notification.companyToUpdate);
                            break;
                        case "ANOMALY":
                            //Ignoring anomaly updates for now
                            break;
                    }
                    break;
            }
            return false;
        }, function(error) {
            console.error(error);
            ajaxSendErrorReport("ERROR: window.FirebasePlugin.onNotificationOpen",JSON.stringify(error),error);
        });
    }
}

document.addEventListener('focus',function(){
    setTimeout(function(){
        var now = new Date();
        var lastSyncDateObjectMaxTime=lastSyncDateObject.setTime(lastSyncDateObject.getTime() + (16000)); //datediff > 12 seconds
        if(lastSyncDateObjectMaxTime<now) {
            setTimeout(ajaxGetNotifications, 1000);
            if(mustSendNewFirebaseToken&&(currentFcmToken!=null)) {
                ajaxSendNewFirebaseTokenToServer(currentFcmToken);
            }
        }
    },10000);
}, true);

//polyfill for browsers not supporting hashchange events
if(!window.HashChangeEvent)(function(){
    var lastURL=document.URL;
    window.addEventListener("hashchange",function(event){
        Object.defineProperty(event,"oldURL",{enumerable:true,configurable:true,value:lastURL});
        Object.defineProperty(event,"newURL",{enumerable:true,configurable:true,value:document.URL});
        lastURL=document.URL;
    });
}());

function authTokenRetrievingCompleted() {
    if(!initialUrlHandled) {
        if(!urlNeedsLogin()) {
            if(!lockStartupUrlPathHandler) {
                handleUrlPath();
            }
            else {
                lockStartupUrlPathHandler=false;
            }
        }
        else {
            if(currentAuthToken==null) {
                console.log("Auth token null, page hash:"+window.location.hash);
                savedPageHash=window.location.hash.replace("#","");
                changePage("page-login");
            }
            else
            {
                //Resuming previous session
                dom.addClass(document.getElementById("page-loading-error"),"hidden");
                dom.addClass(document.getElementById("page-loading"),"hidden");
                if(!lockStartupUrlPathHandler) {
                    preHandleUrlPath(window.location.hash.replace("#",""),null);
                }
                else {
                    lockStartupUrlPathHandler=false;
                }
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", function() {
    initializeCytoscape();
    initialize();
    document.addEventListener("deviceready", onDeviceReady, false);
    try{ //Cookies disabled
        currentAuthToken = window.localStorage.getItem("cfm_at");
        currentSessionCode = window.localStorage.getItem("cfm_sc");
        currentAuthTokenV2 = window.localStorage.getItem("cfm_at_v2");
    }
    catch(e){
        console.error(e);
        currentAuthToken=null;
    }

    if(currentAuthToken || (!db.localDb)){
        authTokenRetrievingCompleted();
    }
    else{
        db.getLastUserSession(function(lastTokenStored) {
            currentAuthToken = lastTokenStored;
            authTokenRetrievingCompleted();
        });
    }
});

var timeoutId;
var lastViewportWasSmall=isSmallViewport();

window.onresize = function() {
  window.clearTimeout(timeoutId);
  //call the function after half second
  timeoutId = window.setTimeout(screenResized, 500);
};

window.onbeforeunload = function() {
    if(popupManager.getOpenPopupsCount()>0) {
        return 'Sei sicuro di voler chiudere la scheda?';
    }
};

document.addEventListener("backbutton", onBackKeyDown, false);

function onBackKeyDown() {
    // Handle the back button
    if(popupManager.getOpenPopupsCount()>0){
        closePopup();
    }
    else {
        ui.pageBack();
    }
}

//screen size has changed between a small (width<800px) and a big viewport
function screenSizeChanged() {
    //Map already initialized
    if(map!==null) {
        loadMapScenario();
    }
    //The filter icon can or cannot take into account the category filter, depending on screen size
    countAssetNavAppliedFilters();
    //On small viewports the scroll logic is different
    scroll.refreshAll();
}

function screenResized() {
    if(lastViewportWasSmall!==isSmallViewport()) {
        lastViewportWasSmall=isSmallViewport();
        screenSizeChanged();
    }
}

var timePickerTimeoutMinutes=null,timePickerTimeoutHours=null;
window.decreaseMinutes=function(){
    var oldValue = parseInt(document.getElementById("time-picker-minutes").value);
    if(oldValue>0){
        document.getElementById("time-picker-minutes").value=oldValue-1;
    }
    else{
        document.getElementById("time-picker-minutes").value=59;
    }
    clearInterval(timePickerTimeoutMinutes);
    timePickerTimeoutMinutes = setInterval(function () {
        oldValue = parseInt(document.getElementById("time-picker-minutes").value);
        if(oldValue>0){
            document.getElementById("time-picker-minutes").value=oldValue-1;
        }
        else{
            document.getElementById("time-picker-minutes").value=59;
        }
    }, 100);
}

window.increaseMinutes=function(){
    var oldValue = parseInt(document.getElementById("time-picker-minutes").value);
    if(oldValue<59){
        document.getElementById("time-picker-minutes").value=oldValue+1;
    }   
    else{
        document.getElementById("time-picker-minutes").value=0;
    } 
    clearInterval(timePickerTimeoutMinutes);
    timePickerTimeoutMinutes = setInterval(function () {
        oldValue = parseInt(document.getElementById("time-picker-minutes").value);
        if(oldValue<59){
            document.getElementById("time-picker-minutes").value=oldValue+1;
        }   
        else{
            document.getElementById("time-picker-minutes").value=0;
        } 
    }, 100);
}
window.decreaseHours=function(){
    var oldValue = parseInt(document.getElementById("time-picker-hours").value);
    if(oldValue>0){
        document.getElementById("time-picker-hours").value=oldValue-1;
    }
    clearInterval(timePickerTimeoutHours);
    timePickerTimeoutHours = setInterval(function () {
        oldValue = parseInt(document.getElementById("time-picker-hours").value);
        if(oldValue>0){
            document.getElementById("time-picker-hours").value=oldValue-1;
        }
    }, 100);
}
window.increaseHours=function(){
    var oldValue = parseInt(document.getElementById("time-picker-hours").value);
    if(oldValue<999){
        document.getElementById("time-picker-hours").value=oldValue+1;
    } 

    clearInterval(timePickerTimeoutHours);
    timePickerTimeoutHours = setInterval(function () {
        oldValue = parseInt(document.getElementById("time-picker-hours").value);
        if(oldValue<999){
            document.getElementById("time-picker-hours").value=oldValue+1;
        } 
    }, 100);
}
window.stopTimePickerMinutes=function(){
    clearInterval(timePickerTimeoutMinutes);
}
window.stopTimePickerHours=function(){
    clearInterval(timePickerTimeoutHours);
}

var currentShownTimeId=null;
window.saveTimeReading =function(){
    document.getElementById(currentShownTimeId).value=document.getElementById("time-picker-hours").value+":"+document.getElementById("time-picker-minutes").value;
}

/********** exposing function to DOM events attributes **************/
window.abortAssetsUpload=abortAssetsUpload;
window.activityOnAssetInstanceEnableEditAssetField=activityOnAssetInstanceEnableEditAssetField;
window.addCompanyAddress=addCompanyAddress;
window.addCompanyCertification=addCompanyCertification;
window.addCompanyFromLicense=addCompanyFromLicense;
window.addCompanyToPermissions=addCompanyToPermissions;
window.addContractEditSubcategoryFrequency=addContractEditSubcategoryFrequency;
window.addContractEditSubcategoryGlobalFrequency=addContractEditSubcategoryGlobalFrequency;
window.addContractPropagateGlobalFrequencyEditToChildren=addContractPropagateGlobalFrequencyEditToChildren;
window.addInvoiceHideReceivingSite=addInvoiceHideReceivingSite;
window.addInvoiceShowReceivingSite=addInvoiceShowReceivingSite;
window.addUserToCompanyPopup=addUserToCompanyPopup;
window.ajaxApproveCompanyDocument=ajaxApproveCompanyDocument;
window.ajaxCloneContract=ajaxCloneContract;
window.ajaxCompleteAssetsUpload=ajaxCompleteAssetsUpload;
window.ajaxDeleteBuildingRepartitionTable=ajaxDeleteBuildingRepartitionTable;
window.ajaxDeleteBuildingsGroupRepartitionTable=ajaxDeleteBuildingsGroupRepartitionTable;
window.ajaxDeleteCommonSiteRepartitionTable=ajaxDeleteCommonSiteRepartitionTable;
window.ajaxDeleteExpenditureItem=ajaxDeleteExpenditureItem;
window.ajaxDownloadMultipleDocumentsFromDataRoom=ajaxDownloadMultipleDocumentsFromDataRoom;
window.ajaxFinalizeAccountingYear=ajaxFinalizeAccountingYear;
window.ajaxFinalizeInstallments=ajaxFinalizeInstallments;
window.ajaxFullSearch=ajaxFullSearch;
window.ajaxGetAssetInstance=ajaxGetAssetInstance;
window.ajaxGetBuildingRepartitionTable=ajaxGetBuildingRepartitionTable;
window.ajaxGetBuildingsGroupRepartitionTable=ajaxGetBuildingsGroupRepartitionTable;
window.ajaxGetCommonSiteRepartitionTable=ajaxGetCommonSiteRepartitionTable;
window.ajaxGetCustomerSatisfactionReport=ajaxGetCustomerSatisfactionReport;
window.ajaxGetFireLogBookBySiteId=ajaxGetFireLogBookBySiteId;
window.ajaxGetUserPermissionsOfSpecificUserId=ajaxGetUserPermissionsOfSpecificUserId;
window.ajaxLoadSiteEditor=ajaxLoadSiteEditor;
window.ajaxManageCompanyProperties=ajaxManageCompanyProperties;
window.ajaxManageLinkedCompany=ajaxManageLinkedCompany;
window.ajaxMoveDocumentToTrash=ajaxMoveDocumentToTrash;
window.ajaxRecoverDocumentFromTrash=ajaxRecoverDocumentFromTrash;
window.ajaxReloadDataRoomFilters=ajaxReloadDataRoomFilters;
window.ajaxSaveNewActivitiesForSubcategoryOrder=ajaxSaveNewActivitiesForSubcategoryOrder;
window.ajaxShareMultipleDocumentsFromDataRoom=ajaxShareMultipleDocumentsFromDataRoom;
window.ajaxSubcategoryRestoreDisabled=ajaxSubcategoryRestoreDisabled;
window.ajaxViewContract=ajaxViewContract;
window.ajaxViewGeolocationData=ajaxViewGeolocationData;
window.anomaliesPage=anomaliesPage;
window.applyDataRoomSearch=applyDataRoomSearch;
window.assetNavClickSubcategory=assetNavClickSubcategory;
window.assetNavClickSubcategoryGroup=assetNavClickSubcategoryGroup;
window.assignTicketRemoveCompanySelector=assignTicketRemoveCompanySelector;
window.attachPhotoUploadToTarget=attachPhotoUploadToTarget;
window.blockUser=blockUser;
window.calculateLicenseCost=calculateLicenseCost;
window.cancelInterventionRequest=cancelInterventionRequest;
window.catTreeToggleCatGroupChildrenVisibility=catTreeToggleCatGroupChildrenVisibility;
window.changeAllUserPermissionsFromSingleSelector=changeAllUserPermissionsFromSingleSelector;
window.changeMapFloor=changeMapFloor;
window.changePage=changePage;
window.changeTicketState=changeTicketState;
window.chatPopup=chatPopup;
window.checkHolidayForDate=checkHolidayForDate;
window.clearDropdown=clearDropdown;
window.closePopup=closePopup;
window.commentActivityOnAssetInstanceExecution=commentActivityOnAssetInstanceExecution;
window.commentActivityOnTicketInExecution=commentActivityOnTicketInExecution;
window.copyPermissions=copyPermissions;
window.correctAssetInstanceInIntervention=correctAssetInstanceInIntervention;
window.createAnomalyFromInterventionDetailedReport=createAnomalyFromInterventionDetailedReport;
window.createAssetLoadSubcategories=createAssetLoadSubcategories;
window.createAssetRemoveSubcategoryRow=createAssetRemoveSubcategoryRow;
window.createContractPopup=createContractPopup;
window.createInterventionPopup=createInterventionPopup;
window.createTicketFromAnomaly=createTicketFromAnomaly;
window.createTicketFromInterventionDetailedReport=createTicketFromInterventionDetailedReport;
window.deleteCompanyAddress=deleteCompanyAddress;
window.deleteUser=deleteUser;
window.deselectDataRoomDocument=deselectDataRoomDocument;
window.disableActivityOnSubcategory=disableActivityOnSubcategory;
window.downloadCompiledInterventionReportPDF=downloadCompiledInterventionReportPDF;
window.downloadInterventionReportPDF=downloadInterventionReportPDF;
window.downloadInterventionTechnicalConsumptivePDF=downloadInterventionTechnicalConsumptivePDF;
window.dropdownChanged=dropdownChanged;
window.dropdownClicked=dropdownClicked;
window.dropdownFocused=dropdownFocused;
window.dropdownLostFocus=dropdownLostFocus;
window.editActivityOnSubcategory=editActivityOnSubcategory;
window.editAssetLoadSubcategories=editAssetLoadSubcategories;
window.editAssetRemoveSubcategoryRow=editAssetRemoveSubcategoryRow;
window.editBuildingsGroupOwnerAdminMatchChanged=editBuildingsGroupOwnerAdminMatchChanged;
window.editCategory=editCategory;
window.editCategoryGroup=editCategoryGroup;
window.editSubcategory=editSubcategory;
window.endInterventionPopup=endInterventionPopup;
window.errorToast=errorToast;
window.finalizeDocumentDeletion=finalizeDocumentDeletion;
window.getInterventionsForDay=getInterventionsForDay;
window.getQrCodeDataByScanningQrCode=getQrCodeDataByScanningQrCode;
window.editAssetScanQR=editAssetScanQR;
window.ghostClickController=ghostClickController;
window.imageTheater=imageTheater;
window.increaseUpdateCounter=increaseUpdateCounter;
window.interventionManager=interventionManager;
window.interventionOutcomeChanged=interventionOutcomeChanged;
window.interventionPage=interventionPage;
window.invalidateUserPassword=invalidateUserPassword;
window.loadDocumentEditor=loadDocumentEditor;
window.manageCompanySelectorChanged=manageCompanySelectorChanged;
window.newRepartitionTableFor=newRepartitionTableFor;
window.newTicketAnomalyLevelChanged=newTicketAnomalyLevelChanged;
window.notificationManager=notificationManager;
window.openGoogleMaps=openGoogleMaps;
window.outboxManager=outboxManager;
window.pastePermissions=pastePermissions;
window.populateExportContractSpecCategories=populateExportContractSpecCategories;
window.populateSubcategorySelect=populateSubcategorySelect;
window.populateViewActivityOnSubcSubcategories=populateViewActivityOnSubcSubcategories;
window.popup=popup;
window.popupCompanyLicenses=popupCompanyLicenses;
window.popupCreateAsset=popupCreateAsset;
window.popupCreateBothCustomerAndSupplierLicense=popupCreateBothCustomerAndSupplierLicense;
window.popupCreateCustomerLicense=popupCreateCustomerLicense;
window.popupCreateSupplierLicense=popupCreateSupplierLicense;
window.popupEditCompany=popupEditCompany;
window.popupUnlinkCompanyFromLicense=popupUnlinkCompanyFromLicense;
window.refreshAnomalyDetailsScroll=refreshAnomalyDetailsScroll;
window.refreshAssetDetailsScroll=refreshAssetDetailsScroll;
window.refreshTicketDetailsScroll=refreshTicketDetailsScroll;
window.refuseIntervention=refuseIntervention;
window.registerCloseInterventionData=registerCloseInterventionData;
window.reloadAssetSearchMatchResults=reloadAssetSearchMatchResults;
window.reloadDataRoomSearchFilters=reloadDataRoomSearchFilters;
window.reloadDataRoomSearchMatchResults=reloadDataRoomSearchMatchResults;
window.reloadSearchAssetsForQrCodeTextSearch=reloadSearchAssetsForQrCodeTextSearch;
window.reloadTicketSearchMatchResults=reloadTicketSearchMatchResults;
window.reloadUsersSearch=reloadUsersSearch;
window.renderCalendar=renderCalendar;
window.renderDocument=renderDocument;
window.renderYearCalendar=renderYearCalendar;
window.replaceAssetDuringIntervention=replaceAssetDuringIntervention;
window.restartAssetsUpload=restartAssetsUpload;
window.safetyControlPage=safetyControlPage;
window.searchAssetForQrCode=searchAssetForQrCode;
window.searchOrAddCompany=searchOrAddCompany;
window.selectAllActivitiesForNewIntervention=selectAllActivitiesForNewIntervention;
window.selectAnomaly=selectAnomaly;
window.selectAssetInstance=selectAssetInstance;
window.selectAssetInstanceInExecution=selectAssetInstanceInExecution;
window.selectAssetInstancePreview=selectAssetInstancePreview;
window.selectDataRoomDocument=selectDataRoomDocument;
window.selectIntervention=selectIntervention;
window.selectInterventionActivity=selectInterventionActivity;
window.selectTicket=selectTicket;
window.showActivitiesOnAssetInstance=showActivitiesOnAssetInstance;
window.showAllGeneratedQrCodes=showAllGeneratedQrCodes;
window.showCompanyAddress=showCompanyAddress;
window.showCompanyAddressToEdit=showCompanyAddressToEdit;
window.showProfilePictureUploader=showProfilePictureUploader;
window.stopDrawing=stopDrawing;
window.submitForm=submitForm;
window.tagLikeInputlostFocus=tagLikeInputlostFocus;
window.toggleActivityForNewIntervention=toggleActivityForNewIntervention;
window.toggleActivityOnAssetInstanceCheckbox=toggleActivityOnAssetInstanceCheckbox;
window.toggleActivityOnTicketCheckbox=toggleActivityOnTicketCheckbox;
window.toggleCustomerSiteForContract=toggleCustomerSiteForContract;
window.toggleEntireAssetNavTree=toggleEntireAssetNavTree;
window.toggleFrequencyPlaceholder=toggleFrequencyPlaceholder;
window.toggleInterventionReport=toggleInterventionReport;
window.togglePseudoCheckbox=togglePseudoCheckbox;
window.togglePseudoRadioByClass=togglePseudoRadioByClass;
window.toggleSubcategoryGraphicalMultiSelectorSubcategories=toggleSubcategoryGraphicalMultiSelectorSubcategories;
window.toggleSubcategoryGraphicalMultiSelectorSubcategoriesVisibility=toggleSubcategoryGraphicalMultiSelectorSubcategoriesVisibility;
window.toggleSubcategoryGraphicalMultiSelectorSubcategory=toggleSubcategoryGraphicalMultiSelectorSubcategory;
window.toggleTeamManagementEmployee=toggleTeamManagementEmployee;
window.ui=ui;
window.unblockUser=unblockUser;
window.verifyInterventionDataPopup=verifyInterventionDataPopup;
window.viewBuildingDetails=viewBuildingDetails;
window.viewBuildingsGroupDetails=viewBuildingsGroupDetails;
window.viewBuildingsOrganization=viewBuildingsOrganization;
window.viewEmployeeDetails=viewEmployeeDetails;
window.viewPermissionsForCompany=viewPermissionsForCompany;
window.viewSiteDetails=viewSiteDetails;
window.viewUserData=viewUserData;
window.yourCompanyUsersPopup=yourCompanyUsersPopup;
window.refreshImageTheaterScroll=refreshImageTheaterScroll;
window.startIntervention=startIntervention;
window.redrawAssetsTable=redrawAssetsTable;
window.manageAssetBuildingsGroupChanged=manageAssetBuildingsGroupChanged;
window.maintenanceCategoryChanged=maintenanceCategoryChanged;
window.maintenancePage=maintenancePage;
window.teamManagementExecutorChanged=teamManagementExecutorChanged;
window.manageAssetBuildingChanged=manageAssetBuildingChanged;
window.reloadAssetNavFilters=reloadAssetNavFilters;
window.createAssetLoadBuildings=createAssetLoadBuildings;
window.createAssetLoadSites=createAssetLoadSites;
window.createAssetLoadFloors=createAssetLoadFloors;
window.assetNavClickCategory=assetNavClickCategory;
window.addAssetToInterventionPopup=addAssetToInterventionPopup;
window.loadMapScenario=loadMapScenario;
window.newUserPasswordChanged=newUserPasswordChanged;
window.accountingBuildingsGroupChanged=accountingBuildingsGroupChanged;
window.calendarSubcategoryChanged=calendarSubcategoryChanged;
window.calendarCategoryChanged=calendarCategoryChanged;
window.calendarExecutorChanged=calendarExecutorChanged;
window.calendarYearCategoryChanged=calendarYearCategoryChanged;
window.calendarYearSubcategoryChanged=calendarYearSubcategoryChanged;
window.calendarYearExecutorChanged=calendarYearExecutorChanged;
window.calendarSiteChanged=calendarSiteChanged;
window.calendarYearSiteChanged=calendarYearSiteChanged;
window.createSubcategoryReloadChildrenCategories=createSubcategoryReloadChildrenCategories;
window.catTreeToggleCatChildrenVisibility=catTreeToggleCatChildrenVisibility;
window.catTreeToggleCatGroupChildrenVisibility=catTreeToggleCatGroupChildrenVisibility;
window.ajaxViewActivitiesForSubcategory=ajaxViewActivitiesForSubcategory;
window.resetCompanySearchField=resetCompanySearchField;
window.updatePropertyUnitAvailbleBuildings=updatePropertyUnitAvailbleBuildings;
window.ajaxGetInterventionsAvailableToMerge=ajaxGetInterventionsAvailableToMerge;
window.ajaxGetValidateInterventionOtp=ajaxGetValidateInterventionOtp;
window.ajaxGetInterventionData=ajaxGetInterventionData;
window.populateActivityOnSubcSubcategories=populateActivityOnSubcSubcategories;
window.reloadSearchAssetsForQrCode=reloadSearchAssetsForQrCode;
window.propagateCategoryChangeToSubcategory=propagateCategoryChangeToSubcategory;
window.reloadSearchAssetsForQrCode=reloadSearchAssetsForQrCode;
window.populateDataRoomUploadSubcategories=populateDataRoomUploadSubcategories;
window.dataRoomDocumentTypeFilterChanged=dataRoomDocumentTypeFilterChanged;
window.uploadDocumentTypeChanged=uploadDocumentTypeChanged;
window.exportAssetFieldForSubcategoryReportPopup=exportAssetFieldForSubcategoryReportPopup;
window.renderEventsLog=renderEventsLog;
window.calculatePagesForEventsLog=calculatePagesForEventsLog;
window.manageSubcategoryAdditionalFieldsPopup=manageSubcategoryAdditionalFieldsPopup;
window.addAssetForQrCodeBuildingChanged=addAssetForQrCodeBuildingChanged;
window.createAssetForQrLoadFloors=createAssetForQrLoadFloors;
window.addAssetForQrCodeCategoryChanged=addAssetForQrCodeCategoryChanged;
window.addAssetForQrSubcategoryChanged=addAssetForQrSubcategoryChanged;
window.addAssetForQrCodeBuildingGroupChanged=addAssetForQrCodeBuildingGroupChanged;
window.iso8601=iso8601;
window.ticketingPage=ticketingPage;