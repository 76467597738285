import '../styles/index.scss';
import '../styles/font-awesome.min.css';

import '../scripts/initialize.js';

import '../fonts/AvenirLTStd-Light.otf';
import '../fonts/AvenirLTStd-Medium.otf';


if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

console.log('webpack starterkit');

